import {
  Checkbox,
  Grid,
  Typography,
  // Hidden
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import find from 'lodash/find';
import { Link } from 'react-router-dom';

import useTableRowStyles from './TableRow.styles';
import { HeadCell } from './Table';

import { ScoreStatus } from '../../../models/lead';
import { darkBlue, hyperlinkBlue, success } from '../../../theme';

export interface handleClickRow<K> {
  function: (id: string) => void;
  to?: (id: string) => string;
  param: keyof K;
  link: string;
  newTab?: boolean;
}

interface ITableRowProps<T, K> {
  row: K;
  scoreStatus: ScoreStatus;
  headerCells: HeadCell<T, K>[];
  handleClickRow: handleClickRow<K>;
  reloadItems?: () => Promise<void>;
  isNewKey?: string;
  isNationalKey?: keyof K;
  isOdd?: boolean;
  handleCheckbox?: (row: K) => void;
  bulkDemoteButtonClicked?: boolean;
}

function TableRow<T, K extends { id: string; [key: string]: string }>(props: ITableRowProps<T, K>) {
  const {
    row,
    headerCells,
    handleClickRow,
    reloadItems,
    isNewKey,
    isNationalKey,
    isOdd,
    handleCheckbox,
    bulkDemoteButtonClicked,
  } = props;
  const classes = useTableRowStyles();

  const isNew = row[isNewKey as keyof K];
  const isNationalAccount = isNationalKey ? row[isNationalKey] === 'CSA Account' : false;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const getCellSizeFromHeader = useCallback(
    (id: string) => {
      const cell: HeadCell<T, K> | undefined = find(headerCells, ['id', id]);
      if (cell) {
        return cell.gridProps.md;
      }
    },
    [headerCells],
  );
  const defaultItemStyle = {
    paddingTop: '1rem',
    alignSelf: 'flex-start',
  };

  const checkTableRow = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (handleCheckbox) {
        handleCheckbox(row);
      }

      setIsChecked((prev: boolean) => !prev);
    },
    [handleCheckbox, row],
  );

  useEffect(() => {
    if (!bulkDemoteButtonClicked) {
      setIsChecked(false);
    }
  }, [bulkDemoteButtonClicked]);

  return (
    <Grid className={classes.root}>
      {/* Default to leads detail page */}
      <Link
        to={
          handleClickRow.to
            ? handleClickRow?.to(row[handleClickRow.param] as string)
            : `${handleClickRow.link}${row[handleClickRow.param].toString()}`
        }
        target={handleClickRow.newTab ? '_blank' : ''}
        style={{ textDecoration: 'none', color: darkBlue, display: 'flex' }}
      >
        {bulkDemoteButtonClicked && (
          <Checkbox color="primary" onClick={checkTableRow} checked={isChecked} />
        )}
        <Grid
          container
          alignItems="center"
          className={classes.main}
          spacing={3}
          id={`row-${row[handleClickRow.param]}`}
        >
          {isNew && (
            <Grid className={classes.newBanner}>
              <Typography variant="subtitle1" className={classes.newText}>
                <strong>NEW</strong>
              </Typography>
            </Grid>
          )}
          {isNationalAccount && (
            <Grid className={classes.nationalBanner}>
              <Typography className={classes.nationalText}>
                <strong>National</strong>
              </Typography>
            </Grid>
          )}
          {headerCells.map(col => {
            const parameters: (
              | K[keyof K]
              | string
              | number
              | boolean
              | undefined
            )[] = col.renderRow.param.map(key => row[key]);
            if (col.renderRow?.additionalParam) {
              col.renderRow.additionalParam.forEach(param => parameters.push(param));
            }
            const style = col.renderRow.style;

            // Following Const used for either rendering projectScore or isFavorites col ids
            const stroke = isOdd ? success : hyperlinkBlue;
            const height = 30;
            const width = 60;
            const fontSize = '20px';
            const projectScore = row?.projectScore || 0;
            if (col.id === 'projectScore') {
              return (
                <Grid
                  key={col.id.toString()}
                  item
                  md={getCellSizeFromHeader(col.id.toString())}
                  style={style || {}}
                >
                  {/* @ts-ignore*/}
                  {col.renderRow.function(
                    projectScore,
                    stroke,
                    height,
                    width,
                    fontSize,
                    row?.projectId,
                  )}
                </Grid>
              );
            }

            if (col.id === 'isFavorite') {
              return (
                <Grid
                  key={col.id.toString()}
                  item
                  md={getCellSizeFromHeader(col.id.toString())}
                  style={style || {}}
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    col.renderRow.function({
                      score: projectScore,
                      stroke,
                      height,
                      width,
                      fontSize,
                      projectId: row?.projectId,
                      reloadFunction: reloadItems,
                    })
                  }
                </Grid>
              );
            }

            // Example: HeadCell id set to 'hide'
            if (col.renderRow.reloadItems) {
              return (
                <Grid
                  key={col.id.toString()}
                  item
                  md={getCellSizeFromHeader(col.id.toString())}
                  style={style || {}}
                >
                  {/*// @ts-ignore*/}
                  {col.renderRow.function(reloadItems, ...parameters, col.renderRow.isConverted)}
                </Grid>
              );
            }

            // Default Render:
            return (
              <Grid
                key={col.id.toString()}
                item
                md={getCellSizeFromHeader(col.id.toString())}
                style={style || defaultItemStyle}
              >
                {/*// @ts-ignore*/}
                {col.renderRow.function(...parameters, col.renderRow.isConverted)}
              </Grid>
            );
          })}
        </Grid>
      </Link>
    </Grid>
  );
}

export default TableRow;
