import { makeStyles } from '@material-ui/core/styles';

export const usePLDetailsTabStyles = makeStyles(() => ({
  detailsTabRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));
