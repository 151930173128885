import React, { KeyboardEvent, ChangeEvent, useEffect, useState } from 'react';
import First from '@material-ui/icons/FirstPage';
import Before from '@material-ui/icons/NavigateBefore';
import Next from '@material-ui/icons/NavigateNext';
import Last from '@material-ui/icons/LastPage';
import { TableInstance } from 'react-table';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';

import { useReactTablePaginationStyle } from './ReactTablePagination.style';

interface ReactTablePaginationProps<TableType extends Record<string, unknown>> {
  tableInstance: TableInstance<TableType>;
}

interface PaginationSize {
  value: number;
  label: string;
}

const pageSizes: PaginationSize[] = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25 (default)',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];

export const ReactTablePagination = <TableType extends Record<any, any>>({
  tableInstance,
}: ReactTablePaginationProps<TableType>): JSX.Element => {
  const [paginationPage, setPaginationPage] = useState<number | ''>(1);
  const classes = useReactTablePaginationStyle();
  const {
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = tableInstance;

  const handlePageInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setPaginationPage('');
    } else if (isNaN(Number(e.target.value))) {
      // handle non-numeric entries
      setPaginationPage(paginationPage);
    } else {
      const page = Number(e.target.value);
      setPaginationPage(page);
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const page = Number(e.currentTarget.value) - 1;
      gotoPage(page >= pageCount ? pageCount - 1 : page);
    }
  };

  useEffect(() => {
    setPaginationPage(pageIndex + 1);
  }, [pageIndex]);

  return (
    <Grid className={classes.root}>
      <Select
        classes={{ outlined: classes.select }}
        value={pageSize}
        variant={'outlined'}
        renderValue={() => (
          <Typography variant={'body2'}>Show {<strong>{pageSize}</strong>} Results</Typography>
        )}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
      >
        {pageSizes.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            classes={{ root: classes.selectItem, selected: classes.selected }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
      <span className={classes.pages}>
        {' '}
        <strong> Page </strong>
        <strong>
          {
            <input
              type="text"
              inputMode="numeric"
              value={paginationPage}
              onKeyUp={handleKeyUp}
              onChange={handlePageInput}
              size={(Number(paginationPage) + 1).toString().length}
            />
          }{' '}
          of {pageOptions.length}
        </strong>{' '}
        results{' '}
      </span>
      <Grid className={classes.buttonsContainer}>
        <button className={classes.button} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <First />
        </button>{' '}
        <button
          className={classes.button}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <Before />
        </button>{' '}
        <button className={classes.button} onClick={() => nextPage()} disabled={!canNextPage}>
          <Next />
        </button>{' '}
        <button
          className={classes.button}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <Last />
        </button>{' '}
      </Grid>
    </Grid>
  );
};
