import { DateTime } from 'luxon';

const INVALID_INPUT = 'Invalid DateTime';

export function getLocalDateFromUtcTimestamp(
  input?: string | Date,
  format = 'L/d/yy',
): string | null {
  let result = '';
  try {
    if (!input) {
      return result;
    }
    const date = input instanceof Date ? input.toISOString() : input;
    if (input !== INVALID_INPUT) {
      result = DateTime.fromISO(date).toLocal().toFormat(format);
    } else {
      result = date;
    }
    if (result === INVALID_INPUT) {
      result = '';
    }
  } catch (err) {
    console.log('Error converting date', err);
  }
  return result;
}

export const dateToRelative = (date: Date): string | null => {
  const dateTime = DateTime.fromJSDate(date);
  if (dateTime.diffNow().as('seconds') > -30) {
    return 'just now';
  }

  return dateTime.toRelative();
};

export function getUtcDateFromUtcTimestamp(
  input?: string | Date,
  format = 'L/d/yy',
): string | null {
  let result = '';
  try {
    if (!input) {
      return result;
    }

    const date = input instanceof Date ? input.toISOString() : input;
    if (input !== INVALID_INPUT) {
      result = DateTime.fromISO(date).toUTC().toFormat(format);
    } else {
      result = date;
    }
    if (result === INVALID_INPUT) {
      result = '';
    }
  } catch (err) {
    console.log('Error converting date', err);
  }
  return result;
}
