import React, { memo, useCallback, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Grid, IconButton, makeStyles, Tab, Typography } from '@material-ui/core';
import { useRecoilState } from 'recoil';

import {
  defaultInitialStateAccountsPL,
  accountProjectLeadsColumns,
} from './AccountsPLTableColumns';
import { accountProjectLeadsConvertedColumns } from './AccountsPLConvertedTableColumns';
import {
  accountProjectLeadsDemotedColumns,
  defaultInitialStateAccountsPLDemoted,
} from './AccountsPLDemotedTableColumns';

import OpenListIcon from '../../../../resources/images/OpenListIcon.svg';
import { FetchDataProps, ReactTable } from '../../../Common/Tables/ReactTable/ReactTable';
import PvfProject from '../../../../models/pvfProject';
import { selectedAccountProjectLeadsState } from '../../../../state/atoms/accounts';
import { getAccountsPageProjects } from '../../../../api/organizations';
import { tabStyles } from '../../../Common/CleoTab.styles';
import EmptyBox from '../../../Common/EmptyBox';
import { sortColumns } from '../../Leads/Archive/Archive.config';
import { black, lightGreen } from '../../../../theme';
import { getProjectLeadsAccountPagePath } from '../../../../utils/page';
import { FilterData } from '../../../../types';
import { ProjectStatusCounts } from '../../../../models/project';

enum Tabs {
  Active = 'active',
  Converted = 'converted',
  Demoted = 'demoted',
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'scroll',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabPanel: {
    height: '100%',
    padding: 0,
  },
  empty: {
    backgroundColor: lightGreen,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: black,
  },
}));

type AccountProjectLeadsProps = {
  organizationId: string;
  setProjectsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountsProjectLeads: React.FC<AccountProjectLeadsProps> = ({
  organizationId,
  setProjectsLoaded,
  setHasError,
}) => {
  const classes = useStyles();
  const [accountProjectLeads, setAccountProjectLeads] = useRecoilState(
    selectedAccountProjectLeadsState(organizationId),
  );

  const [tab, setTab] = useState<Tabs>(Tabs.Active);
  const [pageCount, setPageCount] = useState<number>(0);
  const [projectsCount, setProjectsCount] = useState<ProjectStatusCounts | null>(null);
  const disableProjectLeadsList = tab !== Tabs.Active;

  const loadProjectLeads = useCallback(
    async ({ startIndex = 0, limit = 10, sortId, sortIsDesc }: FetchDataProps) => {
      try {
        const filterOption: FilterData = {
          projectStatus: { eq: tab },
        };

        let projectLeads;
        if (organizationId) {
          const { items, total, counts } = await getAccountsPageProjects(
            organizationId,
            {
              sort: {
                orderBy: sortId || 'projectScore',
                order: sortIsDesc || 'desc',
              },
              startIndex,
              filterOption,
              limit: limit,
            },
            sortColumns,
          );
          projectLeads = items;
          setProjectsCount(counts);
          setPageCount(Math.ceil(total / limit));
        } else {
          projectLeads = [] as PvfProject[];
          setPageCount(0);
        }

        setAccountProjectLeads({ loaded: true, projectLeads });
        setProjectsLoaded(true);
      } catch (error: any) {
        setHasError(true);
      }
    },
    [setAccountProjectLeads, setProjectsCount, setProjectsLoaded, setHasError, organizationId, tab],
  );

  useEffect(() => {
    // initial load
    if (!accountProjectLeads.loaded) {
      loadProjectLeads({});
    }
  }, [loadProjectLeads, accountProjectLeads]);

  useEffect(() => {
    // when new org is selected, default back to active tab
    setTab(Tabs.Active);
  }, [organizationId]);

  useEffect(() => {
    // On tab change, make API call to get project leads
    setAccountProjectLeads({ loaded: false, projectLeads: [] as PvfProject[] });
    loadProjectLeads({});
  }, [loadProjectLeads, setAccountProjectLeads]);

  const handleTabChange = useCallback(
    (e, nextTab: Tabs) => {
      e.preventDefault();
      setTab(nextTab);
    },
    [setTab],
  );

  const handleOpenList = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(getProjectLeadsAccountPagePath(id));
  };

  return (
    <Grid className={classes.root}>
      <TabContext value={tab}>
        <Grid className={classes.flexRow}>
          <TabList onChange={handleTabChange} aria-label="accounts project lead tabs">
            {(Object.keys(Tabs) as Array<keyof typeof Tabs>).map((key, idx) => {
              const { loaded } = accountProjectLeads;
              let labelCount = 0;
              if (projectsCount) {
                const { active_count, demoted_count, converted_count } = projectsCount;
                switch (Tabs[key]) {
                  case Tabs.Converted:
                    labelCount = converted_count || 0;
                    break;
                  case Tabs.Demoted:
                    labelCount = demoted_count;
                    break;
                  default:
                    labelCount = active_count;
                    break;
                }
              }
              return (
                <Tab
                  key={idx}
                  classes={tabStyles({ fontSize: '1rem' })}
                  label={`${key} (${loaded ? labelCount : ''})`}
                  value={Tabs[key]}
                />
              );
            })}
          </TabList>
          <IconButton
            onClick={e => {
              handleOpenList(e, organizationId);
            }}
            disabled={disableProjectLeadsList}
            style={{ opacity: disableProjectLeadsList ? 0.3 : 1 }}
          >
            <img src={OpenListIcon} alt={'open project leads list icon'} />
          </IconButton>
        </Grid>
        {(Object.keys(Tabs) as Array<keyof typeof Tabs>).map((key, idx) => {
          // filter the pvfs to display (based on tab)
          const { loaded, projectLeads } = accountProjectLeads;
          const displayPLs = projectLeads.filter(acc => acc.projectStatus === Tabs[key]);

          let configColumns;
          let defaultInitialTableState;
          switch (Tabs[key]) {
            case Tabs.Converted:
              configColumns = accountProjectLeadsConvertedColumns;
              defaultInitialTableState = defaultInitialStateAccountsPL;
              break;
            case Tabs.Demoted:
              configColumns = accountProjectLeadsDemotedColumns;
              defaultInitialTableState = defaultInitialStateAccountsPLDemoted;
              break;
            default:
              configColumns = accountProjectLeadsColumns;
              defaultInitialTableState = defaultInitialStateAccountsPL;
              break;
          }

          return (
            <TabPanel key={idx} value={Tabs[key]} className={classes.tabPanel}>
              {!displayPLs.length && loaded && (
                <EmptyBox className={classes.empty}>
                  <Typography
                    variant={'subtitle1'}
                  >{`There are no ${Tabs[key]} projects at this time`}</Typography>
                </EmptyBox>
              )}
              {!(loaded && displayPLs.length === 0) && (
                <ReactTable<PvfProject>
                  columns={configColumns}
                  data={displayPLs}
                  loading={!loaded}
                  fetchData={loadProjectLeads}
                  pageCount={pageCount}
                  initialTableState={defaultInitialTableState}
                />
              )}
            </TabPanel>
          );
        })}
      </TabContext>
    </Grid>
  );
};

export default memo(AccountsProjectLeads);
