import { makeStyles } from '@material-ui/core';

import { darkLight, primary } from '../../../theme';

const useNewContactsCardStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5),
    borderLeft: `6px solid ${darkLight}`,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  rootPrimary: {
    borderLeftColor: primary,
  },
  container: {
    flexWrap: 'nowrap',
  },
  dense: {
    lineHeight: '1.1rem',
  },
  primary: {
    fontWeight: 'bold',
    lineHeight: '1.1rem',
  },
  logo: {
    maxWidth: 40,
    maxHeight: 20,
    padding: '0 0.5em',
  },
  contactRow: {},
  belongsTo: {
    borderLeft: '1px solid #0C4377',
    margin: '0 0.5rem',
  },
  toolTipIcon: {
    maxWidth: '5rem',
  },
}));

export default useNewContactsCardStyles;
