import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';
import type { IUserResponse } from './user';
import User from './user';

export type IFeedbackResponse = IBaseModelResponse & {
  action: string;
  notes: string;
  user: IUserResponse;
  project_id?: string;
};

export default class Feedback extends BaseModel {
  action!: string;
  notes!: string;
  user!: User;
  project_id?: string;

  constructor(data: IFeedbackResponse) {
    super(data);

    const { action, notes, user, project_id } = data;
    this.action = action;
    this.notes = notes;
    this.user = new User(user);
    this.project_id = project_id;
  }

  get userDisplayName(): string {
    return this.user.displayName;
  }
}
