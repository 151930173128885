import { Checkbox, makeStyles, styled } from '@material-ui/core';
import React from 'react';

import { areEqual } from './ReactTable.config';

export const HeaderCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '1rem',
    '& svg': {
      width: '1em',
      height: '1em',
      fill: '#0F1A24',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  areEqual,
);

export const RowCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: '1em',
      height: '1em',
      fill: '#0F1A24',
    },
  }),
  areEqual,
);

export const useReactTableStyles = makeStyles({});
