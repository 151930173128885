import { Grid, GridSize, Typography } from '@material-ui/core';
import React from 'react';

interface IProps {
  itemClass?: string;
  rowSize?: boolean | GridSize | undefined;
  handleRowClick?: (id: string) => void;
  rowId?: string;
  useTypography?: boolean;
}
const SavedFiltersRowItem: React.FC<IProps> = ({
  children,
  itemClass,
  rowSize,
  handleRowClick,
  rowId,
  useTypography = true,
}) => {
  return (
    <Grid item md={rowSize}>
      <Grid
        container
        onClick={
          handleRowClick
            ? evt => {
                evt.stopPropagation();
                rowId && handleRowClick(rowId);
              }
            : undefined
        }
      >
        {useTypography ? (
          <Typography className={itemClass || ''}>{children}</Typography>
        ) : (
          <Grid className={itemClass || ''}>{children}</Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SavedFiltersRowItem;
