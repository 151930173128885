import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Stat } from './StatisticsContainer';
import useStatisticStyles from './Statistics.style';

import EmptyBox from '../../../Common/EmptyBox';

interface IProps {
  stat: Stat;
}

const Statistic: React.FC<IProps> = ({ stat }) => {
  const classes = useStatisticStyles();
  return (
    <Grid item container className={classes.root}>
      <Grid item className={classes.header}>
        <Typography style={{ alignContent: 'center' }}>{stat.header}</Typography>
      </Grid>
      <Grid item>
        {stat.statistic ? (
          'Render Data'
        ) : (
          <EmptyBox className={classes.detail}>No Data Available</EmptyBox>
        )}
      </Grid>
    </Grid>
  );
};

export default Statistic;
