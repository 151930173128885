import React from 'react';
import { withStyles } from '@material-ui/styles';
import { createStyles, InputBase, NativeSelect, NativeSelectProps } from '@material-ui/core';

import { primaryBlue, white } from '../../theme';

export const CleoInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      borderRadius: 4,
      border: `1px solid ${primaryBlue}`,
      paddingLeft: '.5rem',
      fontWeight: 'normal',
      // TODO: Font - Use Props
      fontSize: '.875rem',
      '&:focus': {
        borderRadius: 4,
        border: `1px solid ${primaryBlue}`,
        backgroundColor: white,
      },
    },
  }),
)(InputBase);

const CleoSelect: React.FC<NativeSelectProps> = ({ children, ...props }) => {
  return (
    <NativeSelect {...props} input={<CleoInput />}>
      {children}
    </NativeSelect>
  );
};

export default CleoSelect;
