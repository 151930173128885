import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import ErrorIcon from '../../resources/images/LoaderError.svg';
import { hyperlinkBlue } from '../../theme';

type LoaderProps = {
  hasError?: boolean;
  component?: 'table' | 'details' | 'results' | 'list' | undefined;
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    verticalAlign: 'middle',
    width: '100%',
    height: 500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  message: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '25px',
    textDecoration: 'none',
    paddingTop: '1.5rem',
  },
}));

const Loader: React.FC<LoaderProps> = ({ hasError = false, component = 'results' }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {hasError ? (
        <div className={classes.error}>
          <img src={ErrorIcon} alt="Error Icon" width={'100px'} />
          <Typography className={classes.message}>
            Sorry, the {component} couldn&apos;t load at this time. Please
            <a href="." className={classes.message} style={{ color: hyperlinkBlue }}>
              {' '}
              refresh the page{' '}
            </a>
            and try again.
          </Typography>
        </div>
      ) : (
        <div className={classes.error}>
          <div className={'loadingAnimation'} data-testid={'loadingAnimation'} />
          {component === 'table' && (
            <Typography className={classes.message} style={{ marginTop: '10rem' }}>
              The {component} is loading, please allow us a few seconds to complete it.
            </Typography>
          )}
        </div>
      )}
    </Box>
  );
};

export default Loader;
