import React from 'react';
import { Grid, Box, styled } from '@material-ui/core';

import { SectionHeader, Title } from '../../Common/StyleGuideStyles';
import {
  primary,
  primaryBlue,
  darkBlue,
  darkGray,
  black,
  gray,
  secondary,
  light,
  lightGray,
  darkLight,
  success,
  warning,
  error,
  additional,
  info,
} from '../../../theme';

const BoxContainer = styled(Box)({
  height: 200,
  width: 176,
  margin: '10px 22px',
  display: 'flex',
  flexDirection: 'column',
  color: black,
  boxShadow: `none`,
  borderRadius: 6,
  textAlign: 'center',
});

interface ColorProp {
  colorCode: string;
  colorName: string;
}

function ColorBlock({ colorCode, colorName }: ColorProp) {
  return (
    <BoxContainer>
      <div style={{ backgroundColor: colorCode, height: 132 }}></div>
      <div style={{ margin: '10px 0 0 0' }}>{colorName}</div>
      <div>{colorCode}</div>
    </BoxContainer>
  );
}

/**
 * This files servers for representing all the colors used in the application
 */
function ColorStyle(): JSX.Element {
  return (
    <div>
      <SectionHeader>Color</SectionHeader>
      <Title>Primary</Title>
      <Grid container item>
        <ColorBlock colorCode={primary} colorName="Primary" />
        <ColorBlock colorCode={primaryBlue} colorName="Blue" />
        <ColorBlock colorCode={darkBlue} colorName="Dark Blue" />
        <ColorBlock colorCode={black} colorName="Black" />
        <ColorBlock colorCode={darkGray} colorName="Dark Grey" />
        <ColorBlock colorCode={gray} colorName="Gray" />
      </Grid>
      <Title>Secondary</Title>
      <Grid container item>
        <ColorBlock colorCode={secondary} colorName="BG" />
        <ColorBlock colorCode={light} colorName="Light" />
        <ColorBlock colorCode={darkLight} colorName="Dark" />
        <ColorBlock colorCode={lightGray} colorName="Light Gray" />
      </Grid>
      <Title>Semantic</Title>
      <Grid container item>
        <ColorBlock colorCode={info} colorName="Normal" />
        <ColorBlock colorCode={success} colorName="Success (Primary)" />
        <ColorBlock colorCode={warning} colorName="Warning" />
        <ColorBlock colorCode={error} colorName="Error" />
        <ColorBlock colorCode={additional} colorName="Additional" />
      </Grid>
    </div>
  );
}

export default ColorStyle;
