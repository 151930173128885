import React from 'react';
import { Box } from '@material-ui/core';
import type { BoxProps } from '@material-ui/core/Box';

interface IProps {
  withActions?: boolean;
}

const PaperBody: React.FC<IProps> = ({ withActions = false, children }) => {
  const boxProps: BoxProps = { p: 6 };
  if (withActions) {
    boxProps.pb = 4;
  }

  return <Box {...boxProps}>{children}</Box>;
};

export default PaperBody;
