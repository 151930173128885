import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import type { BoxProps } from '@material-ui/core/Box';
import clsx from 'clsx';

import { light, darkGray } from '../../theme';

interface IProps extends BoxProps {
  dense?: boolean;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f9fbfd',
    border: `1px solid ${light}`,
    borderRadius: 8,
    color: darkGray,
    fontSize: '0.875rem',
  },
});

const EmptyBox: React.FC<IProps> = ({ dense, className, ...props }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, className)}
      p={dense ? 2 : 5}
      textAlign="center"
      {...props}
    />
  );
};

export default EmptyBox;
