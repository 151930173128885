import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { RenderAccountIncIcon, renderAccountRatingIcon } from './AccountIconRenderer';

import { lightGray } from '../../../theme';
import accountRatingA from '../../../resources/images/Accounts/AccountRatingAIcon.svg';
import accountRatingB from '../../../resources/images/Accounts/AccountRatingBIcon.svg';
import accountRatingC from '../../../resources/images/Accounts/AccountRatingCIcon.svg';
import accountIncA from '../../../resources/images/Accounts/AccountsIncAIcon.svg';
import accountIncB from '../../../resources/images/Accounts/AccountsIncBIcon.svg';
import accountIncC from '../../../resources/images/Accounts/AccountsIncCIcon.svg';
import accountIncNoRating from '../../../resources/images/Accounts/AccountsIncNoRatingIcon.svg';
import nationalAccountIcon from '../../../resources/images/Accounts/AccountNationalIcon.svg';
import targetNationalAccountIcon from '../../../resources/images/Accounts/NationalAccountTargeted.svg';

export enum AccountRatingColor {
  ratingA = '#BCBDC0',
  ratingB = '#1FB4B1',
  ratingC = '#4E9AF3',
  noRating = '#999AF6',
  nationalAccount = '#DAA520',
}

const useOrgIncAndRatingIconsStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px',
  },
  icon: {
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    padding: '.3rem',
  },
  incremental: {
    height: '30px',
    width: '42px',
    paddingRight: '.1rem',
  },
  rating: {
    borderLeft: `1px solid ${lightGray}`,
    height: '30px',
    width: '30px',
  },
});

interface IOrgIncAndRatingIcons {
  incLength: number;
  sfRating: string;
  isNationalAccount: boolean;
  isTargetedNationalAccount: boolean;
}

const AccountIcons: React.FC<IOrgIncAndRatingIcons> = ({
  incLength,
  sfRating,
  isNationalAccount,
  isTargetedNationalAccount,
}) => {
  const classes = useOrgIncAndRatingIconsStyles();
  if (!incLength && !sfRating && !isNationalAccount) {
    return null;
  }
  const icons: JSX.Element[] = [];

  if (isTargetedNationalAccount) {
    icons.push(
      <img
        style={{ height: '25px' }}
        src={targetNationalAccountIcon}
        alt={'Targeted National Account'}
      />,
    );
  } else if (isNationalAccount) {
    icons.push(
      <img style={{ height: '25px' }} src={nationalAccountIcon} alt={'National Account'} />,
    );
  }
  let ratingIcon: string | null;
  let ratingAlt: string;
  let incIcon: string;
  switch (sfRating) {
    case 'A':
      ratingIcon = accountRatingA;
      ratingAlt = 'A';
      incIcon = accountIncA;
      break;
    case 'B':
      ratingIcon = accountRatingB;
      ratingAlt = 'B';
      incIcon = accountIncB;
      break;
    case 'C':
      ratingIcon = accountRatingC;
      ratingAlt = 'C';
      incIcon = accountIncC;
      break;
    default:
      ratingAlt = 'No Grade';
      ratingIcon = null;
      incIcon = accountIncNoRating;
      break;
  }

  if (incLength) {
    icons.push(<RenderAccountIncIcon incLength={incLength} incIcon={incIcon} size={'26px'} />);
  }

  if (ratingIcon) {
    icons.push(renderAccountRatingIcon({ ratingIcon, ratingAlt, size: '30px' }));
  }

  return (
    <Grid className={classes.root}>
      {icons.map((icon, idx) => {
        return (
          <Grid
            key={`account-icon-${idx}`}
            className={classes.icon}
            style={{ borderTop: idx ? `1px solid ${lightGray}` : '' }}
          >
            {icon}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AccountIcons;
