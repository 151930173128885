import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Avatar, Grid, Paper, Theme, Typography } from '@material-ui/core';
import LeadIcon from '@material-ui/icons/CalendarViewDay';

import type { Property } from '../../../../models';
import { primaryBlue } from '../../../../theme';
import { getLeadDetailsPagePath } from '../../../../utils/page';

interface IProps {
  property: Property;
  clickable?: boolean;
}

const useStyles = makeStyles<Theme, IProps>(theme => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5),
    borderLeft: `6px solid ${primaryBlue}`,
    borderRadius: 8,
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'auto'),
    '&:hover': {
      backgroundColor: ({ clickable }) => (clickable ? theme.palette.secondary.main : 'inherit'),
    },
  },
  container: {
    flexWrap: 'nowrap',
  },
  dense: {
    lineHeight: '1.1rem',
  },
  avatar: {
    backgroundColor: primaryBlue,
  },
}));

const LeadCard: React.FC<IProps> = ({ property, clickable = true }) => {
  const {
    id,
    shortLeadName,
    address1,
    address2,
    city,
    state,
    activeProject,
    totalStakeholders,
    totalEvents,
  } = property;
  const classes = useStyles({ property, clickable });
  return (
    <Paper
      className={classes.root}
      component={clickable ? props => <Link to={getLeadDetailsPagePath(id)} {...props} /> : 'div'}
    >
      <Grid className={classes.container} container spacing={2} alignItems="center">
        <Grid item>
          <Avatar className={classes.avatar}>
            <LeadIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography className={classes.dense} color="primary">
            <strong>Lead:</strong> {shortLeadName}
          </Typography>
          <Typography className={classes.dense} color="secondary" variant="body2">
            {address1}
            {address2 ? ` ${address2}` : ''}, {city}, {state}
          </Typography>
          <Typography className={classes.dense} color="primary" variant="body2">
            {!!activeProject && '1 Project | '}
            {totalStakeholders} {totalStakeholders === 1 ? 'Contact' : 'Contacts'}
            {' | '}
            {totalEvents} {totalEvents === 1 ? 'Event' : 'Events'}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LeadCard;
