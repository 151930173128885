import React, { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Grid } from '@material-ui/core';

import {
  allFilterTagsState,
  clearFiltersState,
  filterTagsToDisplayState,
  previousWinSelectedState,
  searchCountyValueState,
  searchKeywordValueState,
  searchProvinceValueState,
  searchRegionValueState,
  searchSpecAlertValueState,
  searchStateValueState,
} from '../../../../state/atoms/filters';
import { FilterData, PreviousWinFilterTag } from '../../../../types';
import { StyledFilterItemButtons, useLeadsStyles } from '../Leads.styles';
import { black, darkBlue, primaryBlue, white } from '../../../../theme';
import {
  defaultSpecRateSelectedKeys,
  specRateFilterTagsState,
  specRateSelectedKeysState,
} from '../../../../state/atoms/filterSpecRates';

interface IProps {
  allKeysSelected: FilterData | undefined;
  handleFilterChange: (filterData: FilterData) => void;
  editedFilterNameError?: string;
}

const LeadsFilterButtons: React.FC<IProps> = ({
  allKeysSelected,
  handleFilterChange,
  editedFilterNameError,
}: IProps) => {
  const classes = useLeadsStyles();

  const setClearFilters = useSetRecoilState(clearFiltersState);
  const setSearchStateValue = useSetRecoilState(searchStateValueState);
  const setSearchCountyValue = useSetRecoilState(searchCountyValueState);
  const setSearchRegionValue = useSetRecoilState(searchRegionValueState);
  const setSearchProvinceValue = useSetRecoilState(searchProvinceValueState);
  const setSearchSpecAlertValue = useSetRecoilState(searchSpecAlertValueState);
  const setSearchKeywordValue = useSetRecoilState(searchKeywordValueState);
  const setFilterTagsToDisplay = useSetRecoilState(filterTagsToDisplayState);
  const setSpecRateSelectedKeys = useSetRecoilState(specRateSelectedKeysState);

  const allFilterTags = useRecoilValue(allFilterTagsState);
  const specRateFilterTags = useRecoilValue(specRateFilterTagsState);
  const previousWinSelected = useRecoilValue(previousWinSelectedState);

  const clearAllSearchValues = useCallback(async (): Promise<void> => {
    setSearchStateValue('');
    setSearchCountyValue('');
    setSearchRegionValue('');
    setSearchProvinceValue('');
    setSearchKeywordValue('');
    setSearchSpecAlertValue('');
  }, [
    setSearchStateValue,
    setSearchCountyValue,
    setSearchRegionValue,
    setSearchProvinceValue,
    setSearchKeywordValue,
    setSearchSpecAlertValue,
  ]);

  const previousWinFilterTag = PreviousWinFilterTag;

  return (
    <StyledFilterItemButtons className={classes.filterItemButtonsContainer}>
      <Grid item className={classes.filterItemButtons}>
        <Button
          style={
            editedFilterNameError
              ? { color: white, backgroundColor: darkBlue, fontSize: '.9rem' }
              : { color: black, backgroundColor: white, fontSize: '.9rem' }
          }
          variant="contained"
          size="medium"
          onClick={() => {
            allKeysSelected && handleFilterChange(allKeysSelected);
            if (previousWinSelected) {
              setFilterTagsToDisplay([
                ...allFilterTags,
                ...specRateFilterTags,
                previousWinFilterTag,
              ]);
            } else {
              setFilterTagsToDisplay([...allFilterTags, ...specRateFilterTags]);
            }
            clearAllSearchValues();
          }}
          className={classes.filterItemButton}
        >
          APPLY
        </Button>
      </Grid>
      <Grid item className={classes.filterItemButtons}>
        <Button
          disableRipple
          size="medium"
          color="secondary"
          variant="text"
          style={
            editedFilterNameError
              ? { border: '1px solid #0C4377', fontSize: '.9rem', color: primaryBlue }
              : { border: '1px solid white', fontSize: '.9rem' }
          }
          onClick={() => {
            setClearFilters(true);
            allKeysSelected && handleFilterChange(allKeysSelected);
            setFilterTagsToDisplay(allFilterTags);
            setSpecRateSelectedKeys(defaultSpecRateSelectedKeys);
          }}
          className={classes.filterItemButton}
        >
          CLEAR
        </Button>
      </Grid>
    </StyledFilterItemButtons>
  );
};

export default LeadsFilterButtons;
