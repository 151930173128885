import { atom, selector } from 'recoil';

import { Comparators, FilterData, FilterKey, FilterValue, TagsDisplay } from '../../types';

export type specRateFilterKey =
  | FilterKey.ArmstrongSpecRate
  | FilterKey.USGSpecRate
  | FilterKey.CertainteedSpecRate
  | FilterKey.RockfonSpecRate;

export const specRateOptions = [
  { label: 'Armstrong', value: 'Armstrong Rate', filterKey: FilterKey.ArmstrongSpecRate },
  { label: 'USG', value: 'USG Rate', filterKey: FilterKey.USGSpecRate },
  { label: 'CertainTeed', value: 'CertainTeed Rate', filterKey: FilterKey.CertainteedSpecRate },
  { label: 'Rockfon', value: 'Rockfon Rate', filterKey: FilterKey.RockfonSpecRate },
] as { label: string; value: string; filterKey: specRateFilterKey }[];

const defaultSpecRateComparator = Comparators.Gte;
const defaultSpecRateValue = '';

export const defaultSpecRateFilterValue: FilterValue = {
  [defaultSpecRateComparator]: defaultSpecRateValue,
};
Object.freeze(defaultSpecRateFilterValue);

export const defaultSpecRateSelectedKeys = {} as FilterData;
specRateOptions.forEach(({ filterKey }) => {
  defaultSpecRateSelectedKeys[filterKey as specRateFilterKey] = { ...defaultSpecRateFilterValue };
});
Object.freeze(defaultSpecRateSelectedKeys);

/**
 * Handles state of spec rate filters used in accounts
 * */
export const specRateSelectedKeysState = atom({
  key: 'specRateSelectedKeys',
  default: defaultSpecRateSelectedKeys,
});

/**
 * Derived state used when applying filters
 * */
export const normalizedSpecRateSelectedKeysState = selector({
  key: 'normalizedSpecRateSelectedKeys',
  get: ({ get }) => {
    // Derived state
    // Remove any filter that is gte: 0 (default state)
    // Convert percentage into decimal for filtering on BE

    const filter = get(specRateSelectedKeysState);

    const normalizedFilter = {} as FilterData;
    Object.keys(filter).forEach(key => {
      const [[comp, value]] = Object.entries(filter[key]);
      if (!(comp === defaultSpecRateComparator && value === defaultSpecRateValue)) {
        // not default case, use for filter
        normalizedFilter[key] = { [comp]: (value as number) / 100 };
      }
    });
    return normalizedFilter;
  },
});

/**
 * Filter tags derived from normalized state to decide which tags to show
 * */
export const specRateFilterTagsState = selector({
  key: 'specRateFilterTags',
  get: ({ get }) => {
    const normalizedSpecRateFilters = get(normalizedSpecRateSelectedKeysState);
    const filterTags = [] as TagsDisplay[];
    Object.keys(normalizedSpecRateFilters).forEach(key => {
      const [[comp, value]] = Object.entries(normalizedSpecRateFilters[key]);
      const tagValue =
        comp === Comparators.Gte
          ? `>= ${Math.round((value as number) * 100)}%`
          : `<= ${Math.round((value as number) * 100)}%`;

      filterTags.push({
        label: specRateOptions.filter(option => option.filterKey === key)[0].label as string,
        key: key,
        value: tagValue,
        comparator: comp,
        filterValue: value,
        isInput: false,
        id: FilterKey.SpecRates, // Use to identify any spec rate tags
      });
    });
    return filterTags;
  },
});
