import React, { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react';
import { GridProps, Box, Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import useTableStyles from './Table.styles';
import TableHeader from './TableHeader';
import TableRow, { handleClickRow } from './TableRow';

import { ScoreStatus } from '../../../models/lead';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Loader from '../Loader';
import { IResponse } from '../../../api';
import { FilterData } from '../../../types';
import { SortArchive, SortOrder } from '../../Pages/Leads/Archive/Archive.config';
import { renderFunctions } from '../../Pages/Leads/LeadsTable/LeadTableRowRenderers';
import { NoRecordBlock } from '../../Pages/Leads/LeadsTable/LeadsTable.styles';

export type renderRow<K> = {
  function: renderFunctions;
  param: (keyof K)[];
  additionalParam?: (boolean | string | number)[];
  noData: '' | 0;
  reloadItems?: boolean;
  style?: React.CSSProperties;
  isConverted?: boolean;
  addAdditionalParam?: boolean;
};

export type HeadCell<T, K> = {
  id: keyof T;
  label: ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  gridProps: GridProps;
  headerStyle?: React.CSSProperties;
  disableSort?: boolean;
  renderRow: renderRow<K>;
};

export interface GetViewFilterRequest<T> {
  sort: SortArchive<T>;
  startIndex: number;
  filterOption?: FilterData;
}

export type reloadItems = () => Promise<void>;

interface ITableProps<T, K> {
  apiCall?: (params: GetViewFilterRequest<T>) => Promise<IResponse<any>>;
  headerCells: HeadCell<T, K>[];
  order?: SortOrder;
  orderBy?: string;
  rowCount: number;
  rows: K[];
  scoreStatus: ScoreStatus;
  startIndex: number;
  hasMoreItems: boolean;
  onRequestSort?: (event: MouseEvent<unknown>, property: keyof T) => void;
  setStartIndex: Dispatch<SetStateAction<number>>;
  handleClickRow: handleClickRow<K>;
  reloadItems?: reloadItems;
  errorMessage?: string;
  isNewKey?: string;
  isNationalKey?: keyof K;
  isSortable?: boolean;
  limit?: number;
  emptyListString?: string;
  handleCheckbox?: (row: K) => void;
  bulkDemoteButtonClicked?: boolean;
  hasError: boolean;
}
// We use functional composition to include generic types in the generic Table
function Table<T, K extends { id: string }>(props: ITableProps<T, K>) {
  const {
    headerCells,
    order,
    orderBy,
    rows,
    scoreStatus,
    hasMoreItems,
    setStartIndex,
    onRequestSort,
    handleClickRow,
    reloadItems,
    errorMessage = undefined,
    isNewKey = '',
    isNationalKey = undefined,
    isSortable = true,
    limit,
    emptyListString = 'No Data To Display',
    handleCheckbox,
    bulkDemoteButtonClicked,
    hasError,
  } = props;
  const classes = useTableStyles();

  return (
    <Box mb={2} borderRadius={2}>
      <TableHeader
        headerCells={headerCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        isSortable={isSortable}
        bulkDemoteButtonClicked={bulkDemoteButtonClicked}
      />
      <div id="scrollableDiv" className={classes.infiniteScrollContainer}>
        <InfiniteScroll
          dataLength={rows.length}
          next={() =>
            setStartIndex((startIndex: number): number => startIndex + (limit || DEFAULT_PAGE_SIZE))
          }
          hasMore={hasMoreItems}
          loader={<Loader hasError={hasError} component={'table'} />}
          scrollableTarget="scrollableDiv"
        >
          <Grid container direction="column" className={classes.table}>
            {rows.map((row, i) => {
              const id = 'id' in row ? row.id : '';
              return (
                <div key={`${scoreStatus}-${id}-${i}`} className={classes.rowContainer}>
                  <TableRow
                    row={row}
                    scoreStatus={scoreStatus}
                    headerCells={headerCells}
                    handleClickRow={handleClickRow}
                    reloadItems={reloadItems}
                    isNewKey={isNewKey}
                    isNationalKey={isNationalKey}
                    isOdd={i % 2 !== 0}
                    handleCheckbox={handleCheckbox}
                    bulkDemoteButtonClicked={bulkDemoteButtonClicked}
                  />
                </div>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </div>
      {(!!errorMessage || (!hasMoreItems && rows.length === 0)) && (
        <NoRecordBlock>{errorMessage || emptyListString}</NoRecordBlock>
      )}
    </Box>
  );
}

export default Table;
