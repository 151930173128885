import React from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import FormSelect from '../../../Common/FormSelect';
import theme, { darkBlue, hyperlinkBlue, primaryBlue, secondary, white } from '../../../../theme';
import { ProjectIncrementalScoreMapping } from '../../../../constants';
import FormTextField from '../../../Common/FormTextField';
import { renderProjectScore } from '../../Leads/LeadsTable/LeadTableRowRenderers';
import salesforceLogo from '../../../../resources/images/salesforce.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  banner: {
    backgroundColor: secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.5rem 0',
  },
  score: { width: '80px' },
  selectLabel: {
    color: darkBlue,
    fontSize: '0.75rem',
  },
  qualifications: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  sectionBox: {
    marginBottom: theme.spacing(6),
    justifyContent: 'center',
    display: 'contents',
  },
  enterData: {
    padding: theme.spacing(4, 8, 5, 8),
    color: darkBlue,
    width: '90%',
  },
  buttonsContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    padding: '.5rem 2rem',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface IProjectStatusConvertModalProps {
  reasons: Record<string, string>;
  name: string;
  score: number;
  handleClose: () => void;
}

const ProjectStatusConvertModal: React.FC<IProjectStatusConvertModalProps> = ({
  reasons,
  name,
  score,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.banner}>
        <Grid className={classes.score}>
          {renderProjectScore(score, hyperlinkBlue, 30, 60, '18px')}
        </Grid>
        <Typography variant={'subtitle1'}>{name}</Typography>
      </Grid>
      <Grid item className={classes.qualifications}>
        <FormSelect
          className={classes.selectLabel}
          fullWidth
          name="reason"
          label={<span className={classes.selectLabel}>Primary Reason</span>}
          options={reasons}
        />
      </Grid>
      <Grid item className={classes.qualifications}>
        <FormSelect
          fullWidth
          label={<span className={classes.selectLabel}>Incremental Reason & Score</span>}
          name="score"
          options={ProjectIncrementalScoreMapping}
        />
      </Grid>
      <Grid item className={classes.sectionBox}>
        <Grid container direction="column" component={Box} textAlign="center">
          <Grid item className={classes.qualifications}>
            <FormTextField
              fontWeight="normal"
              fontColor={primaryBlue}
              label={<span className={classes.selectLabel}>Add Transition Note</span>}
              name="note"
              multiline
              fullWidth
              rows={1}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          style={{
            backgroundColor: white,
            color: primaryBlue,
          }}
          onClick={handleClose}
        >
          <Typography variant={'subtitle1'}>Cancel</Typography>
        </Button>
        <Button
          className={classes.button}
          style={{
            backgroundColor: primaryBlue,
            color: white,
          }}
          type="submit"
        >
          <Typography variant={'subtitle1'}>Convert</Typography>
        </Button>
      </Grid>
      <Grid item className={clsx(classes.qualifications, classes.center)}>
        <img src={salesforceLogo} alt="Salesforce logo" />
      </Grid>
    </div>
  );
};

export default ProjectStatusConvertModal;
