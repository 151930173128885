import { BaseModel } from '.';

import { IBaseModelResponse } from './baseModel';

export type IDodgeProjectLeadResponse = IBaseModelResponse & {
  event_id: string;
  property_id: string;
  date_of_first_export?: Date;
  date_of_last_export?: Date;
  date_of_current_export?: Date;
  first_issue_date?: Date;
  last_issue_date?: Date;
  report_date?: Date;
  primary_stage?: string;
  secondary_stage?: string;
  bid_date?: Date;
  submit_bids_to?: string;
  target_start_date?: Date;
  target_completion_date?: Date;
  valuation_est_low?: string;
  valuation_est_high?: string;
  valuation?: string;
  market_segment?: string;
  primary_project_type?: string;
  secondary_project_type?: string;
  total_sqft?: string;
  type_of_work?: string;
  ownership_type?: string;
  status_text?: string;
  bid_bond?: string;
  special_code?: string;
  plans_available_from?: string;
  architectural_plans_by?: string;
  structural_plans_by?: string;
  plans_remark?: string;
  plans_location?: string;
  plans_available?: string;
  delivery_system?: string;
  frame_type?: string;
  stories_above?: string;
  stories_below?: string;
  no_of_buildings?: string;
  structural_info?: string;
  spec_alerts?: string;
  search_names?: string;
  created_at?: string;
  updated_at?: string;
  project_note?: string;
  project_title?: string;
  spec_available?: string;
  features_info?: string;
  project_url?: string;
};

export default class DodgeProjectLead extends BaseModel {
  eventId: string;
  propertyId: string;
  dateOfFirstExport?: Date;
  dateOfLastExport?: Date;
  dateOfCurrentExport?: Date;
  firstIssueDate?: Date;
  lastIssueDate?: Date;
  reportDate?: Date;
  primaryStage?: string;
  secondaryStage?: string;
  bidDate?: Date;
  submitBidsTo?: string;
  targetStartDate?: Date;
  targetCompletionDate?: Date;
  valuationEstLow?: string;
  valuationEstHigh?: string;
  valuation?: string;
  marketSegment?: string;
  primaryProjectType?: string;
  secondaryProjectType?: string;
  totalSqft?: string;
  typeOfWork?: string;
  ownershipType?: string;
  statusText?: string;
  bidBond?: string;
  specialCode?: string;
  plansAvailableFrom?: string;
  architecturalPlansBy?: string;
  structuralPlansBy?: string;
  plansRemark?: string;
  plansLocation?: string;
  plansAvailable?: string;
  deliverySystem?: string;
  frameType?: string;
  storiesAbove?: string;
  storiesBelow?: string;
  noOfBuildings?: string;
  structuralInfo?: string;
  specAlerts?: string;
  searchNames?: string;
  projectNote?: string;
  projectTitle?: string;
  specAvailable?: string;
  features_info?: string;
  projectUrl?: string;
  constructor(data: IDodgeProjectLeadResponse) {
    super(data);
    const {
      event_id,
      property_id,
      date_of_first_export,
      date_of_last_export,
      date_of_current_export,
      first_issue_date,
      last_issue_date,
      report_date,
      primary_stage,
      secondary_stage,
      bid_date,
      bid_bond,
      submit_bids_to,
      target_start_date,
      target_completion_date,
      valuation_est_low,
      valuation_est_high,
      valuation,
      market_segment,
      primary_project_type,
      secondary_project_type,
      total_sqft,
      type_of_work,
      ownership_type,
      status_text,
      special_code,
      plans_available_from,
      architectural_plans_by,
      structural_plans_by,
      plans_remark,
      plans_location,
      plans_available,
      delivery_system,
      frame_type,
      stories_above,
      stories_below,
      no_of_buildings,
      structural_info,
      spec_alerts,
      search_names,
      project_note,
      project_title,
      spec_available,
      features_info,
      project_url,
    } = data;

    this.eventId = event_id;
    this.propertyId = property_id;
    this.dateOfFirstExport = date_of_first_export;
    this.dateOfLastExport = date_of_last_export;
    this.dateOfCurrentExport = date_of_current_export;
    this.firstIssueDate = first_issue_date;
    this.lastIssueDate = last_issue_date;
    this.reportDate = report_date;
    this.primaryStage = primary_stage;
    this.secondaryStage = secondary_stage;
    this.bidDate = bid_date;
    this.submitBidsTo = submit_bids_to;
    this.targetStartDate = target_start_date;
    this.targetCompletionDate = target_completion_date;
    this.valuationEstLow = valuation_est_low;
    this.valuation = valuation;
    this.valuationEstHigh = valuation_est_high;
    this.marketSegment = market_segment;
    this.primaryProjectType = primary_project_type;
    this.secondaryProjectType = secondary_project_type;
    this.totalSqft = total_sqft;
    this.typeOfWork = type_of_work;
    this.ownershipType = ownership_type;
    this.statusText = status_text;
    this.bidDate = bid_date;
    this.bidBond = bid_bond;
    this.specialCode = special_code;
    this.plansAvailableFrom = plans_available_from;
    this.architecturalPlansBy = architectural_plans_by;
    this.structuralPlansBy = structural_plans_by;
    this.plansRemark = plans_remark;
    this.plansLocation = plans_location;
    this.plansAvailable = plans_available;
    this.deliverySystem = delivery_system;
    this.frameType = frame_type;
    this.storiesAbove = stories_above;
    this.storiesBelow = stories_below;
    this.noOfBuildings = no_of_buildings;
    this.structuralInfo = structural_info;
    this.specAlerts = spec_alerts;
    this.searchNames = search_names;
    this.projectNote = project_note;
    this.projectTitle = project_title;
    this.specAvailable = spec_available;
    this.features_info = features_info;
    this.projectUrl = project_url;
  }
}
