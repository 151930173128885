import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Typography,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { Form, Formik } from 'formik';

import ProjectLeadDetailsHeader from './ProjectLeadDetailsHeader';
import {
  addressTabsOnProjectLeadDetails,
  projectConvertedHeadCellsOnAddressTab,
  projectDemotedHeadCellsOnAddressTab,
  projectLeadDetailsHeadCellsOnAddressTab,
  ProjectLeadDetailsMenu,
} from './ProjectLeadDetails.config';
import { useProjectLeadDetailsStyles } from './ProjectLeadDetails.styles';
import PLDetailsTab from './ProjectLeadDetailsTab/PLDetailsTab';
import ProjectLeadDocuments from './ProjectLeadDocuments/ProjectLeadDocuments';

import StepIndicator, { Stages } from '../../Common/StepIndicator';
import { getProjectDetails } from '../../../api/projects';
import { IProjectLeadDetails, ProjectLeadsStatusTab } from '../../../models/projectLead';
import { ProjectStatus, SourceType } from '../../../models/common';
import EmptyBox from '../../Common/EmptyBox';
import { toTitleCase } from '../../../utils/string';
import theme, { darkLight, primaryBlue } from '../../../theme';
import { useMessages } from '../../../state/contexts';
import { getPropertyById } from '../../../api/properties';
import { Project, Property } from '../../../models';
import CircularProgressWithLabel from '../../Common/CircularProgressWithLabel';
import { googleSearchQuery } from '../../../utils/map';
import { getFullAddress } from '../../../utils/address';
import TitleBorder from '../../Common/TitleBorder';
import Table from '../../Common/Tables/Table';
import { SortOrder } from '../Leads/Archive/Archive.config';
import { ScoreStatus } from '../../../models/lead';
import PvfProject from '../../../models/pvfProject';
import { getProjectLeadsDetailsPagePath } from '../../../utils/page';
import FormTextField from '../../Common/FormTextField';
import { TabStylesProps } from '../Leads/LeadsTable/StatusBar';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { getLocalDateFromUtcTimestamp } from '../../../utils/date';

interface IRouteParams {
  projectId: string;
}

const tabStyles = makeStyles({
  root: {
    zIndex: 10,
    bottom: '-.1rem',
    color: darkLight,
    fontWeight: 'normal',
    width: '11rem',
    fontSize: (props?: TabStylesProps) => (props?.fontSize ? props.fontSize : '1.21rem'),
    '&:hover': {
      color: `${primaryBlue} !important`,
      borderRadius: 5,
    },
  },
  selected: {
    fontWeight: 'bold',
    // backgroundColor: light,
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    height: 10,
    overflow: 'hidden',
    borderBottom: `6px solid ${primaryBlue}`,
    paddingBottom: '0rem',
  },
  wrapper: {
    border: 'none',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
});

const ProjectLeadDetails: React.FC = () => {
  const { projectId } = useParams<IRouteParams>();
  const history = useHistory();
  const scoreStatus = ScoreStatus.Home;
  const classes = useProjectLeadDetailsStyles();
  const { setPageLoading } = useMessages();
  const [plDetails, setPlDetails] = useState<IProjectLeadDetails>({});
  const [addressDetails, setAddressDetails] = useState<Property>();
  const [tabValue, setTabValue] = useState(ProjectLeadsStatusTab.Details);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [order, setOrder] = useState<SortOrder>('desc');
  const [orderBy, setOrderBy] = useState<keyof PvfProject>('projectScore');
  const [startIndex, setStartIndex] = useState<number>(0);
  const [hasMoreActiveItems, setHasMoreActiveItems] = useState<boolean>(true);
  const [hasMoreConvertedItems, setHasMoreConvertedItems] = useState<boolean>(true);
  const [hasMoreDemotedItems, setHasMoreDemotedItems] = useState<boolean>(true);
  const [activeProjectRows, setActiveProjectRows] = useState<PvfProject[]>([]);
  const [convertedProjectRows, setConvertedProjectRows] = useState<Project[]>([]);
  const [demotedProjectRows, setDemotedProjectRows] = useState<Project[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);
  const [editFormIsDirty, setEditFormIsDirty] = useState<boolean>(false);
  const [addressTab, setAddressTab] = useState<ScoreStatus>(ScoreStatus.Active);

  const handleClickRow = {
    function: (projectId: string) => {
      history.push(getProjectLeadsDetailsPagePath(projectId));
    },
    to: (projectId: string) => getProjectLeadsDetailsPagePath(projectId),
    param: 'id' as keyof PvfProject,
    link: '',
  };

  const handleSortRequest = useCallback(
    (e: React.MouseEvent<unknown>, property: keyof PvfProject) => {
      e.preventDefault();
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setStartIndex(0);
      setActiveProjectRows([]);
    },
    [order, orderBy, setOrder, setOrderBy, setStartIndex],
  );

  const loadProjectLeadDetails = useCallback(async () => {
    setPageLoading(true);
    try {
      const projectDetails = await getProjectDetails(projectId);
      const propertyDetails = await getPropertyById(projectDetails.projectDetails?.property_id);
      setPlDetails(projectDetails);
      setAddressDetails(propertyDetails);
      setActiveProjectRows([projectDetails.pvf]);
      setHasMoreActiveItems((): boolean => {
        const activeItems = propertyDetails?.projects
          ? propertyDetails.projects.filter(project => project.status === ScoreStatus.Active)
          : [];
        if (activeItems.length > DEFAULT_PAGE_SIZE) {
          return true;
        } else {
          return false;
        }
      });
      setConvertedProjectRows((): Project[] => {
        const convertedProjects = propertyDetails?.projects
          ? propertyDetails.projects.filter(project => project.status === ScoreStatus.Converted)
          : [];
        return convertedProjects;
      });
      setHasMoreConvertedItems((): boolean => {
        const convertedItems = propertyDetails?.projects
          ? propertyDetails.projects.filter(project => project.status === ScoreStatus.Converted)
          : [];
        if (convertedItems.length > DEFAULT_PAGE_SIZE) {
          return true;
        } else {
          return false;
        }
      });
      setDemotedProjectRows((): Project[] => {
        const demotedProjects = propertyDetails?.projects
          ? propertyDetails.projects.filter(project => project.status === ScoreStatus.Demoted)
          : [];
        return demotedProjects;
      });
      setHasMoreActiveItems((): boolean => {
        const demotedItems = propertyDetails?.projects
          ? propertyDetails.projects.filter(project => project.status === ScoreStatus.Demoted)
          : [];
        if (demotedItems.length > DEFAULT_PAGE_SIZE) {
          return true;
        } else {
          return false;
        }
      });
    } catch (e: any) {
      setErrorMessage(e.message);
    }
    setPageLoading(false);
  }, [projectId, setPageLoading]);

  const handleTabClick = useCallback(
    (e, nextTab: ProjectLeadsStatusTab) => {
      e.preventDefault();
      setTabValue(nextTab);
    },
    [setTabValue],
  );

  const reloadItems = useCallback(async () => {
    setActiveProjectRows([]);
    setStartIndex(0);
    setHasMoreActiveItems(true);
    setHasMoreConvertedItems(true);
    setHasMoreDemotedItems(true);
    await loadProjectLeadDetails();
  }, [loadProjectLeadDetails]);

  const handleEditAddressButton = useCallback(() => {
    setIsEditingAddress(true);
  }, []);

  const handleCancelEditAddressButton = useCallback(() => {
    setIsEditingAddress(false);
  }, []);

  // TODO: the parameter `values` can be used here to send form data to the API
  const handleSaveAddress = useCallback(() => {
    setIsEditingAddress(false);
  }, []);

  const handleAddressTabChange = useCallback((e, nextTab: ScoreStatus) => {
    e.preventDefault();
    setAddressTab(nextTab);
  }, []);

  useEffect(() => {
    loadProjectLeadDetails();
  }, [loadProjectLeadDetails]);

  useEffect(() => {
    setIsFavorite(plDetails?.pvf?.isFavorite || false);
  }, [setIsFavorite, plDetails]);

  const details = plDetails?.projectDetails;
  const projectStatus = (plDetails?.projectDetails?.status as ProjectStatus) || undefined;
  const activeStatus = plDetails?.projectDetails?.status === ProjectStatus.Active;
  const projectUrl = plDetails?.pvf?.projectUrl;
  const dataSource = plDetails?.pvf?.dataSource as SourceType;
  const mappedActionStage = plDetails?.pvf?.mappedActionStage;
  const bidDate = plDetails?.pvf?.bidDate
    ? new Intl.DateTimeFormat('en-US').format(new Date(plDetails?.pvf?.bidDate))
    : '';

  const tabSize = theme.typography.h5.fontSize;

  return (
    <Paper className={classes.root}>
      <Container>
        <Grid className={classes.header}>
          <ProjectLeadDetailsHeader
            refreshPage={loadProjectLeadDetails}
            projectId={details?.id}
            score={details?.score || 0}
            projectName={details?.name || 'No Project Name'}
            projectStatus={projectStatus}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
          />
        </Grid>

        <Grid className={classes.tabsRoot}>
          <TabContext value={tabValue}>
            <Box className={classes.tabsContainer}>
              <TabList
                onChange={handleTabClick}
                aria-label="project details tabs"
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                {ProjectLeadDetailsMenu.map((tabItem, idx) => {
                  // Disable documents tab if no source (possibly add cmd into logic)
                  const disabled =
                    tabItem.disabled ||
                    (tabItem.text === toTitleCase(ProjectLeadsStatusTab.Documents) &&
                      ![SourceType.Dodge].includes(dataSource || ''));
                  return (
                    <Tab
                      key={`${tabItem.text}-${idx}`}
                      classes={tabStyles({ fontSize: tabSize })}
                      style={{ padding: '0 3rem' }}
                      label={tabItem.text}
                      value={tabItem.status}
                      disabled={disabled}
                    />
                  );
                })}
              </TabList>
              <Grid style={{ display: 'flex', alignItems: 'flex-start', width: '25rem' }}>
                <StepIndicator stage={mappedActionStage as Stages} bidDate={bidDate} />
              </Grid>
            </Box>
            {plDetails?.pvf?.viewed && (
              <Box className={classes.viewed}>
                <Typography variant={'subtitle1'}>{`Viewed On ${getLocalDateFromUtcTimestamp(
                  plDetails?.projectDetails?.updated_at,
                )}`}</Typography>
              </Box>
            )}

            <Box className={classes.tabPanelContainer}>
              <TabPanel value={ProjectLeadsStatusTab.Details} className={classes.tabPanel}>
                {activeStatus ? (
                  <PLDetailsTab plDetails={plDetails} />
                ) : (
                  <>
                    <EmptyBox>
                      {toTitleCase(`This Project Has Already Been ${projectStatus}`)}
                    </EmptyBox>
                    <PLDetailsTab plDetails={plDetails} />
                  </>
                )}
              </TabPanel>
              <TabPanel value={ProjectLeadsStatusTab.Addresses} className={classes.tabPanel}>
                <Grid className={classes.transparentBorder}>
                  <Grid className={clsx(classes.title, classes.boxedScore)}>
                    <CircularProgressWithLabel size={50} value={addressDetails?.score || 0} />
                  </Grid>
                  <Typography className={clsx(classes.title, classes.boxedTitle)}>
                    Address Details
                  </Typography>
                  <Grid className={classes.itemRight}>
                    {!isEditingAddress ? (
                      <Button
                        className={classes.editButton}
                        onClick={handleEditAddressButton}
                        disabled={true}
                      >
                        Edit
                      </Button>
                    ) : (
                      <div>
                        <Button
                          className={classes.editButton}
                          onClick={handleCancelEditAddressButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.editButton}
                          onClick={handleSaveAddress}
                          disabled={editFormIsDirty}
                        >
                          Save Changes
                        </Button>
                      </div>
                    )}
                  </Grid>
                  <Grid className={classes.border}>
                    <Grid className={classes.body}>
                      {!isEditingAddress ? (
                        <>
                          <Grid className={classes.addressItemContainer}>
                            <Typography className={classes.addressItem}>Address:</Typography>
                            {addressDetails?.address1 ? (
                              <a
                                href={googleSearchQuery(getFullAddress(addressDetails))}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  width: '50%',
                                  fontSize: '1rem',
                                  textDecoration: 'none',
                                  color: '#0066cc',
                                }}
                              >
                                {addressDetails.fullAddress}
                              </a>
                            ) : (
                              <Typography className={classes.addressValue}>N/A</Typography>
                            )}
                          </Grid>
                          <Grid className={classes.addressItemContainer}>
                            <Typography className={classes.addressItem}>Phone Number:</Typography>
                            <Typography className={classes.addressValue}>
                              {addressDetails?.phoneNumber || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid className={classes.addressItemContainer}>
                            <Typography className={classes.addressItem}>Email Address:</Typography>
                            <Typography className={classes.addressValue}>
                              {addressDetails?.email || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid className={classes.addressItemContainer}>
                            <Typography className={classes.addressItem}>Website URL:</Typography>
                            <Typography className={classes.addressValue}>
                              {addressDetails?.website || 'N/A'}
                            </Typography>
                          </Grid>
                          {/*<Grid className={classes.addressItemContainer}>*/}
                          {/*  <Typography className={classes.addressItem}>*/}
                          {/*    Description/Notes:*/}
                          {/*  </Typography>*/}
                          {/*  <Typography className={classes.addressItem}>N/A</Typography>*/}
                          {/*</Grid>*/}
                        </>
                      ) : (
                        <Formik
                          initialValues={{
                            phone: addressDetails?.phoneNumber || 'N/A',
                            fax: 'N/A',
                            email: 'N/A',
                            website: 'N/A',
                            description: 'N/A',
                          }}
                          onSubmit={handleSaveAddress}
                        >
                          {({ dirty }) => {
                            setEditFormIsDirty(!dirty);
                            return (
                              <Form>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>Address:</Typography>
                                  {addressDetails?.address1 ? (
                                    <a
                                      href={googleSearchQuery(getFullAddress(addressDetails))}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        width: '50%',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        textDecoration: 'none',
                                        color: '#0066cc',
                                      }}
                                    >
                                      {addressDetails.fullAddress}
                                    </a>
                                  ) : (
                                    <Typography className={classes.addressItem}>N/A</Typography>
                                  )}
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>
                                    Phone Number:
                                  </Typography>
                                  <Grid item className={classes.addressItem}>
                                    <FormTextField
                                      name="phone"
                                      rows={1}
                                      fullWidth
                                      className={clsx(classes.addressItemForm)}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>
                                    Fax Number:
                                  </Typography>
                                  <Grid item className={classes.addressItem}>
                                    <FormTextField
                                      name="fax"
                                      rows={1}
                                      fullWidth
                                      className={clsx(classes.addressItemForm)}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>
                                    Email Address:
                                  </Typography>
                                  <Grid item className={classes.addressItem}>
                                    <FormTextField
                                      name="email"
                                      rows={1}
                                      fullWidth
                                      className={clsx(classes.addressItemForm)}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>
                                    Website URL:
                                  </Typography>
                                  <Grid item className={classes.addressItem}>
                                    <FormTextField
                                      name="website"
                                      rows={1}
                                      fullWidth
                                      className={clsx(classes.addressItemForm)}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Typography className={classes.addressItem}>
                                    Description/Notes:
                                  </Typography>
                                </Grid>
                                <Grid className={classes.addressItemContainer}>
                                  <Grid item className={classes.addressItem}>
                                    <FormTextField
                                      name="description"
                                      rows={3}
                                      fullWidth
                                      multiline
                                      className={clsx(classes.addressItemForm)}
                                    />
                                  </Grid>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <TitleBorder leftItem={`Project Leads (Total: ${activeProjectRows.length || 0})`}>
                  <TabContext value={addressTab}>
                    <Box className={classes.tabsContainer}>
                      <TabList
                        className={classes.tabList}
                        onChange={handleAddressTabChange}
                        aria-label="address tabs"
                      >
                        {addressTabsOnProjectLeadDetails.map((tabItem, idx) => {
                          const count =
                            tabItem.status === ScoreStatus.Active
                              ? activeProjectRows.length
                              : tabItem.status === ScoreStatus.Converted
                              ? convertedProjectRows.length
                              : tabItem.status === ScoreStatus.Demoted
                              ? demotedProjectRows.length
                              : 0;
                          return (
                            <Tab
                              key={idx}
                              label={`${tabItem.text} (${count || 0})`}
                              value={tabItem.status}
                              classes={tabStyles({ fontSize: tabSize })}
                            />
                          );
                        })}
                      </TabList>
                    </Box>
                    <Box className={classes.tabPanelContainer}>
                      <TabPanel value={ScoreStatus.Active} className={classes.tabPanel}>
                        <Table
                          headerCells={projectLeadDetailsHeadCellsOnAddressTab}
                          order={order}
                          orderBy={orderBy}
                          rowCount={activeProjectRows?.length || 0}
                          rows={
                            !activeProjectRows
                              ? []
                              : ((activeProjectRows as unknown) as PvfProject[])
                          }
                          scoreStatus={scoreStatus}
                          startIndex={startIndex}
                          hasMoreItems={hasMoreActiveItems}
                          onRequestSort={handleSortRequest}
                          setStartIndex={setStartIndex}
                          handleClickRow={handleClickRow}
                          reloadItems={reloadItems}
                          errorMessage={errorMessage}
                          isNewKey={'isNew'}
                          hasError={false}
                        />
                      </TabPanel>
                      <TabPanel value={ScoreStatus.Converted} className={classes.tabPanel}>
                        <Table
                          headerCells={projectConvertedHeadCellsOnAddressTab}
                          order={order}
                          orderBy={orderBy}
                          rowCount={convertedProjectRows?.length || 0}
                          rows={
                            !convertedProjectRows
                              ? []
                              : ((convertedProjectRows as unknown) as PvfProject[])
                          }
                          scoreStatus={scoreStatus}
                          startIndex={startIndex}
                          hasMoreItems={hasMoreConvertedItems}
                          onRequestSort={handleSortRequest}
                          setStartIndex={setStartIndex}
                          handleClickRow={handleClickRow}
                          reloadItems={reloadItems}
                          errorMessage={errorMessage}
                          isNewKey={'isNew'}
                          hasError={false}
                        />
                      </TabPanel>
                      <TabPanel value={ScoreStatus.Demoted} className={classes.tabPanel}>
                        <Table
                          headerCells={projectDemotedHeadCellsOnAddressTab}
                          order={order}
                          orderBy={orderBy}
                          rowCount={convertedProjectRows?.length || 0}
                          rows={
                            !convertedProjectRows
                              ? []
                              : ((convertedProjectRows as unknown) as PvfProject[])
                          }
                          scoreStatus={scoreStatus}
                          startIndex={startIndex}
                          hasMoreItems={hasMoreDemotedItems}
                          onRequestSort={handleSortRequest}
                          setStartIndex={setStartIndex}
                          handleClickRow={handleClickRow}
                          reloadItems={reloadItems}
                          errorMessage={errorMessage}
                          isNewKey={'isNew'}
                          hasError={false}
                        />
                      </TabPanel>
                    </Box>
                  </TabContext>
                </TitleBorder>
              </TabPanel>
              <TabPanel value={ProjectLeadsStatusTab.Documents} className={classes.tabPanel}>
                <ProjectLeadDocuments projectUrl={projectUrl} dataSource={dataSource} />
              </TabPanel>
              <TabPanel value={ProjectLeadsStatusTab.Notes} className={classes.tabPanel}>
                Render Panel for Notes
              </TabPanel>
            </Box>
          </TabContext>
        </Grid>
      </Container>
    </Paper>
  );
};

export default memo(ProjectLeadDetails);
