import { Grid } from '@material-ui/core';
import React from 'react';

import accountRatingA from '../resources/images/Accounts/AccountRatingAIcon.svg';
import accountIncA from '../resources/images/Accounts/AccountsIncAIcon.svg';
import accountRatingB from '../resources/images/Accounts/AccountRatingBIcon.svg';
import accountIncB from '../resources/images/Accounts/AccountsIncBIcon.svg';
import accountRatingC from '../resources/images/Accounts/AccountRatingCIcon.svg';
import accountIncC from '../resources/images/Accounts/AccountsIncCIcon.svg';
import accountIncNoRating from '../resources/images/Accounts/AccountsIncNoRatingIcon.svg';

export function getAccountIcons(sfRating: string) {
  let ratingIcon, ratingAlt, incIcon;
  switch (sfRating) {
    case 'A':
      ratingIcon = accountRatingA;
      ratingAlt = 'A';
      incIcon = accountIncA;
      break;
    case 'B':
      ratingIcon = accountRatingB;
      ratingAlt = 'B';
      incIcon = accountIncB;
      break;
    case 'C':
      ratingIcon = accountRatingC;
      ratingAlt = 'C';
      incIcon = accountIncC;
      break;
    default:
      ratingAlt = 'No Grade';
      ratingIcon = '';
      incIcon = accountIncNoRating;
      break;
  }
  const incIconElement = (
    <Grid>
      <img src={incIcon} alt={ratingAlt} style={{ width: '35px', height: '35px' }} />
    </Grid>
  );

  const ratingIconElement = !sfRating ? (
    <div />
  ) : (
    <Grid>
      <img src={ratingIcon} alt={ratingAlt} style={{ width: '35px', height: '35px' }} />
    </Grid>
  );

  return { ratingAlt, ratingIconElement, incIconElement };
}
