import React, { ChangeEvent, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionDetails, Checkbox, CheckboxProps, Grid, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { renderList, variantTitle } from './PLDetailsTab.config';
import { leftConfigEvent, rightConfigEvent } from './DataSourceItem.config';

import { AccordionMod, AccordionSummaryMod } from '../../../Common/AccordionMod';
import { black, primary, primaryBlue, white } from '../../../../theme';
import Event, { IEventResponse } from '../../../../models/event';
import { toTitleCase } from '../../../../utils/string';
import { IDodgeProjectLeadResponse } from '../../../../models';
import { renderNumber2 } from '../../Leads/LeadsTable/LeadTableRowRenderers';
import ProjectLead from '../../../../models/projectLead';

// Determine source type inside component and use switch cases to determine what config to use

export type IEventWithIProjectLead = IEventResponse & {
  projectLeads: IDodgeProjectLeadResponse;
};

export interface DataSourceItemProps {
  backgroundColor: string;
  data?: IEventWithIProjectLead;
  handleEventToggle: (id: string | undefined) => void;
  addedEvent?: boolean;
  isFirst?: boolean;
}

const DataSourceItem: React.FC<DataSourceItemProps> = ({
  backgroundColor,
  data,
  handleEventToggle,
  addedEvent = false,
  isFirst = false,
}) => {
  const [checked, setChecked] = useState(false);
  let color = black;
  backgroundColor = checked ? primary : backgroundColor;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    if (handleEventToggle) {
      handleEventToggle(e?.target?.value || undefined);
    }
  };

  if (!data) {
    return null;
  }
  let checkboxProps: CheckboxProps | undefined;
  if (addedEvent) {
    checkboxProps = {
      icon: <CheckCircle style={{ color: white }} />,
      disabled: true,
    };
    backgroundColor = primaryBlue;
    color = white;
  }

  const eventData = new Event(data);
  let projectLeadData: ProjectLead | undefined;
  if (data.projectLeads) {
    projectLeadData = new ProjectLead(data, data.projectLeads);
  }
  const renderLeft = renderList({ data: projectLeadData || eventData, config: leftConfigEvent });
  const renderRight = renderList({ data: projectLeadData || eventData, config: rightConfigEvent });

  return (
    <AccordionMod square={true} defaultExpanded={isFirst}>
      <AccordionSummaryMod
        style={{
          minHeight: 0,
          backgroundColor: backgroundColor,
          borderRadius: '4px',
          color,
        }}
        aria-label={'Expand'}
        aria-controls={'additional-actions-content'}
        expandIcon={<ExpandMoreIcon style={{ flex: 1, color }} />}
        id={'additional-actions-header'}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid style={{ flex: 1 }}>
            <Checkbox
              checked={checked}
              value={eventData.id}
              onClick={e => e.stopPropagation()}
              onFocus={e => e.stopPropagation()}
              onChange={e => {
                handleChange(e);
              }}
              color={'default'}
              inputProps={{ 'aria-label': `SourceId checkbox` }}
              {...checkboxProps}
            />
          </Grid>

          <Grid style={{ flex: 5 }}>
            <Typography variant={variantTitle}>{`${toTitleCase(eventData.sourceLabel)}: ${
              eventData.externalId
            }`}</Typography>
          </Grid>
          <Grid style={{ flex: 5, display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ paddingRight: '.5rem' }} variant={variantTitle}>
              {`Total SQFT:`}
            </Typography>
            <Grid>
              {renderNumber2(Number(projectLeadData?.totalSqft), false, variantTitle, 'sqft')}
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummaryMod>
      <AccordionDetails>
        <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Grid
            style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '.5rem' }}
          >
            {renderLeft}
          </Grid>
          <Grid
            style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '.5rem' }}
          >
            {renderRight}
          </Grid>
        </Grid>
      </AccordionDetails>
    </AccordionMod>
  );
};

export default DataSourceItem;
