import type { MouseEvent } from 'react';
import React, { useState, useCallback } from 'react';
import { makeStyles, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';

import type { Optional } from '../../types';

type MenuOptionClickHandler = () => void;

export interface IMenuOption {
  label: string;
  onClick: MenuOptionClickHandler;
}

interface IProps {
  title?: string;
  options: IMenuOption[];
  moreIconClassName?: string;
  moreButtonClassName?: string;
  menuClassName?: string;
}

const useStyles = makeStyles({
  button: {
    margin: 0,
    padding: 0,
    minWidth: 'auto',
  },
});

const MoreMenu: React.FC<IProps> = ({
  title = 'Options',
  options,
  moreIconClassName,
  moreButtonClassName,
  menuClassName,
}) => {
  const [anchorEl, setAnchorEl] = useState<Optional<HTMLElement>>();

  const handleClose = useCallback(e => {
    setAnchorEl(undefined);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleOptionClick = useCallback(
    (fn: MenuOptionClickHandler, e) => {
      handleClose(e);
      fn();
    },
    [handleClose],
  );

  const classes = useStyles();
  return (
    <>
      <Tooltip title={title} placement="left" arrow>
        <Button
          className={clsx(classes.button, moreButtonClassName)}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreIcon className={moreIconClassName} />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        className={menuClassName}
      >
        {options.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOptionClick(onClick, e);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreMenu;
