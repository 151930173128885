import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { CSVDownload } from 'react-csv';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import {
  Paper,
  makeStyles,
  Button,
  Grid,
  MenuItem,
  FormControl,
  TextField,
} from '@material-ui/core';

import { DateRanges } from '../../../models/common';
import { DateRangeMapping } from '../../../constants';
import PaperBody from '../../Common/PaperBody';
import PaperHeader from '../../Common/PaperHeader';
import PaperTitle from '../../Common/PaperTitle';
import { getSystemMetrics } from '../../../api/metrics';
import { useMessages } from '../../../state/contexts';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 24),
    },
  },
  paper: {
    borderRadius: 8,
  },
  picker: {
    marginBottom: 16,
  },
}));

const barOptions = {
  barValueSpacing: 20,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

type MetricResponse = {
  asOfDaysAgo: number;
  hasActiveProject: string;
  hasProjectStakeholders: string;
  hasPropertyStakeholders: string;
  percentFollowOnOpportunities: string;
  percentPropertiesWithActiveProject: string;
  percentPropertiesWithProjectStakeholders: string;
  percentPropertiesWithStakeholders: string;
  totalFollowOnOpportunities: string;
  totalProjectStakeholders: string;
  totalProjects: string;
  totalProperties: string;
  totalPropertyStakeholders: string;
  [key: string]: any;
};

type ChartData = {
  labels: string[];
  datasets: Datasets[];
};

type Datasets = {
  label: string;
  backgroundColor: string;
  data: any[];
  key: string;
};

const System: React.FC = () => {
  const { setPageLoading, setErrorMessage } = useMessages();
  const classes = useStyles();
  const [csvData, setCsvData] = useState('');
  const [query, setQuery] = useState<DateRanges>(DateRanges.PastWeek);
  const [systemData, setSystemData] = useState<Array<MetricResponse>>([]);

  const downloadReport = async () => {
    setPageLoading(true);
    try {
      const metrics = await getSystemMetrics({
        query,
        csv: true,
      });
      setCsvData(metrics);
    } catch (err: any) {
      setErrorMessage('Error fetching report', err);
    }
    setPageLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value as DateRanges);
  };

  useEffect(() => {
    if (csvData) {
      setCsvData('');
    }
  }, [csvData]);

  useEffect(() => {
    async function fetchData() {
      setPageLoading(true);
      try {
        const metrics = await getSystemMetrics({
          query,
          csv: false,
        });
        setSystemData(metrics);
      } catch (err: any) {
        setErrorMessage('Error fetching report', err);
      }
      setPageLoading(false);
    }
    fetchData();
  }, [query, setErrorMessage, setPageLoading]);

  if (systemData.length === 0) {
    return null;
  }

  const barPercentData: ChartData = {
    datasets: [
      {
        key: 'percentFollowOnOpportunities',
        label: 'Percent Follow On Opportunities',
        backgroundColor: 'red',
        data: [],
      },
      {
        key: 'percentPropertiesWithActiveProject',
        label: 'Percent Properties With Active Project',
        backgroundColor: 'blue',
        data: [],
      },
      {
        key: 'percentPropertiesWithProjectStakeholders',
        label: 'Percent Properties With Project Stakeholders',
        backgroundColor: 'orange',
        data: [],
      },
      {
        key: 'percentPropertiesWithStakeholders',
        label: 'Percent Properties With Stakeholders',
        backgroundColor: 'grey',
        data: [],
      },
    ],
    labels: [],
  };

  const barTotalData: ChartData = {
    datasets: [
      {
        key: 'totalFollowOnOpportunities',
        label: 'Total Follow On Opportunities',
        backgroundColor: 'red',
        data: [],
      },
      {
        key: 'totalProjectStakeholders',
        label: 'Total Project Stakeholders',
        backgroundColor: 'blue',
        data: [],
      },
      {
        key: 'totalProjects',
        label: 'Total Projects',
        backgroundColor: 'orange',
        data: [],
      },
      {
        key: 'totalProperties',
        label: 'Total Properties',
        backgroundColor: 'grey',
        data: [],
      },
      {
        key: 'totalPropertyStakeholders',
        label: 'Total Property Stakeholders',
        backgroundColor: 'green',
        data: [],
      },
    ],
    labels: [],
  };

  const barNumberData: ChartData = {
    datasets: [
      {
        key: 'hasActiveProject',
        label: 'ActiveProject',
        backgroundColor: 'red',
        data: [],
      },
      {
        key: 'hasProjectStakeholders',
        label: 'Project Stakeholders',
        backgroundColor: 'blue',
        data: [],
      },
      {
        key: 'hasPropertyStakeholders',
        label: 'Property Stakeholders',
        backgroundColor: 'orange',
        data: [],
      },
    ],
    labels: [],
  };

  systemData.forEach(s => {
    barPercentData.labels.push(`${s.asOfDaysAgo} days ago`);
    barTotalData.labels.push(`${s.asOfDaysAgo} days ago`);

    barPercentData.datasets.forEach(d => {
      d.data.push(numeral(s[d.key]).value() * 100);
    });
    barTotalData.datasets.forEach(d => {
      d.data.push(numeral(s[d.key]).value());
    });
    barNumberData.datasets.forEach(d => {
      d.data.push(numeral(s[d.key]).value());
    });
  });

  return (
    <Paper className={classes.paper}>
      <PaperHeader>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <PaperTitle title="System" />
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            <Button variant="contained" color="primary" onClick={downloadReport}>
              Download CSV
            </Button>
          </Grid>
        </Grid>
      </PaperHeader>
      <PaperBody>
        <Grid container className={classes.picker}>
          <Grid>
            <FormControl>
              <TextField
                id="daterange"
                select
                label="Date Range"
                value={query}
                onChange={handleChange}
                helperText="Please select a date range"
              >
                {Object.entries(DateRangeMapping).map(([value, name]) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <HorizontalBar data={barNumberData} width={100} height={12} options={barOptions} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Bar data={barPercentData} width={100} height={50} options={barOptions} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Bar data={barTotalData} width={100} height={50} options={barOptions} />
          </Grid>
        </Grid>
      </PaperBody>
      {csvData && <CSVDownload target="_self" data={csvData} />}
    </Paper>
  );
};

export default System;
