import { HeadCell } from '../../Common/Tables/Table';
import {
  renderValue,
  renderTitleCase,
  renderRole,
  renderFalsyValue,
  renderSource,
} from '../Leads/LeadsTable/LeadTableRowRenderers';
import { IOrganizationResponse, IProjectResponse, IStakeholderResponse } from '../../../models';

export type IDetailStakeholderResponse = IStakeholderResponse & {
  name: string;
  stakeholder_role: string;
  organization_name: string;
  companyName: string;
  phone: string;
  properties: IOrganizationResponse[];
  properties_count: number;
  projects: IProjectResponse[];
  projects_count: number;
  cleoSource: boolean;
  salesforceSource: boolean;
  source_label: string;
  source_name: string;
};

export interface SearchContact {
  name: string;
  stakeholder_role: string;
  organization_name: string;
  companyName: string;
  phone: string;
  properties_count: string;
  projects_count: string;
  cleoSource: boolean;
  salesforceSource: boolean;
  id: string;
  source_label: string;
  source_name: string;
}

export const contactHeadCells: HeadCell<IDetailStakeholderResponse, SearchContact>[] = [
  {
    id: 'name',
    label: 'Name',
    gridProps: { md: 2 },
    renderRow: { function: renderTitleCase, param: ['name'], noData: '' },
  },
  {
    id: 'stakeholder_role',
    label: 'Role',
    gridProps: { md: 2 },
    renderRow: { function: renderRole, param: ['stakeholder_role'], noData: 0 },
  },
  {
    id: 'organization_name',
    label: 'Company Name',
    gridProps: { md: 2 },
    renderRow: { function: renderValue, param: ['organization_name'], noData: '' },
  },
  {
    id: 'phone',
    label: 'Phone',
    gridProps: { md: 2 },
    renderRow: { function: renderValue, param: ['phone'], noData: '' },
  },
  {
    id: 'properties_count',
    label: 'Properties',
    gridProps: { md: 1 },
    renderRow: { function: renderFalsyValue, param: ['properties_count'], noData: 0 },
  },
  {
    id: 'projects_count',
    label: 'Projects',
    gridProps: { md: 1 },
    renderRow: { function: renderFalsyValue, param: ['projects_count'], noData: 0 },
  },
  {
    id: 'salesforce_collision',
    label: 'Source',
    gridProps: { md: 1 },
    renderRow: {
      function: renderSource,
      param: ['cleoSource', 'source_label', 'source_name'],
      noData: '',
    },
  },
];
