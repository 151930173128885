import React, { useRef, useCallback, useState } from 'react';
import {
  makeStyles,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import FeedbackCard from './FeedbackCard';

import EmptyBox from '../../../Common/EmptyBox';
import type { Feedback, Project } from '../../../../models';

const INITIAL_VISIBLE_FEEDBACKS = 3;

const useStyles = makeStyles(theme => ({
  input: {
    border: 0,
    boxShadow: 'none',
  },
  submitButton: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(-2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  feedbacks: Feedback[];
  readOnly?: boolean;
  checkboxHandler?: (feedbackId: string, add: boolean) => void;
  project?: Project;
  onSubmit?: (notes: string) => Promise<void>;
  onEdit?: (id: string, notes: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
}

const Feedbacks: React.FC<IProps> = ({
  feedbacks,
  onSubmit,
  onEdit,
  onDelete,
  checkboxHandler,
  readOnly,
  project,
}) => {
  const [showAll, setShowAll] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const handleSubmit = useCallback(async () => {
    if (!onSubmit) {
      return;
    }

    const input = inputRef.current;
    if (input) {
      if (input.value.length > 0) {
        await onSubmit(input.value);
        input.value = '';
      } else {
        input.focus();
      }
    }
  }, [inputRef, onSubmit]);

  const handleKeydown = useCallback(
    async e => {
      if (!e.shiftKey && e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const classes = useStyles();
  const initialFeedbacksCount =
    feedbacks.length > INITIAL_VISIBLE_FEEDBACKS + 1 ? INITIAL_VISIBLE_FEEDBACKS : feedbacks.length;
  const remainingFeedbacksCount = feedbacks.length - initialFeedbacksCount;
  return (
    <>
      {feedbacks.length === 0 ? (
        <EmptyBox>No Notes</EmptyBox>
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            {feedbacks.slice(0, initialFeedbacksCount).map(feedback => (
              <Grid item key={feedback.id}>
                <FeedbackCard
                  feedback={feedback}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  readOnly={readOnly}
                  checkboxHandler={readOnly ? checkboxHandler : undefined}
                  project={project}
                />
              </Grid>
            ))}
            {remainingFeedbacksCount > 0 && (
              <>
                {!showAll && (
                  <Box my={2}>
                    <Button color="primary" fullWidth onClick={() => setShowAll(true)}>
                      +{remainingFeedbacksCount} more
                    </Button>
                  </Box>
                )}
                <Collapse in={showAll}>
                  <Box mt={3}>
                    <Grid container direction="column" spacing={3}>
                      {feedbacks.slice(initialFeedbacksCount).map(feedback => (
                        <Grid item key={feedback.id}>
                          <FeedbackCard
                            feedback={feedback}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            readOnly={readOnly}
                            checkboxHandler={readOnly ? checkboxHandler : undefined}
                            project={project}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>
              </>
            )}
          </Grid>
        </>
      )}
      {!readOnly && (
        <Box mt={4}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              classes={{ notchedOutline: classes.input }}
              inputRef={inputRef}
              id="input-note"
              placeholder="Add Note"
              onKeyDown={handleKeydown}
              multiline
              labelWidth={70}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={classes.submitButton}
                    aria-label="save note"
                    edge="end"
                    onClick={handleSubmit}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default Feedbacks;
