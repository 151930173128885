import { Box, Typography, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import cronstrue from 'cronstrue';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';

import useDataMapperConfigurationsTableStyles from './DataMapperConfigurationsTable.styles';
import { dataMapperHeaderCells } from './DataMapperConfigurationsTable.config';

import DataMapper from '../../../models/dataMapper';
import { getLocalDateFromUtcTimestamp } from '../../../utils/date';
import { toTitleCase } from '../../../utils/string';
import { useMessages } from '../../../state/contexts';
import { deleteETLConfiguration } from '../../../api/dataMapper';

interface IProps {
  rows: any;
  total: number;
  refreshData: () => void;
}

const DataMapperConfigurationsTable: React.FC<IProps> = ({ rows, total, refreshData }: IProps) => {
  const history = useHistory();
  const classes = useDataMapperConfigurationsTableStyles();
  const { setErrorMessage } = useMessages();

  const handleDeleteETLConfiguration = useCallback(
    async (id: string) => {
      try {
        await deleteETLConfiguration(id);
        refreshData();
      } catch (e: any) {
        setErrorMessage(e.message);
      }
    },
    [setErrorMessage, refreshData],
  );
  return (
    <>
      <Box mt={1} mb={4} ml={4} className={classes.tableCount}>
        <Typography variant="subtitle2" id="data-mapper-results">
          {total} Results
        </Typography>
        <div aria-label="data-mappings" className={classes.dataMappingsTableContainer}>
          <Box mb={2} borderRadius={2}>
            <Grid container alignItems="center">
              {dataMapperHeaderCells.map(({ id, label, textAlign = 'left', gridProps }) => {
                return (
                  <Grid
                    item
                    key={id}
                    style={{ textAlign }}
                    {...gridProps}
                    className={classes.headCell}
                  >
                    <Typography className={classes.label} component="span" variant="body2">
                      {label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Grid container direction="column" className={classes.table}>
            {rows.map((row: DataMapper) => {
              const { name, cron, updatedAt, status, url } = row;
              return (
                <div key={`${row.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item className={classes.row}>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.rowCell}
                      onClick={() => history.push('/data-mapper/create')}
                      spacing={3}
                      style={{
                        flexGrow: 1,
                      }}
                    >
                      <Grid item md={1}>
                        <Typography color="primary">{status}</Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography color="primary">{toTitleCase(name)}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography color="primary">{url}</Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography color="primary">
                          {cronstrue.toString(`${cron.replace(/0/g, '*')} *`)}
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography color="primary">
                          {getLocalDateFromUtcTimestamp(updatedAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{ width: '3%', cursor: 'pointer' }}
                    onClick={() => handleDeleteETLConfiguration(row.id)}
                  >
                    <DeleteIcon />
                  </Grid>
                </div>
              );
            })}
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default DataMapperConfigurationsTable;
