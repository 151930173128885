import React, { useCallback } from 'react';
import { Grid, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import EmptyBox from '../../../Common/EmptyBox';

const useStyles = makeStyles(theme => ({
  emptyNoteModal: {
    padding: theme.spacing(2),
  },
  notesHeader: {
    padding: theme.spacing(2),
  },
  input: {
    border: 0,
    boxShadow: 'none',
  },
  modalContainer: {
    border: '1px solid rgb(31, 180, 177)',
    height: 'auto',
    width: '25rem',
  },
  form: {
    width: '100%',
  },
  submitButton: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(-2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}));

type IProps = {
  onSubmit?: (note: string) => Promise<void>;
  onEdit?: (id: string, note: string) => Promise<void>;
  closePopover: () => void;
  noteDetails?: { id: string; notes: string };
};

const NotesPopup: React.FC<IProps> = ({ onSubmit, closePopover, onEdit, noteDetails }) => {
  const [value, setValue] = React.useState(noteDetails ? noteDetails.notes : '');
  const handleChange = useCallback(
    e => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const handleSubmitForm = useCallback(
    e => {
      e.preventDefault();
      onSubmit && onSubmit(value);
      onEdit && noteDetails && onEdit(noteDetails.id, value);
      closePopover();
    },
    [onSubmit, value, closePopover, noteDetails, onEdit],
  );

  const handleKeyUp = useCallback(
    async e => {
      if (!e.shiftKey && e.key === 'Enter') {
        e.preventDefault();
        handleSubmitForm(e);
      } else {
        handleChange(e);
      }
    },
    [handleSubmitForm, handleChange],
  );

  const handleKeyDown = useCallback(async e => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.modalContainer}>
      <Grid item xs={12}>
        <EmptyBox className={classes.notesHeader}>{onSubmit ? 'Add' : 'Edit'} Note</EmptyBox>
        <form className={classes.form} onSubmit={handleSubmitForm}>
          <OutlinedInput
            classes={{ notchedOutline: classes.input }}
            defaultValue={value}
            id="input-note"
            placeholder="Add Note"
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            multiline
            fullWidth={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  className={classes.submitButton}
                  aria-label="save note"
                  edge="end"
                  onClick={handleSubmitForm}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default NotesPopup;
