import React from 'react';
import { makeStyles, Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import EmptyBox from './EmptyBox';

import { darkGray, primaryBlue } from '../../theme';

export interface IProps {
  title: string;
  emptyButtonText: string;
  total: number;
  onClickAdd?: (e: React.MouseEvent<unknown>) => void;
  onChange?: () => void;
}

const useStyles = makeStyles({
  total: {
    color: darkGray,
  },
  addIcon: {
    color: primaryBlue,
    cursor: 'pointer',
  },
  emptyBox: {
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CardList: React.FC<IProps> = ({ title, emptyButtonText, total, onClickAdd, children }) => {
  const classes = useStyles();
  return (
    <>
      <Box mb={1}>
        <Grid container justify="space-between" alignItems="center">
          {!!title && (
            <Grid item>
              <Typography color="primary" variant="subtitle2" component="span">
                {title}:
              </Typography>
              &nbsp;
              <Typography className={classes.total} component="span">
                <strong>{total}</strong>
              </Typography>
            </Grid>
          )}
          {onClickAdd && (
            <Grid item>
              <AddIcon className={classes.addIcon} onClick={onClickAdd} />
            </Grid>
          )}
        </Grid>
      </Box>
      {total > 0 ? (
        <Grid container direction="column" spacing={3}>
          {children}
        </Grid>
      ) : (
        <EmptyBox dense className={classes.emptyBox}>
          {onClickAdd ? (
            <Button color="primary" onClick={onClickAdd}>
              {emptyButtonText}
            </Button>
          ) : (
            emptyButtonText
          )}
        </EmptyBox>
      )}
    </>
  );
};

export default CardList;
