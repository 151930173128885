import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  renderDate,
  renderName,
  renderNumber,
  renderProperty,
  // renderScore,
  renderString,
} from './MapDrawerRowRenderers';
import useDrawerCardProjectLeadStyle from './DrawerCardProjectLead.style';

import { IProject } from '../../Leads/Archive/Archive.config';
import { darkBlue, hyperlinkBlue, lightGreen, success, white } from '../../../../theme';
import { toTitleCase } from '../../../../utils/string';
import { renderProjectScore } from '../../Leads/LeadsTable/LeadTableRowRenderers';

interface IDrawerCardProjectLead {
  isOdd: boolean;
  project: IProject;
}

const DrawerCardProjectLead: React.FC<IDrawerCardProjectLead> = ({ project, isOdd }) => {
  const classes = useDrawerCardProjectLeadStyle();
  const isNew = true;
  return (
    <Grid
      className={classes.root}
      style={{ backgroundColor: isOdd ? white : lightGreen, cursor: 'pointer' }}
    >
      <Link
        target="_blank"
        to={`/leads/${project.propertyId}`}
        style={{ textDecoration: 'none', color: darkBlue }}
      >
        <Grid className={classes.body}>
          {isNew && (
            <Grid className={classes.newBanner}>
              <Typography variant="caption" className={classes.newText}>
                <strong>NEW</strong>
              </Typography>
            </Grid>
          )}
          <Grid className={classes.property} style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid className={classes.score}>
              {renderProjectScore(
                project.projectScore || 0,
                isOdd ? success : hyperlinkBlue,
                20,
                40,
                '15px',
              )}
            </Grid>
            <Grid className={classes.name} style={{ color: hyperlinkBlue }}>
              {renderName(toTitleCase(project.projectName), false)}
            </Grid>
          </Grid>
          <Grid className={classes.property} style={{ color: hyperlinkBlue }}>
            {renderProperty(project.address1, project.city, project.state, project.zipcode)}
          </Grid>
          <Grid className={classes.row}>
            {renderNumber('Total SqFt', Number(project.size), false)}
          </Grid>
          <Grid className={classes.row}>
            {renderNumber('Valuation', project.value || project.value1, true)}
          </Grid>
          <Grid className={classes.row}>{renderDate('Bid Date', project.bidDate || '')}</Grid>
          <Grid className={classes.row}>{renderString('Action Stage', project.actionStage)}</Grid>
          <Grid className={classes.row}>
            {renderString('Project Type', toTitleCase(project.projectType))}
          </Grid>
          <Grid className={classes.row}>{renderString('LatestEvent', 'Dodge')}</Grid>
        </Grid>
      </Link>
    </Grid>
  );
};

export default DrawerCardProjectLead;
