import { selector } from 'recoil';

import { getFilterOptions } from '../../api/filters';
import { FilterOptionsResponse } from '../../types';

export const filterOptionsFetchState = selector({
  key: 'filterOptionsFetch',
  get: async () => {
    const filterOptions = await getFilterOptions();
    return filterOptions as FilterOptionsResponse;
  },
});
