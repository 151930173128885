import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Grid, Paper, Switch, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import StakeholderCardStyle from './StakeholderCard.style';
import StakeholderAccountIcons from './StakeholderAccountIcons';

import type { Stakeholder } from '../../../models';
import { getAccountDetailsPagePath, getAccountsPagePath } from '../../../utils/page';
import { toTitleCase } from '../../../utils/string';
import TruncatedTypography from '../../Common/TruncatedTypography';
import { projectStakeholdersState } from '../../../state/atoms/leadDetails';
import { hyperlinkBlue } from '../../../theme';
import { renderPropertyPL } from '../Leads/LeadsTable/LeadTableRowRenderers';
import { googleSearchQuery } from '../../../utils/map';
import { getFullAddress } from '../../../utils/address';

interface IProps {
  stakeholder: Stakeholder;
  isOdd: boolean;
  primary?: boolean;
  clickable?: boolean;
  allowAssociation?: boolean;
  isAssociated?: boolean;
  onToggleChanged?: (checked: boolean, data: Stakeholder) => void;
  onRemove?: (
    propertyOrProjectId: string,
    stakeholderId: string,
    addedToProperty: boolean,
  ) => Promise<void>;
  propertyId?: string;
  projectId?: string;
}

const StakeholderCard: React.FC<IProps> = ({
  stakeholder,
  isOdd,
  clickable = true,
  onToggleChanged,
  allowAssociation = false,
  isAssociated = false,
  primary = false,
  onRemove,
  propertyId,
  projectId,
}) => {
  const {
    name,
    roleDisplayName,
    organization,
    phoneNumber,
    address1,
    city,
    state,
    zipcode,
  } = stakeholder;

  const account = organization?.account;
  const isNational = account?.incremental_target_national || !!organization?.isNational;
  const classes = StakeholderCardStyle({ isOdd });
  const projectStakeholders = useRecoilValue(projectStakeholdersState);
  const showRemoveFromProjectOption = projectStakeholders.includes(stakeholder);
  const accountLinkId =
    organization?.account?.primary_organization_id || organization?.id || undefined;
  const handleToggleChange = useCallback(
    evt => {
      const { checked } = evt.target;
      if (onToggleChanged) {
        onToggleChanged(checked, stakeholder);
      }
    },
    [onToggleChanged, stakeholder],
  );

  useEffect(() => {
    const currentOptions = [];
    if (propertyId) {
      currentOptions.push({
        label: 'Remove Contact from Property',
        onClick: () => {
          onRemove && propertyId && onRemove(propertyId, stakeholder.id, true);
        },
      });
    }
    if (showRemoveFromProjectOption) {
      currentOptions.push({
        label: 'Remove Contact from Project',
        onClick: () => {
          onRemove && projectId && onRemove(projectId, stakeholder.id, false);
        },
      });
    }
  }, [propertyId, projectId, stakeholder, showRemoveFromProjectOption, onRemove]);

  return (
    <div>
      {allowAssociation && (
        <Grid container justify="flex-end">
          <Grid item>
            <Switch
              checked={isAssociated}
              onChange={handleToggleChange}
              name="isAssociated"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Paper
        className={clsx(
          classes.root,
          {
            [classes.rootPrimary]: primary,
          },
          isNational && classes.national,
        )}
        elevation={0}
        square
        component={
          clickable
            ? props => (
                <Link
                  target="_blank"
                  to={
                    accountLinkId ? getAccountDetailsPagePath(accountLinkId) : getAccountsPagePath()
                  }
                  {...props}
                />
              )
            : 'div'
        }
      >
        <Grid container className={clsx(classes.flexRow, classes.container)}>
          <Grid className={clsx(classes.flexCol, classes.info)}>
            <Grid item style={{ display: 'flex' }}>
              <Typography
                color={'primary'}
                variant={'body1'}
                // className={classes.dense}
                style={{
                  fontWeight: 900,
                  textDecoration: 'underline',
                  textUnderlinePosition: 'under',
                }}
              >
                {roleDisplayName || 'Other'}
              </Typography>
            </Grid>
            {organization && accountLinkId && (
              <Grid
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(getAccountDetailsPagePath(accountLinkId));
                }}
              >
                <TruncatedTypography
                  maxLines={1}
                  TypographyProps={{
                    variant: 'body1',
                    className: classes.dense,
                    style: { color: hyperlinkBlue },
                  }}
                  TooltipProps={{ title: organization.name }}
                >
                  {organization.name}
                </TruncatedTypography>
              </Grid>
            )}
            <TruncatedTypography
              maxLines={1}
              TypographyProps={{
                color: 'primary',
                variant: 'body1',
                // className: classes.dense,
                style: { fontWeight: 900 },
              }}
              TooltipProps={{ title: toTitleCase(name) }}
            >
              {toTitleCase(name || 'No Name')}
            </TruncatedTypography>
            <Grid
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.open(googleSearchQuery(getFullAddress({ address1, city, state, zipcode })));
              }}
            >
              {renderPropertyPL(
                address1 || '',
                city || '',
                state + ' ' + zipcode || '',
                'body1',
                true,
              )}
            </Grid>
            <Grid item className={classes.bottomRow}>
              <Typography
                variant={'body1'}
                color={'primary'}
                // className={classes.dense}
              >
                {phoneNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.flexCol)}>
            {<StakeholderAccountIcons organization={organization} />}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default StakeholderCard;
