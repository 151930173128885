import { SortColumns } from '../Components/Pages/Leads/Archive/Archive.config';
import { HeadCell } from '../Components/Common/Tables/Table';

export interface IScore {
  score: number;
  propertyId: string;
  propertyName?: string;
  propertyCreatedAt?: string;
  address1: string;
  zipcode?: string;
  organizationName?: string;
  address2?: string;
  activeProjectDate?: string;
  inactiveProjectCount: number;
  size: number;
  value: number;
  eventType?: string;
  propertyType?: string;
  eventSource: string;
  eventDate?: string;
  eventDescription?: string;
  isNew: boolean;
  isBookmarked: boolean;
  hasConverted: boolean;
  hasDemoted: boolean;
  hasActive: boolean;
  city: string;
  state: string;
  ownerEmail: string;
  ownerName: string;
  ownerId: string;
}

export enum ScoreStatus {
  Home = 'home',
  New = 'new',
  Active = 'active',
  Inactive = 'inactive',
  Favorites = 'favorites',
  SavedFilters = 'savedFilters',
  Archive = 'archive',
  Converted = 'converted',
  Demoted = 'demoted',
  Hidden = 'hidden',
}

export enum SearchTabs {
  Projects = 'projects',
  Accounts = 'accounts',
  Contacts = 'contacts',
}

export interface SearchTabItem<T, K> {
  tabItem: SearchTabs;
  label: string;
  headerCells?: HeadCell<T, K>[];
}

export type SortOrder = 'asc' | 'desc';

export interface AddressesTable {
  score: number;
  property: string;
  propertyId: string;
  stakeholders: string;
  status: string;
  value: number;
  size: number;
  type: string;
  event: string;
  propertyCreatedAt?: string;
}

export interface Sort {
  orderBy: keyof AddressesTable;
  order: SortOrder;
}

export const sortColumns: SortColumns<AddressesTable> = {
  status: ['isNew', 'isBookmarked', 'hasActive'],
  score: ['score'],
  property: ['city', 'state', 'address1'],
  propertyId: ['propertyId'],
  stakeholders: ['applicantName', 'address1'],
  value: ['value'],
  size: ['size'],
  type: ['propertyType', 'score'],
  event: ['lastUpdated', 'score'],
  propertyCreatedAt: ['propertyCreatedAt', 'score'],
};

export const sortDict = {
  asc: '+',
  desc: '-',
};

export enum LeadType {
  Inbound = 'inbound',
  Converted = 'converted',
  Demoted = 'demoted',
}

export const LeadTypeDict: Record<LeadType, string> = {
  [LeadType.Inbound]: 'Inbound',
  [LeadType.Converted]: 'Converted',
  [LeadType.Demoted]: 'Demoted',
};

export const LeadTypeScoreStatus: Record<LeadType, ScoreStatus> = {
  [LeadType.Inbound]: ScoreStatus.Home,
  [LeadType.Converted]: ScoreStatus.Inactive,
  [LeadType.Demoted]: ScoreStatus.Inactive,
};
