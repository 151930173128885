import React from 'react';
import { makeStyles } from '@material-ui/core';

import EmptyBox from '../EmptyBox';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'scroll',
    height: '320px',
    alignContent: 'flex-start',
  },
}));

const EmptyPane: React.FC = () => {
  const classes = useStyles();
  return <EmptyBox className={classes.root} />;
};

export default EmptyPane;
