import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import 'mapbox-gl/dist/mapbox-gl.css';

import App from './App';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_REDIRECT_URI } from './constants';
import history from './utils/history';

type AppState = {
  returnTo?: string;
  [key: string]: any;
};

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState.returnTo || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={AUTH0_REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <Router history={history}>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
