import { makeStyles } from '@material-ui/core/styles';

export const useProjectLeadDetailsHeaderStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  score: {
    flex: 2,
  },
  projectName: {
    justifyContent: 'flex-start',
    flex: 10,
    fontWeight: 'bold',
  },
  buttons: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
  },
  favorite: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
