import type { IResponse } from './api';
import API from './api';

import type {
  IFeedbackResponse,
  ITopKeywordsResponse,
  IPropertyResponse,
  IEventResponse,
  IProjectRequest,
  IProjectResponse,
  IStakeholderResponse,
  IOrganizationResponse,
  IPropertyRequest,
} from '../models';
import type {
  IAddPropertyStakeholderRequest,
  IPropertyStakeholderRequest,
} from '../models/stakeholder';
import { Feedback, Property, Event, Stakeholder, Project, Organization } from '../models';
import encodeFilter from '../utils/encodeFilter';
import { FilterQuery } from '../types';

const formatPropertyData = (
  values: Partial<IPropertyRequest>,
  encode = false,
): Partial<IPropertyRequest> => {
  const params: Partial<IPropertyRequest> = {};
  Object.keys(values).forEach(key => {
    if (!values[(key as any) as keyof Partial<IPropertyRequest>]) {
      return;
    }
    let requestKey = key;
    if (key === 'address1') {
      requestKey = 'address_1';
    } else if (key === 'address2') {
      requestKey = 'address_2';
    }

    const newValue = values[(key as any) as keyof Partial<IPropertyRequest>] as string;
    params[(requestKey as any) as keyof Partial<IPropertyRequest>] = encode
      ? encodeURIComponent(newValue)
      : newValue;
  });
  return params;
};

export const createProperty = async (values: IPropertyRequest): Promise<Property> => {
  const { data } = await API.post(`/properties`, formatPropertyData(values));
  return new Property(data as IPropertyResponse);
};

export const getProperties = async (values: Partial<IPropertyRequest>): Promise<Property[]> => {
  const { data } = await API.get(`/properties`, { params: formatPropertyData(values, true) });
  return (data as IResponse<IPropertyResponse>).items.map(item => new Property(item));
};

export const getPropertyById = async (id: string): Promise<Property> => {
  const { data } = await API.get(`/properties/${id}`, { params: { details: true } });
  return new Property(data as IPropertyResponse);
};

export const updatePropertyById = async (
  id: string,
  params: Partial<Property>,
): Promise<IPropertyResponse> => {
  const { data } = await API.patch(`/properties/${id}`, params);
  return data as IPropertyResponse;
};

export const getPropertyFeedbacks = async (id: string): Promise<Feedback[]> => {
  const { data } = await API.get(`/properties/${id}/feedbacks`);
  return (data as IResponse<IFeedbackResponse>).items.map(item => new Feedback(item));
};

export const getPropertyKeywords = async (id: string): Promise<ITopKeywordsResponse> => {
  const { data } = await API.get(`/properties/${id}/keywords`);
  return data as ITopKeywordsResponse;
};

export const addPropertyBookmark = async (id: string): Promise<void> => {
  return API.post(`/properties/${id}/bookmark`);
};

export const deletePropertyBookmark = async (id: string): Promise<void> => {
  return API.delete(`/properties/${id}/bookmark`);
};

export const addFeedbackToProperty = async (id: string, notes: string): Promise<Feedback> => {
  const { data } = await API.post(`/properties/${id}/feedbacks`, { notes, action: 'post' });
  return new Feedback(data as IFeedbackResponse);
};

export const editFeedbackToProperty = async (id: string, notes: string): Promise<Feedback> => {
  const { data } = await API.patch(`/feedbacks/${id}`, { notes, action: 'patch' });
  return new Feedback(data as IFeedbackResponse);
};

export const deleteFeedbackToProperty = async (id: string): Promise<void> => {
  await API.delete(`/feedbacks/${id}`);
  return;
};

export const getPropertyEvents = async (id: string): Promise<IResponse<Event>> => {
  const { data } = await API.get(`/properties/${id}/events`);
  const events = (data as IResponse<IEventResponse>).items.map(item => new Event(item));
  return { items: events, total: data.total };
};

export const getPropertyProjects = async (
  id: string,
  { sort, offset, limit, query }: FilterQuery,
): Promise<IResponse<Project>> => {
  const configOptions = {
    sort,
    query,
    limit: limit || 10,
    offset: offset || 0,
  };
  const { data } = await API.get(`/properties/${id}/projects`, {
    params: {
      filter: encodeFilter(configOptions),
    },
  });
  const projects = (data as IResponse<IProjectResponse>).items.map(item => new Project(item));
  return { items: projects, total: data.total };
};

export const addPropertyProject = async (
  id: string,
  project: Partial<IProjectRequest>,
): Promise<Project> => {
  const { data } = await API.post(`/properties/${id}/projects`, project);
  return new Project(data as IProjectResponse);
};

export const getPropertyStakeholders = async (id: string): Promise<IResponse<Stakeholder>> => {
  const { data } = await API.get(`/properties/${id}/stakeholders`);
  const items = (data as IResponse<IStakeholderResponse>).items.map(item => new Stakeholder(item));
  return { ...data, items };
};

export const createPropertyStakeholder = async (
  id: string,
  propertyStakeholder: IPropertyStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.post(`/properties/${id}/stakeholders`, propertyStakeholder);
  return new Stakeholder(data as IStakeholderResponse);
};

export const addPropertyStakeholder = async (
  id: string,
  stakeholderId: string,
  propertyStakeholder: IAddPropertyStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.post(
    `/properties/${id}/stakeholders/${stakeholderId}`,
    propertyStakeholder,
  );
  return new Stakeholder(data as IStakeholderResponse);
};

export const deletePropertyStakeholders = async (
  id: string,
  stakeholderIds: string[],
): Promise<void> => {
  await API.delete(`/properties/${id}/stakeholders`, {
    data: { stakeholderIds },
  });
  return;
};

export const getPropertyOrganizations = async (id: string): Promise<IResponse<Organization>> => {
  const { data } = await API.get(`/properties/${id}/organizations`);
  const items = (data as IResponse<IOrganizationResponse>).items.map(
    item => new Organization(item),
  );
  return { ...data, items };
};

export const demotePropertyById = async (
  id: string,
  params: Partial<{ reason: string; score?: string; note?: string }>,
): Promise<Project> => {
  const { data } = await API.patch(`/properties/${id}/demote`, params);
  return new Project(data as IProjectResponse);
};

export const demotePropertiesById = async (
  params: Partial<{
    reason: string;
    score?: string;
    note?: string;
    idList: string[];
  }>,
): Promise<Project[]> => {
  const { data } = await API.patch(`/properties/demotions`, params);
  const projects = data.map((dataset: IProjectResponse) => new Project(dataset));
  return projects;
};
