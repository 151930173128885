import React, { ChangeEvent, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, styled, Tabs, Tab, withStyles, Box } from '@material-ui/core';

import { primaryBlue, darkLight, white } from '../../../../theme';
import { FilterData } from '../../../../types';

export interface StatusBarTabItem<T> {
  status: T;
  text: string;
  defaultFilter: FilterData;
  disabled?: boolean;
}

interface StatusBarProps<T> {
  statusMenu: StatusBarTabItem<T>[];
  selectedStatus: T;
  pathPrefix: string;
  id?: string;
  onChange: (scoreStatus: T) => void;
}

export interface TabStylesProps {
  fontSize?: string;
}

export const tabStyles = makeStyles({
  root: {
    zIndex: 10,
    bottom: '-.1rem',
    backgroundColor: 'white !important',
    color: darkLight,
    fontWeight: 'normal',
    fontSize: (props?: TabStylesProps) => (props?.fontSize ? props.fontSize : '1.21rem'),
    '&:hover': {
      backgroundColor: 'white !important',
      color: `${primaryBlue} !important`,
      borderRadius: 5,
    },
  },
  selected: {
    fontWeight: 'bold',
    backgroundColor: 'white !important',
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    height: 10,
    overflow: 'hidden',
    borderBottom: `6px solid ${primaryBlue}`,
    paddingBottom: '0rem',
  },
  wrapper: {
    border: 'none',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
});

const StyledTabs = withStyles({
  root: {
    minWidth: '90%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '35rem',
  },
  indicator: {
    backgroundColor: white,
  },
})(Tabs);

const StatusBarContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export default function StatusBar<T>({
  statusMenu,
  selectedStatus,
  pathPrefix,
  onChange,
  id,
}: StatusBarProps<T>): JSX.Element {
  const handleChange = useCallback(
    (e: ChangeEvent<unknown>, value: T) => {
      e.stopPropagation();
      onChange(value);
    },
    [onChange],
  );

  return (
    <StatusBarContainer>
      <StyledTabs
        value={selectedStatus}
        onChange={handleChange}
        aria-label="lead status tab"
        textColor="secondary"
      >
        {statusMenu
          .map(({ status, text, disabled }, idx) => {
            if (!status) {
              return null;
            }
            const toLink = [pathPrefix, status, id].filter(Boolean).join('/');
            if (text === 'Filter Details') {
              return (
                <Tab
                  key={`${text}-${idx}`}
                  style={{ visibility: 'hidden', width: 0 }}
                  value={status}
                />
              );
            }
            return (
              <Tab
                key={`${text}-${idx}`}
                disabled={disabled}
                value={status}
                classes={tabStyles()}
                textColor="secondary"
                label={text}
                component={NavLink}
                to={toLink}
              />
            );
          })
          .filter(Boolean)}
      </StyledTabs>
    </StatusBarContainer>
  );
}
