import { makeStyles } from '@material-ui/core';
import { light } from '@material-ui/core/styles/createPalette';

import { darkBlue, darkLight, white } from '../../../theme';

const useDataMapperConfigurationsTableStyles = makeStyles(theme => ({
  tableCount: {
    fontSize: '0.71rem',
    color: darkBlue,
    fontWeight: 'bold',
    marginTop: '1rem',
  },
  dataMappingsTableContainer: {
    marginTop: '1rem',

    '& .MuiBox-root': {
      width: '97%',
    },
  },
  table: {
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
  headCell: {
    padding: theme.spacing(0, 1),
  },
  label: {
    fontWeight: 'bold',
    color: `${theme.palette.primary.main} !important`,
    whiteSpace: 'nowrap',
  },
  labelActive: {
    color: `${theme.palette.success.main} !important`,
  },
  row: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px 0 0 8px',
    width: '97%',
  },
  rowCell: {
    margin: 0,
    border: `2px solid ${darkLight}`,
    padding: theme.spacing(2, 0),
    borderRadius: 8,
    backgroundColor: white,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: light,
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        padding: theme.spacing(0, 1),
      },
    },
  },
}));

export default useDataMapperConfigurationsTableStyles;
