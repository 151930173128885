import React from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import { TableBody as MuiTableBody } from '@material-ui/core';

import { ReactTableRow } from './ReactTableRow';

interface ReactTableBodyProps<TableType extends Record<any, any>> {
  rows: Row<TableType>[];
  getTableBodyProps: (propGetter?: TableBodyPropGetter<TableType> | undefined) => TableBodyProps;
  prepareRow: (row: Row<TableType>) => void;
}

export const ReactTableBody = <TableType extends Record<any, any>>({
  rows,
  getTableBodyProps,
  prepareRow,
}: ReactTableBodyProps<TableType>): JSX.Element => {
  return (
    <MuiTableBody {...getTableBodyProps()}>
      {rows.map((row: Row<TableType>) => {
        prepareRow(row);
        return <ReactTableRow<TableType> row={row} key={row.id} />;
      })}
    </MuiTableBody>
  );
};
