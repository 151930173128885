import * as Yup from 'yup';

import { DodgeProjectLead, Feedback, Stakeholder } from '.';

import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';
import type { IEventResponse } from './event';
import Event from './event';
import type { IProjectResponse } from './project';
import Project from './project';
import { IStakeholderResponse } from './stakeholder';
import { IFeedbackResponse } from './feedback';
import { IDodgeProjectLeadResponse } from './dodgeProjectLead';
import Keyword from './keyword';

import type { Optional } from '../types';
import { US_STATES } from '../constants';
import { toTitleCase } from '../utils/string';
import { getFullAddress } from '../utils/address';

export type IScoreResponse = IBaseModelResponse & {
  score: number;
};

export type TopKeywords = {
  positive: string[];
  negative: string[];
};

export type IPropertyResponse = IBaseModelResponse & {
  name?: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  zipcode: string;
  is_bookmarked: boolean;
  phone_number?: string;
  contact?: { [key: string]: string[] };
  size?: number;
  type: string;
  valuation?: number;
  topKeywords?: Keyword[];
  top_keywords?: TopKeywords;
  total_stakeholders?: number | string;
  total_events?: number | string;
  events?: IEventResponse[];
  projects?: IProjectResponse[];
  score?: IScoreResponse;
  salesforce_payload?: JSON;
  estated_payload?: JSON;
  stakeholders?: IStakeholderResponse[];
  allStakeholders?: IStakeholderResponse[];
  feedbacks?: IFeedbackResponse[];
  projectLeads?: IDodgeProjectLeadResponse[];
  property_score?: number;
  email?: string;
  website?: string;
};
export type IPropertyRequest = Pick<
  IPropertyResponse,
  'name' | 'address_1' | 'address_2' | 'city' | 'state' | 'zipcode'
>;

export const PropertyRequestSchema = Yup.object().shape({
  name: Yup.string(),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().oneOf(Object.keys(US_STATES), 'Required'),
  zipcode: Yup.string().required('Required').length(5),
});

export default class Property extends BaseModel {
  name?: string;
  address1!: string;
  address2?: string;
  city!: string;
  state!: string;
  zipcode!: string;
  is_bookmarked!: boolean;
  phone?: string;
  size?: number;
  type!: string;
  valuation?: number;
  topKeywords?: TopKeywords;
  newTopKeywords?: Keyword[];
  totalStakeholders!: number;
  totalEvents!: number;
  events?: Event[];
  projects?: Project[];
  feedbacks?: Feedback[];
  score?: number;
  salesforce_payload?: JSON;
  estated_payload?: JSON;
  stakeholders?: Stakeholder[];
  dodgeProjectLeads?: DodgeProjectLead[];
  allStakeholders?: Stakeholder[];
  email?: string;
  website?: string;

  static get modelName(): string {
    return 'property';
  }

  constructor(data: IPropertyResponse) {
    super(data);

    this.update(data);
  }

  update(data: IPropertyResponse): Property {
    const {
      name,
      address_1,
      address_2,
      city,
      state,
      zipcode,
      is_bookmarked,
      phone_number,
      size,
      type,
      valuation,
      total_stakeholders,
      total_events,
      events,
      score,
      salesforce_payload,
      estated_payload,
      stakeholders,
      topKeywords,
      top_keywords,
      projects,
      feedbacks,
      projectLeads,
      allStakeholders,
      email,
      website,
    } = data;
    this.name = name;
    this.address1 = toTitleCase(address_1) as string;
    this.address2 = toTitleCase(address_2);
    this.city = toTitleCase(city) as string;
    this.state = state?.toUpperCase();
    this.zipcode = zipcode;
    this.is_bookmarked = is_bookmarked;
    this.phone = phone_number;
    this.email = email;
    this.website = website;
    this.size = size;
    this.type = type;
    this.valuation = valuation;
    this.salesforce_payload = salesforce_payload;
    this.estated_payload = estated_payload;
    this.stakeholders =
      stakeholders && stakeholders.map(stakeholder => new Stakeholder(stakeholder));
    this.allStakeholders =
      allStakeholders && allStakeholders.map(stakeholder => new Stakeholder(stakeholder));
    this.projects = projects && projects.map(data => new Project(data));
    this.feedbacks = feedbacks && feedbacks.map(data => new Feedback(data));
    this.dodgeProjectLeads = projectLeads && projectLeads.map(data => new DodgeProjectLead(data));

    if (top_keywords) {
      this.topKeywords = top_keywords;
    }

    if (topKeywords) {
      this.newTopKeywords = topKeywords;
    }

    if (events) {
      this.events = events.map(data => new Event(data));
    }

    if (score) {
      this.score = score.score;
    }

    this.totalStakeholders = total_stakeholders ? Number(total_stakeholders) : 0;
    this.totalEvents = total_events ? Number(total_events) : this.events?.length || 0;

    return this;
  }

  get cityStateZip(): string {
    return `${this.city}, ${this.state} ${this.zipcode}`;
  }

  get leadName(): string {
    return [this.name, this.address1].filter(x => !!x).join(', ');
  }

  get shortLeadName(): string {
    return this.name || this.address1;
  }

  get activeProject(): Optional<Project> {
    return this.projects?.find(project => project.isActive);
  }

  get totalProjectsCount(): number {
    return this.projects?.length || 0;
  }

  get fullAddress(): string {
    return getFullAddress({
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
    });
  }

  get phoneNumber(): string {
    return this.phone ? `+1 ${this.phone}` : '';
  }
}
