import React from 'react';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import useTransitionButtonsActiveStyles from './TransitionButtonsActive.styles';

import {
  CleoObjectType,
  ProjectDemotionReason,
  ProjectStatusAction,
} from '../../../../models/common';
import { updateProjectStateById } from '../../../../api/projects';
import salesforceLogo from '../../../../resources/images/salesforce.svg';
import CleoButton, { CleoButtonProps } from '../../../Common/CleoButton';

interface IProps {
  refreshPropertyDetails: () => void;
  toggleProjectStatusModal: (modal: ProjectStatusAction) => void;
  activeProjectId: string;
  objectType?: CleoObjectType;
  disabled?: boolean;
}

const TransitionButtonsActive: React.FC<IProps> = ({
  activeProjectId,
  refreshPropertyDetails,
  toggleProjectStatusModal,
  disabled = false,
  objectType = CleoObjectType.Addresses,
}: IProps) => {
  const classes = useTransitionButtonsActiveStyles();
  let props: CleoButtonProps;
  if (objectType === CleoObjectType.Addresses) {
    props = {
      className: classes.transitionButtons,
      color: 'primary',
      size: 'medium',
    };
  } else {
    props = {
      style: { minWidth: '100px', margin: '0 .5rem', padding: '.125rem 1rem' },
      variant: undefined,
    };
  }

  const demoteLabel = objectType === CleoObjectType.ProjectLeads ? 'DEMOTE' : 'DEMOTE PROJECT';
  const convertLabel = objectType === CleoObjectType.ProjectLeads ? 'CONVERT' : 'CONVERT PROJECT';

  return (
    <div className={classes.transitionButtonsContainer}>
      <CleoButton
        disabled={disabled}
        className={clsx(classes.transitionButtons, classes.salesforceLogoButton)}
        color="primary"
        size="medium"
        variant="outlined"
        aria-label={'Demote Opportunity Already In Salesforce'}
        onClick={async () => {
          setTimeout(async () => {
            const transitionDetails = {
              reason: ProjectDemotionReason.AlreadyTracked,
            };
            await updateProjectStateById(
              activeProjectId,
              ProjectStatusAction.Demote,
              transitionDetails,
            );
            refreshPropertyDetails();
          }, 500);
        }}
      >
        <Tooltip
          title={'This button icon is to tag a project as already active in Salesforce'}
          placement="top"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrowTip,
          }}
          arrow
        >
          <img src={salesforceLogo} alt="Salesforce logo" className={classes.salesforceLogo} />
        </Tooltip>
      </CleoButton>
      <CleoButton
        primary={true}
        variant={'contained'}
        aria-label={activeProjectId ? 'Convert Active Project' : 'Create a project to convert'}
        disabled={disabled || !activeProjectId}
        onClick={() => toggleProjectStatusModal(ProjectStatusAction.Convert)}
        {...props}
      >
        {convertLabel}
      </CleoButton>
      <CleoButton
        primary={false}
        aria-label={activeProjectId ? 'Demote Active Project' : 'Demote all unassociated events'}
        disabled={disabled || !activeProjectId}
        onClick={() => toggleProjectStatusModal(ProjectStatusAction.Demote)}
        variant={'outlined'}
        {...props}
      >
        {demoteLabel}
      </CleoButton>
    </div>
  );
};

export default TransitionButtonsActive;
