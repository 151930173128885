import { Box, Grid, GridProps, Hidden, TableSortLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { MouseEvent, ReactNode } from 'react';

import useSavedFiltersTableHeaderStyles from './SavedFiltersTableHeader.styles';
import { SavedFilter } from './SavedFilters';

import { SortOrder } from '../../../../models/lead';

type SavedFiltersHeadCell = {
  id: keyof SavedFilter | 'delete';
  label: ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  gridProps: GridProps;
  sortable: boolean;
};
export const savedFiltersHeadCells: SavedFiltersHeadCell[] = [
  {
    id: 'filter_name',
    label: 'Filter Name',
    textAlign: 'left',
    gridProps: { md: 2 },
    sortable: true,
  },
  {
    id: 'filter_tags',
    label: 'Selected Filter',
    textAlign: 'left',
    gridProps: { md: 6 },
    sortable: false,
  },
  { id: 'user', label: 'Owner', textAlign: 'left', gridProps: { md: 1 }, sortable: true },
  {
    id: 'created_at',
    label: 'Created',
    textAlign: 'left',
    gridProps: { md: 1 },
    sortable: true,
  },
  {
    id: 'updated_at',
    label: 'Edited',
    textAlign: 'left',
    gridProps: { md: 1 },
    sortable: true,
  },
  { id: 'delete', label: '', textAlign: 'left', gridProps: { md: 1 }, sortable: false },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof SavedFilter,
    order: SortOrder,
  ) => void;
  order: SortOrder;
  orderBy: string;
}

const SavedFiltersTableHeader: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) => {
  const classes = useSavedFiltersTableHeaderStyles();
  return (
    <Box mb={2} borderRadius={2} className={classes.savedFilterHeadCellRoot}>
      <Grid container alignItems="center">
        {savedFiltersHeadCells.map(({ id, label, textAlign = 'left', gridProps, sortable }) => {
          const isActive = orderBy === id;
          const labelClasses = clsx(classes.label, { [classes.savedFilterLabelActive]: isActive });
          return (
            <Hidden key={id}>
              <Grid
                item
                style={{ textAlign }}
                {...gridProps}
                className={classes.savedFilterHeadCell}
              >
                <TableSortLabel
                  classes={{ icon: labelClasses, active: classes.labelActive }}
                  hideSortIcon={!sortable}
                  active={isActive && sortable}
                  direction={isActive ? order : 'asc'}
                  onClick={sortable ? e => onRequestSort(e, id, order) : undefined}
                >
                  <Typography
                    className={clsx(
                      classes.savedFilterLabel,
                      isActive ? classes.labelActive : classes.label,
                    )}
                    component="span"
                    variant={'body1'}
                  >
                    {label}
                  </Typography>
                  {isActive && sortable && (
                    <span className={classes.hiddenSortOrder}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  )}
                </TableSortLabel>
              </Grid>
            </Hidden>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SavedFiltersTableHeader;
