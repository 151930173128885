import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';

import useSavedFiltersStyles from './SavedFilters.styles';
import SavedFiltersTableHeader from './SavedFiltersTableHeader';
import SavedFiltersTable from './SavedFiltersTable';

import { getSavedFiltersList } from '../../../../api/filters';
import { SortOrder } from '../../../../models/lead';
import { FilterTagsResponse, UserFilterNameResponse } from '../../../../types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { CleoObjectType } from '../../../../models/common';

interface ISavedFiltersProps<T> {
  scoreStatus: T;
  cleoObjectType?: CleoObjectType;
}

export interface SavedFilter {
  filter_name: string;
  user: string;
  created_at: string;
  updated_at: string;
  delete: string;
  filter_tags: FilterTagsResponse;
}

interface Sort {
  orderBy: keyof SavedFilter;
  order: SortOrder;
}

function SavedFilters<T>({
  scoreStatus,
  cleoObjectType = CleoObjectType.Addresses,
}: ISavedFiltersProps<T>) {
  const classes = useSavedFiltersStyles();
  const [savedFilterStartIndex, setSavedFilterStartIndex] = useState<number>(0);
  const [savedFiltersList, setSavedFiltersList] = useState<UserFilterNameResponse[]>([]);
  const [savedFiltersHaveMoreItems, setSavedFiltersHaveMoreItems] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [order, setOrder] = React.useState<SortOrder>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof SavedFilter>('filter_name');
  const [sort, setSort] = useState<Sort>({ orderBy: 'filter_name', order: 'desc' });
  const ariaLabel = cleoObjectType === CleoObjectType.Addresses ? 'addresses' : 'project leads';

  const loadSavedFilters = useCallback(
    async (savedFilterStartIndex: number, deletedUserFilterId?: string) => {
      try {
        const savedFilters = await getSavedFiltersList(sort, cleoObjectType);
        setSavedFiltersList(prev => {
          if (deletedUserFilterId) {
            prev = prev.filter(savedFilter => savedFilter.id !== deletedUserFilterId);
            const amendedSavedFilters = savedFilters.filter(
              savedFilter => savedFilter.id !== deletedUserFilterId,
            );

            return uniqBy(
              [
                ...prev,
                ...amendedSavedFilters.slice(
                  savedFilterStartIndex,
                  savedFilterStartIndex + DEFAULT_PAGE_SIZE,
                ),
              ],
              'id',
            );
          }

          return uniqBy(
            [...savedFilters.slice(0, savedFilterStartIndex + DEFAULT_PAGE_SIZE)],
            'id',
          );
        });
        if (
          savedFilters.slice(savedFilterStartIndex, savedFilterStartIndex + DEFAULT_PAGE_SIZE)
            .length < DEFAULT_PAGE_SIZE
        ) {
          setSavedFiltersHaveMoreItems(false);
        }
      } catch (e: any) {
        if (e.response && e.response.data) {
          setErrorMessage(e.response.data.message);
        }
      }
    },
    [setSavedFiltersList, sort, cleoObjectType],
  );

  const handleSortRequest = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof SavedFilter) => {
      event.preventDefault();
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setSort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    },
    [order, orderBy],
  );

  useEffect(() => {
    loadSavedFilters(savedFilterStartIndex);
  }, [loadSavedFilters, savedFilterStartIndex]);

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }
  return (
    <div
      aria-label={ariaLabel}
      className={clsx(classes.leadListTableContainer, classes.savedFiltersTableContainer)}
    >
      <SavedFiltersTableHeader order={order} orderBy={orderBy} onRequestSort={handleSortRequest} />
      <SavedFiltersTable
        savedFiltersList={savedFiltersList}
        reloadSavedFiltersList={loadSavedFilters}
        savedFilterStartIndex={savedFilterStartIndex}
        savedFiltersHaveMoreItems={savedFiltersHaveMoreItems}
        scoreStatus={scoreStatus}
        setSavedFilterStartIndex={setSavedFilterStartIndex}
        cleoObjectType={cleoObjectType}
      />
    </div>
  );
}

export default SavedFilters;
