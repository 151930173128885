import { makeStyles } from '@material-ui/core';

import { lightGray } from '../../../../theme';

const useSavedFiltersTableHeaderStyles = makeStyles(theme => ({
  savedFilterHeadCell: {
    padding: theme.spacing(0, 2),
  },
  savedFilterHeadCellRoot: {
    padding: theme.spacing(2, 4),
    marginBottom: 0,
    backgroundColor: lightGray,
  },
  savedFilterLabel: {
    fontWeight: 'bold',
    color: `${theme.palette.primary.main} !important`,
    whiteSpace: 'nowrap',
  },
  savedFilterLabelActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  label: {
    fontWeight: 'normal',
    color: `${theme.palette.primary.main}`,
    whiteSpace: 'nowrap',
  },
  labelActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  hiddenSortOrder: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default useSavedFiltersTableHeaderStyles;
