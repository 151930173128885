import { atom } from 'recoil';

import DataMapper from '../../models/dataMapper';

interface DataMapperRows {
  items: DataMapper[];
  total: number;
}

interface QueryParameters {
  key: string;
  value: string;
}

export interface ZipcodeFields {
  boundaryObj: string;
  zipcodeProp: string;
}

interface DataMapperConfig {
  name: string;
  description?: '';
  url: string;
  zipcodeBoundaryAPI: string;
  queryParams: QueryParameters[];
  dataProp: string;
  zipcodeFields: ZipcodeFields;
}

export const dataMapperRowsState = atom({
  key: 'dataMapperRows',
  default: {
    items: [],
    total: 0,
  } as DataMapperRows,
});

export const dataMapperDataResponse = atom({
  key: 'dataMapperDateResponse',
  default: {} as { [index: string]: any },
});

export const dataMapperZipcodeBoundaryResponse = atom({
  key: 'dataMapperZipcodeBoundaryResponse',
  default: [] as any[],
});

export const dataMapperConfigState = atom({
  key: 'dataMapperConfig',
  default: {
    name: '',
    url: '',
    zipcodeBoundaryAPI: '',
    description: '',
    queryParams: [{ key: '', value: '' }],
    dataProp: '',
    zipcodeFields: {
      boundaryObj: '',
      zipcodeProp: '',
    },
  } as DataMapperConfig,
});

export const dataMapperColumnsFromUrlState = atom({
  key: 'dataMapperColumnsFromUrl',
  default: {} as any,
});

export const dataMapperRowsFromUrlState = atom({
  key: 'dataMapperRowsFromUrl',
  default: [] as any,
});

export const dataMapperPreviewState = atom({
  key: 'dataMapperPreview',
  default: [] as any,
});

export const dataMapperPreviewRolesState = atom({
  key: 'dataMapperPreviewRoles',
  default: {
    eventsOrganizations: '',
    eventsStakeholders: '',
    stakeholdersOrganizations: '',
  } as any,
});

export const dataMapperETLRequestState = atom({
  key: 'dataMapperETLRequest',
  default: {
    source_label: '',
    source_name: '',
    data_mapper_name: '',
    dataset_config: {
      columns: [],
      roles: {
        eventsOrganizations: '',
        eventsStakeholders: '',
        stakeholdersOrganizations: '',
      },
      url: '',
      dataProp: '',
      zipcodeBoundaryAPI: '',
      zipcodeFields: {
        boundaryObj: '',
        zipcodeProp: '',
      },
    },
    cron: '',
    settings: {
      fromDate: '',
      limit: 5000,
    },
  },
});
