import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

import { light } from '../../theme';

const useStyles = makeStyles({
  root: {
    borderTop: `1px solid ${light}`,
    minHeight: '55px',
  },
});

type IProps = {
  className?: string;
};

const PaperFooter: React.FC<IProps> = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Box p={3} className={clsx(classes.root, className)}>
      {children}
    </Box>
  );
};

export default PaperFooter;
