import { makeStyles } from '@material-ui/core';

const useSavedFiltersStyles = makeStyles(() => ({
  leadListTableContainer: {
    height: 1,
  },
  savedFiltersTableContainer: {
    margin: '2rem 2rem 0 2rem',
  },
  savedFilterBorderClass: {
    justifyContent: 'center',
  },
}));

export default useSavedFiltersStyles;
