import * as Yup from 'yup';

import type { IBaseContactRequest, IBaseContactResponse } from './baseContact';
import BaseContact, { BaseContactRequestSchema } from './baseContact';
import type { StakeholderRole } from './common';
import { ProfileType } from './common';
import { IAccountListAccountResponse } from './accountListAccount';
import Account, { IAccountResponse } from './account';

export type IOrganizationResponse = IBaseContactResponse & {
  name?: string;
  profile_type?: ProfileType;
  license_type?: string;
  license_number?: string;
  license_expiration_date?: string;
  salesforce_id?: string;
  total_leads?: number;
  total_stakeholders?: number;
  stakeholder_role?: StakeholderRole;
  sf_rating?: string;
  incremental_flag?: boolean;
  sf_type?: string;
  sf_loyalty?: string;
  incremental_reason?: string;
  stakeholders_count?: number;
  project_leads_count?: number;
  events_count?: number;
  total_contacts?: number;
  total_projects?: number;
  total_properties?: number;
  incremental_count?: number;
  sf_owner_name?: string;
  sf_last_modified_date?: string;
  account?: IAccountResponse;
  role?: string;
};

export type IOrganizationWithCountResponse = IOrganizationResponse & {
  total_contacts?: number;
  total_projects?: number;
  total_properties?: number;
  incremental_count?: number;
};

// organization graph fetched with account
export type IOrganizationWithAccount = IOrganizationResponse & {
  account?: IAccountListAccountResponse;
};

export type IOrganizationRequest = IBaseContactRequest &
  Pick<IOrganizationResponse, 'profile_type'>;

export const CreateOrganizationSchema = Yup.object().shape({
  ...BaseContactRequestSchema,
  name: Yup.string().required('Please enter a name.'),
  address1: Yup.string().required('Please enter a street address.'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().length(2, 'Invalid state').required('Required'),
  zipcode: Yup.string().length(5, 'Invalid ZIP code').required('Required'),
});

export const OrganizationRequestSchema = Yup.object().shape({
  ...BaseContactRequestSchema,
  profile_type: Yup.string().nullable(),
});

export default class Organization extends BaseContact {
  account?: Account;
  licenseType?: string;
  profileType?: ProfileType;
  licenseNumber?: string;
  licenseExpirationDate?: string;
  salesforceId?: string;
  totalLeads: number;
  totalStakeholders: number;
  sfRating?: string;
  incrementalFlag = false;
  sfType?: string;
  sfLoyalty?: string;
  sfOwnerName?: string;
  sfLastModifiedDate?: string;
  incrementalReason?: string[];
  cStakeholders?: number;
  cProjectLeads?: number;
  cEvents?: number;
  role?: string;

  static get modelName(): string {
    return 'organization';
  }

  constructor(data: IOrganizationResponse) {
    super(data);

    const {
      license_type,
      profile_type,
      license_number,
      license_expiration_date,
      salesforce_id,
      total_leads,
      total_stakeholders,
      sf_rating,
      sf_type,
      sf_loyalty,
      sf_owner_name,
      sf_last_modified_date,
      incremental_flag,
      incremental_reason,
      stakeholders_count,
      project_leads_count,
      events_count,
      total_contacts,
      total_projects,
      total_properties,
      account,
      role,
    } = data;
    if (account) {
      this.account = new Account(account);
    }

    this.licenseType = license_type;
    this.profileType = profile_type;
    this.licenseNumber = license_number;
    this.licenseExpirationDate = license_expiration_date;
    this.salesforceId = salesforce_id;
    const totalLeads = Number(total_leads);
    this.totalLeads = total_properties || Number.isNaN(totalLeads) ? 0 : totalLeads;
    const totalStakeholders = Number(total_stakeholders);
    this.totalStakeholders = Number.isNaN(totalStakeholders) ? 0 : totalStakeholders;
    this.sfRating = sf_rating || undefined;
    this.sfType = sf_type;
    this.sfLoyalty = sf_loyalty;
    this.sfOwnerName = sf_owner_name;
    this.sfLastModifiedDate = sf_last_modified_date;
    this.incrementalFlag = incremental_flag || false;
    this.cStakeholders = total_contacts || stakeholders_count;
    this.cProjectLeads = total_projects || project_leads_count;
    this.cEvents = total_properties || events_count;
    this.incrementalReason = incremental_reason ? incremental_reason?.split(',') : [];
    this.role = role;
  }

  get companyLicense(): string {
    return `${this.licenseType}, ${this.licenseNumber}`;
  }

  get isNational(): boolean {
    return this.sfType === 'CSA Account' || this.sfLoyalty === 'Target';
  }

  get isCSAAccount(): boolean {
    return this.sfType === 'CSA Account';
  }

  get isTarget(): boolean {
    return this.sfLoyalty === 'Target';
  }
}

/**
 * Class to populate account details on accounts page
 * */
export type IOrgWithAccountDetails = IOrganizationResponse & {
  vol_of_business: number;
  primary_account_data?: IAccountResponse;
  spec_rate_awi: number;
  spec_rate_certainteed: number;
  spec_rate_rockfon: number;
  spec_rate_usg: number;
  win_rate: number;
};

export class OrganizationWithAccountDetails extends Organization {
  volOfBusiness: number;
  primaryAccountData?: Account;
  specRateAWI?: number;
  specRateCT?: number;
  specRateRockfon?: number;
  specRateUSG?: number;
  winRate: number;

  constructor(data: IOrgWithAccountDetails) {
    super(data);
    const {
      vol_of_business,
      primary_account_data,
      spec_rate_awi,
      spec_rate_certainteed,
      spec_rate_rockfon,
      spec_rate_usg,
      win_rate,
    } = data;
    this.volOfBusiness = vol_of_business;
    this.specRateAWI = spec_rate_awi;
    this.specRateCT = spec_rate_certainteed;
    this.specRateRockfon = spec_rate_rockfon;
    this.specRateUSG = spec_rate_usg;
    this.winRate = win_rate;

    if (primary_account_data) {
      this.primaryAccountData = new Account(primary_account_data);
    }
  }

  static get modelName(): string {
    return 'organizationWithVolume';
  }
}

export type IAccountOrg = IOrganizationResponse & IAccountListAccountResponse;
export type IAccountDuplicates = {
  original: IAccountOrg;
  duplicates: IAccountOrg[];
};

/**
 * Class to populate accounts list on accounts page
 * */
export class OrganizationAccount extends Organization {
  sf_rating: string;
  is_national: boolean;
  is_targeted_national: boolean;
  is_new: boolean;
  is_government: boolean;
  is_spec_alert: boolean;
  is_education: boolean;
  is_favorite: boolean;

  constructor(data: IAccountOrg) {
    super(data);
    const { is_national, is_targeted_national, is_new, sf_rating, incremental_reason } = data;
    this.sf_rating = sf_rating;
    this.is_national = is_national;
    this.is_targeted_national = is_targeted_national;
    this.is_new = is_new;
    this.is_government = !!incremental_reason?.includes('Government');
    this.is_education = !!incremental_reason?.includes('Education');
    this.is_spec_alert = !!incremental_reason?.includes('Spec Alert');
    this.is_favorite = false;
  }
}
