import * as Yup from 'yup';

import type { IBaseContactRequest, IBaseContactResponse } from './baseContact';
import BaseContact, { BaseContactRequestSchema } from './baseContact';
import { StakeholderRole } from './common';
import type { IOrganizationResponse } from './organization';
import Organization, { IOrganizationWithAccount } from './organization';
import Project from './project';
import Property from './property';

import { StakeholderRoleMapping } from '../constants';
import type { Optional } from '../types';
import { toTitleCase } from '../utils/string';

export type IStakeholderResponse = IBaseContactResponse & {
  title?: string;
  organization?: IOrganizationResponse;
  stakeholder_role?: StakeholderRole;
  is_primary?: boolean;
  salesforce_collision?: null | boolean;
  source_label?: null | string;
  source_name?: null | string;
  total_properties?: number;
  total_projects?: number;
};

export type IStakeholderRequest = IBaseContactRequest &
  Pick<IStakeholderResponse, 'title'> & {
    id?: string;
  };

export type IOrganizationStakeholderRequest = IStakeholderRequest & {
  stakeholder_role: StakeholderRole;
};

export type IProjectStakeholderRequest = IOrganizationStakeholderRequest & {
  is_primary: boolean;
};

// Stakeholder with graph fetched organization and account
export type IStakeholderWithOrgAndAccountRequest = IOrganizationStakeholderRequest & {
  organization?: IOrganizationWithAccount;
};

export type IAddProjectStakeholderRequest = Pick<
  IProjectStakeholderRequest,
  'stakeholder_role' | 'is_primary'
>;

export type IAddPropertyStakeholderRequest = Pick<IProjectStakeholderRequest, 'stakeholder_role'>;

export type IPropertyStakeholderRequest = IOrganizationStakeholderRequest;

export const StakeholderRequestSchema = Yup.object().shape({
  ...BaseContactRequestSchema,
  title: Yup.string(),
});

export const ProjectStakeholderRequestSchema = Yup.object().shape({
  ...BaseContactRequestSchema,
  name: BaseContactRequestSchema.name.required('Please enter a name.'),
  title: Yup.string(),
  stakeholder_role: Yup.string()
    .oneOf(Object.values(StakeholderRole))
    .required('Please select a project role.'),
  is_primary: Yup.bool(),
});

export default class Stakeholder extends BaseContact {
  title?: string;
  organization?: Organization;
  projects?: Project[];
  properties?: Property[];
  role?: StakeholderRole;
  isPrimary: boolean;
  salesforceCollision?: null | boolean;
  sourceName?: null | string;
  sourceLabel?: null | string;
  cProjects?: number;
  cProperties?: number;

  static get modelName(): string {
    return 'stakeholder';
  }

  constructor(data: IStakeholderResponse) {
    super(data);

    const {
      title,
      organization,
      stakeholder_role,
      is_primary,
      salesforce_collision,
      source_name,
      source_label,
      total_properties,
      total_projects,
    } = data;
    this.title = title;
    this.salesforceCollision = salesforce_collision;
    this.sourceName = source_name;
    this.sourceLabel = source_label;
    this.cProperties = total_properties;
    this.cProjects = total_projects;

    if (organization) {
      this.organization = new Organization(organization);
      this.role = organization.stakeholder_role;
    } else {
      this.role = stakeholder_role;
    }
    this.isPrimary = is_primary || false;
  }

  get applicantName(): string {
    return [this.role ? toTitleCase(this.role) : undefined, toTitleCase(this.name)]
      .filter(x => !!x)
      .join(', ');
  }

  get roleDisplayName(): Optional<string> {
    if (this.role && this.role !== StakeholderRole.Other) {
      return StakeholderRoleMapping[this.role];
    }

    const displayName = this.title || this.role;
    return displayName ? toTitleCase(displayName) : undefined;
  }
}
