import includes from 'lodash/includes';
import { selector } from 'recoil';

import { projectsSelector } from './projects';

import { Event } from '../../models';
import { currentPropertyState } from '../atoms/property';

export const eventsSelector = selector({
  key: 'events',
  get: ({ get }) => {
    const property = get(currentPropertyState);
    const projects = get(projectsSelector);
    if (property) {
      const { events } = property;
      const projectEventIds: string[] = [];
      projects.forEach(
        project =>
          project.events &&
          !project.isActive &&
          projectEventIds.push(...project.events.map(evt => evt.id)),
      );
      if (!!projectEventIds.length && events) {
        const availableEvents = events.filter(evt => !includes(projectEventIds, evt.id));
        return availableEvents;
      }
      return events as Event[];
    } else {
      return [] as Event[];
    }
  },
});
