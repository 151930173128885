import { makeStyles } from '@material-ui/core';

const useTableHeaderStyles = makeStyles((theme?: any) => ({
  root: {
    // width of popout icon plus icon's padding
    // paddingRight: 'calc(35px + 0.5em)',
    padding: '.5rem 0',
  },
  shiftedHeaderContainer: {
    padding: '.5rem 0 .5rem 3rem',
  },
  headCell: {
    padding: theme.spacing(0, 1),
  },
  label: {
    fontWeight: 'bold',
    color: `black !important`,
    whiteSpace: 'nowrap',
  },
  labelActive: {
    color: `${theme.palette.success.main} !important`,
  },
  hiddenSortOrder: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default useTableHeaderStyles;
