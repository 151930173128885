import React, { useCallback, useState } from 'react';
import { makeStyles, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useRecoilValue } from 'recoil';

import LeadCard from '../../Leads/LeadsTable/LeadCard';
import CreateProperty from '../../Leads/CreatePropertyForm';
import { addPropertyProject } from '../../../../api/properties';
import BorderedPaper from '../../../Common/BorderedPaper';
import FormNumberField from '../../../Common/FormNumberField';
import FormSelect from '../../../Common/FormSelect';
import FormTextField from '../../../Common/FormTextField';
import PaperHeader from '../../../Common/PaperHeader';
import PaperTitle from '../../../Common/PaperTitle';
import PaperBody from '../../../Common/PaperBody';
import { info, darkLight, light } from '../../../../theme';
import {
  ProjectSpaceTypeMapping,
  ProjectStageMapping,
  ProjectTypeMapping,
} from '../../../../constants';
import { useMessages } from '../../../../state/contexts';
import type { DodgeProjectLead, Event, IProjectRequest } from '../../../../models';
import { Project, ProjectRequestSchema, Property } from '../../../../models';
import EmptyBox from '../../../Common/EmptyBox';
import { Optional } from '../../../../types';
import { toSentence, toTitleCase } from '../../../../utils/string';
import { eventsSelector } from '../../../../state/selectors/events';

interface IProps {
  property?: Property;
  origin: string;
  onCreate: (project: Project) => void;
  dodgeProjectLead?: DodgeProjectLead;
}

const useStyles = makeStyles(theme => ({
  separator: {
    margin: theme.spacing(6, 0),
    borderColor: light,
    borderStyle: 'solid',
  },
}));

const CreateProject: React.FC<IProps> = ({ property, onCreate, origin, dodgeProjectLead }) => {
  const { setPageFetching, setErrorMessage } = useMessages();
  const events = useRecoilValue<Event[]>(eventsSelector);
  const [projectProperty, setProjectProperty] = useState<Optional<Property>>(property);
  const [createPropertyOpen, setCreatePropertyOpen] = useState(false);

  const handleSubmit = useCallback(
    async (values: IProjectRequest) => {
      if (!projectProperty) {
        return;
      }
      setPageFetching(true);
      try {
        const project = await addPropertyProject(projectProperty.id, { ...values, origin });
        onCreate(project);
      } catch (err: any) {
        setErrorMessage(err);
      }
      setPageFetching(false);
    },
    [projectProperty, origin, onCreate, setPageFetching, setErrorMessage],
  );

  const classes = useStyles();
  return (
    <Formik
      initialValues={
        {
          name:
            !!events?.length && dodgeProjectLead ? toTitleCase(dodgeProjectLead?.projectTitle) : '',
          value: !!events?.length && dodgeProjectLead ? dodgeProjectLead?.valuation : 0,
          total_sqft: !!events?.length && dodgeProjectLead ? dodgeProjectLead?.totalSqft : 0,
          description:
            !!events?.length && dodgeProjectLead ? toSentence(dodgeProjectLead?.projectNote) : '',
        } as IProjectRequest
      }
      validationSchema={ProjectRequestSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <Paper>
            <PaperHeader>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <PaperTitle title="New Project:" color={info} />
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Create Project
                  </Button>
                </Grid>
              </Grid>
            </PaperHeader>
            <PaperBody>
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Project Info
                  </Typography>
                  <BorderedPaper color={darkLight}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Project Name Required
                    </Typography>
                    <FormTextField name="name" label="Project Name" required fullWidth />
                    <hr className={classes.separator} />
                    <Typography variant="h6" color="primary" gutterBottom>
                      Project Location Required
                    </Typography>
                    {projectProperty ? (
                      <LeadCard property={projectProperty} clickable={false} />
                    ) : (
                      <EmptyBox>
                        <CreateProperty
                          onSubmit={(property: Property) => setProjectProperty(property)}
                          handleClose={() => setCreatePropertyOpen(false)}
                          isOpen={createPropertyOpen}
                        />
                        <Typography>No Property Selected</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setCreatePropertyOpen(true)}
                        >
                          Add Property
                        </Button>
                      </EmptyBox>
                    )}
                  </BorderedPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Project Details
                  </Typography>
                  <BorderedPaper color={light}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Qualifications
                    </Typography>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <FormNumberField
                          name="value"
                          label="Project Value"
                          prefix="$"
                          allowNegative={false}
                          thousandSeparator
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <FormNumberField
                          name="total_sqft"
                          label="Total Sqft"
                          allowNegative={false}
                          thousandSeparator
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <FormNumberField
                          name="ceiling_sqft"
                          label="Ceiling Sqft"
                          allowNegative={false}
                          thousandSeparator
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <FormSelect
                          name="stage"
                          label="Project Stage"
                          options={ProjectStageMapping}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <FormSelect
                          name="space_type"
                          label="Market Type"
                          options={ProjectSpaceTypeMapping}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <FormSelect
                          name="type"
                          label="Job Type"
                          options={ProjectTypeMapping}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <hr className={classes.separator} />
                    <Typography variant="h6" color="primary" gutterBottom>
                      Description
                    </Typography>
                    <FormTextField
                      name="description"
                      label="Project Description"
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </BorderedPaper>
                </Grid>
              </Grid>
            </PaperBody>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProject;
