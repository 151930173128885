import { withStyles } from '@material-ui/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

export const AccordionMod = withStyles({
  root: {
    boxShadow: 'none',
    // Keep these css, prevents Accordions from modifying space around them when expanded
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

export const AccordionSummaryMod = withStyles({
  root: {
    '& .Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
    '&$expanded': {
      margin: 0,
      minHeight: 0,
    },
  },
  content: {
    display: 'flex',
    margin: 0,
    justifyContent: 'center',
    minHeight: 0,
  },
  expanded: {},
})(AccordionSummary);

export const AccordionDetailMod = withStyles({
  root: {
    padding: 0,
    width: '100%',
    justifyContent: 'center',
  },
})(AccordionDetails);
