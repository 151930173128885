import { atom } from 'recoil';

import TableSetting from '../../models/tableSetting';

export const userTableSettingsState = atom({
  key: 'userTableSettings',
  default: [] as TableSetting[],
});

export const userTableSettingsLoadedState = atom({
  key: 'userTableSettingsLoaded',
  default: false,
});
