import { toTitleCase } from './string';

import { Optional } from '../types';

export const JWT_ROLES_KEY = 'https://cleodv.xyz/roles';

export enum UserRole {
  Admin = 'ADMIN',
  Concierge = 'CONCIERGE',
}

export interface User {
  [JWT_ROLES_KEY]: UserRole[];
}

export const getUserRoleName = (user: User): Optional<string> => {
  const roles = user[JWT_ROLES_KEY];
  return toTitleCase(roles && roles.length > 0 ? roles[0] : UserRole.Concierge);
};
