import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { error } from '../../../theme';
import targetedNationalAccount from '../../../resources/images/Accounts/NationalAccountTargeted.svg';
import nationalAccount from '../../../resources/images/Accounts/AccountNationalIcon.svg';

export interface IAccountRatingIcon {
  ratingIcon: string;
  ratingAlt: string;
  size: string;
}

interface IAccountIncIcon {
  incIcon: string;
  incLength: number;
  size: string;
}

interface IAccountNationalIcon {
  hasNationalAccount: boolean;
  hasNationalTargetAccount: boolean;
}

const useIncrementalStyles = makeStyles({
  root: {
    position: 'relative',
    paddingTop: '.25rem',
    width: (props: Partial<IAccountIncIcon>) => props.size,
  },
  badge: {
    marginTop: '.25rem',
    color: 'white',
    fontSize: '.6rem',
    fontWeight: 'bold',
    backgroundColor: error,
    borderRadius: '50%',
    width: '.75rem',
    height: '.75rem',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    right: -6,
    top: -5,
  },
});

export const renderAccountRatingIcon = ({
  ratingIcon,
  ratingAlt,
  size,
}: IAccountRatingIcon): JSX.Element => {
  return <img src={ratingIcon} alt={ratingAlt} style={{ width: size }} />;
};

export const RenderAccountIncIcon: React.FC<IAccountIncIcon> = ({ incLength, incIcon, size }) => {
  const classes = useIncrementalStyles({ size });
  return (
    <Grid className={classes.root}>
      <img src={incIcon} alt={'Incremental'} style={{ width: size }} />
      <Grid className={classes.badge}>{incLength}</Grid>
    </Grid>
  );
};

export const RenderAccountNational: React.FC<IAccountNationalIcon> = ({
  hasNationalAccount,
  hasNationalTargetAccount,
}) => {
  let nationalIcon: JSX.Element;
  if (hasNationalAccount && hasNationalTargetAccount) {
    nationalIcon = (
      <img
        src={targetedNationalAccount}
        alt={'targeted national account'}
        style={{ height: '1.75rem' }}
      />
    );
  } else if (hasNationalAccount) {
    nationalIcon = (
      <img src={nationalAccount} alt={'national account'} style={{ height: '1.75rem' }} />
    );
  } else {
    nationalIcon = <></>;
  }
  return nationalIcon;
};
