import type { AxiosError } from 'axios';
import Rollbar, { LogArgument } from 'rollbar';

import { ENVIRONMENT, ROLL_BAR_ACCESS_TOKEN } from '../constants';
import type { Optional } from '../types';
import { RollbarLogEventTypes } from '../types/rollbarLevelTypes';

let rollbar: Optional<Rollbar>;
// Don't call rollbar during local development
if (ENVIRONMENT !== 'local') {
  rollbar = new Rollbar();
  rollbar.configure({
    accessToken: ROLL_BAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: ENVIRONMENT,
    },
  });
}

const reportError = (msg: string, e: LogArgument = {}): void => {
  rollbar?.error(msg, e);
};

const reportWarning = (msg: string, e: LogArgument = {}): void => {
  rollbar?.warn(msg, e);
};

const reportLog = (msg: string, e: LogArgument = {}): void => {
  rollbar?.log(msg, e);
};

const reportInfo = (msg: string, e: LogArgument = {}): void => {
  rollbar?.info(msg, e);
};

export const reportRollbar = (
  level: RollbarLogEventTypes,
  msg: string,
  e: LogArgument = {},
): void => {
  switch (level) {
    case RollbarLogEventTypes.LOG:
      reportLog(msg, e);
      break;
    case RollbarLogEventTypes.INFO:
      reportInfo(msg, e);
      break;
    case RollbarLogEventTypes.WARN:
      reportWarning(msg, e);
      break;
    case RollbarLogEventTypes.ERROR:
      reportError(msg, e);
      break;
  }
};

export const isAxiosError = (err: Error | AxiosError): err is AxiosError =>
  (err as AxiosError).isAxiosError;

export const getErrorMessage = (err: Error | AxiosError): string =>
  isAxiosError(err) ? err.response?.data.message || err.message : err.message;
