import { makeStyles } from '@material-ui/core';

export const useReactTableCellStyles = makeStyles(() => ({
  root: {
    border: 'none',
    fontSize: '0.75rem',
    padding: '0.5rem',
  },
  scoreIcon: {
    justifyContent: 'center',
  },
  rowCellText: {
    width: '100%',
  },
  gearIconCell: {
    width: '5% !important',
  },
  viewed: {
    opacity: '40%',
  },
}));
