import { MapDisplayLevel } from '../../../../state/atoms/interactiveMap';

interface DisplayLevelConfig {
  nextLevel: MapDisplayLevel;
  prevLevel: MapDisplayLevel;
  zoomLevel: number;
  defaultSize: number;
}

export const mapsConfig: Record<MapDisplayLevel, DisplayLevelConfig> = {
  [MapDisplayLevel.Country]: {
    nextLevel: MapDisplayLevel.State,
    prevLevel: MapDisplayLevel.Country,
    zoomLevel: 4,
    defaultSize: 13.5,
  },
  [MapDisplayLevel.State]: {
    nextLevel: MapDisplayLevel.County,
    prevLevel: MapDisplayLevel.Country,
    zoomLevel: 6,
    defaultSize: 10,
  },
  [MapDisplayLevel.County]: {
    nextLevel: MapDisplayLevel.County,
    prevLevel: MapDisplayLevel.State,
    zoomLevel: 10,
    defaultSize: 7,
  },
};
