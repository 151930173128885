import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Avatar, Grid, Paper, Typography } from '@material-ui/core';
import ProjectIcon from '@material-ui/icons/PlaylistAddCheck';

import type { Project } from '../../../../models';
import { info } from '../../../../theme';
import { getLeadDetailsPagePath } from '../../../../utils/page';
import { toTitleCase } from '../../../../utils/string';

interface IProps {
  project: Project;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5),
    borderLeft: `6px solid ${info}`,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  container: {
    flexWrap: 'nowrap',
  },
  avatar: {
    backgroundColor: info,
  },
  dense: {
    lineHeight: '1.1rem',
  },
}));

const ProjectCard: React.FC<IProps> = ({ project }) => {
  const { name, property, totalEvents } = project;
  const classes = useStyles();
  return (
    <Paper
      className={classes.root}
      component={props => (
        <Link to={property ? getLeadDetailsPagePath(property.id) : '/'} {...props} />
      )}
    >
      <Grid container spacing={2} alignItems="center" className={classes.container}>
        <Grid item>
          <Avatar className={classes.avatar}>
            <ProjectIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography className={classes.dense} color="primary">
            <strong>Project:</strong> {toTitleCase(name)}
          </Typography>
          {property && (
            <Typography className={classes.dense} color="secondary" variant="body2">
              {property.address1}, {property.city}, {property.state}
            </Typography>
          )}
          <Typography className={classes.dense} color="primary" variant="body2">
            {totalEvents} {totalEvents === 1 ? 'Event' : 'Events'}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProjectCard;
