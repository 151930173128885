import React, { MouseEvent, KeyboardEvent, useRef, useState, useCallback } from 'react';
import type { ObjectSchema } from 'yup';
import {
  makeStyles,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  FormControl,
  Grid,
  Paper,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import type { FormatInputValueFunction } from 'react-number-format';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

import { primary, primaryBlue, lightGreen } from '../../theme';
import type { DisplayNameMapping } from '../../types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
  },
  closeIcon: {
    cursor: 'pointer',
    color: primaryBlue,
  },
  button: {
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 5),
    backgroundColor: lightGreen,
    color: primary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lightGreen,
      color: primary,
    },
  },
  editIcon: {
    color: primary,
  },
  dialog: {
    padding: theme.spacing(3),
    position: 'absolute',
    left: 0,
    bottom: (props: StyleProps) => (props.bottomPercentage ? props.bottomPercentage : '100%'),
    cursor: 'auto',
    minWidth: 300,
    zIndex: 1299,
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  inputRoot: {
    padding: theme.spacing(3),
  },
  input: {
    padding: 0,
  },
}));

interface IProps {
  label: string;
  name?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  defaultValue?: string | number;
  options?: DisplayNameMapping;
  validationSchema?: ObjectSchema;
  validationContext?: Record<string, unknown>;
  format?: string | FormatInputValueFunction;
  allowEmptyFormatting?: boolean;
  mask?: string | string[];
  onChange: (value: string) => void;
  editableClassName?: string;
  bottomPercentage?: string;
}

interface StyleProps {
  bottomPercentage?: string;
}

const Editable: React.FC<IProps> = ({
  label,
  type = 'text',
  name = 'text',
  defaultValue: defaultValueProp,
  options,
  onChange,
  children,
  validationSchema,
  validationContext = {},
  allowEmptyFormatting,
  format,
  mask,
  editableClassName,
  bottomPercentage,
}) => {
  const [buttonVisible, setButtonVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [validationError, setValidationError] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>();
  const classes = useStyles({ bottomPercentage: bottomPercentage } as StyleProps);
  const defaultValue = defaultValueProp || '';
  const validateValues = useCallback(async (): Promise<void> => {
    if (!validationSchema || !inputRef.current) {
      return;
    }
    const { value, name } = inputRef.current;
    const testValidationObj: any = { [name]: value };
    try {
      const valid = await validationSchema.validate(testValidationObj, {
        context: validationContext,
      });
      if (valid) {
        setValidationError(undefined);
      }
    } catch (err) {
      setValidationError(err.message);
      throw new Error(err.message);
    }
  }, [validationSchema, validationContext]);

  const handleShowButton = useCallback(
    (show: boolean) => {
      if (!dialogVisible) {
        setButtonVisible(show);
      }
    },
    [dialogVisible],
  );

  const handleShowButtonAccessible = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleShowButton(true);
      }
    },
    [handleShowButton],
  );

  const handleOpenDialog = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setButtonVisible(false);
      setDialogVisible(true);
      setTimeout(() => inputRef.current?.focus(), 50);
    },
    [inputRef],
  );

  const handleCloseDialog = useCallback(async () => {
    try {
      await validateValues();
      setButtonVisible(false);
      setDialogVisible(visible => {
        if (visible && inputRef.current) {
          const { value } = inputRef.current;
          if (value !== defaultValue) {
            onChange(value);
          }
        }

        return false;
      });
    } catch (err) {
      // validation error
    }
  }, [validateValues, defaultValue, onChange]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleCloseDialog();
      }
    },
    [handleCloseDialog],
  );

  const inputId = `edit-${label}-input`;
  return (
    <ClickAwayListener onClickAway={handleCloseDialog}>
      <div
        className={clsx(classes.root, editableClassName)}
        onMouseEnter={() => handleShowButton(true)}
        onMouseLeave={() => handleShowButton(false)}
        onFocus={() => handleShowButton(true)}
        onKeyDown={handleShowButtonAccessible}
        role="button"
        tabIndex={0}
      >
        {children}
        <Fade in={buttonVisible}>
          <Button
            className={classes.button}
            startIcon={<EditIcon className={classes.editIcon} />}
            onClick={handleOpenDialog}
          >
            Edit
          </Button>
        </Fade>
        <Fade in={dialogVisible}>
          <Paper className={classes.dialog}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6" color="primary">
                  <strong>Edit</strong>
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon
                  aria-label="Close"
                  className={classes.closeIcon}
                  onClick={handleCloseDialog}
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              <FormControl variant="outlined" fullWidth>
                <label className={classes.label} htmlFor={inputId}>
                  {label}
                </label>
                {options ? (
                  <Select
                    id={inputId}
                    classes={{ root: classes.inputRoot }}
                    inputRef={inputRef}
                    defaultValue={defaultValue}
                    native
                  >
                    {!defaultValue && <option value="">None</option>}
                    {Object.entries(options).map(([value, name]) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <>
                    {type === 'tel' ? (
                      <NumberFormat
                        customInput={TextField}
                        id={inputId}
                        inputRef={inputRef}
                        InputProps={{ classes: { root: classes.inputRoot, input: classes.input } }}
                        variant="outlined"
                        type="tel"
                        name={name}
                        defaultValue={defaultValue}
                        onKeyDown={handleKeyDown}
                        format={format}
                        mask={mask}
                        allowEmptyFormatting={allowEmptyFormatting}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        id={inputId}
                        inputRef={inputRef}
                        InputProps={{ classes: { root: classes.inputRoot, input: classes.input } }}
                        variant="outlined"
                        type={type}
                        name={name}
                        defaultValue={defaultValue}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}

                    {validationError && <FormHelperText error>{validationError}</FormHelperText>}
                  </>
                )}
              </FormControl>
            </Box>
          </Paper>
        </Fade>
      </div>
    </ClickAwayListener>
  );
};

export default Editable;
