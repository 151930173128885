import React from 'react';
import { MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { primaryBlue } from '../../theme';

interface IProps {
  handleShowNew: (filterValue: 'showAll' | 'showNew' | 'showExisting') => void;
  showNew: 'showAll' | 'showNew' | 'showExisting';
}

const useStyles = makeStyles(() => ({
  select: {
    padding: '.5rem',
    width: '201px',
  },
  icon: {
    color: primaryBlue,
  },
  selectViewLabel: {
    fontWeight: 700,
    fontSize: '1rem',
    color: primaryBlue,
    lineHeight: '125%',
    marginRight: '.3rem',
  },
}));

const ShowNewDropdown: React.FC<IProps> = ({ showNew, handleShowNew }) => {
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = React.useState(showNew || 'showNew');
  const [selectOpen, setSelectOpen] = React.useState(false);

  const handleFilterSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFilter(e.target.value as 'showNew' | 'showAll' | 'showExisting');
    handleShowNew(e.target.value as 'showNew' | 'showAll' | 'showExisting');
  };
  return (
    <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
      <Typography
        className={classes.selectViewLabel}
        onClick={() => {
          setSelectOpen(!selectOpen);
        }}
      >
        View
      </Typography>
      <Select
        style={{
          height: '34.5px',
          fontWeight: 700,
          fontSize: '14px',
          color: primaryBlue,
          backgroundColor: 'white',
          border: '1px solid primaryBlue',
        }}
        id="filter-select"
        open={selectOpen}
        onClose={() => setSelectOpen(false)}
        onOpen={() => setSelectOpen(true)}
        value={selectedFilter}
        onChange={handleFilterSelect}
        variant={'outlined'}
        classes={{ root: classes.select }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        <MenuItem value={'showNew'}>Show Only New</MenuItem>
        <MenuItem value={'showExisting'}>Show Only Existing</MenuItem>
        <MenuItem value={'showAll'}>Show All</MenuItem>
      </Select>
    </div>
  );
};

export default ShowNewDropdown;
