import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';

import { primaryBlue } from '../../../../theme';
import {
  MapObjectSource,
  MapObjectSourceState,
  selectedLocationNameState,
  selectedLocationTypeState,
  selectedMapObjectSourceState,
  selectedMapObjectTypeState,
} from '../../../../state/atoms/interactiveMap';

const useStyles = makeStyles({
  root: {
    alignSelf: 'flex-end',
    padding: '.5rem',
    width: '25vh',
    margin: '2rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: primaryBlue,
    color: 'white',
  },
  containerName: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '.85rem',
    padding: '.5rem',
  },
  value: {
    fontSize: '.85rem',
    padding: '.5rem',
  },
});

export const listTrueValues = (values: MapObjectSourceState): MapObjectSource[] => {
  const trueValues: MapObjectSource[] = [];
  const cloneObj: { [key: string]: boolean } = Object.assign({}, { ...values });
  for (const key in cloneObj) {
    if (cloneObj[key]) {
      trueValues.push(key as MapObjectSource);
    }
  }
  return trueValues;
};

const MapSummary: React.FC = () => {
  const classes = useStyles();
  const selectedLocationType = useRecoilValue(selectedLocationTypeState);
  const selectedObjectType = useRecoilValue(selectedMapObjectTypeState);
  const selectedObjectSource = useRecoilValue(selectedMapObjectSourceState);
  const selectedLocationName = useRecoilValue(selectedLocationNameState);

  const getStates = [
    { header: 'Location Type', value: selectedLocationType },
    { header: 'Object Type', value: selectedObjectType },
    { header: 'Object Source', value: selectedObjectSource },
    { header: 'Location Name', value: selectedLocationName },
  ];

  const renderState = (header: string, value: string | MapObjectSourceState): JSX.Element => {
    let listValue;
    let values;
    if (typeof value === 'object') {
      listValue = listTrueValues(value);
    }

    if (listValue) {
      values = listValue.map((val, idx) => <Grid key={`${val}-${idx}`}>{val}</Grid>);
    }

    return (
      <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid className={classes.header}>{header}: </Grid>
        <Grid item className={classes.value}>
          {listValue ? values : value}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.containerName}> Recoil State Summary</Grid>
      <Grid item>
        {getStates.map((state, idx) => {
          return (
            <Grid key={`${state.header}-${idx}`}>{renderState(state.header, state.value)}</Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MapSummary;
