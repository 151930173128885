import React from 'react';
import { makeStyles, Box, CircularProgress, Typography } from '@material-ui/core';
import type { CircularProgressProps } from '@material-ui/core/CircularProgress';

import { lightGray } from '../../theme';

type IProps = CircularProgressProps & { value: number };

export const calculateLabelFontSize = (circleSize?: number | string): string => {
  const size = Number(circleSize);
  return size ? `${Math.max(Math.floor(size / 40), 1)}rem` : '1rem';
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  progressBackground: {
    position: 'absolute',
    color: lightGray,
  },
});

const CircularProgressWithLabel: React.FC<IProps> = props => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress
        className={classes.progressBackground}
        variant="static"
        {...props}
        value={100}
      />
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textPrimary"
          style={{ fontSize: calculateLabelFontSize(props.size) }}
        >{`${Math.round(props.value)}`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
