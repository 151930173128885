import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { lightGray, white } from '../../../../theme';
import accountRatingA from '../../../../resources/images/Accounts/AccountRatingAIcon.svg';
import accountRatingB from '../../../../resources/images/Accounts/AccountRatingBIcon.svg';
import accountRatingC from '../../../../resources/images/Accounts/AccountRatingCIcon.svg';
import accountRatingNA from '../../../../resources/images/Accounts/AccountRatingNAIcon.svg';
import nationalAccountIcon from '../../../../resources/images/Accounts/AccountNationalIcon.svg';
import targetNationalAccountIcon from '../../../../resources/images/Accounts/NationalAccountTargeted.svg';
import educationIcon from '../../../../resources/images/Accounts/EducationIcon.svg';
import governmentIcon from '../../../../resources/images/Accounts/GovernmentIcon.svg';
import specAlertIcon from '../../../../resources/images/Accounts/SpecAlertIcon.svg';
import newLeadIcon from '../../../../resources/images/NewLeadIcon.svg';
import {
  IAccountRatingIcon,
  renderAccountRatingIcon,
} from '../../../Common/OrgIcons/AccountIconRenderer';
export enum AccountRatingColor {
  ratingA = '#BCBDC0',
  ratingB = '#1FB4B1',
  ratingC = '#4E9AF3',
  noRating = '#999AF6',
  nationalAccount = '#DAA520',
}

const useOrgIncAndRatingIconsStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.5rem .5rem .5rem .5rem',
    backgroundColor: 'white',
    borderRadius: '4px',
    gap: '1rem',
    minHeight: '60px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'left',
    padding: '.3rem',
  },
  rating: {
    borderLeft: `1px solid ${lightGray}`,
    height: '30px',
    width: '30px',
  },
  inactive: {
    opacity: '10%',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: white,
    fontSize: '1.5rem',
  },
  tooltip: {
    backgroundColor: white,
    border: '1px solid #E6E8ED',
    color: '#4A4A4A',
    padding: '1rem',
    marginBottom: '1.7rem',
    fontSize: '.8rem',
  },
});

interface IOrgIcons {
  sfRating?: string;
  isNationalAccount: boolean;
  isTargetedNationalAccount: boolean;
  isEducation: boolean;
  isGovernment: boolean;
  isSpecAlert: boolean;
  isNew: boolean;
  hideRatingIcon?: boolean;
}

type Rating = {
  ratingIcon: string | null;
  ratingAlt: string;
  size: string;
};

export const accountRatingHelper = (sfRating: string | undefined): Rating => {
  let ratingIcon: string | null;
  let ratingAlt: string;
  switch (sfRating) {
    case 'A':
      ratingIcon = accountRatingA;
      ratingAlt = 'A';
      break;
    case 'B':
      ratingIcon = accountRatingB;
      ratingAlt = 'B';
      break;
    case 'C':
      ratingIcon = accountRatingC;
      ratingAlt = 'C';
      break;
    default:
      ratingAlt = 'No Grade';
      ratingIcon = accountRatingNA;
      break;
  }

  return {
    ratingIcon,
    ratingAlt,
    size: '30px',
  };
};

const AccountCardIcons: React.FC<IOrgIcons> = ({
  sfRating,
  isNationalAccount,
  isTargetedNationalAccount,
  isEducation,
  isGovernment,
  isSpecAlert,
  isNew,
  hideRatingIcon = false,
}) => {
  const classes = useOrgIncAndRatingIconsStyles();
  const icons: JSX.Element[] = [];

  const ratingObj = accountRatingHelper(sfRating);
  const { ratingIcon } = ratingObj;

  if (ratingIcon && !hideRatingIcon) {
    ratingObj.size = '30px';
    icons.push(renderAccountRatingIcon(ratingObj as IAccountRatingIcon));
  }
  if (hideRatingIcon) {
    icons.push(
      <Tooltip
        title="New Account"
        placement="top"
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        arrow
      >
        <img
          style={{ height: '30px' }}
          src={newLeadIcon}
          alt={'New Lead'}
          className={isNew ? '' : classes.inactive}
        />
      </Tooltip>,
    );
  }
  icons.push(
    <Tooltip
      title="Government Opportunity"
      placement="top"
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      arrow
    >
      <img
        style={{ height: '30px' }}
        src={governmentIcon}
        alt={'Government'}
        className={isGovernment ? '' : classes.inactive}
      />
    </Tooltip>,
  );
  icons.push(
    <Tooltip
      title="Education Opportunity"
      placement="top"
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      arrow
    >
      <img
        style={{ height: '30px' }}
        src={educationIcon}
        alt={'Education'}
        className={isEducation ? '' : classes.inactive}
      />
    </Tooltip>,
  );
  icons.push(
    <Tooltip
      title="Spec Alerts"
      placement="top"
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      arrow
    >
      <img
        style={{ height: '30px' }}
        src={specAlertIcon}
        alt={'Spec Alert'}
        className={isSpecAlert ? '' : classes.inactive}
      />
    </Tooltip>,
  );
  if (isTargetedNationalAccount) {
    icons.push(
      <Tooltip
        title="Targeted National Account"
        placement="top"
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        arrow
      >
        <img
          style={{ height: '30px' }}
          src={targetNationalAccountIcon}
          alt={'Targeted National Account'}
          className={isTargetedNationalAccount ? '' : classes.inactive}
        />
      </Tooltip>,
    );
  } else {
    icons.push(
      <Tooltip
        title="National Account"
        placement="top"
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        arrow
      >
        <img
          style={{ height: '30px' }}
          src={nationalAccountIcon}
          alt={'National Account'}
          className={isNationalAccount ? '' : classes.inactive}
        />
      </Tooltip>,
    );
  }

  return (
    <Grid className={classes.root}>
      {icons.map((icon, idx) => {
        return (
          <Grid key={`account-icon-${idx}`} className={classes.icon}>
            {icon}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AccountCardIcons;
