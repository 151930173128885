import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import has from 'lodash/has';
import { useRecoilState, useSetRecoilState } from 'recoil';
import omit from 'lodash/omit';

import LeadsFilterItemForm from './LeadsFilterItemForm';

import {
  Comparators,
  FilterData,
  FilterItem,
  FilterItemInputType,
  FilterKey,
  FilterOptionsItem,
  OptionValue,
  TagsDisplay,
} from '../../../../types';
import {
  allFilterTagsState,
  allKeysSelectedState,
  filtersInputedState,
  filtersSelectedState,
} from '../../../../state/atoms/filters';
import { light } from '../../../../theme';

interface IProps {
  filterItem: FilterItem;
  filterItemOption: FilterOptionsItem;
  clearFilters?: boolean;
  multipleSelect?: boolean;
  isFilterItemChecked?: boolean;
  filterInputsToClear: string[];
  setFilterInputsToClear: (inputKeys: string[]) => void;
}

const LeadsFilterItem: React.FC<IProps> = ({
  filterItem,
  filterItemOption,
  multipleSelect = true,
  clearFilters = false,
  setFilterInputsToClear,
  filterInputsToClear,
  isFilterItemChecked = false,
}: IProps) => {
  const filterKey: FilterKey = filterItemOption?.filterKey || filterItem.filterKey;
  const defaultSelected = useMemo(
    () => ({
      [filterItem.filterKey]: {},
    }),
    [filterItem.filterKey],
  );
  const defaultInputed = useMemo(() => ({}), []);

  const [isChecked, setIsChecked] = useState<boolean>(isFilterItemChecked);

  const [selectedKeys, setSelectedKeys] = useRecoilState<FilterData>(
    filtersSelectedState(filterItem.filterKey),
  );

  const [allKeys, setAllKeys] = useRecoilState<FilterData | undefined>(allKeysSelectedState);

  // selectedKeys should match allKeys for relevant filter options
  // Fixes issues with loading saved filters and editing from fresh page
  if (
    !has(selectedKeys, [filterKey, filterItemOption.comparator]) &&
    has(allKeys, [filterKey, filterItemOption.comparator]) &&
    allKeys
  ) {
    setSelectedKeys(() => {
      const defaultSelectedKeys = {
        [filterKey]: {
          ...allKeys[filterKey],
        },
      } as FilterData;
      return defaultSelectedKeys;
    });
  }

  const [allFilterTags, setAllFilterTags] = useRecoilState<TagsDisplay[]>(allFilterTagsState);
  const setInputedKeys = useSetRecoilState<Record<string | number, any>>(
    filtersInputedState(filterItem.filterKey),
  );

  const generateFilterTag = useCallback(
    (currentTags?: TagsDisplay[]): TagsDisplay[] =>
      (currentTags || []).concat([
        {
          label: filterItem.filterLabel,
          key: filterItem.filterKey,
          value: `${filterItemOption.label}`,
          comparator: filterItemOption.comparator,
          filterValue: filterItemOption.value,
          isInput: false,
        },
      ]),
    [filterItem, filterItemOption],
  );

  const handleSelect = useCallback(() => {
    let keySelection = { ...allKeys };
    if (!multipleSelect && !filterItemOption.multiple) {
      setSelectedKeys(defaultSelected);
      setInputedKeys(defaultInputed);
    }
    if (
      ![Comparators.In, Comparators.Nin, Comparators.Between].includes(
        filterItemOption.comparator,
      ) &&
      filterItem.filterKey !== 'specAlerts' &&
      filterItem.filterKey !== 'actionStage' &&
      filterItem.filterKey !== 'actionStageAccounts' &&
      filterItem.filterKey !== 'keywords' &&
      filterItem.filterKey !== 'projectTypes' &&
      filterItem.filterKey !== 'accountRatings' &&
      filterItem.filterKey !== 'accountTypes' &&
      filterItem.filterKey !== FilterKey.ProjectScoresAccounts
    ) {
      let removeFromTags = false;
      setSelectedKeys((currentSelectedKeys: FilterData) => {
        let addComparator = { ...currentSelectedKeys };
        if (has(allKeys, [filterItem.filterKey, filterItemOption.comparator]) && multipleSelect) {
          addComparator = omit(addComparator, [
            `${filterItem.filterKey}.${filterItemOption.comparator}`,
          ]);
          if (addComparator[filterItem.filterKey]['selectionId']) {
            addComparator = omit(addComparator, [`${filterItem.filterKey}.selectionId`]);
          }
          removeFromTags = true;
        } else {
          addComparator[filterItem.filterKey] = {
            ...addComparator[filterItem.filterKey],
            [filterItemOption.comparator]: filterItemOption.value,
          };
          if (filterItemOption.selectionId) {
            addComparator[filterItem.filterKey].selectionId = filterItemOption.selectionId;
          }
          removeFromTags = false;
        }
        keySelection = addComparator;

        return addComparator;
      });
      if (removeFromTags) {
        setAllFilterTags((prev: any) =>
          prev.filter(
            (filterTag: any) =>
              filterTag.value !== `${filterItemOption.comparator} ${filterItemOption.label}`,
          ),
        );
      } else {
        if (allFilterTags.map(tag => tag.value).includes(`${filterItemOption.label}`)) {
          setAllFilterTags((prev: any) =>
            prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
          );
        } else {
          setAllFilterTags((prev: any) => {
            if (filterItem.filterKey === FilterKey.EventSource) {
              return generateFilterTag(
                prev.filter((tag: TagsDisplay) => tag.key !== FilterKey.EventSource),
              );
            } else if (filterItem.filterKey === FilterKey.LastUpdated) {
              return generateFilterTag(
                prev.filter((tag: TagsDisplay) => tag.key !== FilterKey.LastUpdated),
              );
            } else {
              return generateFilterTag(prev);
            }
          });
        }
      }

      setAllKeys(prev => ({ ...prev, ...keySelection }));
      return;
    }

    if (
      !has(allKeys, [filterItem.filterKey, filterItemOption.comparator]) ||
      (!multipleSelect && !filterItemOption.multiple)
    ) {
      setSelectedKeys((currentSelectedKeys: FilterData) => {
        const updatedSelectedKeys: FilterData = {
          [filterItem.filterKey]: {
            ...currentSelectedKeys[filterItem.filterKey],
            [filterItemOption.comparator]: [filterItemOption.value],
          },
        };
        if (filterItemOption.selectionId) {
          updatedSelectedKeys[filterItem.filterKey].selectionId = filterItemOption.selectionId;
        }
        keySelection = updatedSelectedKeys;
        return updatedSelectedKeys;
      });

      setAllFilterTags((prev: TagsDisplay[]) => {
        return generateFilterTag(prev);
      });
      setAllKeys(prev => ({ ...prev, ...keySelection }));
      return;
    }

    const valuesArray: Array<OptionValue> = selectedKeys[filterItem.filterKey][
      filterItemOption.comparator
    ] as Array<OptionValue>;
    if (
      filterItemOption.value?.toString() &&
      !valuesArray.map(value => value?.toString()).includes(filterItemOption.value.toString())
    ) {
      setSelectedKeys((currentSelectedKeys: FilterData) => {
        const addList = [...valuesArray, filterItemOption.value];
        const updatedSelectedKeys: FilterData = {
          [filterItem.filterKey]: {
            ...currentSelectedKeys[filterItem.filterKey],
            [filterItemOption.comparator]: addList,
          },
        };
        if (filterItemOption.selectionId) {
          updatedSelectedKeys[filterItem.filterKey].selectionId = filterItemOption.selectionId;
        }
        keySelection = updatedSelectedKeys;
        return updatedSelectedKeys;
      });
      setAllFilterTags((prev: any) => generateFilterTag(prev));
      setAllKeys(prev => ({ ...prev, ...keySelection }));
    } else {
      const valuesArrayAfterDeselection = valuesArray.filter(
        i => i?.toString() !== filterItemOption.value?.toString(),
      );

      if (valuesArrayAfterDeselection.length > 0) {
        setSelectedKeys((currentSelectedKeys: FilterData) => {
          const updatedSelectedKeys = {
            [filterItem.filterKey]: {
              ...currentSelectedKeys[filterItem.filterKey],
              [filterItemOption.comparator]: valuesArrayAfterDeselection,
            },
          };
          keySelection = updatedSelectedKeys;
          return updatedSelectedKeys;
        });
        setAllKeys(prev => ({ ...prev, ...keySelection }));
        setAllFilterTags((prev: any) =>
          prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
        );
      } else {
        setSelectedKeys(currentSelectedKeys => {
          const removeComparator = { ...currentSelectedKeys };
          const allKeys = {
            ...removeComparator,
            [filterItem.filterKey]: omit(
              removeComparator[filterItem.filterKey],
              filterItemOption.comparator,
            ),
          };
          keySelection = allKeys;
          return allKeys;
        });
        setAllKeys(prev => {
          return { ...prev, ...keySelection };
        });
      }

      setAllFilterTags((prev: any) =>
        prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
      );
    }
  }, [
    filterItem,
    filterItemOption,
    setSelectedKeys,
    selectedKeys,
    multipleSelect,
    allFilterTags,
    setAllFilterTags,
    setAllKeys,
    allKeys,
    defaultSelected,
    generateFilterTag,
    defaultInputed,
    setInputedKeys,
  ]);

  useEffect(() => {
    setIsChecked(isFilterItemChecked);
  }, [isFilterItemChecked]);

  return (
    <div
      style={{
        color: 'white',
        justifyContent: 'space-between',
      }}
    >
      {filterItemOption.inputType === FilterItemInputType.Input ? (
        <LeadsFilterItemForm
          filterItemOption={filterItemOption}
          filterItem={filterItem}
          setIsChecked={setIsChecked}
          filterInputsToClear={filterInputsToClear}
          setFilterInputsToClear={setFilterInputsToClear}
          multipleSelect={multipleSelect}
          clearFilters={clearFilters}
        />
      ) : (
        <Checkbox
          checked={isChecked}
          name={filterItemOption.label}
          style={{ color: light }}
          onChange={evt => {
            setIsChecked(evt.target.checked);
            handleSelect();
          }}
        />
      )}
    </div>
  );
};

export default LeadsFilterItem;
