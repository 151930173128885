import cloneDeep from 'lodash/cloneDeep';

import { SortColumns, ViewFilterRequest } from '../Components/Pages/Leads/Archive/Archive.config';
import { sortDict } from '../models/lead';
import { FilterDataOr, FilterKey, FilterValue } from '../types';
import {
  DEFAULT_PAGE_SIZE,
  accountTypesValueMap,
  actionStageValueMap,
  Accounts_Project_Score_Labels_and_Key,
} from '../constants';

export const sanitizeFilterConfig = <T>(
  { sort, startIndex, filterOption, limit }: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
  model = 'addresses',
) => {
  const filterCopy = cloneDeep(filterOption);
  const { order, orderBy } = sort;
  const dir = sortDict[order];
  const query: FilterDataOr = { or: [] };
  /**
   * We need to make sure we push all filters into the query.or array
   * and delete the object from the filterCopy so that backend logic can
   * handle large amounts of filters
   */
  if (filterCopy?.projectStatus) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.projectStatus).map(filterKey => ({
        projectStatus: { [filterKey]: filterCopy.projectStatus[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.projectStatus;
  }

  if (filterCopy?.ownerId) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.ownerId).map(filterKey => ({
        ownerId: { [filterKey]: filterCopy.ownerId[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.ownerId;
  }

  if (filterCopy?.state) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.state).map(filterKey => ({
        state: { [filterKey]: filterCopy.state[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.state.in;
  }

  if (filterCopy?.county) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.county).map(filterKey => ({
        county: { [filterKey]: filterCopy.county[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.county.in;
  }

  if (filterCopy?.countyState) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.countyState).map(filterKey => ({
        countyState: { [filterKey]: filterCopy.countyState[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.countyState.in;
  }

  if (filterCopy?.province) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.province).map(filterKey => ({
        state: { [filterKey]: filterCopy.province[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.province.in;
  }

  if (filterCopy?.keywords && filterCopy.keywords.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.keywords.contains).map(value => ({
        keywords: { contains: value },
      })),
    );
    delete filterCopy.keywords.contains;
  }

  if (filterCopy?.accountRatings && filterCopy.accountRatings.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.accountRatings.contains).map(value => ({
        accountRatings: { contains: value },
      })),
    );
    delete filterCopy.accountRatings.contains;
  }

  if (filterCopy?.accountTypes && filterCopy.accountTypes.contains) {
    const accountTypes: any = [];
    Object.values(filterCopy.accountTypes.contains).forEach(role => {
      accountTypes.push(accountTypesValueMap[role]);
    });
    Array.prototype.push.apply(
      query.or,
      accountTypes.flat().map((value: string) => ({
        accountTypes: { contains: value },
      })),
    );

    delete filterCopy.accountTypes.contains;
  }

  if (filterCopy?.region) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.region).map(filterKey => ({
        region: { [filterKey]: filterCopy.region[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.region.in;
  }

  if (filterCopy?.specAlerts && filterCopy.specAlerts.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.specAlerts.contains).map(specAlert => ({
        specAlerts: { contains: specAlert },
      })),
    );
    delete filterCopy.specAlerts.contains;
  }

  if (filterCopy?.projectTypes && filterCopy.projectTypes.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.projectTypes.contains).map(projectType => ({
        categories: { contains: projectType },
      })),
    );
    delete filterCopy.projectTypes.contains;
  }

  if (filterCopy?.actionStageAccounts && filterCopy.actionStageAccounts.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.actionStageAccounts.contains).map(actionStage => ({
        mappedActionStage: {
          contains: actionStage.toUpperCase(),
        },
      })),
    );
    delete filterCopy.actionStageAccounts.contains;
  }

  if (filterCopy?.actionStage && filterCopy.actionStage.in && !filterCopy.subActionStage) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.actionStage).map(filterKey => ({
        mappedActionStage: {
          [filterKey]: (filterCopy.actionStage[
            filterKey as keyof FilterValue
          ] as string[]).map(stage => stage.toUpperCase()),
        },
      })),
    );
    delete filterCopy.actionStage.in;
  }

  if (filterCopy?.subActionStage && filterCopy.subActionStage.in) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.subActionStage).map(filterKey => {
        return {
          actionStage: {
            [filterKey]: (filterCopy.subActionStage[
              filterKey as keyof FilterValue
            ] as string[]).map(stage => stage.substring(0, stage.indexOf(' - '))),
          },
        };
      }),
    );
    delete filterCopy.actionStage.in;
    delete filterCopy.subActionStage.in;
  }

  if (filterCopy?.actionStage && filterCopy.actionStage.eq) {
    const actionStages: any = [];
    Object.values(filterCopy.actionStage.eq).forEach(actionStage => {
      actionStages.push(actionStageValueMap[actionStage]);
    });
    Array.prototype.push.apply(
      query.or,
      actionStages.flat().map((actionStage: any) => ({
        mappedActionStage: { eq: actionStage },
      })),
    );
    delete filterCopy.actionStage.eq;
  }

  if (model === 'addresses') {
    if (filterCopy?.score?.between) {
      Array.prototype.push.apply(
        query.or,
        filterCopy?.score?.between.map(scoreRange => ({
          score: {
            gt: scoreRange[0],
            lte: scoreRange[1],
          },
        })),
      );
      delete filterCopy.score.between;
    }
  } else if (model === 'projectLeads') {
    if (filterCopy?.score) {
      filterCopy.projectScore = cloneDeep(filterCopy.score);
      filterCopy.score = {};
    }

    if (filterCopy?.projectScore?.between) {
      Array.prototype.push.apply(
        query.or,
        filterCopy?.projectScore?.between.map(scoreRange => ({
          projectScore: {
            gt: scoreRange[0],
            lte: scoreRange[1],
          },
        })),
      );
      delete filterCopy.score.between;
      delete filterCopy.projectScore.between;
    }
  }

  if (filterCopy?.projectTitle?.cicontains) {
    Array.prototype.push.apply(query.or, [
      { projectTitle: { cicontains: filterCopy.projectTitle.cicontains } },
    ]);
    delete filterCopy.projectTitle.cicontains;
  }

  if (filterCopy?.dodgeIds?.cicontains) {
    Array.prototype.push.apply(query.or, [
      { dodgeIds: { contains: filterCopy.dodgeIds.cicontains } },
    ]);
    delete filterCopy.dodgeIds.cicontains;
  }

  if (filterCopy?.[FilterKey.ArmstrongSpecRate]) {
    Array.prototype.push.apply(query.or, [
      { [FilterKey.ArmstrongSpecRate]: { ...filterCopy[FilterKey.ArmstrongSpecRate] } },
    ]);
    delete filterCopy[FilterKey.ArmstrongSpecRate];
  }

  if (filterCopy?.[FilterKey.CertainteedSpecRate]) {
    Array.prototype.push.apply(query.or, [
      { [FilterKey.CertainteedSpecRate]: { ...filterCopy[FilterKey.CertainteedSpecRate] } },
    ]);
    delete filterCopy[FilterKey.CertainteedSpecRate];
  }

  if (filterCopy?.[FilterKey.USGSpecRate]) {
    Array.prototype.push.apply(query.or, [
      { [FilterKey.USGSpecRate]: { ...filterCopy[FilterKey.USGSpecRate] } },
    ]);
    delete filterCopy[FilterKey.USGSpecRate];
  }

  if (filterCopy?.[FilterKey.RockfonSpecRate]) {
    Array.prototype.push.apply(query.or, [
      { [FilterKey.RockfonSpecRate]: { ...filterCopy[FilterKey.RockfonSpecRate] } },
    ]);
    delete filterCopy[FilterKey.RockfonSpecRate];
  }

  if (filterCopy?.activeProjectsCount?.between) {
    Array.prototype.push.apply(
      query.or,
      filterCopy?.activeProjectsCount?.between.map(scoreRange => ({
        activeProjectsCount: {
          gte: scoreRange[0],
          lte: scoreRange[1],
        },
      })),
    );
    delete filterCopy.activeProjectsCount.between;
  }

  if (filterCopy?.projectScoresAccounts && filterCopy.projectScoresAccounts?.in) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.projectScoresAccounts.in).map(scoreKey => ({
        [Accounts_Project_Score_Labels_and_Key[scoreKey]]: {
          eq: true,
        },
      })),
    );
    delete filterCopy.projectScoresAccounts.in;
  }

  return {
    // Include propertyId to ensure deterministic loading across pages
    sort: sort && [...sortColumns[orderBy].map(col => `${dir}${col}`), '-propertyId'],
    query: {
      ...query,
      ...filterCopy,
    },
    limit: limit || DEFAULT_PAGE_SIZE,
    offset: startIndex,
  };
};
