export const countyStateList: { county: string; state: string }[] = [
  {
    county: 'AUTAUGA',
    state: 'AL',
  },
  {
    county: 'BALDWIN',
    state: 'AL',
  },
  {
    county: 'BARBOUR',
    state: 'AL',
  },
  {
    county: 'BIBB',
    state: 'AL',
  },
  {
    county: 'BLOUNT',
    state: 'AL',
  },
  {
    county: 'BULLOCK',
    state: 'AL',
  },
  {
    county: 'BUTLER',
    state: 'AL',
  },
  {
    county: 'CALHOUN',
    state: 'AL',
  },
  {
    county: 'CHAMBERS',
    state: 'AL',
  },
  {
    county: 'CHEROKEE',
    state: 'AL',
  },
  {
    county: 'CHILTON',
    state: 'AL',
  },
  {
    county: 'CHOCTAW',
    state: 'AL',
  },
  {
    county: 'CLARKE',
    state: 'AL',
  },
  {
    county: 'CLAY',
    state: 'AL',
  },
  {
    county: 'CLEBURNE',
    state: 'AL',
  },
  {
    county: 'COFFEE',
    state: 'AL',
  },
  {
    county: 'COLBERT',
    state: 'AL',
  },
  {
    county: 'CONECUH',
    state: 'AL',
  },
  {
    county: 'COOSA',
    state: 'AL',
  },
  {
    county: 'COVINGTON',
    state: 'AL',
  },
  {
    county: 'CRENSHAW',
    state: 'AL',
  },
  {
    county: 'CULLMAN',
    state: 'AL',
  },
  {
    county: 'DALE',
    state: 'AL',
  },
  {
    county: 'DALLAS',
    state: 'AL',
  },
  {
    county: 'DEKALB',
    state: 'AL',
  },
  {
    county: 'ELMORE',
    state: 'AL',
  },
  {
    county: 'ESCAMBIA',
    state: 'AL',
  },
  {
    county: 'ETOWAH',
    state: 'AL',
  },
  {
    county: 'FAYETTE',
    state: 'AL',
  },
  {
    county: 'FRANKLIN',
    state: 'AL',
  },
  {
    county: 'GENEVA',
    state: 'AL',
  },
  {
    county: 'GREENE',
    state: 'AL',
  },
  {
    county: 'HALE',
    state: 'AL',
  },
  {
    county: 'HENRY',
    state: 'AL',
  },
  {
    county: 'HOUSTON',
    state: 'AL',
  },
  {
    county: 'JACKSON',
    state: 'AL',
  },
  {
    county: 'JEFFERSON',
    state: 'AL',
  },
  {
    county: 'LAMAR',
    state: 'AL',
  },
  {
    county: 'LAUDERDALE',
    state: 'AL',
  },
  {
    county: 'LAWRENCE',
    state: 'AL',
  },
  {
    county: 'LEE',
    state: 'AL',
  },
  {
    county: 'LIMESTONE',
    state: 'AL',
  },
  {
    county: 'LOWNDES',
    state: 'AL',
  },
  {
    county: 'MACON',
    state: 'AL',
  },
  {
    county: 'MADISON',
    state: 'AL',
  },
  {
    county: 'MARENGO',
    state: 'AL',
  },
  {
    county: 'MARION',
    state: 'AL',
  },
  {
    county: 'MARSHALL',
    state: 'AL',
  },
  {
    county: 'MOBILE',
    state: 'AL',
  },
  {
    county: 'MONROE',
    state: 'AL',
  },
  {
    county: 'MONTGOMERY',
    state: 'AL',
  },
  {
    county: 'MORGAN',
    state: 'AL',
  },
  {
    county: 'PERRY',
    state: 'AL',
  },
  {
    county: 'PICKENS',
    state: 'AL',
  },
  {
    county: 'PIKE',
    state: 'AL',
  },
  {
    county: 'RANDOLPH',
    state: 'AL',
  },
  {
    county: 'RUSSELL',
    state: 'AL',
  },
  {
    county: 'ST CLAIR',
    state: 'AL',
  },
  {
    county: 'SHELBY',
    state: 'AL',
  },
  {
    county: 'SUMTER',
    state: 'AL',
  },
  {
    county: 'TALLADEGA',
    state: 'AL',
  },
  {
    county: 'TALLAPOOSA',
    state: 'AL',
  },
  {
    county: 'TUSCALOOSA',
    state: 'AL',
  },
  {
    county: 'WALKER',
    state: 'AL',
  },
  {
    county: 'WASHINGTON',
    state: 'AL',
  },
  {
    county: 'WILCOX',
    state: 'AL',
  },
  {
    county: 'WINSTON',
    state: 'AL',
  },
  {
    county: 'ALEUTIANS EAST',
    state: 'AK',
  },
  {
    county: 'ALEUTIANS WEST',
    state: 'AK',
  },
  {
    county: 'ANCHORAGE',
    state: 'AK',
  },
  {
    county: 'BETHEL',
    state: 'AK',
  },
  {
    county: 'BRISTOL BAY',
    state: 'AK',
  },
  {
    county: 'DENALI',
    state: 'AK',
  },
  {
    county: 'DILLINGHAM',
    state: 'AK',
  },
  {
    county: 'FAIRBANKS NORTH STAR',
    state: 'AK',
  },
  {
    county: 'HAINES',
    state: 'AK',
  },
  {
    county: 'HOONAH ANGOON',
    state: 'AK',
  },
  {
    county: 'JUNEAU, CITY AND',
    state: 'AK',
  },
  {
    county: 'KENAI PENINSULA',
    state: 'AK',
  },
  {
    county: 'KETCHIKAN GATEWAY',
    state: 'AK',
  },
  {
    county: 'KODIAK ISLAND',
    state: 'AK',
  },
  {
    county: 'LAKE AND PENINSULA',
    state: 'AK',
  },
  {
    county: 'MATANUSKA SUSITNA',
    state: 'AK',
  },
  {
    county: 'NOME',
    state: 'AK',
  },
  {
    county: 'NORTH SLOPE',
    state: 'AK',
  },
  {
    county: 'NORTHWEST ARCTIC',
    state: 'AK',
  },
  {
    county: 'PETERSBURG',
    state: 'AK',
  },
  {
    county: 'PRINCE OF WALES HYDER',
    state: 'AK',
  },
  {
    county: 'SITKA',
    state: 'AK',
  },
  {
    county: 'SKAGWAY',
    state: 'AK',
  },
  {
    county: 'SOUTHEAST FAIRBANKS',
    state: 'AK',
  },
  {
    county: 'VALDEZ CORDOVA',
    state: 'AK',
  },
  {
    county: 'WADE HAMPTON',
    state: 'AK',
  },
  {
    county: 'WRANGELL',
    state: 'AK',
  },
  {
    county: 'YAKUTAT',
    state: 'AK',
  },
  {
    county: 'YUKON KOYUKUK',
    state: 'AK',
  },
  {
    county: 'APACHE',
    state: 'AZ',
  },
  {
    county: 'COCHISE',
    state: 'AZ',
  },
  {
    county: 'COCONINO',
    state: 'AZ',
  },
  {
    county: 'GILA',
    state: 'AZ',
  },
  {
    county: 'GRAHAM',
    state: 'AZ',
  },
  {
    county: 'GREENLEE',
    state: 'AZ',
  },
  {
    county: 'LA PAZ',
    state: 'AZ',
  },
  {
    county: 'MARICOPA',
    state: 'AZ',
  },
  {
    county: 'MOHAVE',
    state: 'AZ',
  },
  {
    county: 'NAVAJO',
    state: 'AZ',
  },
  {
    county: 'PIMA',
    state: 'AZ',
  },
  {
    county: 'PINAL',
    state: 'AZ',
  },
  {
    county: 'SANTA CRUZ',
    state: 'AZ',
  },
  {
    county: 'YAVAPAI',
    state: 'AZ',
  },
  {
    county: 'YUMA',
    state: 'AZ',
  },
  {
    county: 'ARKANSAS',
    state: 'AR',
  },
  {
    county: 'ASHLEY',
    state: 'AR',
  },
  {
    county: 'BAXTER',
    state: 'AR',
  },
  {
    county: 'BENTON',
    state: 'AR',
  },
  {
    county: 'BOONE',
    state: 'AR',
  },
  {
    county: 'BRADLEY',
    state: 'AR',
  },
  {
    county: 'CALHOUN',
    state: 'AR',
  },
  {
    county: 'CARROLL',
    state: 'AR',
  },
  {
    county: 'CHICOT',
    state: 'AR',
  },
  {
    county: 'CLARK',
    state: 'AR',
  },
  {
    county: 'CLAY',
    state: 'AR',
  },
  {
    county: 'CLEBURNE',
    state: 'AR',
  },
  {
    county: 'CLEVELAND',
    state: 'AR',
  },
  {
    county: 'COLUMBIA',
    state: 'AR',
  },
  {
    county: 'CONWAY',
    state: 'AR',
  },
  {
    county: 'CRAIGHEAD',
    state: 'AR',
  },
  {
    county: 'CRAWFORD',
    state: 'AR',
  },
  {
    county: 'CRITTENDEN',
    state: 'AR',
  },
  {
    county: 'CROSS',
    state: 'AR',
  },
  {
    county: 'DALLAS',
    state: 'AR',
  },
  {
    county: 'DESHA',
    state: 'AR',
  },
  {
    county: 'DREW',
    state: 'AR',
  },
  {
    county: 'FAULKNER',
    state: 'AR',
  },
  {
    county: 'FRANKLIN',
    state: 'AR',
  },
  {
    county: 'FULTON',
    state: 'AR',
  },
  {
    county: 'GARLAND',
    state: 'AR',
  },
  {
    county: 'GRANT',
    state: 'AR',
  },
  {
    county: 'GREENE',
    state: 'AR',
  },
  {
    county: 'HEMPSTEAD',
    state: 'AR',
  },
  {
    county: 'HOT SPRING',
    state: 'AR',
  },
  {
    county: 'HOWARD',
    state: 'AR',
  },
  {
    county: 'INDEPENDENCE',
    state: 'AR',
  },
  {
    county: 'IZARD',
    state: 'AR',
  },
  {
    county: 'JACKSON',
    state: 'AR',
  },
  {
    county: 'JEFFERSON',
    state: 'AR',
  },
  {
    county: 'JOHNSON',
    state: 'AR',
  },
  {
    county: 'LAFAYETTE',
    state: 'AR',
  },
  {
    county: 'LAWRENCE',
    state: 'AR',
  },
  {
    county: 'LEE',
    state: 'AR',
  },
  {
    county: 'LINCOLN',
    state: 'AR',
  },
  {
    county: 'LITTLE RIVER',
    state: 'AR',
  },
  {
    county: 'LOGAN',
    state: 'AR',
  },
  {
    county: 'LONOKE',
    state: 'AR',
  },
  {
    county: 'MADISON',
    state: 'AR',
  },
  {
    county: 'MARION',
    state: 'AR',
  },
  {
    county: 'MILLER',
    state: 'AR',
  },
  {
    county: 'MISSISSIPPI',
    state: 'AR',
  },
  {
    county: 'MONROE',
    state: 'AR',
  },
  {
    county: 'MONTGOMERY',
    state: 'AR',
  },
  {
    county: 'NEVADA',
    state: 'AR',
  },
  {
    county: 'NEWTON',
    state: 'AR',
  },
  {
    county: 'OUACHITA',
    state: 'AR',
  },
  {
    county: 'PERRY',
    state: 'AR',
  },
  {
    county: 'PHILLIPS',
    state: 'AR',
  },
  {
    county: 'PIKE',
    state: 'AR',
  },
  {
    county: 'POINSETT',
    state: 'AR',
  },
  {
    county: 'POLK',
    state: 'AR',
  },
  {
    county: 'POPE',
    state: 'AR',
  },
  {
    county: 'PRAIRIE',
    state: 'AR',
  },
  {
    county: 'PULASKI',
    state: 'AR',
  },
  {
    county: 'RANDOLPH',
    state: 'AR',
  },
  {
    county: 'ST FRANCIS',
    state: 'AR',
  },
  {
    county: 'SALINE',
    state: 'AR',
  },
  {
    county: 'SCOTT',
    state: 'AR',
  },
  {
    county: 'SEARCY',
    state: 'AR',
  },
  {
    county: 'SEBASTIAN',
    state: 'AR',
  },
  {
    county: 'SEVIER',
    state: 'AR',
  },
  {
    county: 'SHARP',
    state: 'AR',
  },
  {
    county: 'STONE',
    state: 'AR',
  },
  {
    county: 'UNION',
    state: 'AR',
  },
  {
    county: 'VAN BUREN',
    state: 'AR',
  },
  {
    county: 'WASHINGTON',
    state: 'AR',
  },
  {
    county: 'WHITE',
    state: 'AR',
  },
  {
    county: 'WOODRUFF',
    state: 'AR',
  },
  {
    county: 'YELL',
    state: 'AR',
  },
  {
    county: 'ALAMEDA',
    state: 'CA',
  },
  {
    county: 'ALPINE',
    state: 'CA',
  },
  {
    county: 'AMADOR',
    state: 'CA',
  },
  {
    county: 'BUTTE',
    state: 'CA',
  },
  {
    county: 'CALAVERAS',
    state: 'CA',
  },
  {
    county: 'COLUSA',
    state: 'CA',
  },
  {
    county: 'CONTRA COSTA',
    state: 'CA',
  },
  {
    county: 'DEL NORTE',
    state: 'CA',
  },
  {
    county: 'EL DORADO',
    state: 'CA',
  },
  {
    county: 'FRESNO',
    state: 'CA',
  },
  {
    county: 'GLENN',
    state: 'CA',
  },
  {
    county: 'HUMBOLDT',
    state: 'CA',
  },
  {
    county: 'IMPERIAL',
    state: 'CA',
  },
  {
    county: 'INYO',
    state: 'CA',
  },
  {
    county: 'KERN',
    state: 'CA',
  },
  {
    county: 'KINGS',
    state: 'CA',
  },
  {
    county: 'LAKE',
    state: 'CA',
  },
  {
    county: 'LASSEN',
    state: 'CA',
  },
  {
    county: 'LOS ANGELES',
    state: 'CA',
  },
  {
    county: 'MADERA',
    state: 'CA',
  },
  {
    county: 'MARIN',
    state: 'CA',
  },
  {
    county: 'MARIPOSA',
    state: 'CA',
  },
  {
    county: 'MENDOCINO',
    state: 'CA',
  },
  {
    county: 'MERCED',
    state: 'CA',
  },
  {
    county: 'MODOC',
    state: 'CA',
  },
  {
    county: 'MONO',
    state: 'CA',
  },
  {
    county: 'MONTEREY',
    state: 'CA',
  },
  {
    county: 'NAPA',
    state: 'CA',
  },
  {
    county: 'NEVADA',
    state: 'CA',
  },
  {
    county: 'ORANGE',
    state: 'CA',
  },
  {
    county: 'PLACER',
    state: 'CA',
  },
  {
    county: 'PLUMAS',
    state: 'CA',
  },
  {
    county: 'RIVERSIDE',
    state: 'CA',
  },
  {
    county: 'SACRAMENTO',
    state: 'CA',
  },
  {
    county: 'SAN BENITO',
    state: 'CA',
  },
  {
    county: 'SAN BERNARDINO',
    state: 'CA',
  },
  {
    county: 'SAN DIEGO',
    state: 'CA',
  },
  {
    county: 'SAN FRANCISCO',
    state: 'CA',
  },
  {
    county: 'SAN JOAQUIN',
    state: 'CA',
  },
  {
    county: 'SAN LUIS OBISPO',
    state: 'CA',
  },
  {
    county: 'SAN MATEO',
    state: 'CA',
  },
  {
    county: 'SANTA BARBARA',
    state: 'CA',
  },
  {
    county: 'SANTA CLARA',
    state: 'CA',
  },
  {
    county: 'SANTA CRUZ',
    state: 'CA',
  },
  {
    county: 'SHASTA',
    state: 'CA',
  },
  {
    county: 'SIERRA',
    state: 'CA',
  },
  {
    county: 'SISKIYOU',
    state: 'CA',
  },
  {
    county: 'SOLANO',
    state: 'CA',
  },
  {
    county: 'SONOMA',
    state: 'CA',
  },
  {
    county: 'STANISLAUS',
    state: 'CA',
  },
  {
    county: 'SUTTER',
    state: 'CA',
  },
  {
    county: 'TEHAMA',
    state: 'CA',
  },
  {
    county: 'TRINITY',
    state: 'CA',
  },
  {
    county: 'TULARE',
    state: 'CA',
  },
  {
    county: 'TUOLUMNE',
    state: 'CA',
  },
  {
    county: 'VENTURA',
    state: 'CA',
  },
  {
    county: 'YOLO',
    state: 'CA',
  },
  {
    county: 'YUBA',
    state: 'CA',
  },
  {
    county: 'ADAMS',
    state: 'CO',
  },
  {
    county: 'ALAMOSA',
    state: 'CO',
  },
  {
    county: 'ARAPAHOE',
    state: 'CO',
  },
  {
    county: 'ARCHULETA',
    state: 'CO',
  },
  {
    county: 'BACA',
    state: 'CO',
  },
  {
    county: 'BENT',
    state: 'CO',
  },
  {
    county: 'BOULDER',
    state: 'CO',
  },
  {
    county: 'BROOMFIELD',
    state: 'CO',
  },
  {
    county: 'CHAFFEE',
    state: 'CO',
  },
  {
    county: 'CHEYENNE',
    state: 'CO',
  },
  {
    county: 'CLEAR CREEK',
    state: 'CO',
  },
  {
    county: 'CONEJOS',
    state: 'CO',
  },
  {
    county: 'COSTILLA',
    state: 'CO',
  },
  {
    county: 'CROWLEY',
    state: 'CO',
  },
  {
    county: 'CUSTER',
    state: 'CO',
  },
  {
    county: 'DELTA',
    state: 'CO',
  },
  {
    county: 'DENVER',
    state: 'CO',
  },
  {
    county: 'DOLORES',
    state: 'CO',
  },
  {
    county: 'DOUGLAS',
    state: 'CO',
  },
  {
    county: 'EAGLE',
    state: 'CO',
  },
  {
    county: 'ELBERT',
    state: 'CO',
  },
  {
    county: 'EL PASO',
    state: 'CO',
  },
  {
    county: 'FREMONT',
    state: 'CO',
  },
  {
    county: 'GARFIELD',
    state: 'CO',
  },
  {
    county: 'GILPIN',
    state: 'CO',
  },
  {
    county: 'GRAND',
    state: 'CO',
  },
  {
    county: 'GUNNISON',
    state: 'CO',
  },
  {
    county: 'HINSDALE',
    state: 'CO',
  },
  {
    county: 'HUERFANO',
    state: 'CO',
  },
  {
    county: 'JACKSON',
    state: 'CO',
  },
  {
    county: 'JEFFERSON',
    state: 'CO',
  },
  {
    county: 'KIOWA',
    state: 'CO',
  },
  {
    county: 'KIT CARSON',
    state: 'CO',
  },
  {
    county: 'LAKE',
    state: 'CO',
  },
  {
    county: 'LA PLATA',
    state: 'CO',
  },
  {
    county: 'LARIMER',
    state: 'CO',
  },
  {
    county: 'LAS ANIMAS',
    state: 'CO',
  },
  {
    county: 'LINCOLN',
    state: 'CO',
  },
  {
    county: 'LOGAN',
    state: 'CO',
  },
  {
    county: 'MESA',
    state: 'CO',
  },
  {
    county: 'MINERAL',
    state: 'CO',
  },
  {
    county: 'MOFFAT',
    state: 'CO',
  },
  {
    county: 'MONTEZUMA',
    state: 'CO',
  },
  {
    county: 'MONTROSE',
    state: 'CO',
  },
  {
    county: 'MORGAN',
    state: 'CO',
  },
  {
    county: 'OTERO',
    state: 'CO',
  },
  {
    county: 'OURAY',
    state: 'CO',
  },
  {
    county: 'PARK',
    state: 'CO',
  },
  {
    county: 'PHILLIPS',
    state: 'CO',
  },
  {
    county: 'PITKIN',
    state: 'CO',
  },
  {
    county: 'PROWERS',
    state: 'CO',
  },
  {
    county: 'PUEBLO',
    state: 'CO',
  },
  {
    county: 'RIO BLANCO',
    state: 'CO',
  },
  {
    county: 'RIO GRANDE',
    state: 'CO',
  },
  {
    county: 'ROUTT',
    state: 'CO',
  },
  {
    county: 'SAGUACHE',
    state: 'CO',
  },
  {
    county: 'SAN JUAN',
    state: 'CO',
  },
  {
    county: 'SAN MIGUEL',
    state: 'CO',
  },
  {
    county: 'SEDGWICK',
    state: 'CO',
  },
  {
    county: 'SUMMIT',
    state: 'CO',
  },
  {
    county: 'TELLER',
    state: 'CO',
  },
  {
    county: 'WASHINGTON',
    state: 'CO',
  },
  {
    county: 'WELD',
    state: 'CO',
  },
  {
    county: 'YUMA',
    state: 'CO',
  },
  {
    county: 'FAIRFIELD',
    state: 'CT',
  },
  {
    county: 'HARTFORD',
    state: 'CT',
  },
  {
    county: 'LITCHFIELD',
    state: 'CT',
  },
  {
    county: 'MIDDLESEX',
    state: 'CT',
  },
  {
    county: 'NEW HAVEN',
    state: 'CT',
  },
  {
    county: 'NEW LONDON',
    state: 'CT',
  },
  {
    county: 'TOLLAND',
    state: 'CT',
  },
  {
    county: 'WINDHAM',
    state: 'CT',
  },
  {
    county: 'KENT',
    state: 'DE',
  },
  {
    county: 'NEW CASTLE',
    state: 'DE',
  },
  {
    county: 'SUSSEX',
    state: 'DE',
  },
  {
    county: 'DISTRICT OF COLUMBIA',
    state: 'DC',
  },
  {
    county: 'ALACHUA',
    state: 'FL',
  },
  {
    county: 'BAKER',
    state: 'FL',
  },
  {
    county: 'BAY',
    state: 'FL',
  },
  {
    county: 'BRADFORD',
    state: 'FL',
  },
  {
    county: 'BREVARD',
    state: 'FL',
  },
  {
    county: 'BROWARD',
    state: 'FL',
  },
  {
    county: 'CALHOUN',
    state: 'FL',
  },
  {
    county: 'CHARLOTTE',
    state: 'FL',
  },
  {
    county: 'CITRUS',
    state: 'FL',
  },
  {
    county: 'CLAY',
    state: 'FL',
  },
  {
    county: 'COLLIER',
    state: 'FL',
  },
  {
    county: 'COLUMBIA',
    state: 'FL',
  },
  {
    county: 'DESOTO',
    state: 'FL',
  },
  {
    county: 'DIXIE',
    state: 'FL',
  },
  {
    county: 'DUVAL',
    state: 'FL',
  },
  {
    county: 'ESCAMBIA',
    state: 'FL',
  },
  {
    county: 'FLAGLER',
    state: 'FL',
  },
  {
    county: 'FRANKLIN',
    state: 'FL',
  },
  {
    county: 'GADSDEN',
    state: 'FL',
  },
  {
    county: 'GILCHRIST',
    state: 'FL',
  },
  {
    county: 'GLADES',
    state: 'FL',
  },
  {
    county: 'GULF',
    state: 'FL',
  },
  {
    county: 'HAMILTON',
    state: 'FL',
  },
  {
    county: 'HARDEE',
    state: 'FL',
  },
  {
    county: 'HENDRY',
    state: 'FL',
  },
  {
    county: 'HERNANDO',
    state: 'FL',
  },
  {
    county: 'HIGHLANDS',
    state: 'FL',
  },
  {
    county: 'HILLSBOROUGH',
    state: 'FL',
  },
  {
    county: 'HOLMES',
    state: 'FL',
  },
  {
    county: 'INDIAN RIVER',
    state: 'FL',
  },
  {
    county: 'JACKSON',
    state: 'FL',
  },
  {
    county: 'JEFFERSON',
    state: 'FL',
  },
  {
    county: 'LAFAYETTE',
    state: 'FL',
  },
  {
    county: 'LAKE',
    state: 'FL',
  },
  {
    county: 'LEE',
    state: 'FL',
  },
  {
    county: 'LEON',
    state: 'FL',
  },
  {
    county: 'LEVY',
    state: 'FL',
  },
  {
    county: 'LIBERTY',
    state: 'FL',
  },
  {
    county: 'MADISON',
    state: 'FL',
  },
  {
    county: 'MANATEE',
    state: 'FL',
  },
  {
    county: 'MARION',
    state: 'FL',
  },
  {
    county: 'MARTIN',
    state: 'FL',
  },
  {
    county: 'MIAMI DADE',
    state: 'FL',
  },
  {
    county: 'MONROE',
    state: 'FL',
  },
  {
    county: 'NASSAU',
    state: 'FL',
  },
  {
    county: 'OKALOOSA',
    state: 'FL',
  },
  {
    county: 'OKEECHOBEE',
    state: 'FL',
  },
  {
    county: 'ORANGE',
    state: 'FL',
  },
  {
    county: 'OSCEOLA',
    state: 'FL',
  },
  {
    county: 'PALM BEACH',
    state: 'FL',
  },
  {
    county: 'PASCO',
    state: 'FL',
  },
  {
    county: 'PINELLAS',
    state: 'FL',
  },
  {
    county: 'POLK',
    state: 'FL',
  },
  {
    county: 'PUTNAM',
    state: 'FL',
  },
  {
    county: 'ST JOHNS',
    state: 'FL',
  },
  {
    county: 'ST LUCIE',
    state: 'FL',
  },
  {
    county: 'SANTA ROSA',
    state: 'FL',
  },
  {
    county: 'SARASOTA',
    state: 'FL',
  },
  {
    county: 'SEMINOLE',
    state: 'FL',
  },
  {
    county: 'SUMTER',
    state: 'FL',
  },
  {
    county: 'SUWANNEE',
    state: 'FL',
  },
  {
    county: 'TAYLOR',
    state: 'FL',
  },
  {
    county: 'UNION',
    state: 'FL',
  },
  {
    county: 'VOLUSIA',
    state: 'FL',
  },
  {
    county: 'WAKULLA',
    state: 'FL',
  },
  {
    county: 'WALTON',
    state: 'FL',
  },
  {
    county: 'WASHINGTON',
    state: 'FL',
  },
  {
    county: 'APPLING',
    state: 'GA',
  },
  {
    county: 'ATKINSON',
    state: 'GA',
  },
  {
    county: 'BACON',
    state: 'GA',
  },
  {
    county: 'BAKER',
    state: 'GA',
  },
  {
    county: 'BALDWIN',
    state: 'GA',
  },
  {
    county: 'BANKS',
    state: 'GA',
  },
  {
    county: 'BARROW',
    state: 'GA',
  },
  {
    county: 'BARTOW',
    state: 'GA',
  },
  {
    county: 'BEN HILL',
    state: 'GA',
  },
  {
    county: 'BERRIEN',
    state: 'GA',
  },
  {
    county: 'BIBB',
    state: 'GA',
  },
  {
    county: 'BLECKLEY',
    state: 'GA',
  },
  {
    county: 'BRANTLEY',
    state: 'GA',
  },
  {
    county: 'BROOKS',
    state: 'GA',
  },
  {
    county: 'BRYAN',
    state: 'GA',
  },
  {
    county: 'BULLOCH',
    state: 'GA',
  },
  {
    county: 'BURKE',
    state: 'GA',
  },
  {
    county: 'BUTTS',
    state: 'GA',
  },
  {
    county: 'CALHOUN',
    state: 'GA',
  },
  {
    county: 'CAMDEN',
    state: 'GA',
  },
  {
    county: 'CANDLER',
    state: 'GA',
  },
  {
    county: 'CARROLL',
    state: 'GA',
  },
  {
    county: 'CATOOSA',
    state: 'GA',
  },
  {
    county: 'CHARLTON',
    state: 'GA',
  },
  {
    county: 'CHATHAM',
    state: 'GA',
  },
  {
    county: 'CHATTAHOOCHEE',
    state: 'GA',
  },
  {
    county: 'CHATTOOGA',
    state: 'GA',
  },
  {
    county: 'CHEROKEE',
    state: 'GA',
  },
  {
    county: 'CLARKE',
    state: 'GA',
  },
  {
    county: 'CLAY',
    state: 'GA',
  },
  {
    county: 'CLAYTON',
    state: 'GA',
  },
  {
    county: 'CLINCH',
    state: 'GA',
  },
  {
    county: 'COBB',
    state: 'GA',
  },
  {
    county: 'COFFEE',
    state: 'GA',
  },
  {
    county: 'COLQUITT',
    state: 'GA',
  },
  {
    county: 'COLUMBIA',
    state: 'GA',
  },
  {
    county: 'COOK',
    state: 'GA',
  },
  {
    county: 'COWETA',
    state: 'GA',
  },
  {
    county: 'CRAWFORD',
    state: 'GA',
  },
  {
    county: 'CRISP',
    state: 'GA',
  },
  {
    county: 'DADE',
    state: 'GA',
  },
  {
    county: 'DAWSON',
    state: 'GA',
  },
  {
    county: 'DECATUR',
    state: 'GA',
  },
  {
    county: 'DEKALB',
    state: 'GA',
  },
  {
    county: 'DODGE',
    state: 'GA',
  },
  {
    county: 'DOOLY',
    state: 'GA',
  },
  {
    county: 'DOUGHERTY',
    state: 'GA',
  },
  {
    county: 'DOUGLAS',
    state: 'GA',
  },
  {
    county: 'EARLY',
    state: 'GA',
  },
  {
    county: 'ECHOLS',
    state: 'GA',
  },
  {
    county: 'EFFINGHAM',
    state: 'GA',
  },
  {
    county: 'ELBERT',
    state: 'GA',
  },
  {
    county: 'EMANUEL',
    state: 'GA',
  },
  {
    county: 'EVANS',
    state: 'GA',
  },
  {
    county: 'FANNIN',
    state: 'GA',
  },
  {
    county: 'FAYETTE',
    state: 'GA',
  },
  {
    county: 'FLOYD',
    state: 'GA',
  },
  {
    county: 'FORSYTH',
    state: 'GA',
  },
  {
    county: 'FRANKLIN',
    state: 'GA',
  },
  {
    county: 'FULTON',
    state: 'GA',
  },
  {
    county: 'GILMER',
    state: 'GA',
  },
  {
    county: 'GLASCOCK',
    state: 'GA',
  },
  {
    county: 'GLYNN',
    state: 'GA',
  },
  {
    county: 'GORDON',
    state: 'GA',
  },
  {
    county: 'GRADY',
    state: 'GA',
  },
  {
    county: 'GREENE',
    state: 'GA',
  },
  {
    county: 'GWINNETT',
    state: 'GA',
  },
  {
    county: 'HABERSHAM',
    state: 'GA',
  },
  {
    county: 'HALL',
    state: 'GA',
  },
  {
    county: 'HANCOCK',
    state: 'GA',
  },
  {
    county: 'HARALSON',
    state: 'GA',
  },
  {
    county: 'HARRIS',
    state: 'GA',
  },
  {
    county: 'HART',
    state: 'GA',
  },
  {
    county: 'HEARD',
    state: 'GA',
  },
  {
    county: 'HENRY',
    state: 'GA',
  },
  {
    county: 'HOUSTON',
    state: 'GA',
  },
  {
    county: 'IRWIN',
    state: 'GA',
  },
  {
    county: 'JACKSON',
    state: 'GA',
  },
  {
    county: 'JASPER',
    state: 'GA',
  },
  {
    county: 'JEFF DAVIS',
    state: 'GA',
  },
  {
    county: 'JEFFERSON',
    state: 'GA',
  },
  {
    county: 'JENKINS',
    state: 'GA',
  },
  {
    county: 'JOHNSON',
    state: 'GA',
  },
  {
    county: 'JONES',
    state: 'GA',
  },
  {
    county: 'LAMAR',
    state: 'GA',
  },
  {
    county: 'LANIER',
    state: 'GA',
  },
  {
    county: 'LAURENS',
    state: 'GA',
  },
  {
    county: 'LEE',
    state: 'GA',
  },
  {
    county: 'LIBERTY',
    state: 'GA',
  },
  {
    county: 'LINCOLN',
    state: 'GA',
  },
  {
    county: 'LONG',
    state: 'GA',
  },
  {
    county: 'LOWNDES',
    state: 'GA',
  },
  {
    county: 'LUMPKIN',
    state: 'GA',
  },
  {
    county: 'MCDUFFIE',
    state: 'GA',
  },
  {
    county: 'MCINTOSH',
    state: 'GA',
  },
  {
    county: 'MACON',
    state: 'GA',
  },
  {
    county: 'MADISON',
    state: 'GA',
  },
  {
    county: 'MARION',
    state: 'GA',
  },
  {
    county: 'MERIWETHER',
    state: 'GA',
  },
  {
    county: 'MILLER',
    state: 'GA',
  },
  {
    county: 'MITCHELL',
    state: 'GA',
  },
  {
    county: 'MONROE',
    state: 'GA',
  },
  {
    county: 'MONTGOMERY',
    state: 'GA',
  },
  {
    county: 'MORGAN',
    state: 'GA',
  },
  {
    county: 'MURRAY',
    state: 'GA',
  },
  {
    county: 'MUSCOGEE',
    state: 'GA',
  },
  {
    county: 'NEWTON',
    state: 'GA',
  },
  {
    county: 'OCONEE',
    state: 'GA',
  },
  {
    county: 'OGLETHORPE',
    state: 'GA',
  },
  {
    county: 'PAULDING',
    state: 'GA',
  },
  {
    county: 'PEACH',
    state: 'GA',
  },
  {
    county: 'PICKENS',
    state: 'GA',
  },
  {
    county: 'PIERCE',
    state: 'GA',
  },
  {
    county: 'PIKE',
    state: 'GA',
  },
  {
    county: 'POLK',
    state: 'GA',
  },
  {
    county: 'PULASKI',
    state: 'GA',
  },
  {
    county: 'PUTNAM',
    state: 'GA',
  },
  {
    county: 'QUITMAN',
    state: 'GA',
  },
  {
    county: 'RABUN',
    state: 'GA',
  },
  {
    county: 'RANDOLPH',
    state: 'GA',
  },
  {
    county: 'RICHMOND',
    state: 'GA',
  },
  {
    county: 'ROCKDALE',
    state: 'GA',
  },
  {
    county: 'SCHLEY',
    state: 'GA',
  },
  {
    county: 'SCREVEN',
    state: 'GA',
  },
  {
    county: 'SEMINOLE',
    state: 'GA',
  },
  {
    county: 'SPALDING',
    state: 'GA',
  },
  {
    county: 'STEPHENS',
    state: 'GA',
  },
  {
    county: 'STEWART',
    state: 'GA',
  },
  {
    county: 'SUMTER',
    state: 'GA',
  },
  {
    county: 'TALBOT',
    state: 'GA',
  },
  {
    county: 'TALIAFERRO',
    state: 'GA',
  },
  {
    county: 'TATTNALL',
    state: 'GA',
  },
  {
    county: 'TAYLOR',
    state: 'GA',
  },
  {
    county: 'TELFAIR',
    state: 'GA',
  },
  {
    county: 'TERRELL',
    state: 'GA',
  },
  {
    county: 'THOMAS',
    state: 'GA',
  },
  {
    county: 'TIFT',
    state: 'GA',
  },
  {
    county: 'TOOMBS',
    state: 'GA',
  },
  {
    county: 'TOWNS',
    state: 'GA',
  },
  {
    county: 'TREUTLEN',
    state: 'GA',
  },
  {
    county: 'TROUP',
    state: 'GA',
  },
  {
    county: 'TURNER',
    state: 'GA',
  },
  {
    county: 'TWIGGS',
    state: 'GA',
  },
  {
    county: 'UNION',
    state: 'GA',
  },
  {
    county: 'UPSON',
    state: 'GA',
  },
  {
    county: 'WALKER',
    state: 'GA',
  },
  {
    county: 'WALTON',
    state: 'GA',
  },
  {
    county: 'WARE',
    state: 'GA',
  },
  {
    county: 'WARREN',
    state: 'GA',
  },
  {
    county: 'WASHINGTON',
    state: 'GA',
  },
  {
    county: 'WAYNE',
    state: 'GA',
  },
  {
    county: 'WEBSTER',
    state: 'GA',
  },
  {
    county: 'WHEELER',
    state: 'GA',
  },
  {
    county: 'WHITE',
    state: 'GA',
  },
  {
    county: 'WHITFIELD',
    state: 'GA',
  },
  {
    county: 'WILCOX',
    state: 'GA',
  },
  {
    county: 'WILKES',
    state: 'GA',
  },
  {
    county: 'WILKINSON',
    state: 'GA',
  },
  {
    county: 'WORTH',
    state: 'GA',
  },
  {
    county: 'HAWAII',
    state: 'HI',
  },
  {
    county: 'HONOLULU',
    state: 'HI',
  },
  {
    county: 'KALAWAO',
    state: 'HI',
  },
  {
    county: 'KAUAI',
    state: 'HI',
  },
  {
    county: 'MAUI',
    state: 'HI',
  },
  {
    county: 'ADA',
    state: 'ID',
  },
  {
    county: 'ADAMS',
    state: 'ID',
  },
  {
    county: 'BANNOCK',
    state: 'ID',
  },
  {
    county: 'BEAR LAKE',
    state: 'ID',
  },
  {
    county: 'BENEWAH',
    state: 'ID',
  },
  {
    county: 'BINGHAM',
    state: 'ID',
  },
  {
    county: 'BLAINE',
    state: 'ID',
  },
  {
    county: 'BOISE',
    state: 'ID',
  },
  {
    county: 'BONNER',
    state: 'ID',
  },
  {
    county: 'BONNEVILLE',
    state: 'ID',
  },
  {
    county: 'BOUNDARY',
    state: 'ID',
  },
  {
    county: 'BUTTE',
    state: 'ID',
  },
  {
    county: 'CAMAS',
    state: 'ID',
  },
  {
    county: 'CANYON',
    state: 'ID',
  },
  {
    county: 'CARIBOU',
    state: 'ID',
  },
  {
    county: 'CASSIA',
    state: 'ID',
  },
  {
    county: 'CLARK',
    state: 'ID',
  },
  {
    county: 'CLEARWATER',
    state: 'ID',
  },
  {
    county: 'CUSTER',
    state: 'ID',
  },
  {
    county: 'ELMORE',
    state: 'ID',
  },
  {
    county: 'FRANKLIN',
    state: 'ID',
  },
  {
    county: 'FREMONT',
    state: 'ID',
  },
  {
    county: 'GEM',
    state: 'ID',
  },
  {
    county: 'GOODING',
    state: 'ID',
  },
  {
    county: 'IDAHO',
    state: 'ID',
  },
  {
    county: 'JEFFERSON',
    state: 'ID',
  },
  {
    county: 'JEROME',
    state: 'ID',
  },
  {
    county: 'KOOTENAI',
    state: 'ID',
  },
  {
    county: 'LATAH',
    state: 'ID',
  },
  {
    county: 'LEMHI',
    state: 'ID',
  },
  {
    county: 'LEWIS',
    state: 'ID',
  },
  {
    county: 'LINCOLN',
    state: 'ID',
  },
  {
    county: 'MADISON',
    state: 'ID',
  },
  {
    county: 'MINIDOKA',
    state: 'ID',
  },
  {
    county: 'NEZ PERCE',
    state: 'ID',
  },
  {
    county: 'ONEIDA',
    state: 'ID',
  },
  {
    county: 'OWYHEE',
    state: 'ID',
  },
  {
    county: 'PAYETTE',
    state: 'ID',
  },
  {
    county: 'POWER',
    state: 'ID',
  },
  {
    county: 'SHOSHONE',
    state: 'ID',
  },
  {
    county: 'TETON',
    state: 'ID',
  },
  {
    county: 'TWIN FALLS',
    state: 'ID',
  },
  {
    county: 'VALLEY',
    state: 'ID',
  },
  {
    county: 'WASHINGTON',
    state: 'ID',
  },
  {
    county: 'ADAMS',
    state: 'IL',
  },
  {
    county: 'ALEXANDER',
    state: 'IL',
  },
  {
    county: 'BOND',
    state: 'IL',
  },
  {
    county: 'BOONE',
    state: 'IL',
  },
  {
    county: 'BROWN',
    state: 'IL',
  },
  {
    county: 'BUREAU',
    state: 'IL',
  },
  {
    county: 'CALHOUN',
    state: 'IL',
  },
  {
    county: 'CARROLL',
    state: 'IL',
  },
  {
    county: 'CASS',
    state: 'IL',
  },
  {
    county: 'CHAMPAIGN',
    state: 'IL',
  },
  {
    county: 'CHRISTIAN',
    state: 'IL',
  },
  {
    county: 'CLARK',
    state: 'IL',
  },
  {
    county: 'CLAY',
    state: 'IL',
  },
  {
    county: 'CLINTON',
    state: 'IL',
  },
  {
    county: 'COLES',
    state: 'IL',
  },
  {
    county: 'COOK',
    state: 'IL',
  },
  {
    county: 'CRAWFORD',
    state: 'IL',
  },
  {
    county: 'CUMBERLAND',
    state: 'IL',
  },
  {
    county: 'DEKALB',
    state: 'IL',
  },
  {
    county: 'DE WITT',
    state: 'IL',
  },
  {
    county: 'DOUGLAS',
    state: 'IL',
  },
  {
    county: 'DUPAGE',
    state: 'IL',
  },
  {
    county: 'EDGAR',
    state: 'IL',
  },
  {
    county: 'EDWARDS',
    state: 'IL',
  },
  {
    county: 'EFFINGHAM',
    state: 'IL',
  },
  {
    county: 'FAYETTE',
    state: 'IL',
  },
  {
    county: 'FORD',
    state: 'IL',
  },
  {
    county: 'FRANKLIN',
    state: 'IL',
  },
  {
    county: 'FULTON',
    state: 'IL',
  },
  {
    county: 'GALLATIN',
    state: 'IL',
  },
  {
    county: 'GREENE',
    state: 'IL',
  },
  {
    county: 'GRUNDY',
    state: 'IL',
  },
  {
    county: 'HAMILTON',
    state: 'IL',
  },
  {
    county: 'HANCOCK',
    state: 'IL',
  },
  {
    county: 'HARDIN',
    state: 'IL',
  },
  {
    county: 'HENDERSON',
    state: 'IL',
  },
  {
    county: 'HENRY',
    state: 'IL',
  },
  {
    county: 'IROQUOIS',
    state: 'IL',
  },
  {
    county: 'JACKSON',
    state: 'IL',
  },
  {
    county: 'JASPER',
    state: 'IL',
  },
  {
    county: 'JEFFERSON',
    state: 'IL',
  },
  {
    county: 'JERSEY',
    state: 'IL',
  },
  {
    county: 'JO DAVIESS',
    state: 'IL',
  },
  {
    county: 'JOHNSON',
    state: 'IL',
  },
  {
    county: 'KANE',
    state: 'IL',
  },
  {
    county: 'KANKAKEE',
    state: 'IL',
  },
  {
    county: 'KENDALL',
    state: 'IL',
  },
  {
    county: 'KNOX',
    state: 'IL',
  },
  {
    county: 'LAKE',
    state: 'IL',
  },
  {
    county: 'LASALLE',
    state: 'IL',
  },
  {
    county: 'LAWRENCE',
    state: 'IL',
  },
  {
    county: 'LEE',
    state: 'IL',
  },
  {
    county: 'LIVINGSTON',
    state: 'IL',
  },
  {
    county: 'LOGAN',
    state: 'IL',
  },
  {
    county: 'MCDONOUGH',
    state: 'IL',
  },
  {
    county: 'MCHENRY',
    state: 'IL',
  },
  {
    county: 'MCLEAN',
    state: 'IL',
  },
  {
    county: 'MACON',
    state: 'IL',
  },
  {
    county: 'MACOUPIN',
    state: 'IL',
  },
  {
    county: 'MADISON',
    state: 'IL',
  },
  {
    county: 'MARION',
    state: 'IL',
  },
  {
    county: 'MARSHALL',
    state: 'IL',
  },
  {
    county: 'MASON',
    state: 'IL',
  },
  {
    county: 'MASSAC',
    state: 'IL',
  },
  {
    county: 'MENARD',
    state: 'IL',
  },
  {
    county: 'MERCER',
    state: 'IL',
  },
  {
    county: 'MONROE',
    state: 'IL',
  },
  {
    county: 'MONTGOMERY',
    state: 'IL',
  },
  {
    county: 'MORGAN',
    state: 'IL',
  },
  {
    county: 'MOULTRIE',
    state: 'IL',
  },
  {
    county: 'OGLE',
    state: 'IL',
  },
  {
    county: 'PEORIA',
    state: 'IL',
  },
  {
    county: 'PERRY',
    state: 'IL',
  },
  {
    county: 'PIATT',
    state: 'IL',
  },
  {
    county: 'PIKE',
    state: 'IL',
  },
  {
    county: 'POPE',
    state: 'IL',
  },
  {
    county: 'PULASKI',
    state: 'IL',
  },
  {
    county: 'PUTNAM',
    state: 'IL',
  },
  {
    county: 'RANDOLPH',
    state: 'IL',
  },
  {
    county: 'RICHLAND',
    state: 'IL',
  },
  {
    county: 'ROCK ISLAND',
    state: 'IL',
  },
  {
    county: 'ST CLAIR',
    state: 'IL',
  },
  {
    county: 'SALINE',
    state: 'IL',
  },
  {
    county: 'SANGAMON',
    state: 'IL',
  },
  {
    county: 'SCHUYLER',
    state: 'IL',
  },
  {
    county: 'SCOTT',
    state: 'IL',
  },
  {
    county: 'SHELBY',
    state: 'IL',
  },
  {
    county: 'STARK',
    state: 'IL',
  },
  {
    county: 'STEPHENSON',
    state: 'IL',
  },
  {
    county: 'TAZEWELL',
    state: 'IL',
  },
  {
    county: 'UNION',
    state: 'IL',
  },
  {
    county: 'VERMILION',
    state: 'IL',
  },
  {
    county: 'WABASH',
    state: 'IL',
  },
  {
    county: 'WARREN',
    state: 'IL',
  },
  {
    county: 'WASHINGTON',
    state: 'IL',
  },
  {
    county: 'WAYNE',
    state: 'IL',
  },
  {
    county: 'WHITE',
    state: 'IL',
  },
  {
    county: 'WHITESIDE',
    state: 'IL',
  },
  {
    county: 'WILL',
    state: 'IL',
  },
  {
    county: 'WILLIAMSON',
    state: 'IL',
  },
  {
    county: 'WINNEBAGO',
    state: 'IL',
  },
  {
    county: 'WOODFORD',
    state: 'IL',
  },
  {
    county: 'ADAMS',
    state: 'IN',
  },
  {
    county: 'ALLEN',
    state: 'IN',
  },
  {
    county: 'BARTHOLOMEW',
    state: 'IN',
  },
  {
    county: 'BENTON',
    state: 'IN',
  },
  {
    county: 'BLACKFORD',
    state: 'IN',
  },
  {
    county: 'BOONE',
    state: 'IN',
  },
  {
    county: 'BROWN',
    state: 'IN',
  },
  {
    county: 'CARROLL',
    state: 'IN',
  },
  {
    county: 'CASS',
    state: 'IN',
  },
  {
    county: 'CLARK',
    state: 'IN',
  },
  {
    county: 'CLAY',
    state: 'IN',
  },
  {
    county: 'CLINTON',
    state: 'IN',
  },
  {
    county: 'CRAWFORD',
    state: 'IN',
  },
  {
    county: 'DAVIESS',
    state: 'IN',
  },
  {
    county: 'DEARBORN',
    state: 'IN',
  },
  {
    county: 'DECATUR',
    state: 'IN',
  },
  {
    county: 'DEKALB',
    state: 'IN',
  },
  {
    county: 'DELAWARE',
    state: 'IN',
  },
  {
    county: 'DUBOIS',
    state: 'IN',
  },
  {
    county: 'ELKHART',
    state: 'IN',
  },
  {
    county: 'FAYETTE',
    state: 'IN',
  },
  {
    county: 'FLOYD',
    state: 'IN',
  },
  {
    county: 'FOUNTAIN',
    state: 'IN',
  },
  {
    county: 'FRANKLIN',
    state: 'IN',
  },
  {
    county: 'FULTON',
    state: 'IN',
  },
  {
    county: 'GIBSON',
    state: 'IN',
  },
  {
    county: 'GRANT',
    state: 'IN',
  },
  {
    county: 'GREENE',
    state: 'IN',
  },
  {
    county: 'HAMILTON',
    state: 'IN',
  },
  {
    county: 'HANCOCK',
    state: 'IN',
  },
  {
    county: 'HARRISON',
    state: 'IN',
  },
  {
    county: 'HENDRICKS',
    state: 'IN',
  },
  {
    county: 'HENRY',
    state: 'IN',
  },
  {
    county: 'HOWARD',
    state: 'IN',
  },
  {
    county: 'HUNTINGTON',
    state: 'IN',
  },
  {
    county: 'JACKSON',
    state: 'IN',
  },
  {
    county: 'JASPER',
    state: 'IN',
  },
  {
    county: 'JAY',
    state: 'IN',
  },
  {
    county: 'JEFFERSON',
    state: 'IN',
  },
  {
    county: 'JENNINGS',
    state: 'IN',
  },
  {
    county: 'JOHNSON',
    state: 'IN',
  },
  {
    county: 'KNOX',
    state: 'IN',
  },
  {
    county: 'KOSCIUSKO',
    state: 'IN',
  },
  {
    county: 'LAGRANGE',
    state: 'IN',
  },
  {
    county: 'LAKE',
    state: 'IN',
  },
  {
    county: 'LAPORTE',
    state: 'IN',
  },
  {
    county: 'LAWRENCE',
    state: 'IN',
  },
  {
    county: 'MADISON',
    state: 'IN',
  },
  {
    county: 'MARION',
    state: 'IN',
  },
  {
    county: 'MARSHALL',
    state: 'IN',
  },
  {
    county: 'MARTIN',
    state: 'IN',
  },
  {
    county: 'MIAMI',
    state: 'IN',
  },
  {
    county: 'MONROE',
    state: 'IN',
  },
  {
    county: 'MONTGOMERY',
    state: 'IN',
  },
  {
    county: 'MORGAN',
    state: 'IN',
  },
  {
    county: 'NEWTON',
    state: 'IN',
  },
  {
    county: 'NOBLE',
    state: 'IN',
  },
  {
    county: 'OHIO',
    state: 'IN',
  },
  {
    county: 'ORANGE',
    state: 'IN',
  },
  {
    county: 'OWEN',
    state: 'IN',
  },
  {
    county: 'PARKE',
    state: 'IN',
  },
  {
    county: 'PERRY',
    state: 'IN',
  },
  {
    county: 'PIKE',
    state: 'IN',
  },
  {
    county: 'PORTER',
    state: 'IN',
  },
  {
    county: 'POSEY',
    state: 'IN',
  },
  {
    county: 'PULASKI',
    state: 'IN',
  },
  {
    county: 'PUTNAM',
    state: 'IN',
  },
  {
    county: 'RANDOLPH',
    state: 'IN',
  },
  {
    county: 'RIPLEY',
    state: 'IN',
  },
  {
    county: 'RUSH',
    state: 'IN',
  },
  {
    county: 'ST JOSEPH',
    state: 'IN',
  },
  {
    county: 'SCOTT',
    state: 'IN',
  },
  {
    county: 'SHELBY',
    state: 'IN',
  },
  {
    county: 'SPENCER',
    state: 'IN',
  },
  {
    county: 'STARKE',
    state: 'IN',
  },
  {
    county: 'STEUBEN',
    state: 'IN',
  },
  {
    county: 'SULLIVAN',
    state: 'IN',
  },
  {
    county: 'SWITZERLAND',
    state: 'IN',
  },
  {
    county: 'TIPPECANOE',
    state: 'IN',
  },
  {
    county: 'TIPTON',
    state: 'IN',
  },
  {
    county: 'UNION',
    state: 'IN',
  },
  {
    county: 'VANDERBURGH',
    state: 'IN',
  },
  {
    county: 'VERMILLION',
    state: 'IN',
  },
  {
    county: 'VIGO',
    state: 'IN',
  },
  {
    county: 'WABASH',
    state: 'IN',
  },
  {
    county: 'WARREN',
    state: 'IN',
  },
  {
    county: 'WARRICK',
    state: 'IN',
  },
  {
    county: 'WASHINGTON',
    state: 'IN',
  },
  {
    county: 'WAYNE',
    state: 'IN',
  },
  {
    county: 'WELLS',
    state: 'IN',
  },
  {
    county: 'WHITE',
    state: 'IN',
  },
  {
    county: 'WHITLEY',
    state: 'IN',
  },
  {
    county: 'ADAIR',
    state: 'IA',
  },
  {
    county: 'ADAMS',
    state: 'IA',
  },
  {
    county: 'ALLAMAKEE',
    state: 'IA',
  },
  {
    county: 'APPANOOSE',
    state: 'IA',
  },
  {
    county: 'AUDUBON',
    state: 'IA',
  },
  {
    county: 'BENTON',
    state: 'IA',
  },
  {
    county: 'BLACK HAWK',
    state: 'IA',
  },
  {
    county: 'BOONE',
    state: 'IA',
  },
  {
    county: 'BREMER',
    state: 'IA',
  },
  {
    county: 'BUCHANAN',
    state: 'IA',
  },
  {
    county: 'BUENA VISTA',
    state: 'IA',
  },
  {
    county: 'BUTLER',
    state: 'IA',
  },
  {
    county: 'CALHOUN',
    state: 'IA',
  },
  {
    county: 'CARROLL',
    state: 'IA',
  },
  {
    county: 'CASS',
    state: 'IA',
  },
  {
    county: 'CEDAR',
    state: 'IA',
  },
  {
    county: 'CERRO GORDO',
    state: 'IA',
  },
  {
    county: 'CHEROKEE',
    state: 'IA',
  },
  {
    county: 'CHICKASAW',
    state: 'IA',
  },
  {
    county: 'CLARKE',
    state: 'IA',
  },
  {
    county: 'CLAY',
    state: 'IA',
  },
  {
    county: 'CLAYTON',
    state: 'IA',
  },
  {
    county: 'CLINTON',
    state: 'IA',
  },
  {
    county: 'CRAWFORD',
    state: 'IA',
  },
  {
    county: 'DALLAS',
    state: 'IA',
  },
  {
    county: 'DAVIS',
    state: 'IA',
  },
  {
    county: 'DECATUR',
    state: 'IA',
  },
  {
    county: 'DELAWARE',
    state: 'IA',
  },
  {
    county: 'DES MOINES',
    state: 'IA',
  },
  {
    county: 'DICKINSON',
    state: 'IA',
  },
  {
    county: 'DUBUQUE',
    state: 'IA',
  },
  {
    county: 'EMMET',
    state: 'IA',
  },
  {
    county: 'FAYETTE',
    state: 'IA',
  },
  {
    county: 'FLOYD',
    state: 'IA',
  },
  {
    county: 'FRANKLIN',
    state: 'IA',
  },
  {
    county: 'FREMONT',
    state: 'IA',
  },
  {
    county: 'GREENE',
    state: 'IA',
  },
  {
    county: 'GRUNDY',
    state: 'IA',
  },
  {
    county: 'GUTHRIE',
    state: 'IA',
  },
  {
    county: 'HAMILTON',
    state: 'IA',
  },
  {
    county: 'HANCOCK',
    state: 'IA',
  },
  {
    county: 'HARDIN',
    state: 'IA',
  },
  {
    county: 'HARRISON',
    state: 'IA',
  },
  {
    county: 'HENRY',
    state: 'IA',
  },
  {
    county: 'HOWARD',
    state: 'IA',
  },
  {
    county: 'HUMBOLDT',
    state: 'IA',
  },
  {
    county: 'IDA',
    state: 'IA',
  },
  {
    county: 'IOWA',
    state: 'IA',
  },
  {
    county: 'JACKSON',
    state: 'IA',
  },
  {
    county: 'JASPER',
    state: 'IA',
  },
  {
    county: 'JEFFERSON',
    state: 'IA',
  },
  {
    county: 'JOHNSON',
    state: 'IA',
  },
  {
    county: 'JONES',
    state: 'IA',
  },
  {
    county: 'KEOKUK',
    state: 'IA',
  },
  {
    county: 'KOSSUTH',
    state: 'IA',
  },
  {
    county: 'LEE',
    state: 'IA',
  },
  {
    county: 'LINN',
    state: 'IA',
  },
  {
    county: 'LOUISA',
    state: 'IA',
  },
  {
    county: 'LUCAS',
    state: 'IA',
  },
  {
    county: 'LYON',
    state: 'IA',
  },
  {
    county: 'MADISON',
    state: 'IA',
  },
  {
    county: 'MAHASKA',
    state: 'IA',
  },
  {
    county: 'MARION',
    state: 'IA',
  },
  {
    county: 'MARSHALL',
    state: 'IA',
  },
  {
    county: 'MILLS',
    state: 'IA',
  },
  {
    county: 'MITCHELL',
    state: 'IA',
  },
  {
    county: 'MONONA',
    state: 'IA',
  },
  {
    county: 'MONROE',
    state: 'IA',
  },
  {
    county: 'MONTGOMERY',
    state: 'IA',
  },
  {
    county: 'MUSCATINE',
    state: 'IA',
  },
  {
    county: 'OBRIEN',
    state: 'IA',
  },
  {
    county: 'OSCEOLA',
    state: 'IA',
  },
  {
    county: 'PAGE',
    state: 'IA',
  },
  {
    county: 'PALO ALTO',
    state: 'IA',
  },
  {
    county: 'PLYMOUTH',
    state: 'IA',
  },
  {
    county: 'POCAHONTAS',
    state: 'IA',
  },
  {
    county: 'POLK',
    state: 'IA',
  },
  {
    county: 'POTTAWATTAMIE',
    state: 'IA',
  },
  {
    county: 'POWESHIEK',
    state: 'IA',
  },
  {
    county: 'RINGGOLD',
    state: 'IA',
  },
  {
    county: 'SAC',
    state: 'IA',
  },
  {
    county: 'SCOTT',
    state: 'IA',
  },
  {
    county: 'SHELBY',
    state: 'IA',
  },
  {
    county: 'SIOUX',
    state: 'IA',
  },
  {
    county: 'STORY',
    state: 'IA',
  },
  {
    county: 'TAMA',
    state: 'IA',
  },
  {
    county: 'TAYLOR',
    state: 'IA',
  },
  {
    county: 'UNION',
    state: 'IA',
  },
  {
    county: 'VAN BUREN',
    state: 'IA',
  },
  {
    county: 'WAPELLO',
    state: 'IA',
  },
  {
    county: 'WARREN',
    state: 'IA',
  },
  {
    county: 'WASHINGTON',
    state: 'IA',
  },
  {
    county: 'WAYNE',
    state: 'IA',
  },
  {
    county: 'WEBSTER',
    state: 'IA',
  },
  {
    county: 'WINNEBAGO',
    state: 'IA',
  },
  {
    county: 'WINNESHIEK',
    state: 'IA',
  },
  {
    county: 'WOODBURY',
    state: 'IA',
  },
  {
    county: 'WORTH',
    state: 'IA',
  },
  {
    county: 'WRIGHT',
    state: 'IA',
  },
  {
    county: 'ALLEN',
    state: 'KS',
  },
  {
    county: 'ANDERSON',
    state: 'KS',
  },
  {
    county: 'ATCHISON',
    state: 'KS',
  },
  {
    county: 'BARBER',
    state: 'KS',
  },
  {
    county: 'BARTON',
    state: 'KS',
  },
  {
    county: 'BOURBON',
    state: 'KS',
  },
  {
    county: 'BROWN',
    state: 'KS',
  },
  {
    county: 'BUTLER',
    state: 'KS',
  },
  {
    county: 'CHASE',
    state: 'KS',
  },
  {
    county: 'CHAUTAUQUA',
    state: 'KS',
  },
  {
    county: 'CHEROKEE',
    state: 'KS',
  },
  {
    county: 'CHEYENNE',
    state: 'KS',
  },
  {
    county: 'CLARK',
    state: 'KS',
  },
  {
    county: 'CLAY',
    state: 'KS',
  },
  {
    county: 'CLOUD',
    state: 'KS',
  },
  {
    county: 'COFFEY',
    state: 'KS',
  },
  {
    county: 'COMANCHE',
    state: 'KS',
  },
  {
    county: 'COWLEY',
    state: 'KS',
  },
  {
    county: 'CRAWFORD',
    state: 'KS',
  },
  {
    county: 'DECATUR',
    state: 'KS',
  },
  {
    county: 'DICKINSON',
    state: 'KS',
  },
  {
    county: 'DONIPHAN',
    state: 'KS',
  },
  {
    county: 'DOUGLAS',
    state: 'KS',
  },
  {
    county: 'EDWARDS',
    state: 'KS',
  },
  {
    county: 'ELK',
    state: 'KS',
  },
  {
    county: 'ELLIS',
    state: 'KS',
  },
  {
    county: 'ELLSWORTH',
    state: 'KS',
  },
  {
    county: 'FINNEY',
    state: 'KS',
  },
  {
    county: 'FORD',
    state: 'KS',
  },
  {
    county: 'FRANKLIN',
    state: 'KS',
  },
  {
    county: 'GEARY',
    state: 'KS',
  },
  {
    county: 'GOVE',
    state: 'KS',
  },
  {
    county: 'GRAHAM',
    state: 'KS',
  },
  {
    county: 'GRANT',
    state: 'KS',
  },
  {
    county: 'GRAY',
    state: 'KS',
  },
  {
    county: 'GREELEY',
    state: 'KS',
  },
  {
    county: 'GREENWOOD',
    state: 'KS',
  },
  {
    county: 'HAMILTON',
    state: 'KS',
  },
  {
    county: 'HARPER',
    state: 'KS',
  },
  {
    county: 'HARVEY',
    state: 'KS',
  },
  {
    county: 'HASKELL',
    state: 'KS',
  },
  {
    county: 'HODGEMAN',
    state: 'KS',
  },
  {
    county: 'JACKSON',
    state: 'KS',
  },
  {
    county: 'JEFFERSON',
    state: 'KS',
  },
  {
    county: 'JEWELL',
    state: 'KS',
  },
  {
    county: 'JOHNSON',
    state: 'KS',
  },
  {
    county: 'KEARNY',
    state: 'KS',
  },
  {
    county: 'KINGMAN',
    state: 'KS',
  },
  {
    county: 'KIOWA',
    state: 'KS',
  },
  {
    county: 'LABETTE',
    state: 'KS',
  },
  {
    county: 'LANE',
    state: 'KS',
  },
  {
    county: 'LEAVENWORTH',
    state: 'KS',
  },
  {
    county: 'LINCOLN',
    state: 'KS',
  },
  {
    county: 'LINN',
    state: 'KS',
  },
  {
    county: 'LOGAN',
    state: 'KS',
  },
  {
    county: 'LYON',
    state: 'KS',
  },
  {
    county: 'MCPHERSON',
    state: 'KS',
  },
  {
    county: 'MARION',
    state: 'KS',
  },
  {
    county: 'MARSHALL',
    state: 'KS',
  },
  {
    county: 'MEADE',
    state: 'KS',
  },
  {
    county: 'MIAMI',
    state: 'KS',
  },
  {
    county: 'MITCHELL',
    state: 'KS',
  },
  {
    county: 'MONTGOMERY',
    state: 'KS',
  },
  {
    county: 'MORRIS',
    state: 'KS',
  },
  {
    county: 'MORTON',
    state: 'KS',
  },
  {
    county: 'NEMAHA',
    state: 'KS',
  },
  {
    county: 'NEOSHO',
    state: 'KS',
  },
  {
    county: 'NESS',
    state: 'KS',
  },
  {
    county: 'NORTON',
    state: 'KS',
  },
  {
    county: 'OSAGE',
    state: 'KS',
  },
  {
    county: 'OSBORNE',
    state: 'KS',
  },
  {
    county: 'OTTAWA',
    state: 'KS',
  },
  {
    county: 'PAWNEE',
    state: 'KS',
  },
  {
    county: 'PHILLIPS',
    state: 'KS',
  },
  {
    county: 'POTTAWATOMIE',
    state: 'KS',
  },
  {
    county: 'PRATT',
    state: 'KS',
  },
  {
    county: 'RAWLINS',
    state: 'KS',
  },
  {
    county: 'RENO',
    state: 'KS',
  },
  {
    county: 'REPUBLIC',
    state: 'KS',
  },
  {
    county: 'RICE',
    state: 'KS',
  },
  {
    county: 'RILEY',
    state: 'KS',
  },
  {
    county: 'ROOKS',
    state: 'KS',
  },
  {
    county: 'RUSH',
    state: 'KS',
  },
  {
    county: 'RUSSELL',
    state: 'KS',
  },
  {
    county: 'SALINE',
    state: 'KS',
  },
  {
    county: 'SCOTT',
    state: 'KS',
  },
  {
    county: 'SEDGWICK',
    state: 'KS',
  },
  {
    county: 'SEWARD',
    state: 'KS',
  },
  {
    county: 'SHAWNEE',
    state: 'KS',
  },
  {
    county: 'SHERIDAN',
    state: 'KS',
  },
  {
    county: 'SHERMAN',
    state: 'KS',
  },
  {
    county: 'SMITH',
    state: 'KS',
  },
  {
    county: 'STAFFORD',
    state: 'KS',
  },
  {
    county: 'STANTON',
    state: 'KS',
  },
  {
    county: 'STEVENS',
    state: 'KS',
  },
  {
    county: 'SUMNER',
    state: 'KS',
  },
  {
    county: 'THOMAS',
    state: 'KS',
  },
  {
    county: 'TREGO',
    state: 'KS',
  },
  {
    county: 'WABAUNSEE',
    state: 'KS',
  },
  {
    county: 'WALLACE',
    state: 'KS',
  },
  {
    county: 'WASHINGTON',
    state: 'KS',
  },
  {
    county: 'WICHITA',
    state: 'KS',
  },
  {
    county: 'WILSON',
    state: 'KS',
  },
  {
    county: 'WOODSON',
    state: 'KS',
  },
  {
    county: 'WYANDOTTE',
    state: 'KS',
  },
  {
    county: 'ADAIR',
    state: 'KY',
  },
  {
    county: 'ALLEN',
    state: 'KY',
  },
  {
    county: 'ANDERSON',
    state: 'KY',
  },
  {
    county: 'BALLARD',
    state: 'KY',
  },
  {
    county: 'BARREN',
    state: 'KY',
  },
  {
    county: 'BATH',
    state: 'KY',
  },
  {
    county: 'BELL',
    state: 'KY',
  },
  {
    county: 'BOONE',
    state: 'KY',
  },
  {
    county: 'BOURBON',
    state: 'KY',
  },
  {
    county: 'BOYD',
    state: 'KY',
  },
  {
    county: 'BOYLE',
    state: 'KY',
  },
  {
    county: 'BRACKEN',
    state: 'KY',
  },
  {
    county: 'BREATHITT',
    state: 'KY',
  },
  {
    county: 'BRECKINRIDGE',
    state: 'KY',
  },
  {
    county: 'BULLITT',
    state: 'KY',
  },
  {
    county: 'BUTLER',
    state: 'KY',
  },
  {
    county: 'CALDWELL',
    state: 'KY',
  },
  {
    county: 'CALLOWAY',
    state: 'KY',
  },
  {
    county: 'CAMPBELL',
    state: 'KY',
  },
  {
    county: 'CARLISLE',
    state: 'KY',
  },
  {
    county: 'CARROLL',
    state: 'KY',
  },
  {
    county: 'CARTER',
    state: 'KY',
  },
  {
    county: 'CASEY',
    state: 'KY',
  },
  {
    county: 'CHRISTIAN',
    state: 'KY',
  },
  {
    county: 'CLARK',
    state: 'KY',
  },
  {
    county: 'CLAY',
    state: 'KY',
  },
  {
    county: 'CLINTON',
    state: 'KY',
  },
  {
    county: 'CRITTENDEN',
    state: 'KY',
  },
  {
    county: 'CUMBERLAND',
    state: 'KY',
  },
  {
    county: 'DAVIESS',
    state: 'KY',
  },
  {
    county: 'EDMONSON',
    state: 'KY',
  },
  {
    county: 'ELLIOTT',
    state: 'KY',
  },
  {
    county: 'ESTILL',
    state: 'KY',
  },
  {
    county: 'FAYETTE',
    state: 'KY',
  },
  {
    county: 'FLEMING',
    state: 'KY',
  },
  {
    county: 'FLOYD',
    state: 'KY',
  },
  {
    county: 'FRANKLIN',
    state: 'KY',
  },
  {
    county: 'FULTON',
    state: 'KY',
  },
  {
    county: 'GALLATIN',
    state: 'KY',
  },
  {
    county: 'GARRARD',
    state: 'KY',
  },
  {
    county: 'GRANT',
    state: 'KY',
  },
  {
    county: 'GRAVES',
    state: 'KY',
  },
  {
    county: 'GRAYSON',
    state: 'KY',
  },
  {
    county: 'GREEN',
    state: 'KY',
  },
  {
    county: 'GREENUP',
    state: 'KY',
  },
  {
    county: 'HANCOCK',
    state: 'KY',
  },
  {
    county: 'HARDIN',
    state: 'KY',
  },
  {
    county: 'HARLAN',
    state: 'KY',
  },
  {
    county: 'HARRISON',
    state: 'KY',
  },
  {
    county: 'HART',
    state: 'KY',
  },
  {
    county: 'HENDERSON',
    state: 'KY',
  },
  {
    county: 'HENRY',
    state: 'KY',
  },
  {
    county: 'HICKMAN',
    state: 'KY',
  },
  {
    county: 'HOPKINS',
    state: 'KY',
  },
  {
    county: 'JACKSON',
    state: 'KY',
  },
  {
    county: 'JEFFERSON',
    state: 'KY',
  },
  {
    county: 'JESSAMINE',
    state: 'KY',
  },
  {
    county: 'JOHNSON',
    state: 'KY',
  },
  {
    county: 'KENTON',
    state: 'KY',
  },
  {
    county: 'KNOTT',
    state: 'KY',
  },
  {
    county: 'KNOX',
    state: 'KY',
  },
  {
    county: 'LARUE',
    state: 'KY',
  },
  {
    county: 'LAUREL',
    state: 'KY',
  },
  {
    county: 'LAWRENCE',
    state: 'KY',
  },
  {
    county: 'LEE',
    state: 'KY',
  },
  {
    county: 'LESLIE',
    state: 'KY',
  },
  {
    county: 'LETCHER',
    state: 'KY',
  },
  {
    county: 'LEWIS',
    state: 'KY',
  },
  {
    county: 'LINCOLN',
    state: 'KY',
  },
  {
    county: 'LIVINGSTON',
    state: 'KY',
  },
  {
    county: 'LOGAN',
    state: 'KY',
  },
  {
    county: 'LYON',
    state: 'KY',
  },
  {
    county: 'MCCRACKEN',
    state: 'KY',
  },
  {
    county: 'MCCREARY',
    state: 'KY',
  },
  {
    county: 'MCLEAN',
    state: 'KY',
  },
  {
    county: 'MADISON',
    state: 'KY',
  },
  {
    county: 'MAGOFFIN',
    state: 'KY',
  },
  {
    county: 'MARION',
    state: 'KY',
  },
  {
    county: 'MARSHALL',
    state: 'KY',
  },
  {
    county: 'MARTIN',
    state: 'KY',
  },
  {
    county: 'MASON',
    state: 'KY',
  },
  {
    county: 'MEADE',
    state: 'KY',
  },
  {
    county: 'MENIFEE',
    state: 'KY',
  },
  {
    county: 'MERCER',
    state: 'KY',
  },
  {
    county: 'METCALFE',
    state: 'KY',
  },
  {
    county: 'MONROE',
    state: 'KY',
  },
  {
    county: 'MONTGOMERY',
    state: 'KY',
  },
  {
    county: 'MORGAN',
    state: 'KY',
  },
  {
    county: 'MUHLENBERG',
    state: 'KY',
  },
  {
    county: 'NELSON',
    state: 'KY',
  },
  {
    county: 'NICHOLAS',
    state: 'KY',
  },
  {
    county: 'OHIO',
    state: 'KY',
  },
  {
    county: 'OLDHAM',
    state: 'KY',
  },
  {
    county: 'OWEN',
    state: 'KY',
  },
  {
    county: 'OWSLEY',
    state: 'KY',
  },
  {
    county: 'PENDLETON',
    state: 'KY',
  },
  {
    county: 'PERRY',
    state: 'KY',
  },
  {
    county: 'PIKE',
    state: 'KY',
  },
  {
    county: 'POWELL',
    state: 'KY',
  },
  {
    county: 'PULASKI',
    state: 'KY',
  },
  {
    county: 'ROBERTSON',
    state: 'KY',
  },
  {
    county: 'ROCKCASTLE',
    state: 'KY',
  },
  {
    county: 'ROWAN',
    state: 'KY',
  },
  {
    county: 'RUSSELL',
    state: 'KY',
  },
  {
    county: 'SCOTT',
    state: 'KY',
  },
  {
    county: 'SHELBY',
    state: 'KY',
  },
  {
    county: 'SIMPSON',
    state: 'KY',
  },
  {
    county: 'SPENCER',
    state: 'KY',
  },
  {
    county: 'TAYLOR',
    state: 'KY',
  },
  {
    county: 'TODD',
    state: 'KY',
  },
  {
    county: 'TRIGG',
    state: 'KY',
  },
  {
    county: 'TRIMBLE',
    state: 'KY',
  },
  {
    county: 'UNION',
    state: 'KY',
  },
  {
    county: 'WARREN',
    state: 'KY',
  },
  {
    county: 'WASHINGTON',
    state: 'KY',
  },
  {
    county: 'WAYNE',
    state: 'KY',
  },
  {
    county: 'WEBSTER',
    state: 'KY',
  },
  {
    county: 'WHITLEY',
    state: 'KY',
  },
  {
    county: 'WOLFE',
    state: 'KY',
  },
  {
    county: 'WOODFORD',
    state: 'KY',
  },
  {
    county: 'ACADIA ',
    state: 'LA',
  },
  {
    county: 'ALLEN ',
    state: 'LA',
  },
  {
    county: 'ASCENSION ',
    state: 'LA',
  },
  {
    county: 'ASSUMPTION ',
    state: 'LA',
  },
  {
    county: 'AVOYELLES ',
    state: 'LA',
  },
  {
    county: 'BEAUREGARD ',
    state: 'LA',
  },
  {
    county: 'BIENVILLE ',
    state: 'LA',
  },
  {
    county: 'BOSSIER ',
    state: 'LA',
  },
  {
    county: 'CADDO ',
    state: 'LA',
  },
  {
    county: 'CALCASIEU ',
    state: 'LA',
  },
  {
    county: 'CALDWELL ',
    state: 'LA',
  },
  {
    county: 'CAMERON ',
    state: 'LA',
  },
  {
    county: 'CATAHOULA ',
    state: 'LA',
  },
  {
    county: 'CLAIBORNE ',
    state: 'LA',
  },
  {
    county: 'CONCORDIA ',
    state: 'LA',
  },
  {
    county: 'DE SOTO ',
    state: 'LA',
  },
  {
    county: 'EAST BATON ROUGE ',
    state: 'LA',
  },
  {
    county: 'EAST CARROLL ',
    state: 'LA',
  },
  {
    county: 'EAST FELICIANA ',
    state: 'LA',
  },
  {
    county: 'EVANGELINE ',
    state: 'LA',
  },
  {
    county: 'FRANKLIN ',
    state: 'LA',
  },
  {
    county: 'GRANT ',
    state: 'LA',
  },
  {
    county: 'IBERIA ',
    state: 'LA',
  },
  {
    county: 'IBERVILLE ',
    state: 'LA',
  },
  {
    county: 'JACKSON ',
    state: 'LA',
  },
  {
    county: 'JEFFERSON ',
    state: 'LA',
  },
  {
    county: 'JEFFERSON DAVIS ',
    state: 'LA',
  },
  {
    county: 'LAFAYETTE ',
    state: 'LA',
  },
  {
    county: 'LAFOURCHE ',
    state: 'LA',
  },
  {
    county: 'LASALLE ',
    state: 'LA',
  },
  {
    county: 'LINCOLN ',
    state: 'LA',
  },
  {
    county: 'LIVINGSTON ',
    state: 'LA',
  },
  {
    county: 'MADISON ',
    state: 'LA',
  },
  {
    county: 'MOREHOUSE ',
    state: 'LA',
  },
  {
    county: 'NATCHITOCHES ',
    state: 'LA',
  },
  {
    county: 'ORLEANS ',
    state: 'LA',
  },
  {
    county: 'OUACHITA ',
    state: 'LA',
  },
  {
    county: 'PLAQUEMINES ',
    state: 'LA',
  },
  {
    county: 'POINTE COUPEE ',
    state: 'LA',
  },
  {
    county: 'RAPIDES ',
    state: 'LA',
  },
  {
    county: 'RED RIVER ',
    state: 'LA',
  },
  {
    county: 'RICHLAND ',
    state: 'LA',
  },
  {
    county: 'SABINE ',
    state: 'LA',
  },
  {
    county: 'ST BERNARD ',
    state: 'LA',
  },
  {
    county: 'ST CHARLES ',
    state: 'LA',
  },
  {
    county: 'ST HELENA ',
    state: 'LA',
  },
  {
    county: 'ST JAMES ',
    state: 'LA',
  },
  {
    county: 'ST JOHN THE BAPTIST ',
    state: 'LA',
  },
  {
    county: 'ST LANDRY ',
    state: 'LA',
  },
  {
    county: 'ST MARTIN ',
    state: 'LA',
  },
  {
    county: 'ST MARY ',
    state: 'LA',
  },
  {
    county: 'ST TAMMANY ',
    state: 'LA',
  },
  {
    county: 'TANGIPAHOA ',
    state: 'LA',
  },
  {
    county: 'TENSAS ',
    state: 'LA',
  },
  {
    county: 'TERREBONNE ',
    state: 'LA',
  },
  {
    county: 'UNION ',
    state: 'LA',
  },
  {
    county: 'VERMILION ',
    state: 'LA',
  },
  {
    county: 'VERNON ',
    state: 'LA',
  },
  {
    county: 'WASHINGTON ',
    state: 'LA',
  },
  {
    county: 'WEBSTER ',
    state: 'LA',
  },
  {
    county: 'WEST BATON ROUGE ',
    state: 'LA',
  },
  {
    county: 'WEST CARROLL ',
    state: 'LA',
  },
  {
    county: 'WEST FELICIANA ',
    state: 'LA',
  },
  {
    county: 'WINN ',
    state: 'LA',
  },
  {
    county: 'ANDROSCOGGIN',
    state: 'ME',
  },
  {
    county: 'AROOSTOOK',
    state: 'ME',
  },
  {
    county: 'CUMBERLAND',
    state: 'ME',
  },
  {
    county: 'FRANKLIN',
    state: 'ME',
  },
  {
    county: 'HANCOCK',
    state: 'ME',
  },
  {
    county: 'KENNEBEC',
    state: 'ME',
  },
  {
    county: 'KNOX',
    state: 'ME',
  },
  {
    county: 'LINCOLN',
    state: 'ME',
  },
  {
    county: 'OXFORD',
    state: 'ME',
  },
  {
    county: 'PENOBSCOT',
    state: 'ME',
  },
  {
    county: 'PISCATAQUIS',
    state: 'ME',
  },
  {
    county: 'SAGADAHOC',
    state: 'ME',
  },
  {
    county: 'SOMERSET',
    state: 'ME',
  },
  {
    county: 'WALDO',
    state: 'ME',
  },
  {
    county: 'WASHINGTON',
    state: 'ME',
  },
  {
    county: 'YORK',
    state: 'ME',
  },
  {
    county: 'ALLEGANY',
    state: 'MD',
  },
  {
    county: 'ANNE ARUNDEL',
    state: 'MD',
  },
  {
    county: 'BALTIMORE',
    state: 'MD',
  },
  {
    county: 'CALVERT',
    state: 'MD',
  },
  {
    county: 'CAROLINE',
    state: 'MD',
  },
  {
    county: 'CARROLL',
    state: 'MD',
  },
  {
    county: 'CECIL',
    state: 'MD',
  },
  {
    county: 'CHARLES',
    state: 'MD',
  },
  {
    county: 'DORCHESTER',
    state: 'MD',
  },
  {
    county: 'FREDERICK',
    state: 'MD',
  },
  {
    county: 'GARRETT',
    state: 'MD',
  },
  {
    county: 'HARFORD',
    state: 'MD',
  },
  {
    county: 'HOWARD',
    state: 'MD',
  },
  {
    county: 'KENT',
    state: 'MD',
  },
  {
    county: 'MONTGOMERY',
    state: 'MD',
  },
  {
    county: 'PRINCE GEORGES',
    state: 'MD',
  },
  {
    county: 'QUEEN ANNES',
    state: 'MD',
  },
  {
    county: 'ST MARYS',
    state: 'MD',
  },
  {
    county: 'SOMERSET',
    state: 'MD',
  },
  {
    county: 'TALBOT',
    state: 'MD',
  },
  {
    county: 'WASHINGTON',
    state: 'MD',
  },
  {
    county: 'WICOMICO',
    state: 'MD',
  },
  {
    county: 'WORCESTER',
    state: 'MD',
  },
  {
    county: 'BALTIMORE',
    state: 'MD',
  },
  {
    county: 'BARNSTABLE',
    state: 'MA',
  },
  {
    county: 'BERKSHIRE',
    state: 'MA',
  },
  {
    county: 'BRISTOL',
    state: 'MA',
  },
  {
    county: 'DUKES',
    state: 'MA',
  },
  {
    county: 'ESSEX',
    state: 'MA',
  },
  {
    county: 'FRANKLIN',
    state: 'MA',
  },
  {
    county: 'HAMPDEN',
    state: 'MA',
  },
  {
    county: 'HAMPSHIRE',
    state: 'MA',
  },
  {
    county: 'MIDDLESEX',
    state: 'MA',
  },
  {
    county: 'NANTUCKET',
    state: 'MA',
  },
  {
    county: 'NORFOLK',
    state: 'MA',
  },
  {
    county: 'PLYMOUTH',
    state: 'MA',
  },
  {
    county: 'SUFFOLK',
    state: 'MA',
  },
  {
    county: 'WORCESTER',
    state: 'MA',
  },
  {
    county: 'ALCONA',
    state: 'MI',
  },
  {
    county: 'ALGER',
    state: 'MI',
  },
  {
    county: 'ALLEGAN',
    state: 'MI',
  },
  {
    county: 'ALPENA',
    state: 'MI',
  },
  {
    county: 'ANTRIM',
    state: 'MI',
  },
  {
    county: 'ARENAC',
    state: 'MI',
  },
  {
    county: 'BARAGA',
    state: 'MI',
  },
  {
    county: 'BARRY',
    state: 'MI',
  },
  {
    county: 'BAY',
    state: 'MI',
  },
  {
    county: 'BENZIE',
    state: 'MI',
  },
  {
    county: 'BERRIEN',
    state: 'MI',
  },
  {
    county: 'BRANCH',
    state: 'MI',
  },
  {
    county: 'CALHOUN',
    state: 'MI',
  },
  {
    county: 'CASS',
    state: 'MI',
  },
  {
    county: 'CHARLEVOIX',
    state: 'MI',
  },
  {
    county: 'CHEBOYGAN',
    state: 'MI',
  },
  {
    county: 'CHIPPEWA',
    state: 'MI',
  },
  {
    county: 'CLARE',
    state: 'MI',
  },
  {
    county: 'CLINTON',
    state: 'MI',
  },
  {
    county: 'CRAWFORD',
    state: 'MI',
  },
  {
    county: 'DELTA',
    state: 'MI',
  },
  {
    county: 'DICKINSON',
    state: 'MI',
  },
  {
    county: 'EATON',
    state: 'MI',
  },
  {
    county: 'EMMET',
    state: 'MI',
  },
  {
    county: 'GENESEE',
    state: 'MI',
  },
  {
    county: 'GLADWIN',
    state: 'MI',
  },
  {
    county: 'GOGEBIC',
    state: 'MI',
  },
  {
    county: 'GRAND TRAVERSE',
    state: 'MI',
  },
  {
    county: 'GRATIOT',
    state: 'MI',
  },
  {
    county: 'HILLSDALE',
    state: 'MI',
  },
  {
    county: 'HOUGHTON',
    state: 'MI',
  },
  {
    county: 'HURON',
    state: 'MI',
  },
  {
    county: 'INGHAM',
    state: 'MI',
  },
  {
    county: 'IONIA',
    state: 'MI',
  },
  {
    county: 'IOSCO',
    state: 'MI',
  },
  {
    county: 'IRON',
    state: 'MI',
  },
  {
    county: 'ISABELLA',
    state: 'MI',
  },
  {
    county: 'JACKSON',
    state: 'MI',
  },
  {
    county: 'KALAMAZOO',
    state: 'MI',
  },
  {
    county: 'KALKASKA',
    state: 'MI',
  },
  {
    county: 'KENT',
    state: 'MI',
  },
  {
    county: 'KEWEENAW',
    state: 'MI',
  },
  {
    county: 'LAKE',
    state: 'MI',
  },
  {
    county: 'LAPEER',
    state: 'MI',
  },
  {
    county: 'LEELANAU',
    state: 'MI',
  },
  {
    county: 'LENAWEE',
    state: 'MI',
  },
  {
    county: 'LIVINGSTON',
    state: 'MI',
  },
  {
    county: 'LUCE',
    state: 'MI',
  },
  {
    county: 'MACKINAC',
    state: 'MI',
  },
  {
    county: 'MACOMB',
    state: 'MI',
  },
  {
    county: 'MANISTEE',
    state: 'MI',
  },
  {
    county: 'MARQUETTE',
    state: 'MI',
  },
  {
    county: 'MASON',
    state: 'MI',
  },
  {
    county: 'MECOSTA',
    state: 'MI',
  },
  {
    county: 'MENOMINEE',
    state: 'MI',
  },
  {
    county: 'MIDLAND',
    state: 'MI',
  },
  {
    county: 'MISSAUKEE',
    state: 'MI',
  },
  {
    county: 'MONROE',
    state: 'MI',
  },
  {
    county: 'MONTCALM',
    state: 'MI',
  },
  {
    county: 'MONTMORENCY',
    state: 'MI',
  },
  {
    county: 'MUSKEGON',
    state: 'MI',
  },
  {
    county: 'NEWAYGO',
    state: 'MI',
  },
  {
    county: 'OAKLAND',
    state: 'MI',
  },
  {
    county: 'OCEANA',
    state: 'MI',
  },
  {
    county: 'OGEMAW',
    state: 'MI',
  },
  {
    county: 'ONTONAGON',
    state: 'MI',
  },
  {
    county: 'OSCEOLA',
    state: 'MI',
  },
  {
    county: 'OSCODA',
    state: 'MI',
  },
  {
    county: 'OTSEGO',
    state: 'MI',
  },
  {
    county: 'OTTAWA',
    state: 'MI',
  },
  {
    county: 'PRESQUE ISLE',
    state: 'MI',
  },
  {
    county: 'ROSCOMMON',
    state: 'MI',
  },
  {
    county: 'SAGINAW',
    state: 'MI',
  },
  {
    county: 'ST CLAIR',
    state: 'MI',
  },
  {
    county: 'ST JOSEPH',
    state: 'MI',
  },
  {
    county: 'SANILAC',
    state: 'MI',
  },
  {
    county: 'SCHOOLCRAFT',
    state: 'MI',
  },
  {
    county: 'SHIAWASSEE',
    state: 'MI',
  },
  {
    county: 'TUSCOLA',
    state: 'MI',
  },
  {
    county: 'VAN BUREN',
    state: 'MI',
  },
  {
    county: 'WASHTENAW',
    state: 'MI',
  },
  {
    county: 'WAYNE',
    state: 'MI',
  },
  {
    county: 'WEXFORD',
    state: 'MI',
  },
  {
    county: 'AITKIN',
    state: 'MN',
  },
  {
    county: 'ANOKA',
    state: 'MN',
  },
  {
    county: 'BECKER',
    state: 'MN',
  },
  {
    county: 'BELTRAMI',
    state: 'MN',
  },
  {
    county: 'BENTON',
    state: 'MN',
  },
  {
    county: 'BIG STONE',
    state: 'MN',
  },
  {
    county: 'BLUE EARTH',
    state: 'MN',
  },
  {
    county: 'BROWN',
    state: 'MN',
  },
  {
    county: 'CARLTON',
    state: 'MN',
  },
  {
    county: 'CARVER',
    state: 'MN',
  },
  {
    county: 'CASS',
    state: 'MN',
  },
  {
    county: 'CHIPPEWA',
    state: 'MN',
  },
  {
    county: 'CHISAGO',
    state: 'MN',
  },
  {
    county: 'CLAY',
    state: 'MN',
  },
  {
    county: 'CLEARWATER',
    state: 'MN',
  },
  {
    county: 'COOK',
    state: 'MN',
  },
  {
    county: 'COTTONWOOD',
    state: 'MN',
  },
  {
    county: 'CROW WING',
    state: 'MN',
  },
  {
    county: 'DAKOTA',
    state: 'MN',
  },
  {
    county: 'DODGE',
    state: 'MN',
  },
  {
    county: 'DOUGLAS',
    state: 'MN',
  },
  {
    county: 'FARIBAULT',
    state: 'MN',
  },
  {
    county: 'FILLMORE',
    state: 'MN',
  },
  {
    county: 'FREEBORN',
    state: 'MN',
  },
  {
    county: 'GOODHUE',
    state: 'MN',
  },
  {
    county: 'GRANT',
    state: 'MN',
  },
  {
    county: 'HENNEPIN',
    state: 'MN',
  },
  {
    county: 'HOUSTON',
    state: 'MN',
  },
  {
    county: 'HUBBARD',
    state: 'MN',
  },
  {
    county: 'ISANTI',
    state: 'MN',
  },
  {
    county: 'ITASCA',
    state: 'MN',
  },
  {
    county: 'JACKSON',
    state: 'MN',
  },
  {
    county: 'KANABEC',
    state: 'MN',
  },
  {
    county: 'KANDIYOHI',
    state: 'MN',
  },
  {
    county: 'KITTSON',
    state: 'MN',
  },
  {
    county: 'KOOCHICHING',
    state: 'MN',
  },
  {
    county: 'LAC QUI PARLE',
    state: 'MN',
  },
  {
    county: 'LAKE',
    state: 'MN',
  },
  {
    county: 'LAKE OF THE WOODS',
    state: 'MN',
  },
  {
    county: 'LE SUEUR',
    state: 'MN',
  },
  {
    county: 'LINCOLN',
    state: 'MN',
  },
  {
    county: 'LYON',
    state: 'MN',
  },
  {
    county: 'MCLEOD',
    state: 'MN',
  },
  {
    county: 'MAHNOMEN',
    state: 'MN',
  },
  {
    county: 'MARSHALL',
    state: 'MN',
  },
  {
    county: 'MARTIN',
    state: 'MN',
  },
  {
    county: 'MEEKER',
    state: 'MN',
  },
  {
    county: 'MILLE LACS',
    state: 'MN',
  },
  {
    county: 'MORRISON',
    state: 'MN',
  },
  {
    county: 'MOWER',
    state: 'MN',
  },
  {
    county: 'MURRAY',
    state: 'MN',
  },
  {
    county: 'NICOLLET',
    state: 'MN',
  },
  {
    county: 'NOBLES',
    state: 'MN',
  },
  {
    county: 'NORMAN',
    state: 'MN',
  },
  {
    county: 'OLMSTED',
    state: 'MN',
  },
  {
    county: 'OTTER TAIL',
    state: 'MN',
  },
  {
    county: 'PENNINGTON',
    state: 'MN',
  },
  {
    county: 'PINE',
    state: 'MN',
  },
  {
    county: 'PIPESTONE',
    state: 'MN',
  },
  {
    county: 'POLK',
    state: 'MN',
  },
  {
    county: 'POPE',
    state: 'MN',
  },
  {
    county: 'RAMSEY',
    state: 'MN',
  },
  {
    county: 'RED LAKE',
    state: 'MN',
  },
  {
    county: 'REDWOOD',
    state: 'MN',
  },
  {
    county: 'RENVILLE',
    state: 'MN',
  },
  {
    county: 'RICE',
    state: 'MN',
  },
  {
    county: 'ROCK',
    state: 'MN',
  },
  {
    county: 'ROSEAU',
    state: 'MN',
  },
  {
    county: 'ST LOUIS',
    state: 'MN',
  },
  {
    county: 'SCOTT',
    state: 'MN',
  },
  {
    county: 'SHERBURNE',
    state: 'MN',
  },
  {
    county: 'SIBLEY',
    state: 'MN',
  },
  {
    county: 'STEARNS',
    state: 'MN',
  },
  {
    county: 'STEELE',
    state: 'MN',
  },
  {
    county: 'STEVENS',
    state: 'MN',
  },
  {
    county: 'SWIFT',
    state: 'MN',
  },
  {
    county: 'TODD',
    state: 'MN',
  },
  {
    county: 'TRAVERSE',
    state: 'MN',
  },
  {
    county: 'WABASHA',
    state: 'MN',
  },
  {
    county: 'WADENA',
    state: 'MN',
  },
  {
    county: 'WASECA',
    state: 'MN',
  },
  {
    county: 'WASHINGTON',
    state: 'MN',
  },
  {
    county: 'WATONWAN',
    state: 'MN',
  },
  {
    county: 'WILKIN',
    state: 'MN',
  },
  {
    county: 'WINONA',
    state: 'MN',
  },
  {
    county: 'WRIGHT',
    state: 'MN',
  },
  {
    county: 'YELLOW MEDICINE',
    state: 'MN',
  },
  {
    county: 'ADAMS',
    state: 'MS',
  },
  {
    county: 'ALCORN',
    state: 'MS',
  },
  {
    county: 'AMITE',
    state: 'MS',
  },
  {
    county: 'ATTALA',
    state: 'MS',
  },
  {
    county: 'BENTON',
    state: 'MS',
  },
  {
    county: 'BOLIVAR',
    state: 'MS',
  },
  {
    county: 'CALHOUN',
    state: 'MS',
  },
  {
    county: 'CARROLL',
    state: 'MS',
  },
  {
    county: 'CHICKASAW',
    state: 'MS',
  },
  {
    county: 'CHOCTAW',
    state: 'MS',
  },
  {
    county: 'CLAIBORNE',
    state: 'MS',
  },
  {
    county: 'CLARKE',
    state: 'MS',
  },
  {
    county: 'CLAY',
    state: 'MS',
  },
  {
    county: 'COAHOMA',
    state: 'MS',
  },
  {
    county: 'COPIAH',
    state: 'MS',
  },
  {
    county: 'COVINGTON',
    state: 'MS',
  },
  {
    county: 'DESOTO',
    state: 'MS',
  },
  {
    county: 'FORREST',
    state: 'MS',
  },
  {
    county: 'FRANKLIN',
    state: 'MS',
  },
  {
    county: 'GEORGE',
    state: 'MS',
  },
  {
    county: 'GREENE',
    state: 'MS',
  },
  {
    county: 'GRENADA',
    state: 'MS',
  },
  {
    county: 'HANCOCK',
    state: 'MS',
  },
  {
    county: 'HARRISON',
    state: 'MS',
  },
  {
    county: 'HINDS',
    state: 'MS',
  },
  {
    county: 'HOLMES',
    state: 'MS',
  },
  {
    county: 'HUMPHREYS',
    state: 'MS',
  },
  {
    county: 'ISSAQUENA',
    state: 'MS',
  },
  {
    county: 'ITAWAMBA',
    state: 'MS',
  },
  {
    county: 'JACKSON',
    state: 'MS',
  },
  {
    county: 'JASPER',
    state: 'MS',
  },
  {
    county: 'JEFFERSON',
    state: 'MS',
  },
  {
    county: 'JEFFERSON DAVIS',
    state: 'MS',
  },
  {
    county: 'JONES',
    state: 'MS',
  },
  {
    county: 'KEMPER',
    state: 'MS',
  },
  {
    county: 'LAFAYETTE',
    state: 'MS',
  },
  {
    county: 'LAMAR',
    state: 'MS',
  },
  {
    county: 'LAUDERDALE',
    state: 'MS',
  },
  {
    county: 'LAWRENCE',
    state: 'MS',
  },
  {
    county: 'LEAKE',
    state: 'MS',
  },
  {
    county: 'LEE',
    state: 'MS',
  },
  {
    county: 'LEFLORE',
    state: 'MS',
  },
  {
    county: 'LINCOLN',
    state: 'MS',
  },
  {
    county: 'LOWNDES',
    state: 'MS',
  },
  {
    county: 'MADISON',
    state: 'MS',
  },
  {
    county: 'MARION',
    state: 'MS',
  },
  {
    county: 'MARSHALL',
    state: 'MS',
  },
  {
    county: 'MONROE',
    state: 'MS',
  },
  {
    county: 'MONTGOMERY',
    state: 'MS',
  },
  {
    county: 'NESHOBA',
    state: 'MS',
  },
  {
    county: 'NEWTON',
    state: 'MS',
  },
  {
    county: 'NOXUBEE',
    state: 'MS',
  },
  {
    county: 'OKTIBBEHA',
    state: 'MS',
  },
  {
    county: 'PANOLA',
    state: 'MS',
  },
  {
    county: 'PEARL RIVER',
    state: 'MS',
  },
  {
    county: 'PERRY',
    state: 'MS',
  },
  {
    county: 'PIKE',
    state: 'MS',
  },
  {
    county: 'PONTOTOC',
    state: 'MS',
  },
  {
    county: 'PRENTISS',
    state: 'MS',
  },
  {
    county: 'QUITMAN',
    state: 'MS',
  },
  {
    county: 'RANKIN',
    state: 'MS',
  },
  {
    county: 'SCOTT',
    state: 'MS',
  },
  {
    county: 'SHARKEY',
    state: 'MS',
  },
  {
    county: 'SIMPSON',
    state: 'MS',
  },
  {
    county: 'SMITH',
    state: 'MS',
  },
  {
    county: 'STONE',
    state: 'MS',
  },
  {
    county: 'SUNFLOWER',
    state: 'MS',
  },
  {
    county: 'TALLAHATCHIE',
    state: 'MS',
  },
  {
    county: 'TATE',
    state: 'MS',
  },
  {
    county: 'TIPPAH',
    state: 'MS',
  },
  {
    county: 'TISHOMINGO',
    state: 'MS',
  },
  {
    county: 'TUNICA',
    state: 'MS',
  },
  {
    county: 'UNION',
    state: 'MS',
  },
  {
    county: 'WALTHALL',
    state: 'MS',
  },
  {
    county: 'WARREN',
    state: 'MS',
  },
  {
    county: 'WASHINGTON',
    state: 'MS',
  },
  {
    county: 'WAYNE',
    state: 'MS',
  },
  {
    county: 'WEBSTER',
    state: 'MS',
  },
  {
    county: 'WILKINSON',
    state: 'MS',
  },
  {
    county: 'WINSTON',
    state: 'MS',
  },
  {
    county: 'YALOBUSHA',
    state: 'MS',
  },
  {
    county: 'YAZOO',
    state: 'MS',
  },
  {
    county: 'ADAIR',
    state: 'MO',
  },
  {
    county: 'ANDREW',
    state: 'MO',
  },
  {
    county: 'ATCHISON',
    state: 'MO',
  },
  {
    county: 'AUDRAIN',
    state: 'MO',
  },
  {
    county: 'BARRY',
    state: 'MO',
  },
  {
    county: 'BARTON',
    state: 'MO',
  },
  {
    county: 'BATES',
    state: 'MO',
  },
  {
    county: 'BENTON',
    state: 'MO',
  },
  {
    county: 'BOLLINGER',
    state: 'MO',
  },
  {
    county: 'BOONE',
    state: 'MO',
  },
  {
    county: 'BUCHANAN',
    state: 'MO',
  },
  {
    county: 'BUTLER',
    state: 'MO',
  },
  {
    county: 'CALDWELL',
    state: 'MO',
  },
  {
    county: 'CALLAWAY',
    state: 'MO',
  },
  {
    county: 'CAMDEN',
    state: 'MO',
  },
  {
    county: 'CAPE GIRARDEAU',
    state: 'MO',
  },
  {
    county: 'CARROLL',
    state: 'MO',
  },
  {
    county: 'CARTER',
    state: 'MO',
  },
  {
    county: 'CASS',
    state: 'MO',
  },
  {
    county: 'CEDAR',
    state: 'MO',
  },
  {
    county: 'CHARITON',
    state: 'MO',
  },
  {
    county: 'CHRISTIAN',
    state: 'MO',
  },
  {
    county: 'CLARK',
    state: 'MO',
  },
  {
    county: 'CLAY',
    state: 'MO',
  },
  {
    county: 'CLINTON',
    state: 'MO',
  },
  {
    county: 'COLE',
    state: 'MO',
  },
  {
    county: 'COOPER',
    state: 'MO',
  },
  {
    county: 'CRAWFORD',
    state: 'MO',
  },
  {
    county: 'DADE',
    state: 'MO',
  },
  {
    county: 'DALLAS',
    state: 'MO',
  },
  {
    county: 'DAVIESS',
    state: 'MO',
  },
  {
    county: 'DEKALB',
    state: 'MO',
  },
  {
    county: 'DENT',
    state: 'MO',
  },
  {
    county: 'DOUGLAS',
    state: 'MO',
  },
  {
    county: 'DUNKLIN',
    state: 'MO',
  },
  {
    county: 'FRANKLIN',
    state: 'MO',
  },
  {
    county: 'GASCONADE',
    state: 'MO',
  },
  {
    county: 'GENTRY',
    state: 'MO',
  },
  {
    county: 'GREENE',
    state: 'MO',
  },
  {
    county: 'GRUNDY',
    state: 'MO',
  },
  {
    county: 'HARRISON',
    state: 'MO',
  },
  {
    county: 'HENRY',
    state: 'MO',
  },
  {
    county: 'HICKORY',
    state: 'MO',
  },
  {
    county: 'HOLT',
    state: 'MO',
  },
  {
    county: 'HOWARD',
    state: 'MO',
  },
  {
    county: 'HOWELL',
    state: 'MO',
  },
  {
    county: 'IRON',
    state: 'MO',
  },
  {
    county: 'JACKSON',
    state: 'MO',
  },
  {
    county: 'JASPER',
    state: 'MO',
  },
  {
    county: 'JEFFERSON',
    state: 'MO',
  },
  {
    county: 'JOHNSON',
    state: 'MO',
  },
  {
    county: 'KNOX',
    state: 'MO',
  },
  {
    county: 'LACLEDE',
    state: 'MO',
  },
  {
    county: 'LAFAYETTE',
    state: 'MO',
  },
  {
    county: 'LAWRENCE',
    state: 'MO',
  },
  {
    county: 'LEWIS',
    state: 'MO',
  },
  {
    county: 'LINCOLN',
    state: 'MO',
  },
  {
    county: 'LINN',
    state: 'MO',
  },
  {
    county: 'LIVINGSTON',
    state: 'MO',
  },
  {
    county: 'MCDONALD',
    state: 'MO',
  },
  {
    county: 'MACON',
    state: 'MO',
  },
  {
    county: 'MADISON',
    state: 'MO',
  },
  {
    county: 'MARIES',
    state: 'MO',
  },
  {
    county: 'MARION',
    state: 'MO',
  },
  {
    county: 'MERCER',
    state: 'MO',
  },
  {
    county: 'MILLER',
    state: 'MO',
  },
  {
    county: 'MISSISSIPPI',
    state: 'MO',
  },
  {
    county: 'MONITEAU',
    state: 'MO',
  },
  {
    county: 'MONROE',
    state: 'MO',
  },
  {
    county: 'MONTGOMERY',
    state: 'MO',
  },
  {
    county: 'MORGAN',
    state: 'MO',
  },
  {
    county: 'NEW MADRID',
    state: 'MO',
  },
  {
    county: 'NEWTON',
    state: 'MO',
  },
  {
    county: 'NODAWAY',
    state: 'MO',
  },
  {
    county: 'OREGON',
    state: 'MO',
  },
  {
    county: 'OSAGE',
    state: 'MO',
  },
  {
    county: 'OZARK',
    state: 'MO',
  },
  {
    county: 'PEMISCOT',
    state: 'MO',
  },
  {
    county: 'PERRY',
    state: 'MO',
  },
  {
    county: 'PETTIS',
    state: 'MO',
  },
  {
    county: 'PHELPS',
    state: 'MO',
  },
  {
    county: 'PIKE',
    state: 'MO',
  },
  {
    county: 'PLATTE',
    state: 'MO',
  },
  {
    county: 'POLK',
    state: 'MO',
  },
  {
    county: 'PULASKI',
    state: 'MO',
  },
  {
    county: 'PUTNAM',
    state: 'MO',
  },
  {
    county: 'RALLS',
    state: 'MO',
  },
  {
    county: 'RANDOLPH',
    state: 'MO',
  },
  {
    county: 'RAY',
    state: 'MO',
  },
  {
    county: 'REYNOLDS',
    state: 'MO',
  },
  {
    county: 'RIPLEY',
    state: 'MO',
  },
  {
    county: 'ST CHARLES',
    state: 'MO',
  },
  {
    county: 'ST CLAIR',
    state: 'MO',
  },
  {
    county: 'STE GENEVIEVE',
    state: 'MO',
  },
  {
    county: 'ST FRANCOIS',
    state: 'MO',
  },
  {
    county: 'ST LOUIS',
    state: 'MO',
  },
  {
    county: 'SALINE',
    state: 'MO',
  },
  {
    county: 'SCHUYLER',
    state: 'MO',
  },
  {
    county: 'SCOTLAND',
    state: 'MO',
  },
  {
    county: 'SCOTT',
    state: 'MO',
  },
  {
    county: 'SHANNON',
    state: 'MO',
  },
  {
    county: 'SHELBY',
    state: 'MO',
  },
  {
    county: 'STODDARD',
    state: 'MO',
  },
  {
    county: 'STONE',
    state: 'MO',
  },
  {
    county: 'SULLIVAN',
    state: 'MO',
  },
  {
    county: 'TANEY',
    state: 'MO',
  },
  {
    county: 'TEXAS',
    state: 'MO',
  },
  {
    county: 'VERNON',
    state: 'MO',
  },
  {
    county: 'WARREN',
    state: 'MO',
  },
  {
    county: 'WASHINGTON',
    state: 'MO',
  },
  {
    county: 'WAYNE',
    state: 'MO',
  },
  {
    county: 'WEBSTER',
    state: 'MO',
  },
  {
    county: 'WORTH',
    state: 'MO',
  },
  {
    county: 'WRIGHT',
    state: 'MO',
  },
  {
    county: 'ST LOUIS',
    state: 'MO',
  },
  {
    county: 'BEAVERHEAD',
    state: 'MT',
  },
  {
    county: 'BIG HORN',
    state: 'MT',
  },
  {
    county: 'BLAINE',
    state: 'MT',
  },
  {
    county: 'BROADWATER',
    state: 'MT',
  },
  {
    county: 'CARBON',
    state: 'MT',
  },
  {
    county: 'CARTER',
    state: 'MT',
  },
  {
    county: 'CASCADE',
    state: 'MT',
  },
  {
    county: 'CHOUTEAU',
    state: 'MT',
  },
  {
    county: 'CUSTER',
    state: 'MT',
  },
  {
    county: 'DANIELS',
    state: 'MT',
  },
  {
    county: 'DAWSON',
    state: 'MT',
  },
  {
    county: 'DEER LODGE',
    state: 'MT',
  },
  {
    county: 'FALLON',
    state: 'MT',
  },
  {
    county: 'FERGUS',
    state: 'MT',
  },
  {
    county: 'FLATHEAD',
    state: 'MT',
  },
  {
    county: 'GALLATIN',
    state: 'MT',
  },
  {
    county: 'GARFIELD',
    state: 'MT',
  },
  {
    county: 'GLACIER',
    state: 'MT',
  },
  {
    county: 'GOLDEN VALLEY',
    state: 'MT',
  },
  {
    county: 'GRANITE',
    state: 'MT',
  },
  {
    county: 'HILL',
    state: 'MT',
  },
  {
    county: 'JEFFERSON',
    state: 'MT',
  },
  {
    county: 'JUDITH BASIN',
    state: 'MT',
  },
  {
    county: 'LAKE',
    state: 'MT',
  },
  {
    county: 'LEWIS AND CLARK',
    state: 'MT',
  },
  {
    county: 'LIBERTY',
    state: 'MT',
  },
  {
    county: 'LINCOLN',
    state: 'MT',
  },
  {
    county: 'MCCONE',
    state: 'MT',
  },
  {
    county: 'MADISON',
    state: 'MT',
  },
  {
    county: 'MEAGHER',
    state: 'MT',
  },
  {
    county: 'MINERAL',
    state: 'MT',
  },
  {
    county: 'MISSOULA',
    state: 'MT',
  },
  {
    county: 'MUSSELSHELL',
    state: 'MT',
  },
  {
    county: 'PARK',
    state: 'MT',
  },
  {
    county: 'PETROLEUM',
    state: 'MT',
  },
  {
    county: 'PHILLIPS',
    state: 'MT',
  },
  {
    county: 'PONDERA',
    state: 'MT',
  },
  {
    county: 'POWDER RIVER',
    state: 'MT',
  },
  {
    county: 'POWELL',
    state: 'MT',
  },
  {
    county: 'PRAIRIE',
    state: 'MT',
  },
  {
    county: 'RAVALLI',
    state: 'MT',
  },
  {
    county: 'RICHLAND',
    state: 'MT',
  },
  {
    county: 'ROOSEVELT',
    state: 'MT',
  },
  {
    county: 'ROSEBUD',
    state: 'MT',
  },
  {
    county: 'SANDERS',
    state: 'MT',
  },
  {
    county: 'SHERIDAN',
    state: 'MT',
  },
  {
    county: 'SILVER BOW',
    state: 'MT',
  },
  {
    county: 'STILLWATER',
    state: 'MT',
  },
  {
    county: 'SWEET GRASS',
    state: 'MT',
  },
  {
    county: 'TETON',
    state: 'MT',
  },
  {
    county: 'TOOLE',
    state: 'MT',
  },
  {
    county: 'TREASURE',
    state: 'MT',
  },
  {
    county: 'VALLEY',
    state: 'MT',
  },
  {
    county: 'WHEATLAND',
    state: 'MT',
  },
  {
    county: 'WIBAUX',
    state: 'MT',
  },
  {
    county: 'YELLOWSTONE',
    state: 'MT',
  },
  {
    county: 'ADAMS',
    state: 'NE',
  },
  {
    county: 'ANTELOPE',
    state: 'NE',
  },
  {
    county: 'ARTHUR',
    state: 'NE',
  },
  {
    county: 'BANNER',
    state: 'NE',
  },
  {
    county: 'BLAINE',
    state: 'NE',
  },
  {
    county: 'BOONE',
    state: 'NE',
  },
  {
    county: 'BOX BUTTE',
    state: 'NE',
  },
  {
    county: 'BOYD',
    state: 'NE',
  },
  {
    county: 'BROWN',
    state: 'NE',
  },
  {
    county: 'BUFFALO',
    state: 'NE',
  },
  {
    county: 'BURT',
    state: 'NE',
  },
  {
    county: 'BUTLER',
    state: 'NE',
  },
  {
    county: 'CASS',
    state: 'NE',
  },
  {
    county: 'CEDAR',
    state: 'NE',
  },
  {
    county: 'CHASE',
    state: 'NE',
  },
  {
    county: 'CHERRY',
    state: 'NE',
  },
  {
    county: 'CHEYENNE',
    state: 'NE',
  },
  {
    county: 'CLAY',
    state: 'NE',
  },
  {
    county: 'COLFAX',
    state: 'NE',
  },
  {
    county: 'CUMING',
    state: 'NE',
  },
  {
    county: 'CUSTER',
    state: 'NE',
  },
  {
    county: 'DAKOTA',
    state: 'NE',
  },
  {
    county: 'DAWES',
    state: 'NE',
  },
  {
    county: 'DAWSON',
    state: 'NE',
  },
  {
    county: 'DEUEL',
    state: 'NE',
  },
  {
    county: 'DIXON',
    state: 'NE',
  },
  {
    county: 'DODGE',
    state: 'NE',
  },
  {
    county: 'DOUGLAS',
    state: 'NE',
  },
  {
    county: 'DUNDY',
    state: 'NE',
  },
  {
    county: 'FILLMORE',
    state: 'NE',
  },
  {
    county: 'FRANKLIN',
    state: 'NE',
  },
  {
    county: 'FRONTIER',
    state: 'NE',
  },
  {
    county: 'FURNAS',
    state: 'NE',
  },
  {
    county: 'GAGE',
    state: 'NE',
  },
  {
    county: 'GARDEN',
    state: 'NE',
  },
  {
    county: 'GARFIELD',
    state: 'NE',
  },
  {
    county: 'GOSPER',
    state: 'NE',
  },
  {
    county: 'GRANT',
    state: 'NE',
  },
  {
    county: 'GREELEY',
    state: 'NE',
  },
  {
    county: 'HALL',
    state: 'NE',
  },
  {
    county: 'HAMILTON',
    state: 'NE',
  },
  {
    county: 'HARLAN',
    state: 'NE',
  },
  {
    county: 'HAYES',
    state: 'NE',
  },
  {
    county: 'HITCHCOCK',
    state: 'NE',
  },
  {
    county: 'HOLT',
    state: 'NE',
  },
  {
    county: 'HOOKER',
    state: 'NE',
  },
  {
    county: 'HOWARD',
    state: 'NE',
  },
  {
    county: 'JEFFERSON',
    state: 'NE',
  },
  {
    county: 'JOHNSON',
    state: 'NE',
  },
  {
    county: 'KEARNEY',
    state: 'NE',
  },
  {
    county: 'KEITH',
    state: 'NE',
  },
  {
    county: 'KEYA PAHA',
    state: 'NE',
  },
  {
    county: 'KIMBALL',
    state: 'NE',
  },
  {
    county: 'KNOX',
    state: 'NE',
  },
  {
    county: 'LANCASTER',
    state: 'NE',
  },
  {
    county: 'LINCOLN',
    state: 'NE',
  },
  {
    county: 'LOGAN',
    state: 'NE',
  },
  {
    county: 'LOUP',
    state: 'NE',
  },
  {
    county: 'MCPHERSON',
    state: 'NE',
  },
  {
    county: 'MADISON',
    state: 'NE',
  },
  {
    county: 'MERRICK',
    state: 'NE',
  },
  {
    county: 'MORRILL',
    state: 'NE',
  },
  {
    county: 'NANCE',
    state: 'NE',
  },
  {
    county: 'NEMAHA',
    state: 'NE',
  },
  {
    county: 'NUCKOLLS',
    state: 'NE',
  },
  {
    county: 'OTOE',
    state: 'NE',
  },
  {
    county: 'PAWNEE',
    state: 'NE',
  },
  {
    county: 'PERKINS',
    state: 'NE',
  },
  {
    county: 'PHELPS',
    state: 'NE',
  },
  {
    county: 'PIERCE',
    state: 'NE',
  },
  {
    county: 'PLATTE',
    state: 'NE',
  },
  {
    county: 'POLK',
    state: 'NE',
  },
  {
    county: 'RED WILLOW',
    state: 'NE',
  },
  {
    county: 'RICHARDSON',
    state: 'NE',
  },
  {
    county: 'ROCK',
    state: 'NE',
  },
  {
    county: 'SALINE',
    state: 'NE',
  },
  {
    county: 'SARPY',
    state: 'NE',
  },
  {
    county: 'SAUNDERS',
    state: 'NE',
  },
  {
    county: 'SCOTTS BLUFF',
    state: 'NE',
  },
  {
    county: 'SEWARD',
    state: 'NE',
  },
  {
    county: 'SHERIDAN',
    state: 'NE',
  },
  {
    county: 'SHERMAN',
    state: 'NE',
  },
  {
    county: 'SIOUX',
    state: 'NE',
  },
  {
    county: 'STANTON',
    state: 'NE',
  },
  {
    county: 'THAYER',
    state: 'NE',
  },
  {
    county: 'THOMAS',
    state: 'NE',
  },
  {
    county: 'THURSTON',
    state: 'NE',
  },
  {
    county: 'VALLEY',
    state: 'NE',
  },
  {
    county: 'WASHINGTON',
    state: 'NE',
  },
  {
    county: 'WAYNE',
    state: 'NE',
  },
  {
    county: 'WEBSTER',
    state: 'NE',
  },
  {
    county: 'WHEELER',
    state: 'NE',
  },
  {
    county: 'YORK',
    state: 'NE',
  },
  {
    county: 'CHURCHILL',
    state: 'NV',
  },
  {
    county: 'CLARK',
    state: 'NV',
  },
  {
    county: 'DOUGLAS',
    state: 'NV',
  },
  {
    county: 'ELKO',
    state: 'NV',
  },
  {
    county: 'ESMERALDA',
    state: 'NV',
  },
  {
    county: 'EUREKA',
    state: 'NV',
  },
  {
    county: 'HUMBOLDT',
    state: 'NV',
  },
  {
    county: 'LANDER',
    state: 'NV',
  },
  {
    county: 'LINCOLN',
    state: 'NV',
  },
  {
    county: 'LYON',
    state: 'NV',
  },
  {
    county: 'MINERAL',
    state: 'NV',
  },
  {
    county: 'NYE',
    state: 'NV',
  },
  {
    county: 'PERSHING',
    state: 'NV',
  },
  {
    county: 'STOREY',
    state: 'NV',
  },
  {
    county: 'WASHOE',
    state: 'NV',
  },
  {
    county: 'WHITE PINE',
    state: 'NV',
  },
  {
    county: 'CARSON CITY',
    state: 'NV',
  },
  {
    county: 'BELKNAP',
    state: 'NH',
  },
  {
    county: 'CARROLL',
    state: 'NH',
  },
  {
    county: 'CHESHIRE',
    state: 'NH',
  },
  {
    county: 'COOS',
    state: 'NH',
  },
  {
    county: 'GRAFTON',
    state: 'NH',
  },
  {
    county: 'HILLSBOROUGH',
    state: 'NH',
  },
  {
    county: 'MERRIMACK',
    state: 'NH',
  },
  {
    county: 'ROCKINGHAM',
    state: 'NH',
  },
  {
    county: 'STRAFFORD',
    state: 'NH',
  },
  {
    county: 'SULLIVAN',
    state: 'NH',
  },
  {
    county: 'ATLANTIC',
    state: 'NJ',
  },
  {
    county: 'BERGEN',
    state: 'NJ',
  },
  {
    county: 'BURLINGTON',
    state: 'NJ',
  },
  {
    county: 'CAMDEN',
    state: 'NJ',
  },
  {
    county: 'CAPE MAY',
    state: 'NJ',
  },
  {
    county: 'CUMBERLAND',
    state: 'NJ',
  },
  {
    county: 'ESSEX',
    state: 'NJ',
  },
  {
    county: 'GLOUCESTER',
    state: 'NJ',
  },
  {
    county: 'HUDSON',
    state: 'NJ',
  },
  {
    county: 'HUNTERDON',
    state: 'NJ',
  },
  {
    county: 'MERCER',
    state: 'NJ',
  },
  {
    county: 'MIDDLESEX',
    state: 'NJ',
  },
  {
    county: 'MONMOUTH',
    state: 'NJ',
  },
  {
    county: 'MORRIS',
    state: 'NJ',
  },
  {
    county: 'OCEAN',
    state: 'NJ',
  },
  {
    county: 'PASSAIC',
    state: 'NJ',
  },
  {
    county: 'SALEM',
    state: 'NJ',
  },
  {
    county: 'SOMERSET',
    state: 'NJ',
  },
  {
    county: 'SUSSEX',
    state: 'NJ',
  },
  {
    county: 'UNION',
    state: 'NJ',
  },
  {
    county: 'WARREN',
    state: 'NJ',
  },
  {
    county: 'BERNALILLO',
    state: 'NM',
  },
  {
    county: 'CATRON',
    state: 'NM',
  },
  {
    county: 'CHAVES',
    state: 'NM',
  },
  {
    county: 'CIBOLA',
    state: 'NM',
  },
  {
    county: 'COLFAX',
    state: 'NM',
  },
  {
    county: 'CURRY',
    state: 'NM',
  },
  {
    county: 'DE BACA',
    state: 'NM',
  },
  {
    county: 'DOÑA ANA',
    state: 'NM',
  },
  {
    county: 'EDDY',
    state: 'NM',
  },
  {
    county: 'GRANT',
    state: 'NM',
  },
  {
    county: 'GUADALUPE',
    state: 'NM',
  },
  {
    county: 'HARDING',
    state: 'NM',
  },
  {
    county: 'HIDALGO',
    state: 'NM',
  },
  {
    county: 'LEA',
    state: 'NM',
  },
  {
    county: 'LINCOLN',
    state: 'NM',
  },
  {
    county: 'LOS ALAMOS',
    state: 'NM',
  },
  {
    county: 'LUNA',
    state: 'NM',
  },
  {
    county: 'MCKINLEY',
    state: 'NM',
  },
  {
    county: 'MORA',
    state: 'NM',
  },
  {
    county: 'OTERO',
    state: 'NM',
  },
  {
    county: 'QUAY',
    state: 'NM',
  },
  {
    county: 'RIO ARRIBA',
    state: 'NM',
  },
  {
    county: 'ROOSEVELT',
    state: 'NM',
  },
  {
    county: 'SANDOVAL',
    state: 'NM',
  },
  {
    county: 'SAN JUAN',
    state: 'NM',
  },
  {
    county: 'SAN MIGUEL',
    state: 'NM',
  },
  {
    county: 'SANTA FE',
    state: 'NM',
  },
  {
    county: 'SIERRA',
    state: 'NM',
  },
  {
    county: 'SOCORRO',
    state: 'NM',
  },
  {
    county: 'TAOS',
    state: 'NM',
  },
  {
    county: 'TORRANCE',
    state: 'NM',
  },
  {
    county: 'UNION',
    state: 'NM',
  },
  {
    county: 'VALENCIA',
    state: 'NM',
  },
  {
    county: 'ALBANY',
    state: 'NY',
  },
  {
    county: 'ALLEGANY',
    state: 'NY',
  },
  {
    county: 'BRONX',
    state: 'NY',
  },
  {
    county: 'BROOME',
    state: 'NY',
  },
  {
    county: 'CATTARAUGUS',
    state: 'NY',
  },
  {
    county: 'CAYUGA',
    state: 'NY',
  },
  {
    county: 'CHAUTAUQUA',
    state: 'NY',
  },
  {
    county: 'CHEMUNG',
    state: 'NY',
  },
  {
    county: 'CHENANGO',
    state: 'NY',
  },
  {
    county: 'CLINTON',
    state: 'NY',
  },
  {
    county: 'COLUMBIA',
    state: 'NY',
  },
  {
    county: 'CORTLAND',
    state: 'NY',
  },
  {
    county: 'DELAWARE',
    state: 'NY',
  },
  {
    county: 'DUTCHESS',
    state: 'NY',
  },
  {
    county: 'ERIE',
    state: 'NY',
  },
  {
    county: 'ESSEX',
    state: 'NY',
  },
  {
    county: 'FRANKLIN',
    state: 'NY',
  },
  {
    county: 'FULTON',
    state: 'NY',
  },
  {
    county: 'GENESEE',
    state: 'NY',
  },
  {
    county: 'GREENE',
    state: 'NY',
  },
  {
    county: 'HAMILTON',
    state: 'NY',
  },
  {
    county: 'HERKIMER',
    state: 'NY',
  },
  {
    county: 'JEFFERSON',
    state: 'NY',
  },
  {
    county: 'KINGS',
    state: 'NY',
  },
  {
    county: 'LEWIS',
    state: 'NY',
  },
  {
    county: 'LIVINGSTON',
    state: 'NY',
  },
  {
    county: 'MADISON',
    state: 'NY',
  },
  {
    county: 'MONROE',
    state: 'NY',
  },
  {
    county: 'MONTGOMERY',
    state: 'NY',
  },
  {
    county: 'NASSAU',
    state: 'NY',
  },
  {
    county: 'NEW YORK',
    state: 'NY',
  },
  {
    county: 'NIAGARA',
    state: 'NY',
  },
  {
    county: 'ONEIDA',
    state: 'NY',
  },
  {
    county: 'ONONDAGA',
    state: 'NY',
  },
  {
    county: 'ONTARIO',
    state: 'NY',
  },
  {
    county: 'ORANGE',
    state: 'NY',
  },
  {
    county: 'ORLEANS',
    state: 'NY',
  },
  {
    county: 'OSWEGO',
    state: 'NY',
  },
  {
    county: 'OTSEGO',
    state: 'NY',
  },
  {
    county: 'PUTNAM',
    state: 'NY',
  },
  {
    county: 'QUEENS',
    state: 'NY',
  },
  {
    county: 'RENSSELAER',
    state: 'NY',
  },
  {
    county: 'RICHMOND',
    state: 'NY',
  },
  {
    county: 'ROCKLAND',
    state: 'NY',
  },
  {
    county: 'ST LAWRENCE',
    state: 'NY',
  },
  {
    county: 'SARATOGA',
    state: 'NY',
  },
  {
    county: 'SCHENECTADY',
    state: 'NY',
  },
  {
    county: 'SCHOHARIE',
    state: 'NY',
  },
  {
    county: 'SCHUYLER',
    state: 'NY',
  },
  {
    county: 'SENECA',
    state: 'NY',
  },
  {
    county: 'STEUBEN',
    state: 'NY',
  },
  {
    county: 'SUFFOLK',
    state: 'NY',
  },
  {
    county: 'SULLIVAN',
    state: 'NY',
  },
  {
    county: 'TIOGA',
    state: 'NY',
  },
  {
    county: 'TOMPKINS',
    state: 'NY',
  },
  {
    county: 'ULSTER',
    state: 'NY',
  },
  {
    county: 'WARREN',
    state: 'NY',
  },
  {
    county: 'WASHINGTON',
    state: 'NY',
  },
  {
    county: 'WAYNE',
    state: 'NY',
  },
  {
    county: 'WESTCHESTER',
    state: 'NY',
  },
  {
    county: 'WYOMING',
    state: 'NY',
  },
  {
    county: 'YATES',
    state: 'NY',
  },
  {
    county: 'ALAMANCE',
    state: 'NC',
  },
  {
    county: 'ALEXANDER',
    state: 'NC',
  },
  {
    county: 'ALLEGHANY',
    state: 'NC',
  },
  {
    county: 'ANSON',
    state: 'NC',
  },
  {
    county: 'ASHE',
    state: 'NC',
  },
  {
    county: 'AVERY',
    state: 'NC',
  },
  {
    county: 'BEAUFORT',
    state: 'NC',
  },
  {
    county: 'BERTIE',
    state: 'NC',
  },
  {
    county: 'BLADEN',
    state: 'NC',
  },
  {
    county: 'BRUNSWICK',
    state: 'NC',
  },
  {
    county: 'BUNCOMBE',
    state: 'NC',
  },
  {
    county: 'BURKE',
    state: 'NC',
  },
  {
    county: 'CABARRUS',
    state: 'NC',
  },
  {
    county: 'CALDWELL',
    state: 'NC',
  },
  {
    county: 'CAMDEN',
    state: 'NC',
  },
  {
    county: 'CARTERET',
    state: 'NC',
  },
  {
    county: 'CASWELL',
    state: 'NC',
  },
  {
    county: 'CATAWBA',
    state: 'NC',
  },
  {
    county: 'CHATHAM',
    state: 'NC',
  },
  {
    county: 'CHEROKEE',
    state: 'NC',
  },
  {
    county: 'CHOWAN',
    state: 'NC',
  },
  {
    county: 'CLAY',
    state: 'NC',
  },
  {
    county: 'CLEVELAND',
    state: 'NC',
  },
  {
    county: 'COLUMBUS',
    state: 'NC',
  },
  {
    county: 'CRAVEN',
    state: 'NC',
  },
  {
    county: 'CUMBERLAND',
    state: 'NC',
  },
  {
    county: 'CURRITUCK',
    state: 'NC',
  },
  {
    county: 'DARE',
    state: 'NC',
  },
  {
    county: 'DAVIDSON',
    state: 'NC',
  },
  {
    county: 'DAVIE',
    state: 'NC',
  },
  {
    county: 'DUPLIN',
    state: 'NC',
  },
  {
    county: 'DURHAM',
    state: 'NC',
  },
  {
    county: 'EDGECOMBE',
    state: 'NC',
  },
  {
    county: 'FORSYTH',
    state: 'NC',
  },
  {
    county: 'FRANKLIN',
    state: 'NC',
  },
  {
    county: 'GASTON',
    state: 'NC',
  },
  {
    county: 'GATES',
    state: 'NC',
  },
  {
    county: 'GRAHAM',
    state: 'NC',
  },
  {
    county: 'GRANVILLE',
    state: 'NC',
  },
  {
    county: 'GREENE',
    state: 'NC',
  },
  {
    county: 'GUILFORD',
    state: 'NC',
  },
  {
    county: 'HALIFAX',
    state: 'NC',
  },
  {
    county: 'HARNETT',
    state: 'NC',
  },
  {
    county: 'HAYWOOD',
    state: 'NC',
  },
  {
    county: 'HENDERSON',
    state: 'NC',
  },
  {
    county: 'HERTFORD',
    state: 'NC',
  },
  {
    county: 'HOKE',
    state: 'NC',
  },
  {
    county: 'HYDE',
    state: 'NC',
  },
  {
    county: 'IREDELL',
    state: 'NC',
  },
  {
    county: 'JACKSON',
    state: 'NC',
  },
  {
    county: 'JOHNSTON',
    state: 'NC',
  },
  {
    county: 'JONES',
    state: 'NC',
  },
  {
    county: 'LEE',
    state: 'NC',
  },
  {
    county: 'LENOIR',
    state: 'NC',
  },
  {
    county: 'LINCOLN',
    state: 'NC',
  },
  {
    county: 'MCDOWELL',
    state: 'NC',
  },
  {
    county: 'MACON',
    state: 'NC',
  },
  {
    county: 'MADISON',
    state: 'NC',
  },
  {
    county: 'MARTIN',
    state: 'NC',
  },
  {
    county: 'MECKLENBURG',
    state: 'NC',
  },
  {
    county: 'MITCHELL',
    state: 'NC',
  },
  {
    county: 'MONTGOMERY',
    state: 'NC',
  },
  {
    county: 'MOORE',
    state: 'NC',
  },
  {
    county: 'NASH',
    state: 'NC',
  },
  {
    county: 'NEW HANOVER',
    state: 'NC',
  },
  {
    county: 'NORTHAMPTON',
    state: 'NC',
  },
  {
    county: 'ONSLOW',
    state: 'NC',
  },
  {
    county: 'ORANGE',
    state: 'NC',
  },
  {
    county: 'PAMLICO',
    state: 'NC',
  },
  {
    county: 'PASQUOTANK',
    state: 'NC',
  },
  {
    county: 'PENDER',
    state: 'NC',
  },
  {
    county: 'PERQUIMANS',
    state: 'NC',
  },
  {
    county: 'PERSON',
    state: 'NC',
  },
  {
    county: 'PITT',
    state: 'NC',
  },
  {
    county: 'POLK',
    state: 'NC',
  },
  {
    county: 'RANDOLPH',
    state: 'NC',
  },
  {
    county: 'RICHMOND',
    state: 'NC',
  },
  {
    county: 'ROBESON',
    state: 'NC',
  },
  {
    county: 'ROCKINGHAM',
    state: 'NC',
  },
  {
    county: 'ROWAN',
    state: 'NC',
  },
  {
    county: 'RUTHERFORD',
    state: 'NC',
  },
  {
    county: 'SAMPSON',
    state: 'NC',
  },
  {
    county: 'SCOTLAND',
    state: 'NC',
  },
  {
    county: 'STANLY',
    state: 'NC',
  },
  {
    county: 'STOKES',
    state: 'NC',
  },
  {
    county: 'SURRY',
    state: 'NC',
  },
  {
    county: 'SWAIN',
    state: 'NC',
  },
  {
    county: 'TRANSYLVANIA',
    state: 'NC',
  },
  {
    county: 'TYRRELL',
    state: 'NC',
  },
  {
    county: 'UNION',
    state: 'NC',
  },
  {
    county: 'VANCE',
    state: 'NC',
  },
  {
    county: 'WAKE',
    state: 'NC',
  },
  {
    county: 'WARREN',
    state: 'NC',
  },
  {
    county: 'WASHINGTON',
    state: 'NC',
  },
  {
    county: 'WATAUGA',
    state: 'NC',
  },
  {
    county: 'WAYNE',
    state: 'NC',
  },
  {
    county: 'WILKES',
    state: 'NC',
  },
  {
    county: 'WILSON',
    state: 'NC',
  },
  {
    county: 'YADKIN',
    state: 'NC',
  },
  {
    county: 'YANCEY',
    state: 'NC',
  },
  {
    county: 'ADAMS',
    state: 'ND',
  },
  {
    county: 'BARNES',
    state: 'ND',
  },
  {
    county: 'BENSON',
    state: 'ND',
  },
  {
    county: 'BILLINGS',
    state: 'ND',
  },
  {
    county: 'BOTTINEAU',
    state: 'ND',
  },
  {
    county: 'BOWMAN',
    state: 'ND',
  },
  {
    county: 'BURKE',
    state: 'ND',
  },
  {
    county: 'BURLEIGH',
    state: 'ND',
  },
  {
    county: 'CASS',
    state: 'ND',
  },
  {
    county: 'CAVALIER',
    state: 'ND',
  },
  {
    county: 'DICKEY',
    state: 'ND',
  },
  {
    county: 'DIVIDE',
    state: 'ND',
  },
  {
    county: 'DUNN',
    state: 'ND',
  },
  {
    county: 'EDDY',
    state: 'ND',
  },
  {
    county: 'EMMONS',
    state: 'ND',
  },
  {
    county: 'FOSTER',
    state: 'ND',
  },
  {
    county: 'GOLDEN VALLEY',
    state: 'ND',
  },
  {
    county: 'GRAND FORKS',
    state: 'ND',
  },
  {
    county: 'GRANT',
    state: 'ND',
  },
  {
    county: 'GRIGGS',
    state: 'ND',
  },
  {
    county: 'HETTINGER',
    state: 'ND',
  },
  {
    county: 'KIDDER',
    state: 'ND',
  },
  {
    county: 'LAMOURE',
    state: 'ND',
  },
  {
    county: 'LOGAN',
    state: 'ND',
  },
  {
    county: 'MCHENRY',
    state: 'ND',
  },
  {
    county: 'MCINTOSH',
    state: 'ND',
  },
  {
    county: 'MCKENZIE',
    state: 'ND',
  },
  {
    county: 'MCLEAN',
    state: 'ND',
  },
  {
    county: 'MERCER',
    state: 'ND',
  },
  {
    county: 'MORTON',
    state: 'ND',
  },
  {
    county: 'MOUNTRAIL',
    state: 'ND',
  },
  {
    county: 'NELSON',
    state: 'ND',
  },
  {
    county: 'OLIVER',
    state: 'ND',
  },
  {
    county: 'PEMBINA',
    state: 'ND',
  },
  {
    county: 'PIERCE',
    state: 'ND',
  },
  {
    county: 'RAMSEY',
    state: 'ND',
  },
  {
    county: 'RANSOM',
    state: 'ND',
  },
  {
    county: 'RENVILLE',
    state: 'ND',
  },
  {
    county: 'RICHLAND',
    state: 'ND',
  },
  {
    county: 'ROLETTE',
    state: 'ND',
  },
  {
    county: 'SARGENT',
    state: 'ND',
  },
  {
    county: 'SHERIDAN',
    state: 'ND',
  },
  {
    county: 'SIOUX',
    state: 'ND',
  },
  {
    county: 'SLOPE',
    state: 'ND',
  },
  {
    county: 'STARK',
    state: 'ND',
  },
  {
    county: 'STEELE',
    state: 'ND',
  },
  {
    county: 'STUTSMAN',
    state: 'ND',
  },
  {
    county: 'TOWNER',
    state: 'ND',
  },
  {
    county: 'TRAILL',
    state: 'ND',
  },
  {
    county: 'WALSH',
    state: 'ND',
  },
  {
    county: 'WARD',
    state: 'ND',
  },
  {
    county: 'WELLS',
    state: 'ND',
  },
  {
    county: 'WILLIAMS',
    state: 'ND',
  },
  {
    county: 'ADAMS',
    state: 'OH',
  },
  {
    county: 'ALLEN',
    state: 'OH',
  },
  {
    county: 'ASHLAND',
    state: 'OH',
  },
  {
    county: 'ASHTABULA',
    state: 'OH',
  },
  {
    county: 'ATHENS',
    state: 'OH',
  },
  {
    county: 'AUGLAIZE',
    state: 'OH',
  },
  {
    county: 'BELMONT',
    state: 'OH',
  },
  {
    county: 'BROWN',
    state: 'OH',
  },
  {
    county: 'BUTLER',
    state: 'OH',
  },
  {
    county: 'CARROLL',
    state: 'OH',
  },
  {
    county: 'CHAMPAIGN',
    state: 'OH',
  },
  {
    county: 'CLARK',
    state: 'OH',
  },
  {
    county: 'CLERMONT',
    state: 'OH',
  },
  {
    county: 'CLINTON',
    state: 'OH',
  },
  {
    county: 'COLUMBIANA',
    state: 'OH',
  },
  {
    county: 'COSHOCTON',
    state: 'OH',
  },
  {
    county: 'CRAWFORD',
    state: 'OH',
  },
  {
    county: 'CUYAHOGA',
    state: 'OH',
  },
  {
    county: 'DARKE',
    state: 'OH',
  },
  {
    county: 'DEFIANCE',
    state: 'OH',
  },
  {
    county: 'DELAWARE',
    state: 'OH',
  },
  {
    county: 'ERIE',
    state: 'OH',
  },
  {
    county: 'FAIRFIELD',
    state: 'OH',
  },
  {
    county: 'FAYETTE',
    state: 'OH',
  },
  {
    county: 'FRANKLIN',
    state: 'OH',
  },
  {
    county: 'FULTON',
    state: 'OH',
  },
  {
    county: 'GALLIA',
    state: 'OH',
  },
  {
    county: 'GEAUGA',
    state: 'OH',
  },
  {
    county: 'GREENE',
    state: 'OH',
  },
  {
    county: 'GUERNSEY',
    state: 'OH',
  },
  {
    county: 'HAMILTON',
    state: 'OH',
  },
  {
    county: 'HANCOCK',
    state: 'OH',
  },
  {
    county: 'HARDIN',
    state: 'OH',
  },
  {
    county: 'HARRISON',
    state: 'OH',
  },
  {
    county: 'HENRY',
    state: 'OH',
  },
  {
    county: 'HIGHLAND',
    state: 'OH',
  },
  {
    county: 'HOCKING',
    state: 'OH',
  },
  {
    county: 'HOLMES',
    state: 'OH',
  },
  {
    county: 'HURON',
    state: 'OH',
  },
  {
    county: 'JACKSON',
    state: 'OH',
  },
  {
    county: 'JEFFERSON',
    state: 'OH',
  },
  {
    county: 'KNOX',
    state: 'OH',
  },
  {
    county: 'LAKE',
    state: 'OH',
  },
  {
    county: 'LAWRENCE',
    state: 'OH',
  },
  {
    county: 'LICKING',
    state: 'OH',
  },
  {
    county: 'LOGAN',
    state: 'OH',
  },
  {
    county: 'LORAIN',
    state: 'OH',
  },
  {
    county: 'LUCAS',
    state: 'OH',
  },
  {
    county: 'MADISON',
    state: 'OH',
  },
  {
    county: 'MAHONING',
    state: 'OH',
  },
  {
    county: 'MARION',
    state: 'OH',
  },
  {
    county: 'MEDINA',
    state: 'OH',
  },
  {
    county: 'MEIGS',
    state: 'OH',
  },
  {
    county: 'MERCER',
    state: 'OH',
  },
  {
    county: 'MIAMI',
    state: 'OH',
  },
  {
    county: 'MONROE',
    state: 'OH',
  },
  {
    county: 'MONTGOMERY',
    state: 'OH',
  },
  {
    county: 'MORGAN',
    state: 'OH',
  },
  {
    county: 'MORROW',
    state: 'OH',
  },
  {
    county: 'MUSKINGUM',
    state: 'OH',
  },
  {
    county: 'NOBLE',
    state: 'OH',
  },
  {
    county: 'OTTAWA',
    state: 'OH',
  },
  {
    county: 'PAULDING',
    state: 'OH',
  },
  {
    county: 'PERRY',
    state: 'OH',
  },
  {
    county: 'PICKAWAY',
    state: 'OH',
  },
  {
    county: 'PIKE',
    state: 'OH',
  },
  {
    county: 'PORTAGE',
    state: 'OH',
  },
  {
    county: 'PREBLE',
    state: 'OH',
  },
  {
    county: 'PUTNAM',
    state: 'OH',
  },
  {
    county: 'RICHLAND',
    state: 'OH',
  },
  {
    county: 'ROSS',
    state: 'OH',
  },
  {
    county: 'SANDUSKY',
    state: 'OH',
  },
  {
    county: 'SCIOTO',
    state: 'OH',
  },
  {
    county: 'SENECA',
    state: 'OH',
  },
  {
    county: 'SHELBY',
    state: 'OH',
  },
  {
    county: 'STARK',
    state: 'OH',
  },
  {
    county: 'SUMMIT',
    state: 'OH',
  },
  {
    county: 'TRUMBULL',
    state: 'OH',
  },
  {
    county: 'TUSCARAWAS',
    state: 'OH',
  },
  {
    county: 'UNION',
    state: 'OH',
  },
  {
    county: 'VAN WERT',
    state: 'OH',
  },
  {
    county: 'VINTON',
    state: 'OH',
  },
  {
    county: 'WARREN',
    state: 'OH',
  },
  {
    county: 'WASHINGTON',
    state: 'OH',
  },
  {
    county: 'WAYNE',
    state: 'OH',
  },
  {
    county: 'WILLIAMS',
    state: 'OH',
  },
  {
    county: 'WOOD',
    state: 'OH',
  },
  {
    county: 'WYANDOT',
    state: 'OH',
  },
  {
    county: 'ADAIR',
    state: 'OK',
  },
  {
    county: 'ALFALFA',
    state: 'OK',
  },
  {
    county: 'ATOKA',
    state: 'OK',
  },
  {
    county: 'BEAVER',
    state: 'OK',
  },
  {
    county: 'BECKHAM',
    state: 'OK',
  },
  {
    county: 'BLAINE',
    state: 'OK',
  },
  {
    county: 'BRYAN',
    state: 'OK',
  },
  {
    county: 'CADDO',
    state: 'OK',
  },
  {
    county: 'CANADIAN',
    state: 'OK',
  },
  {
    county: 'CARTER',
    state: 'OK',
  },
  {
    county: 'CHEROKEE',
    state: 'OK',
  },
  {
    county: 'CHOCTAW',
    state: 'OK',
  },
  {
    county: 'CIMARRON',
    state: 'OK',
  },
  {
    county: 'CLEVELAND',
    state: 'OK',
  },
  {
    county: 'COAL',
    state: 'OK',
  },
  {
    county: 'COMANCHE',
    state: 'OK',
  },
  {
    county: 'COTTON',
    state: 'OK',
  },
  {
    county: 'CRAIG',
    state: 'OK',
  },
  {
    county: 'CREEK',
    state: 'OK',
  },
  {
    county: 'CUSTER',
    state: 'OK',
  },
  {
    county: 'DELAWARE',
    state: 'OK',
  },
  {
    county: 'DEWEY',
    state: 'OK',
  },
  {
    county: 'ELLIS',
    state: 'OK',
  },
  {
    county: 'GARFIELD',
    state: 'OK',
  },
  {
    county: 'GARVIN',
    state: 'OK',
  },
  {
    county: 'GRADY',
    state: 'OK',
  },
  {
    county: 'GRANT',
    state: 'OK',
  },
  {
    county: 'GREER',
    state: 'OK',
  },
  {
    county: 'HARMON',
    state: 'OK',
  },
  {
    county: 'HARPER',
    state: 'OK',
  },
  {
    county: 'HASKELL',
    state: 'OK',
  },
  {
    county: 'HUGHES',
    state: 'OK',
  },
  {
    county: 'JACKSON',
    state: 'OK',
  },
  {
    county: 'JEFFERSON',
    state: 'OK',
  },
  {
    county: 'JOHNSTON',
    state: 'OK',
  },
  {
    county: 'KAY',
    state: 'OK',
  },
  {
    county: 'KINGFISHER',
    state: 'OK',
  },
  {
    county: 'KIOWA',
    state: 'OK',
  },
  {
    county: 'LATIMER',
    state: 'OK',
  },
  {
    county: 'LE FLORE',
    state: 'OK',
  },
  {
    county: 'LINCOLN',
    state: 'OK',
  },
  {
    county: 'LOGAN',
    state: 'OK',
  },
  {
    county: 'LOVE',
    state: 'OK',
  },
  {
    county: 'MCCLAIN',
    state: 'OK',
  },
  {
    county: 'MCCURTAIN',
    state: 'OK',
  },
  {
    county: 'MCINTOSH',
    state: 'OK',
  },
  {
    county: 'MAJOR',
    state: 'OK',
  },
  {
    county: 'MARSHALL',
    state: 'OK',
  },
  {
    county: 'MAYES',
    state: 'OK',
  },
  {
    county: 'MURRAY',
    state: 'OK',
  },
  {
    county: 'MUSKOGEE',
    state: 'OK',
  },
  {
    county: 'NOBLE',
    state: 'OK',
  },
  {
    county: 'NOWATA',
    state: 'OK',
  },
  {
    county: 'OKFUSKEE',
    state: 'OK',
  },
  {
    county: 'OKLAHOMA',
    state: 'OK',
  },
  {
    county: 'OKMULGEE',
    state: 'OK',
  },
  {
    county: 'OSAGE',
    state: 'OK',
  },
  {
    county: 'OTTAWA',
    state: 'OK',
  },
  {
    county: 'PAWNEE',
    state: 'OK',
  },
  {
    county: 'PAYNE',
    state: 'OK',
  },
  {
    county: 'PITTSBURG',
    state: 'OK',
  },
  {
    county: 'PONTOTOC',
    state: 'OK',
  },
  {
    county: 'POTTAWATOMIE',
    state: 'OK',
  },
  {
    county: 'PUSHMATAHA',
    state: 'OK',
  },
  {
    county: 'ROGER MILLS',
    state: 'OK',
  },
  {
    county: 'ROGERS',
    state: 'OK',
  },
  {
    county: 'SEMINOLE',
    state: 'OK',
  },
  {
    county: 'SEQUOYAH',
    state: 'OK',
  },
  {
    county: 'STEPHENS',
    state: 'OK',
  },
  {
    county: 'TEXAS',
    state: 'OK',
  },
  {
    county: 'TILLMAN',
    state: 'OK',
  },
  {
    county: 'TULSA',
    state: 'OK',
  },
  {
    county: 'WAGONER',
    state: 'OK',
  },
  {
    county: 'WASHINGTON',
    state: 'OK',
  },
  {
    county: 'WASHITA',
    state: 'OK',
  },
  {
    county: 'WOODS',
    state: 'OK',
  },
  {
    county: 'WOODWARD',
    state: 'OK',
  },
  {
    county: 'BAKER',
    state: 'OR',
  },
  {
    county: 'BENTON',
    state: 'OR',
  },
  {
    county: 'CLACKAMAS',
    state: 'OR',
  },
  {
    county: 'CLATSOP',
    state: 'OR',
  },
  {
    county: 'COLUMBIA',
    state: 'OR',
  },
  {
    county: 'COOS',
    state: 'OR',
  },
  {
    county: 'CROOK',
    state: 'OR',
  },
  {
    county: 'CURRY',
    state: 'OR',
  },
  {
    county: 'DESCHUTES',
    state: 'OR',
  },
  {
    county: 'DOUGLAS',
    state: 'OR',
  },
  {
    county: 'GILLIAM',
    state: 'OR',
  },
  {
    county: 'GRANT',
    state: 'OR',
  },
  {
    county: 'HARNEY',
    state: 'OR',
  },
  {
    county: 'HOOD RIVER',
    state: 'OR',
  },
  {
    county: 'JACKSON',
    state: 'OR',
  },
  {
    county: 'JEFFERSON',
    state: 'OR',
  },
  {
    county: 'JOSEPHINE',
    state: 'OR',
  },
  {
    county: 'KLAMATH',
    state: 'OR',
  },
  {
    county: 'LAKE',
    state: 'OR',
  },
  {
    county: 'LANE',
    state: 'OR',
  },
  {
    county: 'LINCOLN',
    state: 'OR',
  },
  {
    county: 'LINN',
    state: 'OR',
  },
  {
    county: 'MALHEUR',
    state: 'OR',
  },
  {
    county: 'MARION',
    state: 'OR',
  },
  {
    county: 'MORROW',
    state: 'OR',
  },
  {
    county: 'MULTNOMAH',
    state: 'OR',
  },
  {
    county: 'POLK',
    state: 'OR',
  },
  {
    county: 'SHERMAN',
    state: 'OR',
  },
  {
    county: 'TILLAMOOK',
    state: 'OR',
  },
  {
    county: 'UMATILLA',
    state: 'OR',
  },
  {
    county: 'UNION',
    state: 'OR',
  },
  {
    county: 'WALLOWA',
    state: 'OR',
  },
  {
    county: 'WASCO',
    state: 'OR',
  },
  {
    county: 'WASHINGTON',
    state: 'OR',
  },
  {
    county: 'WHEELER',
    state: 'OR',
  },
  {
    county: 'YAMHILL',
    state: 'OR',
  },
  {
    county: 'ADAMS',
    state: 'PA',
  },
  {
    county: 'ALLEGHENY',
    state: 'PA',
  },
  {
    county: 'ARMSTRONG',
    state: 'PA',
  },
  {
    county: 'BEAVER',
    state: 'PA',
  },
  {
    county: 'BEDFORD',
    state: 'PA',
  },
  {
    county: 'BERKS',
    state: 'PA',
  },
  {
    county: 'BLAIR',
    state: 'PA',
  },
  {
    county: 'BRADFORD',
    state: 'PA',
  },
  {
    county: 'BUCKS',
    state: 'PA',
  },
  {
    county: 'BUTLER',
    state: 'PA',
  },
  {
    county: 'CAMBRIA',
    state: 'PA',
  },
  {
    county: 'CAMERON',
    state: 'PA',
  },
  {
    county: 'CARBON',
    state: 'PA',
  },
  {
    county: 'CENTRE',
    state: 'PA',
  },
  {
    county: 'CHESTER',
    state: 'PA',
  },
  {
    county: 'CLARION',
    state: 'PA',
  },
  {
    county: 'CLEARFIELD',
    state: 'PA',
  },
  {
    county: 'CLINTON',
    state: 'PA',
  },
  {
    county: 'COLUMBIA',
    state: 'PA',
  },
  {
    county: 'CRAWFORD',
    state: 'PA',
  },
  {
    county: 'CUMBERLAND',
    state: 'PA',
  },
  {
    county: 'DAUPHIN',
    state: 'PA',
  },
  {
    county: 'DELAWARE',
    state: 'PA',
  },
  {
    county: 'ELK',
    state: 'PA',
  },
  {
    county: 'ERIE',
    state: 'PA',
  },
  {
    county: 'FAYETTE',
    state: 'PA',
  },
  {
    county: 'FOREST',
    state: 'PA',
  },
  {
    county: 'FRANKLIN',
    state: 'PA',
  },
  {
    county: 'FULTON',
    state: 'PA',
  },
  {
    county: 'GREENE',
    state: 'PA',
  },
  {
    county: 'HUNTINGDON',
    state: 'PA',
  },
  {
    county: 'INDIANA',
    state: 'PA',
  },
  {
    county: 'JEFFERSON',
    state: 'PA',
  },
  {
    county: 'JUNIATA',
    state: 'PA',
  },
  {
    county: 'LACKAWANNA',
    state: 'PA',
  },
  {
    county: 'LANCASTER',
    state: 'PA',
  },
  {
    county: 'LAWRENCE',
    state: 'PA',
  },
  {
    county: 'LEBANON',
    state: 'PA',
  },
  {
    county: 'LEHIGH',
    state: 'PA',
  },
  {
    county: 'LUZERNE',
    state: 'PA',
  },
  {
    county: 'LYCOMING',
    state: 'PA',
  },
  {
    county: 'MCKEAN',
    state: 'PA',
  },
  {
    county: 'MERCER',
    state: 'PA',
  },
  {
    county: 'MIFFLIN',
    state: 'PA',
  },
  {
    county: 'MONROE',
    state: 'PA',
  },
  {
    county: 'MONTGOMERY',
    state: 'PA',
  },
  {
    county: 'MONTOUR',
    state: 'PA',
  },
  {
    county: 'NORTHAMPTON',
    state: 'PA',
  },
  {
    county: 'NORTHUMBERLAND',
    state: 'PA',
  },
  {
    county: 'PERRY',
    state: 'PA',
  },
  {
    county: 'PHILADELPHIA',
    state: 'PA',
  },
  {
    county: 'PIKE',
    state: 'PA',
  },
  {
    county: 'POTTER',
    state: 'PA',
  },
  {
    county: 'SCHUYLKILL',
    state: 'PA',
  },
  {
    county: 'SNYDER',
    state: 'PA',
  },
  {
    county: 'SOMERSET',
    state: 'PA',
  },
  {
    county: 'SULLIVAN',
    state: 'PA',
  },
  {
    county: 'SUSQUEHANNA',
    state: 'PA',
  },
  {
    county: 'TIOGA',
    state: 'PA',
  },
  {
    county: 'UNION',
    state: 'PA',
  },
  {
    county: 'VENANGO',
    state: 'PA',
  },
  {
    county: 'WARREN',
    state: 'PA',
  },
  {
    county: 'WASHINGTON',
    state: 'PA',
  },
  {
    county: 'WAYNE',
    state: 'PA',
  },
  {
    county: 'WESTMORELAND',
    state: 'PA',
  },
  {
    county: 'WYOMING',
    state: 'PA',
  },
  {
    county: 'YORK',
    state: 'PA',
  },
  {
    county: 'BRISTOL',
    state: 'RI',
  },
  {
    county: 'KENT',
    state: 'RI',
  },
  {
    county: 'NEWPORT',
    state: 'RI',
  },
  {
    county: 'PROVIDENCE',
    state: 'RI',
  },
  {
    county: 'WASHINGTON',
    state: 'RI',
  },
  {
    county: 'ABBEVILLE',
    state: 'SC',
  },
  {
    county: 'AIKEN',
    state: 'SC',
  },
  {
    county: 'ALLENDALE',
    state: 'SC',
  },
  {
    county: 'ANDERSON',
    state: 'SC',
  },
  {
    county: 'BAMBERG',
    state: 'SC',
  },
  {
    county: 'BARNWELL',
    state: 'SC',
  },
  {
    county: 'BEAUFORT',
    state: 'SC',
  },
  {
    county: 'BERKELEY',
    state: 'SC',
  },
  {
    county: 'CALHOUN',
    state: 'SC',
  },
  {
    county: 'CHARLESTON',
    state: 'SC',
  },
  {
    county: 'CHEROKEE',
    state: 'SC',
  },
  {
    county: 'CHESTER',
    state: 'SC',
  },
  {
    county: 'CHESTERFIELD',
    state: 'SC',
  },
  {
    county: 'CLARENDON',
    state: 'SC',
  },
  {
    county: 'COLLETON',
    state: 'SC',
  },
  {
    county: 'DARLINGTON',
    state: 'SC',
  },
  {
    county: 'DILLON',
    state: 'SC',
  },
  {
    county: 'DORCHESTER',
    state: 'SC',
  },
  {
    county: 'EDGEFIELD',
    state: 'SC',
  },
  {
    county: 'FAIRFIELD',
    state: 'SC',
  },
  {
    county: 'FLORENCE',
    state: 'SC',
  },
  {
    county: 'GEORGETOWN',
    state: 'SC',
  },
  {
    county: 'GREENVILLE',
    state: 'SC',
  },
  {
    county: 'GREENWOOD',
    state: 'SC',
  },
  {
    county: 'HAMPTON',
    state: 'SC',
  },
  {
    county: 'HORRY',
    state: 'SC',
  },
  {
    county: 'JASPER',
    state: 'SC',
  },
  {
    county: 'KERSHAW',
    state: 'SC',
  },
  {
    county: 'LANCASTER',
    state: 'SC',
  },
  {
    county: 'LAURENS',
    state: 'SC',
  },
  {
    county: 'LEE',
    state: 'SC',
  },
  {
    county: 'LEXINGTON',
    state: 'SC',
  },
  {
    county: 'MCCORMICK',
    state: 'SC',
  },
  {
    county: 'MARION',
    state: 'SC',
  },
  {
    county: 'MARLBORO',
    state: 'SC',
  },
  {
    county: 'NEWBERRY',
    state: 'SC',
  },
  {
    county: 'OCONEE',
    state: 'SC',
  },
  {
    county: 'ORANGEBURG',
    state: 'SC',
  },
  {
    county: 'PICKENS',
    state: 'SC',
  },
  {
    county: 'RICHLAND',
    state: 'SC',
  },
  {
    county: 'SALUDA',
    state: 'SC',
  },
  {
    county: 'SPARTANBURG',
    state: 'SC',
  },
  {
    county: 'SUMTER',
    state: 'SC',
  },
  {
    county: 'UNION',
    state: 'SC',
  },
  {
    county: 'WILLIAMSBURG',
    state: 'SC',
  },
  {
    county: 'YORK',
    state: 'SC',
  },
  {
    county: 'AURORA',
    state: 'SD',
  },
  {
    county: 'BEADLE',
    state: 'SD',
  },
  {
    county: 'BENNETT',
    state: 'SD',
  },
  {
    county: 'BON HOMME',
    state: 'SD',
  },
  {
    county: 'BROOKINGS',
    state: 'SD',
  },
  {
    county: 'BROWN',
    state: 'SD',
  },
  {
    county: 'BRULE',
    state: 'SD',
  },
  {
    county: 'BUFFALO',
    state: 'SD',
  },
  {
    county: 'BUTTE',
    state: 'SD',
  },
  {
    county: 'CAMPBELL',
    state: 'SD',
  },
  {
    county: 'CHARLES MIX',
    state: 'SD',
  },
  {
    county: 'CLARK',
    state: 'SD',
  },
  {
    county: 'CLAY',
    state: 'SD',
  },
  {
    county: 'CODINGTON',
    state: 'SD',
  },
  {
    county: 'CORSON',
    state: 'SD',
  },
  {
    county: 'CUSTER',
    state: 'SD',
  },
  {
    county: 'DAVISON',
    state: 'SD',
  },
  {
    county: 'DAY',
    state: 'SD',
  },
  {
    county: 'DEUEL',
    state: 'SD',
  },
  {
    county: 'DEWEY',
    state: 'SD',
  },
  {
    county: 'DOUGLAS',
    state: 'SD',
  },
  {
    county: 'EDMUNDS',
    state: 'SD',
  },
  {
    county: 'FALL RIVER',
    state: 'SD',
  },
  {
    county: 'FAULK',
    state: 'SD',
  },
  {
    county: 'GRANT',
    state: 'SD',
  },
  {
    county: 'GREGORY',
    state: 'SD',
  },
  {
    county: 'HAAKON',
    state: 'SD',
  },
  {
    county: 'HAMLIN',
    state: 'SD',
  },
  {
    county: 'HAND',
    state: 'SD',
  },
  {
    county: 'HANSON',
    state: 'SD',
  },
  {
    county: 'HARDING',
    state: 'SD',
  },
  {
    county: 'HUGHES',
    state: 'SD',
  },
  {
    county: 'HUTCHINSON',
    state: 'SD',
  },
  {
    county: 'HYDE',
    state: 'SD',
  },
  {
    county: 'JACKSON',
    state: 'SD',
  },
  {
    county: 'JERAULD',
    state: 'SD',
  },
  {
    county: 'JONES',
    state: 'SD',
  },
  {
    county: 'KINGSBURY',
    state: 'SD',
  },
  {
    county: 'LAKE',
    state: 'SD',
  },
  {
    county: 'LAWRENCE',
    state: 'SD',
  },
  {
    county: 'LINCOLN',
    state: 'SD',
  },
  {
    county: 'LYMAN',
    state: 'SD',
  },
  {
    county: 'MCCOOK',
    state: 'SD',
  },
  {
    county: 'MCPHERSON',
    state: 'SD',
  },
  {
    county: 'MARSHALL',
    state: 'SD',
  },
  {
    county: 'MEADE',
    state: 'SD',
  },
  {
    county: 'MELLETTE',
    state: 'SD',
  },
  {
    county: 'MINER',
    state: 'SD',
  },
  {
    county: 'MINNEHAHA',
    state: 'SD',
  },
  {
    county: 'MOODY',
    state: 'SD',
  },
  {
    county: 'PENNINGTON',
    state: 'SD',
  },
  {
    county: 'PERKINS',
    state: 'SD',
  },
  {
    county: 'POTTER',
    state: 'SD',
  },
  {
    county: 'ROBERTS',
    state: 'SD',
  },
  {
    county: 'SANBORN',
    state: 'SD',
  },
  {
    county: 'SHANNON',
    state: 'SD',
  },
  {
    county: 'SPINK',
    state: 'SD',
  },
  {
    county: 'STANLEY',
    state: 'SD',
  },
  {
    county: 'SULLY',
    state: 'SD',
  },
  {
    county: 'TODD',
    state: 'SD',
  },
  {
    county: 'TRIPP',
    state: 'SD',
  },
  {
    county: 'TURNER',
    state: 'SD',
  },
  {
    county: 'UNION',
    state: 'SD',
  },
  {
    county: 'WALWORTH',
    state: 'SD',
  },
  {
    county: 'YANKTON',
    state: 'SD',
  },
  {
    county: 'ZIEBACH',
    state: 'SD',
  },
  {
    county: 'ANDERSON',
    state: 'TN',
  },
  {
    county: 'BEDFORD',
    state: 'TN',
  },
  {
    county: 'BENTON',
    state: 'TN',
  },
  {
    county: 'BLEDSOE',
    state: 'TN',
  },
  {
    county: 'BLOUNT',
    state: 'TN',
  },
  {
    county: 'BRADLEY',
    state: 'TN',
  },
  {
    county: 'CAMPBELL',
    state: 'TN',
  },
  {
    county: 'CANNON',
    state: 'TN',
  },
  {
    county: 'CARROLL',
    state: 'TN',
  },
  {
    county: 'CARTER',
    state: 'TN',
  },
  {
    county: 'CHEATHAM',
    state: 'TN',
  },
  {
    county: 'CHESTER',
    state: 'TN',
  },
  {
    county: 'CLAIBORNE',
    state: 'TN',
  },
  {
    county: 'CLAY',
    state: 'TN',
  },
  {
    county: 'COCKE',
    state: 'TN',
  },
  {
    county: 'COFFEE',
    state: 'TN',
  },
  {
    county: 'CROCKETT',
    state: 'TN',
  },
  {
    county: 'CUMBERLAND',
    state: 'TN',
  },
  {
    county: 'DAVIDSON',
    state: 'TN',
  },
  {
    county: 'DECATUR',
    state: 'TN',
  },
  {
    county: 'DEKALB',
    state: 'TN',
  },
  {
    county: 'DICKSON',
    state: 'TN',
  },
  {
    county: 'DYER',
    state: 'TN',
  },
  {
    county: 'FAYETTE',
    state: 'TN',
  },
  {
    county: 'FENTRESS',
    state: 'TN',
  },
  {
    county: 'FRANKLIN',
    state: 'TN',
  },
  {
    county: 'GIBSON',
    state: 'TN',
  },
  {
    county: 'GILES',
    state: 'TN',
  },
  {
    county: 'GRAINGER',
    state: 'TN',
  },
  {
    county: 'GREENE',
    state: 'TN',
  },
  {
    county: 'GRUNDY',
    state: 'TN',
  },
  {
    county: 'HAMBLEN',
    state: 'TN',
  },
  {
    county: 'HAMILTON',
    state: 'TN',
  },
  {
    county: 'HANCOCK',
    state: 'TN',
  },
  {
    county: 'HARDEMAN',
    state: 'TN',
  },
  {
    county: 'HARDIN',
    state: 'TN',
  },
  {
    county: 'HAWKINS',
    state: 'TN',
  },
  {
    county: 'HAYWOOD',
    state: 'TN',
  },
  {
    county: 'HENDERSON',
    state: 'TN',
  },
  {
    county: 'HENRY',
    state: 'TN',
  },
  {
    county: 'HICKMAN',
    state: 'TN',
  },
  {
    county: 'HOUSTON',
    state: 'TN',
  },
  {
    county: 'HUMPHREYS',
    state: 'TN',
  },
  {
    county: 'JACKSON',
    state: 'TN',
  },
  {
    county: 'JEFFERSON',
    state: 'TN',
  },
  {
    county: 'JOHNSON',
    state: 'TN',
  },
  {
    county: 'KNOX',
    state: 'TN',
  },
  {
    county: 'LAKE',
    state: 'TN',
  },
  {
    county: 'LAUDERDALE',
    state: 'TN',
  },
  {
    county: 'LAWRENCE',
    state: 'TN',
  },
  {
    county: 'LEWIS',
    state: 'TN',
  },
  {
    county: 'LINCOLN',
    state: 'TN',
  },
  {
    county: 'LOUDON',
    state: 'TN',
  },
  {
    county: 'MCMINN',
    state: 'TN',
  },
  {
    county: 'MCNAIRY',
    state: 'TN',
  },
  {
    county: 'MACON',
    state: 'TN',
  },
  {
    county: 'MADISON',
    state: 'TN',
  },
  {
    county: 'MARION',
    state: 'TN',
  },
  {
    county: 'MARSHALL',
    state: 'TN',
  },
  {
    county: 'MAURY',
    state: 'TN',
  },
  {
    county: 'MEIGS',
    state: 'TN',
  },
  {
    county: 'MONROE',
    state: 'TN',
  },
  {
    county: 'MONTGOMERY',
    state: 'TN',
  },
  {
    county: 'MOORE',
    state: 'TN',
  },
  {
    county: 'MORGAN',
    state: 'TN',
  },
  {
    county: 'OBION',
    state: 'TN',
  },
  {
    county: 'OVERTON',
    state: 'TN',
  },
  {
    county: 'PERRY',
    state: 'TN',
  },
  {
    county: 'PICKETT',
    state: 'TN',
  },
  {
    county: 'POLK',
    state: 'TN',
  },
  {
    county: 'PUTNAM',
    state: 'TN',
  },
  {
    county: 'RHEA',
    state: 'TN',
  },
  {
    county: 'ROANE',
    state: 'TN',
  },
  {
    county: 'ROBERTSON',
    state: 'TN',
  },
  {
    county: 'RUTHERFORD',
    state: 'TN',
  },
  {
    county: 'SCOTT',
    state: 'TN',
  },
  {
    county: 'SEQUATCHIE',
    state: 'TN',
  },
  {
    county: 'SEVIER',
    state: 'TN',
  },
  {
    county: 'SHELBY',
    state: 'TN',
  },
  {
    county: 'SMITH',
    state: 'TN',
  },
  {
    county: 'STEWART',
    state: 'TN',
  },
  {
    county: 'SULLIVAN',
    state: 'TN',
  },
  {
    county: 'SUMNER',
    state: 'TN',
  },
  {
    county: 'TIPTON',
    state: 'TN',
  },
  {
    county: 'TROUSDALE',
    state: 'TN',
  },
  {
    county: 'UNICOI',
    state: 'TN',
  },
  {
    county: 'UNION',
    state: 'TN',
  },
  {
    county: 'VAN BUREN',
    state: 'TN',
  },
  {
    county: 'WARREN',
    state: 'TN',
  },
  {
    county: 'WASHINGTON',
    state: 'TN',
  },
  {
    county: 'WAYNE',
    state: 'TN',
  },
  {
    county: 'WEAKLEY',
    state: 'TN',
  },
  {
    county: 'WHITE',
    state: 'TN',
  },
  {
    county: 'WILLIAMSON',
    state: 'TN',
  },
  {
    county: 'WILSON',
    state: 'TN',
  },
  {
    county: 'ANDERSON',
    state: 'TX',
  },
  {
    county: 'ANDREWS',
    state: 'TX',
  },
  {
    county: 'ANGELINA',
    state: 'TX',
  },
  {
    county: 'ARANSAS',
    state: 'TX',
  },
  {
    county: 'ARCHER',
    state: 'TX',
  },
  {
    county: 'ARMSTRONG',
    state: 'TX',
  },
  {
    county: 'ATASCOSA',
    state: 'TX',
  },
  {
    county: 'AUSTIN',
    state: 'TX',
  },
  {
    county: 'BAILEY',
    state: 'TX',
  },
  {
    county: 'BANDERA',
    state: 'TX',
  },
  {
    county: 'BASTROP',
    state: 'TX',
  },
  {
    county: 'BAYLOR',
    state: 'TX',
  },
  {
    county: 'BEE',
    state: 'TX',
  },
  {
    county: 'BELL',
    state: 'TX',
  },
  {
    county: 'BEXAR',
    state: 'TX',
  },
  {
    county: 'BLANCO',
    state: 'TX',
  },
  {
    county: 'BORDEN',
    state: 'TX',
  },
  {
    county: 'BOSQUE',
    state: 'TX',
  },
  {
    county: 'BOWIE',
    state: 'TX',
  },
  {
    county: 'BRAZORIA',
    state: 'TX',
  },
  {
    county: 'BRAZOS',
    state: 'TX',
  },
  {
    county: 'BREWSTER',
    state: 'TX',
  },
  {
    county: 'BRISCOE',
    state: 'TX',
  },
  {
    county: 'BROOKS',
    state: 'TX',
  },
  {
    county: 'BROWN',
    state: 'TX',
  },
  {
    county: 'BURLESON',
    state: 'TX',
  },
  {
    county: 'BURNET',
    state: 'TX',
  },
  {
    county: 'CALDWELL',
    state: 'TX',
  },
  {
    county: 'CALHOUN',
    state: 'TX',
  },
  {
    county: 'CALLAHAN',
    state: 'TX',
  },
  {
    county: 'CAMERON',
    state: 'TX',
  },
  {
    county: 'CAMP',
    state: 'TX',
  },
  {
    county: 'CARSON',
    state: 'TX',
  },
  {
    county: 'CASS',
    state: 'TX',
  },
  {
    county: 'CASTRO',
    state: 'TX',
  },
  {
    county: 'CHAMBERS',
    state: 'TX',
  },
  {
    county: 'CHEROKEE',
    state: 'TX',
  },
  {
    county: 'CHILDRESS',
    state: 'TX',
  },
  {
    county: 'CLAY',
    state: 'TX',
  },
  {
    county: 'COCHRAN',
    state: 'TX',
  },
  {
    county: 'COKE',
    state: 'TX',
  },
  {
    county: 'COLEMAN',
    state: 'TX',
  },
  {
    county: 'COLLIN',
    state: 'TX',
  },
  {
    county: 'COLLINGSWORTH',
    state: 'TX',
  },
  {
    county: 'COLORADO',
    state: 'TX',
  },
  {
    county: 'COMAL',
    state: 'TX',
  },
  {
    county: 'COMANCHE',
    state: 'TX',
  },
  {
    county: 'CONCHO',
    state: 'TX',
  },
  {
    county: 'COOKE',
    state: 'TX',
  },
  {
    county: 'CORYELL',
    state: 'TX',
  },
  {
    county: 'COTTLE',
    state: 'TX',
  },
  {
    county: 'CRANE',
    state: 'TX',
  },
  {
    county: 'CROCKETT',
    state: 'TX',
  },
  {
    county: 'CROSBY',
    state: 'TX',
  },
  {
    county: 'CULBERSON',
    state: 'TX',
  },
  {
    county: 'DALLAM',
    state: 'TX',
  },
  {
    county: 'DALLAS',
    state: 'TX',
  },
  {
    county: 'DAWSON',
    state: 'TX',
  },
  {
    county: 'DEAF SMITH',
    state: 'TX',
  },
  {
    county: 'DELTA',
    state: 'TX',
  },
  {
    county: 'DENTON',
    state: 'TX',
  },
  {
    county: 'DEWITT',
    state: 'TX',
  },
  {
    county: 'DICKENS',
    state: 'TX',
  },
  {
    county: 'DIMMIT',
    state: 'TX',
  },
  {
    county: 'DONLEY',
    state: 'TX',
  },
  {
    county: 'DUVAL',
    state: 'TX',
  },
  {
    county: 'EASTLAND',
    state: 'TX',
  },
  {
    county: 'ECTOR',
    state: 'TX',
  },
  {
    county: 'EDWARDS',
    state: 'TX',
  },
  {
    county: 'ELLIS',
    state: 'TX',
  },
  {
    county: 'EL PASO',
    state: 'TX',
  },
  {
    county: 'ERATH',
    state: 'TX',
  },
  {
    county: 'FALLS',
    state: 'TX',
  },
  {
    county: 'FANNIN',
    state: 'TX',
  },
  {
    county: 'FAYETTE',
    state: 'TX',
  },
  {
    county: 'FISHER',
    state: 'TX',
  },
  {
    county: 'FLOYD',
    state: 'TX',
  },
  {
    county: 'FOARD',
    state: 'TX',
  },
  {
    county: 'FORT BEND',
    state: 'TX',
  },
  {
    county: 'FRANKLIN',
    state: 'TX',
  },
  {
    county: 'FREESTONE',
    state: 'TX',
  },
  {
    county: 'FRIO',
    state: 'TX',
  },
  {
    county: 'GAINES',
    state: 'TX',
  },
  {
    county: 'GALVESTON',
    state: 'TX',
  },
  {
    county: 'GARZA',
    state: 'TX',
  },
  {
    county: 'GILLESPIE',
    state: 'TX',
  },
  {
    county: 'GLASSCOCK',
    state: 'TX',
  },
  {
    county: 'GOLIAD',
    state: 'TX',
  },
  {
    county: 'GONZALES',
    state: 'TX',
  },
  {
    county: 'GRAY',
    state: 'TX',
  },
  {
    county: 'GRAYSON',
    state: 'TX',
  },
  {
    county: 'GREGG',
    state: 'TX',
  },
  {
    county: 'GRIMES',
    state: 'TX',
  },
  {
    county: 'GUADALUPE',
    state: 'TX',
  },
  {
    county: 'HALE',
    state: 'TX',
  },
  {
    county: 'HALL',
    state: 'TX',
  },
  {
    county: 'HAMILTON',
    state: 'TX',
  },
  {
    county: 'HANSFORD',
    state: 'TX',
  },
  {
    county: 'HARDEMAN',
    state: 'TX',
  },
  {
    county: 'HARDIN',
    state: 'TX',
  },
  {
    county: 'HARRIS',
    state: 'TX',
  },
  {
    county: 'HARRISON',
    state: 'TX',
  },
  {
    county: 'HARTLEY',
    state: 'TX',
  },
  {
    county: 'HASKELL',
    state: 'TX',
  },
  {
    county: 'HAYS',
    state: 'TX',
  },
  {
    county: 'HEMPHILL',
    state: 'TX',
  },
  {
    county: 'HENDERSON',
    state: 'TX',
  },
  {
    county: 'HIDALGO',
    state: 'TX',
  },
  {
    county: 'HILL',
    state: 'TX',
  },
  {
    county: 'HOCKLEY',
    state: 'TX',
  },
  {
    county: 'HOOD',
    state: 'TX',
  },
  {
    county: 'HOPKINS',
    state: 'TX',
  },
  {
    county: 'HOUSTON',
    state: 'TX',
  },
  {
    county: 'HOWARD',
    state: 'TX',
  },
  {
    county: 'HUDSPETH',
    state: 'TX',
  },
  {
    county: 'HUNT',
    state: 'TX',
  },
  {
    county: 'HUTCHINSON',
    state: 'TX',
  },
  {
    county: 'IRION',
    state: 'TX',
  },
  {
    county: 'JACK',
    state: 'TX',
  },
  {
    county: 'JACKSON',
    state: 'TX',
  },
  {
    county: 'JASPER',
    state: 'TX',
  },
  {
    county: 'JEFF DAVIS',
    state: 'TX',
  },
  {
    county: 'JEFFERSON',
    state: 'TX',
  },
  {
    county: 'JIM HOGG',
    state: 'TX',
  },
  {
    county: 'JIM WELLS',
    state: 'TX',
  },
  {
    county: 'JOHNSON',
    state: 'TX',
  },
  {
    county: 'JONES',
    state: 'TX',
  },
  {
    county: 'KARNES',
    state: 'TX',
  },
  {
    county: 'KAUFMAN',
    state: 'TX',
  },
  {
    county: 'KENDALL',
    state: 'TX',
  },
  {
    county: 'KENEDY',
    state: 'TX',
  },
  {
    county: 'KENT',
    state: 'TX',
  },
  {
    county: 'KERR',
    state: 'TX',
  },
  {
    county: 'KIMBLE',
    state: 'TX',
  },
  {
    county: 'KING',
    state: 'TX',
  },
  {
    county: 'KINNEY',
    state: 'TX',
  },
  {
    county: 'KLEBERG',
    state: 'TX',
  },
  {
    county: 'KNOX',
    state: 'TX',
  },
  {
    county: 'LAMAR',
    state: 'TX',
  },
  {
    county: 'LAMB',
    state: 'TX',
  },
  {
    county: 'LAMPASAS',
    state: 'TX',
  },
  {
    county: 'LA SALLE',
    state: 'TX',
  },
  {
    county: 'LAVACA',
    state: 'TX',
  },
  {
    county: 'LEE',
    state: 'TX',
  },
  {
    county: 'LEON',
    state: 'TX',
  },
  {
    county: 'LIBERTY',
    state: 'TX',
  },
  {
    county: 'LIMESTONE',
    state: 'TX',
  },
  {
    county: 'LIPSCOMB',
    state: 'TX',
  },
  {
    county: 'LIVE OAK',
    state: 'TX',
  },
  {
    county: 'LLANO',
    state: 'TX',
  },
  {
    county: 'LOVING',
    state: 'TX',
  },
  {
    county: 'LUBBOCK',
    state: 'TX',
  },
  {
    county: 'LYNN',
    state: 'TX',
  },
  {
    county: 'MCCULLOCH',
    state: 'TX',
  },
  {
    county: 'MCLENNAN',
    state: 'TX',
  },
  {
    county: 'MCMULLEN',
    state: 'TX',
  },
  {
    county: 'MADISON',
    state: 'TX',
  },
  {
    county: 'MARION',
    state: 'TX',
  },
  {
    county: 'MARTIN',
    state: 'TX',
  },
  {
    county: 'MASON',
    state: 'TX',
  },
  {
    county: 'MATAGORDA',
    state: 'TX',
  },
  {
    county: 'MAVERICK',
    state: 'TX',
  },
  {
    county: 'MEDINA',
    state: 'TX',
  },
  {
    county: 'MENARD',
    state: 'TX',
  },
  {
    county: 'MIDLAND',
    state: 'TX',
  },
  {
    county: 'MILAM',
    state: 'TX',
  },
  {
    county: 'MILLS',
    state: 'TX',
  },
  {
    county: 'MITCHELL',
    state: 'TX',
  },
  {
    county: 'MONTAGUE',
    state: 'TX',
  },
  {
    county: 'MONTGOMERY',
    state: 'TX',
  },
  {
    county: 'MOORE',
    state: 'TX',
  },
  {
    county: 'MORRIS',
    state: 'TX',
  },
  {
    county: 'MOTLEY',
    state: 'TX',
  },
  {
    county: 'NACOGDOCHES',
    state: 'TX',
  },
  {
    county: 'NAVARRO',
    state: 'TX',
  },
  {
    county: 'NEWTON',
    state: 'TX',
  },
  {
    county: 'NOLAN',
    state: 'TX',
  },
  {
    county: 'NUECES',
    state: 'TX',
  },
  {
    county: 'OCHILTREE',
    state: 'TX',
  },
  {
    county: 'OLDHAM',
    state: 'TX',
  },
  {
    county: 'ORANGE',
    state: 'TX',
  },
  {
    county: 'PALO PINTO',
    state: 'TX',
  },
  {
    county: 'PANOLA',
    state: 'TX',
  },
  {
    county: 'PARKER',
    state: 'TX',
  },
  {
    county: 'PARMER',
    state: 'TX',
  },
  {
    county: 'PECOS',
    state: 'TX',
  },
  {
    county: 'POLK',
    state: 'TX',
  },
  {
    county: 'POTTER',
    state: 'TX',
  },
  {
    county: 'PRESIDIO',
    state: 'TX',
  },
  {
    county: 'RAINS',
    state: 'TX',
  },
  {
    county: 'RANDALL',
    state: 'TX',
  },
  {
    county: 'REAGAN',
    state: 'TX',
  },
  {
    county: 'REAL',
    state: 'TX',
  },
  {
    county: 'RED RIVER',
    state: 'TX',
  },
  {
    county: 'REEVES',
    state: 'TX',
  },
  {
    county: 'REFUGIO',
    state: 'TX',
  },
  {
    county: 'ROBERTS',
    state: 'TX',
  },
  {
    county: 'ROBERTSON',
    state: 'TX',
  },
  {
    county: 'ROCKWALL',
    state: 'TX',
  },
  {
    county: 'RUNNELS',
    state: 'TX',
  },
  {
    county: 'RUSK',
    state: 'TX',
  },
  {
    county: 'SABINE',
    state: 'TX',
  },
  {
    county: 'SAN AUGUSTINE',
    state: 'TX',
  },
  {
    county: 'SAN JACINTO',
    state: 'TX',
  },
  {
    county: 'SAN PATRICIO',
    state: 'TX',
  },
  {
    county: 'SAN SABA',
    state: 'TX',
  },
  {
    county: 'SCHLEICHER',
    state: 'TX',
  },
  {
    county: 'SCURRY',
    state: 'TX',
  },
  {
    county: 'SHACKELFORD',
    state: 'TX',
  },
  {
    county: 'SHELBY',
    state: 'TX',
  },
  {
    county: 'SHERMAN',
    state: 'TX',
  },
  {
    county: 'SMITH',
    state: 'TX',
  },
  {
    county: 'SOMERVELL',
    state: 'TX',
  },
  {
    county: 'STARR',
    state: 'TX',
  },
  {
    county: 'STEPHENS',
    state: 'TX',
  },
  {
    county: 'STERLING',
    state: 'TX',
  },
  {
    county: 'STONEWALL',
    state: 'TX',
  },
  {
    county: 'SUTTON',
    state: 'TX',
  },
  {
    county: 'SWISHER',
    state: 'TX',
  },
  {
    county: 'TARRANT',
    state: 'TX',
  },
  {
    county: 'TAYLOR',
    state: 'TX',
  },
  {
    county: 'TERRELL',
    state: 'TX',
  },
  {
    county: 'TERRY',
    state: 'TX',
  },
  {
    county: 'THROCKMORTON',
    state: 'TX',
  },
  {
    county: 'TITUS',
    state: 'TX',
  },
  {
    county: 'TOM GREEN',
    state: 'TX',
  },
  {
    county: 'TRAVIS',
    state: 'TX',
  },
  {
    county: 'TRINITY',
    state: 'TX',
  },
  {
    county: 'TYLER',
    state: 'TX',
  },
  {
    county: 'UPSHUR',
    state: 'TX',
  },
  {
    county: 'UPTON',
    state: 'TX',
  },
  {
    county: 'UVALDE',
    state: 'TX',
  },
  {
    county: 'VAL VERDE',
    state: 'TX',
  },
  {
    county: 'VAN ZANDT',
    state: 'TX',
  },
  {
    county: 'VICTORIA',
    state: 'TX',
  },
  {
    county: 'WALKER',
    state: 'TX',
  },
  {
    county: 'WALLER',
    state: 'TX',
  },
  {
    county: 'WARD',
    state: 'TX',
  },
  {
    county: 'WASHINGTON',
    state: 'TX',
  },
  {
    county: 'WEBB',
    state: 'TX',
  },
  {
    county: 'WHARTON',
    state: 'TX',
  },
  {
    county: 'WHEELER',
    state: 'TX',
  },
  {
    county: 'WICHITA',
    state: 'TX',
  },
  {
    county: 'WILBARGER',
    state: 'TX',
  },
  {
    county: 'WILLACY',
    state: 'TX',
  },
  {
    county: 'WILLIAMSON',
    state: 'TX',
  },
  {
    county: 'WILSON',
    state: 'TX',
  },
  {
    county: 'WINKLER',
    state: 'TX',
  },
  {
    county: 'WISE',
    state: 'TX',
  },
  {
    county: 'WOOD',
    state: 'TX',
  },
  {
    county: 'YOAKUM',
    state: 'TX',
  },
  {
    county: 'YOUNG',
    state: 'TX',
  },
  {
    county: 'ZAPATA',
    state: 'TX',
  },
  {
    county: 'ZAVALA',
    state: 'TX',
  },
  {
    county: 'BEAVER',
    state: 'UT',
  },
  {
    county: 'BOX ELDER',
    state: 'UT',
  },
  {
    county: 'CACHE',
    state: 'UT',
  },
  {
    county: 'CARBON',
    state: 'UT',
  },
  {
    county: 'DAGGETT',
    state: 'UT',
  },
  {
    county: 'DAVIS',
    state: 'UT',
  },
  {
    county: 'DUCHESNE',
    state: 'UT',
  },
  {
    county: 'EMERY',
    state: 'UT',
  },
  {
    county: 'GARFIELD',
    state: 'UT',
  },
  {
    county: 'GRAND',
    state: 'UT',
  },
  {
    county: 'IRON',
    state: 'UT',
  },
  {
    county: 'JUAB',
    state: 'UT',
  },
  {
    county: 'KANE',
    state: 'UT',
  },
  {
    county: 'MILLARD',
    state: 'UT',
  },
  {
    county: 'MORGAN',
    state: 'UT',
  },
  {
    county: 'PIUTE',
    state: 'UT',
  },
  {
    county: 'RICH',
    state: 'UT',
  },
  {
    county: 'SALT LAKE',
    state: 'UT',
  },
  {
    county: 'SAN JUAN',
    state: 'UT',
  },
  {
    county: 'SANPETE',
    state: 'UT',
  },
  {
    county: 'SEVIER',
    state: 'UT',
  },
  {
    county: 'SUMMIT',
    state: 'UT',
  },
  {
    county: 'TOOELE',
    state: 'UT',
  },
  {
    county: 'UINTAH',
    state: 'UT',
  },
  {
    county: 'UTAH',
    state: 'UT',
  },
  {
    county: 'WASATCH',
    state: 'UT',
  },
  {
    county: 'WASHINGTON',
    state: 'UT',
  },
  {
    county: 'WAYNE',
    state: 'UT',
  },
  {
    county: 'WEBER',
    state: 'UT',
  },
  {
    county: 'ADDISON',
    state: 'VT',
  },
  {
    county: 'BENNINGTON',
    state: 'VT',
  },
  {
    county: 'CALEDONIA',
    state: 'VT',
  },
  {
    county: 'CHITTENDEN',
    state: 'VT',
  },
  {
    county: 'ESSEX',
    state: 'VT',
  },
  {
    county: 'FRANKLIN',
    state: 'VT',
  },
  {
    county: 'GRAND ISLE',
    state: 'VT',
  },
  {
    county: 'LAMOILLE',
    state: 'VT',
  },
  {
    county: 'ORANGE',
    state: 'VT',
  },
  {
    county: 'ORLEANS',
    state: 'VT',
  },
  {
    county: 'RUTLAND',
    state: 'VT',
  },
  {
    county: 'WASHINGTON',
    state: 'VT',
  },
  {
    county: 'WINDHAM',
    state: 'VT',
  },
  {
    county: 'WINDSOR',
    state: 'VT',
  },
  {
    county: 'ACCOMACK',
    state: 'VA',
  },
  {
    county: 'ALBEMARLE',
    state: 'VA',
  },
  {
    county: 'ALLEGHANY',
    state: 'VA',
  },
  {
    county: 'AMELIA',
    state: 'VA',
  },
  {
    county: 'AMHERST',
    state: 'VA',
  },
  {
    county: 'APPOMATTOX',
    state: 'VA',
  },
  {
    county: 'ARLINGTON',
    state: 'VA',
  },
  {
    county: 'AUGUSTA',
    state: 'VA',
  },
  {
    county: 'BATH',
    state: 'VA',
  },
  {
    county: 'BEDFORD',
    state: 'VA',
  },
  {
    county: 'BLAND',
    state: 'VA',
  },
  {
    county: 'BOTETOURT',
    state: 'VA',
  },
  {
    county: 'BRUNSWICK',
    state: 'VA',
  },
  {
    county: 'BUCHANAN',
    state: 'VA',
  },
  {
    county: 'BUCKINGHAM',
    state: 'VA',
  },
  {
    county: 'CAMPBELL',
    state: 'VA',
  },
  {
    county: 'CAROLINE',
    state: 'VA',
  },
  {
    county: 'CARROLL',
    state: 'VA',
  },
  {
    county: 'CHARLES CITY',
    state: 'VA',
  },
  {
    county: 'CHARLOTTE',
    state: 'VA',
  },
  {
    county: 'CHESTERFIELD',
    state: 'VA',
  },
  {
    county: 'CLARKE',
    state: 'VA',
  },
  {
    county: 'CRAIG',
    state: 'VA',
  },
  {
    county: 'CULPEPER',
    state: 'VA',
  },
  {
    county: 'CUMBERLAND',
    state: 'VA',
  },
  {
    county: 'DICKENSON',
    state: 'VA',
  },
  {
    county: 'DINWIDDIE',
    state: 'VA',
  },
  {
    county: 'ESSEX',
    state: 'VA',
  },
  {
    county: 'FAIRFAX',
    state: 'VA',
  },
  {
    county: 'FAUQUIER',
    state: 'VA',
  },
  {
    county: 'FLOYD',
    state: 'VA',
  },
  {
    county: 'FLUVANNA',
    state: 'VA',
  },
  {
    county: 'FRANKLIN',
    state: 'VA',
  },
  {
    county: 'FREDERICK',
    state: 'VA',
  },
  {
    county: 'GILES',
    state: 'VA',
  },
  {
    county: 'GLOUCESTER',
    state: 'VA',
  },
  {
    county: 'GOOCHLAND',
    state: 'VA',
  },
  {
    county: 'GRAYSON',
    state: 'VA',
  },
  {
    county: 'GREENE',
    state: 'VA',
  },
  {
    county: 'GREENSVILLE',
    state: 'VA',
  },
  {
    county: 'HALIFAX',
    state: 'VA',
  },
  {
    county: 'HANOVER',
    state: 'VA',
  },
  {
    county: 'HENRICO',
    state: 'VA',
  },
  {
    county: 'HENRY',
    state: 'VA',
  },
  {
    county: 'HIGHLAND',
    state: 'VA',
  },
  {
    county: 'ISLE OF WIGHT',
    state: 'VA',
  },
  {
    county: 'JAMES CITY',
    state: 'VA',
  },
  {
    county: 'KING AND QUEEN',
    state: 'VA',
  },
  {
    county: 'KING GEORGE',
    state: 'VA',
  },
  {
    county: 'KING WILLIAM',
    state: 'VA',
  },
  {
    county: 'LANCASTER',
    state: 'VA',
  },
  {
    county: 'LEE',
    state: 'VA',
  },
  {
    county: 'LOUDOUN',
    state: 'VA',
  },
  {
    county: 'LOUISA',
    state: 'VA',
  },
  {
    county: 'LUNENBURG',
    state: 'VA',
  },
  {
    county: 'MADISON',
    state: 'VA',
  },
  {
    county: 'MATHEWS',
    state: 'VA',
  },
  {
    county: 'MECKLENBURG',
    state: 'VA',
  },
  {
    county: 'MIDDLESEX',
    state: 'VA',
  },
  {
    county: 'MONTGOMERY',
    state: 'VA',
  },
  {
    county: 'NELSON',
    state: 'VA',
  },
  {
    county: 'NEW KENT',
    state: 'VA',
  },
  {
    county: 'NORTHAMPTON',
    state: 'VA',
  },
  {
    county: 'NORTHUMBERLAND',
    state: 'VA',
  },
  {
    county: 'NOTTOWAY',
    state: 'VA',
  },
  {
    county: 'ORANGE',
    state: 'VA',
  },
  {
    county: 'PAGE',
    state: 'VA',
  },
  {
    county: 'PATRICK',
    state: 'VA',
  },
  {
    county: 'PITTSYLVANIA',
    state: 'VA',
  },
  {
    county: 'POWHATAN',
    state: 'VA',
  },
  {
    county: 'PRINCE EDWARD',
    state: 'VA',
  },
  {
    county: 'PRINCE GEORGE',
    state: 'VA',
  },
  {
    county: 'PRINCE WILLIAM',
    state: 'VA',
  },
  {
    county: 'PULASKI',
    state: 'VA',
  },
  {
    county: 'RAPPAHANNOCK',
    state: 'VA',
  },
  {
    county: 'RICHMOND',
    state: 'VA',
  },
  {
    county: 'ROANOKE',
    state: 'VA',
  },
  {
    county: 'ROCKBRIDGE',
    state: 'VA',
  },
  {
    county: 'ROCKINGHAM',
    state: 'VA',
  },
  {
    county: 'RUSSELL',
    state: 'VA',
  },
  {
    county: 'SCOTT',
    state: 'VA',
  },
  {
    county: 'SHENANDOAH',
    state: 'VA',
  },
  {
    county: 'SMYTH',
    state: 'VA',
  },
  {
    county: 'SOUTHAMPTON',
    state: 'VA',
  },
  {
    county: 'SPOTSYLVANIA',
    state: 'VA',
  },
  {
    county: 'STAFFORD',
    state: 'VA',
  },
  {
    county: 'SURRY',
    state: 'VA',
  },
  {
    county: 'SUSSEX',
    state: 'VA',
  },
  {
    county: 'TAZEWELL',
    state: 'VA',
  },
  {
    county: 'WARREN',
    state: 'VA',
  },
  {
    county: 'WASHINGTON',
    state: 'VA',
  },
  {
    county: 'WESTMORELAND',
    state: 'VA',
  },
  {
    county: 'WISE',
    state: 'VA',
  },
  {
    county: 'WYTHE',
    state: 'VA',
  },
  {
    county: 'YORK',
    state: 'VA',
  },
  {
    county: 'ALEXANDRIA',
    state: 'VA',
  },
  {
    county: 'BEDFORD',
    state: 'VA',
  },
  {
    county: 'BRISTOL',
    state: 'VA',
  },
  {
    county: 'BUENA VISTA',
    state: 'VA',
  },
  {
    county: 'CHARLOTTESVILLE',
    state: 'VA',
  },
  {
    county: 'CHESAPEAKE',
    state: 'VA',
  },
  {
    county: 'COLONIAL HEIGHTS',
    state: 'VA',
  },
  {
    county: 'COVINGTON',
    state: 'VA',
  },
  {
    county: 'DANVILLE',
    state: 'VA',
  },
  {
    county: 'EMPORIA',
    state: 'VA',
  },
  {
    county: 'FAIRFAX',
    state: 'VA',
  },
  {
    county: 'FALLS CHURCH',
    state: 'VA',
  },
  {
    county: 'FRANKLIN',
    state: 'VA',
  },
  {
    county: 'FREDERICKSBURG',
    state: 'VA',
  },
  {
    county: 'GALAX',
    state: 'VA',
  },
  {
    county: 'HAMPTON',
    state: 'VA',
  },
  {
    county: 'HARRISONBURG',
    state: 'VA',
  },
  {
    county: 'HOPEWELL',
    state: 'VA',
  },
  {
    county: 'LEXINGTON',
    state: 'VA',
  },
  {
    county: 'LYNCHBURG',
    state: 'VA',
  },
  {
    county: 'MANASSAS',
    state: 'VA',
  },
  {
    county: 'MANASSAS PARK',
    state: 'VA',
  },
  {
    county: 'MARTINSVILLE',
    state: 'VA',
  },
  {
    county: 'NEWPORT NEWS',
    state: 'VA',
  },
  {
    county: 'NORFOLK',
    state: 'VA',
  },
  {
    county: 'NORTON',
    state: 'VA',
  },
  {
    county: 'PETERSBURG',
    state: 'VA',
  },
  {
    county: 'POQUOSON',
    state: 'VA',
  },
  {
    county: 'PORTSMOUTH',
    state: 'VA',
  },
  {
    county: 'RADFORD',
    state: 'VA',
  },
  {
    county: 'RICHMOND',
    state: 'VA',
  },
  {
    county: 'ROANOKE',
    state: 'VA',
  },
  {
    county: 'SALEM',
    state: 'VA',
  },
  {
    county: 'STAUNTON',
    state: 'VA',
  },
  {
    county: 'SUFFOLK',
    state: 'VA',
  },
  {
    county: 'VIRGINIA BEACH',
    state: 'VA',
  },
  {
    county: 'WAYNESBORO',
    state: 'VA',
  },
  {
    county: 'WILLIAMSBURG',
    state: 'VA',
  },
  {
    county: 'WINCHESTER',
    state: 'VA',
  },
  {
    county: 'ADAMS',
    state: 'WA',
  },
  {
    county: 'ASOTIN',
    state: 'WA',
  },
  {
    county: 'BENTON',
    state: 'WA',
  },
  {
    county: 'CHELAN',
    state: 'WA',
  },
  {
    county: 'CLALLAM',
    state: 'WA',
  },
  {
    county: 'CLARK',
    state: 'WA',
  },
  {
    county: 'COLUMBIA',
    state: 'WA',
  },
  {
    county: 'COWLITZ',
    state: 'WA',
  },
  {
    county: 'DOUGLAS',
    state: 'WA',
  },
  {
    county: 'FERRY',
    state: 'WA',
  },
  {
    county: 'FRANKLIN',
    state: 'WA',
  },
  {
    county: 'GARFIELD',
    state: 'WA',
  },
  {
    county: 'GRANT',
    state: 'WA',
  },
  {
    county: 'GRAYS HARBOR',
    state: 'WA',
  },
  {
    county: 'ISLAND',
    state: 'WA',
  },
  {
    county: 'JEFFERSON',
    state: 'WA',
  },
  {
    county: 'KING',
    state: 'WA',
  },
  {
    county: 'KITSAP',
    state: 'WA',
  },
  {
    county: 'KITTITAS',
    state: 'WA',
  },
  {
    county: 'KLICKITAT',
    state: 'WA',
  },
  {
    county: 'LEWIS',
    state: 'WA',
  },
  {
    county: 'LINCOLN',
    state: 'WA',
  },
  {
    county: 'MASON',
    state: 'WA',
  },
  {
    county: 'OKANOGAN',
    state: 'WA',
  },
  {
    county: 'PACIFIC',
    state: 'WA',
  },
  {
    county: 'PEND OREILLE',
    state: 'WA',
  },
  {
    county: 'PIERCE',
    state: 'WA',
  },
  {
    county: 'SAN JUAN',
    state: 'WA',
  },
  {
    county: 'SKAGIT',
    state: 'WA',
  },
  {
    county: 'SKAMANIA',
    state: 'WA',
  },
  {
    county: 'SNOHOMISH',
    state: 'WA',
  },
  {
    county: 'SPOKANE',
    state: 'WA',
  },
  {
    county: 'STEVENS',
    state: 'WA',
  },
  {
    county: 'THURSTON',
    state: 'WA',
  },
  {
    county: 'WAHKIAKUM',
    state: 'WA',
  },
  {
    county: 'WALLA WALLA',
    state: 'WA',
  },
  {
    county: 'WHATCOM',
    state: 'WA',
  },
  {
    county: 'WHITMAN',
    state: 'WA',
  },
  {
    county: 'YAKIMA',
    state: 'WA',
  },
  {
    county: 'BARBOUR',
    state: 'WV',
  },
  {
    county: 'BERKELEY',
    state: 'WV',
  },
  {
    county: 'BOONE',
    state: 'WV',
  },
  {
    county: 'BRAXTON',
    state: 'WV',
  },
  {
    county: 'BROOKE',
    state: 'WV',
  },
  {
    county: 'CABELL',
    state: 'WV',
  },
  {
    county: 'CALHOUN',
    state: 'WV',
  },
  {
    county: 'CLAY',
    state: 'WV',
  },
  {
    county: 'DODDRIDGE',
    state: 'WV',
  },
  {
    county: 'FAYETTE',
    state: 'WV',
  },
  {
    county: 'GILMER',
    state: 'WV',
  },
  {
    county: 'GRANT',
    state: 'WV',
  },
  {
    county: 'GREENBRIER',
    state: 'WV',
  },
  {
    county: 'HAMPSHIRE',
    state: 'WV',
  },
  {
    county: 'HANCOCK',
    state: 'WV',
  },
  {
    county: 'HARDY',
    state: 'WV',
  },
  {
    county: 'HARRISON',
    state: 'WV',
  },
  {
    county: 'JACKSON',
    state: 'WV',
  },
  {
    county: 'JEFFERSON',
    state: 'WV',
  },
  {
    county: 'KANAWHA',
    state: 'WV',
  },
  {
    county: 'LEWIS',
    state: 'WV',
  },
  {
    county: 'LINCOLN',
    state: 'WV',
  },
  {
    county: 'LOGAN',
    state: 'WV',
  },
  {
    county: 'MCDOWELL',
    state: 'WV',
  },
  {
    county: 'MARION',
    state: 'WV',
  },
  {
    county: 'MARSHALL',
    state: 'WV',
  },
  {
    county: 'MASON',
    state: 'WV',
  },
  {
    county: 'MERCER',
    state: 'WV',
  },
  {
    county: 'MINERAL',
    state: 'WV',
  },
  {
    county: 'MINGO',
    state: 'WV',
  },
  {
    county: 'MONONGALIA',
    state: 'WV',
  },
  {
    county: 'MONROE',
    state: 'WV',
  },
  {
    county: 'MORGAN',
    state: 'WV',
  },
  {
    county: 'NICHOLAS',
    state: 'WV',
  },
  {
    county: 'OHIO',
    state: 'WV',
  },
  {
    county: 'PENDLETON',
    state: 'WV',
  },
  {
    county: 'PLEASANTS',
    state: 'WV',
  },
  {
    county: 'POCAHONTAS',
    state: 'WV',
  },
  {
    county: 'PRESTON',
    state: 'WV',
  },
  {
    county: 'PUTNAM',
    state: 'WV',
  },
  {
    county: 'RALEIGH',
    state: 'WV',
  },
  {
    county: 'RANDOLPH',
    state: 'WV',
  },
  {
    county: 'RITCHIE',
    state: 'WV',
  },
  {
    county: 'ROANE',
    state: 'WV',
  },
  {
    county: 'SUMMERS',
    state: 'WV',
  },
  {
    county: 'TAYLOR',
    state: 'WV',
  },
  {
    county: 'TUCKER',
    state: 'WV',
  },
  {
    county: 'TYLER',
    state: 'WV',
  },
  {
    county: 'UPSHUR',
    state: 'WV',
  },
  {
    county: 'WAYNE',
    state: 'WV',
  },
  {
    county: 'WEBSTER',
    state: 'WV',
  },
  {
    county: 'WETZEL',
    state: 'WV',
  },
  {
    county: 'WIRT',
    state: 'WV',
  },
  {
    county: 'WOOD',
    state: 'WV',
  },
  {
    county: 'WYOMING',
    state: 'WV',
  },
  {
    county: 'ADAMS',
    state: 'WI',
  },
  {
    county: 'ASHLAND',
    state: 'WI',
  },
  {
    county: 'BARRON',
    state: 'WI',
  },
  {
    county: 'BAYFIELD',
    state: 'WI',
  },
  {
    county: 'BROWN',
    state: 'WI',
  },
  {
    county: 'BUFFALO',
    state: 'WI',
  },
  {
    county: 'BURNETT',
    state: 'WI',
  },
  {
    county: 'CALUMET',
    state: 'WI',
  },
  {
    county: 'CHIPPEWA',
    state: 'WI',
  },
  {
    county: 'CLARK',
    state: 'WI',
  },
  {
    county: 'COLUMBIA',
    state: 'WI',
  },
  {
    county: 'CRAWFORD',
    state: 'WI',
  },
  {
    county: 'DANE',
    state: 'WI',
  },
  {
    county: 'DODGE',
    state: 'WI',
  },
  {
    county: 'DOOR',
    state: 'WI',
  },
  {
    county: 'DOUGLAS',
    state: 'WI',
  },
  {
    county: 'DUNN',
    state: 'WI',
  },
  {
    county: 'EAU CLAIRE',
    state: 'WI',
  },
  {
    county: 'FLORENCE',
    state: 'WI',
  },
  {
    county: 'FOND DU LAC',
    state: 'WI',
  },
  {
    county: 'FOREST',
    state: 'WI',
  },
  {
    county: 'GRANT',
    state: 'WI',
  },
  {
    county: 'GREEN',
    state: 'WI',
  },
  {
    county: 'GREEN LAKE',
    state: 'WI',
  },
  {
    county: 'IOWA',
    state: 'WI',
  },
  {
    county: 'IRON',
    state: 'WI',
  },
  {
    county: 'JACKSON',
    state: 'WI',
  },
  {
    county: 'JEFFERSON',
    state: 'WI',
  },
  {
    county: 'JUNEAU',
    state: 'WI',
  },
  {
    county: 'KENOSHA',
    state: 'WI',
  },
  {
    county: 'KEWAUNEE',
    state: 'WI',
  },
  {
    county: 'LA CROSSE',
    state: 'WI',
  },
  {
    county: 'LAFAYETTE',
    state: 'WI',
  },
  {
    county: 'LANGLADE',
    state: 'WI',
  },
  {
    county: 'LINCOLN',
    state: 'WI',
  },
  {
    county: 'MANITOWOC',
    state: 'WI',
  },
  {
    county: 'MARATHON',
    state: 'WI',
  },
  {
    county: 'MARINETTE',
    state: 'WI',
  },
  {
    county: 'MARQUETTE',
    state: 'WI',
  },
  {
    county: 'MENOMINEE',
    state: 'WI',
  },
  {
    county: 'MILWAUKEE',
    state: 'WI',
  },
  {
    county: 'MONROE',
    state: 'WI',
  },
  {
    county: 'OCONTO',
    state: 'WI',
  },
  {
    county: 'ONEIDA',
    state: 'WI',
  },
  {
    county: 'OUTAGAMIE',
    state: 'WI',
  },
  {
    county: 'OZAUKEE',
    state: 'WI',
  },
  {
    county: 'PEPIN',
    state: 'WI',
  },
  {
    county: 'PIERCE',
    state: 'WI',
  },
  {
    county: 'POLK',
    state: 'WI',
  },
  {
    county: 'PORTAGE',
    state: 'WI',
  },
  {
    county: 'PRICE',
    state: 'WI',
  },
  {
    county: 'RACINE',
    state: 'WI',
  },
  {
    county: 'RICHLAND',
    state: 'WI',
  },
  {
    county: 'ROCK',
    state: 'WI',
  },
  {
    county: 'RUSK',
    state: 'WI',
  },
  {
    county: 'ST CROIX',
    state: 'WI',
  },
  {
    county: 'SAUK',
    state: 'WI',
  },
  {
    county: 'SAWYER',
    state: 'WI',
  },
  {
    county: 'SHAWANO',
    state: 'WI',
  },
  {
    county: 'SHEBOYGAN',
    state: 'WI',
  },
  {
    county: 'TAYLOR',
    state: 'WI',
  },
  {
    county: 'TREMPEALEAU',
    state: 'WI',
  },
  {
    county: 'VERNON',
    state: 'WI',
  },
  {
    county: 'VILAS',
    state: 'WI',
  },
  {
    county: 'WALWORTH',
    state: 'WI',
  },
  {
    county: 'WASHBURN',
    state: 'WI',
  },
  {
    county: 'WASHINGTON',
    state: 'WI',
  },
  {
    county: 'WAUKESHA',
    state: 'WI',
  },
  {
    county: 'WAUPACA',
    state: 'WI',
  },
  {
    county: 'WAUSHARA',
    state: 'WI',
  },
  {
    county: 'WINNEBAGO',
    state: 'WI',
  },
  {
    county: 'WOOD',
    state: 'WI',
  },
  {
    county: 'ALBANY',
    state: 'WY',
  },
  {
    county: 'BIG HORN',
    state: 'WY',
  },
  {
    county: 'CAMPBELL',
    state: 'WY',
  },
  {
    county: 'CARBON',
    state: 'WY',
  },
  {
    county: 'CONVERSE',
    state: 'WY',
  },
  {
    county: 'CROOK',
    state: 'WY',
  },
  {
    county: 'FREMONT',
    state: 'WY',
  },
  {
    county: 'GOSHEN',
    state: 'WY',
  },
  {
    county: 'HOT SPRINGS',
    state: 'WY',
  },
  {
    county: 'JOHNSON',
    state: 'WY',
  },
  {
    county: 'LARAMIE',
    state: 'WY',
  },
  {
    county: 'LINCOLN',
    state: 'WY',
  },
  {
    county: 'NATRONA',
    state: 'WY',
  },
  {
    county: 'NIOBRARA',
    state: 'WY',
  },
  {
    county: 'PARK',
    state: 'WY',
  },
  {
    county: 'PLATTE',
    state: 'WY',
  },
  {
    county: 'SHERIDAN',
    state: 'WY',
  },
  {
    county: 'SUBLETTE',
    state: 'WY',
  },
  {
    county: 'SWEETWATER',
    state: 'WY',
  },
  {
    county: 'TETON',
    state: 'WY',
  },
  {
    county: 'UINTA',
    state: 'WY',
  },
  {
    county: 'WASHAKIE',
    state: 'WY',
  },
  {
    county: 'WESTON',
    state: 'WY',
  },
];
