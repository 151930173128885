import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  Typography,
  withStyles,
} from '@material-ui/core';
import { SetterOrUpdater } from 'recoil';

import { black, primaryBlue } from '../../../../theme';
import { useLeadsStyles } from '../Leads.styles';

const StyledCheckbox = withStyles({
  root: {
    color: black,
    '&$checked': {
      color: black,
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

interface IProps {
  setIsOpen: (bool: boolean) => void;
  isOpen: boolean;
  handleSavedFilterName: (isPublicChecked: boolean) => Promise<void>;
  setSavedFilterValue: SetterOrUpdater<string>;
  savedFilterValue: string;
}

const SaveFilterModal: React.FC<IProps> = ({
  setIsOpen,
  isOpen,
  handleSavedFilterName,
  setSavedFilterValue,
  savedFilterValue,
}: IProps) => {
  const classes = useLeadsStyles();
  const [isPublicChecked, setIsPublicChecked] = React.useState<boolean>(false);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      BackdropProps={{
        style: {
          background: 'rgba(49, 49, 49, 0.5)',
        },
      }}
    >
      <DialogContent style={{ paddingTop: '.5rem' }}>
        <FormGroup>
          <FormControl>
            <Typography style={{ color: primaryBlue, fontWeight: 'bold' }}>
              New Filter Name
            </Typography>
            <Input
              value={savedFilterValue}
              onChange={evt => setSavedFilterValue(evt.currentTarget.value)}
              id={`saved-filter-value-input-display`}
              onKeyPress={ev => ev.key === 'Enter' && handleSavedFilterName(isPublicChecked)}
              className={classes.filterNameInput}
              style={{ width: '15rem' }}
              disableUnderline
            />
          </FormControl>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={isPublicChecked}
                onChange={() => setIsPublicChecked(!isPublicChecked)}
              />
            }
            label="Make Public?"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '.75rem' }}>
        <Button
          onClick={() => handleSavedFilterName(isPublicChecked)}
          className={classes.saveModalButton}
        >
          Save
        </Button>
        <Button onClick={() => setIsOpen(false)} className={classes.cancelModalButton}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFilterModal;
