import {
  IPropertyResponse,
  IStakeholderResponse,
  Stakeholder,
  Property,
  IOrganizationResponse,
} from '.';

import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';
import Keyword, { IKeywordReponse } from './keyword';
import Organization from './organization';

import { toSentence } from '../utils/string';

export type IEventResponse = IBaseModelResponse & {
  score: number;
  external_id: string;
  source: string;
  type: string;
  description: string;
  event_date: string;
  payload: Record<string, unknown>;
  stakeholders?: IStakeholderResponse[];
  organizations?: IOrganizationResponse[];
  property?: IPropertyResponse;
  dodge_id?: string;
  dodge_version_number?: string;
  project_leads_id?: string;
  stage?: string;
  keywords?: IKeywordReponse[];
  incremental_flag?: boolean;
  incremental_reason?: string;
  project_id?: string;
};
/**
 * Event Type for Permit, Survey and Violation
 */

export default class Event extends BaseModel {
  score: number;
  externalId: string;
  sourceLabel: string;
  type: string;
  description: string;
  eventDate: Date;
  payload: Record<string, unknown>;
  property?: Property;
  stakeholders?: Stakeholder[];
  organizations?: Organization[];
  dodgeId?: string;
  dodgeVersionNumber?: string;
  projectLeadsId?: string;
  stage?: string;
  keywords?: Keyword[];
  incrementalFlag = false;
  incrementalReason?: string;
  projectId?: string;
  constructor(data: IEventResponse) {
    super(data);

    const {
      score,
      external_id,
      source,
      type,
      description,
      event_date,
      payload,
      property,
      stakeholders,
      organizations,
      dodge_id,
      dodge_version_number,
      project_leads_id,
      stage,
      keywords,
      incremental_flag,
      incremental_reason,
      project_id,
    } = data;

    this.score = score;
    this.externalId = external_id;
    this.sourceLabel = source;
    this.type = type;
    this.description = toSentence(description);
    this.eventDate = new Date(event_date);
    this.payload = payload;
    this.property = property && new Property(property);
    this.stakeholders =
      stakeholders && stakeholders.map(stakeholder => new Stakeholder(stakeholder));
    this.organizations =
      organizations && organizations.map(organization => new Organization(organization));
    this.dodgeId = dodge_id;
    this.dodgeVersionNumber = dodge_version_number;
    this.projectLeadsId = project_leads_id;
    this.stage = stage;
    this.keywords = keywords && keywords.map(keyword => new Keyword(keyword));
    this.incrementalFlag = incremental_flag || false;
    this.incrementalReason = incremental_reason;
    this.projectId = project_id;
  }
  get isDodgeEvent(): boolean {
    return !!this.dodgeId;
  }
}
