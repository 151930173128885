import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Box, Button, Grid, Popover } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import NotificationsList from './NotificationsList';

import notificationsIcon from '../../../../resources/images/notifications-icon.svg';
import { primaryBlue, white } from '../../../../theme';
import { getNotificationsCount, markNotificationsAsRead } from '../../../../api/notifications';

const useStyles = makeStyles(() => ({
  button: {
    color: white,
    textTransform: 'none',
    backgroundColor: primaryBlue,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: primaryBlue,
    },
  },
  badge: {
    color: white,
  },
  popover: {
    '& div.MuiPopover-paper': { overflowY: 'hidden' },
  },
}));

const Notifications: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notificationIds, setNotificationIds] = useState<string[]>([]);

  const loadNotificationsCount = useCallback(async () => {
    const counts = await getNotificationsCount();
    setNotificationCount(counts.newNotifications);
  }, [setNotificationCount]);

  const markNotificationsRead = useCallback(
    async (ids: string[]) => {
      await markNotificationsAsRead(ids);
      setNotificationCount(0);
    },
    [setNotificationCount],
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    await markNotificationsRead(notificationIds);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();

  useEffect(() => {
    loadNotificationsCount();
  }, [loadNotificationsCount]);

  return (
    <div>
      <Button
        aria-describedby={id}
        variant={'contained'}
        className={classes.button}
        onClick={handleClick}
        disableRipple
      >
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item>
            <Badge
              className={classes.badge}
              badgeContent={notificationCount}
              color={'error'}
              max={99}
              showZero={false}
              overlap={'circle'}
            >
              <img src={notificationsIcon} alt="Notifications" height={30} />
            </Badge>
          </Grid>
          <Box ml={2}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
        </Grid>
      </Button>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationsList
          count={notificationCount}
          setNotificationCount={setNotificationCount}
          setNotificationIds={setNotificationIds}
        />
      </Popover>
    </div>
  );
};

export default Notifications;
