import React from 'react';

interface IProps {
  titles: string[];
}

const TooltipTitleSpan: React.FC<IProps> = ({ titles }: IProps): JSX.Element => {
  const jsxElement = titles.map((name, index) => (
    <span key={index}>
      {!!index && <br />}
      {name}
    </span>
  ));

  return <>{jsxElement}</>;
};

export default TooltipTitleSpan;
