import { makeStyles } from '@material-ui/core/styles';

import { black, primary, secondary } from '../../../theme';

const useAtAGlanceStyles = makeStyles({
  map: {
    position: 'absolute',
    height: 'calc(100vh - 64px)',
    width: '100%',
    zIndex: 0,
  },
  mapContainer: {
    height: 'calc(100vh - 64px)',
    width: '100vw',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: 0,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
  },
  // Stats and Dropdown grouped together
  group1: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stats: {
    paddingTop: '1rem',
    justifyContent: 'space-around',
  },
  dropDown: {
    alignSelf: 'center',
    width: '30rem',
    border: `1px solid ${primary}`,
    backgroundColor: 'white',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    opacity: '85%',
  },
  zoomContainer: {
    zIndex: 1,
    position: 'absolute',
    bottom: '5vh',
    left: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  zoomInOut: {
    color: black,
    backgroundColor: secondary,
    margin: '.1rem',
    padding: '.5rem',
    cursor: 'pointer',
    borderRadius: '4px',
    width: '2.5rem',
    height: '2.5rem',
  },
  drawer: {
    backgroundColor: 'white',
    height: 'calc(100vh - 64px)',
    width: '314px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${primary}`,
  },
});

export default useAtAGlanceStyles;
