import { makeStyles } from '@material-ui/core';

const useContactsAccountsContainerStyles = makeStyles(theme => ({
  contactsAccountsTabs: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '& .MuiTabs-root': {
      minHeight: 'inherit',
    },
    '& button': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      border: '1px solid #59C9BC',
      borderBottom: 'none',
      marginLeft: '0.5rem',
      borderRadius: '4px !important',
      minHeight: 'inherit',
      fontSize: '1rem',
    },
    '& button:hover': {
      border: '1px solid #59C9BC',
      borderBottom: 'none',
      backgroundColor: '#59C9BC',
      color: theme.palette.primary.contrastText,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    '& button.Mui-selected': {
      backgroundColor: '#59C9BC',
      color: theme.palette.primary.contrastText,
    },
    '& button span.MuiTab-wrapper': {
      border: 'none',
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: '#59C9BC',
    },
  },

  contactsContainer: {
    border: '1px solid rgb(31, 180, 177)',
    height: '18rem',
    width: '25rem',
  },
  contactsHeaderContainer: {
    borderBottom: '1px solid rgb(31, 180, 177)',
    height: '15%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactsTitle: {
    color: '#9E9797',
    fontWeight: 'bold',
    paddingLeft: '1rem',
  },
  contactsAddButton: {
    fontSize: '1rem',
    color: '#9E9797',
    padding: '0',
  },
  contactsList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    height: '85%',
    flexWrap: 'nowrap',
    overflow: 'scroll',
  },
  card: {
    paddingBottom: '0.5rem',
  },
  emptyContactListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '85%',
    overflow: 'auto',
    padding: '0',
  },
  emptyContactsList: {
    borderRadius: '0',
    height: '8rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: 'rgb(31, 180, 177)',
  },
}));

export default useContactsAccountsContainerStyles;
