// import { SUB_ACTION_STAGES_MAP } from '../../../../../constants';
import { Comparators, FilterKey, FilterOptionsItem, TagsDisplay } from '../../../../../types';
import { stateAbbreviations } from '../../../../../utils/stateAbbreviations';

interface IHandleSelectStateCountyStateProps {
  countyStateIn: string[];
  countyFilterTagsToAdd: TagsDisplay[];
  countiesInEachState: Record<string, FilterOptionsItem[]>;
  filterItemOption: FilterOptionsItem;
}

/**
 * Function helps create a list of counties to add/remove from display tags AND list to select/deselect for filters
 * @param countyStateIn - list of counties in the state
 * @param countyFilterTagsToAdd - Tags Display of the counties inside the state
 * @param countiesInEachState - recoil value that contains all the mapping of the counties (FilterOptionsItem) in each state (string)
 * @param filterItemOption - FilterItemOption that just clicked (state)
 */
export const updateCountiesAssociatedWithState = ({
  countyStateIn,
  countyFilterTagsToAdd,
  countiesInEachState,
  filterItemOption,
}: IHandleSelectStateCountyStateProps) => {
  countiesInEachState[stateAbbreviations[filterItemOption.value as string]].forEach(option => {
    const filterValue = option.value as string;
    const filterLabel = option.label as string;
    countyStateIn.push(filterValue);
    countyFilterTagsToAdd.push({
      label: 'County',
      key: FilterKey.CountyState,
      value: filterLabel,
      comparator: Comparators.In,
      filterValue: filterValue,
      isInput: false,
    });
  });
};

/**
 * Function is expecting to receive a countyState filter value (string)
 * in the form of `COUNTY - ST`.
 * Returns the abbreviated state
 * */
export const stateAssociatedWithCountyState = ({
  countyStateValue,
}: {
  countyStateValue: string;
}) => {
  const stateAbb = countyStateValue?.split('-')[1].trim();
  return stateAbb;
};

/**
 * Function is expecting to receive a subActionStage filter value (string)
 * in the form of `SUBBIDS:ASAP - BIDDING`.
 * Returns the actionStage
 * */
export const actionStagesAssociatedWithSubActionStages = ({
  subActionStageValue,
}: {
  subActionStageValue: string;
}) => {
  const actionStage = subActionStageValue?.split('-')[1].trim();
  return actionStage;
};

interface IHandleSelectActionStageSubActionStageProps {
  subActionStageIn: string[];
  subActionStageFilterTagsToAdd: TagsDisplay[];
  subActionStageInEachActionStage: Record<string, FilterOptionsItem[]>;
  filterItemOption: FilterOptionsItem;
}

/**
 * Function helps create a list of counties to add/remove from display tags AND list to select/deselect for filters
 * @param subActionStageIn - list of counties in the state
 * @param subActionStageFilterTagsToAdd - Tags Display of the counties inside the state
 * @param subActionStageInEachActionStage - recoil value that contains all the mapping of the counties (FilterOptionsItem) in each state (string)
 * @param filterItemOption - FilterItemOption that just clicked (state)
 */
export const updateSubActionStagesAssociatedWithActionStages = ({
  subActionStageIn,
  subActionStageFilterTagsToAdd,
  subActionStageInEachActionStage,
  filterItemOption,
}: IHandleSelectActionStageSubActionStageProps) => {
  subActionStageInEachActionStage[filterItemOption.value as string].forEach(option => {
    const filterValue = option.value as string;
    const filterLabel = option.label as string;
    subActionStageIn.push(filterValue);
    subActionStageFilterTagsToAdd.push({
      label: 'Sub Action Stage',
      key: FilterKey.SubActionStage,
      value: filterLabel,
      comparator: Comparators.In,
      filterValue: filterValue,
      isInput: false,
    });
  });
};
