import API from './api';

interface NotificationsCount {
  newNotifications: number;
  totalNotification: number;
}

interface TransitionDetails {
  reason?: string;
  note?: string;
  value?: string;
  transition_date?: string;
}

export interface DetailedNotification {
  property_id: string;
  project_id: string;
  title: string;
  project_leads_id: string;
  transition_details: TransitionDetails;
  sfdc_opportunity_id: string;
  id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  updated_spec_alerts?: string;
  updated_contacts?: string;
  viewed: boolean;
}

export interface NotificationList {
  project_id: string;
  user_id: string;
  updated_spec_alerts: string;
  updated_contacts: string;
  viewed: boolean;
}

export const getNotificationsCount = async (): Promise<NotificationsCount> => {
  const { data } = await API.get('/notifications');
  return data as NotificationsCount;
};

export const getNotificationsDetailed = async (): Promise<DetailedNotification[]> => {
  const { data } = await API.get('/notifications/detailed');
  return data as DetailedNotification[];
};

export const deleteNotifications = async (ids: string[]): Promise<DetailedNotification[]> => {
  const { data } = await API.delete('/notifications', { data: ids });
  return data as DetailedNotification[];
};

export const markNotificationsAsRead = async (ids: string[]): Promise<string[]> => {
  const { data } = await API.patch('/notifications', ids);
  return data as string[];
};
