import { HeadCell } from '../../Common/Tables/Table';
import {
  renderProperty,
  renderAccountIcons,
  renderTitleCase,
  renderFalsyValue,
} from '../Leads/LeadsTable/LeadTableRowRenderers';
import {
  IOrganizationResponse,
  IProjectResponse,
  IPropertyResponse,
  IStakeholderResponse,
} from '../../../models';

export type IDetailAccountResponse = IOrganizationResponse & {
  stakeholders: IStakeholderResponse[];
  projects: IProjectResponse[];
  properties: IPropertyResponse[];
  total_properties: number;
  total_contacts: number;
  total_projects: number;
};

export interface SearchAccount {
  id: string;
  name: string;
  address_1: string;
  city: string;
  state: string;
  zipcode: string;
  sf_rating: string;
  incremental_flag: boolean;
  total_contacts: number;
  total_projects: number;
  total_properties: number;
  sf_type: string;
}

export const accountHeadCells: HeadCell<IDetailAccountResponse, SearchAccount>[] = [
  {
    id: 'name',
    label: 'Account Name',
    gridProps: { md: 4 },
    renderRow: {
      function: renderTitleCase,
      param: ['name'],
      noData: '',
      style: { paddingLeft: '2rem' },
    },
  },
  {
    id: 'address_1',
    label: 'Address',
    gridProps: { md: 3 },
    renderRow: {
      function: renderProperty,
      param: ['address_1', 'city', 'state'],
      noData: '',
      style: {},
    },
  },
  {
    id: 'sf_rating',
    label: 'Rating',
    gridProps: { md: 1 },
    textAlign: 'center',
    renderRow: {
      function: renderAccountIcons,
      param: ['sf_rating', 'incremental_flag'],
      additionalParam: [true],
      noData: '',
      style: {},
    },
  },
  {
    id: 'incremental_flag',
    label: 'Incremental',
    gridProps: { md: 1 },
    renderRow: {
      function: renderAccountIcons,
      param: ['sf_rating', 'incremental_flag'],
      additionalParam: [false],
      noData: '',
      style: {},
    },
  },
  {
    id: 'total_contacts',
    label: 'Contacts',
    gridProps: { md: 1 },
    renderRow: { function: renderFalsyValue, param: ['total_contacts'], noData: 0, style: {} },
  },
  {
    id: 'total_properties',
    label: 'Properties',
    gridProps: { md: 1 },
    renderRow: { function: renderFalsyValue, param: ['total_properties'], noData: 0, style: {} },
  },
  {
    id: 'total_projects',
    label: 'Projects',
    gridProps: { md: 1 },
    renderRow: { function: renderFalsyValue, param: ['total_projects'], noData: 0, style: {} },
  },
];
