import React from 'react';
import { FastField, FieldProps } from 'formik';
import { Switch } from '@material-ui/core';

interface IProps {
  name: string;
}

const FormSwitch: React.FC<IProps> = ({ name }) => {
  return (
    <FastField name={name}>
      {({ field }: FieldProps) => {
        return <Switch color="primary" {...field} />;
      }}
    </FastField>
  );
};

export default FormSwitch;
