import React from 'react';
import { FormControl } from '@material-ui/core';
import { useRecoilState } from 'recoil';

import { FieldInputLabel, TextFieldInput } from './LeadsFilterItem.styles';

import { FilterItem, FilterKey } from '../../../../types';
import {
  searchCountyValueState,
  searchKeywordValueState,
  searchLocationValueState,
  searchProjectTypeValueState,
  searchProvinceValueState,
  searchRegionValueState,
  searchSpecAlertValueState,
  searchStateValueState,
} from '../../../../state/atoms/filters';

interface IProps {
  config: FilterItem;
}
const AddSearchForm: React.FC<IProps> = ({ config }: IProps) => {
  const [searchLocationValue, setSearchLocationValue] = useRecoilState(searchLocationValueState);
  const [searchStateValue, setSearchStateValue] = useRecoilState(searchStateValueState);
  const [searchCountyValue, setSearchCountyValue] = useRecoilState(searchCountyValueState);
  const [searchRegionValue, setSearchRegionValue] = useRecoilState(searchRegionValueState);
  const [searchProvinceValue, setSearchProvinceValue] = useRecoilState(searchProvinceValueState);
  const [searchSpecAlertValue, setSearchSpecAlertValue] = useRecoilState(searchSpecAlertValueState);
  const [searchKeywordValue, setSearchKeywordValue] = useRecoilState(searchKeywordValueState);
  const [searchProjectTypesValue, setSearchProjectTypesValue] = useRecoilState(
    searchProjectTypeValueState,
  );

  return (
    <div style={{ margin: '0.5rem 0', paddingLeft: '1.5rem' }}>
      <FormControl>
        <FieldInputLabel shrink htmlFor="bootstrap-input">
          {config.filterLabel}
        </FieldInputLabel>
        <TextFieldInput
          className="hidden"
          value={searchStateValue}
          id={`search-${config.filterKey}-input`}
          placeholder={'Search..'}
        />
      </FormControl>
      <FormControl>
        <TextFieldInput
          value={(() => {
            switch (config.filterKey) {
              case FilterKey.Location:
                return searchLocationValue;
              case FilterKey.State:
                return searchStateValue;
              case FilterKey.CountyState:
                return searchCountyValue;
              case FilterKey.SpecAlerts:
                return searchSpecAlertValue;
              case FilterKey.Keywords:
                return searchKeywordValue;
              case FilterKey.Region:
                return searchRegionValue;
              case FilterKey.Province:
                return searchProvinceValue;
              case FilterKey.ProjectTypes:
                return searchProjectTypesValue;
              default:
                return '';
            }
          })()}
          onChange={evt => {
            if (config.filterKey === FilterKey.Location) {
              setSearchLocationValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.State) {
              setSearchStateValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.CountyState) {
              setSearchCountyValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.Region) {
              setSearchRegionValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.Province) {
              setSearchProvinceValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.SpecAlerts) {
              setSearchSpecAlertValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.Keywords) {
              setSearchKeywordValue(evt.currentTarget.value);
            }
            if (config.filterKey === FilterKey.ProjectTypes) {
              setSearchProjectTypesValue(evt.currentTarget.value);
            }
          }}
          id={`search-${config.filterKey}-input-display`}
          placeholder={'Search..'}
        />
      </FormControl>
    </div>
  );
};

export default AddSearchForm;
