import React from 'react';
import { TableRow as MuiTableRow } from '@material-ui/core';
import { HeaderGroup } from 'react-table';
import { Droppable } from 'react-beautiful-dnd';

import { ReactTableHeaderCell } from './ReactTableHeaderCell';
import { useReactTableHeaderRowStyles } from './ReactTableHeaderRow.styles';
interface ReactTableHeaderRowProps<TableType extends Record<string, unknown>> {
  headerGroup: HeaderGroup<TableType>;
}

export const ReactTableHeaderRow = <TableType extends Record<string, unknown>>({
  headerGroup,
}: ReactTableHeaderRowProps<TableType>): JSX.Element => {
  const classes = useReactTableHeaderRowStyles();
  return (
    <Droppable droppableId="droppable" direction="horizontal">
      {droppableProvided => (
        <MuiTableRow
          {...headerGroup.getHeaderGroupProps()}
          className={classes.tableRow}
          ref={droppableProvided.innerRef}
        >
          {droppableProvided.placeholder}
          {headerGroup.headers.map((column, index) => {
            return (
              <ReactTableHeaderCell<TableType>
                column={column}
                key={`cell-${column.id}`}
                index={index}
              />
            );
          })}
        </MuiTableRow>
      )}
    </Droppable>
  );
};
