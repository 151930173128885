import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import has from 'lodash/has';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import omit from 'lodash/omit';

import {
  stateAssociatedWithCountyState,
  updateCountiesAssociatedWithState,
} from './LocationFilterHelpers/LocationFilterHelper';
import { handleStateCountyStateDeselectionFromFilter } from './LocationFilterHelpers/HandleStateCountyStateDeselectionFromFilter';

import {
  Comparators,
  FilterData,
  FilterItem,
  FilterKey,
  FilterOptionsItem,
  OptionValue,
  TagsDisplay,
} from '../../../../types';
import {
  allFilterTagsState,
  allKeysSelectedState,
  countiesInEachStateState,
  filtersSelectedState,
} from '../../../../state/atoms/filters';
import { light } from '../../../../theme';
interface IProps {
  filterItem: FilterItem;
  filterItemOption: FilterOptionsItem;
  clearFilters?: boolean;
  multipleSelect?: boolean;
  isFilterItemChecked?: boolean;
  filterInputsToClear: string[];
  setFilterInputsToClear: (inputKeys: string[]) => void;
}

/**
 * This Component is used exclusively for State and CountyState Filter since they are nested
 * The logic is separated out to cover these items
 * */
const LeadsFilterItemLocation: React.FC<IProps> = ({
  filterItem,
  filterItemOption,
  isFilterItemChecked = false,
}: IProps) => {
  const filterKey: FilterKey = filterItemOption?.filterKey || filterItem.filterKey;

  const [isChecked, setIsChecked] = useState<boolean>(isFilterItemChecked);

  const [selectedKeys, setSelectedKeys] = useRecoilState<FilterData>(
    filtersSelectedState(filterKey),
  );

  const [allKeys, setAllKeys] = useRecoilState<FilterData | undefined>(allKeysSelectedState);
  const setAllFilterTags = useSetRecoilState<TagsDisplay[]>(allFilterTagsState);
  const countiesInEachState = useRecoilValue(countiesInEachStateState);
  // Grab the filterItemOptions filterKey first(i.e Locations state/countyState) the defualt to filterKey on filterItem

  // Create Selected Keys state for State and CountyState filters
  // Fixes issues with loading saved filters and editing from fresh page
  if (
    !has(selectedKeys, [FilterKey.State, Comparators.In]) &&
    has(allKeys, [FilterKey.State, Comparators.In]) &&
    allKeys
  ) {
    setSelectedKeys(() => {
      const defaultSelectedKeys = {
        [FilterKey.State]: {
          [Comparators.In]: [],
        },
      } as FilterData;

      defaultSelectedKeys[FilterKey.CountyState] = {
        [Comparators.In]: [],
      };

      return defaultSelectedKeys;
    });
  }

  const generateFilterTag = useCallback(
    (currentTags?: TagsDisplay[]): TagsDisplay[] =>
      (currentTags || []).concat([
        {
          label: filterItem.filterLabel,
          key: filterKey,
          value: `${filterItemOption.label}`,
          comparator: filterItemOption.comparator,
          filterValue: filterItemOption.value,
          isInput: false,
        },
      ]),
    [filterItem, filterItemOption, filterKey],
  );

  const handleSelect = useCallback(() => {
    let keySelection = { ...allKeys };
    let countyFilterTagsToAdd: TagsDisplay[] = [];
    const countyStateIn: string[] = [];
    let stateAssociatedWithCounty: string | undefined;

    // START Setup Steps:
    if (filterKey === FilterKey.State) {
      updateCountiesAssociatedWithState({
        countyStateIn,
        countyFilterTagsToAdd,
        countiesInEachState,
        filterItemOption,
      });
    }

    if (filterKey === FilterKey.CountyState) {
      stateAssociatedWithCounty = stateAssociatedWithCountyState({
        countyStateValue: filterItemOption.value as string,
      });
    }

    if (
      //  Lodash has checks to see if path exists in the object (allKeys)
      //  filterKey and comparator is not inside allKeys (first time clicking the filter type)
      !has(allKeys, [filterKey, filterItemOption.comparator])
    ) {
      setSelectedKeys((currentSelectedKeys: FilterData) => {
        const updatedSelectedKeys: FilterData = {
          [filterKey]: {
            ...currentSelectedKeys[filterKey],
            [filterItemOption.comparator]: [filterItemOption.value],
          },
        };

        // Adds and checkmarks all the countyState keys associated with the state
        if (filterKey === FilterKey.State) {
          // Responsible for updating all the sub-filter checkbox to be marked
          updatedSelectedKeys[FilterKey.CountyState] = {
            ...currentSelectedKeys[FilterKey.CountyState],
            [Comparators.In]: countyStateIn,
          };
        }
        keySelection = updatedSelectedKeys;
        return updatedSelectedKeys;
      });

      setAllFilterTags((prev: TagsDisplay[]) => {
        let filterTagsToGenerate = prev;
        // Adds the generated countyState filter tags associated with the state
        if (filterKey === FilterKey.State) {
          // When adding state filter, makes sure not to double up on county filter tags again
          countyFilterTagsToAdd = countyFilterTagsToAdd.filter(
            (filterTag: any) => !prev.map(i => i.value).includes(filterTag.value),
          );
          filterTagsToGenerate = [...prev, ...countyFilterTagsToAdd];
        }
        return generateFilterTag(filterTagsToGenerate);
      });
      setAllKeys(prev => ({ ...prev, ...keySelection }));
      return;
    }

    // STATE EXISTS - NOT FIRST TIME CLICKING FILTER TYPE
    if (!allKeys) {
      // allKeys should always be defined at this point - move on to below code
      return;
    }

    //Initialize empty comparator sets if it doesn't exist. valuesArray will always be a list of strings or an empty array. Never undefined.
    //Remove empty comparators from state and county before moving on
    const valuesArray: Array<OptionValue> = allKeys[filterKey][
      filterItemOption.comparator
    ] as Array<OptionValue>;
    const valuesArrayCounties: Array<string> | undefined = selectedKeys[FilterKey.CountyState]?.in
      ? (selectedKeys[FilterKey.CountyState][Comparators.In] as string[])
      : undefined;
    if (
      filterItemOption.value?.toString() &&
      !valuesArray.map(value => value?.toString()).includes(filterItemOption.value.toString())
    ) {
      setSelectedKeys((currentSelectedKeys: FilterData) => {
        const addList = [...valuesArray, filterItemOption.value];
        const updatedSelectedKeys: FilterData = {
          [filterKey]: {
            ...currentSelectedKeys[filterKey],
            [filterItemOption.comparator]: addList,
          },
        };

        if (filterKey === FilterKey.State) {
          updatedSelectedKeys[FilterKey.CountyState] = {
            ...allKeys[FilterKey.CountyState],
            [Comparators.In]: [
              ...(currentSelectedKeys[FilterKey.CountyState][Comparators.In] as string[]),
              ...countyStateIn,
            ],
          };
        }

        keySelection = updatedSelectedKeys;
        return updatedSelectedKeys;
      });
      setAllFilterTags((prev: TagsDisplay[]) => {
        countyFilterTagsToAdd = countyFilterTagsToAdd.filter(
          (filterTag: any) => !prev.map(i => i.value).includes(filterTag.value),
        );
        return generateFilterTag([...prev, ...countyFilterTagsToAdd]);
      });
      setAllKeys(prev => ({ ...prev, ...keySelection }));
    } else {
      // Filters out items that are equal to the value selected - left with the filters still checkmarked
      const valuesArrayAfterDeselection = valuesArray.filter(
        i => i?.toString() !== filterItemOption.value?.toString(),
      );

      if (filterKey === FilterKey.State) {
        // Filters out items inside countyStateIn from valuesArrayCounties (removes them)
        const countiesArrayAfterDeselection = valuesArrayCounties?.filter(
          i => !countyStateIn.includes(i),
        );
        if (countiesArrayAfterDeselection && countiesArrayAfterDeselection.length > 0) {
          setSelectedKeys((currentSelectedKeys: FilterData) => {
            const updatedSelectedKeys = {
              [FilterKey.CountyState]: {
                ...currentSelectedKeys[FilterKey.CountyState],
                [Comparators.In]: countiesArrayAfterDeselection,
              },
            };
            keySelection = updatedSelectedKeys;
            return updatedSelectedKeys;
          });
        } else {
          setSelectedKeys(currentSelectedKeys => {
            const removeComparator = { ...currentSelectedKeys };
            let allKeys = { ...removeComparator };
            allKeys = {
              ...removeComparator,
              [FilterKey.CountyState]: omit(
                removeComparator[FilterKey.CountyState],
                Comparators.In,
              ),
            };
            keySelection = allKeys;
            return allKeys;
          });
        }
        setAllKeys(prev => {
          return { ...prev, ...keySelection };
        });
        setAllFilterTags((prev: any) =>
          prev.filter(
            (filterTag: any) => !countyFilterTagsToAdd.map(i => i.value).includes(filterTag.value),
          ),
        );
      }

      handleStateCountyStateDeselectionFromFilter({
        valuesArrayAfterDeselection,
        setSelectedKeys,
        filterItem,
        filterItemOption,
        allKeys,
        stateAssociatedWithCounty,
        setAllKeys,
        setAllFilterTags,
        keySelection,
        countiesInState: countyStateIn || [],
      });

      setAllFilterTags((prev: any) =>
        prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
      );
    }
  }, [
    filterItem,
    filterItemOption,
    setSelectedKeys,
    selectedKeys,
    setAllFilterTags,
    setAllKeys,
    allKeys,
    generateFilterTag,
    countiesInEachState,
    filterKey,
  ]);

  useEffect(() => {
    setIsChecked(isFilterItemChecked);
  }, [isFilterItemChecked]);

  return (
    <div
      style={{
        color: 'white',
        justifyContent: 'space-between',
      }}
    >
      <Checkbox
        checked={isChecked}
        style={{ color: light }}
        name={filterItemOption.label}
        onChange={evt => {
          setIsChecked(evt.target.checked);
          handleSelect();
        }}
      />
    </div>
  );
};

export default LeadsFilterItemLocation;
