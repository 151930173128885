import { AppBar, Button, Card, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import uniqBy from 'lodash/uniqBy';

import useContactsAccountsContainerStyles from './ContactsAccountsContainer.styles';
import NewContactsCard from './ContactsCard';
import TabPanel from './TabPanel';

import EmptyBox from '../../Common/EmptyBox';
import OrganizationCard from '../OrganizationDetails/OrganizationCard';
import CreateStakeholderModal from '../StakeholderDetails/CreateStakeholderModal';
import { Organization, Project, Property, Stakeholder } from '../../../models';
import { currentPropertyState } from '../../../state/atoms/property';
import { deletePropertyStakeholders } from '../../../api/properties';
import { deleteProjectStakeholders } from '../../../api/projects';
import { useMessages } from '../../../state/contexts';
import CreateOrganizationModal from '../OrganizationDetails/CreateOrganizationModal';
import { projectStakeholdersState } from '../../../state/atoms/leadDetails';
import { activeProjectsSelector } from '../../../state/selectors/projects';

interface IProps {
  refreshPropertyDetails: () => void;
}

export const tabsStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    height: '100%',
  },
  scroller: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));

export const tabStyle2 = makeStyles(() => ({
  root: {
    padding: '.5rem',
  },
  selected: {
    zIndex: 10,
    marginBottom: '0rem',
    borderLeft: 0,
    bottom: '-.1rem',
  },
  wrapper: {
    border: 'none',
    alignItems: 'center',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    paddingLeft: 0,
  },
}));

const ContactsAccountsContainer: React.FC<IProps> = ({ refreshPropertyDetails }: IProps) => {
  const classes = useContactsAccountsContainerStyles();
  const { setErrorMessage } = useMessages();

  const property = useRecoilValue<Property>(currentPropertyState);
  const activeProject = useRecoilValue<Project>(activeProjectsSelector);
  const [projectStakeholders, setProjectStakeholders] = useRecoilState<Stakeholder[]>(
    projectStakeholdersState,
  );

  const [contactsTabValue, setContactsTabValue] = useState<number>(0);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const [projectIds, setProjectIds] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [openContacts, setOpenContacts] = useState<boolean>(false);
  const [openAccounts, setOpenAccounts] = useState<boolean>(false);

  const handleContactsTabValueChange = useCallback(
    (event: React.ChangeEvent<unknown>, newTabValue: number): void => {
      event.preventDefault();
      setContactsTabValue(newTabValue);
    },
    [],
  );

  const handleCloseContacts = useCallback(
    (success = false): void => {
      if (success && refreshPropertyDetails) {
        refreshPropertyDetails();
      }

      setOpenContacts(false);
    },
    [refreshPropertyDetails],
  );

  const handleCloseAccounts = useCallback(
    (success = false): void => {
      if (success && refreshPropertyDetails) {
        refreshPropertyDetails();
      }
      setOpenAccounts(false);
    },
    [refreshPropertyDetails],
  );

  const handleRemoveStakeholder = useCallback(
    async (
      propertyOrProjectId: string,
      stakeholderId: string,
      addedToProperty: boolean,
    ): Promise<void> => {
      try {
        if (addedToProperty) {
          await deletePropertyStakeholders(propertyOrProjectId, [stakeholderId]);
        } else {
          await deleteProjectStakeholders(propertyOrProjectId, [stakeholderId]);
        }
        setStakeholders(prevStakeholders =>
          prevStakeholders.filter(({ id }) => id !== stakeholderId),
        );
      } catch (err: any) {
        setErrorMessage(
          `Error removing stakeholder from ${addedToProperty ? 'property' : 'project'}`,
          err,
        );
      }
    },
    [setStakeholders, setErrorMessage],
  );

  const getProjectStakeholder = useCallback(
    (stakeholderId: string) => {
      const projectStakeholder = projectStakeholders.find(
        stakeholder => stakeholderId === stakeholder.id,
      );
      const projectId = projectIds.find(projectId => projectId === projectStakeholder?.id);
      return {
        projectStakeholder,
        projectId,
      };
    },
    [projectStakeholders, projectIds],
  );

  useEffect((): void => {
    if (property?.projects) {
      const projectStakeholders: Stakeholder[] = [];
      const projectIds: string[] = [];
      property.projects.forEach((project: Project) => {
        projectIds.push(project.id);
        projectStakeholders.push(...(project.stakeholders || []));
      });
      setProjectStakeholders(projectStakeholders);
      setProjectIds(projectIds);
      setStakeholders(
        uniqBy([...(property?.stakeholders || []), ...(property?.allStakeholders || [])], 'id'),
      );
      let allOrganizations: Organization[] = [];
      property?.allStakeholders?.forEach(stakeholder => {
        if (stakeholder.organization) {
          allOrganizations.push(stakeholder.organization);
        }
      });
      property?.events?.forEach(event => {
        if (event.organizations) {
          allOrganizations = allOrganizations.concat(event.organizations);
        }
      });
      allOrganizations = uniqBy(allOrganizations, 'id');
      allOrganizations = uniqBy(allOrganizations, 'name');
      setOrganizations(allOrganizations);
    }
  }, [property, setProjectStakeholders]);

  return (
    <div>
      <AppBar position="static" className={classes.contactsAccountsTabs}>
        <Tabs
          value={contactsTabValue}
          onChange={handleContactsTabValueChange}
          classes={tabsStyle()}
        >
          <Tab label="Contacts" classes={tabStyle2()} />
          <Tab label="Accounts" classes={tabStyle2()} />
        </Tabs>
      </AppBar>
      <Card className={classes.contactsContainer}>
        <TabPanel value={contactsTabValue} index={0}>
          <Grid item className={classes.contactsHeaderContainer}>
            <Typography className={classes.contactsTitle}>Contacts</Typography>
            <Button onClick={() => setOpenContacts(true)} className={classes.contactsAddButton}>
              +
            </Button>
          </Grid>

          {stakeholders.length > 0 ? (
            <Grid container className={classes.contactsList}>
              {stakeholders.map((stakeholder, i) => {
                const { projectStakeholder, projectId } = getProjectStakeholder(stakeholder.id);
                return (
                  <div key={`${stakeholder.id}-${i}`} className={classes.card}>
                    <NewContactsCard
                      stakeholder={projectStakeholder || stakeholder}
                      onRemove={handleRemoveStakeholder}
                      propertyId={property.id}
                      projectId={projectId}
                      primary={projectStakeholder?.isPrimary}
                    />
                  </div>
                );
              })}
            </Grid>
          ) : (
            <Grid item className={classes.emptyContactListContainer}>
              <EmptyBox className={classes.emptyContactsList}>No Contacts</EmptyBox>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={contactsTabValue} index={1}>
          <Grid item className={classes.contactsHeaderContainer}>
            <Typography className={classes.contactsTitle}>Accounts</Typography>
            <Button onClick={() => setOpenAccounts(true)} className={classes.contactsAddButton}>
              +
            </Button>
          </Grid>
          {organizations.length > 0 ? (
            <Grid container className={classes.contactsList}>
              {organizations.map((organization, i) => {
                return (
                  <div key={`${organization.id}-${i}`} className={classes.card}>
                    <OrganizationCard organization={organization} />
                  </div>
                );
              })}
            </Grid>
          ) : (
            <Grid item className={classes.emptyContactListContainer}>
              <EmptyBox className={classes.emptyContactsList}>No Accounts</EmptyBox>
            </Grid>
          )}
        </TabPanel>
        <CreateStakeholderModal
          open={openContacts}
          onClose={handleCloseContacts}
          project={activeProject}
          property={property}
        />
        <CreateOrganizationModal open={openAccounts} onClose={handleCloseAccounts} />
      </Card>
    </div>
  );
};

export default ContactsAccountsContainer;
