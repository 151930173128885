import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { makeStyles, Button, Grid, Paper, Typography, Container } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { createProperty, getProperties } from '../../../api/properties';
import BorderedPaper from '../../Common/BorderedPaper';
import FormSelect from '../../Common/FormSelect';
import FormTextField from '../../Common/FormTextField';
import PaperHeader from '../../Common/PaperHeader';
import PaperBody from '../../Common/PaperBody';
import { darkBlue } from '../../../theme';
import { US_STATES } from '../../../constants';
import { useMessages } from '../../../state/contexts';
import useDebounce from '../../../utils/debounce';
import type { IPropertyRequest } from '../../../models';
import { Property, PropertyRequestSchema } from '../../../models';
import ModalLayout from '../../Common/ModalLayout';
import PaperFooter from '../../Common/PaperFooter';
import { Optional } from '../../../types';
import { removeFalsyValues } from '../../../utils/object';
import LoadingPane from '../../Common/Search/LoadingSearch';
import SelectedPane from '../../Common/Search/SelectedSearch';
import EmptyPane from '../../Common/Search/EmptySearch';
import Search from '../../Common/Search/Search';

interface IProps {
  onSubmit: (property: Property) => void;
  handleClose: () => void;
  isOpen: boolean;
  title?: string;
  redirectOnSearch?: boolean;
}

const useStyles = makeStyles(theme => ({
  createButton: {
    margin: theme.spacing(0, -3, -4, -3),
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    width: `calc(100% + ${theme.spacing(7)}px)`,
  },
  searchTitle: {
    textAlign: 'center',
    padding: theme.spacing(1, 7, 4),
  },
  searchContainer: {
    overflowY: 'scroll',
    height: '320px',
    alignContent: 'flex-start',
  },
  footer: {
    textAlign: 'center',
  },
}));

const CreateProperty: React.FC<IProps> = ({
  onSubmit,
  handleClose,
  isOpen,
  title,
  redirectOnSearch = false,
}) => {
  const initialForm = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
  } as Record<string, Optional<string>>;
  const { setErrorMessage } = useMessages();
  const [fetchingProperties, setFetchingProperties] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<Partial<Property>>();
  const [propertySearchResults, setPropertySearchResults] = useState<Property[]>([]);
  const [query, setQuery] = useState<Record<string, Optional<string>>>(initialForm);
  const savePropertyToProject = useCallback(
    async (property: Optional<Property | Partial<Property>>) => {
      try {
        if (!property) {
          throw new Error('A property must be selected');
        }
        if (property?.id) {
          onSubmit(property as Property);
        } else {
          const newProperty: Property = await createProperty(
            (property as unknown) as IPropertyRequest,
          );
          onSubmit(newProperty);
        }
      } catch (err: any) {
        setErrorMessage(err);
      }
      handleClose();
    },
    [onSubmit, setErrorMessage, handleClose],
  );
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    const { address1, address2, city, state, zipcode } = debouncedQuery || {};
    const hasValues = address1 || address2 || city || state || zipcode;
    if (hasValues) {
      setFetchingProperties(true);
      (async () => {
        const properties = await getProperties(removeFalsyValues(debouncedQuery));
        setPropertySearchResults(properties);
        setFetchingProperties(false);
      })();
    }
  }, [debouncedQuery, setPropertySearchResults]);

  const classes = useStyles();
  return (
    <ModalLayout isOpen={isOpen} handleClose={handleClose} label="create-project">
      <Container maxWidth="md">
        <Formik
          initialValues={initialForm}
          validationSchema={PropertyRequestSchema}
          onSubmit={savePropertyToProject}
        >
          {({ setFieldValue }) => {
            return (
              <Form
                onChange={(e: SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const { name, value } = target;
                  if (value !== query[name]) {
                    setQuery(current => ({ ...current, [name]: value }));
                  }
                }}
              >
                <Paper>
                  <PaperHeader>
                    <Grid container justify="center">
                      <Typography variant="h6" color="primary">
                        {title || 'Add Property'}
                      </Typography>
                    </Grid>
                  </PaperHeader>
                  <PaperBody>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                          Property Information
                        </Typography>
                        <BorderedPaper color={darkBlue}>
                          <Grid container direction="column" justify="flex-start" spacing={4}>
                            <Typography variant="subtitle2" color="primary" gutterBottom>
                              Enter Property Address
                            </Typography>
                            <Grid item md={10}>
                              <FormTextField name="address1" label="Address 1" fullWidth required />
                            </Grid>
                            <Grid item md={5}>
                              <FormTextField name="address2" label="Address 2" fullWidth />
                            </Grid>
                            <Grid item md={5}>
                              <FormTextField name="city" label="City" required />
                            </Grid>
                            <Grid item md={4}>
                              <FormSelect
                                options={US_STATES}
                                name="state"
                                label="State"
                                required
                                onChange={(
                                  event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                  }>,
                                ) => {
                                  const target = event.target as HTMLInputElement;
                                  const { name, value } = target;
                                  if (value !== query[name]) {
                                    setQuery(current => ({ ...current, [name]: value }));
                                  }
                                  setFieldValue(name, value);
                                }}
                              />
                            </Grid>
                            <Grid item md={4}>
                              <FormTextField name="zipcode" label="Zipcode" required />
                            </Grid>
                          </Grid>
                        </BorderedPaper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                          Property Location
                        </Typography>
                        {fetchingProperties ? (
                          <LoadingPane />
                        ) : selectedProperty ? (
                          <SelectedPane
                            modelType={'property'}
                            selected={(selectedProperty as unknown) as Property}
                            setSelected={setSelectedProperty}
                            redirectOnSearch={redirectOnSearch}
                          />
                        ) : Object.values(query).filter(x => !!x).length === 0 ? (
                          <EmptyPane />
                        ) : (
                          <Search
                            modelType={'property'}
                            searchResults={propertySearchResults}
                            setSelected={setSelectedProperty}
                            redirectOnSearch={redirectOnSearch}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </PaperBody>
                  <PaperFooter className={classes.footer}>
                    {selectedProperty && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => savePropertyToProject(selectedProperty)}
                      >
                        Save Property
                      </Button>
                    )}
                  </PaperFooter>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </ModalLayout>
  );
};

export default CreateProperty;
