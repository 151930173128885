import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import ContactDetails from './ContactDetails';
import type { SubmitHandler } from './ContactDetails';

import {
  getOrganizationById,
  updateOrganizationById,
  getOrganizationProjects,
  getOrganizationProperties,
  getOrganizationStakeholders,
} from '../../../api/organizations';
import FormSelect from '../../Common/FormSelect';
import { OrganizationProfileTypeMapping } from '../../../constants';
import { useMessages } from '../../../state/contexts';
import type { IOrganizationRequest, Project, Property, Stakeholder } from '../../../models';
import { Organization } from '../../../models';
import { OrganizationRequestSchema } from '../../../models/organization';
import { purple } from '../../../theme';
import type { Optional } from '../../../types';

interface IRouteParams {
  organizationId: string;
}

const OrganizationDetails: React.FC = () => {
  const { organizationId } = useParams<IRouteParams>();
  const [organization, setOrganization] = useState<Optional<Organization>>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const { setPageLoading, setPageFetching, setPageNotFound, setErrorMessage } = useMessages();

  const fetchData = useCallback(
    async (initialLoad = false) => {
      if (initialLoad) {
        setPageLoading(true);
      }
      try {
        const [
          nextOrganization,
          nextProjects,
          nextProperties,
          nextStakeholders,
        ] = await Promise.all([
          getOrganizationById(organizationId),
          getOrganizationProjects(organizationId),
          getOrganizationProperties(organizationId),
          getOrganizationStakeholders(organizationId),
        ]);
        setOrganization(nextOrganization);
        setProjects(nextProjects);
        setProperties(nextProperties);
        setStakeholders(nextStakeholders);
        setPageLoading(false);
      } catch (err) {
        setPageNotFound();
      }
    },
    [organizationId, setPageLoading, setPageNotFound],
  );

  const handleSubmit: SubmitHandler = useCallback(
    async (values: IOrganizationRequest, { resetForm }) => {
      setPageFetching(true);
      try {
        const nextOrganization = await updateOrganizationById(organizationId, values);
        setOrganization(nextOrganization);
        resetForm();
      } catch (err) {
        setErrorMessage(err);
      }
      setPageFetching(false);
    },
    [organizationId, setPageFetching, setErrorMessage],
  );

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  const initialValues: IOrganizationRequest = useMemo(
    () => ({
      name: organization?.name,
      description: organization?.description,
      contact: organization?.contactDetails,
      profile_type: organization?.profileType,
    }),
    [organization],
  );

  if (!organization) {
    return null;
  }

  return (
    <ContactDetails
      accentColor={purple}
      title="Account"
      showAddress
      refreshData={() => fetchData()}
      contact={organization}
      initialValues={initialValues}
      validationSchema={OrganizationRequestSchema}
      onSubmit={handleSubmit}
      projects={projects}
      properties={properties}
      stakeholders={stakeholders}
    >
      {organization.licenseNumber && (
        <Grid item>
          <Typography>License:</Typography>
          <Typography color="primary">{organization.licenseNumber}</Typography>
        </Grid>
      )}
      <Grid item>
        <FormSelect
          name="profile_type"
          label="Profile Type"
          options={OrganizationProfileTypeMapping}
          fullWidth
        />
      </Grid>
    </ContactDetails>
  );
};

export default OrganizationDetails;
