import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import isEmpty from 'lodash/isEmpty';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router';

import { ProjectLeadsMenu } from './ProjectLeads.config';
import ProjectLeadsTable from './ProjectLeadsTable/ProjectLeadsTable';
import PLArchive from './Archive/PLArchive';
import ProjectLeadsFavoritesTable from './ProjectLeadsFavorite/ProjectLeadsFavoritesTable';
import ProjectLeadsHeader from './ProjectLeadsHeader';

import StatusBar from '../Leads/LeadsTable/StatusBar';
import { getProjectLeadsPagePath } from '../../../utils/page';
import { useLeadsStyles } from '../Leads/Leads.styles';
import LeadsFilterDrawer from '../Leads/LeadsFilter/LeadsFilterDrawer';
import {
  Comparators,
  FilterData,
  FilterItemInputType,
  FilterOptionsItem,
  FilterOptionsResponse,
  FilterType,
  Optional,
  UserFilterNameResponse,
} from '../../../types';
import { filterOptionsFetchState } from '../../../state/selectors/filterOptions';
import {
  allFilterTagsState,
  allKeysSelectedState,
  clearFiltersState,
  currentFilterOptionsState,
  editedFilterNameState,
  filterNameErrorState,
  filterOptionState,
  filterTagsToDisplayState,
  savedFilterValueState,
} from '../../../state/atoms/filters';
import FilterTags from '../Leads/FilterTags';
import { resetCachedFilterState } from '../../../state/atoms/leadRows';
import { usersFetchState } from '../../../state/selectors/users';
import { Organization, User } from '../../../models';
import { currentUserState, userOptionsState } from '../../../state/atoms/users';
import { ProjectLeadStatus, ProjectLeadStatusNonTabs } from '../../../models/projectLead';
import SavedFilterHeader from '../Leads/SavedFilters/SavedFilterHeader';
import { CleoObjectType } from '../../../models/common';
import { getSavedFilterById } from '../../../api/filters';
import SavedFilters from '../Leads/SavedFilters/SavedFilters';
import { getOrganizationById } from '../../../api/organizations';
import { secondary } from '../../../theme';

export interface IRouteParams {
  status: ProjectLeadStatus | ProjectLeadStatusNonTabs;
  savedFilterId?: string;
  id?: string;
}

const ProjectLeadsPage: React.FC = () => {
  const classes = useLeadsStyles();
  const { status, savedFilterId, id } = useParams<IRouteParams>();
  const fetchedFilterOptions = useRecoilValueLoadable<FilterOptionsResponse>(
    filterOptionsFetchState,
  );
  let tabStatus: ProjectLeadStatus;
  if (status === ProjectLeadStatusNonTabs.Account) {
    tabStatus = ProjectLeadStatus.Hidden;
  } else {
    tabStatus = status as ProjectLeadStatus;
  }
  const [projectLeadStatus, setProjectLeadStatus] = useState(tabStatus || ProjectLeadStatus.Home);
  const setCurrentFilterOptions = useSetRecoilState<FilterOptionsResponse>(
    currentFilterOptionsState,
  );

  const [filterTagsToDisplay, setFilterTagsToDisplay] = useRecoilState(filterTagsToDisplayState);
  const [clearFilters, setClearFilters] = useRecoilState(clearFiltersState);
  const [filterOption, setFilterOption] = useRecoilState<Optional<FilterData>>(filterOptionState);
  const prefix = getProjectLeadsPagePath();
  const setAllKeysSelected = useSetRecoilState(allKeysSelectedState);
  const setAllFilterTags = useSetRecoilState(allFilterTagsState);
  const setResetCachedFilter = useSetRecoilState(resetCachedFilterState);

  // User Filters
  const { user } = useAuth0();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setUserOptions = useSetRecoilState<FilterOptionsItem[]>(userOptionsState);
  const users = useRecoilValueLoadable(usersFetchState);

  // Saved Filters
  const [editedFilterNameError, setEditedFilterNameError] = useState<string>();
  const [savedFilter, setSavedFilter] = useState<UserFilterNameResponse>();
  const setEditedFilterName = useSetRecoilState<string>(editedFilterNameState);
  const setFilterNameError = useSetRecoilState<string | undefined>(filterNameErrorState);
  const setSavedFilterValue = useSetRecoilState(savedFilterValueState);
  const disableFilter =
    status === ProjectLeadStatus.Favorites || status === ProjectLeadStatus.SavedFilters;

  // Project Leads by Account
  const [account, setAccount] = useState<Organization | undefined>(undefined);
  const disableSavingFilters = status === ProjectLeadStatusNonTabs.Account;

  const handleTabClick = (nextProjectLeadStatus: ProjectLeadStatus) => {
    setProjectLeadStatus(nextProjectLeadStatus);
  };

  const handleFilterChange = useCallback(
    (filterData: FilterData) => {
      return setFilterOption(currentFilterOption => ({ ...currentFilterOption, ...filterData }));
    },
    [setFilterOption],
  );

  const hasFilterBeenCleared = useCallback(() => {
    let filterHasBeenCleared = false;
    if (
      filterOption &&
      (isEmpty(filterOption) ||
        Object.keys(filterOption).every(option =>
          option === 'isNew' ? true : isEmpty(filterOption[option]),
        ))
    ) {
      filterHasBeenCleared = true;
    }
    return filterHasBeenCleared;
  }, [filterOption]);

  const loadSavedFilter = useCallback(async (): Promise<void> => {
    if (savedFilterId) {
      try {
        const savedFilterResponse = await getSavedFilterById(savedFilterId);
        // setProjectLeadStatus(ProjectLeadStatus.Hidden);
        setSavedFilter(savedFilterResponse);
        setEditedFilterName(savedFilterResponse.filter_name || '');
        setFilterNameError(undefined);
        setFilterOption(savedFilterResponse.filter);
        handleFilterChange(savedFilterResponse.filter);
        setAllFilterTags(savedFilterResponse.filter_tags.tags);
        setFilterTagsToDisplay(savedFilterResponse.filter_tags.tags);
        setAllKeysSelected(savedFilterResponse.filter);
      } catch (e: any) {
        if (e.response && e.response.data) {
          setFilterNameError(e.response.data.message);
        }
      }
    }
  }, [
    handleFilterChange,
    savedFilterId,
    setAllFilterTags,
    setAllKeysSelected,
    setEditedFilterName,
    setFilterTagsToDisplay,
    setFilterOption,
    setFilterNameError,
    setSavedFilter,
  ]);

  const getOrganization = useCallback(async () => {
    if (id) {
      const organization = await getOrganizationById(id);
      setAccount(organization);
    }
  }, [id, setAccount]);

  useEffect(() => {
    if (fetchedFilterOptions.state === 'hasValue') {
      setCurrentFilterOptions(fetchedFilterOptions.contents);
    }
  }, [fetchedFilterOptions, setCurrentFilterOptions]);

  // Clearing filters
  useEffect(() => {
    if (clearFilters) {
      setAllKeysSelected({});
      setFilterOption({});
      setAllFilterTags([]);
      setFilterTagsToDisplay([]);
      setResetCachedFilter(true);
      setClearFilters(false);
      setEditedFilterName('');
    }
    if (hasFilterBeenCleared()) {
      setEditedFilterNameError(undefined);
      setFilterNameError(undefined);
      setSavedFilterValue('');
    }
  }, [
    clearFilters,
    setAllKeysSelected,
    setAllFilterTags,
    setResetCachedFilter,
    setClearFilters,
    hasFilterBeenCleared,
    setFilterTagsToDisplay,
    setFilterOption,
    setFilterNameError,
    setSavedFilterValue,
    setEditedFilterName,
  ]);

  useEffect(() => {
    if (users.state === 'hasValue') {
      const options: FilterOptionsItem[] = users.contents.map(i => {
        return {
          label: i.fullName || i.email,
          value: i.id,
          inputType: FilterItemInputType.Select,
          comparator: Comparators.In,
        };
      });
      options.push({
        label: 'Unassigned',
        value: null,
        inputType: FilterItemInputType.Select,
        comparator: Comparators.Is,
      });
      setUserOptions(options);
      setCurrentUser(users.contents.find((u: User) => user.email === u.email));
    }
  }, [users, user, setUserOptions, setCurrentUser]);

  useEffect(() => {
    loadSavedFilter();
  }, [loadSavedFilter]);

  // Clear filters when changing tabs
  useEffect(() => {
    setClearFilters(true);
  }, [setClearFilters, status]);

  useEffect(() => {
    if (id) {
      getOrganization();
    }
  }, [getOrganization, id]);

  return (
    <Container className={classes.root}>
      <LeadsFilterDrawer
        handleFilterChange={handleFilterChange}
        hasFilterBeenCleared={hasFilterBeenCleared}
        setScoreStatus={() => setProjectLeadStatus(ProjectLeadStatus.Home)}
        setSavedFilter={setSavedFilter}
        savedFilter={savedFilter}
        cleoObjectType={CleoObjectType.ProjectLeads}
        disabled={disableFilter}
        filterTagsToDisplay={filterTagsToDisplay}
        filterType={FilterType.ProjectLead}
        editedFilterNameError={editedFilterNameError}
        setEditedFilterNameError={setEditedFilterNameError}
        disableSavingFilters={disableSavingFilters}
      />
      <Paper className={classes.paper}>
        <Grid className={classes.header}>
          <StatusBar
            statusMenu={ProjectLeadsMenu}
            selectedStatus={tabStatus || projectLeadStatus}
            pathPrefix={prefix}
            onChange={handleTabClick}
          />
        </Grid>
        {account && (
          <Grid style={{ margin: '0rem 2rem' }}>
            <ProjectLeadsHeader name={account.name} />
          </Grid>
        )}
        <div style={{ backgroundColor: secondary }}>
          {savedFilter && (
            <SavedFilterHeader
              editedFilterNameError={editedFilterNameError}
              savedFilter={savedFilter}
              setScoreStatus={() => setProjectLeadStatus(ProjectLeadStatus.Home)}
              setSavedFilter={setSavedFilter}
              setEditedFilterNameError={setEditedFilterNameError}
              filterType={FilterType.ProjectLead}
            />
          )}
          {filterTagsToDisplay.length > 0 && <FilterTags handleFilterChange={handleFilterChange} />}
        </div>
        {status === ProjectLeadStatus.Home && (
          <ProjectLeadsTable filterOption={filterOption} status={status} />
        )}
        {status === ProjectLeadStatusNonTabs.Account && (
          <ProjectLeadsTable filterOption={filterOption} status={status} organizationId={id} />
        )}
        {!!savedFilterId && savedFilter && (
          <ProjectLeadsTable filterOption={filterOption} status={status} />
        )}
        {status === ProjectLeadStatus.Favorites && (
          <ProjectLeadsFavoritesTable filterOption={{ isFavorite: { eq: true } }} status={status} />
        )}
        {status === ProjectLeadStatus.Archive && <PLArchive />}
        {status === ProjectLeadStatus.SavedFilters && (
          <SavedFilters
            scoreStatus={ProjectLeadStatus.SavedFilters}
            cleoObjectType={CleoObjectType.ProjectLeads}
          />
        )}
      </Paper>
    </Container>
  );
};

export default ProjectLeadsPage;
