import React, { useEffect, useCallback, useState } from 'react';
import { makeStyles, Box, Container, Grid, Paper, Typography, Button } from '@material-ui/core';

import NetworkSearch from './Search';

import { getOrganizations } from '../../../api/organizations';
import { getStakeholders } from '../../../api/stakeholders';
import PaperBody from '../../Common/PaperBody';
import PaperHeader from '../../Common/PaperHeader';
import PaperTitle from '../../Common/PaperTitle';
import OrganizationCard from '../OrganizationDetails/OrganizationCard';
import StakeholderCard from '../StakeholderDetails/StakeholderCard';
import { useMessages } from '../../../state/contexts';
import { Organization, Stakeholder } from '../../../models';
import { light } from '../../../theme';
import CreateStakeholderModal from '../StakeholderDetails/CreateStakeholderModal';
import CreateOrganizationModal from '../OrganizationDetails/CreateOrganizationModal';

const MAX_RECENT_CONTACTS = 8;

const useStyles = makeStyles({
  list: {
    borderColor: light,
    borderRadius: 8,
  },
  createButton: {
    width: 'fit-content',
    margin: '0.5em auto',
  },
});

const NetworkPage: React.FC = () => {
  const [recentOrganizations, setRecentOrganizations] = useState<Organization[]>([]);
  const [recentStakeholders, setRecentStakeholders] = useState<Stakeholder[]>([]);
  const [createStakeholderModalOpen, setCreateStakeholderModalOpen] = useState<boolean>(false);
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState<boolean>(false);
  const { setPageLoading, setErrorMessage } = useMessages();

  const fetchRecentOrganizations = useCallback(async () => {
    try {
      const query = {
        name: { neq: ' ' },
        or: [{ name: { neq: '' } }],
      };
      const { items } = await getOrganizations({ limit: MAX_RECENT_CONTACTS, query });
      setRecentOrganizations(items);
    } catch (err: any) {
      setErrorMessage('Error fetching accounts', err);
    }
  }, [setErrorMessage]);

  const fetchRecentStakeholders = useCallback(async () => {
    try {
      const { items } = await getStakeholders({ limit: MAX_RECENT_CONTACTS });
      setRecentStakeholders(items);
    } catch (err: any) {
      setErrorMessage('Error fetching contacts', err);
    }
  }, [setErrorMessage]);

  const fetchData = useCallback(async () => {
    setPageLoading(true);
    await Promise.all([fetchRecentOrganizations(), fetchRecentStakeholders()]);
    setPageLoading(false);
  }, [setPageLoading, fetchRecentOrganizations, fetchRecentStakeholders]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Paper>
            <PaperHeader>
              <PaperTitle title="Recent Activity" />
            </PaperHeader>
            <PaperBody>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" className={classes.list}>
                    <PaperHeader>
                      <Typography variant="h6" color="primary">
                        <strong>Accounts</strong>
                      </Typography>
                    </PaperHeader>

                    <Box p={3}>
                      <Grid container direction="column" spacing={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setCreateAccountModalOpen(true)}
                          className={classes.createButton}
                        >
                          Create New Account
                        </Button>
                        {recentOrganizations.map(organization => (
                          <Grid item key={organization.id}>
                            <OrganizationCard organization={organization} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <CreateOrganizationModal
                      open={createAccountModalOpen}
                      onClose={() => setCreateAccountModalOpen(false)}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" className={classes.list}>
                    <PaperHeader>
                      <Typography variant="h6" color="primary">
                        <strong>Contacts</strong>
                      </Typography>
                    </PaperHeader>
                    <Box p={3}>
                      <Grid container direction="column" spacing={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setCreateStakeholderModalOpen(true)}
                          className={classes.createButton}
                        >
                          Create New Contact
                        </Button>
                        {recentStakeholders.map((stakeholder, idx) => (
                          <Grid item key={stakeholder.id}>
                            <StakeholderCard stakeholder={stakeholder} isOdd={!(idx % 2)} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <CreateStakeholderModal
                      open={createStakeholderModalOpen}
                      onClose={() => setCreateStakeholderModalOpen(false)}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </PaperBody>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <NetworkSearch />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NetworkPage;
