import React, { useState, useCallback, ReactNode, ChangeEvent } from 'react';
import { AppBar, Tabs, Tab, styled, Box, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import TextStyle from '../StyleGuide/TextStyle';
import ColorStyle from '../StyleGuide/ColorStyle';
import ElementStyle from '../StyleGuide/ElementStyle';
import ParagraphStyle from '../StyleGuide/ParagraphStyle';
import ColumnLayoutStyle from '../StyleGuide/ColumnLayoutStyle';
import { Layout } from '../../Common/StyleGuideStyles';
import IconStyle from '../StyleGuide/IconStyle';
import { primaryBlue } from '../../../theme';
interface TabContainerProps {
  index: any;
  children?: ReactNode;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function TabContainer(props: TabContainerProps) {
  const { index, children, ...other } = props;
  return (
    <div role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
      {}
    </div>
  );
}

const Block = styled(Box)({
  marginBottom: '10px',
  display: 'flex',
});

const Navigation = styled(Link)({
  fontSize: '18px',
  color: primaryBlue,
});
/**
 * This file servers for displaying the style guide of the application.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function StyleGuide(): JSX.Element {
  const [value, setValue] = useState(0);
  const handleChange = useCallback((event: ChangeEvent<any>, value: any) => {
    event.preventDefault();
    setValue(value);
  }, []);

  return (
    <Layout>
      <h1>Style Guide</h1>
      <Block>
        <Navigation href="/Home">Home</Navigation>
      </Block>
      <AppBar position="static">
        <Tabs
          value={value}
          aria-label="Style Guild Tab"
          textColor="primary"
          indicatorColor="primary"
          onChange={handleChange}
        >
          <Tab label="Text Style" {...a11yProps(0)} />
          <Tab label="Color Style" {...a11yProps(1)} />
          <Tab label="Element Style" {...a11yProps(2)} />
          <Tab label="Paragraph Styles" {...a11yProps(3)} />
          <Tab label="Column Layout" {...a11yProps(4)} />
          <Tab label="Icons" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer index={0}>
          <TextStyle fontFamilyName={'Noto Sans'} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer index={1}>
          <ColorStyle />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer index={2}>
          <ElementStyle />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer index={3}>
          <ParagraphStyle />
        </TabContainer>
      )}
      {value === 4 && (
        <TabContainer index={4}>
          <ColumnLayoutStyle />
        </TabContainer>
      )}
      {value === 5 && (
        <TabContainer index={5}>
          <IconStyle />
        </TabContainer>
      )}
    </Layout>
  );
}
