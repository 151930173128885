import mapboxgl, { LngLatLike, Map } from 'mapbox-gl';
import { Dispatch, SetStateAction } from 'react';
import ReactDOMServer from 'react-dom/server';

import { popUpMap } from './popUpMap';

import { black } from '../../../../../theme';
import { MapDisplayLevel } from '../../../../../state/atoms/interactiveMap';
import { mapsConfig } from '../InteractiveMap.config';
import { IMapResponse } from '../../../../../api/interactiveMap';
import { toTitleCase } from '../../../../../utils/string';

interface IParamsPointsLayer {
  map: Map;
  color: string;
  layerId: string;
  sourceId: string;
  data: IMapResponse;
  mapDisplayLevel: MapDisplayLevel;
  setMapDisplayLevel: Dispatch<SetStateAction<MapDisplayLevel>>;
  setLocationName: Dispatch<SetStateAction<string>>;
  setLoadNewData: Dispatch<SetStateAction<boolean>>;
  setZoomOutLocation: Dispatch<SetStateAction<string[]>>;
}

export const createMapLayer = ({
  map,
  color,
  layerId,
  sourceId,
  data,
  setLoadNewData,
  mapDisplayLevel,
  setMapDisplayLevel,
  setLocationName,
  setZoomOutLocation,
}: IParamsPointsLayer): void => {
  if (!map) {
    return;
  }

  map.addSource(sourceId, {
    type: 'geojson',
    data: data.item,
  });

  map.addLayer({
    id: layerId,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': color,
      'circle-radius': mapsConfig[mapDisplayLevel].defaultSize,
      'circle-stroke-width': 0,
      // 'circle-stroke-color': primaryBlue,
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * 20px circles when point count is less than 100
      //   * 30px circles when point count is between 100 and 750
      //   * 40px circles when point count is greater than or equal to 750
      // 'circle-color': ['step', ['get', 'count'], color, 100, color, 750, color],
      // 'circle-radius': ['step', ['get', 'count'], 20, 100, 30, 750, 40],
    },
  });

  map.addLayer({
    id: `${layerId}-cluster-count`,
    type: 'symbol',
    source: sourceId,
    filter: ['>', 'count', 1],
    layout: {
      'text-field': '{count}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': black,
    },
  });

  //  Zoom in feature when clicking on clusters on the map
  map.on('click', layerId, e => {
    if (!e.features) {
      return;
    }
    const nextLevel = mapsConfig[mapDisplayLevel].nextLevel;
    if (mapDisplayLevel === nextLevel) {
      return;
    }

    //  Set zoomout location for zoomout logic
    setZoomOutLocation(prevState => {
      return [...prevState, data.locationName];
    });

    const newLocation = e?.features[0]?.properties?.location as string;
    try {
      // All logic for clicking map point goes here
      flyToNewLayer({
        map,
        layerId,
        sourceId,
        mapDisplayLevel,
        newLocation,
        setMapDisplayLevel,
        setLocationName,
        setLoadNewData,
        layerToZoom: nextLevel,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        flyToCoordinates: e.features[0].geometry.coordinates,
      });
    } catch (err) {
      console.error('There is an error flying to this point: ', err);
    }
  });

  //  Use location Name for pop up
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on('mouseenter', layerId, e => {
    map.getCanvas().style.cursor = 'pointer';

    //For popup logic
    if (e.features && e.features[0].properties) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const coordinates = e?.features[0].geometry.coordinates.slice();
      const title = mapsConfig[mapDisplayLevel].nextLevel;
      const locationName = e.features[0].properties.location;
      const popUp = ReactDOMServer.renderToString(
        popUpMap({ title: toTitleCase(title), locationName }),
      );

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(popUp).addTo(map);
    }
  });
  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
};

interface IParamRemoveLayer {
  map: Map;
  layerId: string;
  sourceId?: string;
}

// Don't need to remove layers. Just hide and show layers as they are created
export const hideMapLayer = ({ map, layerId }: IParamRemoveLayer) => {
  if (map.getLayer(layerId)) {
    map.setLayoutProperty(layerId, 'visibility', 'none');
    map.setLayoutProperty(`${layerId}-cluster-count`, 'visibility', 'none');
  }
};

export const showMapLayer = ({ map, layerId }: IParamRemoveLayer) => {
  if (map.getLayer(layerId)) {
    map.setLayoutProperty(layerId, 'visibility', 'visible');
    map.setLayoutProperty(`${layerId}-cluster-count`, 'visibility', 'visible');
  }
};

interface ZoomOut {
  map?: Map | null;
  layerId: string;
  sourceId: string;
  newLocation: string;
  mapDisplayLevel: MapDisplayLevel;
  setMapDisplayLevel: Dispatch<SetStateAction<MapDisplayLevel>>;
  setLocationName: Dispatch<React.SetStateAction<string>>;
  setLoadNewData: Dispatch<SetStateAction<boolean>>;
}

export const zoomOut = ({
  map,
  mapDisplayLevel,
  layerId,
  sourceId,
  newLocation,
  setMapDisplayLevel,
  setLocationName,
  setLoadNewData,
}: ZoomOut) => {
  if (!map) {
    return;
  }
  const prevLevel = mapsConfig[mapDisplayLevel].prevLevel;

  if (prevLevel === mapDisplayLevel) {
    return;
  }
  flyToNewLayer({
    map,
    layerId,
    sourceId,
    mapDisplayLevel,
    newLocation,
    setMapDisplayLevel,
    setLocationName,
    setLoadNewData,
    layerToZoom: prevLevel,
  });
};

interface FlyTo {
  map: Map;
  layerId: string;
  sourceId: string;
  layerToZoom: MapDisplayLevel;
  newLocation: string;
  mapDisplayLevel: MapDisplayLevel;
  setMapDisplayLevel: Dispatch<SetStateAction<MapDisplayLevel>>;
  setLocationName: Dispatch<React.SetStateAction<string>>;
  setLoadNewData: Dispatch<SetStateAction<boolean>>;
  flyToCoordinates?: LngLatLike | undefined;
}

export const flyToNewLayer = ({
  map,
  layerToZoom,
  newLocation,
  setMapDisplayLevel,
  setLoadNewData,
  flyToCoordinates,
  setLocationName,
}: FlyTo) => {
  //  fly to coordinates used only for zooming in on clicked map item
  if (flyToCoordinates) {
    map.flyTo({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      center: flyToCoordinates,
      zoom: mapsConfig[layerToZoom].zoomLevel,
    });
  } else {
    map.zoomTo(mapsConfig[layerToZoom].zoomLevel);
  }
  setMapDisplayLevel(layerToZoom);
  setLocationName(newLocation);
  setLoadNewData(true);
};
