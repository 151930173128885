import React from 'react';
import { Box, Container, Dialog, Slide } from '@material-ui/core';
import type { DialogProps } from '@material-ui/core/Dialog';
import { SlideProps } from '@material-ui/core/Slide';

import BackButton from './BackButton';

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

Transition.displayName = 'CreateDialogTransition';

const CreateDialog: React.FC<DialogProps> = ({ children, ...props }) => {
  return (
    <Dialog fullScreen TransitionComponent={Transition} {...props}>
      <Box m={5} component={Container} maxWidth="lg">
        <BackButton
          onClick={() => props.onClose && props.onClose({}, 'backdropClick')}
          needsConfirmation
        />
        <Container maxWidth="md">
          <>{children}</>
        </Container>
      </Box>
    </Dialog>
  );
};

export default CreateDialog;
