export const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate;

export enum HorizontalAnchorPosition {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum VerticalAnchorPosition {
  Center = 'center',
  Bottom = 'bottom',
  Top = 'top',
}

export const SelectionHookProperties = {
  id: '_selector',
  disableResizing: true,
  disableGroupBy: true,
  minWidth: 45,
  width: 45,
  maxWidth: 45,
};
