import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Container,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import { ProjectStatusAction } from '../../../../models/common';
import completedIcon from '../../../../resources/images/completed-check.svg';
import closeIcon from '../../../../resources/images/close-icon.svg';

type IProps = {
  status: ProjectStatusAction;
  transitionComplete: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles(() => ({
  flex: { display: 'flex', justifyContent: 'center' },
  flexRow: { flexDirection: 'row' },
  header: { padding: '2rem 0 0 0' },
  progress: {
    padding: '10% 0',
    alignContent: 'center',
    justifyContent: 'center',
  },
  empty: {
    height: '3rem',
  },
  completed: {
    position: 'absolute',
    zIndex: 10,
    right: '-10px',
    top: 0,
  },
}));

const StyledLinearProgress = withStyles({
  root: {
    borderRadius: '20px',
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: '#1FB4B1',
  },
  barColorSecondary: {
    backgroundColor: '#F37223',
  },
})(LinearProgress);

const ProjectTransitioning: React.FC<IProps> = ({
  status,
  transitionComplete = false,
  handleClose,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(transitionComplete ? 100 : 0);
  const [completed, setCompleted] = useState(false);
  const action = status === ProjectStatusAction.Convert ? 'Converting' : 'Demoting';
  const finish = status === ProjectStatusAction.Convert ? 'converted' : 'demoted';

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 75) {
          return oldProgress;
        }
        const diff = 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // makes sure the bar load to 100 before changing colors / message
    if (progress === 100) {
      setTimeout(() => {
        setCompleted(true);
      }, 300);
    }
  }, [progress]);

  return (
    <Container maxWidth="md">
      <Paper>
        <Grid container justify="center">
          <Grid container item className={clsx(classes.header, classes.flex, classes.flexRow)}>
            <Grid item xs={1} />
            <Grid item xs={10} style={{ display: 'flex', justifyContent: 'center' }}>
              {completed ? (
                <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
                  {`Lead ${finish}!`}
                </Typography>
              ) : (
                <Typography variant={'h3'}>{`${action} this lead...`}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ opacity: completed ? 100 : 0 }}
              onClick={completed ? handleClose : undefined}
            >
              <img src={closeIcon} alt={'close modal'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={clsx(classes.flex, classes.flexRow, classes.progress)}>
          <Grid item xs={10} style={{ alignSelf: 'center', position: 'relative' }}>
            <Grid style={{ alignSelf: 'center', height: '80px' }}>
              <StyledLinearProgress
                variant="determinate"
                value={progress}
                style={{ height: '2rem', top: '20px' }}
                color={completed ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid style={{ opacity: completed ? 100 : 0 }} className={classes.completed}>
              <img src={completedIcon} alt={'completed'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.empty} />
      </Paper>
    </Container>
  );
};

export default ProjectTransitioning;
