import type { IResponse } from './api';
import API from './api';

import { FilterData, FilterQuery } from '../types';
import type {
  IStakeholderResponse,
  IOrganizationResponse,
  IProjectResponse,
  IPropertyResponse,
  IStakeholderRequest,
} from '../models';
import { Stakeholder, Organization, Project, Property } from '../models';
import encodeFilter from '../utils/encodeFilter';
import { removeFalsyValues } from '../utils/object';
import { convertAddressKeys } from '../utils/address';

export const getStakeholders = async ({
  sort = ['-updated_at'],
  offset = 0,
  limit = 10,
  query,
}: FilterQuery): Promise<IResponse<Stakeholder>> => {
  const filterData: FilterQuery = {
    limit,
    offset,
    sort,
  };

  if (query) {
    filterData.query = convertAddressKeys(query) as FilterData;
  }
  const { data } = await API.get('/stakeholders', {
    params: {
      filter: encodeFilter(filterData),
    },
  });
  const events = (data as IResponse<IStakeholderResponse>).items.map(item => new Stakeholder(item));
  return { items: events, total: data.total };
};

export const createStakeholder = async (params: IStakeholderRequest): Promise<Stakeholder> => {
  const { data } = await API.post(`/stakeholders`, removeFalsyValues(params));
  return new Stakeholder(data as IStakeholderResponse);
};

export const getStakeholderById = async (id: string): Promise<Stakeholder> => {
  const { data } = await API.get(`/stakeholders/${id}`);
  return new Stakeholder(data as IStakeholderResponse);
};

export const updateStakeholderById = async (
  id: string,
  params: IStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.patch(`/stakeholders/${id}`, removeFalsyValues(params));
  return new Stakeholder(data as IStakeholderResponse);
};

export const getStakeholderOrganizations = async (id: string): Promise<Organization[]> => {
  const { data } = await API.get(`/stakeholders/${id}/organizations`);
  return (data as IResponse<IOrganizationResponse>).items.map(item => new Organization(item));
};

export const createStakeholderOrganization = async (
  id: string,
  organization: Partial<Organization>,
): Promise<Organization> => {
  const { data } = await API.post(`/stakeholders/${id}/organizations`, organization);
  return new Organization(data as IOrganizationResponse);
};

export const addStakeholderOrganization = async (
  id: string,
  organizationId: string,
  role: string,
): Promise<Organization> => {
  const { data } = await API.post(`/stakeholders/${id}/organizations/${organizationId}`, {
    stakeholder_role: role,
  });
  return new Organization(data as IOrganizationResponse);
};
export const getStakeholderProjects = async (id: string): Promise<Project[]> => {
  const { data } = await API.get(`/stakeholders/${id}/projects`);
  return (data as IResponse<IProjectResponse>).items.map(item => new Project(item));
};

export const getStakeholderProperties = async (id: string): Promise<Property[]> => {
  const { data } = await API.get(`/stakeholders/${id}/properties`);
  return (data as IResponse<IPropertyResponse>).items.map(item => new Property(item));
};
