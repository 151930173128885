import { BaseModel } from '.';

import { IBaseModelResponse } from './baseModel';

export type ITopKeywordsResponse = {
  positive: string[];
  negative: string[];
};

export type IKeywordReponse = IBaseModelResponse & {
  event_id: string;
  score: number;
  word: string;
};

export default class Keyword extends BaseModel {
  eventId: string;
  score: number;
  word: string;

  static get modelName(): string {
    return 'keyword';
  }

  constructor(data: IKeywordReponse) {
    super(data);
    const { event_id, score, word } = data;
    this.eventId = event_id;
    this.score = score;
    this.word = word;
  }
}
