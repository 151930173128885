import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import {
  withStyles,
  Paper,
  Tabs,
  Tab,
  Box,
  InputBase,
  InputAdornment,
  CircularProgress,
  Grid,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import { getOrganizations } from '../../../api/organizations';
import { getStakeholders } from '../../../api/stakeholders';
import PaperBody from '../../Common/PaperBody';
import OrganizationCard from '../OrganizationDetails/OrganizationCard';
import StakeholderCard from '../StakeholderDetails/StakeholderCard';
import useDebounce from '../../../utils/debounce';
import { useMessages } from '../../../state/contexts';
import { Organization, Stakeholder } from '../../../models';
import { light, darkLight, primaryBlue, white } from '../../../theme';
import { getNetworkPagePath } from '../../../utils/page';

const StyledBox = withStyles({
  root: {
    padding: 8,
    borderBottom: `1px solid ${light}`,
  },
})(Box);

const ResultsHolder = withStyles({
  root: {
    marginTop: 16,
  },
})(Grid);

const StyledTab = withStyles({
  root: {
    backgroundColor: 'white !important',
    color: darkLight,
    fontWeight: 'bold',
    fontSize: '1.21rem',
    minHeight: 10,
    '&:hover': {
      backgroundColor: 'white !important',
      color: `${primaryBlue} !important`,
      borderRadius: 5,
    },
  },
  selected: {
    backgroundColor: 'white !important',
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    overflow: 'hidden',
  },
  wrapper: {
    border: 'none',
    padding: 0,
  },
})(Tab);

const StyledTabs = withStyles({
  root: {
    minWidth: '90%',
    minHeight: 10,
    backgroundColor: 'white !important',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 0,
  },
  indicator: {
    backgroundColor: white,
  },
})(Tabs);

const SearchField = withStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    'label + &': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: white,
    border: `1px solid ${darkLight}`,
    fontSize: '0.9em',
    fontWeight: 'bold',
    padding: '16px 8px',
    borderColor: 'transparent',
  },
})(InputBase);

interface IProps {
  showNetworkLink?: boolean;
}

const NetworkSearch: React.FC<IProps> = ({ showNetworkLink = false }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = useCallback((evt: ChangeEvent<any>, value: number) => {
    evt.preventDefault();
    setActiveTab(value);
  }, []);

  const [query, setQuery] = useState<Record<string, string>>({});
  const [fetching, setFetching] = useState<boolean>(false);
  const debouncedQuery = useDebounce(query, 500);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const { setErrorMessage } = useMessages();

  const handleQueryChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQuery({ ...query, [activeTab]: evt.target.value });
  };

  const fetchOrganizations = useCallback(
    async q => {
      setFetching(true);
      try {
        const { items } = await getOrganizations({
          query: {
            name: { cicontains: q },
          },
        });
        setOrganizations(items);
        setFetching(false);
      } catch (err: any) {
        setErrorMessage('Error fetching accounts', err);
        setFetching(false);
      }
    },
    [setErrorMessage],
  );

  const fetchStakeholders = useCallback(
    async q => {
      setFetching(true);
      try {
        const { items } = await getStakeholders({
          query: {
            name: { cicontains: q },
          },
        });
        setStakeholders(items);
        setFetching(false);
      } catch (err: any) {
        setErrorMessage('Error fetching contacts', err);
        setFetching(false);
      }
    },
    [setErrorMessage],
  );

  useEffect(() => {
    if (debouncedQuery) {
      const q = get(debouncedQuery, `[${activeTab}]`, '');
      switch (activeTab) {
        case 0:
          if (q) {
            fetchOrganizations(q);
          } else {
            setOrganizations([]);
          }
          break;
        case 1:
          if (q) {
            fetchStakeholders(q);
          } else {
            setStakeholders([]);
          }
          break;
      }
    }
  }, [debouncedQuery, activeTab, fetchOrganizations, fetchStakeholders]);

  let results = null;

  if (activeTab === 0) {
    results = organizations.map(organization => (
      <Grid item key={organization.id}>
        <OrganizationCard organization={organization} />
      </Grid>
    ));
  } else if (activeTab === 1) {
    results = stakeholders.map((stakeholder, idx) => (
      <Grid item key={stakeholder.id}>
        <StakeholderCard stakeholder={stakeholder} isOdd={!(idx % 2)} />
      </Grid>
    ));
  }

  return (
    <Paper>
      <StyledBox p={3}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <StyledTabs
              value={activeTab}
              aria-label="Search"
              textColor="primary"
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              <StyledTab
                label="Accounts"
                id="accounts-tab"
                key="accounts-tab"
                aria-controls="tabpanel-accounts-tab"
              />
              <StyledTab
                label="Contacts"
                id="contacts-tab"
                key="contacts-tab"
                aria-controls="tabpanel-contacts-tab"
              />
            </StyledTabs>
          </Grid>
          {showNetworkLink && (
            <Grid item>
              <Button color="primary" component={Link} to={getNetworkPagePath()}>
                See Network
              </Button>
            </Grid>
          )}
        </Grid>
      </StyledBox>
      <PaperBody>
        <SearchField
          value={get(query, `[${activeTab}]`, '')}
          onChange={handleQueryChange}
          placeholder={`Search ${activeTab === 0 ? 'Accounts' : 'Contacts'}`}
          endAdornment={
            <InputAdornment position="start">
              {fetching ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <SearchIcon color="secondary" />
              )}
            </InputAdornment>
          }
        />
        <ResultsHolder container direction="column" spacing={2}>
          {results}
        </ResultsHolder>
      </PaperBody>
    </Paper>
  );
};

export default NetworkSearch;
