import { styled, Box } from '@material-ui/core';
import React, { memo } from 'react';

import {
  Text,
  SectionHeader,
  Title,
  BoldContainer,
  Container,
} from '../../Common/StyleGuideStyles';

const TextXs = styled(Text)({
  fontSize: 10,
});

const TextS = styled(Text)({
  fontSize: 11,
});

const TextM = styled(Text)({
  fontSize: 13,
});

const TextML = styled(Text)({
  fontSize: 15,
});

const TextL = styled(Text)({
  fontSize: 17,
});

const TextXL = styled(Text)({
  fontSize: 22,
});

const TextXXL = styled(Text)({
  fontSize: 36,
});

type TextStyleProp = {
  fontFamilyName: string;
};
/**
 * This files servers for representing styling guide for the text
 */
const TextStyle = memo(function TextStyle({ fontFamilyName }: TextStyleProp) {
  return (
    <div>
      <SectionHeader>Text</SectionHeader>
      <Box boxShadow={0}>
        <Container>
          <Title>{fontFamilyName} Regular</Title>
          <TextXs>XS - 10px</TextXs>
          <TextS>XS - 11px</TextS>
          <TextM>XS - 13px</TextM>
          <TextML>XS - 15px</TextML>
          <TextL>XS - 17px</TextL>
          <TextXL>XS - 22px</TextXL>
          <TextXXL>XXL - 36px</TextXXL>
        </Container>
        <BoldContainer>
          <Title>{fontFamilyName} Bold</Title>
          <TextXs>XS - 10px</TextXs>
          <TextS>XS - 11px</TextS>
          <TextM>XS - 13px</TextM>
          <TextML>XS - 15px</TextML>
          <TextL>XS - 17px</TextL>
          <TextXL>XS - 22px</TextXL>
          <TextXXL>XXL - 36px</TextXXL>
        </BoldContainer>
      </Box>
    </div>
  );
});

export default TextStyle;
