import { makeStyles } from '@material-ui/core';

import { black, darkGray, primary, primaryBlue, success } from '../../../theme';

const useUniversalSearchStyles = makeStyles({
  root: {
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchInput: {
    backgroundColor: 'white',
    border: `1px solid ${primary}`,
    borderRight: 0,
    borderRadius: '4px 0 0 4px',
    width: '14rem',
    height: '30px',
    color: darkGray,
    fontSize: '.8rem',
    fontWeight: 'normal',
    paddingLeft: '10px',
  },
  searchButton: {
    minWidth: '41px',
    border: `1px solid ${success}`,
    borderLeft: 0,
    borderRadius: '0 4px 4px 0',
    textTransform: 'none',
    backgroundColor: success,
    color: primaryBlue,
    width: '41px',
    height: '30px',
    '&:hover': {
      backgroundColor: '#D9F2EF',
      color: black,
    },
  },
});

export default useUniversalSearchStyles;
