import { makeStyles } from '@material-ui/core/styles';

const useDropDownOptionsStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    position: 'absolute',
    border: `.5px solid black`,
    borderRadius: '4px',
    margin: '1rem',
    marginTop: 0,
    width: '20rem',
  },
  accordion: {
    width: '100%',
    padding: 0,
    '& .MuiFormGroup-root': {
      width: '100%',
    },
  },
  summary: {
    fontWeight: 'bold',
    minHeight: 'auto',
    height: '2rem',
    paddingLeft: '1rem',
  },
  section: {
    flexDirection: 'column',
    width: '100%',
  },
  radio: {
    paddingLeft: '1rem',
  },
  checkmark: {
    paddingLeft: '2rem',
  },
}));

export default useDropDownOptionsStyles;
