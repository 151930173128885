import React, { useCallback, useState } from 'react';
import { makeStyles, Button, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ContactDetailMapping } from '../../../constants';
import type { ContactDetailType } from '../../../models/common';
import { darkLight, paleGray, primaryBlue } from '../../../theme';

interface IProps {
  onSelect: (type: ContactDetailType) => void;
}

const useStyles = makeStyles({
  addButton: {
    backgroundColor: paleGray,
    color: primaryBlue,
    borderColor: darkLight,
    maxWidth: 180,
  },
});

const AddContactDetailButton: React.FC<IProps> = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSelect = useCallback(
    (type: ContactDetailType) => {
      setAnchorEl(null);
      onSelect(type);
    },
    [onSelect],
  );

  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.addButton}
        variant="outlined"
        fullWidth
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
      >
        <AddIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {Object.entries(ContactDetailMapping).map(([key, value]) => (
          <MenuItem key={key} onClick={() => handleSelect(key as ContactDetailType)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddContactDetailButton;
