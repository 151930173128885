import { atom } from 'recoil';

// location selection type (country, state, county),
// object type (project lead, address, account),
// object sources ( dodge, cmd, cw)
// location name (US, CAN, CA, WA, etc)

export enum MapDisplayLevel {
  Country = 'country',
  State = 'state',
  County = 'county',
}

export enum MapObjectType {
  ProjectLeads = 'project leads',
  Addresses = 'addresses',
  Accounts = 'accounts',
}

export enum MapObjectSource {
  Dodge = 'dodge',
  CMD = 'cmd',
  // CW = 'cw',
}

export interface MapObjectSourceState {
  dodge: boolean;
  cmd: boolean;
  // cw: boolean;
}

export const selectedLocationTypeState = atom({
  key: 'selectedLocationType',
  default: MapDisplayLevel.Country as MapDisplayLevel,
});

export const selectedMapObjectTypeState = atom({
  key: 'selectedMapObjectType',
  default: MapObjectType.ProjectLeads as MapObjectType,
});

export const selectedMapObjectSourceState = atom({
  key: 'selectedMapObjectSource',
  default: {
    dodge: true,
    cmd: false,
    cw: false,
  } as MapObjectSourceState,
});

export const selectedLocationNameState = atom({
  key: 'selectedLocationName',
  default: 'US',
});
