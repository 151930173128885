import { GridProps } from '@material-ui/core';

export const bulkConfirmTableConfig: {
  id: string;
  label: string;
  gridProps: GridProps;
  textAlign: 'left' | 'right' | 'center';
}[] = [
  {
    id: 'projectName',
    label: 'Project Name',
    gridProps: { md: 3 },
    textAlign: 'left',
  },
  {
    id: 'location',
    label: 'Location',
    gridProps: { md: 2 },
    textAlign: 'left',
  },
  {
    id: 'valuation',
    label: 'Valuation',
    gridProps: { md: 1 },
    textAlign: 'left',
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    gridProps: { md: 2 },
    textAlign: 'left',
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 2 },
    textAlign: 'left',
  },
  {
    id: 'projectType',
    label: 'Project Type',
    gridProps: { md: 2 },
    textAlign: 'left',
  },
];
