import React from 'react';
import type { NumberFormatProps } from 'react-number-format';
import NumberFormat from 'react-number-format';
import { makeStyles, TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core/TextField';

import FormInput from './FormInput';

export type IProps = TextFieldProps & NumberFormatProps;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  input: {
    padding: 0,
  },
}));

const FormNumberField: React.FC<IProps> = ({ label, ...props }) => {
  const { name, fullWidth } = props;
  const classes = useStyles();
  return (
    <FormInput name={name} label={label} fullWidth={fullWidth}>
      {({ onChange, ...inputProps }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <NumberFormat
          onValueChange={({ value }) => onChange(inputProps.name)(value)}
          customInput={TextField}
          variant="outlined"
          InputProps={{ classes }}
          {...inputProps}
          {...props}
        />
      )}
    </FormInput>
  );
};

export default FormNumberField;
