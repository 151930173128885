import { toTitleCase } from '../../../utils/string';
import { ProjectLeadsStatusTab } from '../../../models/projectLead';
import { StatusBarTabItem } from '../Leads/LeadsTable/StatusBar';
import { HeadCell } from '../../Common/Tables/Table';
import PvfProject from '../../../models/pvfProject';
import {
  renderDate,
  renderEvent,
  renderItem,
  renderNumber2,
  renderProjectScore,
  renderPropertyPL,
} from '../Leads/LeadsTable/LeadTableRowRenderers';
import { Variant } from '../../../types/typography';
import { ScoreStatus } from '../../../models/lead';

const typographyVariant: Variant = 'body1';

export const ProjectLeadDetailsMenu: StatusBarTabItem<ProjectLeadsStatusTab>[] = [
  {
    status: ProjectLeadsStatusTab.Details,
    text: toTitleCase(ProjectLeadsStatusTab.Details),
    defaultFilter: {},
  },
  {
    status: ProjectLeadsStatusTab.Addresses,
    text: toTitleCase(ProjectLeadsStatusTab.Addresses),
    defaultFilter: {},
  },
  // TODO: Remove disabled when tabs are ready to use
  {
    status: ProjectLeadsStatusTab.Documents,
    text: toTitleCase(ProjectLeadsStatusTab.Documents),
    defaultFilter: {},
  },
  {
    status: ProjectLeadsStatusTab.Notes,
    text: toTitleCase(ProjectLeadsStatusTab.Notes),
    defaultFilter: {},
    disabled: true,
  },
];

export const projectLeadDetailsHeadCellsOnAddressTab: HeadCell<PvfProject, PvfProject>[] = [
  {
    id: 'projectScore',
    label: 'Project Score',
    gridProps: { md: 2 },
    headerStyle: { paddingLeft: '1rem' },
    renderRow: {
      function: renderProjectScore,
      param: ['projectScore'],
      noData: 0,
      style: { textAlign: 'center' },
    },
  },
  {
    id: 'projectName',
    label: 'Project Name',
    gridProps: { xs: 4, md: 2 },
    headerStyle: { paddingLeft: '1rem' },
    renderRow: {
      function: renderItem,
      param: ['projectName'],
      additionalParam: [true, typographyVariant],
      noData: '',
      style: { paddingLeft: '1rem', paddingTop: '1rem', alignSelf: 'flex-start' },
    },
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderDate,
      param: ['bidDate'],
      additionalParam: [typographyVariant, 'primary'],
      noData: '',
    },
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['actionStage'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 2 },
    renderRow: {
      function: renderNumber2,
      param: ['value'],
      additionalParam: [true, typographyVariant],
      noData: 0,
    },
  },
  {
    id: 'latestEventDate',
    label: 'Latest Event',
    gridProps: { md: 2 },
    renderRow: {
      function: renderEvent,
      param: ['dataSource', 'latestEventDate', 'projectNote'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'fullAddress',
    label: 'Location',
    gridProps: { md: 2 },
    renderRow: {
      function: renderPropertyPL,
      param: ['address1', 'city', 'state'],
      additionalParam: [typographyVariant],
      noData: '',
      style: {
        paddingLeft: '2rem',
      },
    },
  },
];

export const projectConvertedHeadCellsOnAddressTab: HeadCell<PvfProject, PvfProject>[] = [
  {
    id: 'projectScore',
    label: 'Project Score',
    gridProps: { md: 2 },
    headerStyle: { paddingLeft: '1rem' },
    renderRow: {
      function: renderProjectScore,
      param: ['projectScore'],
      noData: 0,
      style: { textAlign: 'center' },
    },
  },
  {
    id: 'fullAddress',
    label: 'Location',
    gridProps: { md: 2 },
    renderRow: {
      function: renderPropertyPL,
      param: ['address1', 'city', 'state'],
      additionalParam: [typographyVariant],
      noData: '',
      style: {
        paddingLeft: '2rem',
      },
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 2 },
    renderRow: {
      function: renderNumber2,
      param: ['value'],
      additionalParam: [true, typographyVariant],
      noData: 0,
    },
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderDate,
      param: ['bidDate'],
      additionalParam: [typographyVariant, 'primary'],
      noData: '',
    },
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['actionStage'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'primaryProjectType',
    label: 'Project Type',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['primaryProjectType'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'transitionDate',
    label: 'Transition Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['transitionDate'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'transitionReason',
    label: 'Reason',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['transitionDate'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'projectStatus',
    label: 'Status',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['projectStatus'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
];

export const projectDemotedHeadCellsOnAddressTab: HeadCell<PvfProject, PvfProject>[] = [
  {
    id: 'fullAddress',
    label: 'Location',
    gridProps: { md: 2 },
    renderRow: {
      function: renderPropertyPL,
      param: ['address1', 'city', 'state'],
      additionalParam: [typographyVariant],
      noData: '',
      style: {
        paddingLeft: '2rem',
      },
    },
  },
  {
    id: 'projectName',
    label: 'Project Name',
    gridProps: { xs: 4, md: 2 },
    headerStyle: { paddingLeft: '1rem' },
    renderRow: {
      function: renderItem,
      param: ['projectName'],
      additionalParam: [true, typographyVariant],
      noData: '',
      style: { paddingLeft: '1rem', paddingTop: '1rem', alignSelf: 'flex-start' },
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 2 },
    renderRow: {
      function: renderNumber2,
      param: ['value'],
      additionalParam: [true, typographyVariant],
      noData: 0,
    },
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderDate,
      param: ['bidDate'],
      additionalParam: [typographyVariant, 'primary'],
      noData: '',
    },
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['actionStage'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'primaryProjectType',
    label: 'Project Type',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['primaryProjectType'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'transitionDate',
    label: 'Transition Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['transitionDate'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'transitionReason',
    label: 'Reason',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['transitionDate'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
];

export const addressTabsOnProjectLeadDetails: StatusBarTabItem<ScoreStatus>[] = [
  {
    status: ScoreStatus.Active,
    text: toTitleCase(ScoreStatus.Active),
    defaultFilter: {},
  },
  {
    status: ScoreStatus.Converted,
    text: toTitleCase(ScoreStatus.Converted),
    defaultFilter: {},
  },
  {
    status: ScoreStatus.Demoted,
    text: toTitleCase(ScoreStatus.Demoted),
    defaultFilter: {},
  },
];
