import { HeadCell } from '../../../../Common/Tables/Table';
import {
  renderDate,
  renderNumber2,
  renderPropertyPL,
  renderItem,
  confirmHideWNationalIcon,
} from '../../../Leads/LeadsTable/LeadTableRowRenderers';
import PvfProject from '../../../../../models/pvfProject';
import { ArchivedPLTableCols } from '../PLArchive.config';
import { Variant } from '../../../../../types/typography';

const typographyVariant: Variant = 'body1';

export const plDemotedHeadCells: HeadCell<ArchivedPLTableCols, PvfProject>[] = [
  {
    id: 'projectName',
    label: 'Project Name',
    gridProps: { xs: 4, md: 2 },
    headerStyle: { paddingLeft: '1rem' },
    renderRow: {
      function: renderItem,
      param: ['projectName'],
      additionalParam: [true, typographyVariant],
      noData: '',
      style: { paddingLeft: '1rem', paddingTop: '1rem', alignSelf: 'flex-start' },
    },
  },
  {
    id: 'property',
    label: 'Location',
    gridProps: { xs: 5, md: 2 },
    renderRow: {
      function: renderPropertyPL,
      param: ['address1', 'city', 'state'],
      additionalParam: [typographyVariant],
      noData: '',
      style: {
        paddingLeft: '2rem',
      },
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 2 },
    renderRow: {
      function: renderNumber2,
      param: ['value'],
      additionalParam: [true, typographyVariant],
      noData: 0,
    },
  },
  {
    id: 'biddate',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderDate,
      param: ['bidDate'],
      additionalParam: [typographyVariant, 'primary'],
      noData: '',
    },
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['actionStage'],
      additionalParam: [true, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'categories',
    label: 'Project Type',
    gridProps: { md: 1 },
    headerStyle: {},
    renderRow: {
      function: renderItem,
      param: ['categories'],
      additionalParam: [true, typographyVariant],
      noData: '',
      style: {
        lineBreak: 'anywhere',
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'transitionDate',
    label: 'Transition Date',
    gridProps: { md: 1 },
    disableSort: true,
    renderRow: {
      function: renderDate,
      param: ['transitionDate'],
      additionalParam: [typographyVariant, 'primary'],
      noData: '',
    },
  },
  {
    id: 'transitionReason',
    label: 'Reason',
    gridProps: { md: 1 },
    disableSort: true,
    renderRow: {
      function: renderItem,
      param: ['transitionReason'],
      additionalParam: [false, typographyVariant],
      noData: '',
    },
  },
  {
    id: 'hide',
    label: '',
    gridProps: { md: 1 },
    textAlign: 'right',
    headerStyle: {},
    renderRow: {
      function: confirmHideWNationalIcon,
      param: ['projectName', 'projectId', 'hasNationalAccount', 'hasNationalTargetAccount'],
      noData: '',
      reloadItems: true,
      style: {
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
];
