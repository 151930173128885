import { makeStyles } from '@material-ui/core';

const useTransitionButtonsActiveStyles = makeStyles(theme => ({
  transitionButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  transitionButtons: {
    margin: theme.spacing(0, 2),
    height: theme.spacing(7),
    width: '9rem',
  },
  salesforceLogoButton: {
    width: '1rem',
    padding: '0',
  },
  tooltip: {
    border: '1px solid rgb(31, 180, 177)',
    background: 'white',
    color: 'black',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  salesforceLogo: {
    width: '55%',
  },
  arrowTip: {
    color: 'rgb(31, 180, 177)',
  },
}));

export default useTransitionButtonsActiveStyles;
