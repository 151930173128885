import type { Optional } from '../types';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatArea = (feet?: string | number): Optional<string> =>
  feet ? Number(feet).toLocaleString() : undefined;

export const formatCurrency = (dollars?: string | number): Optional<string> =>
  dollars !== undefined ? currencyFormatter.format(Number(dollars)).replace('.00', '') : undefined;
