import React from 'react';
import { Grid } from '@material-ui/core';

import LeadCard from './LeadCard';

import type { IProps as CardListProps } from '../../../Common/CardList';
import CardList from '../../../Common/CardList';
import type { Property } from '../../../../models';

interface IProps extends CardListProps {
  properties: Property[];
}

const LeadCardList: React.FC<IProps> = ({ properties, ...props }) => {
  return (
    <CardList {...props}>
      {properties.map(property => (
        <Grid item key={property.id}>
          <LeadCard property={property} />
        </Grid>
      ))}
    </CardList>
  );
};

export default LeadCardList;
