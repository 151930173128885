export const toTitleCase = (str?: string): string =>
  str?.replace(/\w\S*/g, txt => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`) ||
  '';

export const toTitleCaseHyphen = (str?: string): string =>
  str?.replace(/(\b[a-z](?!\s))/g, txt => txt.toUpperCase()) || '';

export const toSentence = (sentence?: string): string =>
  sentence ? `${sentence.charAt(0).toUpperCase()}${sentence.substr(1).toLowerCase()}` : '';

/**
 * Keyword casing is split between two groups, upper and lower cased.
 * Upper cased keywords tend to be abbreviations, their casing is correct.
 * Lower cased keywords are regular words. They're all lower and need to be title cased.
 *
 * @param {string} keyword - the keyword for which to validate casing
 * @returns {string}
 */
export const keywordCasing = (keyword: string): string =>
  /[A-Z]/.test(keyword) ? keyword : (toTitleCase(keyword) as string);
