import { DateTime } from 'luxon';
import * as Yup from 'yup';

import { User } from '../models';
import { CleoObjectType } from '../models/common';

export type County = {
  county: string;
  state: string;
};

export type ActionStage = {
  action: string;
  subAction: string;
};

export type FilterOptionsResponse = {
  keywords: string[];
  projectTypes: string[];
  regions: string[];
  specAlerts: string[];
  actionStages: ActionStage[];
};

export type FilterItem = {
  filterKey: FilterKey;
  filterLabel: string;
  multiple: boolean;
  options: FilterOptionsItem[];
  validation?: Yup.ObjectSchema;
  addSearch?: boolean;
  model: CleoObjectType[]; // Array of CleoObjectTypes that this FilterItem applies to
};

export enum FilterKey {
  Location = 'location', // only used for AddSearchForm to condense all location filters into one
  CountyState = 'countyState',
  State = 'state',
  Region = 'region',
  Score = 'score',
  EventSource = 'eventSource',
  OwnerId = 'ownerId',
  Value = 'value',
  LastUpdated = 'lastUpdated',
  ActionStage = 'actionStage',
  ActionStageAccounts = 'actionStageAccounts',
  SubActionStage = 'subActionStage',
  SpecAlerts = 'specAlerts',
  Keywords = 'keywords',
  ProjectTypes = 'projectTypes',
  AccountRatings = 'accountRatings',
  Province = 'province',
  AccountTypes = 'accountTypes',
  VolumeOfBusiness = 'volume_of_business',
  SpecRates = 'specRates',
  ArmstrongSpecRate = 'armstrongSpecRate',
  CertainteedSpecRate = 'certainteedSpecRate',
  RockfonSpecRate = 'rockfonSpecRate',
  USGSpecRate = 'usgSpecRate',
  ActiveProjects = 'activeProjectsCount',
  PreviousWins = 'previousWins',
  ProjectScoresAccounts = 'projectScoresAccounts',
}

export const PreviousWinFilterTag = {
  label: 'Previous Wins',
  key: FilterKey.PreviousWins,
  value: 0,
  comparator: 'gt',
  filterValue: 0,
  isInput: false,
} as TagsDisplay;

export type OptionValue = string | number | number[] | DateTime | null;

export type FilterOptionsItem = {
  id?: string;
  label?: string;
  selectionId?: string;
  inputType: FilterItemInputType;
  value?: OptionValue;
  formatter?: (val: OptionValue) => string;
  unformatter?: (val: OptionValue) => string | number;
  comparator: Comparators;
  multiple?: boolean;
  filterKey?:
    | FilterKey.State
    | FilterKey.CountyState
    | FilterKey.ActionStage
    | FilterKey.SubActionStage
    | undefined; //For Location Search Filter - specify state or county
};

export enum FilterItemInputType {
  Select = 'select',
  Input = 'input',
}

export enum Comparators {
  Eq = 'eq',
  Neq = 'neq',
  Gt = 'gt',
  Lt = 'lt',
  Gte = 'gte',
  Lte = 'lte',
  Cieq = 'cieq',
  Contains = 'contains',
  Cicontains = 'cicontains',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  In = 'in',
  Nin = 'nin',
  Is = 'is',
  Nis = 'nis',
  Between = 'between', // Front-end only, used to handle ranges
}

export type FilterData = {
  [key: string]: FilterValue;
};

export type FilterDataOr =
  | {
      or?: FilterData[];
    }
  | FilterData;

export type FilterValue = {
  gt?: number | string; // The value is greater than number or a date string
  gte?: number | string; // The value is greater than or equal to the number or a date string
  lt?: number | string; // The value is less than the number or a date string
  lte?: number | string; // The value is less than or equal to the number or a date string
  eq?: string | number | boolean; // The value strictly equals the string or number or a date string
  neq?: string | number | boolean | null; // The value strictly does not equal the string or number or a date string or null
  cieq?: string; // The value case-insensitive equals the string (ILIKE)
  contains?: string | number; // The value contains the string or number (LIKE)
  cicontains?: string | number; // The value case-insensitive contains the string or number (ILIKE)
  startsWith?: string; // The value starts with the string (LIKE)
  endsWith?: string; // The value ends with the string (LIKE)
  in?: string[] | number[]; // The value is in the array passed
  nin?: string[]; // The value is NOT in the array passed
  selectionId?: string;
  is?: string | null;
  nis?: string | null;
  between?: number[][]; // Frontend only, convert to gt, lte combo
};

export interface FilterQuery {
  sort?: string[];
  offset?: number;
  limit?: number;
  include?: string[];
  query?: FilterData | FilterDataOr;
}

export interface TagsDisplay {
  label: string;
  key: string;
  value: number | string;
  comparator: string;
  filterValue: any;
  isInput: false;
  id?: string;
}

export interface FilterTagsResponse {
  tags: TagsDisplay[];
}

export enum FilterType {
  Address = 'address',
  ProjectLead = 'project_lead',
  Account = 'account',
}

export interface UserFilterNameRequest {
  user_id: string;
  filter_name: string;
  filter: FilterData;
  shared: boolean;
  filter_type: FilterType;
  filter_tags: FilterTagsResponse;
  is_public: boolean;
}

export interface UserFilterNameResponse {
  created_at: string;
  filter: FilterData;
  filter_name: string;
  id: string;
  shared: boolean;
  updated_at: string;
  user_id: string;
  user: User;
  filter_tags: FilterTagsResponse;
  is_public: boolean;
}
