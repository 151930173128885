export enum Roles {
  NA = '',
  Applicant = 'APPLICANT',
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Designer = 'DESIGNER',
  Engineer = 'ENGINEER',
  Installer = 'INSTALLER',
  Owner = 'OWNER',
  OwnerBldr = 'OWNER-BLDR',
  Principal = 'PRINCIPAL',
  Subcontractor = 'SUBCONTRACTOR',
  Other = 'OTHER',
}

export const types = [
  '',
  'object',
  'stakeholders',
  'events',
  'payload',
  'feedbacks',
  'organizations',
  'projects',
  'properties',
  'users',
];

export const stakeholderFields = [
  '',
  'title',
  'organization',
  'stakeholder_role',
  'is_primary',
  'description',
  'phone',
  'name',
  'first_name',
  'last_name',
  'email',
  'website',
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
];

export const eventFields = [
  '',
  'score',
  'external_id',
  'source',
  'type',
  'description',
  'event_date',
  'stakeholders',
  'property',
];

export const payloadFields = ['', 'project_valuation', 'category', 'latest_status', 'status_date'];

export const propertyFields = [
  '',
  'name',
  'address_1',
  'address_2',
  'street_number',
  'street_direction',
  'street_name',
  'street_suffix',
  'suffix_direction',
  'unit_number',
  'city',
  'state',
  'zipcode',
  'lat',
  'long',
  'is_bookmarked',
  'phone',
  'size',
  'type',
  'valuation',
  'total_stakeholders',
  'total_events',
  'events',
  'projects',
  'score',
  'salesforce_payload',
  'estated_payload',
  'stakeholders',
];

export const projectFields = [
  '',
  'name',
  'description',
  'value',
  'type',
  'stage',
  'externals',
  'status',
  'space_type',
  'materials',
  'total_sqft',
  'ceiling_sqft',
  'creator',
  'owner',
];

export const feedbackFields = ['', 'action', 'notes', 'user', 'project_id'];

export const organizationFields = [
  '',
  'name',
  'profile_type',
  'license_type',
  'license_number',
  'license_expiration_date',
  'total_leads',
  'total_stakeholders',
  'stakeholder_role',
  'description',
  'phone',
  'email',
  'website',
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
];

export const userFields = ['', 'full_name', 'username', 'email'];

export enum DataMapperTabs {
  DataConfiguration = 0,
  DataResponse = 1,
  Preview = 2,
  RunSchedule = 3,
  Review = 4,
}
