import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  Typography,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';

import { editSavedFilterName } from '../../../../api/filters';
import { ScoreStatus } from '../../../../models/lead';
import { FilterType, UserFilterNameResponse } from '../../../../types';
import { useLeadsStyles } from '../Leads.styles';
import {
  editedFilterNameState,
  filterOptionState,
  filterTagsToDisplayState,
} from '../../../../state/atoms/filters';
import { ProjectLeadStatus } from '../../../../models/projectLead';
import { CleoObjectType } from '../../../../models/common';
import { useMessages } from '../../../../state/contexts';
import { black } from '../../../../theme';
import privateIcon from '../../../../resources/images/private.svg';

const StyledCheckbox = withStyles({
  root: {
    color: black,
    '&$checked': {
      color: black,
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

interface IProps {
  editedFilterNameError: string | undefined;
  savedFilter: UserFilterNameResponse;
  setScoreStatus: (status: ScoreStatus | ProjectLeadStatus) => void;
  setSavedFilter: (filter: UserFilterNameResponse) => void;
  setEditedFilterNameError: (filterNameError: string) => void;
  cleoObjectType?: CleoObjectType;
  filterType: FilterType;
}

function SavedFilterHeader({
  editedFilterNameError,
  savedFilter,
  setScoreStatus,
  setSavedFilter,
  setEditedFilterNameError,
  filterType,
}: IProps) {
  const classes = useLeadsStyles();
  const { user } = useAuth0();
  const [isPublicChecked, setIsPublicChecked] = useState<boolean>(savedFilter.is_public);
  const [editingSavedFilterName, setEditingSavedFilterName] = useState<boolean>(false);
  const [editedFilterName, setEditedFilterName] = useRecoilState<string>(editedFilterNameState);

  const { setSuccessMessage } = useMessages();

  const filterTagsToDisplay = useRecoilValue(filterTagsToDisplayState);
  const filterOption = useRecoilValue(filterOptionState);

  const handleEditSavedFilterName = useCallback(async () => {
    if (savedFilter && filterOption && editedFilterName.length > 0) {
      try {
        const editedFilterResponse = await editSavedFilterName(savedFilter.id, {
          user_id: savedFilter.user_id,
          filter_name: editedFilterName,
          filter: filterOption,
          shared: true,
          filter_tags: {
            tags: filterTagsToDisplay,
          },
          filter_type: filterType,
          is_public: isPublicChecked,
        });
        setEditingSavedFilterName(false);
        setScoreStatus(ScoreStatus.Home);
        setSavedFilter(editedFilterResponse);
        setSuccessMessage('Your filter name has been changed.');
      } catch (e: any) {
        if (e.response && e.response.data) {
          setEditedFilterNameError(
            e.response.data.message === 'Data already exists in database'
              ? 'Filter Name already exists, please try again'
              : e.response.data.message,
          );
        }
      }
    } else {
      setEditedFilterNameError('You must give this filter a name in order to save it');
    }
  }, [
    setSuccessMessage,
    editedFilterName,
    filterOption,
    savedFilter,
    filterTagsToDisplay,
    setEditedFilterNameError,
    setSavedFilter,
    setScoreStatus,
    filterType,
    isPublicChecked,
  ]);

  const handleCheckbox = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublicChecked(evt.target.checked);
  };

  const isStateTrue = (bool: boolean) => {
    return ((bool as unknown) as string) === 'true' || bool === true;
  };

  const handleEditFilterNameCancel = useCallback(() => {
    setEditingSavedFilterName(false);
    setEditedFilterNameError('');
    setEditedFilterName(savedFilter.filter_name);
  }, [setEditedFilterNameError, setEditedFilterName, savedFilter]);

  return (
    <Box
      className={classes.filterNameContainer}
      style={{ borderBottom: !editingSavedFilterName ? '1.5px solid #0C4377' : '' }}
    >
      <div style={{ display: 'flex' }}>
        {!editingSavedFilterName ? (
          <Typography className={classes.filterNameDisplay}>
            {savedFilter.filter_name}
            {savedFilter.user.email === user.email && (
              <sup>
                <EditIcon
                  className={classes.filterNameEditIcon}
                  onClick={() => {
                    setEditingSavedFilterName(true);
                  }}
                />
              </sup>
            )}
          </Typography>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Input
                value={
                  editedFilterName || editedFilterName === ''
                    ? editedFilterName
                    : savedFilter.filter_name
                }
                onChange={evt => setEditedFilterName(evt.currentTarget.value)}
                id={`edited-filter-value-input-display`}
                onKeyPress={ev => ev.key === 'Enter' && handleEditSavedFilterName()}
                className={
                  !editedFilterNameError
                    ? classes.filterNameEditInput
                    : classes.filterNameErrorEditInput
                }
                disableUnderline
              />
              <div className={classes.filterNameEditButtonGroup}>
                <Button
                  onClick={() => handleEditSavedFilterName()}
                  className={classes.filterNameEditSaveButton}
                  size="small"
                >
                  SAVE NAME
                </Button>
                <Button
                  onClick={() => handleEditFilterNameCancel()}
                  className={classes.filterNameEditCancelButton}
                >
                  CANCEL
                </Button>
              </div>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={isStateTrue(isPublicChecked) ? true : false} //this gets around a weird react issue that thinks booleans are strings
                    onChange={handleCheckbox}
                  />
                }
                label="Make Public?"
                style={{ width: '75%' }}
              />
            </FormControl>
            {editedFilterNameError && (
              <Typography style={{ color: '#f03434' }}>Error: {editedFilterNameError}</Typography>
            )}
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.filterNameDetails}>
            <strong>{savedFilter.user.fullName || savedFilter.user.email}</strong>
          </Typography>
          <Typography className={classes.filterNameDetails}>
            <strong>Date Created:</strong> {new Date(savedFilter.created_at).toLocaleDateString()}
          </Typography>
          <Typography className={classes.filterNameDetails}>
            <strong>Last Edited:</strong> {new Date(savedFilter.updated_at).toLocaleDateString()}
          </Typography>
        </div>
      </div>
      <div>
        {!editingSavedFilterName && !isStateTrue(savedFilter.is_public) && (
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            <strong>Private</strong>
            <img
              src={privateIcon}
              alt="Private Filter Icon"
              style={{ width: '1rem', margin: '0 .5rem .2rem .75rem' }}
            />
          </Typography>
        )}
      </div>
    </Box>
  );
}

export default SavedFilterHeader;
