import { atom, atomFamily } from 'recoil';

import {
  FilterData,
  FilterOptionsItem,
  FilterOptionsResponse,
  Optional,
  TagsDisplay,
} from '../../types';

export const currentFilterOptionsState = atom({
  key: 'currentFilterOptions',
  default: {} as FilterOptionsResponse,
});
export const filterOptionState = atom({
  key: 'filterOption',
  default: {} as Optional<FilterData>,
});

export const filtersSelectedState = atomFamily({
  key: 'currentFiltersSelected',
  default: (lsKey: string) => {
    const currentSelectedFilter = localStorage.getItem(`${lsKey}_selected`);
    if (currentSelectedFilter) {
      return currentSelectedFilter;
    } else {
      return {};
    }
  },
});

export const filtersInputedState = atomFamily({
  key: 'currentFiltersInputed',
  default: (lsKey: string) => {
    const currentInputedFilter = localStorage.getItem(`${lsKey}_inputed`);
    if (currentInputedFilter) {
      return currentInputedFilter;
    } else {
      return {};
    }
  },
});

// String is the full state name in titleCase
export const countiesInEachStateState = atom({
  key: 'countiesInEachState',
  default: {} as Record<string, FilterOptionsItem[]>,
});

export const subActionStagesInEachActionStageState = atom({
  key: 'subActionStages',
  default: {} as Record<string, FilterOptionsItem[]>,
});

export const filterItemsOpenState = atom({
  key: 'filterItemsOpen',
  default: {} as any,
});

export const filterInputsToClearState = atom({
  key: 'filterInputsToClear',
  default: [] as string[],
});

export const allKeysSelectedState = atom({
  key: 'allKeysSelected',
  default: {} as Optional<FilterData>,
});

export const allFilterTagsState = atom({
  key: 'allFilterTags',
  default: [] as TagsDisplay[],
});

export const filterTagsToDisplayState = atom({
  key: 'filterTagsToDisplay',
  default: [] as TagsDisplay[],
});

export const clearFiltersState = atom({
  key: 'clearFilters',
  default: false,
});

export const searchLocationValueState = atom({
  key: 'searchLocationValue',
  default: '',
});

export const searchStateValueState = atom({
  key: 'searchStateValue',
  default: '',
});

export const searchCountyValueState = atom({
  key: 'searchCountyValue',
  default: '',
});

export const searchRegionValueState = atom({
  key: 'searchRegionValue',
  default: '',
});

export const searchProvinceValueState = atom({
  key: 'searchProvinceValue',
  default: '',
});

export const searchSpecAlertValueState = atom({
  key: 'searchSpecAlertValue',
  default: '',
});

export const searchKeywordValueState = atom({
  key: 'searchKeywordValue',
  default: '',
});

export const searchProjectTypeValueState = atom({
  key: 'searchProjectTypeValue',
  default: '',
});

export const showMoreStateOptionsState = atom({
  key: 'showMoreStateOptions',
  default: false,
});

export const showSeeMoreLinksState = atom({
  key: 'showSeeMoreLinksState',
  default: false,
});

export const savedFilterValueState = atom({
  key: 'savedFilterValue',
  default: '',
});

export const editedFilterNameState = atom({
  key: 'editedFilterName',
  default: '',
});

export const areFiltersAppliedState = atom({
  key: 'areFiltersApplied',
  default: false,
});

export const filterNameErrorState = atom({
  key: 'filterNameError',
  default: undefined as string | undefined,
});

export const previousWinSelectedState = atom({
  key: 'previousWinSelected',
  default: false,
});
