import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { plConvertedHeadCells } from './ProjectLeadsConvertedArchive.config';

import { APICancel, createCancelToken, isCancel } from '../../../../../api';
import { getProjectViewTransitioned } from '../../../../../api/projects';
import { ScoreStatus } from '../../../../../models/lead';
import PvfProject from '../../../../../models/pvfProject';
import { getLeadDetailsPagePath } from '../../../../../utils/page';
import Table from '../../../../Common/Tables/Table';
import { SortArchive, SortOrder } from '../../../Leads/Archive/Archive.config';
import { ArchivedPLTableCols, sortColumnsPLArchive } from '../PLArchive.config';
import Loader from '../../../../Common/Loader';
import { FilterData } from '../../../../../types';

let apiCancel: APICancel;

export interface IPLConvertedProp {
  outcomeFilter: FilterData;
  setCConverted: (count: number) => void;
}

const ProjectLeadsConvertedArchive: React.FC<IPLConvertedProp> = ({
  outcomeFilter,
  setCConverted,
}: IPLConvertedProp) => {
  const rowCount = 10;
  const limit = 25;
  const history = useHistory();

  const scoreStatus = ScoreStatus.Converted;

  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<PvfProject[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [hasError, setHasError] = useState(false);

  // state for sorting
  const [orderBy, setOrderBy] = React.useState<keyof ArchivedPLTableCols>('projectName');
  const [order, setOrder] = React.useState<SortOrder>('desc');
  const [sort, setSort] = useState<SortArchive<ArchivedPLTableCols>>({
    orderBy: 'projectName',
    order: 'asc',
  });

  const handleClickRow = {
    function: (projectId: string) => {
      history.push(getLeadDetailsPagePath(projectId));
    },
    param: 'projectId' as keyof PvfProject,
    link: '/project-leads/details/',
  };

  const handleSortRequest = useCallback(
    (e: React.MouseEvent<unknown>, property: keyof ArchivedPLTableCols) => {
      e.preventDefault();
      const isAsc = orderBy === property && order === 'asc';
      setHasMoreItems(true);
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setSort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
      setStartIndex(0);
      setRows([]);
    },
    [order, orderBy, setOrder, setOrderBy, setSort, setStartIndex, setRows],
  );

  const loadItems = useCallback(
    async startIndex => {
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }

      try {
        const defaultFilter = {
          ...outcomeFilter,
          projectStatus: { eq: 'converted' },
          hide: { eq: false },
        };

        const data = await getProjectViewTransitioned(
          {
            sort,
            startIndex,
            filterOption: defaultFilter,
            limit: limit,
          },
          sortColumnsPLArchive,
          createCancelToken(cancel => {
            apiCancel = cancel;
          }),
        );

        if (!data.items) {
          throw new Error('Converted Projects not available');
        }
        setCConverted(data.total);
        const items = data.items.map(ipvf => new PvfProject(ipvf));
        setRows(rows => [...rows, ...items]);
        setLoading(false);
        if (data.items.length < rowCount) {
          setHasMoreItems(false);
        } else {
          // Take into consideration toggling between filters.
          setHasMoreItems(true);
        }
      } catch (e: any) {
        if (!isCancel(e)) {
          setHasError(true);
        }
      }
    },
    [sort, outcomeFilter, setCConverted],
  );

  const reloadItems = useCallback(async () => {
    setRows([]);
    setStartIndex(0);
    await loadItems(startIndex);
  }, [setRows, setStartIndex, loadItems, startIndex]);

  useEffect(() => {
    loadItems(startIndex);
  }, [loadItems, startIndex]);

  useEffect(() => {
    reloadItems();
    // eslint-disable-next-line
  }, [outcomeFilter]);

  return (
    <div>
      {loading && <Loader hasError={hasError} component={'table'} />}
      {!loading && (
        <div style={{ margin: '1rem 2rem 1rem 2rem' }}>
          <Table
            headerCells={plConvertedHeadCells}
            order={order}
            orderBy={orderBy}
            rowCount={rowCount}
            rows={rows}
            scoreStatus={scoreStatus}
            startIndex={startIndex}
            hasMoreItems={hasMoreItems}
            onRequestSort={handleSortRequest}
            setStartIndex={setStartIndex}
            handleClickRow={handleClickRow}
            reloadItems={reloadItems}
            limit={limit}
            emptyListString={'No Data Available for Converted tab.'}
            hasError={hasError}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectLeadsConvertedArchive;
