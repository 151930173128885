import React from 'react';
import type { SnackbarProps } from '@material-ui/core/Snackbar';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Typography } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';

import { MessageType } from '../../../models/common';
import theme, { black, primary } from '../../../theme';

interface IProps {
  type: MessageType;
}

const useStyles = makeStyles(() => ({
  alert: {
    display: 'flex',
    padding: '1rem 2rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize,
    color: primary,
    width: '26rem',
    height: '4.75rem',
  },
}));

const Snackbar: React.FC<SnackbarProps & IProps> = ({
  type = MessageType.Info,
  children,
  ...props
}) => {
  const classes = useStyles();
  const backgroundColor = type === MessageType.Success ? '#D9F2EF' : undefined;
  return (
    <MuiSnackbar autoHideDuration={3000} {...props}>
      <Alert
        className={classes.alert}
        style={{ backgroundColor: backgroundColor }}
        elevation={6}
        variant="filled"
        severity={type}
      >
        {type === MessageType.Success && (
          <AlertTitle>
            <Typography style={{ color: black }}>
              <strong>Success</strong>
            </Typography>
          </AlertTitle>
        )}
        {children}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
