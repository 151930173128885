import { Map } from 'mapbox-gl';

import { primaryBlue } from '../../../../../theme';

interface IParamsHoverLayer {
  map: Map;
  sourceId: string;
  sourceData: GeoJSON.FeatureCollection | string;
  layerId: string;
}

export const createHoverLayer = ({ map, sourceId, sourceData, layerId }: IParamsHoverLayer) => {
  let hoveredStateId = null as number | string | null | undefined;

  map?.addSource(sourceId, {
    type: 'geojson',
    data: sourceData,
  });

  // The feature-state dependent fill-opacity expression will render the hover effect
  // when a feature's hover state is set to true.
  map?.addLayer({
    id: `${layerId}-fills`,
    type: 'fill',
    source: sourceId,
    layout: {},
    paint: {
      'fill-color': primaryBlue,
      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.1, 0.0],
    },
  });

  map?.addLayer({
    id: `${layerId}-borders`,
    type: 'line',
    source: sourceId,
    layout: {},
    paint: {
      'line-color': primaryBlue,
      'line-width': 0.5,
    },
  });

  // When the user moves their mouse over the state-fill layer, we'll update the
  // feature state for the feature under the mouse.

  // COMMENT
  map?.on('mousemove', `${layerId}-fills`, e => {
    if (!e.features) {
      return;
    }
    if (e.features.length > 0) {
      if (hoveredStateId !== null) {
        map?.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = e.features[0].id;
      map?.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: true });
    }
  });

  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  map?.on('mouseleave', `${layerId}-fills`, () => {
    if (hoveredStateId !== null) {
      map?.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: false });
    }
    hoveredStateId = null;
  });

  // Hover state click logic can go here
  // map?.on('click', `${layerId}-fills`, e => {
  //   const clickedItem = e?.features?.[0];
  //   if (clickedItem) {
  //     const clickedName =
  //       clickedItem?.properties?.STATE_NAME || clickedItem?.properties?.name || undefined;
  //     console.log('Clicked fill layer: ', clickedName);
  //   }
  // });
};
