import {
  Comparators,
  FilterItemInputType,
  FilterOptionsItem,
  FilterOptionsResponse,
} from '../../../../../types';
import { toTitleCase } from '../../../../../utils/string';
import { stateAbbreviations } from '../../../../../utils/stateAbbreviations';
import { ACTION_STAGES_MAP } from '../../../../../constants';
import { countyStateList } from '../../../../../utils/countyStateList';

/**
 * Maps each state (key) to a list of county options in the form of FilterOptionsItem[]
 * */
export const getCountyFiltersByState = (): Record<string, FilterOptionsItem[]> => {
  const counties = countyStateList;
  const mappedCountyOptions: Record<string, FilterOptionsItem[]> = {};
  counties.forEach(countyObj => {
    const { state, county } = countyObj;
    const countyOption: FilterOptionsItem = {
      label: `${toTitleCase(county)} - ${state}`.trim(),
      value: `${county} - ${state}`.trim(),
      inputType: FilterItemInputType.Select,
      comparator: Comparators.In,
      multiple: true,
    };

    const stateKey = stateAbbreviations[state];
    if (!mappedCountyOptions[stateKey]) {
      mappedCountyOptions[stateKey] = [countyOption];
    } else {
      mappedCountyOptions[stateKey].push(countyOption);
    }
  });
  return mappedCountyOptions;
};

export const getSubActionStages = (
  currentFilterOptions: FilterOptionsResponse,
): Record<string, FilterOptionsItem[]> => {
  const { actionStages } = currentFilterOptions;
  const actionStagesOptions: Record<string, FilterOptionsItem[]> = {};
  actionStages.forEach(stageObj => {
    const { action, subAction } = stageObj;
    const stageOption: FilterOptionsItem = {
      label: `${toTitleCase(subAction)} - ${action}`.trim(),
      value: `${subAction} - ${action}`.trim(),
      inputType: FilterItemInputType.Select,
      comparator: Comparators.In,
      multiple: true,
    };

    const stageKey = ACTION_STAGES_MAP[action];
    if (stageKey && toTitleCase(stageKey) === 'Bidding/negotiating') {
      if (!actionStagesOptions['Bidding/Negotiating']) {
        actionStagesOptions['Bidding/Negotiating'] = [stageOption];
      } else {
        actionStagesOptions['Bidding/Negotiating'].push(stageOption);
      }
    } else if (stageKey && stageKey !== 'N/A') {
      if (!actionStagesOptions[toTitleCase(stageKey)]) {
        actionStagesOptions[toTitleCase(stageKey)] = [stageOption];
      } else {
        actionStagesOptions[toTitleCase(stageKey)].push(stageOption);
      }
    }
  });
  return actionStagesOptions;
};
