import React from 'react';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import clsx from 'clsx';

import nationalAccountIcon from '../../../resources/images/Accounts/AccountNationalIcon.svg';
import nationalTargetAccountIcon from '../../../resources/images/Accounts/NationalAccountTargeted.svg';
import {
  IAccountRatingIcon,
  renderAccountRatingIcon,
} from '../../Common/OrgIcons/AccountIconRenderer';
import { accountRatingHelper } from '../Accounts/AccountsHome/AccountCardIcons';
import { Organization } from '../../../models';

const useStyles = makeStyles(() => ({
  root: { display: 'flex', flexDirection: 'column' },
  inactive: {
    opacity: '20%',
  },
  icon: {
    margin: `.125rem 0`,
  },
}));

type IStakeholderAccountIconsProps = {
  organization: Organization | undefined;
};

const StakeholderAccountIcons = ({ organization }: IStakeholderAccountIconsProps) => {
  const classes = useStyles();

  const sfRating = organization?.sfRating || organization?.account?.sf_rating || undefined;
  const rating = accountRatingHelper(sfRating);
  const isNew = !organization?.salesforceId;
  let ratingIconRender: JSX.Element | undefined = undefined;
  if (rating.ratingIcon) {
    rating.size = '25px';
    ratingIconRender = renderAccountRatingIcon(rating as IAccountRatingIcon);
  }
  return (
    <Grid className={classes.root}>
      {ratingIconRender && (
        <Tooltip title={'Account Rating'} placement={'bottom'} arrow>
          <Grid className={classes.icon}>{ratingIconRender}</Grid>
        </Tooltip>
      )}
      {!organization?.isTarget ? (
        <Tooltip title={'National Account'} placement={'bottom'} arrow>
          <img
            style={{
              height: '25px',
            }}
            src={nationalAccountIcon}
            alt={'National Account'}
            className={clsx(classes.icon, !organization?.isCSAAccount && classes.inactive)}
          />
        </Tooltip>
      ) : (
        <Tooltip title={'Target National Account'} placement={'bottom'} arrow>
          <img
            style={{
              height: '25px',
            }}
            src={nationalTargetAccountIcon}
            alt={'Target National Account'}
            className={classes.icon}
          />
        </Tooltip>
      )}
      <Tooltip title={'New Account'} placement={'bottom'} arrow>
        <FiberNewIcon
          titleAccess={'New Account'}
          color={'secondary'}
          className={clsx(classes.icon, !isNew && classes.inactive)}
        />
      </Tooltip>
    </Grid>
  );
};

export default StakeholderAccountIcons;
