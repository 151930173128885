import { GOOGLE_MAP_API_KEY } from '../constants';
import { primary } from '../theme';

interface GetGoogleMapRequest {
  address: string;
  width: number;
  height: number;
  zoom: number;
  markerSize?: 'tiny' | 'mid' | 'small';
}

export const googleSearchQuery = (query: string): string => {
  return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
};

export const getMapUrl = (address: string): string =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

export const getGoogleMapImageUrl = (request: GetGoogleMapRequest): string => {
  const { address, width, height, zoom, markerSize = 'tiny' } = request;
  return `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
    address,
  )}&zoom=${zoom}&scale=2&size=${width}x${height}&maptype=roadmap&markers=size:${markerSize}|color:0x${primary.slice(
    1,
  )}|label:1|${address}&key=${GOOGLE_MAP_API_KEY}`;
};
