import { Column } from 'react-table';

import { defaultHiddenColumnIdsAccountsPL } from './AccountsPLTableColumns';

import { renderDate, renderItem } from '../../Leads/LeadsTable/LeadTableRowRenderers';
import PvfProject from '../../../../models/pvfProject';
import { ColumnDraggable } from '../../../Common/Tables/ReactTable/ReactTable';

export const accountProjectLeadsDemotedColumns: (Column<PvfProject> & ColumnDraggable)[] = [
  {
    id: 'id',
    Header: 'Id',
    disableSortBy: true,
    disableResizing: true,
    disableDrag: true,
    accessor: (row: PvfProject) => row.id,
  },
  {
    id: 'projectName',
    Header: 'Project Name',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.projectName, false, undefined, true),
  },
  {
    id: 'biddate',
    Header: 'Bid Date',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderDate(row.bidDate),
  },
  {
    id: 'actionStage',
    Header: 'Action Stage',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.mappedActionStage, true),
  },
  {
    id: 'projectType',
    Header: 'Project Type',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.projectType, true, 'body1'),
  },
  {
    id: 'transitionReason',
    Header: 'Demotion Reason',
    disableSortBy: true,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.transitionReason, true, 'body1'),
  },
];

export const defaultInitialStateAccountsPLDemoted = {
  hiddenColumns: defaultHiddenColumnIdsAccountsPL,
  pageSize: 10,
  sortBy: [
    {
      id: 'projectName',
      desc: true,
    },
  ],
};
