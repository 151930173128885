import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { selectedBulkDemoteState } from '../../../../state/atoms/projects';
import { white } from '../../../../theme';

interface IProps {
  handleCancelBulkDemote: () => void;
  setIsDemoting: (isDemoting: boolean) => void;
  count: number;
}
const BulkDemoteButtons: React.FC<IProps> = ({ handleCancelBulkDemote, setIsDemoting, count }) => {
  const selectedBulkItems = useRecoilValue(selectedBulkDemoteState);
  return (
    <Grid
      style={{
        backgroundColor: '#F4F9FC',
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          border: '1px solid #0C4377',
          width: 'fit-content',
          marginLeft: '3rem',
          borderRadius: '4px',
          padding: '0.2rem 0.8rem',
        }}
      >
        <Button
          style={{ textTransform: 'none', color: '#0C4377', width: '5rem', padding: '0.1rem' }}
          onClick={handleCancelBulkDemote}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: selectedBulkItems.length ? white : '#a1a2a3',
            backgroundColor: selectedBulkItems.length ? '#172D50' : '#e5edea',
            width: '5rem',
            padding: '0.1rem',
          }}
          disabled={selectedBulkItems.length === 0}
          onClick={() => {
            setIsDemoting(true);
          }}
        >
          Demote
        </Button>
      </div>
      <Typography style={{ margin: '0 1rem' }} variant="subtitle2" id="bulkDemotedSelected">
        {selectedBulkItems.length} Selected <span style={{ fontWeight: 'lighter' }}>out of</span>{' '}
        {count}
      </Typography>
    </Grid>
  );
};

export default BulkDemoteButtons;
