import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Column } from 'react-table';
import { useLocation } from 'react-router-dom';

import BulkDemoteButtons from './BulkDemoteButtons';
import QualificationBulkDemoteButtons from './QualificationBulkDemoteButtons';
import { projectLeadsColumns } from './ProjectLeadsTableColumns';

import { IProject, sortColumns } from '../../Leads/Archive/Archive.config';
import { getDemotionReasons, getProjectViewFilter } from '../../../../api/projects';
import { APICancel, createCancelToken, IResponse, isCancel } from '../../../../api';
import ShowNewAndBulkDemote from '../../../Common/ShowNewAndBulkDemote';
import { FilterData, Optional, RollbarLogEventTypes } from '../../../../types';
import { showNewFilterState } from '../../../../state/atoms/leadRows';
import { ProjectLeadStatus, ProjectLeadStatusNonTabs } from '../../../../models/projectLead';
import {
  bulkDemoteTransitionState,
  selectedBulkDemoteState,
} from '../../../../state/atoms/projects';
import ProjectLeadsBulkDemoteConfirmation from '../ProjectLeadsBulkDemote/ProjectLeadsBulkDemoteConfirmation';
import {
  bulkDemoteLeadsButtonPressedState,
  projectLeadsState,
} from '../../../../state/atoms/projectLeads';
import { FetchDataProps, ReactTable } from '../../../Common/Tables/ReactTable/ReactTable';
import NationalAccountFilter from '../NationalAccountFilter';
import {
  userTableSettingsLoadedState,
  userTableSettingsState,
} from '../../../../state/atoms/tableSettings';
import { getTableSettingsByUser } from '../../../../api/tableSettings';
import { TableReference } from '../../../../models/tableSetting';
import { reportRollbar } from '../../../../utils/errors';
import { getAccountProjectsLeads } from '../../../../api/organizations';

let apiCancel: APICancel;

interface ProjectLeadsTableProps {
  filterOption: Optional<FilterData>;
  status: ProjectLeadStatus | ProjectLeadStatusNonTabs;
  organizationId?: string;
}

const ProjectLeadsTable: React.FC<ProjectLeadsTableProps> = ({
  filterOption = {},
  organizationId,
}) => {
  const [startIndex, setStartIndex] = useState<number>(0);
  const [showNew, setShowNew] = useRecoilState(showNewFilterState);
  const [bulkDemoteButtonClicked, setBulkDemoteButtonClicked] = useRecoilState(
    bulkDemoteLeadsButtonPressedState,
  );
  const [isDemoting, setIsDemoting] = useState<boolean>(false);
  const [completingDemotion, setCompletingDemotion] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const setSelectedBulkItems = useSetRecoilState(selectedBulkDemoteState);
  const [transitionItems, setTransitionItems] = useRecoilState(bulkDemoteTransitionState);
  const [projectLeads, setProjectLeads] = useRecoilState<IProject[]>(projectLeadsState);
  const [nationalAccountFilter, setNationalAccountFilter] = useState<FilterData>({} as FilterData);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [demotionReasons, setDemotionReasons] = React.useState<Record<string, string>>({});

  const [tableSettingsLoaded, setTableSettingsLoaded] = useRecoilState(
    userTableSettingsLoadedState,
  );
  const [tableSettings, setTableSettings] = useRecoilState(userTableSettingsState);
  const { pathname } = useLocation();

  const headerCells: Column<IProject>[] = useMemo(() => {
    return projectLeadsColumns;
  }, []);

  const loadTableSettings = useCallback(async () => {
    setTableSettingsLoaded(false);
    const allTableSettings = await getTableSettingsByUser();
    setTableSettings(allTableSettings);
    setTableSettingsLoaded(true);
  }, [setTableSettings, setTableSettingsLoaded]);

  const createDefaultFilter = useCallback(() => {
    const defaultFilter: FilterData = cloneDeep(filterOption);
    defaultFilter.projectStatus = { eq: 'active' };
    if (showNew === 'showNew') {
      defaultFilter.isNew = { eq: true };
    } else if (showNew === 'showExisting') {
      defaultFilter.isNew = { eq: false };
    }

    // Handle National Account Filters
    Object.keys(nationalAccountFilter).forEach(key => {
      defaultFilter[key] = (nationalAccountFilter as FilterData)[key];
    });
    return defaultFilter;
  }, [showNew, filterOption, nationalAccountFilter]);

  const paginateLoadItems = useCallback(
    async ({ startIndex = 0, limit = 10, sortId: sortBy, sortIsDesc }: FetchDataProps) => {
      setProjectLeads([] as IProject[]);
      setLoading(true);
      setPageSize(limit);
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }
      try {
        const defaultFilter = createDefaultFilter();
        let data: IResponse<IProject>;
        if (!organizationId) {
          data = await getProjectViewFilter(
            {
              sort: {
                orderBy: sortBy || 'projectScore',
                order: sortIsDesc || 'desc',
              },
              startIndex,
              filterOption: defaultFilter,
              limit,
            },
            sortColumns,
            createCancelToken(cancel => {
              apiCancel = cancel;
            }),
          );
        } else {
          data = await getAccountProjectsLeads(
            organizationId,
            {
              sort: {
                orderBy: sortBy || 'projectScore',
                order: sortIsDesc || 'desc',
              },
              startIndex,
              filterOption: defaultFilter,
              limit,
            },
            sortColumns,
          );
        }
        if (!data.items) {
          throw new Error('Projects not available');
        }
        setCount(data.total);
        setPageCount(Math.ceil(data.total / limit));
        setProjectLeads([...data.items]);
        setLoading(false);
        setInitialLoad(false);
      } catch (e: any) {
        setError(true);
        if (!isCancel(e)) {
          reportRollbar(RollbarLogEventTypes.ERROR, e.message, e);
          console.error(JSON.stringify(e));
          console.error(e.message);
        }
      }
    },
    [setProjectLeads, createDefaultFilter, setPageCount, setPageSize, setLoading, organizationId],
  );

  const reloadItems = useCallback(() => {
    setStartIndex(0);

    paginateLoadItems({ startIndex, limit: pageSize });
  }, [startIndex, pageSize, paginateLoadItems]);

  const handleShowNew = (filterValue: 'showNew' | 'showAll' | 'showExisting') => {
    setShowNew(filterValue);
    setError(false);
  };

  const handleBulkDemoteButton = useCallback(() => {
    setBulkDemoteButtonClicked((prev: boolean) => !prev);
  }, [setBulkDemoteButtonClicked]);

  const handleCancelBulkDemote = useCallback(() => {
    setSelectedBulkItems([]);
    if (transitionItems) {
      setTransitionItems(undefined);
    }
    setBulkDemoteButtonClicked(false);
    setIsDemoting(false);
    setCompletingDemotion(false);
  }, [transitionItems, setSelectedBulkItems, setTransitionItems, setBulkDemoteButtonClicked]);

  const getReasons = useCallback(async () => {
    const dReasonsResp = await getDemotionReasons();
    const dReasons = {} as Record<string, string>;
    dReasonsResp.map(x => {
      return (dReasons[x.transition_reason] = x.reason_mapping);
    });
    setDemotionReasons(dReasons);
  }, []);

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  useEffect(() => {
    if (!initialLoad) {
      reloadItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption, nationalAccountFilter, showNew]);

  useEffect(() => {
    // Make sure to clear out bulk demote recoil state when starting process over
    if (!bulkDemoteButtonClicked) {
      handleCancelBulkDemote();
    }
  }, [bulkDemoteButtonClicked, handleCancelBulkDemote]);

  useEffect(() => {
    if (!tableSettingsLoaded) {
      loadTableSettings();
    }
  }, [loadTableSettings, tableSettingsLoaded]);

  useEffect(() => {
    // Clear out table data so that lag time is minimized when returning to table
    setProjectLeads([] as IProject[]);
  }, [pathname, setProjectLeads]);

  const initialTableState = tableSettings.filter(
    setting => setting.tableReference === TableReference.ProjectLeadsHome,
  )[0];
  return (
    <div>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '.5rem 2rem 0 2rem',
        }}
      >
        {!completingDemotion && (
          <NationalAccountFilter setNationalAccountFilter={setNationalAccountFilter} />
        )}
        <ShowNewAndBulkDemote
          showNew={showNew}
          count={count}
          handleShowNew={handleShowNew}
          handleBulkDemoteButton={handleBulkDemoteButton}
          bulkDemoteButtonClicked={bulkDemoteButtonClicked}
        />
      </Grid>
      {bulkDemoteButtonClicked && !isDemoting && !completingDemotion && (
        <BulkDemoteButtons
          handleCancelBulkDemote={handleCancelBulkDemote}
          setIsDemoting={setIsDemoting}
          count={count}
        />
      )}
      {completingDemotion && (
        <ProjectLeadsBulkDemoteConfirmation clearBulkDemote={handleCancelBulkDemote} />
      )}
      {isDemoting && bulkDemoteButtonClicked && (
        <QualificationBulkDemoteButtons
          setTransitionItems={setTransitionItems}
          handleCancelBulkDemote={handleCancelBulkDemote}
          setCompletingDemotion={setCompletingDemotion}
          completingDemotion={completingDemotion}
          count={count}
          demotionReasons={demotionReasons}
        />
      )}
      {!completingDemotion && (
        <>
          <div style={{ margin: '.5rem 2rem' }}>
            {tableSettingsLoaded && (
              <ReactTable<IProject>
                columns={headerCells}
                data={projectLeads}
                loading={loading}
                error={error}
                fetchData={paginateLoadItems}
                pageCount={pageCount}
                initialTableState={initialTableState?.savedSettings}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectLeadsTable;
