import { makeStyles } from '@material-ui/core';
import { light } from '@material-ui/core/styles/createPalette';

import { secondary } from '../../../theme';

const useTableRowStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  main: {
    width: '100%',
    minHeight: '5rem',
    margin: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: light,
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        padding: theme.spacing(0, 1),
      },
    },
  },
  nationalText: {
    paddingRight: '1.1rem',
    fontSize: '.70rem',
  },
  newText: {
    paddingRight: '1.1rem',
  },
  nationalBanner: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '0rem',
    left: '-2rem',
    height: '1.5rem',
    width: '8rem',
    transform: 'rotate(-33.61deg)',
    backgroundColor: '#DAA520',
    color: '#0C4377',
    zIndex: 10,
    padding: '.8rem',
  },
  newBanner: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '0rem',
    left: '-1.75rem',
    height: '1.5rem',
    width: '8rem',
    transform: 'rotate(-33.61deg)',
    backgroundColor: '#172D50',
    color: secondary,
    zIndex: 10,
    padding: '.8rem',
  },
}));

export default useTableRowStyles;
