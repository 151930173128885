import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Input,
  NativeSelect,
  Paper,
  Tab,
  Tabs,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import qs from 'querystringify';
import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useRecoilState } from 'recoil';
import isPlainObject from 'lodash/isPlainObject';
import flattenDeep from 'lodash/flattenDeep';
import cronstrue from 'cronstrue';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';

import {
  types,
  stakeholderFields,
  eventFields,
  payloadFields,
  propertyFields,
  projectFields,
  feedbackFields,
  organizationFields,
  userFields,
  DataMapperTabs,
  Roles,
} from './DataMapperConstants';
import useDataMapperCreateWizardStyles from './DataMapperCreateWizard.styles';

import FormTextField from '../../Common/FormTextField';
import PaperHeader from '../../Common/PaperHeader';
import { primaryBlue, white } from '../../../theme';
import TabPanel from '../LeadDetails/TabPanel';
import {
  DataMapperCronRequestSchema,
  DataMapperDataResponseSchema,
  DataMapperRequestSchema,
} from '../../../models/dataMapper';
import {
  dataMapperColumnsFromUrlState,
  dataMapperConfigState,
  dataMapperETLRequestState,
  dataMapperPreviewState,
  dataMapperDataResponse,
  dataMapperPreviewRolesState,
  dataMapperZipcodeBoundaryResponse,
  ZipcodeFields,
} from '../../../state/atoms/dataMapper';
import { getDataResponse, sendDataMapperETLRequest } from '../../../api/dataMapper';
import Loader from '../../Common/Loader';
import { getArrayKeys } from '../../../utils/object';
import { useMessages } from '../../../state/contexts';
import { toTitleCase } from '../../../utils/string';
import { tabsStyle, tabStyle2 } from '../LeadDetails/ContactsAccountsContainer';

const DataMapperCreateWizard: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useMessages();
  const classes = useDataMapperCreateWizardStyles();

  const [tabValue, setTabValue] = useState<number>(DataMapperTabs.DataConfiguration);
  const [currentCronValue, setCurrentCronValue] = useState<string>('');
  const [startDate, setStartDate] = useState<DateTime>();
  const [redirectToDataMapperHome, setRedirectToDataMapperHome] = useState<boolean>(false);
  const [selectData, setSelectData] = useState<string[]>(['']);
  const [zipcodeSelectData, setZipcodeSelectData] = useState<string[]>(['']);
  const [dataProp, setDataProp] = useState<string>('');
  const [zipcodeField, setZipcodeField] = useState<ZipcodeFields>({
    boundaryObj: '',
    zipcodeProp: '',
  });
  const [stakeholderCount, setStakeholderCount] = useState(1);

  const [dataMapperConfig, setDataMapperConfig] = useRecoilState(dataMapperConfigState);
  const [dataResponse, setDataResponse] = useRecoilState(dataMapperDataResponse);
  const [zipcodeApiResponse, setZipcodeApiResponse] = useRecoilState(
    dataMapperZipcodeBoundaryResponse,
  );
  const [dataMapperColumnsFromUrl, setDataMapperColumnsFromUrl] = useRecoilState(
    dataMapperColumnsFromUrlState,
  );
  const [dataMapperPreview, setDataMapperPreview] = useRecoilState(dataMapperPreviewState);
  const [dataMapperPreviewRoles, setDataMapperPreviewRoles] = useRecoilState(
    dataMapperPreviewRolesState,
  );
  const [dataMapperETLRequest, setDataMapperETLRequest] = useRecoilState(dataMapperETLRequestState);

  const renderInput = (props: TextFieldProps): any => (
    <Input
      type="text"
      inputRef={props.inputRef}
      inputProps={props.inputProps}
      value={props.value}
      onClick={props.onClick}
      onChange={props.onChange}
      endAdornment={props.InputProps?.endAdornment}
    />
  );
  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: DataMapperTabs) => {
    event.preventDefault();
    setTabValue(newTabValue);
  };

  const handleSubmitDataMapperConfig = useCallback(
    async values => {
      setDataMapperConfig({
        name: values.name,
        description: values.description,
        url: values.url,
        zipcodeBoundaryAPI: values.zipcodeBoundaryAPI,
        queryParams: values.queryParams,
        dataProp: values.dataProp,
        zipcodeFields: values.zipcodeFields,
      });
      setTabValue(DataMapperTabs.DataResponse);
      const dataUrl =
        values.queryParams.length > 0
          ? `${values.url}${qs.stringify(
              values.queryParams.reduce((a: any, c: any) => {
                if (c.key.length !== 0 && c.value.length !== 0) {
                  a[c.key] = c.value;
                }
                return a;
              }, {}),
              true,
            )}`
          : values.url;

      try {
        const dataRes = await getDataResponse(dataUrl, setTabValue);
        setDataResponse(dataRes);

        if (values.zipcodeBoundaryAPI) {
          const zipcodeBoundaryRes = await getDataResponse(values.zipcodeBoundaryAPI, setTabValue);
          setZipcodeApiResponse(zipcodeBoundaryRes);
          const zipcodeFieldOptions = Object.keys(zipcodeBoundaryRes[0]);
          zipcodeFieldOptions.unshift('');
          setZipcodeSelectData(zipcodeFieldOptions);
        }
        setSelectData(getArrayKeys(dataRes));

        if (Array.isArray(dataRes) && !values.zipcodeBoundaryAPI) {
          const [firstItem] = dataRes;
          setDataMapperColumnsFromUrl(firstItem);
          setTabValue(DataMapperTabs.Preview);
        }
      } catch (err) {
        setErrorMessage('Data configuration is not returning valid response: ', err);
      }
    },
    [
      setDataResponse,
      setDataMapperConfig,
      setZipcodeApiResponse,
      setDataMapperColumnsFromUrl,
      setZipcodeSelectData,
      setErrorMessage,
    ],
  );

  const handleSubmitDataMapperDataResponse = useCallback(
    async values => {
      setDataMapperConfig({
        name: values.name,
        description: values.description,
        url: values.url,
        zipcodeBoundaryAPI: values.zipcodeBoundaryAPI,
        queryParams: values.queryParams,
        dataProp: dataProp,
        zipcodeFields: zipcodeField,
      });
      setTabValue(DataMapperTabs.Preview);
      try {
        let firstItem;
        if (dataProp) {
          [firstItem] = dataResponse[dataProp];
        } else {
          firstItem = dataResponse[0];
        }
        setDataMapperColumnsFromUrl(firstItem);
      } catch (err) {
        setErrorMessage('Problem parsing object, select property with data: ', err);
        setTabValue(DataMapperTabs.DataResponse);
      }
    },
    [
      setDataMapperColumnsFromUrl,
      setDataMapperConfig,
      dataProp,
      dataResponse,
      zipcodeField,
      setErrorMessage,
    ],
  );

  const handleStakeholderChange = useCallback(
    event => {
      if (event.target.value === stakeholderCount.toString()) {
        setStakeholderCount(stakeholderCount + 1);
      }
    },
    [setStakeholderCount, stakeholderCount],
  );

  const handleSelectChange = useCallback(
    e => {
      setDataProp(e.target.value);
    },
    [setDataProp],
  );

  const handleSelectZipChange = useCallback(
    (e, updateField: keyof ZipcodeFields) => {
      const update = { ...zipcodeField };
      update[updateField] = e.target.value;
      setZipcodeField(update);
    },
    [setZipcodeField, zipcodeField],
  );

  const handleSubmitDataMapperPreview = useCallback(
    values => {
      setDataMapperPreview(values.columns);
      setDataMapperPreviewRoles(values.roles);
      setTabValue(DataMapperTabs.RunSchedule);
    },
    [setDataMapperPreview, setDataMapperPreviewRoles],
  );

  const handleStartDateChange = useCallback(value => {
    setStartDate(value);
  }, []);

  const handleDisableLogic = useCallback(values => {
    return (
      values.minutes === undefined ||
      values.minutes === '' ||
      values.hours === undefined ||
      values.hours === '' ||
      values.monthDay === undefined ||
      values.monthDay === '' ||
      values.month === undefined ||
      values.month === '' ||
      values.weekDay === undefined ||
      values.weekDay === ''
    );
  }, []);

  const handleSubmitDataMapperSchedule = useCallback(
    values => {
      const { minutes, hours, monthDay, month, weekDay } = values;
      const cronValue = `${minutes} ${hours} ${monthDay} ${month} ${weekDay}`;
      setCurrentCronValue(cronValue);
      const start: string = startDate
        ? startDate.toISODate()
        : new Date().toISOString().substring(0, 10);
      const etlRequest = {
        source_label: 'DATA_MAPPER',
        source_name: 'DATA_MAPPER',
        data_mapper_name: dataMapperConfig.name,
        dataset_config: {
          columns: dataMapperPreview,
          roles: dataMapperPreviewRoles,
          url: dataMapperConfig.url,
          zipcodeBoundaryAPI: dataMapperConfig.zipcodeBoundaryAPI,
          description: dataMapperConfig.description,
          dataProp: dataMapperConfig.dataProp,
          zipcodeFields: dataMapperConfig.zipcodeFields,
        },
        cron: cronValue,
        settings: {
          fromDate: start,
          limit: 5000,
        },
      };
      setDataMapperETLRequest(etlRequest);
      setTabValue(DataMapperTabs.Review);
    },
    [
      dataMapperConfig,
      dataMapperPreview,
      setDataMapperETLRequest,
      startDate,
      dataMapperPreviewRoles,
    ],
  );

  const handleSubmitETLRequest = useCallback(async () => {
    try {
      await sendDataMapperETLRequest(dataMapperETLRequest);
    } catch (e: any) {
      console.log(e);
    }
    setRedirectToDataMapperHome(true);
  }, [dataMapperETLRequest]);

  const getInitialValuesForDataMapperColumnsFromUrl = useCallback(() => {
    const isJSON = (jsonString: any) => {
      try {
        const obj = JSON.parse(jsonString);
        if (isPlainObject(obj)) {
          return true;
        }
      } catch (_) {
        return false;
      }
    };

    const getDataMapperRows = (
      dataMapperColumnKey: string,
      dataMapperColumns = dataMapperColumnsFromUrl,
      type = '',
      parent = '',
    ): any => {
      const dataMapperColumnValue = isJSON(dataMapperColumns[dataMapperColumnKey])
        ? JSON.parse(dataMapperColumns[dataMapperColumnKey])
        : dataMapperColumns[dataMapperColumnKey];
      if (!isPlainObject(dataMapperColumnValue)) {
        return {
          fixedName: dataMapperColumnKey,
          type,
          field: '',
          parent,
          group: undefined,
        };
      } else {
        const nestedDataMapperColumns = Object.assign(
          {},
          {
            [dataMapperColumnKey]: 'Object',
          },
          dataMapperColumnValue,
        );
        return Object.keys(nestedDataMapperColumns).map((key: string) => {
          const childType = key === dataMapperColumnKey ? 'Object' : '';
          let childParent = '';
          if (key === dataMapperColumnKey) {
            childParent = parent;
          } else {
            childParent = dataMapperColumnKey;
          }
          return getDataMapperRows(key, dataMapperColumnValue, childType, childParent);
        });
      }
    };

    const rows = Object.keys(dataMapperColumnsFromUrl).map((key: string) => {
      return getDataMapperRows(key);
    });

    return { columns: flattenDeep(rows), roles: dataMapperPreviewRoles };
  }, [dataMapperColumnsFromUrl, dataMapperPreviewRoles]);

  useEffect(() => {
    return () => setRedirectToDataMapperHome(false);
  });

  return (
    <Container maxWidth="xl" className={classes.root}>
      {redirectToDataMapperHome && <Redirect to="/data-mapper" />}
      <AppBar position="static" className={classes.dataMapperTabs}>
        <Tabs value={tabValue} onChange={handleTabChange} classes={tabsStyle()}>
          <Tab label="Data Configuration" classes={tabStyle2()} />
          <Tab label="Data Response" classes={tabStyle2()} />
          <Tab label="Preview" classes={tabStyle2()} />
          <Tab label="Run Schedule" classes={tabStyle2()} />
          <Tab label="Review" classes={tabStyle2()} />
        </Tabs>
      </AppBar>
      <Paper className={classes.paper}>
        <TabPanel
          value={tabValue}
          index={DataMapperTabs.DataConfiguration}
          height={'auto'}
          id={'data-mapper-tab-0'}
        >
          <PaperHeader className={classes.header}>
            <Typography className={classes.headerTitle}>Data Configuration</Typography>
          </PaperHeader>
          <Box px={6} pb={6}>
            <Grid item className={classes.root}>
              <Formik
                enableReinitialize
                initialValues={dataMapperConfig}
                onSubmit={handleSubmitDataMapperConfig}
                validateOnMount
                validationSchema={DataMapperRequestSchema}
              >
                {({ values, isValid }) => {
                  return (
                    <Form>
                      <Grid item style={{ marginBottom: '2rem' }}>
                        <FormTextField name="name" label="Name" fullWidth />
                      </Grid>
                      <Grid item style={{ marginBottom: '2rem' }}>
                        <FormTextField
                          name="description"
                          label="Description"
                          fullWidth
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item style={{ marginBottom: '2rem' }}>
                        <FormTextField name="url" label="URL" fullWidth value={values.url} />
                      </Grid>
                      <Grid item style={{ marginBottom: '2rem' }}>
                        <FormTextField
                          name="zipcodeBoundaryAPI"
                          label="Zipcode Boundary API"
                          fullWidth
                          value={values.zipcodeBoundaryAPI}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.formTitle}>Query Parameters</Typography>
                      </Grid>
                      <FieldArray name="queryParams">
                        {({ remove, push }) =>
                          values.queryParams.map((queryParam, index: number) => (
                            <Grid
                              item
                              style={{
                                marginBottom: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              key={`${queryParam}-${index}`}
                            >
                              <FormTextField
                                name={`queryParams.${index}.key`}
                                label="Key"
                                style={{ marginRight: '1rem' }}
                                value={values.queryParams[index].key}
                              />
                              <FormTextField
                                name={`queryParams.${index}.value`}
                                label="Value"
                                style={{ marginRight: '1rem' }}
                                value={values.queryParams[index].value}
                              />
                              <div style={{ marginTop: '1rem' }}>
                                <RemoveCircleOutlineIcon
                                  className={classes.queryParametersButtons}
                                  onClick={() => index > 0 && remove(index)}
                                />
                                <AddCircleOutlineIcon
                                  className={classes.queryParametersButtons}
                                  onClick={() => push({ key: '', value: '' })}
                                />
                              </div>
                            </Grid>
                          ))
                        }
                      </FieldArray>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginLeft: '1rem' }}
                          onClick={() => {
                            setDataMapperConfig({
                              name: '',
                              url: '',
                              zipcodeBoundaryAPI: '',
                              description: '',
                              queryParams: [{ key: '', value: '' }],
                              dataProp: '',
                              zipcodeFields: {
                                boundaryObj: '',
                                zipcodeProp: '',
                              },
                            });
                            history.push('/data-mapper');
                          }}
                        >
                          Back to Data Mapper Home
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!isValid}
                          style={{ marginLeft: '1rem' }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={DataMapperTabs.DataResponse} height={'auto'}>
          <PaperHeader className={classes.header}>
            <Typography className={classes.headerTitle}>Data Response</Typography>
          </PaperHeader>
          <Box px={6} pb={6}>
            <Grid item className={classes.root}>
              {Object.keys(dataResponse).length > 0 ? (
                <Formik
                  enableReinitialize
                  initialValues={dataMapperConfig}
                  onSubmit={handleSubmitDataMapperDataResponse}
                  validateOnMount
                  validationSchema={DataMapperDataResponseSchema}
                >
                  {({ values }) => {
                    return (
                      <Form>
                        <Grid item>
                          <Typography className={classes.formTitle}>Property With Data:</Typography>
                        </Grid>
                        <Grid item>
                          <NativeSelect
                            variant="outlined"
                            fullWidth
                            value={dataProp}
                            onChange={handleSelectChange}
                          >
                            {selectData.map((selection, key) => (
                              <option value={selection} key={`${selection}-${key}`}>
                                {selection}
                              </option>
                            ))}
                          </NativeSelect>
                        </Grid>
                        <Box
                          style={{
                            backgroundColor: primaryBlue,
                            color: white,
                            height: '20rem',
                            overflow: 'scroll',
                            margin: '1rem 0',
                          }}
                        >
                          <pre>{JSON.stringify(dataResponse, null, 2)}</pre>
                        </Box>
                        <Grid item>
                          <Typography className={classes.formTitle}>Boundary Key:</Typography>
                        </Grid>
                        <Grid item>
                          <NativeSelect
                            variant="outlined"
                            fullWidth
                            value={zipcodeField.boundaryObj}
                            onChange={e => handleSelectZipChange(e, 'boundaryObj')}
                            name={'boundaryObj'}
                          >
                            {zipcodeSelectData.map((selection, key) => (
                              <option value={selection} key={`${selection}-${key}`}>
                                {selection}
                              </option>
                            ))}
                          </NativeSelect>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.formTitle}>Zipcode Key:</Typography>
                        </Grid>
                        <Grid item>
                          <NativeSelect
                            variant="outlined"
                            fullWidth
                            value={zipcodeField.zipcodeProp}
                            onChange={e => handleSelectZipChange(e, 'zipcodeProp')}
                            name={'zipcodeProp'}
                          >
                            {zipcodeSelectData.map((selection, key) => (
                              <option value={selection} key={`${selection}-${key}`}>
                                {selection}
                              </option>
                            ))}
                          </NativeSelect>
                        </Grid>
                        <Box
                          style={{
                            backgroundColor: primaryBlue,
                            color: white,
                            height: '20rem',
                            overflow: 'scroll',
                            margin: '1rem 0',
                          }}
                        >
                          <pre>{JSON.stringify(zipcodeApiResponse, null, 2)}</pre>
                        </Box>
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: '1rem' }}
                            onClick={() => setTabValue(DataMapperTabs.DataConfiguration)}
                          >
                            Previous
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitDataMapperDataResponse(values)}
                            disabled={false}
                            style={{ marginLeft: '1rem' }}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <Loader />
              )}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={DataMapperTabs.Preview} height={'auto'}>
          <PaperHeader className={classes.header}>
            <Typography className={classes.headerTitle}>Preview</Typography>
          </PaperHeader>
          <Box px={6} pb={6}>
            <Grid item className={classes.root}>
              {Object.keys(dataMapperColumnsFromUrl).length > 0 ? (
                <Formik
                  enableReinitialize
                  initialValues={getInitialValuesForDataMapperColumnsFromUrl()}
                  onSubmit={handleSubmitDataMapperPreview}
                  validateOnMount
                  validationSchema={DataMapperRequestSchema}
                >
                  {({ values, handleChange }) => {
                    return (
                      <Form>
                        <Grid item>
                          <Typography className={classes.formTitle}>Total Columns</Typography>
                        </Grid>
                        <Grid container>
                          <Grid item md={4}>
                            <Typography className={classes.formTitle}>Fixed Name</Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography className={classes.formTitle}>Type</Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography className={classes.formTitle}>Field</Typography>
                          </Grid>
                        </Grid>
                        <Box style={{ height: '30rem', overflow: 'scroll', margin: '1rem 0' }}>
                          <FieldArray name="columns">
                            {({ remove, insert }) =>
                              values.columns.map((value: any, index: any) => (
                                <div
                                  key={`${value}-${index}`}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '1rem',
                                  }}
                                >
                                  <Grid item md={4}>
                                    <FormTextField
                                      name={`columns.${index}.fixedName`}
                                      className={
                                        values.columns[index].parent === ''
                                          ? ''
                                          : classes.indentedField
                                      }
                                      style={{
                                        paddingRight: '1rem',
                                      }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={3} style={{ paddingRight: '1rem' }}>
                                    <NativeSelect
                                      variant="outlined"
                                      style={{
                                        padding: 0,
                                        ...((values.columns[index].type === 'stakeholders' ||
                                          values.columns[index].type === 'organizations') && {
                                          width: '50%',
                                        }),
                                      }}
                                      fullWidth={
                                        values.columns[index].type !== 'stakeholders' &&
                                        values.columns[index].type !== 'organizations'
                                      }
                                      inputProps={{ name: `columns.${index}.type` }}
                                      onChange={handleChange(`columns.${index}.type`)}
                                      value={values.columns[index].type}
                                    >
                                      {(() => {
                                        if (values.columns[index].type === 'Object') {
                                          return <option value={'Object'}>Object</option>;
                                        } else {
                                          return types.map((type, i) => (
                                            <option value={type} key={`${type}-${i}`}>
                                              {type}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </NativeSelect>
                                    {(values.columns[index].type === 'stakeholders' ||
                                      values.columns[index].type === 'organizations') && (
                                      <NativeSelect
                                        variant="outlined"
                                        style={{ padding: 0, marginLeft: '.5rem', width: '45%' }}
                                        inputProps={{ name: `columns.${index}.group` }}
                                        onChange={event => {
                                          handleChange(event);
                                          handleStakeholderChange(event);
                                        }}
                                        value={values.columns[index].group}
                                      >
                                        <option value={0} key={`group-${0}`}></option>
                                        {(() => {
                                          return [...Array(stakeholderCount).keys()].map(i => (
                                            <option value={i + 1} key={`group-${i + 1}`}>
                                              {`Group ${i + 1}`}
                                            </option>
                                          ));
                                        })()}
                                      </NativeSelect>
                                    )}
                                  </Grid>
                                  <Grid item md={3} style={{ paddingRight: '1rem' }}>
                                    {values.columns[index].type === 'Object' ? (
                                      <div
                                        style={{
                                          height: '2rem',
                                          backgroundColor: '#dad8d8',
                                          borderRadius: '10px',
                                        }}
                                      ></div>
                                    ) : (
                                      <NativeSelect
                                        variant="outlined"
                                        fullWidth
                                        style={{ padding: 0 }}
                                        inputProps={{ name: `columns.${index}.field` }}
                                        onChange={handleChange(`columns.${index}.field`)}
                                        value={values.columns[index].field}
                                      >
                                        {(() => {
                                          switch (values.columns[index].type) {
                                            case 'stakeholders':
                                              return stakeholderFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'events':
                                              return eventFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'payload':
                                              return payloadFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'properties':
                                              return propertyFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'projects':
                                              return projectFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'feedbacks':
                                              return feedbackFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'organizations':
                                              return organizationFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            case 'users':
                                              return userFields.map((field, i) => (
                                                <option value={field} key={`${field}-${i}`}>
                                                  {field}
                                                </option>
                                              ));
                                            default:
                                              return <option disabled></option>;
                                          }
                                        })()}
                                      </NativeSelect>
                                    )}
                                  </Grid>
                                  <div style={{ marginTop: '1rem', display: 'flex' }}>
                                    <Tooltip
                                      title={`Delete ${values.columns[index].fixedName} mapping`}
                                    >
                                      <RemoveCircleOutlineIcon
                                        className={classes.queryParametersButtons}
                                        onClick={() => index > 0 && remove(index)}
                                      />
                                    </Tooltip>
                                    <Tooltip title={`Add a duplicate mapping`}>
                                      <AddCircleIcon
                                        className={classes.queryParametersButtons}
                                        onClick={() =>
                                          insert(index + 1, {
                                            fixedName: values.columns[index].fixedName,
                                            type: '',
                                            field: '',
                                            parent: values.columns[index].parent,
                                          })
                                        }
                                      />
                                    </Tooltip>
                                    {['Object', 'object'].includes(values.columns[index].type) && (
                                      <Tooltip
                                        title={`Add new child to ${values.columns[index].fixedName}`}
                                      >
                                        <AddCircleOutlineIcon
                                          className={classes.queryParametersButtons}
                                          onClick={() =>
                                            insert(index + 1, {
                                              fixedName: '',
                                              type: '',
                                              field: '',
                                              parent: values.columns[index].fixedName,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              ))
                            }
                          </FieldArray>
                        </Box>
                        <Box>
                          <Grid container>
                            <Grid item md={4}>
                              <Typography className={classes.formTitle}>Roles </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography className={classes.formTitle}>Mapped Role</Typography>
                            </Grid>
                          </Grid>
                          <FieldArray name="roles">
                            {() =>
                              [
                                { key: 'eventsOrganizations', value: `Organization's Event Role` },
                                { key: 'eventsStakeholders', value: `Stakeholder's Event Role` },
                                {
                                  key: 'stakeholdersOrganizations',
                                  value: `Stakeholder's Organization Role`,
                                },
                              ].map(({ key, value }, index) => (
                                <div
                                  key={`${key}-${index}`}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '1rem',
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    style={{
                                      paddingLeft: '1rem',
                                    }}
                                  >
                                    <Typography className={classes.formRoles}>{value}</Typography>
                                  </Grid>
                                  <Grid item md={6} style={{ paddingRight: '1rem' }}>
                                    <NativeSelect
                                      variant="outlined"
                                      fullWidth
                                      style={{ padding: 0 }}
                                      inputProps={{ name: `roles.${key}` }}
                                      onChange={handleChange(`roles.${key}`)}
                                    >
                                      {Object.values(Roles).map((roleOptions, idx) => (
                                        <option key={`${roleOptions}-${idx}`} value={roleOptions}>
                                          {toTitleCase(roleOptions)}
                                        </option>
                                      ))}
                                    </NativeSelect>
                                  </Grid>
                                </div>
                              ))
                            }
                          </FieldArray>
                        </Box>
                        <Box
                          style={{
                            backgroundColor: primaryBlue,
                            color: white,
                            height: '20rem',
                            overflow: 'scroll',
                            margin: '1rem 0',
                          }}
                        >
                          <pre>{JSON.stringify(dataMapperColumnsFromUrl, null, 2)}</pre>
                        </Box>
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: '1rem' }}
                            onClick={() => setTabValue(DataMapperTabs.DataResponse)}
                          >
                            Previous
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitDataMapperPreview(values)}
                            disabled={false}
                            style={{ marginLeft: '1rem' }}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <Loader />
              )}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={DataMapperTabs.RunSchedule} height={'auto'}>
          <PaperHeader className={classes.header}>
            <Typography className={classes.headerTitle}>Run Schedule</Typography>
          </PaperHeader>
          <Box px={6} pb={6}>
            {currentCronValue.length !== 0 && (
              <Grid item>{cronstrue.toString(currentCronValue)}</Grid>
            )}
            <Formik
              enableReinitialize
              initialValues={{}}
              onSubmit={handleSubmitDataMapperPreview}
              validateOnMount
              validationSchema={DataMapperCronRequestSchema}
            >
              {({ values }) => {
                return (
                  <Form>
                    <Box>
                      <div className={classes.cronSchedulerContainer}>
                        <FormTextField
                          name="minutes"
                          label="Minutes"
                          inputProps={{
                            style: {
                              display: 'flex',
                              margin: '0 1rem',
                              flexDirection: 'column-reverse',
                              alignItems: 'center',
                            },
                          }}
                        />
                        <FormTextField
                          name="hours"
                          label="Hours"
                          style={{
                            display: 'flex',
                            margin: '0 1rem',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                          }}
                        />
                        <FormTextField
                          name="monthDay"
                          label="Day of the Month"
                          style={{
                            display: 'flex',
                            margin: '0 1rem',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                          }}
                        />
                        <FormTextField
                          name="month"
                          label="Month"
                          style={{
                            display: 'flex',
                            margin: '0 1rem',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                          }}
                        />
                        <FormTextField
                          name="weekDay"
                          label="Day of the Week"
                          style={{
                            display: 'flex',
                            margin: '0 1rem',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                          }}
                        />
                      </div>
                      <Grid item>
                        <Typography>Ingest Data From This Date</Typography>
                        <DatePicker
                          value={startDate}
                          onChange={handleStartDateChange}
                          renderInput={renderInput}
                        />
                      </Grid>
                      <Grid item>
                        <Button></Button>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginLeft: '1rem' }}
                          onClick={() => setTabValue(DataMapperTabs.Preview)}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubmitDataMapperSchedule(values)}
                          disabled={handleDisableLogic(values)}
                          style={{ marginLeft: '1rem' }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={DataMapperTabs.Review} height={'auto'}>
          <PaperHeader className={classes.header}>
            <Typography className={classes.headerTitle}>Review</Typography>
          </PaperHeader>
          <Box px={6} pb={6} className={classes.reviewItems}>
            <Grid item className={classes.reviewItem}>
              <Typography className={classes.reviewItemTitle}>Source Name:</Typography>
              <Typography>{dataMapperETLRequest.source_name}</Typography>
            </Grid>
            <Grid item className={classes.reviewItem}>
              <Typography className={classes.reviewItemTitle}>Data Mapper Name:</Typography>
              <Typography>{dataMapperETLRequest.data_mapper_name}</Typography>
            </Grid>
            <Grid item className={classes.reviewItem}>
              <Typography className={classes.reviewItemTitle}>ETL Start Date:</Typography>
              <Typography>{dataMapperETLRequest.settings.fromDate}</Typography>
            </Grid>
            <Grid item className={classes.reviewItem}>
              <Typography className={classes.reviewItemTitle}>URL:</Typography>
              <Typography>{dataMapperETLRequest.dataset_config.url}</Typography>
            </Grid>
            {dataMapperETLRequest.dataset_config.dataProp && (
              <Grid item className={classes.reviewItem}>
                <Typography className={classes.reviewItemTitle}>Property With Data: </Typography>
                <Typography>{dataMapperETLRequest.dataset_config.dataProp}</Typography>
              </Grid>
            )}
            <Grid item className={classes.reviewItem}>
              <Typography className={classes.reviewItemTitle}>Cron Schedule:</Typography>
              <Typography>
                {dataMapperETLRequest.cron}{' '}
                {dataMapperETLRequest.cron.length &&
                  `(${cronstrue.toString(dataMapperETLRequest.cron)})`}
              </Typography>
            </Grid>
            {dataMapperETLRequest.dataset_config.zipcodeBoundaryAPI && (
              <>
                <Grid item className={classes.reviewItem}>
                  <Typography className={classes.reviewItemTitle}>
                    Zipcode Boundary API:{' '}
                  </Typography>
                  <Typography>{dataMapperETLRequest.dataset_config.zipcodeBoundaryAPI}</Typography>
                </Grid>
                <Grid item className={classes.reviewItem}>
                  <Typography className={classes.reviewItemTitle}>Zipcode Property:</Typography>
                  <Typography>
                    {dataMapperETLRequest.dataset_config.zipcodeFields.zipcodeProp}
                  </Typography>
                </Grid>
                <Grid item className={classes.reviewItem}>
                  <Typography className={classes.reviewItemTitle}>
                    Zipcode Boundary Objects:
                  </Typography>
                  <Typography>
                    {dataMapperETLRequest.dataset_config.zipcodeFields.boundaryObj}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item className={classes.reviewDataSet}>
              <Grid>
                <Typography className={classes.reviewItemTitle}>Data Set Configuration</Typography>
                <Box
                  style={{
                    backgroundColor: primaryBlue,
                    color: white,
                    height: '15rem',
                    overflow: 'scroll',
                    width: '25rem',
                    marginRight: '.5rem',
                    paddingLeft: '.5rem',
                  }}
                >
                  <pre>
                    {JSON.stringify(dataMapperETLRequest.dataset_config.columns, null, 2).replace(
                      '/n',
                      '',
                    )}
                  </pre>
                </Box>
              </Grid>
              <Grid>
                <Typography className={classes.reviewItemTitle} style={{ paddingLeft: '.5rem' }}>
                  Mapped Roles
                </Typography>
                <Box
                  style={{
                    backgroundColor: primaryBlue,
                    color: white,
                    height: '15rem',
                    overflow: 'scroll',
                    width: '25rem',
                    marginLeft: '.5rem',
                    paddingLeft: '.5rem',
                  }}
                >
                  <pre>
                    {JSON.stringify(dataMapperETLRequest.dataset_config.roles, null, 2).replace(
                      '/n',
                      '',
                    )}
                  </pre>
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '1rem 0',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: '1rem' }}
                onClick={() => setTabValue(DataMapperTabs.RunSchedule)}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitETLRequest}
                disabled={false}
                style={{ marginLeft: '1rem' }}
              >
                Send ETL Request
              </Button>
            </Grid>
          </Box>
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default DataMapperCreateWizard;
