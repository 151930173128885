import { makeStyles } from '@material-ui/core/styles';

import { secondary } from '../../../../theme';

const useDrawerCardProjectLeadStyle = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  body: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    padding: '.5rem',
  },
  score: {
    paddingRight: '.25rem',
    width: '30%',
  },
  name: {
    paddingLeft: '.25rem',
    width: '70%',
  },
  property: {
    marginBottom: '.5rem',
  },
  row: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  newBanner: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '0rem',
    left: '-2.5rem',
    height: '.5rem',
    width: '8rem',
    transform: 'rotate(-36.61deg)',
    backgroundColor: '#172D50',
    color: secondary,
    zIndex: 10,
    padding: '.7rem',
    overflow: 'hidden',
  },
  newText: {
    paddingRight: '1.1rem',
  },
});

export default useDrawerCardProjectLeadStyle;
