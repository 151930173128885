import React, { useState, useEffect, SetStateAction } from 'react';
import numeral from 'numeral';
import { CSVDownload } from 'react-csv';
import { Bar } from 'react-chartjs-2';
import { DateTime } from 'luxon';
import {
  Paper,
  makeStyles,
  TextField,
  Button,
  Typography,
  Grid,
  Checkbox,
} from '@material-ui/core';
import { DateRangePicker, DateRange, DateRangeDelimiter } from '@material-ui/pickers';

import PaperBody from '../../Common/PaperBody';
import PaperHeader from '../../Common/PaperHeader';
import PaperTitle from '../../Common/PaperTitle';
import { getMetrics } from '../../../api/metrics';
import { useMessages } from '../../../state/contexts';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 24),
    },
  },
  paper: {
    borderRadius: 8,
  },
  picker: {
    marginBottom: 16,
  },
}));

const barOptions = {
  barValueSpacing: 20,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

type Quintile = {
  percentConverted: string;
  percentDemoted: string;
  percentUntransitioned: string;
  percentUnworked: string;
  percentWithStakeholders: string;
  percentWorked: string;
  quintile: string;
  totalConverted: string;
  totalDemoted: string;
  totalLeads: string;
  totalUntransitioned: string;
  totalUnworked: string;
  totalWithStakeholders: string;
  totalWorked: string;
  [key: string]: any;
};

type ChartData = {
  labels: string[];
  datasets: Datasets[];
};

type Datasets = {
  label: string;
  backgroundColor: string;
  data: any[];
  key: string;
};

const Quintiles: React.FC = () => {
  const { setPageLoading, setErrorMessage } = useMessages();
  const classes = useStyles();
  const [csvData, setCsvData] = useState('');
  const [quintiles, setQuintiles] = useState<Array<Quintile>>([]);
  const [showQuintiles, setShowQuintiles] = useState<Record<string, boolean>>({
    '0 - 20': true,
    '20 - 40': true,
    '40 - 60': true,
    '60 - 80': true,
    '80 - 100': true,
  });
  const [dates, setDates] = useState<DateRange<DateTime>>([null, null]);

  const downloadReport = async () => {
    const dateStart = dates[0];
    const dateEnd = dates[1];

    setPageLoading(true);
    try {
      const metrics = await getMetrics({
        dateStart,
        dateEnd,
        csv: true,
      });
      setCsvData(metrics);
    } catch (err: any) {
      setErrorMessage('Error fetching report', err);
    }
    setPageLoading(false);
  };

  const handleDatesChange = (newValue: SetStateAction<DateRange<DateTime>>) => {
    setDates(newValue);
  };

  useEffect(() => {
    if (csvData) {
      setCsvData('');
    }
  }, [csvData]);

  const fetchData = async (): Promise<void> => {
    const dateStart = dates[0];
    const dateEnd = dates[1];

    setPageLoading(true);
    try {
      const metrics = await getMetrics({
        dateStart,
        dateEnd,
        csv: false,
      });
      setQuintiles(metrics);
    } catch (err: any) {
      setErrorMessage('Error fetching report', err);
    }
    setPageLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrorMessage, setPageLoading]);

  const handleDatesClose = async (): Promise<void> => {
    await fetchData();
  };

  if (quintiles.length === 0) {
    return null;
  }

  const barPercentData: ChartData = {
    datasets: [
      {
        key: 'percentConverted',
        label: 'Percent Converted',
        backgroundColor: 'red',
        data: [],
      },
      {
        key: 'percentDemoted',
        label: 'Percent Demoted',
        backgroundColor: 'blue',
        data: [],
      },
      {
        key: 'percentUntransitioned',
        label: 'Percent Untransitioned',
        backgroundColor: 'orange',
        data: [],
      },
      {
        key: 'percentUnworked',
        label: 'Percent Unworked',
        backgroundColor: 'grey',
        data: [],
      },
      {
        key: 'percentWorked',
        label: 'Percent Worked',
        backgroundColor: 'green',
        data: [],
      },
      {
        key: 'percentWithStakeholders',
        label: 'Percent With Stakeholders',
        backgroundColor: 'yellow',
        data: [],
      },
    ],
    labels: [],
  };

  const barTotalData: ChartData = {
    datasets: [
      {
        key: 'totalConverted',
        label: 'Total Converted',
        backgroundColor: 'red',
        data: [],
      },
      {
        key: 'totalDemoted',
        label: 'Total Demoted',
        backgroundColor: 'blue',
        data: [],
      },
      {
        key: 'totalUntransitioned',
        label: 'Total Untransitioned',
        backgroundColor: 'orange',
        data: [],
      },
      {
        key: 'totalUnworked',
        label: 'Total Unworked',
        backgroundColor: 'grey',
        data: [],
      },
      {
        key: 'totalWorked',
        label: 'Total Worked',
        backgroundColor: 'green',
        data: [],
      },
      {
        key: 'totalWithStakeholders',
        label: 'Total With Stakeholders',
        backgroundColor: 'yellow',
        data: [],
      },
    ],
    labels: [],
  };

  quintiles.forEach(q => {
    if (!showQuintiles[q.quintile]) {
      return;
    }
    barPercentData.labels.push(q.quintile);
    barTotalData.labels.push(q.quintile);

    barPercentData.datasets.forEach(d => {
      d.data.push(numeral(q[d.key]).value() * 100);
    });
    barTotalData.datasets.forEach(d => {
      d.data.push(numeral(q[d.key]).value());
    });
  });

  return (
    <Paper className={classes.paper}>
      <PaperHeader>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <PaperTitle title="Quintiles" />
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            <Button variant="contained" color="primary" onClick={downloadReport}>
              Download CSV
            </Button>
          </Grid>
        </Grid>
      </PaperHeader>
      <PaperBody>
        <Grid container className={classes.picker}>
          <Grid>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={dates}
              onChange={handleDatesChange}
              onClose={handleDatesClose}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField {...endProps} />
                </>
              )}
            />
            <Typography>Leaving values blank will generate report for all time.</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Bar data={barPercentData} width={100} height={50} options={barOptions} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Bar data={barTotalData} width={100} height={50} options={barOptions} />
          </Grid>
        </Grid>
        <Grid container>
          {quintiles.map(q => (
            <Grid item key={q.quintile}>
              <label>{q.quintile}</label>
              <Checkbox
                checked={showQuintiles[q.quintile]}
                onChange={() =>
                  setShowQuintiles(cur => ({ ...cur, [q.quintile]: !cur[q.quintile] }))
                }
              />
            </Grid>
          ))}
        </Grid>
      </PaperBody>
      {csvData && <CSVDownload target="_self" data={csvData} />}
    </Paper>
  );
};

export default Quintiles;
