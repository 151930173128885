import React from 'react';
import { Cell } from 'react-table';
import { Grid, TableCell as MuiTableCell } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import clsx from 'clsx';

import { useReactTableCellStyles } from './ReactTableCell.styles';

import { bulkDemoteLeadsButtonPressedState } from '../../../../state/atoms/projectLeads';

export interface ReactTableCellProps<TableType extends Record<any, any>> {
  cell: Cell<TableType>;
  viewed?: boolean;
}

const ReactTableCell = <TableType extends Record<any, any>>(
  props: ReactTableCellProps<TableType>,
): JSX.Element => {
  const { cell, viewed } = props;
  const bulkDemoteButtonPressed = useRecoilValue(bulkDemoteLeadsButtonPressedState);
  const classes = useReactTableCellStyles(props);
  const selectionCell = cell.column.id === '_selector';
  const scoreCell = cell.column.id === 'score';
  const gearIconCell = cell.column.id === 'customizeColumnsGearIcon';

  return (
    <MuiTableCell
      {...cell.getCellProps()}
      className={clsx(
        classes.root,
        scoreCell && classes.scoreIcon,
        gearIconCell && classes.gearIconCell,
        viewed && classes.viewed,
      )}
      size="small"
    >
      <Grid container>
        <Grid item className={classes.rowCellText}>
          {selectionCell && !bulkDemoteButtonPressed ? '' : cell.render('Cell')}
        </Grid>
      </Grid>
    </MuiTableCell>
  );
};

export default ReactTableCell;
