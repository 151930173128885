import { Box, makeStyles, styled } from '@material-ui/core';

import { primary, primaryBlue, light } from '../../../../theme';

export const NoRecordBlock = styled(Box)({
  minHeight: '200px',
  justifyContent: 'center',
  fontSize: '1.21rem',
  fontWeight: 'bold',
  paddingTop: '40px',
  display: 'flex',
});

const useLeadsTableStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '3rem',
  },
  showNew: {
    color: theme.palette.primary.main,
  },
  results: {
    margin: theme.spacing(0, 4),
  },
  table: {
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    backgroundColor: '#fff',
  },
  tableCount: {
    fontSize: '0.71rem',
    color: primaryBlue,
    fontWeight: 'bold',
  },
  leadsListButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leadsListButton: {
    marginLeft: '1em',
  },
  leadListTableContainer: {
    height: 1,
  },
  savedFiltersTableContainer: {
    margin: '3rem 2rem 0 2rem',
  },
  infiniteScrollContainer: {
    maxHeight: '72vh',
    overflow: 'scroll',
  },
  savedFilterLabel: {
    fontWeight: 'bold',
    color: `${theme.palette.primary.main} !important`,
    whiteSpace: 'nowrap',
  },
  savedFilterLabelActive: {
    color: `${theme.palette.success.main} !important`,
  },
  headCell: {
    padding: theme.spacing(0, 1),
  },
  savedFilterHeadCell: {
    padding: theme.spacing(0, 2),
  },
  savedFilterHeadCellRoot: {
    padding: theme.spacing(0, 4),
  },
  savedFilterRoot: {
    width: '100%',
    border: `1px solid ${primary}`,
    borderLeft: `6px solid ${primary}`,
    borderRadius: 6,
    overflow: 'hidden',
    position: 'relative',
  },
  savedFilterMain: {
    width: '100%',
    margin: 0,
    // backgroundColor: white,
    boxShadow: 'none',
    cursor: 'pointer',
    padding: theme.spacing(2, 4),
    '&:hover': {
      backgroundColor: light,
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        padding: theme.spacing(0, 1),
      },
    },
  },
  savedFilterBorderClass: {
    justifyContent: 'center',
  },
  savedFilterName: {
    fontSize: '1.1rem',
    color: '#0C4377',
    fontWeight: 600,
  },
  savedFilterOwner: {
    fontWeight: 'bold',
  },
}));

export default useLeadsTableStyles;
