import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Snackbar from './Snackbar';

import { Body, Header, Footer } from '../..';
import ErrorPage from '../../Pages/Error';
import { useMessages } from '../../../state/contexts';

interface Props {
  children: React.ReactNode;
}

export function PageLayout({ children }: Props): JSX.Element {
  const { isLoading, isAuthenticated } = useAuth0();
  const {
    message,
    type: messageType,
    clearMessage,
    clearPageError,
    pageLoading,
    pageFetching,
    pageError,
  } = useMessages();
  const location = useLocation();

  useEffect(() => {
    clearPageError();
  }, [location, clearPageError]);

  const loading = isLoading || !isAuthenticated || pageLoading;
  return (
    <>
      {isAuthenticated && <Header />}
      <Body loading={loading} fetching={pageFetching}>
        {pageError ? <ErrorPage type={pageError} /> : children}
      </Body>
      <Snackbar type={messageType} open={Boolean(message)} onClose={clearMessage}>
        <>{message}</>
      </Snackbar>
      {isAuthenticated && <Footer />}
    </>
  );
}
