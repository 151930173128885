import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';

import CreateContactModal, { IModalProps } from './CreateContactModal';

import FormSelect from '../../Common/FormSelect';
import FormTextField from '../../Common/FormTextField';
import {
  addOrganizationStakeholder,
  createOrganizationStakeholder,
} from '../../../api/organizations';
import { addProjectStakeholder, createProjectStakeholder } from '../../../api/projects';
import { addPropertyStakeholder, createPropertyStakeholder } from '../../../api/properties';
import { ModalType, StakeholderRoleMapping } from '../../../constants';
import type { Organization, Project, Property } from '../../../models';
import { IContactDetails, StakeholderRole } from '../../../models/common';
import Stakeholder, {
  IOrganizationStakeholderRequest,
  IProjectStakeholderRequest,
  IPropertyStakeholderRequest,
  ProjectStakeholderRequestSchema,
} from '../../../models/stakeholder';
import { createStakeholder } from '../../../api/stakeholders';
import { Optional } from '../../../types';
import { convertAddressKeys } from '../../../utils/address';

export interface ICreateStakeholderProps {
  organization?: Organization;
  project?: Project;
  property?: Property;
}

type IProps = IModalProps & ICreateStakeholderProps;

type IFormValues =
  | IOrganizationStakeholderRequest
  | IProjectStakeholderRequest
  | IPropertyStakeholderRequest;

const CreateStakeholderModal: React.FC<IProps> = ({
  open,
  onClose,
  organization,
  project,
  property,
}) => {
  const handleSubmit = useCallback(
    async (
      values: IFormValues,
      contact?: Partial<Stakeholder>,
      addContactParams: Record<string, boolean> = {},
    ): Promise<Optional<Stakeholder>> => {
      if (!contact) {
        const v = convertAddressKeys(values);
        return createStakeholder({
          name: v.name as string,
          title: v.title as string,
          description: v.description as string,
          contact: v.contact as IContactDetails,
          address_1: v.address_1 as string,
          address_2: v.address_2 as string,
          city: v.city as string,
          state: v.state as string,
          zipcode: v.zipcode as string,
        });
      }
      if (organization) {
        if (contact?.id) {
          await addOrganizationStakeholder(organization.id, contact.id, values.stakeholder_role);
        } else {
          await createOrganizationStakeholder(
            organization.id,
            values as IOrganizationStakeholderRequest,
          );
        }
      } else if (project && addContactParams.addToProject) {
        if (contact?.id) {
          await addProjectStakeholder(project.id, contact.id, {
            stakeholder_role: values.stakeholder_role,
            is_primary: (values as IProjectStakeholderRequest).is_primary,
          });
        } else if (!contact) {
          await createProjectStakeholder(project.id, values as IProjectStakeholderRequest);
        }
      } else if (property && addContactParams.addToProperty) {
        if (contact?.id) {
          await addPropertyStakeholder(property.id, contact.id, {
            stakeholder_role: values.stakeholder_role,
          });
        } else {
          await createPropertyStakeholder(property.id, values as IPropertyStakeholderRequest);
        }
      } else if (contact && !property && !organization && !project) {
        return;
      } else {
        throw new Error('Missing required field');
      }
    },
    [organization, project, property],
  );

  const roleLabel: string = useMemo(() => {
    if (organization) {
      return 'Account Role';
    }

    if (project) {
      return 'Project Role';
    }

    return 'Property Role';
  }, [organization, project]);

  return (
    <CreateContactModal
      open={open}
      onClose={onClose}
      showPrimary={!!project}
      modalType={property ? ModalType.AddLeadStakeholder : ModalType.AddOrganizationStakeholder}
      modelType={Stakeholder.modelName}
      initialValues={
        {
          name: '',
          stakeholder_role: StakeholderRole.Other,
        } as IFormValues
      }
      validationSchema={ProjectStakeholderRequestSchema}
      onSubmit={handleSubmit}
      redirectOnSearch={!property && !project && !organization}
    >
      <Grid item>
        <FormTextField name="title" label="Position or Title" fullWidth />
      </Grid>
      {(organization || property || project) && (
        <Grid item>
          <FormSelect
            name="stakeholder_role"
            label={roleLabel}
            options={StakeholderRoleMapping}
            fullWidth
          />
        </Grid>
      )}
    </CreateContactModal>
  );
};

export default CreateStakeholderModal;
