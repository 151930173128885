import { Button, Divider, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import NotificationCard from './NotificationCard';
import useNotificationsListStyles from './NotificationsList.styles';

import {
  deleteNotifications,
  DetailedNotification,
  getNotificationsDetailed,
} from '../../../../api/notifications';

interface IProps {
  count: number;
  setNotificationCount: (count: number) => void;
  setNotificationIds: (ids: string[]) => void;
}

const NotificationsList: React.FC<IProps> = ({
  count,
  setNotificationCount,
  setNotificationIds,
}: IProps) => {
  const [detailedNotificationsList, setDetailedNotificationsList] = useState<
    DetailedNotification[]
  >([] as DetailedNotification[]);

  const [confirmRemoveAllOpen, setConfirmRemoveAllOpen] = useState<boolean>(false);

  const loadNotifications = useCallback(async () => {
    const detailedNotifications = await getNotificationsDetailed();
    setDetailedNotificationsList(detailedNotifications);
    setNotificationIds(detailedNotifications.map(n => n.id));
  }, [setDetailedNotificationsList, setNotificationIds]);

  const deleteNotification = useCallback(
    async (ids: string[]) => {
      const notificationsList = await deleteNotifications(ids);
      setDetailedNotificationsList(notificationsList);
      setNotificationCount(notificationsList.filter(n => !n.viewed).length);
    },
    [setDetailedNotificationsList, setNotificationCount],
  );

  const handleDeleteAll = async () => {
    const ids = detailedNotificationsList.map(notif => notif.id);
    await deleteNotification(ids);
    setConfirmRemoveAllOpen(false);
  };

  const classes = useNotificationsListStyles();

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <Grid container spacing={1} className={classes.main}>
      <Grid className={classes.title}>
        <Typography variant="h4">
          <strong>Notifications ({count})</strong>
        </Typography>
        <Divider className={classes.divider} />
        <Button
          disabled={detailedNotificationsList.length === 0}
          variant="contained"
          className={classes.button}
          size="medium"
          onClick={() => setConfirmRemoveAllOpen(true)}
        >
          {'Remove All'}
        </Button>
      </Grid>
      {confirmRemoveAllOpen && (
        <Grid className={classes.confirmation}>
          <div className={classes.confirmationText}>
            <Typography variant="body1">
              Are you sure you want to remove all notifications?
            </Typography>
          </div>
          <div className={classes.confirmationButtons}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              style={{ marginRight: '1.2rem' }}
              onClick={() => handleDeleteAll()}
            >
              Yes
            </Button>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={() => setConfirmRemoveAllOpen(false)}
            >
              No
            </Button>
          </div>
        </Grid>
      )}
      {detailedNotificationsList.length > 0 && (
        <div className={classes.notifications}>
          <Grid>
            {detailedNotificationsList.map(notification => (
              <div key={notification.id} className={classes.notification}>
                <NotificationCard
                  notification={notification}
                  deleteNotification={deleteNotification}
                />
              </div>
            ))}
          </Grid>
        </div>
      )}
      {detailedNotificationsList.length === 0 && (
        <Grid className={classes.noNotifications}>
          <Typography variant="subtitle2">There are no notifications.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NotificationsList;
