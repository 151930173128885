import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';

import ConfirmationDialog from './ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    width: 64,
    height: 64,
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: 34,
    height: 34,
    color: theme.palette.success.main,
  },
}));

interface IProps {
  fallbackPath?: string;
  onClick?: () => void;
  needsConfirmation?: boolean;
  confirmationTitle?: string;
  confirmationContent?: string;
  className?: string;
}

const BackButton: React.FC<IProps> = ({
  fallbackPath,
  onClick,
  needsConfirmation = false,
  confirmationTitle = 'Discarding changes...',
  confirmationContent = 'Are you sure you want to leave the current page? All unsaved changes will be discarded',
  className,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const goBack = useCallback(() => {
    setOpen(false);
    if (onClick) {
      onClick();
    } else if (history.length > 2 || !fallbackPath) {
      history.goBack();
    } else {
      history.replace(fallbackPath);
    }
  }, [onClick, history, fallbackPath]);

  const handleClick = useCallback(() => {
    if (needsConfirmation) {
      setOpen(true);
    } else {
      goBack();
    }
  }, [needsConfirmation, goBack]);

  return (
    <>
      <IconButton
        className={clsx(classes.root, className || undefined)}
        aria-label="back"
        onClick={handleClick}
        data-testid="backButton"
      >
        <BackIcon className={classes.icon} />
      </IconButton>
      {needsConfirmation && confirmationTitle && confirmationContent && (
        <ConfirmationDialog
          open={open}
          title={confirmationTitle}
          content={confirmationContent}
          onConfirm={goBack}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default BackButton;
