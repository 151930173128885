import React, { useCallback, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Popover, Tooltip } from '@material-ui/core';

import NotesPopup from './NotesPopup';

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginRight: theme.spacing(-2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  onEdit?: (id: string, notes: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  noteDetails?: { id: string; notes: string };
}

const NotesActionIcons: React.FC<IProps> = ({ noteDetails, onDelete, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  //use popover for edit
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        className={classes.actionButtons}
        aria-label="edit note"
        edge="end"
        size="small"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <Tooltip title={'Edit Note'} placement="top" arrow>
          <EditIcon />
        </Tooltip>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NotesPopup onEdit={onEdit} closePopover={handleClose} noteDetails={noteDetails} />
      </Popover>

      <Tooltip title={'Delete Note'} placement="top" arrow>
        <IconButton
          className={classes.actionButtons}
          aria-label="delete note"
          edge="end"
          size="small"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onDelete && onDelete();
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default NotesActionIcons;
