import React from 'react';
import { makeStyles, Box, Paper } from '@material-ui/core';
import type { PaperProps } from '@material-ui/core/Paper';

import { darkLight } from '../../theme';

interface IProps extends PaperProps {
  color?: string;
}

const useStyles = (color: string) =>
  makeStyles({
    root: {
      borderLeft: `6px solid ${color}`,
    },
  });

const BorderedPaper: React.FC<IProps> = ({ color = darkLight, ...props }) => {
  const classes = useStyles(color)();
  return <Box pl={3} pr={4} py={4} component={Paper} className={classes.root} {...props} />;
};

export default BorderedPaper;
