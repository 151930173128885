import React from 'react';
import { Theme, makeStyles, TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core/TextField';

import FormInput from './FormInput';

type IFontProps = {
  fontWeight?:
    | number
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'lighter'
    | undefined;
  fontColor?: string;
};

type IProps = TextFieldProps & IFontProps;

const useStyles = makeStyles<Theme, IFontProps>(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  input: {
    padding: 0,
    color: ({ fontColor }) => fontColor || 'inherit',
    fontWeight: ({ fontWeight }) => fontWeight || 'inherit',
    lineHeight: '1.68',
    letterSpacing: '0.8px',
  },
}));

const FormTextField: React.FC<IProps> = ({ label, fontWeight, fontColor, ...props }) => {
  const { name, fullWidth, required } = props;
  const classes = useStyles({ fontWeight, fontColor } as IFontProps);
  return (
    <FormInput name={name} label={required ? `${label}*` : label} fullWidth={fullWidth}>
      {inputProps => (
        <TextField variant="outlined" InputProps={{ classes }} {...inputProps} {...props} />
      )}
    </FormInput>
  );
};

export default FormTextField;
