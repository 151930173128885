import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import DataMapperConfigurationsTable from './DataMapperConfigurationsTable';
import { useDataMapperStyles, NoRecordBlock } from './DataMapper.styles';

import { getDataMapperConfigurations } from '../../../api/dataMapper';
import { dataMapperRowsState } from '../../../state/atoms/dataMapper';
import PaperHeader from '../../Common/PaperHeader';

const DataMapper: React.FC = () => {
  const classes = useDataMapperStyles();
  const [configurationRows, setConfigurationRows] = useRecoilState(dataMapperRowsState);
  const [errorMessage, setErrorMessage] = useState('');

  const loadConfigurations = useCallback(async () => {
    try {
      const { items, total } = await getDataMapperConfigurations();
      setConfigurationRows({ items, total });
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  }, [setConfigurationRows]);

  useEffect(() => {
    loadConfigurations();
  }, [loadConfigurations]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Paper className={classes.paper}>
        <PaperHeader className={classes.header}>
          <Typography className={classes.headerTitle}>All Configurations</Typography>
          <div>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              size="medium"
              className={classes.headerButton}
              component={Link}
              to="/data-mapper/create"
            >
              Create New
            </Button>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              size="medium"
              className={classes.headerButton}
              disabled
            >
              Download CSV
            </Button>
          </div>
        </PaperHeader>
        <Box px={6} pb={6}>
          {configurationRows.total > 0 ? (
            <DataMapperConfigurationsTable
              rows={configurationRows.items}
              total={configurationRows.total}
              refreshData={loadConfigurations}
            />
          ) : (
            <NoRecordBlock> {errorMessage || 'No ETL Configurations Available'}</NoRecordBlock>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default DataMapper;
