import type { IResponse } from './api';
import API, { APICancelToken } from './api';

import {
  Event,
  Organization,
  Project,
  Stakeholder,
  Feedback,
  IEventResponse,
  IOrganizationResponse,
  IProjectResponse,
  IStakeholderResponse,
  IFeedbackResponse,
} from '../models';
import type {
  IAddProjectStakeholderRequest,
  IProjectStakeholderRequest,
} from '../models/stakeholder';
import { ProjectStatus, ProjectStatusAction } from '../models/common';
import encodeFilter from '../utils/encodeFilter';
import {
  IProject,
  SortColumns,
  ViewFilterRequest,
} from '../Components/Pages/Leads/Archive/Archive.config';
import { sanitizeFilterConfig } from '../utils/filter';
import TransitionReason from '../models/transitionReason';

export const updateProjectById = async (
  id: string,
  params: Partial<IProjectResponse>,
): Promise<Project> => {
  const body: any = { ...params };
  if (body.owner) {
    const ownerId = body?.owner;
    delete body.owner;
    body.owned_by = ownerId;
  }
  const { data } = await API.patch(`/projects/${id}`, body);
  return new Project(data as IProjectResponse);
};

export const updateProjectStateById = async (
  id: string,
  action: ProjectStatusAction,
  params: Partial<{
    reason: string;
    value: string;
    score?: string;
    note?: string;
  }>,
): Promise<Project> => {
  const { data } = await API.patch(`/projects/${id}/${action}`, params);
  return new Project(data as IProjectResponse);
};

// TODO: support pagination
export const getProjectEvents = async (id: string): Promise<IResponse<Event>> => {
  const { data } = await API.get(`/projects/${id}/events`);
  const events = (data as IResponse<IEventResponse>).items.map(item => new Event(item));
  return { items: events, total: data.total };
};

export const addProjectEvents = async (id: string, eventIds: string[]): Promise<void> => {
  await API.put(`/projects/${id}/events`, { eventIds });
  return;
};

export const getProjectFeedbacks = async (id: string): Promise<IResponse<Feedback>> => {
  const { data } = await API.get(`/projects/${id}/feedbacks`);
  const feedbacks = (data as IResponse<IFeedbackResponse>).items.map(item => new Feedback(item));
  return { items: feedbacks, total: data.total };
};

export const addProjectFeedbacks = async (id: string, feedbackIds: string[]): Promise<void> => {
  await API.put(`/projects/${id}/feedbacks`, { feedbackIds });
  return;
};

export const removeProjectEvents = async (id: string, eventIds: string[]): Promise<void> => {
  await API.delete(`/projects/${id}/events`, {
    data: { eventIds },
  });
  return;
};

export const inactivateProjectById = async (id: string): Promise<Project> =>
  updateProjectById(id, { status: ProjectStatus.Inactive });

export const deleteProjectById = async (id: string): Promise<void> => API.delete(`/projects/${id}`);

export const getProjectLeadStakeholders = async (id: string): Promise<Stakeholder[]> => {
  const { data } = await API.get(`/projects/${id}/project-lead-stakeholders`);
  return (data as IStakeholderResponse[]).map(IStakeholder => new Stakeholder(IStakeholder));
};

export const getProjectLeadOrganizations = async (id: string): Promise<Organization[]> => {
  const { data } = await API.get(`/projects/${id}/project-lead-organizations`);
  return (data as IOrganizationResponse[]).map(IOrganization => new Organization(IOrganization));
};

export const getProjectStakeholders = async (id: string): Promise<IResponse<Stakeholder>> => {
  const { data } = await API.get(`/projects/${id}/stakeholders`);
  const items = (data as IResponse<IStakeholderResponse>).items.map(item => new Stakeholder(item));
  return { ...data, items };
};

export const createProjectStakeholder = async (
  id: string,
  projectStakeholder: IProjectStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.post(`/projects/${id}/stakeholders`, projectStakeholder);
  return new Stakeholder(data as IStakeholderResponse);
};

export const addProjectStakeholder = async (
  id: string,
  stakeholderId: string,
  projectStakeholder: IAddProjectStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.post(
    `/projects/${id}/stakeholders/${stakeholderId}`,
    projectStakeholder,
  );
  return new Stakeholder(data as IStakeholderResponse);
};

export const deleteProjectStakeholders = async (
  id: string,
  stakeholderIds: string[],
): Promise<void> => {
  await API.delete(`/projects/${id}/stakeholders`, {
    data: { stakeholderIds },
  });
  return;
};

export const getProjectOrganizations = async (id: string): Promise<IResponse<Organization>> => {
  const { data } = await API.get(`/projects/${id}/organizations`);
  const items = (data as IResponse<IOrganizationResponse>).items.map(
    item => new Organization(item),
  );
  return { ...data, items };
};

export const hideProject = async (id: string): Promise<void> => {
  await API.patch(`/projects/${id}/delete`);
};

export async function getProjectViewFilter<T>(
  projectsRequest: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
  cancelToken?: APICancelToken,
): Promise<IResponse<IProject>> {
  const configOptions = sanitizeFilterConfig(projectsRequest, sortColumns, 'projectLeads');
  // POST method used because query parameter could exceed length limit and error out
  const { data } = await API.post(
    '/projects/projects-view/all',
    { get_filter: encodeFilter(configOptions) },
    {
      cancelToken,
    },
  );
  return data as IResponse<IProject>;
}

export async function getProjectViewTransitioned<T>(
  projectsRequest: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
  cancelToken?: APICancelToken,
): Promise<IResponse<IProject>> {
  const configOptions = sanitizeFilterConfig(projectsRequest, sortColumns, 'projectLeads');
  // POST method used because query parameter could exceed length limit and error out
  const { data } = await API.post(
    '/projects/projects-view/transitioned',
    { get_filter: encodeFilter(configOptions) },
    {
      cancelToken,
    },
  );
  return data as IResponse<IProject>;
}

export const getConversionReasons = async (): Promise<TransitionReason[]> => {
  const { data } = await API.get(`/projects/reasons/conversion`);
  return data as TransitionReason[];
};

export const getDemotionReasons = async (): Promise<TransitionReason[]> => {
  const { data } = await API.get(`/projects/reasons/demotion`);
  return data as TransitionReason[];
};

export const getTransitionedProjectCount = async (): Promise<{
  cDemoted: number;
  cConverted: number;
}> => {
  const { data } = await API.get('/projects/transitioned/count');
  return data as { cDemoted: number; cConverted: number };
};

export const getProjectDetails = async (projectId: string) => {
  const { data } = await API.get(`/projects/details/${projectId}`);
  return data;
};

export const addProjectLeadFavorite = async (id: string): Promise<void> => {
  return API.post(`/projects/${id}/favorite`);
};

export const deleteProjectLeadFavorite = async (id: string): Promise<void> => {
  return API.delete(`/projects/${id}/favorite`);
};

export const bulkDemoteProjects = async (
  params: Partial<{
    reason: string;
    value: string;
    score?: string;
    note?: string;
    idList: string[];
  }>,
): Promise<Project[]> => {
  const { data } = await API.patch(`/projects/demotions`, params);
  const projects = data.items.map((dataset: IProjectResponse) => new Project(dataset));
  return projects;
};
