import { makeStyles } from '@material-ui/core/styles';

import { primary } from '../../../../theme';

const useDataDropDownStyles = makeStyles({
  title: {
    padding: '.5rem',
    paddingLeft: '1rem',
    fontWeight: 'bold',
  },
  container1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  container2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    borderRadius: '4px',
    height: '2rem',
    padding: '1rem',
    margin: '1rem',
    marginTop: 0,
    display: 'flex',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    fontWeight: 'bold',
    alignItems: 'center',
  },
  buttonShow: {
    color: primary,
    border: `1px solid ${primary}`,
    backgroundColor: 'white',
  },
  buttonHide: {
    color: 'white',
    border: `1px solid white`,
    backgroundColor: `${primary}`,
  },
});

export default useDataDropDownStyles;
