import React, { useState } from 'react';
import {
  makeStyles,
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useLocation, Link, NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Notifications from './Notifications/Notifications';

import { primary, white } from '../../../theme';
import { LeadType, LeadTypeDict } from '../../../models/lead';
import Logo from '../../../resources/images/cleo-logo-wh.svg';
import { getLeadsPagePath, getUniversalSearchPagePath } from '../../../utils/page';
import { getUserRoleName } from '../../../utils/user';
import UniversalSearch from '../Search/UniversalSearch';

const a11yProps = (index: number): Record<string, unknown> => ({
  id: `header-tab-${index}`,
  'aria-controls': `header-tabpanel-${index}`,
});

const indexMap: Record<string, number> = {
  'project-leads': 0,
  accounts: 1,
  analytics: 2,
  insights: 3,
  'at-a-glance': 4,
  hidden: 5,
};

const tabStyle = makeStyles(() => ({
  root: {
    padding: 0,
    marginLeft: '.5rem',
    marginRight: '.5rem',
    height: '100%',
  },
  selected: {
    zIndex: 10,
    borderBottom: `6px solid ${primary}`,
    marginBottom: '0rem',
    borderLeft: 0,
    bottom: '-.1rem',
  },
  wrapper: {
    border: 'none',
    alignItems: 'center',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    paddingLeft: 0,
    //  height: 64px(Header height) - 6px(border) = 58px
    height: '58px',
  },
}));

const useStyles = makeStyles(theme => ({
  logo: {
    height: 20,
    margin: theme.spacing(0, 2),
    '& img': {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuItemContainer: {
    alignItems: 'center',
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  notifications: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
    padding: '.5rem',
  },
  userButton: {
    padding: '.5rem',
    color: white,
    textTransform: 'none',
  },
  userInfo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Header: React.FC = () => {
  const { pathname: currentPath } = useLocation();
  const currentPathPrefix = currentPath.split('/').filter(Boolean)[0];

  const isUniversalSearchPage = currentPath.startsWith(getUniversalSearchPagePath());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth0();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const tabsConfig = [
    { label: 'Project Leads', to: '/project-leads/home' },
    { label: 'Accounts', to: '/accounts/home' },
    { label: 'Analytics', to: '/analytics' },
    { label: 'Insights', to: '/insights' },
    { label: 'At A Glance', to: '/at-a-glance' },
    { label: 'Hidden', to: '' },
  ];

  const classes = useStyles();
  const isTabValue = indexMap[currentPathPrefix] !== undefined;
  return (
    <AppBar position="static">
      <Toolbar style={{ display: 'flex', flexDirection: 'row' }}>
        <Link to="/project-leads/home" className={classes.logo}>
          <img src={Logo} alt="Cleo" />
        </Link>
        <Grid container>
          <Tabs
            value={isTabValue ? indexMap[currentPathPrefix] : indexMap['hidden']}
            aria-label="nav tabs"
          >
            {tabsConfig.map((tab, idx) => {
              if (tab.label === 'Hidden') {
                return (
                  <Tab
                    key={`${tab.label}-${idx}`}
                    style={{ visibility: 'hidden', width: 0 }}
                    {...a11yProps(idx)}
                  />
                );
              }
              return (
                <Tab
                  key={`${tab.label}-${idx}`}
                  label={tab.label}
                  component={NavLink}
                  to={tab.to}
                  {...a11yProps(idx)}
                  classes={tabStyle()}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid container className={classes.menuItemContainer}>
          {!isUniversalSearchPage && (
            <Grid item className={classes.search}>
              <UniversalSearch />
            </Grid>
          )}
          <Grid
            item
            className={classes.notifications}
            style={{ marginLeft: isUniversalSearchPage ? 'auto' : '' }}
          >
            <Notifications />
          </Grid>
          <Grid item>
            <Button className={classes.userButton} onClick={handleUserMenuOpen} disableRipple>
              <Grid container spacing={3} alignItems="center">
                <Grid item className={classes.userInfo}>
                  <Box textAlign="right">
                    <Typography variant="subtitle1">{user.name}</Typography>
                    <Typography variant="body2">{getUserRoleName(user)}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Avatar src={user.picture} alt={user.name} />
                </Grid>
              </Grid>
              <Box ml={2}>{userMenuAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <Menu
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        onClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        keepMounted
      >
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log Out</MenuItem>
        {getUserRoleName(user) === 'Admin' && (
          <div>
            <MenuItem component={Link} to="/metrics" onClick={handleUserMenuClose}>
              Metrics
            </MenuItem>
            <MenuItem component={Link} to="/data-mapper" onClick={handleUserMenuClose}>
              Data Mapper
            </MenuItem>
          </div>
        )}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        keepMounted
      >
        {Object.values(LeadType).map(type => (
          <MenuItem
            key={type}
            component={NavLink}
            to={getLeadsPagePath(type)}
            onClick={handleClose}
          >
            {LeadTypeDict[type]} Leads
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

export default Header;
