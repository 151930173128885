import { Box, styled, makeStyles, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import {
  darkGray,
  darkLight,
  primary,
  primaryBlue,
  secondary,
  success,
  white,
} from '../../../theme';

interface TabStylesProps {
  fontSize?: string;
}

export const StyledTabs = withStyles({
  root: {
    minWidth: '90%',
    backgroundColor: 'white !important',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    margin: '0 2rem',
    borderBottom: `1px ${primaryBlue} solid`,
    '&:first-child div': {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  indicator: {
    backgroundColor: white,
  },
})(Tabs);

export const TabBarContainer = styled(Box)({
  display: 'flex',
});

export const tabStyles = makeStyles({
  root: {
    margin: '0 2rem 0 0',
    zIndex: 10,
    bottom: '-.1rem',
    backgroundColor: 'white !important',
    color: darkLight,
    fontWeight: 'bold',
    fontSize: (props?: TabStylesProps) => (props?.fontSize ? '1rem' : '1.21rem'),
    '&:hover': {
      backgroundColor: 'white !important',
      color: `${primaryBlue} !important`,
      borderRadius: 5,
    },
  },
  selected: {
    backgroundColor: 'white !important',
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    height: 10,
    overflow: 'hidden',
    borderBottom: `6px solid ${primaryBlue}`,
    paddingBottom: '0rem',
  },
  wrapper: {
    border: 'none',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
});

const useUniversalSearchStyles = makeStyles({
  headerSearchContainer: {
    height: '6rem',
    width: '100%',
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchForm: {
    marginRight: '0.8rem',
    width: '20rem',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 0px',
  },
  searchInput: {
    border: `1px solid ${primary}`,
    borderWidth: '1px 0 1px 1px',
    borderBottomLeftRadius: '6px',
    borderTopLeftRadius: '6px',
    width: '15rem',
    height: '2rem',
    color: darkGray,
    fontSize: '.8rem',
    fontWeight: 'normal',
    paddingLeft: '10px',
    backgroundColor: 'white',
  },
  searchButton: {
    border: `1px solid ${success}`,
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    textTransform: 'none',
    backgroundColor: success,
    color: secondary,
    fontSize: '.8rem',
    width: '2.25rem',
    height: '2rem',
    '&:hover': {
      backgroundColor: darkLight,
    },
  },
  icon: {
    color: primaryBlue,
  },
});

export default useUniversalSearchStyles;
