import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { DATA_PLACEHOLDER } from '../../../../constants';
import { formatArea, formatCurrency } from '../../../../utils/number';
import CircularProgressWithLabel from '../../../Common/CircularProgressWithLabel';
import { getLocalDateFromUtcTimestamp } from '../../../../utils/date';
import { toTitleCase } from '../../../../utils/string';

export const renderName = (name: string, bold = true): JSX.Element => {
  return <Typography variant="body2">{bold ? <strong>{name}</strong> : <>{name}</>}</Typography>;
};

export const renderValue = (value?: string): string => {
  return value || DATA_PLACEHOLDER;
};

export const renderNumber = (title: string, value: number, isMoney = false): JSX.Element => {
  return (
    <Typography variant="body2">
      {`${title}:  `}
      {value > 0 ? (isMoney ? formatCurrency(value) : formatArea(value)) : DATA_PLACEHOLDER}
    </Typography>
  );
};

export const renderString = (title: string, value?: string): JSX.Element => {
  return (
    <Typography variant="body2">
      {`${title}:  `}
      {value || DATA_PLACEHOLDER}
    </Typography>
  );
};

export const renderScore = (score: number, size = 50): JSX.Element => {
  return <CircularProgressWithLabel size={size} value={score} />;
};

export const renderDate = (title: string, date: string): JSX.Element => {
  return (
    <Typography variant={'body2'}>
      {`${title}:  `}
      {renderValue(getLocalDateFromUtcTimestamp(date) || '')}
    </Typography>
  );
};

export const renderProperty = (
  address: string,
  city: string,
  state: string,
  zipcode: string,
): JSX.Element => {
  const formattedAddr = toTitleCase(address);
  const stateZip = [state, zipcode].filter(Boolean).join(' ');
  const line2 = [city, stateZip].filter(Boolean).join(', ');
  return (
    <>
      {formattedAddr && <Typography variant={'body2'}>{formattedAddr}</Typography>}
      {line2 && <Typography variant="body2">{line2}</Typography>}
    </>
  );
};

export const renderContacts = (contacts: string[], renderCount: number): JSX.Element => {
  const renderContacts = contacts.map((contact, idx) => {
    if (idx > renderCount - 1) {
      return null;
    }
    return <Grid key={`${contact}-${idx}`}>{contact}</Grid>;
  });

  const additionalCount = contacts.length - renderCount;

  return (
    <>
      {renderContacts}
      {contacts.length > renderCount
        ? `${additionalCount} more ${additionalCount === 1 ? 'contact' : 'contacts'}`
        : null}
    </>
  );
};

export const renderProjectLeadsContactCount = (
  cProject: number,
  cLeads: number,
  cContact: number,
): JSX.Element => {
  return (
    <Typography variant="body2">{`${cProject} Projects | ${cLeads} Leads | ${cContact} Contacts`}</Typography>
  );
};
