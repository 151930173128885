import React, { useState } from 'react';
import { Grid, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';

import dropDownFilter from '../../../resources/images/drop_down_filter.svg';
import { FilterData } from '../../../types';

enum NationalAccountFilterOptions {
  ShowAll = 'Show All',
  TargetedAccounts = 'Show Only Targeted Accounts',
  NationalAccounts = 'Show Only National Accounts',
  HideNational = 'Hide National Accounts',
}

const nationalAccountsFilterConfig: {
  filterId: NationalAccountFilterOptions;
  label: string;
  defaultFilter: FilterData;
}[] = [
  {
    filterId: NationalAccountFilterOptions.ShowAll,
    label: '',
    defaultFilter: {},
  },
  {
    filterId: NationalAccountFilterOptions.TargetedAccounts,
    label: 'Showing Targeted National Accounts',
    defaultFilter: { hasNationalTargetAccount: { eq: true } },
  },
  {
    filterId: NationalAccountFilterOptions.NationalAccounts,
    label: 'Showing National Accounts',
    defaultFilter: { hasNationalAccount: { eq: true }, hasNationalTargetAccount: { eq: false } },
  },
  {
    filterId: NationalAccountFilterOptions.HideNational,
    label: 'National Accounts Hidden',
    defaultFilter: { hideNationalAccount: { eq: true } },
  },
];

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
  list: {
    padding: 0,
  },
  menuItem: {
    fontWeight: 'normal',
    color: 'black',
    margin: 0,
    padding: '1px 1.5rem !important',
    minHeight: '3rem',
  },
  selected: {
    fontWeight: 'bold',
  },
}));

interface INationalAccountFilterProps {
  setNationalAccountFilter: React.Dispatch<React.SetStateAction<FilterData>>;
}

const NationalAccountFilter: React.FC<INationalAccountFilterProps> = ({
  setNationalAccountFilter,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<NationalAccountFilterOptions>(
    NationalAccountFilterOptions.ShowAll,
  );
  const [filterLabel, setFilterLabel] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    selected: NationalAccountFilterOptions | null,
    label: string,
    filter: FilterData,
  ) => {
    if (selected) {
      setSelectedFilter(selected);
      setFilterLabel(label);
      setNationalAccountFilter(filter);
    }
    setAnchorEl(null);
  };

  return (
    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
      {selectedFilter !== NationalAccountFilterOptions.ShowAll && (
        <Typography style={{ display: 'flex', alignItems: 'center' }}>{filterLabel}</Typography>
      )}
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ backgroundColor: 'transparent' }}
      >
        <img src={dropDownFilter} alt={'national accounts drop down filter'} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null, '', {})}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        classes={{ paper: classes.paper, list: classes.list }}
      >
        {nationalAccountsFilterConfig.map(config => (
          <MenuItem
            key={config.filterId}
            onClick={() => handleClose(config.filterId, config.label, config.defaultFilter)}
            selected={config.filterId === selectedFilter}
            classes={{ root: classes.menuItem, selected: classes.selected }}
          >
            {config.filterId}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default NationalAccountFilter;
