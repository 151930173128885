import { makeStyles } from '@material-ui/core';

const useTableStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  infiniteScrollContainer: {
    maxHeight: '72vh',
    overflow: 'scroll',
  },
  rowContainer: {
    border: '2px solid transparent',
    '&:nth-child(odd)': {
      background: '#EAF5F3',
    },
    '&:nth-child(even)': {
      background: '#fff',
    },
    '&:hover': {
      border: '2px solid #999AF6',
    },
  },
}));

export default useTableStyles;
