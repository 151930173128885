import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid, Paper, Typography, Divider, Tooltip, Badge } from '@material-ui/core';

import type { Organization } from '../../../models';
import { purple } from '../../../theme';
import { getOrganizationDetailsPagePath } from '../../../utils/page';
import { toTitleCase } from '../../../utils/string';
import TruncatedTypography from '../../Common/TruncatedTypography';
import gradeA from '../../../resources/images/account-grade-A.png';
import gradeB from '../../../resources/images/account-grade-B.png';
import gradeC from '../../../resources/images/account-grade-C.png';
import TooltipTitleSpan from '../../Common/TooltipTitleSpan';

interface IProps {
  clickable?: boolean;
  organization: Organization;
}

interface StylesProps {
  gradeColor?: string;
  borderColor?: string;
}

type IncrementalReasons = string[];

const useStyles = makeStyles(theme => ({
  rootNational: {
    display: 'flex',
    flexDirection: 'column',
    border: (props: StylesProps) => `1px solid ${props.borderColor || props.gradeColor}`,
    borderLeft: (props: StylesProps) => `6px solid ${props.borderColor || props.gradeColor}`,
    textDecoration: 'none',
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  nationalAccount: {
    padding: theme.spacing(1, 1.5),
    marginTop: '0.5rem',
    background:
      'linear-gradient(90.31deg, rgba(218, 165, 32, 0.5) 33.52%, rgba(218, 165, 32, 0.2) 101.71%)',
    color: 'primary',
  },
  root: {
    display: 'flex',
    height: '5rem',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5),
    border: (props: StylesProps) => `1px solid ${props.gradeColor}`,
    borderLeft: (props: StylesProps) => `6px solid ${props.gradeColor}`,
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  main: {
    display: 'flex',
    height: '5rem',
    padding: theme.spacing(1, 1.5),
  },
  container: {
    flexWrap: 'nowrap',
  },
  avatar: {
    backgroundColor: purple,
  },
  dense: {
    lineHeight: '1.1rem',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1em',
    margin: '1.2em 0',
    width: '49%',
    minWidth: '50px',
  },
  icon: {
    height: 30,
    padding: '0 0.5em',
  },
  borderClass: {
    justifyContent: 'space-between',
    paddingLeft: '2.5em',
  },
  accountCardDivider: {
    backgroundColor: 'lightgray',
    height: '60%',
    width: 1,
    alignSelf: 'center',
  },
  badge: {
    '& .MuiBadge-badge': {
      height: '15px',
      padding: '0 4px',
      borderRadius: '10px',
      fontSize: '.60rem',
      minWidth: '15px',
      color: '#F4F9FC',
      backgroundColor: '#EF5DA8',
      top: '0%',
      right: '9%',
    },
  },
}));

enum GradeColor {
  gradeA = '#BCBDC0',
  gradeB = '#1FB4B1',
  gradeC = '#4E9AF3',
  noGrade = '#999AF6',
  nationalAccount = '#DAA520',
}

interface IOrgIncrementalIconProps {
  fill?: string;
  incrementalReasons: IncrementalReasons | undefined;
}
export const OrgIncrementalIcon: React.FC<IOrgIncrementalIconProps> = ({
  fill = '#999AF6',
  incrementalReasons = [] as IncrementalReasons,
}: IOrgIncrementalIconProps) => {
  const classes = useStyles({ gradeColor: fill } as StylesProps);

  return (
    <Tooltip
      title={<TooltipTitleSpan titles={incrementalReasons} />}
      placement="bottom"
      arrow
      disableHoverListener={!incrementalReasons.length}
    >
      <Badge
        badgeContent={incrementalReasons.length || ''}
        color="error"
        overlap="circle"
        className={classes.badge}
        invisible={!incrementalReasons.length}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 112 93"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          className={classes.icon}
        >
          <path
            d="M65.2686 67.4285H72.7996C74.0548 67.4285 75.31 66.163 75.31 64.8975V38.3225C75.31 37.0571 74.0548 35.7916 72.7996 35.7916H65.2686C64.0135 35.7916 62.7583 37.0571 62.7583 38.3225V64.8975C62.7583 66.163 64.0135 67.4285 65.2686 67.4285ZM84.0961 67.4285H91.6271C92.8823 67.4285 94.1375 66.163 94.1375 64.8975V19.3404C94.1375 18.0749 92.8823 16.8094 91.6271 16.8094H84.0961C82.841 16.8094 81.5858 18.0749 81.5858 19.3404V64.8975C81.5858 66.163 82.841 67.4285 84.0961 67.4285ZM27.6137 67.4285H35.1447C36.3998 67.4285 37.655 66.163 37.655 64.8975V50.9773C37.655 49.7118 36.3998 48.4463 35.1447 48.4463H27.6137C26.3585 48.4463 25.1033 49.7118 25.1033 50.9773V64.8975C25.1033 66.163 26.3585 67.4285 27.6137 67.4285ZM46.4411 67.4285H53.9721C55.2273 67.4285 56.4825 66.163 56.4825 64.8975V25.6678C56.4825 24.4023 55.2273 23.1368 53.9721 23.1368H46.4411C45.186 23.1368 43.9308 24.4023 43.9308 25.6678V64.8975C43.9308 66.163 45.186 67.4285 46.4411 67.4285ZM97.2754 80.0832H12.5517V19.9731C12.5517 18.2252 11.1474 16.8094 9.41375 16.8094H3.13792C1.40422 16.8094 0 18.2252 0 19.9731V86.4106C0 89.9045 2.8104 92.738 6.27583 92.738H97.2754C99.0091 92.738 100.413 91.3222 100.413 89.5743V83.2469C100.413 81.499 99.0091 80.0832 97.2754 80.0832Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80 0C77.2386 0 75 2.23858 75 5C75 7.76142 77.2386 10 80 10H98.0955L64.1472 60.1182L45.4534 32.5202C44.6161 31.2842 43.2205 30.5439 41.7276 30.5439C40.2348 30.5439 38.8391 31.2842 38.0019 32.5202L4.3725 82.1676C2.97871 84.2253 3.51689 87.0233 5.57455 88.4171C7.63221 89.8108 10.4302 89.2727 11.824 87.215L41.7276 43.0679L60.4215 70.6659C61.2587 71.9019 62.6544 72.6422 64.1472 72.6422C65.6401 72.6422 67.0357 71.9019 67.873 70.6659L102 20.2838V33C102 35.7614 104.239 38 107 38C109.761 38 112 35.7614 112 33V5C112 2.23858 109.761 0 107 0H80Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="85.5"
              y1="1.5"
              x2="57.4214"
              y2="86.1915"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.354167" stopColor="#0C4377" />
              <stop offset="1" stopColor={fill} />
            </linearGradient>
          </defs>
        </svg>
      </Badge>
    </Tooltip>
  );
};

const OrganizationCard: React.FC<IProps> = ({ organization, clickable = true }) => {
  const {
    name,
    phoneNumber,
    addressWithoutZipCode,
    totalLeads,
    totalStakeholders,
    id,
    sfRating,
    incrementalFlag,
    sfType,
  } = organization;
  let gradeIcon: string;
  let gradeAlt: string;
  let gradeColor: GradeColor;
  switch (sfRating) {
    case 'A':
      gradeIcon = gradeA;
      gradeAlt = 'A';
      gradeColor = GradeColor.gradeA;
      break;
    case 'B':
      gradeIcon = gradeB;
      gradeAlt = 'B';
      gradeColor = GradeColor.gradeB;
      break;
    case 'C':
      gradeIcon = gradeC;
      gradeAlt = 'C';
      gradeColor = GradeColor.gradeC;
      break;
    default:
      gradeAlt = 'No Grade';
      gradeColor = GradeColor.noGrade;
      break;
  }
  const isNationalAccount = sfType === 'CSA Account';
  let borderColor: string;
  if (isNationalAccount) {
    borderColor = GradeColor.nationalAccount;
  } else {
    borderColor = gradeColor;
  }

  const classes = useStyles({ gradeColor, borderColor } as StylesProps);
  const orgGradeIcon = () => {
    return <img src={gradeIcon} alt={gradeAlt} className={classes.icon} />;
  };

  return (
    <Paper
      className={isNationalAccount ? classes.rootNational : classes.root}
      component={
        clickable ? props => <Link to={getOrganizationDetailsPagePath(id)} {...props} /> : 'div'
      }
      elevation={0}
    >
      {isNationalAccount && (
        <Paper elevation={0}>
          <Grid className={classes.nationalAccount}>
            <Typography color="primary">National Account</Typography>
          </Grid>
        </Paper>
      )}
      <Grid className={classes.main}>
        <Grid container spacing={0} alignItems="center" className={classes.container}>
          <Grid item>
            <TruncatedTypography
              TypographyProps={{
                className: classes.dense,
                color: 'primary',
                variant: 'subtitle1',
              }}
              maxLines={1}
              TooltipProps={{ title: toTitleCase(name) }}
            >
              {toTitleCase(name)}
            </TruncatedTypography>
            <TruncatedTypography
              TypographyProps={{
                className: classes.dense,
                color: 'primary',
                variant: 'body2',
              }}
              maxLines={1}
              TooltipProps={{ title: phoneNumber || addressWithoutZipCode }}
            >
              {phoneNumber || addressWithoutZipCode}
            </TruncatedTypography>
            <div>
              <Typography
                className={classes.dense}
                color="primary"
                variant="body2"
                component="span"
              >
                {totalLeads} {totalLeads === 1 ? 'Lead' : 'Leads'}
              </Typography>
              {' | '}
              <Typography
                className={classes.dense}
                color="primary"
                variant="body2"
                component="span"
              >
                {totalStakeholders} {totalStakeholders === 1 ? 'Contact' : 'Contacts'}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {(incrementalFlag || sfRating) && (
          <Grid container className={classes.borderClass}>
            <Divider orientation="vertical" className={classes.accountCardDivider} />
            <Grid item className={classes.iconContainer}>
              {incrementalFlag && (
                <OrgIncrementalIcon
                  fill={gradeColor}
                  incrementalReasons={organization.incrementalReason}
                />
              )}
            </Grid>
            <Divider orientation="vertical" className={classes.accountCardDivider} />
            <Grid item className={classes.iconContainer}>
              {sfRating && orgGradeIcon()}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default OrganizationCard;
