import React from 'react';
import { Box, Typography, styled } from '@material-ui/core';

import { SectionHeader } from '../../Common/StyleGuideStyles';

const MyTypography = styled(Typography)({
  padding: '10px 0px',
});

/**
 * This files servers for representing heading and paragraph standard for the Grid system
 */
export default function ParagraphStyle(): JSX.Element {
  return (
    <Box>
      <SectionHeader>Heading and Paragraph</SectionHeader>
      <MyTypography variant="h1" gutterBottom>
        h1. Heading
      </MyTypography>
      <MyTypography variant="h2" gutterBottom>
        h2. Heading
      </MyTypography>
      <MyTypography variant="h3" gutterBottom>
        h3. Heading
      </MyTypography>
      <MyTypography variant="h4" gutterBottom>
        h4. Heading
      </MyTypography>
      <MyTypography variant="h5" gutterBottom>
        h5. Heading
      </MyTypography>
      <MyTypography variant="h6" gutterBottom>
        h6. Heading
      </MyTypography>
      <MyTypography variant="subtitle1" gutterBottom>
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </MyTypography>
      <MyTypography variant="subtitle2" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </MyTypography>
      <MyTypography variant="body1" gutterBottom>
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </MyTypography>
      <MyTypography variant="body2" gutterBottom>
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </MyTypography>
      <MyTypography variant="button" display="block" gutterBottom>
        button text
      </MyTypography>
      <MyTypography variant="caption" display="block" gutterBottom>
        caption text
      </MyTypography>
      <MyTypography variant="overline" display="block" gutterBottom>
        overline text
      </MyTypography>
    </Box>
  );
}
