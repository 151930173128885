import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

import { black, white } from '../../theme';

const HTMLTooltip = withStyles(() => ({
  arrow: {
    color: white,
  },
  tooltip: {
    backgroundColor: white,
    color: black,
    margin: 0,
    padding: '.5rem 0',
    boxSizing: 'border-box',
    maxWidth: 'none',
  },
}))(Tooltip);

export default HTMLTooltip;
