import React from 'react';
import { makeStyles } from '@material-ui/core';

import EmptyBox from '../EmptyBox';
import Loader from '../Loader';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'scroll',
    height: '320px',
    alignContent: 'flex-start',
  },
}));

const LoadingPane: React.FC = () => {
  const classes = useStyles();
  return (
    <EmptyBox className={classes.root}>
      <Loader />
    </EmptyBox>
  );
};

export default LoadingPane;
