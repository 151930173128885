import * as Yup from 'yup';

import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';
import type { IContactDetails } from './common';
import { ContactDetailType } from './common';

import { getFullAddress } from '../utils/address';
import { toTitleCase } from '../utils/string';

export type IBaseContactResponse = IBaseModelResponse & {
  name?: string;
  description?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  contact?: IContactDetails;
};

export type IBaseContactRequest = Pick<
  IBaseContactResponse,
  | 'description'
  | 'contact'
  | 'name'
  | 'description'
  | 'address_1'
  | 'address_2'
  | 'city'
  | 'state'
  | 'zipcode'
> & {
  address1?: string;
  address2?: string;
};

export const BaseContactRequestSchema = {
  name: Yup.string(),
  description: Yup.string().nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zipcode: Yup.string().nullable(),
  contact: Yup.object()
    .shape({
      [ContactDetailType.Phone]: Yup.array()
        .of(Yup.string().matches(/^.{10,}$/, 'Please enter a valid phone number.'))
        .nullable(),
      [ContactDetailType.Email]: Yup.array()
        .of(Yup.string().email('Please enter a valid email address.'))
        .nullable(),
      [ContactDetailType.Website]: Yup.array()
        .of(Yup.string().url('Please enter a valid URL in the format https://example.cleodv.xyz'))
        .nullable(),
    })
    .nullable(),
};

export default class BaseContact extends BaseModel {
  name: string;
  description?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  contactDetails?: IContactDetails;

  constructor(data: IBaseContactResponse) {
    super(data);

    const { name, description, address_1, address_2, city, state, zipcode, contact } = data;
    this.name = toTitleCase(name) as string;
    this.description = description;
    this.address1 = address_1;
    this.address2 = address_2;
    this.city = city;
    this.state = state;
    this.zipcode = zipcode;
    this.contactDetails = contact;
  }

  get fullAddress(): string {
    return getFullAddress({
      address1: this.address1 || '',
      address2: this.address2 || '',
      city: this.city || '',
      state: this.state || '',
      zipcode: this.zipcode || '',
    });
  }

  get addressWithoutZipCode(): string {
    return [
      this.address2
        ? `${toTitleCase(this.address1)} ${toTitleCase(this.address2)}`
        : toTitleCase(this.address1),
      toTitleCase(this.city),
      this.state?.toUpperCase(),
    ]
      .filter(Boolean)
      .join(', ');
  }

  get phoneNumber(): string {
    return this.phoneNumbers[0] || '';
  }

  get phoneNumbers(): string[] {
    return this.contactDetails?.phone?.map(phone => `+1 ${phone}`) || [];
  }

  get emailAddresses(): string[] {
    return this.contactDetails?.email || [];
  }

  get websites(): string[] {
    return this.contactDetails?.website || [];
  }
}
