import { makeStyles } from '@material-ui/core';

import { secondary } from '../../../../theme';

export const useReactTableRowStyles = makeStyles({
  root: {
    border: '2px solid transparent',
    justifyContent: 'space-evenly',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #999AF6',
    },
    '&:nth-child(odd)': {
      background: '#EAF5F3',
    },
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
  },
  newRibbon: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '.25rem',
    left: '-2.5rem',
    height: '.5rem',
    width: '8rem',
    transform: 'rotate(-36.61deg)',
    backgroundColor: '#172D50',
    color: secondary,
    zIndex: 10,
    padding: '.7rem',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  viewed: {
    top: '.75rem',
    left: '-2rem',
  },
});
