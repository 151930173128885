import { atom } from 'recoil';

import { User } from '../../models';
import { FilterOptionsItem, Optional } from '../../types';

export const usersState = atom({
  key: 'users',
  default: (undefined as unknown) as Optional<User[]>,
});

export const currentUserState = atom({
  key: 'currentUser',
  default: (undefined as unknown) as Optional<User>,
});

export const userOptionsState = atom({
  key: 'userOptions',
  default: [] as FilterOptionsItem[],
});
