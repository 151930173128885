import {
  OwnersSelection,
  ProjectAndIProject,
  renderProjectOwnerSelection,
} from './PLDetailsTabHelperFunctions';

import {
  renderDate,
  renderFunctions,
  renderItem,
  renderItem2,
  renderNumber2,
  renderPropertyPL,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { black, lightGreen, white } from '../../../../theme';
import { oneColDetail, twoColDetail } from '../detailsList';
import { Variant } from '../../../../types/typography';
import { DATA_PLACEHOLDER } from '../../../../constants';

export type additionalParam = string | number | boolean | undefined;

export interface Render<T> {
  function: renderFunctions;
  paramKeys: (keyof T)[];
  additionalParam?: additionalParam[];
  columns: 'one' | 'two';
  linkKey?: keyof T;
  tooltip?: boolean;
  ownersList?: boolean;
}

export interface RowConfig<T> {
  id: keyof T;
  label: string;
  render: Render<T>;
}

export const variant: Variant = 'body1';
export const variantTitle: Variant = 'subtitle1';

export const config: RowConfig<Partial<ProjectAndIProject>>[] = [
  {
    id: 'externalId',
    label: 'External Id',
    render: {
      function: renderItem2,
      paramKeys: ['externalId'],
      additionalParam: [false, 'subtitle1'],
      columns: 'two',
    },
  },
  {
    id: 'dodgeVersionNumber',
    label: 'Dodge Version Number',
    render: {
      function: renderItem2,
      paramKeys: ['dodgeVersionNumber'],
      additionalParam: [false, 'subtitle1'],
      columns: 'two',
    },
  },
  {
    id: 'address1',
    label: 'Address',
    render: {
      function: renderPropertyPL,
      paramKeys: ['address1', 'city', 'state'],
      additionalParam: [variant, true],
      columns: 'two',
      linkKey: 'address1',
    },
  },
  {
    id: 'actionStage',
    label: 'Stage',
    render: {
      function: renderItem,
      paramKeys: ['actionStage'],
      additionalParam: [false, 'subtitle1'],
      columns: 'two',
    },
  },
  {
    id: 'created_at',
    label: 'Created',
    render: {
      function: renderDate,
      paramKeys: ['created_at'],
      additionalParam: [variant, black],
      columns: 'two',
    },
  },
  {
    id: 'ownerId',
    label: 'Project Lead Owner',
    render: {
      function: renderProjectOwnerSelection,
      paramKeys: ['ownerId'],
      columns: 'two',
      ownersList: true,
    },
  },
  {
    id: 'dataSource',
    label: 'Lead Source',
    render: {
      function: renderItem,
      paramKeys: ['dataSource'],
      additionalParam: [true, variant, true],
      columns: 'two',
      linkKey: 'dataSource',
    },
  },
  {
    id: 'value1',
    label: 'Value',
    render: {
      function: renderNumber2,
      paramKeys: ['value1'],
      additionalParam: [true, variant],
      columns: 'two',
    },
  },
  {
    id: 'primaryProjectType',
    label: 'Type',
    render: {
      function: renderItem,
      paramKeys: ['primaryProjectType'],
      additionalParam: [false, variant],
      columns: 'two',
    },
  },
  {
    id: 'categories',
    label: 'Market Type',
    render: {
      function: renderItem,
      paramKeys: ['categories'],
      additionalParam: [true, variant],
      columns: 'two',
    },
  },
  {
    id: 'size',
    label: 'Total SQFT',
    render: {
      function: renderNumber2,
      paramKeys: ['size'],
      additionalParam: [false, variant, 'sqft'],
      columns: 'two',
    },
  },
  {
    id: 'sfdc_project_lead_id',
    label: 'Salesforce Link',
    render: {
      function: renderItem,
      paramKeys: ['sfdc_project_lead_id'],
      additionalParam: [false, variant, true, 'Not in Salesforce'],
      columns: 'two',
      linkKey: 'sfdc_project_lead_id',
    },
  },
  {
    id: 'specAlerts',
    label: 'Spec Alerts',
    render: {
      function: renderItem,
      paramKeys: ['specAlerts'],
      additionalParam: [false, variant],
      columns: 'one',
      tooltip: true,
    },
  },
];

interface renderListProps<T> {
  config: RowConfig<T>[];
  data: Partial<T>;
  links?: Partial<Record<keyof T, string>>;
  tooltips?: Partial<Record<keyof T, JSX.Element>>;
  ownersSelection?: OwnersSelection;
  source?: string;
}

// TODO: Move this function to render functions page

export function renderList<T>({
  config,
  data,
  links,
  ownersSelection,
  tooltips,
  source,
}: renderListProps<T>): JSX.Element[] {
  let hasNoDodgeVersion = false;
  const list = config.map((item, idx) => {
    const linkKey = item.render.linkKey;
    let toLink;
    let tooltip;
    const backgroundColor = !((hasNoDodgeVersion ? idx - 1 : idx) % 2) ? lightGreen : white;

    // Handle Links
    if (linkKey && links) {
      toLink = links[linkKey];
    }

    // Handle Param values
    const paramValues: (
      | T[keyof T]
      | additionalParam
      | OwnersSelection
    )[] = item.render.paramKeys.map(key => data[key]);

    // Handle additional params
    if (item.render.additionalParam) {
      item.render.additionalParam.forEach(param => {
        paramValues.push(param);
      });
    }

    // Handle owners list
    if (item.render.ownersList) {
      paramValues.push({
        ownersList: ownersSelection?.ownersList,
        handleSelection: ownersSelection?.handleSelection,
      } as OwnersSelection);
    }

    // Handle tooltip
    if (item.render.tooltip && tooltips?.[item.id]) {
      tooltip = tooltips[item.id];
    }

    // Handle label for externalId
    if (item.id === 'externalId' && source) {
      item.label = source + ' ID';
    }

    // TODO Fix Typing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const renderedValue = item.render.function(...paramValues);

    if (item.render.columns === 'two') {
      if (item.id === 'dodgeVersionNumber' && !renderedValue) {
        hasNoDodgeVersion = true;
        return undefined;
      }
      return twoColDetail({
        value: renderedValue || DATA_PLACEHOLDER,
        key: item.label,
        idx: hasNoDodgeVersion ? idx - 1 : idx,
        backgroundColor,
        toLink,
        variantTitle: variantTitle,
      });
    } else {
      return oneColDetail({
        value: renderedValue || DATA_PLACEHOLDER,
        key: item.label,
        idx: hasNoDodgeVersion ? idx - 1 : idx,
        backgroundColor,
        toLink,
        tooltip,
        variantTitle: variantTitle,
      });
    }
  });
  const cleanList = list.filter(Boolean);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return cleanList;
}
