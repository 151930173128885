import React from 'react';
import { Box, Grid, GridProps, Typography } from '@material-ui/core';
import clsx from 'clsx';

import DropDownOptions from './DropDownOptions';
import useDataDropDownStyles from './DataDropDown.style';
import { config } from './DropDownOptionsConfig';

import {
  MapDisplayLevel,
  MapObjectSourceState,
  MapObjectType,
} from '../../../../state/atoms/interactiveMap';

type IProps = GridProps & {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  setRadioValue: React.Dispatch<React.SetStateAction<MapObjectType>>;
  radioValue: MapObjectType;
  setCheckboxState: React.Dispatch<React.SetStateAction<MapObjectSourceState>>;
  checkboxState: MapObjectSourceState;
  setLoadNewData: React.Dispatch<React.SetStateAction<boolean>>;
  mapDisplayLevel: MapDisplayLevel;
};

const DataDropDown: React.FC<IProps> = ({
  className,
  setOpenDrawer,
  openDrawer,
  setRadioValue,
  radioValue,
  setCheckboxState,
  checkboxState,
  setLoadNewData,
  mapDisplayLevel,
}) => {
  const classes = useDataDropDownStyles();
  return (
    <Box className={className}>
      <Grid>
        <Typography variant={'subtitle1'} className={classes.title}>
          Choose Data Points to View
        </Typography>
      </Grid>
      <Grid container className={classes.container2}>
        <DropDownOptions
          config={config}
          setRadioValue={setRadioValue}
          radioValue={radioValue}
          setCheckboxState={setCheckboxState}
          checkboxState={checkboxState}
          setLoadNewData={setLoadNewData}
        />
      </Grid>
      <Grid container className={classes.container1}>
        <Grid item>
          <Typography variant={'caption'}>
            <button
              disabled={mapDisplayLevel !== MapDisplayLevel.County}
              className={clsx(classes.button, {
                [classes.buttonShow]: !openDrawer,
                [classes.buttonHide]: openDrawer,
              })}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              {openDrawer ? 'HIDE LIST' : 'SHOW LIST'}
            </button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataDropDown;
