import React from 'react';
import { Grid, GridProps, IconButton, InputBase, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { ReactComponent as Arrow } from '../../../../resources/images/vector.svg';
import { error, primary } from '../../../../theme';
import { Comparators, FilterData } from '../../../../types';
import {
  specRateFilterKey,
  specRateOptions,
  specRateSelectedKeysState,
} from '../../../../state/atoms/filterSpecRates';

const useStyles = makeStyles(() => ({
  root: {
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: 4,
  },
  toggleContainer: {
    flex: 5,
  },
  down: {
    transform: 'rotate(180deg)',
  },
  arrow: {
    flex: 1,
    padding: '.25rem',
  },
  input: {
    flex: 3,
    padding: '0 .25rem',
    color: 'black',
    backgroundColor: 'transparent',
    fontWeight: 'normal',
  },
  clear: {
    marginTop: '.5rem',
    color: 'white',
    alignSelf: 'center',
    width: '50%',
    borderColor: 'white',
  },
}));

enum ToggleType {
  Up = 'up',
  Down = 'down',
}

interface IToggleSpecRateItemProps {
  id: specRateFilterKey;
  specRateSelectedKeys: FilterData;
  setSpecRateSelectedKeys: SetterOrUpdater<FilterData>;
}

const ToggleSpecRateItem = ({
  id,
  className,
  specRateSelectedKeys,
  setSpecRateSelectedKeys,
}: GridProps & IToggleSpecRateItemProps) => {
  const classes = useStyles();
  const [[comparator, stateValue]] = Object.entries(specRateSelectedKeys[id]);
  const toggleGreater = ((comparator as unknown) as Comparators) === Comparators.Gte;
  const toggleLess = ((comparator as unknown) as Comparators) === Comparators.Lte;

  const handleChange = (val: string) => {
    if (val === '') {
      setSpecRateSelectedKeys({ ...specRateSelectedKeys, [id]: { [comparator]: '' } });
      return;
    }
    let value = Number(val);
    if (isNaN(value)) {
      return;
    }
    const copyState = { ...specRateSelectedKeys };
    value = Math.min(100, value);
    value = Math.max(0, value);
    copyState[id] = { [(comparator as unknown) as string]: value };
    setSpecRateSelectedKeys(copyState);
  };

  const handleToggle = (type: ToggleType) => {
    const copyState = { ...specRateSelectedKeys };
    if (type === ToggleType.Up) {
      copyState[id] = { [(Comparators.Gte as unknown) as string]: stateValue };
      setSpecRateSelectedKeys(copyState);
    } else {
      copyState[id] = { [(Comparators.Lte as unknown) as string]: stateValue };
      setSpecRateSelectedKeys(copyState);
    }
  };

  return (
    <Grid className={clsx(classes.option, className)}>
      <IconButton
        aria-label={`${id}-greater-than-button`}
        onClick={() => {
          handleToggle(ToggleType.Up);
        }}
        className={classes.arrow}
      >
        <Arrow fill={toggleGreater ? primary : 'white'} opacity={toggleGreater ? '1' : '.5'} />
      </IconButton>
      <IconButton
        aria-label={`${id}-less-than-button`}
        onClick={() => handleToggle(ToggleType.Down)}
        className={clsx(classes.arrow, classes.down)}
      >
        <Arrow fill={toggleLess ? error : 'white'} opacity={toggleLess ? '1' : '.5'} />
      </IconButton>
      <Grid
        className={classes.option}
        style={{
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '4px',
          margin: '.25rem',
          padding: '0 .25rem',
        }}
      >
        <InputBase
          className={classes.input}
          inputProps={{
            style: { textAlign: 'right' },
          }}
          value={stateValue}
          aria-label={`${id}-input`}
          onChange={e => handleChange(e.target.value)}
          placeholder={'0'}
        />
        %
      </Grid>
    </Grid>
  );
};

const SpecRateFilter: React.FC = () => {
  const classes = useStyles();

  const [specRateSelectedKeys, setSpecRateSelectedKeys] = useRecoilState(specRateSelectedKeysState);
  return (
    <div className={classes.root}>
      {specRateOptions.map((option, idx) => {
        const { label, value, filterKey } = option;
        return (
          <div className={classes.option} key={`${value}-${idx}`}>
            <Typography className={classes.label}>{label}</Typography>
            <ToggleSpecRateItem
              className={classes.toggleContainer}
              id={filterKey}
              specRateSelectedKeys={specRateSelectedKeys}
              setSpecRateSelectedKeys={setSpecRateSelectedKeys}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SpecRateFilter;
