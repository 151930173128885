import React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  height?: any;
  id?: string;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, height, id, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={id ? id : `propertyTabPanel-${index}`}
      style={{ height: height ? height : '19rem' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
