import React from 'react';
import { Grid, GridProps, Typography, Zoom } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { darkBlue } from '../../../theme';
import HTMLTooltip from '../../Common/TooltipHTML';
import { Variant } from '../../../types/typography';

export type detailProps = GridProps & {
  value: string | JSX.Element | JSX.Element[];
  idx: number;
  backgroundColor: string;
  toLink?: string;
  tooltip?: JSX.Element;
  variantTitle?: Variant;
};

export const twoColDetail = ({
  value,
  key,
  idx,
  backgroundColor,
  toLink,
  variantTitle = 'subtitle2',
}: detailProps): JSX.Element => {
  if (toLink) {
    value = (
      <Link
        target="_blank"
        to={{ pathname: toLink }}
        style={{ textDecoration: 'none', color: darkBlue }}
      >
        {value}
      </Link>
    );
  }
  return (
    <Grid
      key={`${key}-${idx}`}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: '.75rem',
        backgroundColor: backgroundColor,
        alignItems: 'center',
      }}
    >
      <Grid
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography style={{ width: '50%' }} variant={variantTitle}>
          {key}:
        </Typography>
        <Grid style={{ width: '50%', paddingRight: '.25rem', lineBreak: 'anywhere' }}>
          {value}{' '}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const oneColDetail = ({
  value,
  key,
  idx,
  backgroundColor,
  toLink,
  tooltip,
  variantTitle = 'subtitle2',
}: detailProps): JSX.Element => {
  if (toLink) {
    value = (
      <Link
        target="_blank"
        to={{ pathname: toLink }}
        style={{ textDecoration: 'none', color: darkBlue }}
      >
        {value}
      </Link>
    );
  }

  if (tooltip) {
    value = (
      <HTMLTooltip
        title={tooltip}
        placement={'top'}
        arrow
        TransitionComponent={Zoom}
        leaveDelay={200}
        interactive
      >
        <Grid>{value}</Grid>
      </HTMLTooltip>
    );
  }
  return (
    <Grid
      key={`${key}-${idx}`}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: '.75rem',
        backgroundColor: backgroundColor,
      }}
    >
      <Grid style={{ flex: 1, fontWeight: 'bold' }}>
        <Typography variant={variantTitle}>{key}:</Typography>
        {value}
      </Grid>
    </Grid>
  );
};
