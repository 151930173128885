import BaseContact, { IBaseContactResponse } from './baseContact';

export type IAccountResponse = IBaseContactResponse & {
  primary_organization_id: string;
  spec_rate_awi?: number;
  spec_rate_usg?: number;
  spec_rate_certainteed?: number;
  spec_rate_rockfon?: number;
  win_rate?: number;
  value_dodge?: number;
  value_cmd?: number;
  value_buildcentral?: number;
  incremental_government?: boolean;
  incremental_education?: boolean;
  incremental_medical?: boolean;
  incremental_new_account?: boolean;
  incremental_target_national?: boolean;
  sf_rating?: string;
};

export default class Account extends BaseContact {
  primary_organization_id!: string;
  spec_rate_awi?: number;
  spec_rate_usg?: number;
  spec_rate_certainteed?: number;
  spec_rate_rockfon?: number;
  win_rate?: number;
  value_dodge?: number;
  value_cmd?: number;
  value_buildcentral?: number;
  incremental_government?: boolean;
  incremental_education?: boolean;
  incremental_medical?: boolean;
  incremental_new_account?: boolean;
  incremental_target_national?: boolean;
  sf_rating?: string;

  constructor(data: IAccountResponse) {
    super(data);

    const {
      primary_organization_id,
      spec_rate_awi,
      spec_rate_usg,
      spec_rate_certainteed,
      spec_rate_rockfon,
      win_rate,
      value_dodge,
      value_cmd,
      value_buildcentral,
      incremental_government,
      incremental_education,
      incremental_medical,
      incremental_new_account,
      incremental_target_national,
      sf_rating,
    } = data;

    this.primary_organization_id = primary_organization_id;
    this.spec_rate_awi = spec_rate_awi;
    this.spec_rate_usg = spec_rate_usg;
    this.spec_rate_certainteed = spec_rate_certainteed;
    this.spec_rate_rockfon = spec_rate_rockfon;
    this.win_rate = win_rate;
    this.value_dodge = value_dodge;
    this.value_cmd = value_cmd;
    this.value_buildcentral = value_buildcentral;
    this.incremental_government = incremental_government;
    this.incremental_education = incremental_education;
    this.incremental_medical = incremental_medical;
    this.incremental_new_account = incremental_new_account;
    this.incremental_target_national = incremental_target_national;
    this.sf_rating = sf_rating;
  }
}
