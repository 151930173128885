import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Grid, Typography } from '@material-ui/core';
import { ColumnInstance, IdType, TableInstance } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import clsx from 'clsx';

import { useCustomizeTablesPopoverStyles } from './CustomizeTablesPopover.styles';

import { ReactComponent as CloseIcon } from '../../../../resources/images/close-icon.svg';
import { bulkDemoteLeadsButtonPressedState } from '../../../../state/atoms/projectLeads';
import { saveTableSetting } from '../../../../api/tableSettings';
import { useMessages } from '../../../../state/contexts';
import { userTableSettingsState } from '../../../../state/atoms/tableSettings';
import { defaultHiddenColumnIdsPL } from '../../../Pages/ProjectLeads/ProjectLeadsTable/ProjectLeadsTableColumns';

interface CustomizeTablesPopoverProps<TableType extends Record<any, any>> {
  columns: ColumnInstance<TableType>[];
  handleCloseCustomizeColumns: () => void;
  tableInstance: TableInstance<TableType>;
  setUpdateCol: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomizeTablesPopover = <TableType extends Record<any, any>>({
  columns,
  handleCloseCustomizeColumns,
  tableInstance,
  setUpdateCol,
}: CustomizeTablesPopoverProps<TableType>): JSX.Element => {
  const classes = useCustomizeTablesPopoverStyles();
  const bulkDemoteButtonPressed = useRecoilValue(bulkDemoteLeadsButtonPressedState);
  const hideableColumns = columns.filter(column => column.id !== 'customizeColumnsGearIcon');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { resetResizing, state, toggleHideColumn } = tableInstance;
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [columnCount, setColumnCount] = useState<number>(
    hideableColumns.filter(column => column.isVisible).length,
  );
  const setTableSettings = useSetRecoilState(userTableSettingsState);
  const maxColCount = 8;
  const handleCheckedColumnTag = useCallback(
    (e, id: IdType<TableType>, isVisible: boolean) => {
      if (e.target.checked) {
        setColumnCount(prev => prev + 1);
      } else {
        setColumnCount(prev => prev - 1);
      }
      toggleHideColumn(id, isVisible);
      setUpdateCol(true);
    },
    [toggleHideColumn, setUpdateCol],
  );

  const handleClearColumns = useCallback(() => {
    hideableColumns
      .filter(column => column.isVisible)
      .forEach(col => {
        if (col.id !== 'projectScore' && col.id !== 'projectName') {
          toggleHideColumn(col.id, col.isVisible);
        }
      });
    setColumnCount(2);
    setUpdateCol(true);
  }, [hideableColumns, toggleHideColumn, setUpdateCol]);

  const handleSaveTableSettings = useCallback(async () => {
    try {
      const allSavedSettings = await saveTableSetting({
        table_reference: 'project-leads-home',
        saved_settings: state,
      });
      setTableSettings(allSavedSettings);
      handleCloseCustomizeColumns();
      setSuccessMessage('Table Settings Saved.');
    } catch (err) {
      setErrorMessage('Error Saving Settings: ', err);
    }
  }, [state, setSuccessMessage, setErrorMessage, setTableSettings, handleCloseCustomizeColumns]);

  const handleResetToDefault = () => {
    const defaultHiddenCols = defaultHiddenColumnIdsPL;
    hideableColumns.forEach(column => {
      defaultHiddenCols.includes(column.id)
        ? toggleHideColumn(column.id, true)
        : toggleHideColumn(column.id, false);
    });
    setColumnCount(7);
    resetResizing();
    setUpdateCol(true);
  };

  useEffect(() => {
    if (bulkDemoteButtonPressed) {
      toggleHideColumn('_selector', false);
    } else {
      toggleHideColumn('_selector', true);
    }
    setUpdateCol(true);
  }, [bulkDemoteButtonPressed, toggleHideColumn, setUpdateCol]);

  return (
    <div>
      <Grid container className={classes.titleContainer}>
        <Typography className={classes.customizeTitle}>Customize Columns</Typography>
        <Button onClick={handleCloseCustomizeColumns} className={classes.closeButton}>
          <CloseIcon />
        </Button>
      </Grid>
      {columnCount < maxColCount && (
        <Grid className={classes.customizeMessage}>
          (Choose up to
          {
            <strong>
              {' '}
              {['ZERO', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX'][maxColCount - columnCount]}
            </strong>
          }{' '}
          more)
        </Grid>
      )}
      <Divider className={classes.divider} />
      <Grid container className={classes.columnContainer}>
        {hideableColumns.map(({ Header, id, isVisible }, index: number) => {
          switch (Header) {
            case 'Score':
            case 'Project Name':
              return (
                <Grid item className={classes.columnTagDisabled} key={`${Header}-${index}`}>
                  <Checkbox className={classes.checkbox} checked />
                  <Typography className={classes.columnNameRequired}>
                    {`${Header} (required)`}
                  </Typography>
                </Grid>
              );
            case 'Id':
              // Never show id in table
              return null;
            default:
              return (
                <Grid
                  item
                  className={clsx(classes.columnTag, isVisible && classes.columnTagSelected)}
                  key={`${Header}-${index}`}
                >
                  <Checkbox
                    className={classes.checkbox}
                    onChange={e => handleCheckedColumnTag(e, id, isVisible)}
                    checked={isVisible}
                    disabled={columnCount === maxColCount && !isVisible}
                  />
                  <Typography>{`${Header}`}</Typography>
                </Grid>
              );
          }
        })}
      </Grid>
      {columnCount === maxColCount && (
        <Grid item>
          <Typography className={classes.categoryLimitError}>Category Limit Reached</Typography>
        </Grid>
      )}

      <Divider className={classes.divider} />
      <Grid container className={classes.customizeButtonsContainer}>
        <Button
          className={clsx(classes.customizeButtons, classes.saveButton)}
          variant={'contained'}
          color={'primary'}
          onClick={handleSaveTableSettings}
          disableRipple={true}
        >
          <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
            Save
          </Typography>
        </Button>
        <Button
          className={clsx(classes.customizeButtons, classes.clearButton)}
          variant={'contained'}
          color={'primary'}
          onClick={handleClearColumns}
          disableRipple={true}
        >
          <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
            Clear
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Typography className={classes.resetText} onClick={handleResetToDefault}>
          Reset to default
        </Typography>
      </Grid>
    </div>
  );
};
