import { atom } from 'recoil';

import { IProject } from '../../Components/Pages/Leads/Archive/Archive.config';

export const projectLeadsState = atom({
  key: 'projectLeads',
  default: [] as IProject[],
});

export const bulkDemoteLeadsButtonPressedState = atom({
  key: 'bulkDemoteLeadsButtonPressed',
  default: false,
});

export const customizeColumnsGearIconClickedState = atom({
  key: 'customizeColumnsGearIconClicked',
  default: false,
});

export const customizeColumnsGearIconAnchorElState = atom({
  key: 'customizeColumnsGearIconAnchorElState',
  default: null,
});
