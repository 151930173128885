import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface PopUpMap {
  title: string;
  locationName: string;
}

export const popUpMap = ({ title, locationName }: PopUpMap): JSX.Element => {
  return (
    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant={'caption'} style={{ font: 'bold' }}>
        {locationName}
      </Typography>
    </Grid>
  );
};
