import omit from 'lodash/omit';
import { SetterOrUpdater } from 'recoil';

import { stateAssociatedWithCountyState } from './LocationFilterHelper';

import {
  Comparators,
  FilterData,
  FilterKey,
  FilterOptionsItem,
  Optional,
  TagsDisplay,
} from '../../../../../types';

interface IHandleDeleteStateCountyStateFilterTagProps {
  allKeysSelected: Optional<FilterData>;
  obj: TagsDisplay;
  setClearFilters: SetterOrUpdater<boolean>;
  setAllKeysSelected: SetterOrUpdater<Optional<FilterData>>;
  handleFilterChange: (filter: FilterData) => void;
  setAllFilterTags: SetterOrUpdater<TagsDisplay[]>;
  setFilterTagsToDisplay: SetterOrUpdater<TagsDisplay[]>;
  countiesInEachState: Record<string, FilterOptionsItem[]>;
}
export const handleDeleteStateCountyStateFilterTag = ({
  allKeysSelected,
  obj,
  setAllKeysSelected,
  handleFilterChange,
  setAllFilterTags,
  setFilterTagsToDisplay,
  countiesInEachState,
}: IHandleDeleteStateCountyStateFilterTagProps) => {
  let selectedKeys: any = { ...allKeysSelected };
  let stateKeyToRemove: string;
  let countyKeysToRemove: string[]; // list of countyState strings to remove from filterOptions

  if (obj.key === FilterKey.State) {
    countyKeysToRemove = countiesInEachState[obj.value].map(item => item.value as string);
    const countiesAlreadySelected = selectedKeys[FilterKey.CountyState][Comparators.In] as string[];
    const countiesLeftAfterFilterTagRemoval = countiesAlreadySelected.filter(
      i => !countyKeysToRemove.includes(i),
    );

    if (countiesLeftAfterFilterTagRemoval.length) {
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.CountyState]: {
          [Comparators.In]: countiesLeftAfterFilterTagRemoval,
        },
      };
    } else {
      //  Remove Comparator from countyState since nothing is left
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.CountyState]: {},
      };
    }

    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => !countyKeysToRemove.includes(filterTag.filterValue)),
    );
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => !countyKeysToRemove.includes(filterTag.filterValue)),
    );
  }

  if (obj.key === FilterKey.CountyState) {
    stateKeyToRemove = stateAssociatedWithCountyState({ countyStateValue: obj.value as string });
    const stateAlreadySelected = selectedKeys?.state?.in as string[] | undefined;

    const statesLeftAfterFilterTagRemoval = stateAlreadySelected?.filter(
      i => i !== stateKeyToRemove,
    );

    if (statesLeftAfterFilterTagRemoval?.length) {
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.State]: {
          [Comparators.In]: statesLeftAfterFilterTagRemoval,
        },
      };
    } else {
      //  Remove Comparator from countyState since nothing is left
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.State]: {},
      };
    }

    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => filterTag.filterValue !== stateKeyToRemove),
    );
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.filterValue !== stateKeyToRemove),
    );
  }

  const values = selectedKeys[obj.key][obj.comparator];
  if (!Array.isArray(values)) {
    selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);
  }
  const removeList = Array.isArray(values)
    ? values.filter((i: string) => i !== obj.filterValue)
    : values;

  if (Array.isArray(values) && removeList.length > 0) {
    selectedKeys = {
      ...selectedKeys,
      [obj.key]: {
        ...selectedKeys[obj.key],
        [obj.comparator]: removeList,
      },
    };

    setAllKeysSelected(selectedKeys);
    handleFilterChange(selectedKeys);
    setAllFilterTags((prev: any) => prev.filter((filterTag: any) => filterTag.value !== obj.value));
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== obj.value),
    );
  } else {
    let allKeys = { ...selectedKeys };
    allKeys = {
      ...allKeys,
      [obj.key]: omit(allKeys[obj.key], obj.comparator),
    };
    setAllKeysSelected(allKeys);
    handleFilterChange(allKeys);
    setAllFilterTags((prev: any) => prev.filter((filterTag: any) => filterTag.value !== obj.value));
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== obj.value),
    );
  }
};
