import { Grid, Hidden, TableSortLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { MouseEvent } from 'react';

import { HeadCell } from './Table';
import useTableHeaderStyles from './TableHeader.styles';

import { SortOrder } from '../../Pages/Leads/Archive/Archive.config';

interface IProps<T, K> {
  headerCells: HeadCell<T, K>[];
  order?: SortOrder;
  orderBy?: string;
  onRequestSort?: (event: MouseEvent<unknown>, property: keyof T) => void;
  isSortable: boolean;
  bulkDemoteButtonClicked?: boolean;
}

function TableHeader<T, K>(props: IProps<T, K>) {
  const { headerCells, order, orderBy, isSortable, onRequestSort, bulkDemoteButtonClicked } = props;
  const classes = useTableHeaderStyles();

  return (
    <Grid
      container
      alignItems="center"
      className={bulkDemoteButtonClicked ? classes.shiftedHeaderContainer : classes.root}
    >
      {headerCells.map(
        ({
          id,
          label,
          textAlign = 'left',
          gridProps,
          renderRow,
          headerStyle,
          disableSort,
        }: HeadCell<T, K>) => {
          let style = renderRow?.style;
          const isActive = orderBy === id;
          const labelClasses = clsx(classes.label, { [classes.labelActive]: isActive });
          // Let style from headerStyle in config file take precedence
          style = headerStyle ? headerStyle : style;

          return (
            <Hidden key={id.toString()} smDown={!gridProps.xs}>
              <Grid
                item
                style={{ textAlign, ...style }}
                {...gridProps}
                className={classes.headCell}
              >
                <TableSortLabel
                  disabled={disableSort}
                  classes={{ icon: labelClasses, active: classes.labelActive }}
                  active={isActive}
                  direction={isActive ? order : 'asc'}
                  onClick={e => onRequestSort?.(e, id)}
                  hideSortIcon={!isSortable}
                >
                  <Typography className={labelClasses} component="span" variant="body2">
                    {label}
                  </Typography>
                  {isActive && (
                    <span className={classes.hiddenSortOrder}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  )}
                </TableSortLabel>
              </Grid>
            </Hidden>
          );
        },
      )}
    </Grid>
  );
}

export default TableHeader;
