import React from 'react';
import type { GridProps } from '@material-ui/core';

import MapBoxPage from './MapboxMap';

type IProps = GridProps;

const InteractiveMap: React.FC<IProps> = ({ className }) => {
  return (
    <div className={className}>
      <MapBoxPage />
    </div>
  );
};

export default InteractiveMap;
