export const EventType: Record<string, string> = {
  'BLDG-NEW': 'New Build',
  'BLDG-ADDITION': 'Addition',
  'BLDG-ALTER/REPAIR': 'Bldg/Repair',
  F044: 'F044',
  'FIRE SPRINKLER': 'Fire Sprinkler',
  ELECTRICAL: 'Electrical',
  HVAC: 'HVAC',
  PLUMBING: 'Plumbing',
};

export enum MessageType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum CleoObjectType {
  ProjectLeads = 'project_leads',
  Addresses = 'addresses',
  Accounts = 'accounts',
}

export enum ProjectSpaceType {
  Office = 'office',
  Retail = 'retail',
  Education = 'education',
  Healthcare = 'healthcare',
  Hospitality = 'hospitality',
  Other = 'other',
}

export enum ProjectStage {
  Preplanning = 'preplanning',
  SchematicDesign = 'schematic_design',
  DesignDevelopment = 'design_development',
  ConstructionDocuments = 'construction_documents',
  ContractAwards = 'contract_awards',
  Construction = 'construction',
}

export enum ProjectStatus {
  Active = 'active',
  Converted = 'converted',
  Demoted = 'demoted',
  Inactive = 'inactive',
}

export enum ProjectStatusAction {
  Activate = 'activate',
  Convert = 'convert',
  Demote = 'demote',
  Deactivate = 'deactivate',
}

export enum ProjectConversionReason {
  Sold = 'sold',
  Opportunity = 'opportunity',
  HighValue = 'highValue',
  Other = 'other',
}

export enum ProjectDemotionReason {
  AlreadyTracked = 'alreadyTracked',
  AlreadyCompleted = 'jobAlreadyCompleted',
  NoOpportunity = 'noOpportunity',
  NoContact = 'noContact',
  Competitor = 'competitor',
  Residential = 'residentalProject',
  Other = 'other',
}

export enum ProjectValue {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum ProjectIncrementalScore {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
}

export enum ProjectType {
  NewBuild = 'Bld',
  Renovation = 'Reno',
}

export enum ProfileType {
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Designer = 'DESIGNER',
  Installer = 'INSTALLER',
  Subcontractor = 'SUBCONTRACTOR',
  Other = 'OTHER',
}

export enum SourceType {
  Permits = 'PERMITS',
  Violations = 'VIOLATIONS',
  Surveys = 'SURVEYS',
  Metadata = 'METADATA',
  Contacts = 'CONTACTS',
  Validations = 'VALIDATION',
  Dodge = 'DODGE',
  Cmd = 'CMD',
}

export enum StakeholderRole {
  Applicant = 'APPLICANT',
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Designer = 'DESIGNER',
  Installer = 'INSTALLER',
  Owner = 'OWNER',
  OwnerBldr = 'OWNER-BLDR',
  Principal = 'PRINCIPAL',
  Subcontractor = 'SUBCONTRACTOR',
  Other = 'OTHER',
}

export enum ContactDetailType {
  Phone = 'phone',
  Email = 'email',
  Website = 'website',
}

export enum DateRanges {
  PastWeek = 'interval=1&iterations=7',
  PastMonth = 'interval=7&iterations=4',
  Past3Months = 'interval=30&iterations=3',
  Past6Months = 'interval=30&iterations=6',
  PastYer = 'interval=30&iterations=12',
}

export type IContactDetails = { [K in ContactDetailType]?: string[] };
