import React from 'react';
import { Column } from 'react-table';

import { IProject } from '../../Leads/Archive/Archive.config';
import {
  renderDate,
  renderEvent,
  renderItem,
  renderNumber2,
  renderProjectScore,
  renderProperty,
  renderIcons,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { ReactComponent as CustomizeColumnsGearIcon } from '../../../../resources/images/customizeColumnsGearWheel.svg';
import { hyperlinkBlue, success } from '../../../../theme';
import { ColumnDraggable } from '../../../Common/Tables/ReactTable/ReactTable';

export const projectLeadsColumns: (Column<IProject> & ColumnDraggable)[] = [
  {
    id: 'id',
    Header: 'Id',
    disableSortBy: true,
    disableResizing: true,
    disableDrag: true,
    accessor: (row: IProject) => row.id,
  },
  {
    id: 'projectScore',
    Header: 'Score',
    disableSortBy: false,
    disableResizing: true,
    accessor: (row: IProject, idx) => {
      const stroke = idx % 2 ? success : hyperlinkBlue;
      return renderProjectScore(row.projectScore, stroke, 30, 60, '20px');
    },
  },
  {
    id: 'projectName',
    Header: 'Project Name',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderItem(row.projectName, false, undefined, true),
  },
  {
    id: 'biddate',
    Header: 'Bid Date',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderDate(row.bidDate),
  },
  {
    id: 'actionStage',
    Header: 'Action Stage',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderItem(row.mappedActionStage, true),
  },
  {
    id: 'value',
    Header: 'Valuation',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderNumber2(row.value, true),
  },
  {
    id: 'latestEventSource',
    Header: 'Latest Event',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderEvent(row.dataSource, row.lastUpdated, row.latestEvent),
  },
  {
    id: 'property',
    Header: 'Location',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderProperty(row.address1, row.city, row.state),
  },
  {
    id: 'primaryProjectType',
    Header: 'Project Type',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderItem(row.primaryProjectType),
  },
  {
    id: 'region',
    Header: 'Region',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderItem(row.region, true),
  },
  {
    id: 'state',
    Header: 'State',
    disableSortBy: false,
    disableResizing: false,
    // isVisible: true,
    accessor: (row: IProject) => renderItem(row.state),
  },
  {
    id: 'county',
    Header: 'County',
    disableSortBy: false,
    disableResizing: false,
    accessor: (row: IProject) => renderItem(row.county, true),
  },
  {
    id: 'customizeColumnsGearIcon',
    disableSortBy: true,
    disableResizing: true,
    disableDrag: true,
    Header: function ReactTableCustomizeColumnsGearIcon() {
      return <CustomizeColumnsGearIcon />;
    },
    accessor: (row: IProject) =>
      renderIcons(row.hasNationalAccount, row.hasNationalTargetAccount, row.specAlerts),
  },
];

export const defaultHiddenColumnIdsPL = [
  'id',
  'primaryProjectType',
  'projectType',
  'region',
  'state',
  'county',
];

export const defaultInitialStatePL = {
  hiddenColumns: defaultHiddenColumnIdsPL,
  pageIndex: 0,
  pageSize: 25,
  sortBy: [
    {
      id: 'projectScore',
      desc: true,
    },
  ],
};
