import omit from 'lodash/omit';
import { SetterOrUpdater } from 'recoil';

import { actionStagesAssociatedWithSubActionStages } from './LocationFilterHelper';

import {
  Comparators,
  FilterData,
  FilterKey,
  FilterOptionsItem,
  Optional,
  TagsDisplay,
} from '../../../../../types';

interface IHandleDeleteActionStageSubActionStageFilterTagProps {
  allKeysSelected: Optional<FilterData>;
  obj: TagsDisplay;
  setClearFilters: SetterOrUpdater<boolean>;
  setAllKeysSelected: SetterOrUpdater<Optional<FilterData>>;
  handleFilterChange: (filter: FilterData) => void;
  setAllFilterTags: SetterOrUpdater<TagsDisplay[]>;
  setFilterTagsToDisplay: SetterOrUpdater<TagsDisplay[]>;
  subActionStagesInEachActionStage: Record<string, FilterOptionsItem[]>;
}
export const handleDeleteActionStageSubActionStageFilterTag = ({
  allKeysSelected,
  obj,
  setAllKeysSelected,
  handleFilterChange,
  setAllFilterTags,
  setFilterTagsToDisplay,
  subActionStagesInEachActionStage,
}: IHandleDeleteActionStageSubActionStageFilterTagProps) => {
  let selectedKeys: any = { ...allKeysSelected };
  let actionStagesKeyToRemove: string;
  let subActionStagesKeysToRemove: string[]; // list of countyState strings to remove from filterOptions

  if (obj.key === FilterKey.ActionStage) {
    subActionStagesKeysToRemove = subActionStagesInEachActionStage[obj.value].map(
      item => item.value as string,
    );
    const subActionStagesAlreadySelected = selectedKeys[FilterKey.CountyState][
      Comparators.In
    ] as string[];
    const subActionStagesLeftAfterFilterTagRemoval = subActionStagesAlreadySelected.filter(
      i => !subActionStagesKeysToRemove.includes(i),
    );

    if (subActionStagesLeftAfterFilterTagRemoval.length) {
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.SubActionStage]: {
          [Comparators.In]: subActionStagesLeftAfterFilterTagRemoval,
        },
      };
    } else {
      //  Remove Comparator from subAction since nothing is left
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.SubActionStage]: {},
      };
    }

    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => !subActionStagesKeysToRemove.includes(filterTag.filterValue)),
    );
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => !subActionStagesKeysToRemove.includes(filterTag.filterValue)),
    );
  }

  if (obj.key === FilterKey.SubActionStage) {
    actionStagesKeyToRemove = actionStagesAssociatedWithSubActionStages({
      subActionStageValue: obj.value as string,
    });
    const actionStageAlreadySelected = selectedKeys?.state?.in as string[] | undefined;

    const actionStagesLeftAfterFilterTagRemoval = actionStageAlreadySelected?.filter(
      i => i !== actionStagesKeyToRemove,
    );

    if (actionStagesLeftAfterFilterTagRemoval?.length) {
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.ActionStage]: {
          [Comparators.In]: actionStagesLeftAfterFilterTagRemoval,
        },
      };
    } else {
      //  Remove Comparator from actionStage since nothing is left
      selectedKeys = {
        ...selectedKeys,
        [FilterKey.ActionStage]: {},
      };
    }

    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => filterTag.filterValue !== actionStagesKeyToRemove),
    );
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.filterValue !== actionStagesKeyToRemove),
    );
  }

  const values = selectedKeys[obj.key][obj.comparator];
  if (!Array.isArray(values)) {
    selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);
  }
  const removeList = Array.isArray(values)
    ? values.filter((i: string) => i !== obj.filterValue)
    : values;

  if (Array.isArray(values) && removeList.length > 0) {
    selectedKeys = {
      ...selectedKeys,
      [obj.key]: {
        ...selectedKeys[obj.key],
        [obj.comparator]: removeList,
      },
    };

    setAllKeysSelected(selectedKeys);
    handleFilterChange(selectedKeys);
    setAllFilterTags((prev: any) => prev.filter((filterTag: any) => filterTag.value !== obj.value));
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== obj.value),
    );
  } else {
    let allKeys = { ...selectedKeys };
    allKeys = {
      ...allKeys,
      [obj.key]: omit(allKeys[obj.key], obj.comparator),
    };
    setAllKeysSelected(allKeys);
    handleFilterChange(allKeys);
    setAllFilterTags((prev: any) => prev.filter((filterTag: any) => filterTag.value !== obj.value));
    setFilterTagsToDisplay((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== obj.value),
    );
  }
};
