import React, { useCallback, useEffect, useState } from 'react';
import { Grid, GridProps, Slide, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import useDrawerListStyles from './DrawerList.style';
import DrawerCardAddress from './DrawerCardAddress';
import DrawerCardProjectLead from './DrawerCardProjectLead';

import AccountCard from '../../OrganizationDetails/AccountCard';
import { ReactComponent as CancelIcon } from '../../../../resources/images/cancel-icon.svg';
import EmptyBox from '../../../Common/EmptyBox';
import {
  MapDisplayLevel,
  MapObjectSourceState,
  MapObjectType,
} from '../../../../state/atoms/interactiveMap';
import { toTitleCase } from '../../../../utils/string';
import { getScores } from '../../../../api/score';
import { IScore, Sort } from '../../../../models/lead';
import { FilterData } from '../../../../types';
import { APICancel, createCancelToken, isCancel, IResponse } from '../../../../api';
import Loader from '../../../Common/Loader';
import { getMapDrawerAccounts } from '../../../../api/interactiveMap';
import { Organization } from '../../../../models';
import { useMessages } from '../../../../state/contexts';
import { getProjectViewFilter } from '../../../../api/projects';
import { IProject, sortColumns } from '../../Leads/Archive/Archive.config';
import { listTrueValues } from '../Summary/MapSummary';

let apiCancel: APICancel;
const DEFAULT_PAGE_SIZE = 10;

type IProps = GridProps & {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  mapDisplayLevel: string;
  locationName: string;
  objectType: string;
  objectSource?: MapObjectSourceState;
  zoomOutLocations: string[];
};

const DrawerList: React.FC<IProps> = ({
  className,
  setOpenDrawer,
  openDrawer,
  mapDisplayLevel,
  locationName,
  objectType,
  zoomOutLocations,
  objectSource,
}) => {
  const classes = useDrawerListStyles();
  const { setErrorMessage } = useMessages();
  const [rows, setRows] = useState<IScore[] | Organization[] | IProject[]>([]);
  const [count, setCount] = useState<number>(0);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const [drawerErrorMessage, setDrawerErrorMessage] = useState<null | string>(null);
  const [startIndex, setStartIndex] = useState(0);

  const resetData = () => {
    setStartIndex(0);
    setHasMoreItems(true);
    setCount(0);
    setRows([]);
  };

  const loadDrawerData = useCallback(
    async startIndex => {
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }

      try {
        const sort = { orderBy: 'propertyCreatedAt', order: 'desc' } as Sort;
        const filterOption = {} as FilterData;
        const limit = DEFAULT_PAGE_SIZE;

        let locationState;
        if (zoomOutLocations.length === 2) {
          //  sets state at county level for BE query to make more accurate
          locationState = zoomOutLocations[1];
          filterOption.state = { eq: locationState };
        }
        filterOption[mapDisplayLevel] = { eq: `${locationName}` };

        let data: IResponse<IScore> | IResponse<Organization> | IResponse<IProject> = {
          items: [] as IScore[],
          total: 0,
        };
        if (objectType === MapObjectType.Addresses) {
          data = await getScores(
            { sort, startIndex, filterOption, limit: limit },
            createCancelToken(cancel => {
              apiCancel = cancel;
            }),
          );
        } else if (objectType === MapObjectType.Accounts) {
          data = await getMapDrawerAccounts(
            {
              startIndex,
              limit,
              locationName: encodeURIComponent(locationName.trim()),
              displayLevel: mapDisplayLevel,
              locationState: locationState ? encodeURIComponent(locationState.trim()) : undefined,
            },
            createCancelToken(cancel => {
              apiCancel = cancel;
            }),
          );
        } else if (objectType === MapObjectType.ProjectLeads) {
          filterOption.projectStatus = { eq: 'active' };
          data = await getProjectViewFilter(
            {
              sort: { orderBy: 'lastUpdated', order: 'desc' },
              startIndex,
              filterOption,
            },
            sortColumns,
            createCancelToken(cancel => (apiCancel = cancel)),
          );
        }
        const { items, total } = data;
        if (!items) {
          setRows([]);
          setCount(0);
          setDrawerErrorMessage('No data');
          return;
        }
        // TODO fix types
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setRows(rows => [...rows, ...items]);
        setCount(total);

        if (items.length < DEFAULT_PAGE_SIZE) {
          setHasMoreItems(false);
        }
      } catch (e: any) {
        if (!isCancel(e)) {
          setErrorMessage(`Failed to load ${objectType} list: `, e);
          setDrawerErrorMessage(e.message);
        }
        setRows([]);
        setCount(0);
      }
    },
    [locationName, mapDisplayLevel, zoomOutLocations, objectType, setErrorMessage],
  );

  const renderRows = () => {
    if (objectType === MapObjectType.Addresses) {
      return (rows as IScore[]).map((value, idx) => {
        return <DrawerCardAddress key={idx} score={value} isOdd={idx % 2 !== 0} />;
      });
    } else if (objectType === MapObjectType.Accounts) {
      return (
        <Grid>
          {(rows as Organization[]).map((org, idx) => {
            return <AccountCard key={idx} organization={org} isOdd={!(idx % 2)} />;
          })}
        </Grid>
      );
    } else if (objectType === MapObjectType.ProjectLeads) {
      return (
        <Grid>
          {(rows as IProject[]).map((project, idx) => {
            return <DrawerCardProjectLead key={idx} project={project} isOdd={idx % 2 !== 0} />;
          })}
        </Grid>
      );
    }
  };

  useEffect(() => {
    if (mapDisplayLevel !== MapDisplayLevel.County) {
      setOpenDrawer(false);
    }
  }, [mapDisplayLevel, setOpenDrawer]);

  useEffect(() => {
    resetData();
  }, [objectType, mapDisplayLevel]);

  useEffect(() => {
    // Make API call based on objectType here
    if (openDrawer) {
      setDrawerErrorMessage(null);
      loadDrawerData(startIndex);
    } else {
      setRows([]);
      setCount(0);
    }
  }, [objectType, mapDisplayLevel, loadDrawerData, startIndex, openDrawer]);

  const alternateSource = objectSource ? listTrueValues(objectSource) : [];
  const source = alternateSource?.length === 1 ? alternateSource[0] : objectType;

  return (
    <Slide direction={'left'} in={openDrawer} mountOnEnter unmountOnExit>
      <Grid container className={className}>
        <Grid container item className={classes.headerContainer}>
          <Grid container className={classes.header}>
            {`${toTitleCase(source)} for ${locationName} (Showing ${rows.length} out of ${count})`}
          </Grid>
          <Grid container className={classes.cancel}>
            <CancelIcon cursor={'pointer'} onClick={() => setOpenDrawer(false)}>
              Close Drawer
            </CancelIcon>
          </Grid>
          <Grid container className={classes.buttonContainer}>
            <Link target="_blank" to={`/leads/inbound`}>
              <Typography variant={'caption'}>
                <button className={classes.button}>VIEW FULL LIST</button>
              </Typography>
            </Link>
          </Grid>
        </Grid>
        {/*List of items go here. Use Infinite Scroll*/}
        <div id="scrollableDivMapDrawer" className={classes.infiniteScrollContainerMapDrawer}>
          <InfiniteScroll
            next={() =>
              setStartIndex((startIndex: number): number => startIndex + DEFAULT_PAGE_SIZE)
            }
            hasMore={hasMoreItems}
            loader={
              !drawerErrorMessage ? (
                <Loader />
              ) : (
                <Grid className={classes.body}>
                  <EmptyBox className={classes.empty}>No Data Available</EmptyBox>
                </Grid>
              )
            }
            dataLength={rows.length}
            scrollableTarget="scrollableDivMapDrawer"
          >
            {count ? renderRows() : null}
          </InfiniteScroll>
        </div>
      </Grid>
    </Slide>
  );
};

export default DrawerList;
