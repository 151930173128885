import React from 'react';
import clsx from 'clsx';
import { makeStyles, Box, Fade } from '@material-ui/core';

import Loader from '../Loader';

interface IProps {
  loading?: boolean;
  fetching?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    padding: 0,
  },
  fetchingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    zIndex: 2,
  },
  fetchingLoader: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1302,
  },
  hidden: {
    visibility: 'hidden',
  },
});

const Body: React.FC<IProps> = ({ loading = false, fetching = false, children }) => {
  const classes = useStyles();
  return (
    <>
      <Box py={9} px={2} className={classes.root}>
        {loading && <Loader />}
        <Fade in={fetching}>
          <div className={classes.fetchingContainer} />
        </Fade>
        <div className={clsx({ [classes.hidden]: loading })}>{children}</div>
      </Box>
      {fetching && (
        <div className={classes.fetchingLoader}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default Body;
