import React from 'react';
import { Grid, GridProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { primaryBlue } from '../../theme';
import { Variant } from '../../types/typography';

type TitleBorderProps = GridProps & {
  leftItem: string | JSX.Element;
  rightItem?: string | JSX.Element;
  count?: number;
  variantLeft?: Variant;
  variantRight?: Variant;
  border?: string;
};

const useStyles = makeStyles(() => ({
  transparentBorder: {
    width: '100%',
    margin: '.75rem .25rem',
    border: `1px solid transparent`,
    position: 'relative',
  },
  border: {
    border: ({ border }: { border: string | undefined }) =>
      border ? border : `1px solid ${primaryBlue}`,
    borderRadius: '4px',
    margin: '.75rem .25rem',
    padding: '.75rem',
    height: '100%',
    zIndex: 0,
  },
  title: {
    zIndex: 1,
    position: 'absolute',
    margin: '0 1rem',
    padding: '0 1rem',
    left: '3%',
    display: 'flex',
    flexDirection: 'row',
  },
  leftItem: {
    backgroundColor: 'white',
    fontWeight: 'bold',
  },
  count: {
    padding: '.25rem .5rem',
    backgroundColor: 'white',
  },
  itemRight: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    margin: '0 1rem',
    padding: '0 1rem',
    right: '3%',
  },
  body: {
    paddingTop: '.5rem',
    height: '100%',
  },
}));

const TitleBorder: React.FC<TitleBorderProps> = ({
  leftItem,
  rightItem,
  count,
  variantLeft = 'h5',
  variantRight = 'subtitle1',
  className,
  style,
  border,
  children,
}) => {
  const classes = useStyles({ border: border });

  let left;
  if (typeof leftItem === 'string') {
    left = (
      <Typography style={{ fontWeight: 'bold', padding: '0 .5rem' }} variant={variantLeft}>
        {leftItem}
      </Typography>
    );
  }

  return (
    <Grid className={clsx(classes.transparentBorder, className)} style={style}>
      <Grid className={classes.title}>
        <Grid className={classes.leftItem}>{left || leftItem}</Grid>
        {!!count && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.count}>Total: {count}</Typography>
            <div style={{ flex: 1, backgroundColor: 'transparent' }} />
          </div>
        )}
      </Grid>
      {rightItem && (
        <Typography variant={variantRight} className={classes.itemRight}>
          {rightItem}
        </Typography>
      )}
      <Grid className={classes.border}>
        <Grid className={classes.body}>{children}</Grid>
      </Grid>
    </Grid>
  );
};

export default TitleBorder;
