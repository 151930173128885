import React from 'react';
import { makeStyles, FormHelperText, MenuItem, Select } from '@material-ui/core';
import type { SelectProps } from '@material-ui/core/Select';

import FormInput from './FormInput';

import type { DisplayNameMapping } from '../../types';

interface IProps extends SelectProps {
  options: DisplayNameMapping;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const FormSelect: React.FC<IProps> = ({ label, options, ...props }) => {
  const { name, fullWidth } = props;
  const classes = useStyles();
  return (
    <FormInput name={name} label={label} fullWidth={fullWidth}>
      {({ error, helperText, ...inputProps }) => (
        <>
          <Select classes={classes} {...inputProps} {...props}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Object.entries(options).map(([value, name]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </>
      )}
    </FormInput>
  );
};

export default FormSelect;
