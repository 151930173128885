import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';

import CreateContactModal, { IModalProps } from '../StakeholderDetails/CreateContactModal';
import FormSelect from '../../Common/FormSelect';
import { createOrganization } from '../../../api/organizations';
import { addStakeholderOrganization } from '../../../api/stakeholders';
import {
  ModalType,
  OrganizationProfileTypeMapping,
  StakeholderRoleMapping,
} from '../../../constants';
import type { Stakeholder } from '../../../models';
import { Organization } from '../../../models';
import { ProfileType, StakeholderRole } from '../../../models/common';
import { CreateOrganizationSchema, IOrganizationRequest } from '../../../models/organization';
import { purple } from '../../../theme';

export interface ICreateOrganizationProps {
  stakeholder?: Stakeholder;
}

type IProps = IModalProps & ICreateOrganizationProps;

const CreateOrganizationModal: React.FC<IProps> = ({ open, onClose, stakeholder }) => {
  const handleSubmit = useCallback(
    async (
      values: Partial<Organization> & { stakeholder_role: string },
      contact?: Partial<Organization>,
    ) => {
      if (!contact) {
        return createOrganization({
          name: values.name,
          description: values.description,
          address_1: values.address1,
          address_2: values.address2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
        } as IOrganizationRequest);
      }
      if (stakeholder) {
        await addStakeholderOrganization(stakeholder.id, contact.id || '', values.stakeholder_role);
      }
    },
    [stakeholder],
  );

  return (
    <CreateContactModal
      open={open}
      onClose={onClose}
      accentColor={purple}
      modalType={ModalType.AddOrganization}
      modelType={Organization.modelName}
      title="Account"
      showAddress
      initialValues={
        {
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          profile_type: ProfileType.Other,
          stakeholder_role: StakeholderRole.Other,
        } as Partial<Organization>
      }
      validationSchema={CreateOrganizationSchema}
      onSubmit={handleSubmit}
      redirectOnSearch={!stakeholder}
    >
      <Grid item>
        <FormSelect
          name="profile_type"
          label="Account Type"
          options={OrganizationProfileTypeMapping}
          fullWidth
        />
      </Grid>
      {stakeholder && (
        <Grid item>
          <FormSelect
            name="stakeholder_role"
            label="Contact Role"
            options={StakeholderRoleMapping}
            fullWidth
          />
        </Grid>
      )}
    </CreateContactModal>
  );
};

export default CreateOrganizationModal;
