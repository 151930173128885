import { makeStyles } from '@material-ui/core';

import { ReactTableHeaderCellProps } from './ReactTableHeaderCell';

export const useReactTableHeaderCellStyles = makeStyles(theme => ({
  root: {
    fontSize: '0.9rem',
    padding: '0.5rem',
    fontWeight: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
      column.id === 'Score' ? 'bolder' : 'inherit',
    textAlign: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
      column.id === 'Score' ? 'center' : 'left',
    border: 'none',
    cursor: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
      column.id === 'customizeColumnsGearIcon' ? 'pointer' : 'default',
    // width: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
    //   column.id === '_selector'
    //     ? '5% !important'
    //     : column.id === 'customizeColumnGearIcon'
    //     ? '3% !important'
    //     : '10% !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gearIconColumn: {
    display: 'flex',
    width: '5% !important',
  },
  cellContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  cellText: {
    width: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
      column.id === 'customizeColumnsGearIcon' || column.id === '_selector' ? '100%' : '80%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    justifyContent: ({ column }: ReactTableHeaderCellProps<Record<any, any>>) =>
      column.id === 'customizeColumnsGearIcon' || column.id === '_selector' || column.id === 'score'
        ? 'center'
        : 'space-between',
  },
  scoreIcon: {
    justifyContent: 'center',
  },
  gearIconSpan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '0.5rem',
  },
  resizer: {
    cursor: 'col-resize',
    width: '1rem',
    background: 'transparent',
  },
  label: {
    fontWeight: 'bold',
    color: `${theme.palette.primary.main} !important`,
    whiteSpace: 'nowrap',
  },
  labelActive: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 'bold',
  },
}));
