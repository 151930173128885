import React from 'react';
import { DateTime } from 'luxon';
import { makeStyles, Box, Paper, Grid, Typography } from '@material-ui/core';

import MoreMenu, { IMenuOption } from './MoreMenu';

import { darkGray } from '../../theme';
import NotesActionIcons from '../Pages/LeadDetails/Feedbacks/NotesActionIcons';

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
  },
  subtitle: {
    color: darkGray,
  },
}));

type AdditionalCardTitle = {
  title: string;
  subtitle: string;
};

interface IProps {
  title: string;
  date: Date;
  additionalTitles?: AdditionalCardTitle[];
  options?: IMenuOption[];
  onEdit?: (id: string, notes: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  noteDetails?: { id: string; notes: string };
}

const DatedTextCard: React.FC<IProps> = ({
  title,
  date,
  additionalTitles = [],
  options = [],
  children,
  onDelete,
  onEdit,
  noteDetails,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box p={3}>
        <Box mb={1}>
          <Grid container direction="column">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.subtitle} variant="body2">
                  {/*{dateToRelative(date)}*/}
                  {DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_FULL) || ''}
                </Typography>
              </Grid>
              {noteDetails && (
                <Grid item>
                  <NotesActionIcons
                    noteDetails={noteDetails}
                    onDelete={onDelete && (() => onDelete())}
                    onEdit={onEdit}
                  />
                </Grid>
              )}
              {!!options.length && !noteDetails && (
                <Grid item>
                  <MoreMenu options={options} />
                </Grid>
              )}
            </Grid>
            {additionalTitles.map(additionalTitle => (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                key={additionalTitle.title}
              >
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    {additionalTitle.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtitle} variant="body2">
                    {additionalTitle.subtitle}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        {children}
      </Box>
    </Paper>
  );
};

export default DatedTextCard;
