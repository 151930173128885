import React, { useState } from 'react';
import { Grid, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { useRecoilState } from 'recoil';

import dropDownFilter from '../../../resources/images/drop_down_filter.svg';
import { accountsSortState } from '../../../state/atoms/accounts';

const sortConfig: {
  label: string;
  sortColumn: string;
  sortDirection: string;
}[] = [
  {
    label: 'Most Recent Activity',
    sortColumn: 'updated_at',
    sortDirection: 'desc',
  },
  {
    label: 'Alphabetical: A-Z',
    sortColumn: 'name',
    sortDirection: 'asc',
  },
  {
    label: 'Alphabetical: Z-A',
    sortColumn: 'name',
    sortDirection: 'desc',
  },
];

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
  list: {
    padding: 0,
  },
  menuItem: {
    fontWeight: 'normal',
    color: 'black',
    margin: 0,
    padding: '1px 1.5rem !important',
    minHeight: '3rem',
  },
  selected: {
    fontWeight: 'bold',
  },
}));

const AccountsListSort: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [accountsSort, setAccountsSort] = useRecoilState(accountsSortState);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    selected: {
      column: string;
      direction: string;
    } | null,
  ) => {
    if (selected) {
      setAccountsSort({ column: selected.column, direction: selected.direction });
    }
    setAnchorEl(null);
  };

  return (
    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ backgroundColor: 'transparent' }}
      >
        <img src={dropDownFilter} alt={'accounts list drop down sort'} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        classes={{ paper: classes.paper, list: classes.list }}
      >
        {sortConfig.map(config => (
          <MenuItem
            key={config.sortColumn + config.sortDirection}
            onClick={() =>
              handleClose({ column: config.sortColumn, direction: config.sortDirection })
            }
            selected={
              { column: config.sortColumn, direction: config.sortDirection } ===
              { column: accountsSort.column, direction: accountsSort.direction }
            }
            classes={{ root: classes.menuItem, selected: classes.selected }}
          >
            {config.label}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default AccountsListSort;
