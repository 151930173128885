import { makeStyles } from '@material-ui/core';

import { gold, light, lightGreen, primary } from '../../../theme';

type IProps = {
  isOdd: boolean;
};

const StakeholderCardStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5),
    backgroundColor: (props: IProps) => (!props.isOdd ? 'white' : lightGreen),
    '&:hover': {
      backgroundColor: light,
    },
    cursor: 'pointer',
    alignItems: 'flex-start',
    borderRadius: '4px',
  },
  national: {
    border: `2px solid ${gold}`,
  },
  rootPrimary: {
    borderLeftColor: primary,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexWrap: 'nowrap',
    padding: '.5rem',
  },
  info: { flex: 1 },
  dense: {
    lineHeight: '1.1rem',
  },
  denseName: {
    lineHeight: '1.1rem',
    wordBreak: 'break-all',
  },
  logo: {
    maxWidth: 40,
    maxHeight: 20,
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default StakeholderCardStyle;
