import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

interface IProjectLeadsHeaderProps {
  name: string;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem 0 0 0',
    // borderBottom: `1px solid ${primaryBlue}`,
  },
}));

const ProjectLeadsHeader: React.FC<IProjectLeadsHeaderProps> = ({ name }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Typography variant={'h2'} style={{ fontWeight: 'bold' }}>
        {name} Projects
      </Typography>
    </Grid>
  );
};

export default ProjectLeadsHeader;
