import { makeStyles } from '@material-ui/core';

import { lightGray, primaryBlue } from '../../../../theme';

export const useReactTablePaginationStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: lightGray,
    padding: '.5rem 0',
  },
  select: {
    padding: '.25rem',
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
  },
  selectItem: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    color: primaryBlue,
  },
  selected: {
    fontWeight: 'bold',
    color: 'black',
  },
  pages: { padding: '0 .5rem' },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 .5rem',
  },
  button: {
    background: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
  },
}));
