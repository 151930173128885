import {
  MapObjectSource,
  MapObjectSourceState,
  MapObjectType,
} from '../../../../state/atoms/interactiveMap';
import { toTitleCase } from '../../../../utils/string';
import { info, primary, purple, secondary } from '../../../../theme';
import { listTrueValues } from '../Summary/MapSummary';

export interface RadioOptions {
  label: string;
  value: string;
  color?: string;
  bgColor?: string;
  checkboxOptions?: CheckboxOptions[];
}

export interface CheckboxOptions {
  label: string;
  value: MapObjectSource;
  color: string;
}

export const config: RadioOptions[] = [
  Object.freeze({
    label: toTitleCase(MapObjectType.ProjectLeads),
    value: MapObjectType.ProjectLeads,
    color: primary,
    bgColor: secondary,
    checkboxOptions: [
      Object.freeze({
        label: 'Dodge',
        value: MapObjectSource.Dodge,
        color: '#DAA520',
      }),
      Object.freeze({
        label: 'CMD',
        value: MapObjectSource.CMD,
        color: '#BFBF5F',
      }),
      //  TODO: Uncomment to unlock CMD and CW options
      // Object.freeze({ label: 'Construction Wire', value: MapObjectSource.CW, color: '#F03434' }),
    ],
  }),
  Object.freeze({
    label: toTitleCase(MapObjectType.Addresses),
    value: MapObjectType.Addresses,
    color: info,
  }),
  Object.freeze({
    label: toTitleCase(MapObjectType.Accounts),
    value: MapObjectType.Accounts,
    color: purple,
  }),
];

/**
 * Function that determines if we show Project Leads or the sources they come from
 * */
export const displayTypeOrSource = (
  objectType: MapObjectType,
  objectSources: MapObjectSourceState,
): string[] => {
  const configuration = config.filter(typeOption => typeOption.value === objectType)[0];
  const sources = listTrueValues(objectSources);
  let display;
  let color;
  if (sources.length === 1) {
    // A single project lead is selected. Use selected object source information
    display = sources[0] as MapObjectSource;
    color = configuration?.checkboxOptions?.filter(
      sourceOption => sourceOption.value === (sources[0] as MapObjectSource),
    )[0].color as string;
    return [toTitleCase(display), color];
  } else {
    // Case to use object Type
    color = configuration.color as string;
    return [toTitleCase(objectType), color];
  }
};
