import { makeStyles } from '@material-ui/core/styles';

import { primary } from '../../../../theme';

const useDrawerListStyles = makeStyles(theme => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `3px solid ${primary}`,
    marginBottom: '1rem',
  },
  header: {
    width: '80%',
    padding: '1rem 1rem 0rem 1rem',
    fontWeight: 'bold',
    fontSize: theme.typography.h6.fontSize,
  },
  cancel: {
    width: '20%',
    padding: '1rem 1rem 0rem 1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: 'white',
    border: `1px solid ${primary}`,
    color: primary,
    borderRadius: '4px',
    padding: '.25rem',
    paddingRight: '.75rem',
    paddingLeft: '.75rem',
    margin: '0 1rem .5rem 1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  infiniteScrollContainerMapDrawer: {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  empty: {
    height: '50vh',
    alignItems: 'center',
  },
}));

export default useDrawerListStyles;
