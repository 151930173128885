import { Button, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import SaveFilterModal from './SaveFilterModal';

import { saveFilterName } from '../../../../api/filters';
import { ScoreStatus } from '../../../../models/lead';
import { FilterData, FilterType, Optional, UserFilterNameResponse } from '../../../../types';
import { useLeadsStyles } from '../Leads.styles';
import {
  editedFilterNameState,
  filterNameErrorState,
  filterTagsToDisplayState,
  savedFilterValueState,
} from '../../../../state/atoms/filters';
import { currentUserState } from '../../../../state/atoms/users';
import { CleoObjectType } from '../../../../models/common';
import { useMessages } from '../../../../state/contexts';

interface IProps {
  filterOption: Optional<FilterData>;
  setScoreStatus: (status: ScoreStatus) => void;
  setSavedFilter: (savedFilter: UserFilterNameResponse) => void;
  cleoObjectType: CleoObjectType;
  disabled: boolean;
}

const SaveFilterForm: React.FC<IProps> = ({
  filterOption,
  setScoreStatus,
  setSavedFilter,
  cleoObjectType = CleoObjectType.Addresses,
  disabled,
}: IProps) => {
  const history = useHistory();
  const classes = useLeadsStyles();

  const filterTagsToDisplay = useRecoilValue(filterTagsToDisplayState);
  const currentUser = useRecoilValue(currentUserState);
  let pathPrefix =
    cleoObjectType === CleoObjectType.Addresses
      ? '/leads/inbound/savedFilters/'
      : '/project-leads/savedFilters/';
  let filterType: FilterType =
    cleoObjectType === CleoObjectType.Addresses ? FilterType.Address : FilterType.ProjectLead;

  if (cleoObjectType === CleoObjectType.Accounts) {
    pathPrefix = '/accounts/savedFilters/';
    filterType = FilterType.Account;
  }

  const setEditedFilterName = useSetRecoilState(editedFilterNameState);

  const [filterNameError, setFilterNameError] = useRecoilState(filterNameErrorState);
  const [savedFilterValue, setSavedFilterValue] = useRecoilState(savedFilterValueState);

  const { setSuccessMessage } = useMessages();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleSavedFilterName = useCallback(
    async (isPublicChecked: boolean) => {
      setIsOpen(false);
      if (savedFilterValue.length === 0) {
        setFilterNameError('You must give this filter a name in order to save it.');
        return;
      }
      try {
        const savedFilterResponse =
          currentUser &&
          filterOption &&
          (await saveFilterName({
            user_id: currentUser?.id,
            filter_name: savedFilterValue,
            filter: filterOption,
            shared: true,
            filter_tags: {
              tags: filterTagsToDisplay,
            },
            filter_type: filterType,
            is_public: isPublicChecked,
          }));
        setScoreStatus(ScoreStatus.Home);
        setSavedFilterValue('');
        setEditedFilterName(savedFilterValue);
        setFilterNameError(undefined);
        if (savedFilterResponse && savedFilterResponse.id) {
          setSavedFilter(savedFilterResponse);
          history.push(`${pathPrefix}${savedFilterResponse.id}`);
          setSuccessMessage('You have saved a new filter.');
        }
      } catch (e: any) {
        if (e.response && e.response.data) {
          setFilterNameError(e.response.data.message);
        }
      }
    },
    [
      savedFilterValue,
      currentUser,
      filterOption,
      setSavedFilterValue,
      filterTagsToDisplay,
      history,
      setEditedFilterName,
      setFilterNameError,
      setSavedFilter,
      setScoreStatus,
      pathPrefix,
      filterType,
      setSuccessMessage,
    ],
  );

  return (
    <Grid item className={filterNameError && classes.savedFilterErrorContainer}>
      <Button
        disableRipple
        disabled={disabled}
        size="medium"
        variant="text"
        onClick={() => setIsOpen(true)}
        className={classes.saveFilterButton}
        style={disabled ? { backgroundColor: '#DFE2E1', color: 'black' } : {}}
      >
        SAVE AS NEW
      </Button>
      <SaveFilterModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        savedFilterValue={savedFilterValue}
        setSavedFilterValue={setSavedFilterValue}
        handleSavedFilterName={handleSavedFilterName}
      />
    </Grid>
  );
};

export default SaveFilterForm;
