import * as Yup from 'yup';

import { BaseModel } from '.';

import { IBaseModelResponse } from './baseModel';

export interface DatasetItem {
  fixedName: string;
  type: string;
  field: string;
  parent: string;
  group: number | undefined;
}

export type DatasetConfig = {
  columns: DatasetItem[];
  url: string;
};

export enum DataMapperStatusTypes {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
}

export type IDataMapperResponse = IBaseModelResponse & {
  cron: string;
  dataset_config: DatasetConfig;
  data_mapper_name: string;
  source_label: string;
  source_name: string;
  status: DataMapperStatusTypes;
};

export type IDataMapperRequest = Pick<
  IDataMapperResponse & DatasetConfig,
  'cron' | 'dataset_config' | 'data_mapper_name' | 'source_label' | 'source_name' | 'status' | 'url'
>;

export const DataMapperDataResponseSchema = Yup.object().shape({
  dataProp: Yup.string().nullable(),
});

export const DataMapperRequestSchema = Yup.object().shape({
  name: Yup.string().required('A name is required for this data mapper configuration.'),
  description: Yup.string().nullable(),
  url: Yup.string().required('A url is required in order to fetch data.'),
});

export const DataMapperCronRequestSchema = Yup.object().shape({
  minutes: Yup.mixed().test({
    message: 'Maximum minutes allowed is 59.',
    test: value => (value > 0 && value < 60) || value === '*',
  }),
  hours: Yup.mixed().test({
    message: 'Maximum hours allowed is 23.',
    test: value => (value > 0 && value < 24) || value === '*',
  }),
  monthDay: Yup.mixed().test({
    message: 'Maximum days allowed for the month is 31.',
    test: value => (value > 0 && value < 32) || value === '*',
  }),
  month: Yup.mixed().test({
    message: 'Maximum month number allowed is 12.',
    test: value => (value > 0 && value < 13) || value === '*',
  }),
  day: Yup.mixed().test({
    message: 'Maximum days allowed for the week is 7.',
    test: value => (value > 0 && value < 8) || value === '*',
  }),
  weekDay: Yup.mixed().test({
    message: 'Weekday values must be between 0-6.',
    test: value => (value >= 0 && value <= 6) || value === '*',
  }),
});

export default class DataMapper extends BaseModel {
  cron!: string;
  dataset_config!: Record<string, unknown>;
  name!: string;
  sourceLabel!: string;
  sourceName!: string;
  status!: DataMapperStatusTypes;
  url: string;

  constructor(data: IDataMapperResponse) {
    super(data);

    const { cron, dataset_config, data_mapper_name, source_label, source_name, status } = data;
    this.cron = cron;
    this.dataset_config = dataset_config;
    this.name = data_mapper_name;
    this.sourceLabel = source_label;
    this.status = status;
    this.sourceName = source_name;
    this.url = dataset_config && dataset_config.url;
  }
}
