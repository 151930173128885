import { makeStyles } from '@material-ui/core';

import { darkLight, light, primaryBlue } from '../../theme';
import { TabStylesProps } from '../Pages/Leads/LeadsTable/StatusBar';

export const tabStyles = makeStyles({
  root: {
    zIndex: 10,
    bottom: '-.1rem',
    color: darkLight,
    fontWeight: 'normal',
    width: '10rem',
    fontSize: (props?: TabStylesProps) => (props?.fontSize ? props.fontSize : '1.21rem'),
    '&:hover': {
      color: `${primaryBlue} !important`,
      borderRadius: 5,
    },
  },
  selected: {
    fontWeight: 'bold',
    backgroundColor: light,
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    height: 10,
    overflow: 'hidden',
    borderBottom: `6px solid ${primaryBlue}`,
    paddingBottom: '0rem',
  },
  wrapper: {
    border: 'none',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
});
