import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import isEmpty from 'lodash/isEmpty';

import { useAccountsPageStyles } from './AccountsPage.styles';
import { AccountPageStatus, AccountsPageMenu } from './AccountsPage.config';
import AccountsHome from './AccountsHome/AccountsHome';

import { CleoObjectType } from '../../../models/common';
import LeadsFilterDrawer from '../Leads/LeadsFilter/LeadsFilterDrawer';
import StatusBar from '../Leads/LeadsTable/StatusBar';
import { getAccountsPagePath } from '../../../utils/page';
import {
  Comparators,
  FilterData,
  FilterItemInputType,
  FilterKey,
  FilterOptionsItem,
  FilterOptionsResponse,
  FilterType,
  FilterValue,
  Optional,
  UserFilterNameResponse,
} from '../../../types';
import { filterOptionsFetchState } from '../../../state/selectors/filterOptions';
import {
  allFilterTagsState,
  allKeysSelectedState,
  clearFiltersState,
  currentFilterOptionsState,
  editedFilterNameState,
  filterNameErrorState,
  filterOptionState,
  filterTagsToDisplayState,
  previousWinSelectedState,
  savedFilterValueState,
} from '../../../state/atoms/filters';
import FilterTags from '../Leads/FilterTags';
import { User } from '../../../models';
import { resetCachedFilterState } from '../../../state/atoms/leadRows';
import { currentUserState, userOptionsState } from '../../../state/atoms/users';
import { usersFetchState } from '../../../state/selectors/users';
import {
  accountDetailsIdState,
  accountsIsNewState,
  accountsMergeIdState,
} from '../../../state/atoms/accounts';
import SavedFilters from '../Leads/SavedFilters/SavedFilters';
import SavedFilterHeader from '../Leads/SavedFilters/SavedFilterHeader';
import { getSavedFilterById } from '../../../api/filters';
import {
  defaultSpecRateFilterValue,
  defaultSpecRateSelectedKeys,
  normalizedSpecRateSelectedKeysState,
  specRateSelectedKeysState,
} from '../../../state/atoms/filterSpecRates';
import { secondary } from '../../../theme';

interface IRouteParams {
  status: AccountPageStatus;
  savedFilterId?: string;
  organizationId?: string;
}

const AccountsPage: React.FC = () => {
  const classes = useAccountsPageStyles();
  const { status, organizationId, savedFilterId } = useParams<IRouteParams>();

  const [accountsPageStatus, setAccountsPageStatus] = useState(status);
  const accountsMergeId = useRecoilValue(accountsMergeIdState);
  const [filterTagsToDisplay, setFilterTagsToDisplay] = useRecoilState(filterTagsToDisplayState);
  const [clearFilters, setClearFilters] = useRecoilState(clearFiltersState);
  const [filterOption, setFilterOption] = useRecoilState<Optional<FilterData>>(filterOptionState);
  const setIsNew = useSetRecoilState(accountsIsNewState);

  //Filters and Tags
  const setAllKeysSelected = useSetRecoilState(allKeysSelectedState);
  const setAllFilterTags = useSetRecoilState(allFilterTagsState);
  const setSpecRateSelectedKeys = useSetRecoilState(specRateSelectedKeysState);
  const setResetCachedFilter = useSetRecoilState(resetCachedFilterState);
  const setAccountDetailsId = useSetRecoilState(accountDetailsIdState);
  const normalizedSpecRateSelectedKeys = useRecoilValue(normalizedSpecRateSelectedKeysState);
  const [previousWinSelected, setPreviousWinSelected] = useRecoilState(previousWinSelectedState);

  // User Filters
  const { user } = useAuth0();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setUserOptions = useSetRecoilState<FilterOptionsItem[]>(userOptionsState);
  const users = useRecoilValueLoadable(usersFetchState);

  // Saved Filters
  const [editedFilterNameError, setEditedFilterNameError] = useState<string>();
  const [savedFilter, setSavedFilter] = useState<UserFilterNameResponse>();
  const setEditedFilterName = useSetRecoilState<string>(editedFilterNameState);
  const setFilterNameError = useSetRecoilState<string | undefined>(filterNameErrorState);
  const setSavedFilterValue = useSetRecoilState(savedFilterValueState);

  const fetchedFilterOptions = useRecoilValueLoadable<FilterOptionsResponse>(
    filterOptionsFetchState,
  );
  const setCurrentFilterOptions = useSetRecoilState<FilterOptionsResponse>(
    currentFilterOptionsState,
  );

  const handleTabClick = (nextAccountsPageStatus: AccountPageStatus) => {
    setAccountsPageStatus(nextAccountsPageStatus);
  };

  const disableFilters =
    !!accountsMergeId || !!organizationId || status === AccountPageStatus.SavedFilters;

  const handleFilterChange = useCallback(
    (filterData: FilterData) => {
      return setFilterOption(currentFilterOption => {
        const prevWinFilter = previousWinSelected
          ? { previousWins: { gt: 0 } }
          : { previousWins: {} };
        const { restOfFilters } = separateSpecRateAndPreviousWinFilter({ ...currentFilterOption });
        return {
          ...restOfFilters,
          ...normalizedSpecRateSelectedKeys,
          ...filterData,
          ...prevWinFilter,
        };
      });
    },
    [setFilterOption, normalizedSpecRateSelectedKeys, previousWinSelected],
  );

  const hasFilterBeenCleared = useCallback(() => {
    let filterHasBeenCleared = false;
    if (
      filterOption &&
      (isEmpty(filterOption) ||
        Object.keys(filterOption).every(option =>
          option === 'isNew' ? true : isEmpty(filterOption[option]),
        ))
    ) {
      filterHasBeenCleared = true;
    }
    return filterHasBeenCleared;
  }, [filterOption]);

  /**
   * Helper function to transform decimal into percentage or use default spec rate filter value
   * */
  const getSpecRateFilterValue = (specRate?: FilterValue): FilterValue => {
    if (!specRate) {
      // default spec rate value
      return { ...defaultSpecRateFilterValue } as FilterValue;
    }
    const specRateFilter = {} as FilterValue;
    const [[comp, decimal]] = Object.entries(specRate);
    specRateFilter[(comp as unknown) as Comparators.Gte | Comparators.Lte] =
      ((decimal as unknown) as number) * 100;
    return specRateFilter;
  };

  const separateSpecRateAndPreviousWinFilter = (
    filter: FilterData,
  ): { specRateFilters: FilterData; restOfFilters: FilterData; previousWinFilter: FilterData } => {
    const {
      armstrongSpecRate,
      certainteedSpecRate,
      rockfonSpecRate,
      usgSpecRate,
      previousWins,
      ...restOfFilters
    } = filter;

    return {
      specRateFilters: { armstrongSpecRate, certainteedSpecRate, rockfonSpecRate, usgSpecRate },
      previousWinFilter: { previousWins },
      restOfFilters,
    };
  };

  const loadSavedFilter = useCallback(async (): Promise<void> => {
    if (savedFilterId) {
      try {
        const savedFilterResponse = await getSavedFilterById(savedFilterId);

        // Separate spec rate filters/tags from filter response
        // This prevents spec rate filters/tags being applied multiple times when updating state
        const { specRateFilters, restOfFilters } = separateSpecRateAndPreviousWinFilter(
          savedFilterResponse.filter,
        );

        const loadedSpecRates = {
          armstrongSpecRate: getSpecRateFilterValue(specRateFilters.armstrongSpecRate),
          certainteedSpecRate: getSpecRateFilterValue(specRateFilters.certainteedSpecRate),
          rockfonSpecRate: getSpecRateFilterValue(specRateFilters.rockfonSpecRate),
          usgSpecRate: getSpecRateFilterValue(specRateFilters.usgSpecRate),
        };

        const filterTagsSansSpecRateAndPreviousWin = savedFilterResponse.filter_tags.tags.filter(
          tag => tag.id !== FilterKey.SpecRates && tag.key !== FilterKey.PreviousWins,
        );

        setSavedFilter(savedFilterResponse);
        setEditedFilterName(savedFilterResponse.filter_name || '');
        setFilterNameError(undefined);
        setFilterOption(savedFilterResponse.filter); // Include all filters
        // handleFilterChange(savedFilterResponse.filter); // Include all filters
        setSpecRateSelectedKeys(loadedSpecRates);
        setAllFilterTags(filterTagsSansSpecRateAndPreviousWin); // exclude spec rate filter tags and previous win filter tag
        setFilterTagsToDisplay(savedFilterResponse.filter_tags.tags); // include all filter tags
        setAllKeysSelected(restOfFilters);
        setPreviousWinSelected(savedFilterResponse.filter.previousWins.gt === 0);
      } catch (e: any) {
        if (e.response && e.response.data) {
          setFilterNameError(e.response.data.message);
        }
      }
    }
  }, [
    // handleFilterChange,
    savedFilterId,
    setAllFilterTags,
    setAllKeysSelected,
    setSpecRateSelectedKeys,
    setEditedFilterName,
    setFilterTagsToDisplay,
    setFilterOption,
    setFilterNameError,
    setSavedFilter,
    setPreviousWinSelected,
  ]);

  useEffect(() => {
    if (fetchedFilterOptions.state === 'hasValue') {
      setCurrentFilterOptions(fetchedFilterOptions.contents);
    }
  }, [fetchedFilterOptions, setCurrentFilterOptions]);

  // handles specific organization from route parameters
  useEffect(() => {
    if (organizationId) {
      setAccountDetailsId(organizationId);
      setIsNew('showAll');
    } else {
      setAccountDetailsId(null);
      setIsNew('showNew');
    }
  }, [setAccountDetailsId, organizationId, setIsNew]);

  useEffect(() => {
    if (clearFilters) {
      setAllKeysSelected({});
      setFilterOption({});
      setAllFilterTags([]);
      setFilterTagsToDisplay([]);
      setSpecRateSelectedKeys(defaultSpecRateSelectedKeys);
      setResetCachedFilter(true);
      setClearFilters(false);
      setEditedFilterName('');
      setPreviousWinSelected(false);
    }
    if (hasFilterBeenCleared()) {
      setEditedFilterNameError(undefined);
      setFilterNameError(undefined);
      setSavedFilterValue('');
    }
  }, [
    clearFilters,
    setAllKeysSelected,
    setAllFilterTags,
    setResetCachedFilter,
    setClearFilters,
    setSpecRateSelectedKeys,
    hasFilterBeenCleared,
    setFilterTagsToDisplay,
    setFilterOption,
    setFilterNameError,
    setSavedFilterValue,
    setEditedFilterName,
    setPreviousWinSelected,
  ]);

  useEffect(() => {
    if (users.state === 'hasValue') {
      const options: FilterOptionsItem[] = users.contents.map(i => {
        return {
          label: i.fullName || i.email,
          value: i.id,
          inputType: FilterItemInputType.Select,
          comparator: Comparators.In,
        };
      });
      options.push({
        label: 'Unassigned',
        value: null,
        inputType: FilterItemInputType.Select,
        comparator: Comparators.Is,
      });
      setUserOptions(options);
      setCurrentUser(users.contents.find((u: User) => user.email === u.email));
    }
  }, [users, user, setUserOptions, setCurrentUser]);

  useEffect(() => {
    loadSavedFilter();
  }, [loadSavedFilter]);

  // Clear filters when changing tabs
  useEffect(() => {
    setClearFilters(true);
  }, [setClearFilters, status]);

  return (
    <Container className={classes.root}>
      <LeadsFilterDrawer
        handleFilterChange={handleFilterChange}
        hasFilterBeenCleared={hasFilterBeenCleared}
        setScoreStatus={() => setAccountsPageStatus(AccountPageStatus.Home)}
        setSavedFilter={setSavedFilter}
        savedFilter={savedFilter}
        cleoObjectType={CleoObjectType.Accounts}
        disabled={disableFilters}
        filterTagsToDisplay={filterTagsToDisplay}
        filterType={FilterType.Account}
        editedFilterNameError={editedFilterNameError}
        setEditedFilterNameError={setEditedFilterNameError}
        closeFilterDrawer={!!accountsMergeId}
      />
      <Paper className={classes.paper}>
        <Grid className={classes.menuTabs}>
          <StatusBar
            statusMenu={AccountsPageMenu}
            selectedStatus={accountsPageStatus || AccountPageStatus.Home}
            pathPrefix={getAccountsPagePath()}
            onChange={handleTabClick}
          />
        </Grid>
        <div style={{ backgroundColor: secondary }}>
          {savedFilter && (
            <SavedFilterHeader
              editedFilterNameError={editedFilterNameError}
              savedFilter={savedFilter}
              setScoreStatus={() => setAccountsPageStatus(AccountPageStatus.Home)}
              setSavedFilter={setSavedFilter}
              setEditedFilterNameError={setEditedFilterNameError}
              filterType={FilterType.Account}
            />
          )}
          {filterTagsToDisplay.length > 0 && <FilterTags handleFilterChange={handleFilterChange} />}
        </div>
        {(accountsPageStatus === AccountPageStatus.Home || organizationId) && (
          <AccountsHome filterOption={filterOption} />
        )}
        {!!savedFilterId && savedFilter && <AccountsHome filterOption={filterOption} />}
        {status === AccountPageStatus.SavedFilters && (
          <SavedFilters
            scoreStatus={AccountPageStatus.SavedFilters}
            cleoObjectType={CleoObjectType.Accounts}
          />
        )}
      </Paper>
    </Container>
  );
};

export default AccountsPage;
