import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

import Statistic from './Statistic';

type IProps = GridProps;

export interface Stat {
  header: string;
  statistic: null | number;
  delta: null | number;
}

const config: Stat[] = [
  { header: 'Project Leads Count', statistic: null, delta: null },
  { header: 'MF Won(Sqft)', statistic: null, delta: null },
  { header: 'Incr. Project Leads', statistic: null, delta: null },
  { header: 'New Accounts', statistic: null, delta: null },
];

const StatisticsContainer: React.FC<IProps> = ({ className }) => {
  return (
    <Grid container className={className}>
      {config.map((stat, idx) => (
        <Statistic key={`${stat.header}-${idx}`} stat={stat} />
      ))}
    </Grid>
  );
};

export default StatisticsContainer;
