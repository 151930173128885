import { atom } from 'recoil';

import { DodgeProjectLead } from '../../models';
import { Optional } from '../../types';

export const dodgeProjectLeadDisplayState = atom({
  key: 'currentDodgeLead',
  default: (undefined as unknown) as Optional<DodgeProjectLead>,
});

export const selectedBulkDemoteState = atom({
  key: 'selectedBulkDemote',
  default: [] as {
    [key: string]: any;
  }[],
});

export const bulkDemoteTransitionState = atom({
  key: 'bulkDemoteTransition',
  default: undefined as { reason: string; note: string } | undefined,
});

export const clearCheckboxesState = atom({
  key: 'clearCheckboxes',
  default: false,
});
