import omit from 'lodash/omit';
import { SetterOrUpdater } from 'recoil';

import {
  Comparators,
  FilterData,
  FilterItem,
  FilterKey,
  FilterOptionsItem,
  OptionValue,
  TagsDisplay,
} from '../../../../../types';
import { ACTION_STAGES } from '../../../../../constants';

interface IHandleActionStageDeselectionFromFilterProps {
  valuesArrayAfterDeselection: OptionValue[];
  filterItem: FilterItem;
  filterItemOption: FilterOptionsItem;
  allKeys: FilterData;
  actionStageAssociatedWithSubActionStage: string | undefined;
  setAllKeys: SetterOrUpdater<FilterData | undefined>;
  setAllFilterTags: SetterOrUpdater<TagsDisplay[]>;
  setSelectedKeys: SetterOrUpdater<FilterData>;
  keySelection: FilterData;
  subActionStageInActionStage: string[];
}

/**
 * Function helps remove the state filter along with associated counties (sub-filters) and tags on deselection
 * */
// TODO: Come back here and update comments
export const handleActionStageSubActionStageDeselectionFromFilter = ({
  valuesArrayAfterDeselection,
  setSelectedKeys,
  filterItem,
  filterItemOption,
  allKeys,
  actionStageAssociatedWithSubActionStage,
  setAllKeys,
  setAllFilterTags,
  keySelection,
  subActionStageInActionStage,
}: IHandleActionStageDeselectionFromFilterProps) => {
  if (valuesArrayAfterDeselection?.length > 0) {
    // If state filter, remove the county filters associated
    setSelectedKeys((currentSelectedKeys: FilterData) => {
      const updatedSelectedKeys = {
        [filterItem.filterKey]: {
          ...currentSelectedKeys[filterItem.filterKey],
          [filterItemOption.comparator]: valuesArrayAfterDeselection,
        },
      };

      // If countyState filter, remove the state filter and tag associated
      if (filterItem.filterKey === FilterKey.SubActionStage) {
        const allActionStagesIn = allKeys[FilterKey.ActionStage][Comparators.In] as
          | string[]
          | undefined;
        if (allActionStagesIn?.length && actionStageAssociatedWithSubActionStage) {
          const actionStageArrayAfterDeselection = allActionStagesIn.filter(
            i => i?.toString() !== actionStageAssociatedWithSubActionStage?.toString(),
          );
          // handle if comparator becomes empty
          updatedSelectedKeys[FilterKey.ActionStage] = actionStageArrayAfterDeselection.length
            ? {
                ...currentSelectedKeys[FilterKey.ActionStage],
                [Comparators.In]: actionStageArrayAfterDeselection,
              }
            : {};
        }
      } else if (filterItem.filterKey === FilterKey.ActionStage) {
        // If state filter, remove the county filters associated and update selected countyState keys
        let subActionStageKeysLeft: string[] = (allKeys?.subActionStage?.in || []) as string[];
        subActionStageKeysLeft = subActionStageKeysLeft.filter(
          (filter: string) =>
            !subActionStageInActionStage.map(subActionStage => subActionStage).includes(filter),
        );
        updatedSelectedKeys[FilterKey.SubActionStage] = {
          [Comparators.In]: subActionStageKeysLeft,
        };
      }
      keySelection = updatedSelectedKeys;
      return updatedSelectedKeys;
    });
    setAllKeys(prev => ({ ...prev, ...keySelection }));
    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
    );
    if (filterItem.filterKey === FilterKey.SubActionStage) {
      if (actionStageAssociatedWithSubActionStage) {
        setAllFilterTags((prev: any) =>
          prev.filter(
            (filterTag: any) =>
              filterTag.value !== ACTION_STAGES[actionStageAssociatedWithSubActionStage || ''],
          ),
        );
      }
    }
  } else {
    // If state filter, remove the county filters associated and remove the comparator
    setSelectedKeys(currentSelectedKeys => {
      const removeComparator = { ...currentSelectedKeys };
      let allKeys = { ...removeComparator };
      allKeys = {
        ...removeComparator,
        [filterItem.filterKey]: omit(
          removeComparator[filterItem.filterKey],
          filterItemOption.comparator,
        ),
      };
      keySelection = allKeys;
      return allKeys;
    });
    setAllKeys(prev => {
      return { ...prev, ...keySelection };
    });
  }
};
