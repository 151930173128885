import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BaseModel, Organization, Property, Stakeholder } from '../../../models';
import StakeholderCard from '../../Pages/StakeholderDetails/StakeholderCard';
import OrganizationCard from '../../Pages/OrganizationDetails/OrganizationCard';
import LeadCard from '../../Pages/Leads/LeadsTable/LeadCard';
import { toTitleCase } from '../../../utils/string';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Loader from '../Loader';

const useStyles = makeStyles(theme => ({
  createButton: {
    margin: theme.spacing(0, -3, -4, -3),
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    width: `calc(100% + ${theme.spacing(7)}px)`,
  },
  searchTitle: {
    textAlign: 'center',
    padding: theme.spacing(1, 7, 4),
  },
  searchContainer: {
    overflowY: 'scroll',
    height: '320px',
    alignContent: 'flex-start',
  },
}));

type IProps<T> = {
  redirectOnSearch?: boolean;
  modelType: string;
  searchResults: T[];
  setSelected: (property: Partial<T>) => void;
};

export default function Search<T extends BaseModel>({
  modelType,
  searchResults,
  setSelected,
  redirectOnSearch = false,
}: IProps<T>): ReactElement<IProps<T>> {
  const classes = useStyles();
  const modelName = toTitleCase(modelType);
  // FIXME: Figure out how to access static properties on T
  const modelNamePlural = modelType === Property.modelName ? 'Properties' : `${modelName}s`;
  const [startIndex, setStartIndex] = useState<number>(0);
  const [resultRows, setResultRows] = useState<T[]>([]);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(true);
  const loadResults = useCallback(
    (startIndex: number) => {
      const pageResults = searchResults.slice(startIndex, startIndex + DEFAULT_PAGE_SIZE);
      setResultRows(rows => [...rows, ...pageResults]);
      if (pageResults.length < DEFAULT_PAGE_SIZE) {
        setHasMoreRows(false);
      }
    },
    [searchResults],
  );
  useEffect(() => {
    loadResults(startIndex);
  }, [startIndex, loadResults]);

  return (
    <Box pl={3} pr={4} py={4} component={Paper}>
      <Grid item className={classes.searchTitle}>
        <Typography variant="subtitle2">Select Existing {modelName} or Create New</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {searchResults.length > 0
            ? `Existing ${modelNamePlural}`
            : `No Matching ${modelNamePlural} Found`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="space-between">
        <InfiniteScroll
          dataLength={resultRows.length}
          next={() => setStartIndex(startIndex => startIndex + DEFAULT_PAGE_SIZE)}
          hasMore={hasMoreRows}
          loader={<Loader />}
          style={{
            height: '310px',
          }}
          scrollableTarget="searchContainer"
        >
          <Grid
            container
            className={classes.searchContainer}
            spacing={2}
            justify="flex-start"
            id="searchContainer"
          >
            {resultRows.map((result, idx) => (
              <Grid item key={result.id} onClick={() => setSelected(result)} xs={12}>
                {modelType === Property.modelName ? (
                  <LeadCard
                    property={(result as unknown) as Property}
                    clickable={redirectOnSearch}
                  />
                ) : modelType === Stakeholder.modelName ? (
                  <StakeholderCard
                    stakeholder={(result as unknown) as Stakeholder}
                    clickable={redirectOnSearch}
                    isOdd={!(idx % 2)}
                  />
                ) : (
                  <OrganizationCard
                    organization={(result as unknown) as Organization}
                    clickable={redirectOnSearch}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.createButton}
            type="submit"
          >
            Create New {modelName}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
