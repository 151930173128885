import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { displayTypeOrSource, RadioOptions } from './DropDownOptionsConfig';
import useDropDownOptionsStyles from './DropDownOptions.style';

import { MapObjectSourceState, MapObjectType } from '../../../../state/atoms/interactiveMap';
import useDebounce from '../../../../utils/debounce';
import { primary } from '../../../../theme';

interface IProps {
  config: RadioOptions[];
  setRadioValue: React.Dispatch<React.SetStateAction<MapObjectType>>;
  radioValue: MapObjectType;
  setCheckboxState: React.Dispatch<React.SetStateAction<MapObjectSourceState>>;
  checkboxState: MapObjectSourceState;
  setLoadNewData: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDownOptions: React.FC<IProps> = ({
  config,
  setRadioValue,
  radioValue,
  setCheckboxState,
  checkboxState,
  setLoadNewData,
}) => {
  const classes = useDropDownOptionsStyles();

  const defaultCheckboxedState: MapObjectSourceState = {
    dodge: false,
    cmd: false,
    // cw: false,
  };

  // Click all sources when project leads is clicked
  const defaultPLCheckboxedState: MapObjectSourceState = {
    dodge: true,
    cmd: true,
    // cw: true,
  };
  const [accordionOpen, setAccordionOpen] = React.useState(false);
  // Use for debounce action
  const [load, setLoad] = React.useState(false);
  const [isInput, setIsInput] = React.useState(false);
  const [summary, setSummary] = React.useState(['project leads', primary]);
  const debouncedLoad = useDebounce(load, 1250);

  useEffect(() => {
    if (debouncedLoad) {
      setLoadNewData(true);
      setLoad(false);
      setAccordionOpen(false);
    }
  }, [debouncedLoad, setLoadNewData]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  Make sure radio button value is parent of checkbox option
    const newRadioValue = MapObjectType.ProjectLeads;
    if (radioValue !== newRadioValue) {
      //  using title cased value of MapObjectType
      setRadioValue(newRadioValue as MapObjectType);
    }

    const updatedCheckboxState = { ...checkboxState, [e.target.name]: e.target.checked };
    setCheckboxState(updatedCheckboxState);
    setLoad(true);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (e.target as HTMLInputElement).value as MapObjectType;
    setRadioValue(selectedValue);
    if (selectedValue === MapObjectType.ProjectLeads) {
      setCheckboxState((defaultPLCheckboxedState as unknown) as MapObjectSourceState);
    } else {
      setCheckboxState((defaultCheckboxedState as unknown) as MapObjectSourceState);
    }

    setLoad(true);
  };

  const closeAccordion = () => {
    if (!isInput) {
      setAccordionOpen(false);
    }
  };

  const onAccordionClick = () => {
    const activeEl = document.activeElement?.tagName;
    if (activeEl === 'INPUT') {
      setIsInput(true);
    }
  };

  const radioOptions = config.map((option, idx) => {
    // Map out the radio buttons
    return (
      <Grid
        container
        className={classes.section}
        style={{
          backgroundColor: option?.bgColor,
        }}
        key={`${option.value}-${idx}`}
      >
        <FormControlLabel
          aria-label={'Project Leads'}
          control={<Radio color={'primary'} />}
          value={option.value}
          label={option.label}
          className={classes.radio}
          key={`${option.value}-${idx}`}
        />
        {option.checkboxOptions &&
          //  If radio buttons have checkboxes, render them here
          option.checkboxOptions.map((checkboxOption, idx) => {
            return (
              <FormControlLabel
                key={`{${checkboxOption.value}-${idx}`}
                className={classes.checkmark}
                control={
                  <Checkbox
                    name={checkboxOption.value}
                    onChange={handleCheckboxChange}
                    checked={checkboxState[checkboxOption.value]}
                    color={'primary'}
                  />
                }
                label={checkboxOption.label}
              />
            );
          })}
      </Grid>
    );
  });

  useEffect(() => {
    setSummary(displayTypeOrSource(radioValue, checkboxState));
  }, [radioValue, checkboxState, setSummary]);

  useEffect(() => {
    if (accordionOpen) {
      setIsInput(false);
    }
  }, [accordionOpen, setIsInput]);

  return (
    <Box className={classes.root}>
      <Accordion
        expanded={accordionOpen}
        onChange={() => setAccordionOpen(!accordionOpen)}
        className={classes.accordion}
        onBlur={() => setTimeout(() => closeAccordion(), 500)}
        onClick={onAccordionClick}
      >
        <AccordionSummary
          className={classes.summary}
          style={{ color: summary[1] }}
          expandIcon={<ExpandMoreIcon style={{ padding: 0, color: 'black' }} />}
        >
          {summary[0]}
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup value={radioValue} onChange={handleRadioChange} style={{ width: '100%' }}>
            {radioOptions}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DropDownOptions;
