import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';

export type IUserResponse = IBaseModelResponse & {
  id: string;
  full_name?: string;
  username: string;
  email: string;
};

export default class User extends BaseModel {
  fullName?: string;
  username: string;
  email: string;

  constructor(data: IUserResponse) {
    super(data);
    const { full_name, username, email } = data;
    this.fullName = full_name;
    this.username = username;
    this.email = email;
  }

  get displayName(): string {
    return this.fullName || this.username;
  }
}

export interface emptyUserDisplayName {
  displayName: string;
}
