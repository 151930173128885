import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export const primary = '#59C9BC';
export const secondary = '#F4F9FC';
export const success = '#1FB4B1';
export const error = '#F03434';
export const info = '#4E9AF3';
export const warning = '#FAF697';
export const additional = '#7A76F7';
export const white = 'white';
export const primaryBlue = '#0C4377';
export const darkBlue = '#172D50';
export const black = '#1A1A1A';
export const darkGray = '#737579';
export const gray = '#BCBDC0';
export const light = '#E3ECF6';
export const darkLight = '#8FB1D7';
export const paleGray = '#F9FBFD';
export const lightGray = '#DFE2E1';
export const lightGreen = '#EAF5F3';
export const purple = '#999AF6';
export const hyperlinkBlue = '#0066CC';
export const gold = '#DAA520';
export const foundationBlue = '#051E36';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryBlue,
      contrastText: secondary,
    },
    background: {
      default: secondary,
    },
    secondary: {
      main: secondary,
      contrastText: primaryBlue,
    },
    success: {
      main: success,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
  },
  typography: {
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: '2.625rem',
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'normal',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'normal',
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
    button: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '0.6875rem',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
  },
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: primary,
        cursor: 'pointer',
        fontSize: '11px',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
      },
      textPrimary: {
        color: primary,
      },
      outlinedPrimary: {
        color: primary,
        backgroundColor: 'inherit',
        border: `1px solid ${primary}`,
        '&:hover': {
          backgroundColor: secondary,
          border: `1px solid ${primary}`,
        },
      },
      containedPrimary: {
        color: secondary,
        backgroundColor: primary,
        '&:hover': {
          backgroundColor: primary,
        },
      },
      containedSizeLarge: {
        height: '36px',
        fontSize: '11px',
        '&:hover': {
          backgroundColor: primary,
          border: `1px solid ${primary}`,
        },
      },
      containedSizeSmall: {
        height: '24px',
        fontSize: '10px',
      },
      outlinedSizeLarge: {
        height: '36px',
        fontSize: '11px',
        '&:hover': {
          backgroundColor: secondary,
          border: `1px solid ${primary}`,
        },
      },
      outlinedSizeSmall: {
        height: '24px',
        fontSize: '10px',
        '&:hover': {
          backgroundColor: secondary,
          border: `1px solid ${primary}`,
        },
      },
    },
    MuiMenu: {
      paper: {
        padding: [[0, 16]],
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: darkGray,
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: 'transparent',
        color: darkBlue,
        fontWeight: 'bold',
        borderRadius: 5,
        // borderLeft: '5px solid transparent',
        fontSize: '0.85rem',
        padding: '1px 8px !important',
        margin: [[8, 0]],
        '&:hover': {
          backgroundColor: light,
        },
        '&$selected': {
          backgroundColor: light,
          borderRadius: 0,
          borderColor: primary,
        },
        '&.active': {
          backgroundColor: 'transparent !important',
          borderRadius: 0,
          color: primary,
          borderColor: primary,
        },
        '@media (max-width: 600px)': {
          minHeight: 'auto',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'white',
          border: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          border: '1px solid #0c4377',
        },
        '&:hover $notchedOutline': {
          border: '1px solid #0c4377',
        },
        '&$focused $notchedOutline': {
          border: '3px solid #0c4377',
        },
      },
      focused: {},
      notchedOutline: {},
    },
    MuiTabs: {
      indicator: {
        backgroundColor: primaryBlue,
      },
    },
    MuiTab: {
      root: {
        color: light,
        fontSize: '17px',
        fontWeight: 'Bold',
        borderRadius: '0 !important',
        textTransform: 'none',
        borderBottomColor: primaryBlue,
        '&:hover': {
          color: white,
        },
        '&$selected': {
          color: white,
          overflow: `hidden`,
          '& $wrapper': {
            borderColor: primary,
          },
        },
        '@media (min-width: 600px)': {
          minWidth: 72,
        },
        '@media (max-width: 600px)': {
          minWidth: 'auto',
        },
      },
      textColorPrimary: {
        color: secondary,
        '&:hover': {
          color: primary,
          backgroundColor: primaryBlue,
        },
        '&$selected': {
          color: primary,
          backgroundColor: primaryBlue,
        },
      },
      wrapper: {
        borderLeft: `3px solid ${darkLight}`,
        paddingLeft: '5px',
        height: '18px',
        flexDirection: 'row',
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          fontWeight: 'bold',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 'bold',
        color: primaryBlue,
        '&$focused': {
          color: darkBlue,
          backgroundColor: paleGray,
        },
      },
    },
    MuiSwitch: {
      root: {
        color: lightGray,
      },
      colorPrimary: {
        '&$checked': {
          color: primary,
          '& + $track': {
            backgroundColor: primary,
          },
        },
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: primary,
        color: white,
      },
      colorSecondary: {
        backgroundColor: secondary,
        color: darkLight,
      },
      outlinedPrimary: {
        backgroundColor: white,
        color: primary,
        borderColor: primary,
      },
      outlinedSecondary: {
        backgroundColor: white,
        borderColor: darkLight,
      },
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: black,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle',
      },
      colorPrimary: {
        color: primary,
      },
      colorSecondary: {
        color: primaryBlue,
      },
      fontSizeSmall: {
        width: '8px',
        height: '8px',
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: primary,
      },
      colorSecondary: {
        color: darkGray,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: primary,
        width: '42px',
      },
      colorSecondary: {
        color: primaryBlue,
        width: '42px',
      },
    },
  },
});

export default theme;
