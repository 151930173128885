import React from 'react';
import {
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';

import completedIcon from '../../resources/images/StepIndicator/step-indicator.svg';
import emptyIcon from '../../resources/images/StepIndicator/step-indicator-empty.svg';
import emptyIconLast from '../../resources/images/StepIndicator/step-indicator-empty-last.svg';
import bidIcon from '../../resources/images/StepIndicator/biddate-indicator.svg';
import emptyBidIcon from '../../resources/images/StepIndicator/empty-biddate-indicator.svg';
import { black, gray, success, white } from '../../theme';

const useStyles = makeStyles(() => ({
  baseLayer: {
    height: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  topLayer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    alignItems: 'center',
  },
}));

export enum Stages {
  PreDesign = 'PREDESIGN',
  Design = 'DESIGN',
  Bidding = 'BIDDING',
  Construction = 'CONSTRUCTION',
  Operation = 'OPERATION',
}

const CleoLinearProgress = withStyles(() =>
  createStyles({
    colorPrimary: {
      backgroundColor: white,
      border: `1px solid ${success}`,
    },
    bar: {
      backgroundColor: success,
      border: `1px solid ${success}`,
    },
  }),
)(LinearProgress);

interface Step {
  key: Stages;
  label: string;
}

export const actionStageSteps: Step[] = [
  { key: Stages.PreDesign, label: 'Pre-Design' },
  { key: Stages.Design, label: 'Design' },
  { key: Stages.Bidding, label: 'Bidding' },
  { key: Stages.Construction, label: 'Construction' },
  { key: Stages.Operation, label: 'Operation' },
];

interface IStepIndicatorProps {
  stage?: Stages;
  steps?: Step[];
  bidDate?: string;
}

const StepIndicator: React.FC<IStepIndicatorProps> = ({
  stage,
  steps = actionStageSteps,
  bidDate,
}) => {
  const classes = useStyles();
  const completedStageIndex = steps.findIndex(s => s.key === stage); // Step number completed

  if (completedStageIndex < 0) {
    return <></>;
  }

  const progressPercentage = (completedStageIndex / (steps.length - 1)) * 100; // Percentage progress bar should be filled

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/*Items to appear above the step indicator*/}
      <Grid
        style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        {steps.map((step, idx) => {
          const isCompleted = idx <= completedStageIndex;
          const isBidding = bidDate && step.key === Stages.Bidding;
          return (
            <Grid
              key={`${step.label}-${idx}`}
              style={{
                color: isCompleted ? black : gray,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // spaces the labels out correctly per progress bar
              }}
            >
              {isBidding && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: success,
                    width: '10.75rem',
                    color: black,
                    marginBottom: '.75rem',
                    borderRadius: '4px',
                    padding: '.5rem 0',
                    zIndex: 1,
                  }}
                  variant={'body1'}
                >
                  Bid Date: <strong>&nbsp; {bidDate}</strong>
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid>
        <Grid className={classes.baseLayer}>
          <CleoLinearProgress
            value={progressPercentage}
            variant={'determinate'}
            style={{ margin: `0 ${50 / steps.length}%` }} // Adds appropriate margin so that labels show correctly
          />
          <Grid
            className={classes.topLayer}
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            {steps.map((step, idx) => {
              const isCompleted = idx <= completedStageIndex;
              const isBidding = step.key === Stages.Bidding && bidDate;
              const icon =
                isCompleted && isBidding
                  ? bidIcon
                  : isCompleted && !isBidding
                  ? completedIcon
                  : !isCompleted && isBidding
                  ? emptyBidIcon
                  : !isCompleted && idx === steps.length - 1
                  ? emptyIconLast
                  : emptyIcon;
              return (
                <Grid
                  key={`${step.label}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: `${100 / steps.length}%`, // spaces the icons out correctly per progress bar
                  }}
                >
                  <img
                    src={icon}
                    alt={`${step.label} ${isCompleted ? 'completed' : 'incomplete'}`}
                    height={`${icon === bidIcon || icon === emptyBidIcon ? '42px' : '24px'}`}
                    style={
                      icon === bidIcon || icon === emptyBidIcon ? { marginBottom: '1.05rem' } : {}
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          {steps.map((step, idx) => {
            const isCompleted = idx <= completedStageIndex;
            return (
              <Grid
                key={`${step.label}-${idx}`}
                style={{
                  color: isCompleted ? black : gray,
                  display: 'flex',
                  flexDirection: 'column',
                  width: `${100 / steps.length}%`, // spaces the labels out correctly per progress bar
                }}
              >
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  variant={'body1'}
                >
                  {step.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepIndicator;
