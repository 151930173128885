export const removeFalsyValues = (obj: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(obj).reduce((cleanedObj, [key, value]) => {
    if (!value) {
      return cleanedObj;
    }

    return {
      ...cleanedObj,
      [key]: value,
    };
  }, {});

export const getArrayKeys = (obj: Record<string, unknown>): string[] => {
  if (Array.isArray(obj)) {
    return [];
  }
  const keysArr = [];
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (Array.isArray(obj[key])) {
      keysArr.push(key);
    }
  }
  keysArr.push('');
  return keysArr;
};
