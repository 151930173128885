import React from 'react';
import { Checkbox, Grid, IconButton, Typography } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { useRecoilState } from 'recoil';

import AccountCardIcons from './AccountCardIcons';
import AccountCardStyle from './AccountCard.style';

import { AccountListAccount } from '../../../../models';
import { black, hyperlinkBlue } from '../../../../theme';
import {
  accountsMergeIdsSelectedState,
  accountsMergeIdState,
} from '../../../../state/atoms/accounts';

interface IAccountCard {
  account: AccountListAccount;
  isSelected: boolean;
  onClick: (params: any) => any;
}

const AccountCard: React.FC<IAccountCard> = ({ onClick, isSelected, account }) => {
  const classes = AccountCardStyle();
  const [accountsMergeId, setAccountsMergeId] = useRecoilState(accountsMergeIdState);
  const [selectedMergeIds, setSelectedMergeIds] = useRecoilState(accountsMergeIdsSelectedState);

  const {
    name,
    is_national,
    is_targeted_national,
    is_favorite,
    is_new,
    sf_rating,
    is_spec_alert,
    is_government,
    is_education,
  } = account;

  const handleMergeSelection = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedMergeIds.has(account.id)) {
      setSelectedMergeIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(account.id);
        return newSet;
      });
    } else {
      setSelectedMergeIds(prev => {
        return new Set(prev).add(account.id);
      });
    }
  };

  const icons = (
    <AccountCardIcons
      sfRating={sf_rating || ''}
      isNationalAccount={is_national}
      isTargetedNationalAccount={is_targeted_national}
      isEducation={is_education}
      isSpecAlert={is_spec_alert}
      isGovernment={is_government}
      isNew={false}
    />
  );

  return (
    <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Grid
        className={classes.root}
        style={
          isSelected
            ? { border: '1.5px solid #0C4377' }
            : !isSelected && !accountsMergeId
            ? { opacity: '50%' }
            : undefined
        }
        onClick={onClick}
      >
        {is_new && (
          <Grid className={classes.newBanner}>
            <Typography variant="caption" className={classes.newText}>
              <strong>NEW</strong>
            </Typography>
          </Grid>
        )}
        <Grid className={classes.topRow}>
          <Typography variant={'subtitle1'}>{name}</Typography>
          <Grid className={classes.actions}>
            {!accountsMergeId && (
              <Typography
                className={classes.action}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: hyperlinkBlue,
                }}
                onClick={() => setAccountsMergeId(account.id)}
              >
                View Duplicate Accounts
              </Typography>
            )}
            <IconButton
              aria-label={'Add Accounts Favorite'}
              component="span"
              className={classes.action}
            >
              {is_favorite ? <Favorite color={'error'} /> : <FavoriteBorder color={'error'} />}
            </IconButton>
            {accountsMergeId && (
              <Checkbox
                className={classes.action}
                style={{ padding: 0, color: black }}
                onClick={handleMergeSelection}
                inputProps={{ 'aria-label': `Merge Account id: ${account.id}` }}
                checked={selectedMergeIds.has(account.id)}
              />
            )}
          </Grid>
        </Grid>
        <Grid className={classes.body}>
          <Grid className={classes.icons}>{icons}</Grid>
        </Grid>
      </Grid>
      <Grid className={classes.connect}>{isSelected && <hr className={classes.hr} />}</Grid>
    </Grid>
  );
};

export default AccountCard;
