import API, { APICancelToken, IResponse } from './api';

import { MapDisplayLevel, MapObjectSource, MapObjectType } from '../state/atoms/interactiveMap';
import { IOrganizationResponse, Organization } from '../models';

interface IMapRequest {
  objectType: MapObjectType;
  objectSources: MapObjectSource[];
  displayLevel: MapDisplayLevel;
  locationName: string;
  locationState?: string;
}

export interface IMapResponse {
  objectType: MapObjectType;
  locationName: string;
  item: GeoJSON.FeatureCollection;
}

export interface IMapsDrawerAccountsRequest {
  startIndex: number;
  limit?: number;
  locationName: string;
  displayLevel: string;
  locationState?: string;
}

export const getMapItems = async (requestObj: IMapRequest): Promise<IMapResponse> => {
  const preppedSources = JSON.stringify(requestObj.objectSources).replace(/"/g, "'");
  const params = { ...requestObj, objectSources: preppedSources };

  const { data } = await API.get('/maps', {
    params,
  });

  return data as IMapResponse;
};

export const getMapDrawerAccounts = async (
  { startIndex, limit, locationName, displayLevel, locationState }: IMapsDrawerAccountsRequest,
  cancelToken?: APICancelToken,
): Promise<IResponse<Organization>> => {
  const params = {
    locationState,
    locationName,
    displayLevel,
    limit,
    offset: startIndex,
  };

  const { data } = await API.get('/maps/accounts', {
    params,
    cancelToken,
  });
  const accounts = (data as IResponse<IOrganizationResponse>).items.map(
    item => new Organization(item),
  );

  return { items: accounts, total: data.total };
};
