import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

import { light } from '../../theme';

const useStyles = makeStyles({
  root: {
    borderBottom: (props: IProps) => `1px solid ${props.dividerColor ? props.dividerColor : light}`,
    minHeight: '55px',
  },
});

type IProps = {
  className?: string;
  dividerColor?: string;
};

const PaperHeader: React.FC<IProps> = ({ className, dividerColor, children }) => {
  const classes = useStyles({ dividerColor });
  return (
    <Box p={3} className={clsx(classes.root, className)}>
      {children}
    </Box>
  );
};

export default PaperHeader;
