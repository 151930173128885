import React from 'react';
import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

import { SectionHeader, Title } from '../../Common/StyleGuideStyles';
import Edit from '../../../resources/images/cleo-logo.png';
import Edit2 from '../../../resources/images/cleo-logo-wh.png';
import { primaryBlue, light } from '../../../theme';

const StyledImage = styled('img')({
  height: 50,
  minWidth: 206,
});

const StyledPrimaryBlueBox = styled(Box)({
  backgroundColor: primaryBlue,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  minWidth: 206,
  margin: '10px',
});

const StyledPrimaryBox = styled(Box)({
  backgroundColor: light,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  minWidth: 206,
  margin: '10px',
});

/**
 * This files servers for representing logo icons used in the application
 */
export default function IconStyle(): JSX.Element {
  return (
    <div>
      <SectionHeader>Icon Layout</SectionHeader>
      <Title>Logo Icons</Title>
      <Grid container>
        <StyledPrimaryBox>
          <StyledImage src={Edit} alt="Sample Logo" />
        </StyledPrimaryBox>
        <StyledPrimaryBlueBox>
          <StyledImage src={Edit2} alt="Sample Logo Alternative" />
        </StyledPrimaryBlueBox>
      </Grid>
    </div>
  );
}
