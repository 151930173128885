import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { black, lightGray, primary, white } from '../../theme';

export type CleoButtonProps = ButtonProps & {
  // name: string;
  primary?: boolean;
};

const useStyles = makeStyles(() => ({
  button: {
    padding: '.25 1rem',
    borderRadius: '4px',
    alignItems: 'center',
  },
  primary: {
    backgroundColor: primary,
    color: black,
  },
  secondary: {
    backgroundColor: white,
    color: primary,
    border: `1px solid ${primary}`,
  },
  disabled: {
    backgroundColor: lightGray,
    color: black,
  },
}));

const CleoButton: React.FC<CleoButtonProps> = ({
  primary = true,
  // name,
  disabled = false,
  onClick,
  children,
  ...props
}) => {
  const classes = useStyles();
  const buttonStyle = primary ? classes.primary : classes.secondary;
  return (
    <Button
      className={
        disabled ? clsx(classes.button, classes.disabled) : clsx(classes.button, buttonStyle)
      }
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CleoButton;
