import mapboxgl, { Map } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import ZoomOut from '@material-ui/icons/Remove';
import ZoomIn from '@material-ui/icons/Add';

import { createMapLayer, hideMapLayer, showMapLayer, zoomOut } from './Layers/createMapLayer';
import { createHoverLayer } from './Layers/createHoverLayers';
import canadaMap from './Layers/canadaProvinces.json';

import { MAPBOX_ACCESS_TOKEN } from '../../../../constants';
import {
  MapDisplayLevel,
  MapObjectSource,
  MapObjectSourceState,
  MapObjectType,
} from '../../../../state/atoms/interactiveMap';
import { listTrueValues } from '../Summary/MapSummary';
import StatisticsContainer from '../Statistics/StatisticsContainer';
import DataDropDown from '../DropDown/DataDropDown';
import DrawerList from '../DrawerList/DrawerList';
import useAtAGlanceStyles from '../AtAGlance.style';
import { getMapItems, IMapResponse } from '../../../../api/interactiveMap';
import { displayTypeOrSource } from '../DropDown/DropDownOptionsConfig';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
const MapBoxPage: React.FC = () => {
  const map = useRef<Map | null>(null);
  const lastLayerId = useRef<string>('initial');
  const memo = useRef<Record<string, boolean>>({});
  const mapContainer = useRef(null);
  const defaultLong = -100;
  const defaultLat = 50;
  const defaultZoom = 3.4;
  const [lng, setLng] = useState<number>(defaultLong);
  const [lat, setLat] = useState<number>(defaultLat);
  const [zoom, setZoom] = useState<number>(defaultZoom);

  const [loadNewData, setLoadNewData] = useState<boolean>(true);
  const [mapDisplayLevel, setMapDisplayLevel] = useState<MapDisplayLevel>(MapDisplayLevel.Country);
  const [locationName, setLocationName] = useState<string>('USA');
  const [zoomOutLocation, setZoomOutLocation] = useState<string[]>([]);
  const [objectType, setObjectType] = useState<MapObjectType>(MapObjectType.ProjectLeads);
  const [objectSource, setObjectSource] = useState<MapObjectSourceState>({
    dodge: true,
    cmd: true,
    // cw: false,
  } as MapObjectSourceState);
  const [openDrawer, setOpenDrawer] = useState(true);

  const getUniqueLayerId = useCallback((): string => {
    const level = `${objectType}-${mapDisplayLevel}`;
    const locations = zoomOutLocation.join('-');
    const sources = listTrueValues(objectSource).join('-');
    return [level, sources, locations, locationName].filter(Boolean).join('-');
  }, [objectType, locationName, mapDisplayLevel, zoomOutLocation, objectSource]);

  const loadData = useCallback(async () => {
    if (!map.current) {
      return;
    }
    const uniqueId = getUniqueLayerId();
    // Hide last layer before creating / showing different layer
    hideMapLayer({ map: map.current, layerId: lastLayerId.current });
    lastLayerId.current = uniqueId;
    // Keep track of layers already created
    if (memo.current[uniqueId]) {
      showMapLayer({ map: map.current, layerId: uniqueId });
      return;
    }
    if (loadNewData) {
      const locationState = zoomOutLocation[1] || undefined;
      const params = {
        objectType: encodeURIComponent(objectType) as MapObjectType,
        objectSources: listTrueValues(objectSource) as MapObjectSource[],
        displayLevel: mapDisplayLevel,
        locationName: encodeURIComponent(locationName.trim()),
        locationState: locationState ? encodeURIComponent(locationState.trim()) : undefined,
      };
      const data: IMapResponse = await getMapItems(params);

      const createSourceAndLayer = () => {
        /**
         * If API returns data too quickly (on initial load),
         * mapbox styles will not finish loading before applying layer.
         * Therefore, wait until load process is complete
         */
        if (!map.current?.isStyleLoaded() || !data) {
          setTimeout(createSourceAndLayer, 200);
        } else {
          if (map.current && data) {
            //  Use try catch block, else it tries to load twice and app crashes
            try {
              const color = displayTypeOrSource(objectType, objectSource)[1];

              const params = {
                map: map.current,
                color: color,
                sourceId: getUniqueLayerId(),
                layerId: getUniqueLayerId(),
                data: data,
                setLoadNewData,
                setMapDisplayLevel,
                setLocationName,
                mapDisplayLevel,
                setZoomOutLocation,
              };
              createMapLayer(params);
              memo.current[uniqueId] = true;
            } catch (err) {
              console.error('There was an error on making new layers: ', err);
            }
          }
        }
      };
      createSourceAndLayer();
    }
  }, [
    locationName,
    objectType,
    objectSource,
    mapDisplayLevel,
    loadNewData,
    zoomOutLocation,
    memo,
    getUniqueLayerId,
  ]);

  /**
   * Initializing Mapbox Map - base layer
   * */
  useEffect(() => {
    if (map.current) {
      return;
    } // initialize map only once
    map.current = new mapboxgl.Map({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: zoom,
      minZoom: 3,
      maxZoom: 11,
      logoPosition: 'bottom-left',
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
  });

  /**
   * Handles Position and Zoom State
   * */
  useEffect(() => {
    if (!map.current) {
      return;
    } // wait for map to initialize
    map.current.on('move', () => {
      setLng(parseInt(map.current?.getCenter().lng.toFixed(4) || `${defaultLong}`));
      setLat(parseInt(map.current?.getCenter().lat.toFixed(4) || `${defaultLat}`));
      setZoom(parseInt(map.current?.getZoom().toFixed(2) || `${defaultZoom}`));
    });
  });

  /**
   * On Map load, create hover effect layer for states and provinces
   * */
  useEffect(() => {
    // wait for map to initialize
    if (!map.current) {
      return;
    }

    map.current.on('load', () => {
      if (map.current) {
        try {
          createHoverLayer({
            map: map.current,
            sourceId: 'us-states',
            layerId: 'states-layer',
            sourceData: 'https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson',
          });
          createHoverLayer({
            map: map.current,
            sourceId: 'canada-provinces',
            layerId: 'provinces-layer',
            sourceData: canadaMap as GeoJSON.FeatureCollection,
          });
        } catch (err) {
          console.log('Hover states already exist: ', err);
        }
      }
    });
  }, [mapDisplayLevel]);

  /**
   * Handles loading of new data when triggered
   * */
  useEffect(() => {
    if (!map.current) {
      return;
    }
    if (loadNewData) {
      setLoadNewData(false);
      loadData().catch();
    }
  }, [loadData, loadNewData]);

  const classes2 = useAtAGlanceStyles();
  return (
    <Container maxWidth="xl" style={{ padding: 0, margin: 0 }}>
      <Box className={classes2.overlay}>
        <Grid className={classes2.group1}>
          <StatisticsContainer className={classes2.stats} />
          <DataDropDown
            className={classes2.dropDown}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
            setRadioValue={setObjectType}
            radioValue={objectType}
            setCheckboxState={setObjectSource}
            checkboxState={objectSource}
            setLoadNewData={setLoadNewData}
            mapDisplayLevel={mapDisplayLevel}
          />
        </Grid>
        <DrawerList
          className={classes2.drawer}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          mapDisplayLevel={mapDisplayLevel}
          locationName={locationName}
          objectType={objectType}
          objectSource={objectSource}
          zoomOutLocations={zoomOutLocation}
        />
      </Box>
      <Grid container spacing={0} direction="column">
        <Grid item xs>
          <div>
            <Grid className={classes2.zoomContainer}>
              <ZoomIn
                className={classes2.zoomInOut}
                onClick={() => {
                  if (map.current) {
                    const zoomLevel = map?.current?.getZoom() * 1.25;
                    setZoom(zoomLevel);
                    map.current?.zoomTo(zoomLevel);
                  }
                }}
              />
              <ZoomOut
                className={classes2.zoomInOut}
                onClick={() => {
                  if (!zoomOutLocation.length && map.current) {
                    //  At the outermost zoom level, so naturally zoom out
                    const zoomLevel = map?.current?.getZoom() * 0.8;
                    setZoom(zoomLevel);
                    map.current?.zoomTo(zoomLevel);
                    return;
                  }
                  //  Take last item from zoomOutLocations to set as newLocation
                  const newLocation = zoomOutLocation.slice(-1)[0];
                  zoomOut({
                    map: map.current,
                    sourceId: getUniqueLayerId(),
                    layerId: getUniqueLayerId(),
                    mapDisplayLevel,
                    newLocation,
                    setMapDisplayLevel,
                    setLocationName,
                    setLoadNewData,
                  });
                  setZoomOutLocation(zoomOutLocation.slice(0, -1));
                }}
              />
            </Grid>
            <div ref={mapContainer} className={classes2.mapContainer} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MapBoxPage;
