import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import { SourceType } from '../../models';
import { toTitleCase } from '../../utils/string';

interface ISalesforceIcon {
  salesforceLogo: string;
  size: string;
  sourceLabel: string;
  sourceName: string;
}

interface ICleoIcon {
  cleoLogo: string;
  size: string;
  sourceLabel: string;
  sourceName: string;
  salesforceCollision: boolean;
}

const useSourceStyles = makeStyles({
  icon: {
    border: '100px 100px 100px 100px',
  },
  toolTipIcon: {
    maxWidth: '5rem',
  },
});

export const RenderCleoIcon = ({
  cleoLogo,
  size,
  sourceLabel,
  sourceName,
  salesforceCollision,
}: ICleoIcon): JSX.Element => {
  const classes = useSourceStyles();
  const cleoToolTip = () => {
    if (sourceLabel === SourceType.Dodge) {
      return `Source comes from Dodge`;
    } else {
      const city = sourceName?.split('_') || [];
      city.pop();
      const cityString = city.join(' ');
      return `Source comes from ${toTitleCase(cityString || '')} ${toTitleCase(sourceLabel || '')}`;
    }
  };
  return !salesforceCollision ||
    sourceLabel !== 'CONTACTS' ||
    sourceName !== 'SALESFORCE_WEEKLY_STAKEHOLDERS' ? (
    <Tooltip title={cleoToolTip()} placement="top" arrow classes={{ tooltip: classes.toolTipIcon }}>
      <img
        src={cleoLogo}
        alt={'Cleo'}
        style={{ width: size, margin: '0px 10px' }}
        className={classes.icon}
      />
    </Tooltip>
  ) : (
    <></>
  );
};

export const RenderSalesforceIcon = ({
  salesforceLogo,
  size,
  sourceLabel,
  sourceName,
}: ISalesforceIcon): JSX.Element => {
  return sourceLabel === 'CONTACTS' && sourceName === 'SALESFORCE_WEEKLY_STAKEHOLDERS' ? (
    <img src={salesforceLogo} alt={'Salesforce'} style={{ width: size }} />
  ) : (
    <></>
  );
};
