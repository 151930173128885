import {
  IDodgeProjectLeadResponse,
  IEventResponse,
  IProjectResponse,
  IUserResponse,
} from './index';

import Event from './event';

import { IProject } from '../Components/Pages/Leads/Archive/Archive.config';
import { IEventWithIProjectLead } from '../Components/Pages/ProjectLeadDetails/ProjectLeadDetailsTab/DataSourceItem';

export enum ProjectLeadsStatusTab {
  Details = 'details',
  Addresses = 'addresses',
  Documents = 'documents',
  Notes = 'notes',
}

export enum ProjectLeadStatus {
  Home = 'home',
  Favorites = 'favorites',
  SavedFilters = 'savedFilters',
  Archive = 'archive',
  Hidden = 'hidden',
}

export enum ProjectLeadStatusNonTabs {
  Account = 'account',
}

export interface IProjectLeadDetails {
  projectDetails?: IProjectResponse;
  pvf?: IProject;
  eventsOnActiveProject?: IEventWithIProjectLead[];
  eventsOnProperty?: IEventWithIProjectLead[];
  incrementalReason?: string[];
  users?: IUserResponse[];
}

// Project Leads Class that is created from combining IEvent and IProjectLead

export default class ProjectLead extends Event {
  eventId?: string;
  propertyId?: string;
  dateOfFirstExport?: Date;
  dateOfLastExport?: Date;
  dateOfCurrentExport?: Date;
  firstIssueDate?: Date;
  lastIssueDate?: Date;
  reportDate?: Date;
  primaryStage?: string;
  secondaryStage?: string;
  bidDate?: Date;
  submitBidsTo?: string;
  targetStartDate?: Date;
  targetCompletionDate?: Date;
  valuationEstLow?: string;
  valuationEstHigh?: string;
  valuation?: string;
  marketSegment?: string;
  primaryProjectType?: string;
  secondaryProjectType?: string;
  totalSqft?: string;
  typeOfWork?: string;
  ownershipType?: string;
  statusText?: string;
  bidBond?: string;
  specialCode?: string;
  plansAvailableFrom?: string;
  architecturalPlansBy?: string;
  structuralPlansBy?: string;
  plansRemark?: string;
  plansLocation?: string;
  plansAvailable?: string;
  deliverySystem?: string;
  frameType?: string;
  storiesAbove?: string;
  storiesBelow?: string;
  noOfBuildings?: string;
  structuralInfo?: string;
  specAlerts?: string;
  searchNames?: string;
  projectNote?: string;
  projectTitle?: string;
  specAvailable?: string;
  features_info?: string;
  projectUrl?: string;
  constructor(event: IEventResponse, projectLead?: IDodgeProjectLeadResponse) {
    super(event);
    if (!projectLead) {
      return;
    }
    const {
      event_id,
      property_id,
      date_of_first_export,
      date_of_last_export,
      date_of_current_export,
      first_issue_date,
      last_issue_date,
      report_date,
      primary_stage,
      secondary_stage,
      bid_date,
      bid_bond,
      submit_bids_to,
      target_start_date,
      target_completion_date,
      valuation_est_low,
      valuation_est_high,
      valuation,
      market_segment,
      primary_project_type,
      secondary_project_type,
      total_sqft,
      type_of_work,
      ownership_type,
      status_text,
      special_code,
      plans_available_from,
      architectural_plans_by,
      structural_plans_by,
      plans_remark,
      plans_location,
      plans_available,
      delivery_system,
      frame_type,
      stories_above,
      stories_below,
      no_of_buildings,
      structural_info,
      spec_alerts,
      search_names,
      project_note,
      project_title,
      spec_available,
      features_info,
      project_url,
    } = projectLead;
    this.eventId = event_id;
    this.propertyId = property_id;
    this.dateOfFirstExport = date_of_first_export;
    this.dateOfLastExport = date_of_last_export;
    this.dateOfCurrentExport = date_of_current_export;
    this.firstIssueDate = first_issue_date;
    this.lastIssueDate = last_issue_date;
    this.reportDate = report_date;
    this.primaryStage = primary_stage;
    this.secondaryStage = secondary_stage;
    this.bidDate = bid_date;
    this.submitBidsTo = submit_bids_to;
    this.targetStartDate = target_start_date;
    this.targetCompletionDate = target_completion_date;
    this.valuationEstLow = valuation_est_low;
    this.valuation = valuation;
    this.valuationEstHigh = valuation_est_high;
    this.marketSegment = market_segment;
    this.primaryProjectType = primary_project_type;
    this.secondaryProjectType = secondary_project_type;
    this.totalSqft = total_sqft;
    this.typeOfWork = type_of_work;
    this.ownershipType = ownership_type;
    this.statusText = status_text;
    this.bidDate = bid_date;
    this.bidBond = bid_bond;
    this.specialCode = special_code;
    this.plansAvailableFrom = plans_available_from;
    this.architecturalPlansBy = architectural_plans_by;
    this.structuralPlansBy = structural_plans_by;
    this.plansRemark = plans_remark;
    this.plansLocation = plans_location;
    this.plansAvailable = plans_available;
    this.deliverySystem = delivery_system;
    this.frameType = frame_type;
    this.storiesAbove = stories_above;
    this.storiesBelow = stories_below;
    this.noOfBuildings = no_of_buildings;
    this.structuralInfo = structural_info;
    this.specAlerts = spec_alerts;
    this.searchNames = search_names;
    this.projectNote = project_note;
    this.projectTitle = project_title;
    this.specAvailable = spec_available;
    this.features_info = features_info;
    this.projectUrl = project_url;
  }
}
