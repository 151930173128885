import React, { useCallback, useState } from 'react';

import { CleoObjectType, ProjectStatusAction } from '../../../models/common';
import TransitionButtonsActive from '../Leads/TransitionButtons/TransitionButtonsActive';
import ProjectStatusModal from '../LeadDetails/Projects/ProjectStatusModal';

interface IProps {
  activeProjectId: string;
  projectName: string;
  refreshPropertyDetails: () => void;
  disabled?: boolean;
  score: number;
}

const TransitionButtonsActiveContainer: React.FC<IProps> = ({
  activeProjectId,
  refreshPropertyDetails,
  disabled = false,
  projectName,
  score,
}: IProps) => {
  const [demoteModalOpen, setDemoteModalOpen] = useState<boolean>(false);
  const [convertModalOpen, setConvertModalOpen] = useState<boolean>(false);

  const toggleProjectStatusModal = useCallback(
    (modal: ProjectStatusAction): void => {
      if (modal === ProjectStatusAction.Convert) {
        setConvertModalOpen(true);
      } else {
        setDemoteModalOpen(true);
      }
    },
    [setConvertModalOpen, setDemoteModalOpen],
  );

  return (
    <>
      <TransitionButtonsActive
        activeProjectId={activeProjectId}
        refreshPropertyDetails={refreshPropertyDetails}
        toggleProjectStatusModal={toggleProjectStatusModal}
        objectType={CleoObjectType.ProjectLeads}
        disabled={disabled}
      />
      <>
        <ProjectStatusModal
          leadName={projectName}
          projectId={activeProjectId}
          objectType={CleoObjectType.ProjectLeads}
          project={undefined}
          status={ProjectStatusAction.Convert}
          isOpen={convertModalOpen}
          feedbacks={undefined}
          handleClose={() => {
            setConvertModalOpen(false);
            refreshPropertyDetails();
          }}
        />
      </>
      <ProjectStatusModal
        leadName={projectName}
        projectId={activeProjectId}
        objectType={CleoObjectType.ProjectLeads}
        project={undefined}
        propertyId={undefined}
        status={ProjectStatusAction.Demote}
        isOpen={demoteModalOpen}
        feedbacks={undefined}
        score={score}
        handleClose={() => {
          setDemoteModalOpen(false);
          refreshPropertyDetails();
        }}
      />
    </>
  );
};

export default TransitionButtonsActiveContainer;
