import { makeStyles, InputBase, InputLabel, withStyles } from '@material-ui/core';

import { darkLight, white, foundationBlue } from '../../../../theme';

export const TextFieldInput = withStyles({
  root: {
    'label + &': {
      marginTop: 8,
      marginBottom: 8,
    },
    marginRight: 8,
    '&.last': {
      marginRight: 0,
    },
    '&.hidden': {
      display: 'none',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: white,
    fontSize: 11,
    fontWeight: 'bold',
    width: 'auto',
    padding: '6px 8px',
    focused: {
      borderRadius: 4,
      borderColor: darkLight,
    },
  },
})(InputBase);

export const FieldInputLabel = withStyles({
  root: {
    fontSize: 11,
  },
})(InputLabel);

export const useLeadFilterItemStyles = makeStyles(() => ({
  checkBG: {
    width: '1rem',
    height: '1rem',
    backgroundColor: foundationBlue,
    borderRadius: 'unset',
    color: white,
  },
}));
