import { RowConfig, variant } from './PLDetailsTab.config';

import { renderDate, renderItem } from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { black } from '../../../../theme';
import ProjectLead from '../../../../models/projectLead';

export const leftConfigEvent: RowConfig<ProjectLead>[] = [
  {
    id: 'dodgeVersionNumber',
    label: 'Dodge Version',
    render: {
      function: renderItem,
      paramKeys: ['dodgeVersionNumber'],
      additionalParam: [false, variant],
      columns: 'two',
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    render: {
      function: renderDate,
      paramKeys: ['updatedAt'],
      additionalParam: [variant, black],
      columns: 'two',
    },
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    render: {
      function: renderDate,
      paramKeys: ['bidDate'],
      additionalParam: [variant, black],
      columns: 'two',
    },
  },
  {
    id: 'primaryStage',
    label: 'Stage',
    render: {
      function: renderItem,
      paramKeys: ['primaryStage'],
      additionalParam: [false, variant],
      columns: 'two',
    },
  },
  {
    id: 'statusText',
    label: 'Status',
    render: {
      function: renderItem,
      paramKeys: ['statusText'],
      additionalParam: [true, variant],
      columns: 'one',
    },
  },
];
export const rightConfigEvent: RowConfig<ProjectLead>[] = [
  {
    id: 'targetStartDate',
    label: 'Start Date',
    render: {
      function: renderDate,
      paramKeys: ['targetStartDate'],
      additionalParam: [variant, black],
      columns: 'two',
    },
  },
  {
    id: 'marketSegment',
    label: 'Market Type',
    render: {
      function: renderItem,
      paramKeys: ['marketSegment'],
      additionalParam: [true, variant],
      columns: 'two',
    },
  },
  {
    id: 'type',
    label: 'Type',
    render: {
      function: renderItem,
      paramKeys: ['type'],
      additionalParam: [true, variant],
      columns: 'two',
    },
  },
  {
    id: 'description',
    label: 'Description',
    render: {
      function: renderItem,
      paramKeys: ['description'],
      additionalParam: [false, variant],
      columns: 'one',
    },
  },
];
