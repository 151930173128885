import React, { memo, useState, useCallback } from 'react';
import { styled } from '@material-ui/core/styles';
import { Button, Box, Icon } from '@material-ui/core';

import { primaryBlue, darkLight, light } from '../../theme';

type CheckItemProps = {
  label: string;
  checkStatus: boolean;
};
const BaseButton = styled(Button)({
  color: primaryBlue,
  marginTop: '10px',
  padding: '3px 5px',
  '&:hover': {
    backgroundColor: light,
  },
});
const CheckedButton = styled(BaseButton)({
  opacity: '0.7',
  backgroundColor: light,
});

const UncheckedButton = styled(BaseButton)({
  border: `1px solid ${darkLight}`,
  backgroundColor: '#ffffff',
});
/**
 * This component is to demonstrate check Item Select Element represented in the design guide.
 */
const CheckItem = memo(function CheckItem({ label, checkStatus }: CheckItemProps) {
  const [checked, setChecked] = useState(checkStatus);
  const handleCheckStatus = useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <Box>
      {!checked && (
        <UncheckedButton size="small" onClick={handleCheckStatus}>
          {label}
        </UncheckedButton>
      )}
      {checked && (
        <CheckedButton size="small" endIcon={<Icon>check</Icon>} onClick={handleCheckStatus}>
          {label}
        </CheckedButton>
      )}
    </Box>
  );
});

export default CheckItem;
