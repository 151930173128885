import React, { useState } from 'react';
import FilterItemClosedIcon from '@material-ui/icons/ExpandMore';
import FilterItemSelectIcon from '@material-ui/icons/ExpandLess';
import { useRecoilState } from 'recoil';

import { filterItemsOpenState } from '../../../../state/atoms/filters';

interface IProps {
  filterItem: string;
}

const LeadsFilterItemLabel: React.FC<IProps> = ({ filterItem }: IProps) => {
  const [filterItemIconOpen, setFilterItemIconOpen] = useState<boolean>(false);
  const [filterItemsOpen, setFilterItemsOpen] = useRecoilState(filterItemsOpenState);
  return (
    <div style={{ color: 'white', justifyContent: 'space-between' }}>
      {filterItemIconOpen && filterItemsOpen[filterItem] ? (
        <FilterItemSelectIcon
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() => {
            setFilterItemsOpen(() => ({ [filterItem]: false }));
            setFilterItemIconOpen(prev => !prev);
          }}
        />
      ) : (
        <FilterItemClosedIcon
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() => {
            setFilterItemsOpen(() => ({ [filterItem]: true }));
            setFilterItemIconOpen(prev => !prev);
          }}
        />
      )}
    </div>
  );
};

export default LeadsFilterItemLabel;
