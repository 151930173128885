import React, { useRef } from 'react';
import { TableHead as MuiTableHead } from '@material-ui/core';
import { HeaderGroup, IdType, TableInstance } from 'react-table';
import { DragDropContext } from 'react-beautiful-dnd';

import { ReactTableHeaderRow } from './ReactTableHeaderRow';
import { useReactTableHeaderStyles } from './ReactTableHeader.styles';

interface ReactTableHeaderProps<TableType extends Record<any, any>> {
  headerGroups: HeaderGroup<TableType>[];
  tableInstance: TableInstance<TableType>;
  setUpdateCol?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReactTableHeader = <TableType extends Record<string, unknown>>({
  headerGroups,
  tableInstance,
  setUpdateCol,
}: ReactTableHeaderProps<TableType>): JSX.Element => {
  const classes = useReactTableHeaderStyles();
  const currentColOrder = useRef([] as IdType<TableType>[]);
  const { flatHeaders, setColumnOrder } = tableInstance;

  return (
    <MuiTableHead className={classes.root}>
      {headerGroups.map((headerGroup, index: number) => {
        return (
          <DragDropContext
            key={index}
            onDragStart={() => {
              currentColOrder.current = flatHeaders.map(o => o.id);
            }}
            onDragEnd={() => {
              if (setUpdateCol) {
                setUpdateCol(true);
              }
            }}
            onDragUpdate={dragUpdateObj => {
              const colOrder = [...currentColOrder.current];
              const sIndex = dragUpdateObj.source.index;
              const dIndex = dragUpdateObj.destination && dragUpdateObj.destination.index;

              if (typeof sIndex === 'number' && typeof dIndex === 'number') {
                colOrder.splice(sIndex, 1);
                colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                setColumnOrder(colOrder);
              }
            }}
          >
            <ReactTableHeaderRow<TableType>
              headerGroup={headerGroup}
              key={`headerRow-${headerGroup.id}-${index}`}
            />
          </DragDropContext>
        );
      })}
    </MuiTableHead>
  );
};
