import { makeStyles } from '@material-ui/core';

import { hyperlinkBlue } from '../../../../theme';

const useSavedFiltersRowStyles = makeStyles(theme => ({
  row: {
    margin: theme.spacing(0, 0),
    display: 'flex',
    alignItems: 'center',
  },
  savedFilterRoot: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  savedFilterMain: {
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    padding: theme.spacing(2, 4),
    border: '2px solid transparent',
    alignItems: 'flex-start',
    '&:hover': {
      border: '2px solid #999AF6',
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        padding: theme.spacing(0, 1),
      },
    },
  },
  savedFilter: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'normal',
  },
  savedFilterName: {
    fontSize: theme.typography.body1.fontSize,
    color: hyperlinkBlue,
    fontWeight: 'normal',
  },
  savedFilterTags: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  savedFilterOwner: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'normal',
  },
}));

export default useSavedFiltersRowStyles;
