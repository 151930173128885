import { makeStyles } from '@material-ui/core';

const useSavedFiltersTableStyles = makeStyles(() => ({
  infiniteScrollContainer: {
    maxHeight: '72vh',
    overflow: 'scroll',
  },
  table: {
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
}));

export default useSavedFiltersTableStyles;
