import { toTitleCase } from './string';
interface FullAddressRequest {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export function getFullAddress(data: FullAddressRequest): string {
  const { address1, address2, city, state, zipcode } = data;
  const addressInfo = `${address1}${address2 ? ' ' + address2 : ''}, ${city}`;
  return `${toTitleCase(addressInfo) || ''}, ${state} ${zipcode}`;
}

export function convertAddressKeys(data: Record<string, unknown>): Record<string, unknown> {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key] as unknown;
    switch (key) {
      case 'address1':
        acc.address_1 = value;
        break;
      case 'address2':
        acc.address_2 = value;
        break;
      default:
        acc[key] = value;
    }
    return acc;
  }, {} as Record<string, unknown>);
}

export const sanitizeAddress = (address: string): string =>
  address
    .replace(/undefined/i, '')
    .replace(/null/i, '')
    .split(',')
    .filter(part => part.trim())
    .filter(Boolean)
    .join(', ');
