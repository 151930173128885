import React from 'react';
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import theme, { secondary, primary } from '../../../theme';
import { SectionHeader } from '../../Common/StyleGuideStyles';

const StyledPaper = styled(Paper)({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: secondary,
  backgroundColor: primary,
});

/**
 * This files servers for representing spacing standard for the Grid system
 */
function ColumnLayoutStyle(): JSX.Element {
  return (
    <div>
      <SectionHeader>Column Layout</SectionHeader>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledPaper>xs=12</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>xs=12 sm=6</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>xs=12 sm=6</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ColumnLayoutStyle;
