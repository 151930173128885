import { makeStyles } from '@material-ui/core/styles';

import { primary } from '../../../../theme';

const useStatisticStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    minWidth: '5rem',
    maxWidth: '18vw',
    minHeight: '10rem',
    border: `1px solid ${primary}`,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    margin: '.25rem',
  },
  header: {
    padding: '1rem',
    paddingBottom: 0,
    borderBottom: `1px solid ${primary}`,
    width: '70%',
    textAlign: 'center',
  },
  detail: { margin: '1rem' },
}));

export default useStatisticStyles;
