import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { selectedBulkDemoteState } from '../../../../state/atoms/projects';
import { primaryBlue, white } from '../../../../theme';
import FormSelect from '../../../Common/FormSelect';
import FormTextField from '../../../Common/FormTextField';

interface IProps {
  setTransitionItems: (values: { reason: string; note: string }) => void;
  handleCancelBulkDemote: () => void;
  setCompletingDemotion: (completed: boolean) => void;
  completingDemotion: boolean;
  count: number;
  demotionReasons: Record<string, string>;
}

const useStyles = makeStyles(() => ({
  qualificationsBox: {
    '& div': {
      width: '97%',
    },
  },
}));

const QualificationBulkDemoteButtons: React.FC<IProps> = ({
  setTransitionItems,
  handleCancelBulkDemote,
  setCompletingDemotion,
  completingDemotion,
  count,
  demotionReasons,
}) => {
  const classes = useStyles();
  const selectedBulkItems = useRecoilValue(selectedBulkDemoteState);

  return (
    <Grid
      style={{
        backgroundColor: '#F4F9FC',
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        //  Hiding versus removing the component - otherwise formik is unable to submit form
        visibility: completingDemotion ? 'hidden' : 'visible',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '0.2rem 0.8rem',
          margin: '0 1.5rem',
        }}
      >
        <Typography variant="subtitle1">Qualification Reason</Typography>
        <Grid item>
          <Formik
            initialValues={{ reason: '', note: '' }}
            onSubmit={values => {
              setTransitionItems(values);
              setCompletingDemotion(true);
            }}
          >
            {({ values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                className={classes.qualificationsBox}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FormSelect
                    name="reason"
                    label={<span style={{ color: 'black' }}>Primary Reason</span>}
                    options={demotionReasons}
                  />
                  <FormTextField
                    fontWeight="normal"
                    fontColor={primaryBlue}
                    label={<span style={{ color: 'black' }}>Transition Note</span>}
                    name="note"
                    rows={1}
                  />
                </div>

                <Grid
                  style={{
                    backgroundColor: '#F4F9FC',
                    padding: '1rem 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid #0C4377',
                      width: 'fit-content',
                      borderRadius: '4px',
                      padding: '0.2rem 0.8rem',
                    }}
                  >
                    <Button
                      style={{
                        textTransform: 'none',
                        color: '#0C4377',
                        width: '5rem',
                        padding: '0.1rem',
                      }}
                      onClick={handleCancelBulkDemote}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        textTransform: 'none',
                        color: values.reason !== '' ? white : '#a1a2a3',
                        backgroundColor: values.reason !== '' ? '#172D50' : '#e5edea',
                        width: '5rem',
                        padding: '0.1rem',
                      }}
                      disabled={values.reason === ''}
                      onClick={() => {
                        setCompletingDemotion(true);
                      }}
                    >
                      Demote
                    </Button>
                  </div>
                  <Typography
                    style={{ margin: '0 1rem' }}
                    variant="subtitle2"
                    id="bulkDemotedSelected"
                  >
                    {selectedBulkItems.length} Selected{' '}
                    <span style={{ fontWeight: 'lighter' }}>out of</span> {count}
                  </Typography>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </div>
    </Grid>
  );
};

export default QualificationBulkDemoteButtons;
