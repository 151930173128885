import { makeStyles } from '@material-ui/core';

import { darkLight, paleGray, primaryBlue, white } from '../../../theme';

const useDataMapperCreateWizardStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 24),
    },
    margin: '2rem 0',
  },
  paper: {
    borderRadius: 8,
    height: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    backgroundColor: 'white !important',
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    fontWeight: 'bold',
    fontSize: '1.21rem',
  },
  formTitle: {
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    fontSize: '1rem',
    margin: '0.5rem 0',
    fontWeight: 'bold',
  },
  formRoles: {
    color: `${primaryBlue} !important`,
    fontSize: '1rem',
  },
  queryParametersButtons: {
    backgroundColor: paleGray,
    color: primaryBlue,
    borderColor: darkLight,
    maxWidth: 180,
    cursor: 'pointer',
  },
  dataMapperTabs: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiTabs-root': {
      minHeight: 'inherit',
    },
    '& button': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      border: '1px solid #59C9BC',
      borderBottom: 'none',
      marginLeft: '0.5rem',
      borderRadius: '4px !important',
      minHeight: 'inherit',
      fontSize: '1.1rem',
    },
    '& button:hover': {
      border: '1px solid #59C9BC',
      borderBottom: 'none',
      backgroundColor: '#59C9BC',
      color: theme.palette.primary.contrastText,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    '& button.Mui-selected': {
      backgroundColor: '#59C9BC',
      color: theme.palette.primary.contrastText,
    },
    '& button span.MuiTab-wrapper': {
      border: 'none',
      padding: 0,
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: '#59C9BC',
    },
  },
  indentedField: {
    alignItems: 'flex-end',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: 'calc(100% - 2rem)',
    },
  },
  cronSchedulerContainer: {
    display: 'flex',
    height: 'auto',
    border: `1px solid ${primaryBlue}`,
    padding: '2rem 3rem',
    borderRadius: '50px',
    backgroundColor: '#59C9BC',
    margin: '2rem 0',
    justifyContent: 'space-evenly',

    '& div.MuiFormControl-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      width: '8rem',
      textAlign: 'center',
    },

    '& label': {
      color: white,
      textAlign: 'center',
    },

    '& fieldset': {
      borderColor: white,
    },

    '& div.MuiInputBase-formControl': {
      width: '4.8rem',
      textAlign: 'center',
    },

    '& div.MuiInputBase-formControl input': {
      textAlign: 'center',
    },

    '& p.Mui-error': {
      textAlign: 'center',
    },
  },
  reviewItems: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  reviewItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    marginBottom: '0.5rem',
    '& p': {
      fontSize: '1rem',
    },
  },
  reviewItemTitle: {
    color: primaryBlue,
    fontWeight: 'bold',
  },
  reviewDataSet: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1rem',
    '& p': {
      fontSize: '1rem',
      marginBottom: '0.3rem',
    },
  },
}));

export default useDataMapperCreateWizardStyles;
