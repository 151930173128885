import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';

import Quintiles from './Quintiles';
import System from './System';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 24),
    },
  },
}));

const MetricsPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={8} direction="column">
        <Grid item xs>
          <Quintiles />
        </Grid>
        <Grid item xs>
          <System />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MetricsPage;
