import React, {
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { makeStyles, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import type { ObjectSchema } from 'yup';

import AddContactDetailButton from './AddContactDetailButton';

import { FilterValue, Optional } from '../../../types';
import { getStakeholders } from '../../../api/stakeholders';
import { getOrganizations } from '../../../api/organizations';
import { useMessages } from '../../../state/contexts';
import BorderedPaper from '../../Common/BorderedPaper';
import FormPhoneNumberField from '../../Common/FormPhoneNumberField';
import FormNumberField from '../../Common/FormNumberField';
import FormSwitch from '../../Common/FormSwitch';
import FormTextField from '../../Common/FormTextField';
import PaperHeader from '../../Common/PaperHeader';
import PaperTitle from '../../Common/PaperTitle';
import PaperBody from '../../Common/PaperBody';
import PaperFooter from '../../Common/PaperFooter';
import ModalLayout from '../../Common/ModalLayout';
import { ContactDetailType } from '../../../models/common';
import { primary, darkBlue } from '../../../theme';
import { BaseModel, Stakeholder } from '../../../models';
import LoadingPane from '../../Common/Search/LoadingSearch';
import SelectedPane from '../../Common/Search/SelectedSearch';
import EmptyPane from '../../Common/Search/EmptySearch';
import Search from '../../Common/Search/Search';
import useDebounce from '../../../utils/debounce';
import { removeFalsyValues } from '../../../utils/object';
import { ModalType } from '../../../constants';

export type SubmitHandler<T> = (
  values: any,
  contact?: Partial<T>,
  addContactParams?: Record<string, boolean>,
) => Promise<Optional<T>>;

export interface IModalProps {
  redirectOnSearch?: boolean;
  open: boolean;
  onClose: (success?: boolean) => void;
}

interface IProps<T> extends PropsWithChildren<IModalProps> {
  accentColor?: string;
  title?: string;
  showAddress?: boolean;
  showPrimary?: boolean;
  initialValues: any;
  validationSchema: ObjectSchema;
  modelType: string;
  modalType: ModalType;
  onSubmit: SubmitHandler<T>;
}

const useStyles = makeStyles({
  columnSubtitle: {
    color: darkBlue,
  },
});

export default function CreateContactModal<T extends BaseModel>({
  open,
  onClose,
  accentColor = primary,
  title = 'Contact',
  modelType,
  modalType,
  showPrimary = false,
  initialValues,
  validationSchema,
  onSubmit,
  children,
  redirectOnSearch = false,
}: IProps<T>): ReactElement<IProps<T>> {
  const initVals: Record<string, Optional<string>> = useMemo(
    () => ({ ...initialValues, addToProject: false, addToProperty: true }),
    [initialValues],
  );
  const [selectedContact, setSelectedContact] = useState<Partial<T>>();
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [addContactParams, setAddContactParams] = useState<Record<string, boolean>>({});
  const [query, setQuery] = useState<Record<string, Optional<string>>>(initVals);
  const [fetching, setFetching] = useState(false);
  const [detailCounts, setDetailCounts] = useState<Record<ContactDetailType, number>>({
    [ContactDetailType.Phone]: 1,
    [ContactDetailType.Email]: 1,
    [ContactDetailType.Website]: 0,
  });
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const debouncedQuery = useDebounce(query, 500);

  const fetchContacts = useCallback(
    async q => {
      setFetching(true);
      try {
        const dsl = {
          query: Object.keys(q).reduce((acc, key) => {
            if (!['addToProperty', 'addToProject'].includes(key)) {
              acc[key] = { cicontains: q[key] };
            }
            return acc;
          }, {} as Record<string, FilterValue>),
        };
        const { items } =
          modelType === Stakeholder.modelName
            ? await getStakeholders(dsl)
            : await getOrganizations(dsl);
        setSearchResults((items as unknown[]) as T[]);
      } catch (err: any) {
        setErrorMessage('Error fetching contacts', err);
      }
      setFetching(false);
    },
    // There is warning for generics that can be ignored and will be fixed by 3rd party
    [setErrorMessage, modelType],
  );

  useEffect(() => {
    const { name, email, phone, address1, address2, city, state, zipcode } = debouncedQuery || {};
    const hasValues = name || email || phone || address1 || address2 || city || state || zipcode;
    if (hasValues) {
      fetchContacts(removeFalsyValues(debouncedQuery));
    } else {
      setSearchResults([]);
    }
  }, [debouncedQuery, fetchContacts, setSearchResults]);

  const handleSelectDetailType = useCallback((type: ContactDetailType) => {
    setDetailCounts(currentDetailCounts => ({
      ...currentDetailCounts,
      [type]: currentDetailCounts[type] + 1,
    }));
  }, []);

  const handleSubmit = useCallback(
    async values => {
      try {
        const item = await onSubmit(values, selectedContact, addContactParams);
        if (item) {
          setSelectedContact(item);
          return;
        }
        setSelectedContact(undefined);
        setSearchResults([]);
        setAddContactParams({});
        setQuery(initVals);
        onClose(true);
        setSuccessMessage('Contact added');
      } catch (err: any) {
        let msg = 'Error creating contact';
        if (values.id) {
          msg = 'Error adding contact';
        }
        setErrorMessage(msg, err);
      }
    },
    [
      onSubmit,
      onClose,
      selectedContact,
      addContactParams,
      initVals,
      setErrorMessage,
      setSuccessMessage,
    ],
  );

  const classes = useStyles();
  return (
    <ModalLayout
      label="create-contact"
      isOpen={open}
      handleClose={() => {
        setSearchResults([]);
        onClose();
      }}
    >
      <Container maxWidth="md">
        <Formik
          initialValues={initVals}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form
              onChange={(e: SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                const { name, value, checked } = target;
                if (['is_primary', 'addToProject', 'addToProperty'].includes(name)) {
                  setAddContactParams(curr => ({ ...curr, [name]: checked }));
                } else if (value !== query[name]) {
                  setQuery(current => ({ ...current, [name]: value }));
                }
              }}
            >
              <Paper>
                <PaperHeader>
                  <Grid container justify="center">
                    <Grid item>
                      <PaperTitle title={`Create ${title}`} />
                    </Grid>
                  </Grid>
                </PaperHeader>
                <PaperBody>
                  <Grid container spacing={7}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        <strong>{title} Information</strong>
                      </Typography>
                      <BorderedPaper color={accentColor}>
                        <Typography
                          variant="subtitle1"
                          className={classes.columnSubtitle}
                          gutterBottom
                        >
                          Enter {title} Information
                        </Typography>
                        <Grid container direction="column" spacing={3}>
                          <Grid item>
                            <FormTextField name="name" label="Name" fullWidth />
                          </Grid>
                          <Grid item>
                            <FormTextField
                              name="address1"
                              label="Address Line 1"
                              placeholder="(e.g. 123 Main St)"
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <FormTextField
                              name="address2"
                              label="Address Line 2"
                              placeholder="(e.g. Ste 200)"
                              fullWidth
                            />
                          </Grid>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <FormTextField name="city" label="City" fullWidth />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FormTextField
                                name="state"
                                label="State"
                                inputProps={{ minLength: 2, maxLength: 2 }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <FormNumberField
                                name="zipcode"
                                label="ZIP Code"
                                inputProps={{ minLength: 5, maxLength: 5 }}
                                fullWidth
                                allowLeadingZeros
                              />
                            </Grid>
                          </Grid>
                          {[...Array(detailCounts[ContactDetailType.Email]).keys()].map(i => (
                            <Grid item key={`email-${i}`}>
                              <FormTextField name={`contact.email[${i}]`} label="Email" fullWidth />
                            </Grid>
                          ))}
                          {[...Array(detailCounts[ContactDetailType.Phone]).keys()].map(i => (
                            <Grid item key={`phone-${i}`}>
                              <FormPhoneNumberField
                                name={`contact.phone[${i}]`}
                                label="Phone"
                                fullWidth
                              />
                            </Grid>
                          ))}
                          {[...Array(detailCounts[ContactDetailType.Website]).keys()].map(i => (
                            <Grid item key={`website-${i}`}>
                              <FormTextField
                                name={`contact.website[${i}]`}
                                label="Website"
                                fullWidth
                              />
                            </Grid>
                          ))}
                          <Grid container direction="column" spacing={3}>
                            {children}
                            <Grid item>
                              <FormTextField
                                name="description"
                                label={`${title} Description`}
                                fullWidth
                                multiline
                                rows={3}
                              />
                            </Grid>
                          </Grid>
                          <Grid item>
                            <AddContactDetailButton onSelect={handleSelectDetailType} />
                          </Grid>
                        </Grid>
                      </BorderedPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        <strong>Existing {title}s</strong>
                      </Typography>
                      {fetching ? (
                        <LoadingPane />
                      ) : selectedContact ? (
                        <SelectedPane
                          modelType={modelType}
                          selected={(selectedContact as unknown) as T}
                          setSelected={setSelectedContact}
                          redirectOnSearch={redirectOnSearch}
                        >
                          {modalType === ModalType.AddLeadStakeholder && (
                            <Grid container direction="column" justify="space-between">
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.columnSubtitle}
                                  gutterBottom
                                >
                                  Add to Property
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormSwitch name="addToProperty" />
                              </Grid>
                              {showPrimary && (
                                <>
                                  <Grid item>
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.columnSubtitle}
                                      gutterBottom
                                    >
                                      Add to Project
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <FormSwitch name="addToProject" />
                                  </Grid>
                                  {addContactParams.addToProject && (
                                    <>
                                      <Grid item>
                                        <Typography
                                          variant="subtitle1"
                                          className={classes.columnSubtitle}
                                          gutterBottom
                                        >
                                          Primary Project Contact
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <FormSwitch name="is_primary" />
                                      </Grid>
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          )}
                        </SelectedPane>
                      ) : Object.values(query).filter(x => !!x).length === 0 ? (
                        <EmptyPane />
                      ) : (
                        <Search
                          modelType={modelType}
                          searchResults={searchResults}
                          setSelected={setSelectedContact}
                          redirectOnSearch={redirectOnSearch}
                        />
                      )}
                    </Grid>
                  </Grid>
                </PaperBody>
                <PaperFooter>
                  <Grid container justify="center">
                    {selectedContact && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={
                            modalType === ModalType.AddLeadStakeholder &&
                            !addContactParams.addToProject &&
                            !addContactParams.addToProperty
                          }
                          onClick={() => handleSubmit(values)}
                        >
                          Save {title}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </PaperFooter>
              </Paper>
            </Form>
          )}
        </Formik>
      </Container>
    </ModalLayout>
  );
}
