import React, { memo, useState, useCallback } from 'react';
import { Select, Input, MenuItem, InputLabel, Box, styled, SelectProps } from '@material-ui/core';

import { darkBlue, primaryBlue } from '../../theme';

type MultiSelectProps = SelectProps & {
  items: string[];
  selectedItems: string[];
  label: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectLabel = styled(InputLabel)({
  fontSize: 9,
  color: darkBlue,
  fontWeight: 'bold',
  marginBottom: '5px',
  textDecoration: 'none',
});

const MultiSelection = styled(Select)({
  border: `solid 1px ${primaryBlue}`,
  padding: '0 5px',
  width: '100px',
});

const SelectBox = styled(Box)({
  margin: '10px',
});

/**
 * This component is to demonstrate Multi-Select Element represented in the design guide.
 */
const MultiSelect = memo(function MultiSelect({
  items,
  selectedItems,
  label,
  disabled,
}: MultiSelectProps) {
  const [selected, setSelected] = useState(selectedItems);
  const handleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string[]);
  }, []);

  const handleRenderValue = useCallback(() => {
    if ((selected as string[]).length === 0) {
      return <em>Placeholder</em>;
    }
    return (selected as string[]).join(', ');
  }, [selected]);
  return (
    <SelectBox>
      <SelectLabel id="select-label">{label}</SelectLabel>
      <MultiSelection
        variant="outlined"
        disabled={disabled}
        multiple
        displayEmpty
        value={selected}
        onChange={handleChange}
        input={<Input />}
        renderValue={handleRenderValue}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled value="">
          <em>Placeholder</em>
        </MenuItem>
        {items.map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </MultiSelection>
    </SelectBox>
  );
});
export default MultiSelect;
