import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CellProps,
  Column,
  HeaderProps,
  Hooks,
  Row,
  TableOptions,
  TableState,
  useAsyncDebounce,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Table as MuiTable, Popover } from '@material-ui/core';

import { ReactTableHeader } from './ReactTableHeader';
import { ReactTableBody } from './ReactTableBody';
import { HeaderCheckbox, RowCheckbox } from './ReactTable.styles';
import { CustomizeTablesPopover } from './CustomizeTablesPopover';
import {
  HorizontalAnchorPosition,
  SelectionHookProperties,
  VerticalAnchorPosition,
} from './ReactTable.config';
import { ReactTablePagination } from './ReactTablePagination';

import {
  bulkDemoteLeadsButtonPressedState,
  customizeColumnsGearIconAnchorElState,
  customizeColumnsGearIconClickedState,
} from '../../../../state/atoms/projectLeads';
import { selectedBulkDemoteState } from '../../../../state/atoms/projects';
import Loader from '../../Loader';
import { defaultInitialStatePL } from '../../../Pages/ProjectLeads/ProjectLeadsTable/ProjectLeadsTableColumns';

export interface FetchDataProps {
  startIndex?: number;
  limit?: number;
  sortId?: string;
  sortIsDesc?: 'asc' | 'desc';
}

export type ColumnDraggable = {
  disableDrag?: boolean;
};

export interface ReactTableProps<TableType extends Record<string, unknown>>
  extends TableOptions<TableType> {
  columns: (Column<TableType> & ColumnDraggable)[];
  data: TableType[];
  loading: boolean;
  onClick?: (row: Row<TableType>) => void;
  fetchData: (props: FetchDataProps) => void;
  initialTableState: Partial<TableState<TableType>> | undefined;
  error?: boolean;
}

const selectionHook = (hooks: Hooks<any>) => {
  hooks.allColumns.push(columns => [
    {
      ...SelectionHookProperties,
      Header: function ReactTableHeaderCheckbox({
        getToggleAllRowsSelectedProps,
      }: HeaderProps<any>) {
        return <HeaderCheckbox {...getToggleAllRowsSelectedProps()} />;
      },
      Cell: function ReactTableRowCheckbox({ row }: CellProps<any>) {
        return (
          <RowCheckbox
            {...row.getToggleRowSelectedProps()}
            onClick={e => {
              // prevents entire row from being clicked
              e.stopPropagation();
            }}
          />
        );
      },
    },
    ...columns,
  ]);
  hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
    const selectionGroupHeader = headerGroups[0].headers[0];
    selectionGroupHeader.canResize = false;
  });
};

const hooks = [
  useColumnOrder,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  selectionHook,
];

export const ReactTable = <TableType extends Record<any, any>>({
  columns,
  data,
  loading,
  error,
  fetchData,
  pageCount: controlledPageCount,
  initialTableState,
}: ReactTableProps<TableType>): JSX.Element => {
  const [gearIconClicked, setGearIconClicked] = useRecoilState(
    customizeColumnsGearIconClickedState,
  );
  // create state to update col, prevents firing off infinitely
  const [orderCols, setOrderCols] = useState(true);

  const [anchorEl, setAnchorEl] = useRecoilState(customizeColumnsGearIconAnchorElState);
  const bulkDemoteButtonPressed = useRecoilValue(bulkDemoteLeadsButtonPressedState);
  const setSelectedBulkItems = useSetRecoilState(selectedBulkDemoteState);
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 80,
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: initialTableState || defaultInitialStatePL,
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    ...hooks,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleHideColumn,
    columns: allInstanceColumns,
    state: { pageIndex, pageSize, sortBy },
    selectedFlatRows,
    toggleAllRowsSelected,
    visibleColumns,
    setColumnOrder,
  } = tableInstance;
  const { ...tableProps } = getTableProps();

  const handleCloseCustomizeColumns = useCallback(() => {
    setGearIconClicked(false);
    setAnchorEl(null);
    setOrderCols(true);
  }, [setGearIconClicked, setAnchorEl]);

  // debounces rapid table state changes
  const onFetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const handleColOrder = useCallback(() => {
    // Make sure the gear icon col is last
    const colOrder = visibleColumns.map(c => c.id);
    const gearIdx = colOrder.indexOf('customizeColumnsGearIcon');
    if (gearIdx >= 0) {
      colOrder.splice(gearIdx, 1);
      colOrder.push('customizeColumnsGearIcon');
    }
    const selectorIdx = colOrder.indexOf('_selector');
    if (selectorIdx >= 0) {
      colOrder.splice(selectorIdx, 1);
      colOrder.unshift('_selector');
    }

    setColumnOrder(colOrder);
    setOrderCols(false);
  }, [setColumnOrder, setOrderCols, visibleColumns]);

  useEffect(() => {
    if (bulkDemoteButtonPressed) {
      toggleHideColumn('_selector', false);
    } else if (!bulkDemoteButtonPressed) {
      toggleHideColumn('_selector', true);
    }
  }, [allInstanceColumns, bulkDemoteButtonPressed, toggleHideColumn]);

  useEffect(() => {
    const startIndex = pageIndex * pageSize;
    const orderBy = sortBy[0]?.id || 'projectScore';
    const isDesc = sortBy[0]?.desc ? 'desc' : 'asc';
    onFetchDataDebounced({ startIndex, limit: pageSize, sortId: orderBy, sortIsDesc: isDesc });
  }, [pageIndex, pageSize, sortBy, onFetchDataDebounced]);

  useEffect(() => {
    if (orderCols) {
      handleColOrder();
    }
  }, [orderCols, handleColOrder]);

  //Bulk Demote logic for react table
  useEffect(() => {
    const selectedBulkDemoteProjects = selectedFlatRows.map(d => d.original);
    setSelectedBulkItems(selectedBulkDemoteProjects as TableType[]);
    setOrderCols(true);
  }, [selectedFlatRows, setSelectedBulkItems, bulkDemoteButtonPressed]);

  //Bulk Demote Reset logic for react table
  useEffect(() => {
    if (!bulkDemoteButtonPressed) {
      toggleAllRowsSelected(false);
    }
  }, [bulkDemoteButtonPressed, toggleAllRowsSelected]);

  return (
    <>
      {/*<ReactTableMetaData tableInstance={tableInstance} />*/}
      <MuiTable {...tableProps}>
        <ReactTableHeader<TableType> headerGroups={headerGroups} tableInstance={tableInstance} />
        <ReactTableBody<TableType>
          rows={rows}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
        />
      </MuiTable>
      {loading && <Loader hasError={error} component={'table'} />}
      {!!data.length && !!pageSize && <ReactTablePagination tableInstance={tableInstance} />}
      {/*For development use*/}
      <Popover
        open={gearIconClicked}
        anchorEl={anchorEl}
        onClose={handleCloseCustomizeColumns}
        anchorOrigin={{
          vertical: VerticalAnchorPosition.Bottom,
          horizontal: HorizontalAnchorPosition.Left,
        }}
        transformOrigin={{
          vertical: VerticalAnchorPosition.Top,
          horizontal: HorizontalAnchorPosition.Left,
        }}
      >
        <CustomizeTablesPopover<TableType>
          columns={allInstanceColumns}
          handleCloseCustomizeColumns={handleCloseCustomizeColumns}
          tableInstance={tableInstance}
          setUpdateCol={setOrderCols}
        />
      </Popover>
    </>
  );
};
