import { makeStyles } from '@material-ui/core/styles';

import { secondary } from '../../../../theme';

const AccountCardStyle = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '0',
    paddingRight: '0',
    width: '97%',
    backgroundColor: secondary,
    borderRadius: '4px',
    border: '1.5px solid transparent',
    boxShadow: 'inset 5px 0px 0px #0C4377',
  },
  connect: {
    width: '3%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  hr: {
    borderTop: '3px solid #0C4377',
    flex: 1,
  },
  topRow: {
    padding: '.5rem',
    paddingBottom: 0,
    marginLeft: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  action: {
    padding: '0 .25rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.5rem',
  },
  icons: {
    backgroundColor: secondary,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '1.5rem',
  },
  newBanner: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '0rem',
    left: '-2.5rem',
    height: '.5rem',
    width: '8rem',
    transform: 'rotate(-36.61deg)',
    backgroundColor: '#172D50',
    color: secondary,
    zIndex: 10,
    padding: '.7rem',
    overflow: 'hidden',
  },
  newText: {
    paddingRight: '1.1rem',
  },
});

export default AccountCardStyle;
