import type { IResponse } from './api';
import API, { APICancelToken } from './api';

import { FilterData, FilterQuery } from '../types';
import type {
  IStakeholderResponse,
  IOrganizationResponse,
  IOrganizationRequest,
  IProjectResponse,
  IPropertyResponse,
  StakeholderRole,
} from '../models';
import { Stakeholder, Organization, Project, Property } from '../models';
import type { IOrganizationStakeholderRequest } from '../models/stakeholder';
import encodeFilter from '../utils/encodeFilter';
import { removeFalsyValues } from '../utils/object';
import { convertAddressKeys } from '../utils/address';
import AccountListAccount, {
  IAccountDuplicateRequest,
  IAccountListRequest,
  IAccountListAccountResponse,
} from '../models/accountListAccount';
import {
  IAccountDuplicates,
  IOrgWithAccountDetails,
  OrganizationAccount,
  OrganizationWithAccountDetails,
} from '../models/organization';
import PvfProject, { IPVFResponse } from '../models/pvfProject';
import { sanitizeFilterConfig } from '../utils/filter';
import {
  IProject,
  SortColumns,
  ViewFilterRequest,
} from '../Components/Pages/Leads/Archive/Archive.config';
import { ProjectStatusCounts } from '../models/project';

export const getOrganizations = async ({
  sort = ['-updated_at'],
  offset = 0,
  limit = 10,
  query,
}: FilterQuery): Promise<IResponse<Organization>> => {
  const filterData: FilterQuery = {
    limit,
    offset,
    sort,
  };

  if (query) {
    delete (query as FilterData).profile_type;
    delete (query as FilterData).stakeholder_role;
    filterData.query = convertAddressKeys(query) as FilterData;
  }
  const { data } = await API.get('/organizations', {
    params: {
      filter: encodeFilter(filterData),
    },
  });
  const events = (data as IResponse<IOrganizationResponse>).items.map(
    item => new Organization(item),
  );
  return { items: events, total: data.total };
};

export const getOrganizationById = async (id: string): Promise<Organization> => {
  const { data } = await API.get(`/organizations/${id}`);
  return new Organization(data as IOrganizationResponse);
};

export const createOrganization = async (params: IOrganizationRequest): Promise<Organization> => {
  const { data } = await API.post(`/organizations`, removeFalsyValues(params));
  return new Organization(data as IOrganizationResponse);
};

export const updateOrganizationById = async (
  id: string,
  params: IOrganizationRequest,
): Promise<Organization> => {
  const { data } = await API.patch(`/organizations/${id}`, removeFalsyValues(params));
  return new Organization(data as IOrganizationResponse);
};

export const getOrganizationDetails = async (
  id: string,
): Promise<OrganizationWithAccountDetails> => {
  const { data } = await API.get(`/organizations/${id}/details`);
  return new OrganizationWithAccountDetails(data as IOrgWithAccountDetails);
};

export const getOrganizationProjects = async (id: string): Promise<Project[]> => {
  const { data } = await API.get(`/organizations/${id}/projects`);
  return (data as IResponse<IProjectResponse>).items.map(item => new Project(item));
};

export const getOrganizationProperties = async (id: string): Promise<Property[]> => {
  const { data } = await API.get(`/organizations/${id}/properties`);
  return (data as IResponse<IPropertyResponse>).items.map(item => new Property(item));
};

export const getOrganizationStakeholders = async (id: string): Promise<Stakeholder[]> => {
  const { data } = await API.get(`/organizations/${id}/stakeholders`);
  return (data as IResponse<IStakeholderResponse>).items.map(item => new Stakeholder(item));
};

export const createOrganizationStakeholder = async (
  id: string,
  organizationStakeholder: IOrganizationStakeholderRequest,
): Promise<Stakeholder> => {
  const { data } = await API.post(`/organizations/${id}/stakeholders`, organizationStakeholder);
  return new Stakeholder(data as IStakeholderResponse);
};

export const addOrganizationStakeholder = async (
  id: string,
  stakeholderId: string,
  role: StakeholderRole,
): Promise<Stakeholder> => {
  const { data } = await API.post(`/organizations/${id}/stakeholders/${stakeholderId}`, {
    stakeholder_role: role,
  });
  return new Stakeholder(data as IStakeholderResponse);
};

type Config = {
  offset: number;
  limit: number;
};

export const getAccountViewFilter = async (
  config: Config,
  cancelToken?: APICancelToken,
): Promise<IResponse<AccountListAccount>> => {
  const { data } = await API.post(
    '/organizations/accounts-view-filter/',
    { get_filter: encodeFilter(config) },
    { cancelToken },
  );
  const accounts = (data as IResponse<IAccountListAccountResponse>).items.map(
    item => new AccountListAccount(item),
  );

  return { items: accounts, total: data.total };
};

export const getAccountListAccounts = async (
  { offset, limit, sortColumn, sortDirection, isNew }: IAccountListRequest,
  cancelToken?: APICancelToken,
): Promise<IResponse<AccountListAccount>> => {
  const params: IAccountListRequest = {
    limit,
    offset,
    sortColumn,
    sortDirection,
  };
  if (isNew) {
    params.isNew = isNew;
  }

  const { data } = await API.get('/organizations/accounts/', {
    params,
    cancelToken,
  });
  const accounts = (data as IResponse<IAccountListAccountResponse>).items.map(
    item => new AccountListAccount(item),
  );

  return { items: accounts, total: data.total };
};

export const getAccountDuplicates = async (
  organizationId: string,
): Promise<OrganizationAccount[]> => {
  const { data } = await API.get(`/organizations/${organizationId}/duplicates/`);
  const { original, duplicates } = data as IAccountDuplicates;
  const duplicateAccounts = duplicates.map(acc => new OrganizationAccount(acc));
  return [new OrganizationAccount(original), ...duplicateAccounts];
};

export const mergeAccountDuplicates = async (params: IAccountDuplicateRequest): Promise<void> => {
  await API.post(`/organizations/merge/`, params);
  return;
};

export async function getAccountProjectsLeads<T>(
  organizationId: string,
  projectsRequest: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
): Promise<IResponse<IProject>> {
  const configOptions = sanitizeFilterConfig(projectsRequest, sortColumns, 'projectLeads');
  const { data } = await API.post(`/organizations/${organizationId}/projectLeads/`, {
    get_filter: encodeFilter(configOptions),
    paginate: true,
  });
  return data as IResponse<IProject>;
}

export async function getAccountsPageProjects<T>(
  organizationId: string,
  projectsRequest: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
): Promise<IResponse<PvfProject> & { counts: ProjectStatusCounts }> {
  const configOptions = sanitizeFilterConfig(projectsRequest, sortColumns, 'projectLeads');
  const { data } = await API.post(`/organizations/${organizationId}/projectLeads/`, {
    get_filter: encodeFilter(configOptions),
    paginate: true,
  });
  const { total, items, counts } = data as IResponse<IPVFResponse> & {
    counts: ProjectStatusCounts;
  };
  return { total, items: items.map(iPVF => new PvfProject(iPVF)), counts };
}
