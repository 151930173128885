import { Optional } from '../types';

interface IAuth0IdToken {
  __raw: string;
}

class AuthUtils {
  static getIdTokenClaims: () => Promise<IAuth0IdToken>;
  static getAccessTokenSilently: () => Promise<string>;

  static async getAccessToken(retryCount = 0): Promise<Optional<string>> {
    const idTokenClaims = await this.getIdTokenClaims();

    // May return empty if previous token has expired
    if (!idTokenClaims) {
      if (retryCount >= 1) {
        return undefined;
      }

      // Use the refresh token to obtain a new access token in the background
      await this.getAccessTokenSilently();
      return this.getAccessToken(retryCount + 1);
    }

    return idTokenClaims.__raw;
  }
}

export default AuthUtils;
