import React, { Fragment, useCallback, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import {
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
  Paper,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import NetworkSearch from './Search';

import { IBaseContactRequest } from '../../../models/baseContact';
import BackButton from '../../Common/BackButton';
import Editable from '../../Common/Editable';
import FormPhoneNumberField from '../../Common/FormPhoneNumberField';
import FormTextField from '../../Common/FormTextField';
import PaperBody from '../../Common/PaperBody';
import PaperHeader from '../../Common/PaperHeader';
import PaperTitle from '../../Common/PaperTitle';
import OrganizationCardList from '../OrganizationDetails/OrganizationCardList';
import StakeholderCardList from '../StakeholderDetails/StakeholderCardList';
import LeadCardList from '../Leads/LeadsTable/LeadCardList';
import ProjectCardList from '../LeadDetails/Projects/ProjectCardList';
import {
  Stakeholder,
  Organization,
  Project,
  Property,
  IStakeholderRequest,
  IOrganizationRequest,
} from '../../../models';
import {
  DATA_PLACEHOLDER,
  ContactDetailMapping,
  ContactDetailPlaceholderMapping,
  ProjectOrigin,
} from '../../../constants';
import { ContactDetailType, IContactDetails, StakeholderRole } from '../../../models/common';
import type { Optional } from '../../../types';
import { darkLight, paleGray, primary, primaryBlue, light } from '../../../theme';
import { IAddProjectStakeholderRequest } from '../../../models/stakeholder';
import { sanitizeAddress } from '../../../utils/address';

interface ISubmitProps {
  resetForm: () => void;
}

export type SubmitHandler = (
  values: IOrganizationRequest | IStakeholderRequest,
  props: ISubmitProps,
) => void;

export type EditContactParams = {
  contactType: ContactDetailType;
  contactIndex: number;
};

interface IProps {
  accentColor?: string;
  title?: string;
  showAddress?: boolean;
  refreshData?: () => void;
  contact: Stakeholder | Organization;
  initialValues: IStakeholderRequest | IOrganizationRequest;
  validationSchema: Yup.ObjectSchema;
  onSubmit: SubmitHandler;
  organizations?: Organization[];
  projects?: Project[];
  properties?: Property[];
  stakeholders?: Stakeholder[];
  onCreateProject?: (
    projectId: string,
    stakeholderId: string,
    role: IAddProjectStakeholderRequest,
  ) => void;
  onRemove?: (
    propertyOrProjectId: string,
    stakeholderId: string,
    addedToProperty: boolean,
  ) => Promise<void>;
}

const useStyles = (color: string) =>
  makeStyles({
    detailBox: {
      borderLeft: `6px solid ${color}`,
      borderRight: `solid 1px ${light}`,
      borderTop: `solid 1px ${light}`,
      borderBottom: `solid 1px ${light}`,
      borderRadius: 8,
      padding: 16,
      boxShadow: 'none',
    },
    formTitle: {
      color,
    },
    addButton: {
      backgroundColor: paleGray,
      color: primaryBlue,
      borderColor: darkLight,
      maxWidth: 180,
    },
  });

const ContactDetails: React.FC<IProps> = ({
  accentColor = primary,
  title = 'Contact',
  showAddress = true,
  refreshData,
  contact,
  initialValues,
  validationSchema,
  onSubmit,
  onCreateProject,
  organizations,
  projects,
  properties,
  stakeholders,
  children,
}) => {
  const [addDetailsAnchorEl, setAddDetailsAnchorEl] = useState<null | HTMLElement>(null);
  const [addContactDetailType, setAddContactDetailType] = useState<Optional<ContactDetailType>>();
  const { name, fullAddress, phoneNumbers, emailAddresses, websites, contactDetails } = contact;
  const sanitizedAddress = sanitizeAddress(fullAddress);
  showAddress = !!sanitizedAddress;
  const onEdit = (
    key: keyof IBaseContactRequest,
    value: string,
    editContactParams?: Optional<EditContactParams>,
  ) => {
    const resetForm = () => {
      return;
    };

    const data: IBaseContactRequest = {};
    let doSubmit = false;

    if (editContactParams) {
      const updateContactObj: IContactDetails = { ...contactDetails };
      const updateContactArray: string[] | undefined =
        updateContactObj[editContactParams.contactType];
      if (updateContactArray) {
        updateContactArray[editContactParams.contactIndex] = value;
        updateContactObj[editContactParams.contactType] = updateContactArray;
        data['contact'] = updateContactObj;
        doSubmit = true;
      }
    } else {
      data[key] = value;
      doSubmit = true;
    }

    if (doSubmit) {
      onSubmit(data, { resetForm });
    }
  };

  const handleSubmit: SubmitHandler = useCallback(
    (values, props) => {
      setAddContactDetailType(undefined);
      onSubmit(values, props);
    },
    [onSubmit],
  );

  const contactDetailsLength: Record<ContactDetailType, number> = {
    [ContactDetailType.Phone]: phoneNumbers.length,
    [ContactDetailType.Email]: emailAddresses.length,
    [ContactDetailType.Website]: websites.length,
  };
  const contactDetailsInputProps = addContactDetailType
    ? {
        name: `contact.${addContactDetailType}[${contactDetailsLength[addContactDetailType]}]`,
        label: ContactDetailMapping[addContactDetailType],
        placeholder: ContactDetailPlaceholderMapping[addContactDetailType],
        fullWidth: true,
      }
    : {};

  const classes = useStyles(accentColor)();
  return (
    <Container maxWidth="lg">
      <BackButton fallbackPath="/" />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, values, resetForm }) => (
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12} lg={8}>
                <Paper>
                  <PaperHeader>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <PaperTitle title={title} subtitle={name} />
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" disabled={!dirty}>
                          {`Save ${title}`}
                        </Button>
                      </Grid>
                    </Grid>
                  </PaperHeader>
                  <PaperBody>
                    <Paper className={classes.detailBox}>
                      <Typography variant="h6" className={classes.formTitle} gutterBottom>
                        {title} Details
                      </Typography>
                      <Grid container spacing={6}>
                        <Grid item xs={12} lg={6} container direction="column" spacing={4}>
                          <Grid item>
                            <Editable
                              validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required'),
                              })}
                              name="name"
                              label="Name"
                              defaultValue={name}
                              onChange={value => onEdit('name', value)}
                            >
                              <Typography color="primary">{name || DATA_PLACEHOLDER}</Typography>
                            </Editable>
                          </Grid>
                          {showAddress && (
                            <Grid item>
                              <Typography>Address:</Typography>
                              <Typography color="primary">{sanitizedAddress}</Typography>
                            </Grid>
                          )}
                          {emailAddresses.map((email, i) => (
                            <Fragment key={email}>
                              <Grid item>
                                <Editable
                                  label="Email"
                                  validationSchema={Yup.object().shape({
                                    email: Yup.string().email(
                                      'Please enter a valid email address.',
                                    ),
                                  })}
                                  name="email"
                                  defaultValue={email}
                                  onChange={value =>
                                    onEdit('contact', value, {
                                      contactType: ContactDetailType.Email,
                                      contactIndex: i,
                                    })
                                  }
                                >
                                  <Typography color="primary">{email}</Typography>
                                </Editable>
                              </Grid>
                              <Field
                                type="hidden"
                                name={`contact.${ContactDetailType.Email}[${i}]`}
                              />
                            </Fragment>
                          ))}
                          {phoneNumbers.map((phoneNumber, i) => (
                            <Fragment key={phoneNumber}>
                              <Grid item key={phoneNumber}>
                                <Editable
                                  label="Phone"
                                  validationSchema={Yup.object().shape({
                                    phone: Yup.string()
                                      .matches(
                                        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                        'Phone number is not valid',
                                      )
                                      .required('Phone number is required'),
                                  })}
                                  format="+# (###) ###-####"
                                  mask="_"
                                  allowEmptyFormatting={false}
                                  name="phone"
                                  type="tel"
                                  defaultValue={phoneNumber}
                                  onChange={value =>
                                    onEdit('contact', value, {
                                      contactType: ContactDetailType.Phone,
                                      contactIndex: i,
                                    })
                                  }
                                >
                                  <Typography color="primary">
                                    <NumberFormat
                                      value={phoneNumber}
                                      displayType="text"
                                      format="+# (###) ###-####"
                                      mask="_"
                                    />
                                  </Typography>
                                </Editable>
                              </Grid>
                              <Field
                                type="hidden"
                                name={`contact.${ContactDetailType.Phone}[${i}]`}
                              />
                            </Fragment>
                          ))}
                          {websites.map((website, i) => (
                            <Fragment key={website}>
                              <Grid item>
                                <Editable
                                  label="Website"
                                  validationSchema={Yup.object().shape({
                                    website: Yup.string().url(
                                      'Please enter a valid URL in the format https://example.cleodv.xyz',
                                    ),
                                  })}
                                  name="website"
                                  defaultValue={website}
                                  onChange={value =>
                                    onEdit('contact', value, {
                                      contactType: ContactDetailType.Website,
                                      contactIndex: i,
                                    })
                                  }
                                >
                                  <Typography color="primary">{website}</Typography>
                                </Editable>
                              </Grid>
                              <Field
                                type="hidden"
                                name={`contact.${ContactDetailType.Website}[${i}]`}
                              />
                            </Fragment>
                          ))}
                          <Menu
                            anchorEl={addDetailsAnchorEl}
                            keepMounted
                            open={!!addDetailsAnchorEl && !addContactDetailType}
                            onClose={() => setAddDetailsAnchorEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            getContentAnchorEl={null}
                          >
                            {Object.entries(ContactDetailMapping).map(([key, value]) => (
                              <MenuItem
                                key={key}
                                onClick={() => {
                                  setAddDetailsAnchorEl(null);
                                  setAddContactDetailType(key as ContactDetailType);
                                }}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Menu>
                          {addContactDetailType ? (
                            <>
                              <Grid item>
                                {addContactDetailType === ContactDetailType.Phone ? (
                                  <FormPhoneNumberField {...contactDetailsInputProps} />
                                ) : (
                                  <FormTextField {...contactDetailsInputProps} />
                                )}
                              </Grid>
                              <Grid item>
                                <Button variant="contained" color="primary" type="submit">
                                  Add
                                </Button>
                                &nbsp;
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    const nextValues = { ...values };
                                    if (addContactDetailType && nextValues.contact) {
                                      nextValues.contact[addContactDetailType]?.splice(
                                        contactDetailsLength[addContactDetailType],
                                        1,
                                      );
                                    }
                                    setAddContactDetailType(undefined);
                                    resetForm({ values: nextValues });
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </>
                          ) : (
                            <Grid item>
                              <Button
                                className={classes.addButton}
                                variant="outlined"
                                fullWidth
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                  setAddDetailsAnchorEl(e.currentTarget)
                                }
                              >
                                <AddIcon />
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6} container direction="column" spacing={3}>
                          {children}
                          <Grid item>
                            <FormTextField
                              name="description"
                              label={`${title} Description`}
                              fullWidth
                              multiline
                              rows={3}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Box mt={6}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <Paper>
                            <PaperHeader>
                              <PaperTitle title="Leads" />
                            </PaperHeader>
                            <PaperBody>
                              <Grid container direction="column" spacing={4}>
                                <Grid item>
                                  <ProjectCardList
                                    title="Projects"
                                    emptyButtonText="New Project"
                                    total={projects?.length || 0}
                                    projects={projects || []}
                                    origin={
                                      organizations
                                        ? ProjectOrigin.Stakeholder
                                        : ProjectOrigin.Organization
                                    }
                                    onProjectCreate={
                                      organizations && onCreateProject
                                        ? project =>
                                            onCreateProject(project.id, contact.id, {
                                              is_primary: false,
                                              stakeholder_role: StakeholderRole.Other,
                                            })
                                        : undefined
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <LeadCardList
                                    title="Leads"
                                    emptyButtonText="Add Lead"
                                    total={properties?.length || 0}
                                    properties={properties || []}
                                  />
                                </Grid>
                              </Grid>
                            </PaperBody>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Paper>
                            <PaperHeader>
                              <PaperTitle title={organizations ? 'Accounts' : 'Contacts'} />
                            </PaperHeader>
                            <PaperBody>
                              {organizations ? (
                                <OrganizationCardList
                                  title="Accounts"
                                  emptyButtonText="Add Account"
                                  total={organizations.length}
                                  organizations={organizations}
                                  ModalProps={{ stakeholder: contact as Stakeholder }}
                                  onChange={refreshData}
                                />
                              ) : (
                                <Grid container direction="column" spacing={4}>
                                  <Grid item>
                                    <StakeholderCardList
                                      title="Primary Contact"
                                      emptyButtonText="Add Primary"
                                      total={0}
                                      stakeholders={[]}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <StakeholderCardList
                                      title="Contacts"
                                      emptyButtonText="Add Contact"
                                      total={stakeholders?.length || 0}
                                      stakeholders={stakeholders || []}
                                      ModalProps={{ organization: contact as Organization }}
                                      onChange={refreshData}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </PaperBody>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  </PaperBody>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <NetworkSearch showNetworkLink={true} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactDetails;
