import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  renderNumber,
  renderScore,
  renderProperty,
  renderDate,
  renderContacts,
} from './MapDrawerRowRenderers';
import useDrawerCardAddressStyles from './DrawerCardAddress.style';

import { lightGreen, white, darkBlue, hyperlinkBlue } from '../../../../theme';
import { IScore } from '../../../../models/lead';
import { getPropertyById } from '../../../../api/properties';
import { Property } from '../../../../models';

interface IDrawerCard {
  score: IScore;
  isOdd: boolean;
}

const DrawerCardAddress: React.FC<IDrawerCard> = ({ score, isOdd }) => {
  const classes = useDrawerCardAddressStyles();
  const isNew = score.isNew;
  const [propertyDetails, setPropertyDetails] = useState<Property | null>(null);
  const [contactNames, setContactNames] = useState<string[]>([]);

  const getPropertyDetails = useCallback(async () => {
    const details = await getPropertyById(score.propertyId);
    setPropertyDetails(details);
  }, [score.propertyId]);

  const getContacts = useCallback(() => {
    if (propertyDetails?.allStakeholders) {
      const uniqueContacts = [
        ...new Set(propertyDetails?.allStakeholders.map(item => item.name)),
      ] as string[];
      setContactNames(uniqueContacts || []);
    }
  }, [propertyDetails]);

  useEffect(() => {
    getPropertyDetails();
  }, [getPropertyDetails]);

  useEffect(() => {
    getContacts();
  }, [propertyDetails, getContacts]);

  return (
    <Grid
      className={classes.root}
      style={{ backgroundColor: isOdd ? white : lightGreen, cursor: 'pointer' }}
    >
      <Link
        target="_blank"
        to={`/leads/${score.propertyId}`}
        style={{ textDecoration: 'none', color: darkBlue }}
      >
        <Grid className={classes.body}>
          {isNew && (
            <Grid className={classes.newBanner}>
              <Typography variant="caption" className={classes.newText}>
                <strong>NEW</strong>
              </Typography>
            </Grid>
          )}

          <Grid className={classes.rowScore}>{renderScore(score.score, 40)}</Grid>
          <Grid className={classes.property} style={{ color: hyperlinkBlue }}>
            {renderProperty(score.address1, score.city, score.state, score.zipcode || '')}
          </Grid>

          {!!contactNames.length && <Grid>{renderContacts(contactNames, 3)}</Grid>}

          <Grid className={classes.row}>
            {renderNumber('Active Projects', propertyDetails?.projects?.length || 0, false)}
          </Grid>
          <Grid className={classes.row}>{renderNumber('Value', score.value, true)}</Grid>
          <Grid className={classes.row}>{renderNumber('Project Sqft', score.size, false)}</Grid>
          <Grid className={classes.row}>{renderDate('Latest Event', score.eventDate || '')}</Grid>
        </Grid>
      </Link>
    </Grid>
  );
};

export default DrawerCardAddress;
