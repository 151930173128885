import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, makeStyles, Typography } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';

import DatedTextCard from '../../../Common/DatedTextCard';
import type { Feedback, Project } from '../../../../models';
import { light, paleGray, primaryBlue } from '../../../../theme';

interface IProps {
  feedback: Feedback;
  onEdit?: (id: string, notes: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  checkboxHandler?: (feedbackId: string, add: boolean) => void;
  project?: Project;
  readOnly?: boolean;
}
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
  bgCheckbox: {
    padding: '1rem 0 0 0',
    textAlign: 'center',
    background: paleGray,
  },
  checkBG: {
    width: '1rem',
    height: '1rem',
    backgroundColor: light,
    borderRadius: 'unset',
    color: primaryBlue,
  },
  noteBox: {
    width: '100%',
  },
}));

const FeedbackCard: React.FC<IProps> = ({
  feedback,
  onEdit,
  onDelete,
  readOnly,
  checkboxHandler,
  project,
}) => {
  const classes = useStyles();
  const { userDisplayName, updatedAt, notes, id } = feedback;
  const [isChecked, setIsChecked] = useState(project && feedback?.createdAt > project?.createdAt);

  useEffect(() => {
    if (project && checkboxHandler && feedback?.createdAt > project?.createdAt) {
      checkboxHandler(feedback.id, true);
    }
    // eslint-disable-next-line
  }, []);

  return readOnly && checkboxHandler ? (
    <Box className={classes.root}>
      <Grid item className={classes.bgCheckbox}>
        <Checkbox
          icon={<div className={classes.checkBG} />}
          checked={isChecked}
          checkedIcon={<CheckIcon className={classes.checkBG} />}
          onChange={evt => {
            checkboxHandler(feedback.id, evt.target.checked);
            setIsChecked(evt.target.checked);
          }}
        />
      </Grid>
      <Grid item className={classes.noteBox}>
        <DatedTextCard title={userDisplayName} date={updatedAt}>
          <Typography className={classes.text} component="div">
            {notes}
          </Typography>
        </DatedTextCard>
      </Grid>
    </Box>
  ) : (
    <DatedTextCard
      title={userDisplayName}
      date={updatedAt}
      noteDetails={{ id, notes }}
      onDelete={onDelete && (() => onDelete(id))}
      onEdit={onEdit && onEdit}
      options={
        onDelete && [
          {
            label: 'onDelete',
            onClick: () => onDelete(id),
          },
        ]
      }
    >
      <Typography className={classes.text} component="div">
        {notes}
      </Typography>
    </DatedTextCard>
  );
};

export default FeedbackCard;
