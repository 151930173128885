import React, { useCallback, useState } from 'react';
import { Button, Grid, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

import useUniversalSearchStyles from './UniversalSearch.style';

import { getUniversalSearchPagePath } from '../../../utils/page';

const UniversalSearch: React.FC = () => {
  const classes = useUniversalSearchStyles();
  const history = useHistory();
  const [search, setSearch] = useState<string>('');

  const handleSubmit = useCallback(
    e => {
      if (!search) {
        return;
      }
      e.preventDefault();
      history.push(`${getUniversalSearchPagePath()}/${search}`);
      setSearch('');
    },
    [search, history],
  );

  const handleChange = useCallback(
    e => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  return (
    <Grid>
      <form className={classes.root} onSubmit={handleSubmit}>
        <Input
          id="universal-search"
          placeholder={'Search..'}
          className={classes.searchInput}
          disableUnderline={true}
          value={search}
          onChange={handleChange}
        />
        <Button className={classes.searchButton} onClick={handleSubmit} disabled={!search}>
          <SearchIcon style={{ fontSize: '1.25rem' }} />
        </Button>
      </form>
    </Grid>
  );
};

export default UniversalSearch;
