import { DateTime } from 'luxon';

import API from './api';

export interface MetricsProps {
  dateStart: DateTime | null;
  dateEnd: DateTime | null;
  csv: boolean;
}
interface MetricsSystemProps {
  query: string;
  csv: boolean;
}

// Backend timeout is 1 minute.
const metricsConfig = { timeout: 59000 };

export const getMetrics = async ({ dateStart, dateEnd, csv }: MetricsProps): Promise<any> => {
  // Only get metrics for the aggregate of all regions
  const params = ['region=all'];
  if (csv) {
    params.push('csv=true');
  }

  if (dateStart) {
    params.push(`dateStart=${encodeURIComponent(dateStart.toUTC().toISO())}`);
  }
  if (dateEnd) {
    params.push(`dateEnd=${encodeURIComponent(dateEnd.toUTC().toISO())}`);
  }
  const url = `/metrics/leads?${params.join('&')}`;
  const { data } = await API.get(url, metricsConfig);
  return data;
};

export const getSystemMetrics = async ({ query, csv }: MetricsSystemProps): Promise<any> => {
  // Only get metrics for the aggregate of all regions
  const params = ['region=all'];
  if (csv) {
    params.push('csv=true');
  }

  if (query) {
    params.push(query);
  }

  const url = `/metrics/system?${params.join('&')}`;
  const { data } = await API.get(url, metricsConfig);
  return data;
};

export const getEtlMetrics = async ({ dateStart, dateEnd, csv }: MetricsProps): Promise<any> => {
  const params = [];
  if (csv) {
    params.push('csv=true');
  }

  if (dateStart) {
    params.push(`dateStart=${encodeURIComponent(dateStart.toUTC().toISO())}`);
  }
  if (dateEnd) {
    params.push(`dateEnd=${encodeURIComponent(dateEnd.toUTC().toISO())}`);
  }
  const url = `/metrics/etl?${params.join('&')}`;
  const { data } = await API.get(url, metricsConfig);
  return data;
};
