import { TableState } from 'react-table';

import API from './api';

import TableSetting, { ITableSettingResponse } from '../models/tableSetting';

export interface TableSettingsPayload<TableType extends Record<string, any>> {
  table_reference: string;
  saved_settings: Partial<TableState<TableType>>;
}

export const getTableSettingsByUser = async (): Promise<TableSetting[]> => {
  const { data } = await API.get(`/user-table-settings/`);
  return (data as ITableSettingResponse[]).map(item => new TableSetting(item));
};

export const saveTableSetting = async (
  payload: TableSettingsPayload<any>,
): Promise<TableSetting[]> => {
  const { data } = await API.post('/user-table-settings/', payload);
  return (data as ITableSettingResponse[]).map(item => new TableSetting(item));
};
