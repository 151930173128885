import omit from 'lodash/omit';
import { SetterOrUpdater } from 'recoil';

import {
  Comparators,
  FilterData,
  FilterItem,
  FilterKey,
  FilterOptionsItem,
  OptionValue,
  TagsDisplay,
} from '../../../../../types';
import { stateAbbreviations } from '../../../../../utils/stateAbbreviations';

interface IHandleStateDeselectionFromFilterProps {
  valuesArrayAfterDeselection: OptionValue[];
  filterItem: FilterItem;
  filterItemOption: FilterOptionsItem;
  allKeys: FilterData;
  stateAssociatedWithCounty: string | undefined;
  setAllKeys: SetterOrUpdater<FilterData | undefined>;
  setAllFilterTags: SetterOrUpdater<TagsDisplay[]>;
  setSelectedKeys: SetterOrUpdater<FilterData>;
  keySelection: FilterData;
  countiesInState: string[];
}

/**
 * Function helps remove the state filter along with associated counties (sub-filters) and tags on deselection
 * */
export const handleStateCountyStateDeselectionFromFilter = ({
  valuesArrayAfterDeselection,
  setSelectedKeys,
  filterItem,
  filterItemOption,
  allKeys,
  stateAssociatedWithCounty,
  setAllKeys,
  setAllFilterTags,
  keySelection,
  countiesInState,
}: IHandleStateDeselectionFromFilterProps) => {
  if (valuesArrayAfterDeselection?.length > 0) {
    // If state filter, remove the county filters associated
    setSelectedKeys((currentSelectedKeys: FilterData) => {
      const updatedSelectedKeys = {
        [filterItem.filterKey]: {
          ...currentSelectedKeys[filterItem.filterKey],
          [filterItemOption.comparator]: valuesArrayAfterDeselection,
        },
      };

      // If countyState filter, remove the state filter and tag associated
      if (filterItem.filterKey === FilterKey.CountyState) {
        const allStatesIn = allKeys[FilterKey.State][Comparators.In] as string[] | undefined;
        if (allStatesIn?.length && stateAssociatedWithCounty) {
          const statesArrayAfterDeselection = allStatesIn.filter(
            i => i?.toString() !== stateAssociatedWithCounty?.toString(),
          );
          // handle if comparator becomes empty
          updatedSelectedKeys[FilterKey.State] = statesArrayAfterDeselection.length
            ? {
                ...currentSelectedKeys[FilterKey.State],
                [Comparators.In]: statesArrayAfterDeselection,
              }
            : {};
        }
      } else if (filterItem.filterKey === FilterKey.State) {
        // If state filter, remove the county filters associated and update selected countyState keys
        let countyStateKeysLeft: string[] = (allKeys?.countyState?.in || []) as string[];
        countyStateKeysLeft = countyStateKeysLeft.filter(
          (filter: string) => !countiesInState.map(county => county).includes(filter),
        );
        updatedSelectedKeys[FilterKey.CountyState] = { [Comparators.In]: countyStateKeysLeft };
      }
      keySelection = updatedSelectedKeys;
      return updatedSelectedKeys;
    });
    setAllKeys(prev => ({ ...prev, ...keySelection }));
    setAllFilterTags((prev: any) =>
      prev.filter((filterTag: any) => filterTag.value !== `${filterItemOption.label}`),
    );
    if (filterItem.filterKey === FilterKey.CountyState) {
      if (stateAssociatedWithCounty) {
        setAllFilterTags((prev: any) =>
          prev.filter(
            (filterTag: any) =>
              filterTag.value !== stateAbbreviations[stateAssociatedWithCounty || ''],
          ),
        );
      }
    }
  } else {
    // If state filter, remove the county filters associated and remove the comparator
    setSelectedKeys(currentSelectedKeys => {
      const removeComparator = { ...currentSelectedKeys };
      let allKeys = { ...removeComparator };
      allKeys = {
        ...removeComparator,
        [filterItem.filterKey]: omit(
          removeComparator[filterItem.filterKey],
          filterItemOption.comparator,
        ),
      };
      keySelection = allKeys;
      return allKeys;
    });
    setAllKeys(prev => {
      return { ...prev, ...keySelection };
    });
  }
};
