import React, { ReactNode } from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import { black, primaryBlue, white } from '../../theme';

type IProps = DialogProps & {
  title: string | ReactNode;
  content: string | ReactNode;
  confirmText?: string | ReactNode;
  cancelText?: string | ReactNode;
  onConfirm: (e: React.MouseEvent) => void;
  onCancel: (e: React.MouseEvent) => void;
  newDesign?: boolean;
};

const ConfirmationDialog: React.FC<IProps> = ({
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  newDesign = false,
  ...rest
}) => {
  if (newDesign) {
    //  New Design has no title, Text and buttons are centered with primaryBlue color scheme
    return (
      <Dialog disableBackdropClick {...rest}>
        <DialogContent>
          <DialogContentText
            style={{ color: black, width: '15rem', textAlign: 'center' }}
            variant={'body1'}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            onClick={onConfirm}
            variant={'contained'}
            style={{ backgroundColor: primaryBlue, color: white }}
          >
            {confirmText}
          </Button>
          <Button
            onClick={onCancel}
            variant={'outlined'}
            style={{ color: primaryBlue, borderColor: primaryBlue }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog disableBackdropClick {...rest}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button onClick={onConfirm} color="primary">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default ConfirmationDialog;
