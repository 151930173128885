import { makeStyles } from '@material-ui/core';

import { primaryBlue } from '../../../theme';

export const useAccountsPageStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '0',
    width: '100vw',
    height: 'calc(100vh - 64px)',
    maxWidth: 'none',
    overflow: 'hidden',
  },
  paper: {
    flexGrow: 1,
    overflow: 'scroll',
  },
  menuTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    height: '4rem',
    alignContent: 'center',
    borderBottom: `1px solid ${primaryBlue}`,
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  body: {
    height: 'calc(100vh - 4rem - 48px - 64px - 1rem)',
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    height: '100%',
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  list: {
    width: '40%',
    height: '100%',
    borderRadius: '4px',
    overflow: 'scroll',
  },
  mainRight: {
    width: '60%',
    border: `1.5px solid ${primaryBlue}`,
    borderRadius: '4px',
    height: '100%',
    bottom: '0%',
    padding: '.5rem',
  },
  rightTop: {
    display: 'flex',
    flexDirection: 'row',
    height: '54%',
    width: '100%',
  },
  details: {
    width: '50%',
  },
  contacts: {
    width: '50%',
  },
  rightBottom: {
    display: 'flex',
    height: '45%',
    width: '100%',
  },
  dropDown: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  count: {
    backgroundColor: 'transparent',
    paddingLeft: '.5rem',
  },
});
