import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import PLDemoted from './Demoted/PLDemoted';
import ProjectLeadsConvertedArchive from './Converted/ProjectLeadsConvertedArchive';

import { useArchiveStyles } from '../../Leads/Archive/Archive.styles';
import { ScoreStatus } from '../../../../models/lead';
import { ArchiveMenu } from '../../Leads/Archive/Archive.config';
import { getTransitionedProjectCount } from '../../../../api/projects';
import { FilterData } from '../../../../types';
import OutcomeFilter from '../OutcomeFilter';
import { tabStyles } from '../../../Common/CleoTab.styles';

const PLArchive: React.FC = () => {
  const classes = useArchiveStyles();

  const [archiveTab, setArchiveTab] = useState<ScoreStatus>(ScoreStatus.Converted);
  const [cDemoted, setCDemoted] = useState<number>(0);
  const [cConverted, setCConverted] = useState<number>(0);
  const [outcomeFilter, setOutcomeFilter] = useState<FilterData>({} as FilterData);

  const getTransitionedCounts = useCallback(async () => {
    const data = await getTransitionedProjectCount();
    setCDemoted(data.cDemoted);
    setCConverted(data.cConverted);
  }, []);

  const handleTabChange = useCallback(
    (e, nextTab: ScoreStatus) => {
      e.preventDefault();
      setArchiveTab(nextTab);
      setOutcomeFilter({});
      getTransitionedCounts();
    },
    [setArchiveTab, getTransitionedCounts],
  );

  useEffect(() => {
    getTransitionedCounts();
  }, [getTransitionedCounts]);

  return (
    <Grid className={classes.root}>
      <TabContext value={archiveTab}>
        <Box className={classes.tabsContainer}>
          <TabList className={classes.tabList} onChange={handleTabChange} aria-label="archive tabs">
            {ArchiveMenu.map((tabItem, idx) => {
              const transitionedProjectCount =
                tabItem.status === ScoreStatus.Demoted ? cDemoted : cConverted;
              return (
                <Tab
                  key={idx}
                  classes={tabStyles({ fontSize: '1rem' })}
                  label={`${tabItem.text} (${transitionedProjectCount || 0})`}
                  value={tabItem.status}
                />
              );
            })}
          </TabList>
          {archiveTab === ScoreStatus.Converted && (
            <OutcomeFilter setOutcomeFilter={setOutcomeFilter} />
          )}
        </Box>
        <Box className={classes.tabPanelContainer}>
          <TabPanel value={'converted'} className={classes.tabPanel}>
            <ProjectLeadsConvertedArchive
              outcomeFilter={outcomeFilter}
              setCConverted={setCConverted}
            />
          </TabPanel>
          <TabPanel value={'demoted'} className={classes.tabPanel}>
            <PLDemoted reloadTabs={getTransitionedCounts} />
          </TabPanel>
        </Box>
      </TabContext>
    </Grid>
  );
};

export default PLArchive;
