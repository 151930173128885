import { Box, Chip, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import omit from 'lodash/omit';

import { useLeadsStyles } from './Leads.styles';
import { handleDeleteStateCountyStateFilterTag } from './LeadsFilter/LocationFilterHelpers/HandleDeleteStateCountyStateFilterTag';
import { handleDeleteActionStageSubActionStageFilterTag } from './LeadsFilter/LocationFilterHelpers/HandleDeleteActionStageSubActionStageFilterTag';

import {
  allKeysSelectedState,
  clearFiltersState,
  filterInputsToClearState,
  allFilterTagsState,
  filterTagsToDisplayState,
  countiesInEachStateState,
  subActionStagesInEachActionStageState,
  filterOptionState,
  previousWinSelectedState,
} from '../../../state/atoms/filters';
import { FilterData, FilterKey, Optional, TagsDisplay } from '../../../types';
import { toTitleCase } from '../../../utils/string';
import { useMessages } from '../../../state/contexts';
import {
  defaultSpecRateFilterValue,
  specRateSelectedKeysState,
} from '../../../state/atoms/filterSpecRates';

interface IProps {
  handleFilterChange: (filter: FilterData) => void;
}

const FilterTags: React.FC<IProps> = ({ handleFilterChange }: IProps) => {
  const classes = useLeadsStyles();
  const [allKeysSelected, setAllKeysSelected] = useRecoilState(allKeysSelectedState);
  const [filterTagsToDisplay, setFilterTagsToDisplay] = useRecoilState(filterTagsToDisplayState);
  const setAllFilterTags = useSetRecoilState(allFilterTagsState);
  const setFilterInputsToClear = useSetRecoilState(filterInputsToClearState);
  const setClearFilters = useSetRecoilState(clearFiltersState);
  const countiesInEachState = useRecoilValue(countiesInEachStateState);
  const subActionStagesInEachActionStage = useRecoilValue(subActionStagesInEachActionStageState);
  const [specRateSelectedKeys, setSpecRateSelectedKeys] = useRecoilState(specRateSelectedKeysState);
  const setFilterOption = useSetRecoilState<Optional<FilterData>>(filterOptionState);
  const setPreviousWinSelected = useSetRecoilState(previousWinSelectedState);
  const { setErrorMessage } = useMessages();

  const handleDeleteTag = useCallback(
    (obj: TagsDisplay) => {
      if (allKeysSelected) {
        //handle deletion of specRate filter tags
        if (
          [
            FilterKey.ArmstrongSpecRate,
            FilterKey.USGSpecRate,
            FilterKey.CertainteedSpecRate,
            FilterKey.RockfonSpecRate,
          ].includes(obj.key as FilterKey)
        ) {
          try {
            const copySpecRateState = { ...specRateSelectedKeys };
            copySpecRateState[obj.key] = { ...defaultSpecRateFilterValue };

            const normalizedSpecRates: FilterData = {};
            Object.keys(specRateSelectedKeys).forEach(key => {
              const [[comparator, value]] = Object.entries(specRateSelectedKeys[key]);
              const [[defaultComparator, defaultValue]] = Object.entries(
                defaultSpecRateFilterValue,
              );

              if (
                !(key === obj.key) &&
                !(comparator === defaultComparator && value === defaultValue)
              ) {
                normalizedSpecRates[key] = { [comparator]: (value as number) / 100 };
              }
            });

            setSpecRateSelectedKeys(copySpecRateState);
            setFilterOption({ ...allKeysSelected, ...normalizedSpecRates });
            setFilterTagsToDisplay((prev: any) => prev.filter((tag: any) => tag.key !== obj.key));
          } catch (err) {
            setErrorMessage('There was a problem deleting filter tag', err);
          }
          return;
        }
        // handle deletion of previous win filter tag
        if (obj.key === FilterKey.PreviousWins) {
          try {
            setPreviousWinSelected(false);
            setFilterTagsToDisplay((prev: any) => prev.filter((tag: any) => tag.key !== obj.key));
          } catch (err) {
            setErrorMessage('There was a problem deleting filter tag', err);
          }
          return;
        }

        // Handling all state and countyState filter tag deselection here
        if (obj.key === FilterKey.State || obj.key === FilterKey.CountyState) {
          try {
            handleDeleteStateCountyStateFilterTag({
              allKeysSelected,
              obj,
              setClearFilters,
              setAllKeysSelected,
              handleFilterChange,
              setAllFilterTags,
              setFilterTagsToDisplay,
              countiesInEachState,
            });
          } catch (err) {
            setErrorMessage('There was a problem deleting filter tag', err);
          }
          return;
        }

        if (obj.key === FilterKey.ActionStage || obj.key === FilterKey.SubActionStage) {
          try {
            handleDeleteActionStageSubActionStageFilterTag({
              allKeysSelected,
              obj,
              setClearFilters,
              setAllKeysSelected,
              handleFilterChange,
              setAllFilterTags,
              setFilterTagsToDisplay,
              subActionStagesInEachActionStage,
            });
          } catch (err) {
            setErrorMessage('There was a problem deleting filter tag', err);
          }
          return;
        }

        if (obj.isInput && obj.id) {
          let selectedKeys: FilterData = { ...allKeysSelected };
          const inputId: string = obj.id;
          selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);
          if (selectedKeys[obj.key]['selectionId']) {
            selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);
          }

          setAllKeysSelected(selectedKeys);
          handleFilterChange(selectedKeys);
          setAllFilterTags((prev: any) => prev.filter((tag: any) => tag.id !== inputId));
          setFilterTagsToDisplay((prev: any) => prev.filter((tag: any) => tag.id !== inputId));
          setFilterInputsToClear(prev => [...prev, inputId]);
        } else {
          let selectedKeys: any = { ...allKeysSelected };
          if (obj.label === 'Search Project') {
            setClearFilters(true);
            return;
          }

          const values = selectedKeys[obj.key][obj.comparator];
          if (!Array.isArray(values)) {
            selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);

            if (selectedKeys[obj.key]['selectionId']) {
              selectedKeys = omit(selectedKeys, `${obj.key}.${obj.comparator}`);
            }
          }

          const removeList = Array.isArray(values)
            ? values.filter((i: string) => i !== obj.filterValue)
            : values;

          if (Array.isArray(values) && removeList.length > 0) {
            selectedKeys = {
              ...selectedKeys,
              [obj.key]: {
                ...selectedKeys[obj.key],
                [obj.comparator]: removeList,
              },
            };

            setAllKeysSelected(selectedKeys);
            handleFilterChange(selectedKeys);
            setAllFilterTags((prev: any) =>
              prev.filter((filterTag: any) => filterTag.value !== obj.value),
            );
            setFilterTagsToDisplay((prev: any) =>
              prev.filter((filterTag: any) => filterTag.value !== obj.value),
            );
          } else {
            let allKeys = { ...selectedKeys };
            allKeys = {
              ...allKeys,
              [obj.key]: omit(allKeys[obj.key], obj.comparator),
            };
            setAllKeysSelected(allKeys);
            handleFilterChange(allKeys);
            setAllFilterTags((prev: any) =>
              prev.filter((filterTag: any) => filterTag.value !== obj.value),
            );
            setFilterTagsToDisplay((prev: any) =>
              prev.filter((filterTag: any) => filterTag.value !== obj.value),
            );
          }
        }
      }
    },
    [
      allKeysSelected,
      handleFilterChange,
      setAllKeysSelected,
      setAllFilterTags,
      setClearFilters,
      setFilterInputsToClear,
      setFilterTagsToDisplay,
      setSpecRateSelectedKeys,
      setFilterOption,
      specRateSelectedKeys,
      countiesInEachState,
      subActionStagesInEachActionStage,
      setErrorMessage,
      setPreviousWinSelected,
    ],
  );

  return (
    <Box className={classes.filterTagsContainer}>
      <Typography className={classes.filterTagsLabel}>Selected Filters:</Typography>
      <div className={classes.filterTagsScrollContainer}>
        <div className={classes.filterTags}>
          {filterTagsToDisplay.map((obj: TagsDisplay, i: number) => {
            return (
              <div className={classes.filterTag} key={`${i}`}>
                <Chip
                  className={classes.filterChip}
                  label={
                    obj.key === FilterKey.PreviousWins
                      ? `${toTitleCase(obj.label)}`
                      : `${toTitleCase(obj.label)}: ${
                          obj.key === 'value' || obj.key === 'volume_of_business'
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(Number(obj.value))
                            : obj.key === FilterKey.PreviousWins
                            ? ''
                            : obj.value
                        }`
                  }
                  onDelete={() => handleDeleteTag(obj)}
                  variant="outlined"
                  size="small"
                />
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default FilterTags;
