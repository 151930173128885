import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';

import { ReactComponent as Eclipse } from '../../resources/images/eclipse.svg';
import { ReactComponent as IndicatorBug } from '../../resources/images/indicator_bug.svg';
interface IProps {
  score: number;
  height?: number;
  width?: number;
  stroke?: string;
  fontSize?: string;
}

const useStyles = makeStyles({
  scoreIndicator: {
    margin: '0 auto',
    padding: '0.5rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  boundary: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicatorContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: ({ width }: IProps) => (width ? width + 'px' : '112px'),
    width: ({ width }: IProps) => (width ? width + 'px' : '112px'),
    borderRadius: '100%',
    transform: ({ score }: IProps) => `rotate(${Math.round(180 * (score / 100) - 90)}deg)`,
  },
  indicatorBug: {
    position: 'absolute',
    top: '-4px',
    'z-index': '20',
    transform: 'rotate(-90deg)',
  },
  scoreContainer: {
    position: 'relative',
    'z-index': '10',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
  score: {
    paddingTop: ({ height }: IProps) => (height ? `${height * 0.3}px` : '1rem'),
    margin: '0',
    fontSize: ({ fontSize }: IProps) => fontSize ?? '36px',
    fontFamily: '"Noto Sans", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '1.25',
  },
});

const ProjectScoreIndicator: React.FC<IProps> = (props: IProps) => {
  const { score, height, width, stroke } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.scoreIndicator}>
      <div className={classes.boundary}>
        <Eclipse stroke={stroke ?? '#1FB4B1'} width={width ?? 112} height={height ?? 61} />
        <div className={classes.indicatorContainer}>
          <div className={classes.indicatorBug}>
            <IndicatorBug width={width ? width * 0.25 : 28} height={height ? height * 0.25 : 15} />
          </div>
        </div>
      </div>
      <div className={classes.scoreContainer}>
        <p className={classes.score}>{score}</p>
      </div>
    </div>
  );
};

export default memo(ProjectScoreIndicator);
