import { toTitleCase } from '../../../utils/string';
import { StatusBarTabItem } from '../Leads/LeadsTable/StatusBar';
import { ProjectLeadStatus } from '../../../models/projectLead';

export const ProjectLeadsMenu: StatusBarTabItem<ProjectLeadStatus>[] = [
  {
    status: ProjectLeadStatus.Home,
    text: toTitleCase(ProjectLeadStatus.Home),
    defaultFilter: { ProjectStatus: { eq: 'active' } },
  },
  {
    status: ProjectLeadStatus.Favorites,
    text: toTitleCase(ProjectLeadStatus.Favorites),
    defaultFilter: { isFavorite: { eq: true } },
  },
  {
    status: ProjectLeadStatus.SavedFilters,
    text: 'Saved Filters',
    defaultFilter: {},
  },
  {
    status: ProjectLeadStatus.Archive,
    text: toTitleCase(ProjectLeadStatus.Archive),
    defaultFilter: {},
  },
  {
    status: ProjectLeadStatus.Hidden,
    text: 'Filter Details',
    defaultFilter: {},
    disabled: true,
  },
];
