import React from 'react';

import InteractiveMap from './InteractiveMap/InteractiveMap';
import useAtAGlanceStyles from './AtAGlance.style';

const AtAGlancePage: React.FC = () => {
  const classes = useAtAGlanceStyles();
  return (
    <div>
      <InteractiveMap className={classes.map} />
    </div>
  );
};

export default AtAGlancePage;
