export enum SFStageName {
  '10 Day List' = '10 Day List',
  'Active' = 'Active',
  'Closed Lost' = 'Closed Lost',
  'Closed Won' = 'Closed Won',
  'Complete' = 'Complete',
  'Discard' = 'Discard',
  'Ghosted' = 'Ghosted',
  'Kanopi Install Influence' = 'Kanopi Install Influence',
  'No Response' = 'No Response',
  'Order Placed' = 'Order Placed',
  'Out of Budget' = 'Out of Budget',
  'Out Of Scope' = 'Out Of Scope',
  'Pending' = 'Pending',
  'Pre-qualified' = 'Pre-qualified',
}
