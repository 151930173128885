import React, { useState } from 'react';
import { Clear as ClearIcon } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import { hyperlinkBlue } from '../../../../theme';
import { hideProject } from '../../../../api/projects';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import { useMessages } from '../../../../state/contexts';

interface IProps {
  reloadItems?: () => Promise<void>;
  projectId: string;
  projectName: string;
  isConverted?: boolean;
  hideBackdrop?: boolean;
}

const ConfirmHide: React.FC<IProps> = ({ reloadItems, projectId }) => {
  const [confirmHideOpen, setConfirmHideOpen] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useMessages();
  return (
    <Grid>
      {
        <ClearIcon
          style={{ color: hyperlinkBlue }}
          onClick={async (e: React.MouseEvent) => {
            setConfirmHideOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      }
      <ConfirmationDialog
        open={confirmHideOpen}
        title={'Deleting Archived Project Lead'}
        content={'This action cannot be undone. Are you sure you want to remove this project?'}
        onConfirm={async e => {
          e.stopPropagation();
          e.preventDefault();
          try {
            await hideProject(projectId || '');
            setConfirmHideOpen(false);
            if (reloadItems) {
              await reloadItems();
            }
            setSuccessMessage('The project has been removed');
          } catch (err) {
            if (reloadItems) {
              await reloadItems();
            }
            setErrorMessage('There was an error removing this project: ', err);
          }
        }}
        onCancel={e => {
          e.stopPropagation();
          e.preventDefault();
          setConfirmHideOpen(false);
        }}
        /**
         * When anything else is clicked, follow onCancel logic.
         * Following props failed to be successful: onBackdropClick, onClose
         */
        onClose={() => {
          //  Used to escape out of confirmation dialog
          setConfirmHideOpen(false);
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setConfirmHideOpen(false);
        }}
        hideBackdrop={true}
        newDesign={true}
        confirmText={'Yes'}
        cancelText={'No'}
      />
    </Grid>
  );
};

export default ConfirmHide;
