// Interface of Project View Filter
// PL Archive

import { ProjectLeadTable } from '../../Leads/Archive/Archive.config';

export enum ArchivedStatus {
  Converted = 'converted',
  Demoted = 'demoted',
}

export type SortColumns<T> = Record<keyof T, string[]>;

export type ArchivedPLTableCols = ProjectLeadTable & {
  transitionReason: string;
  transitionDate: string;
  projectStatus: string;
  result: string;
};

export const sortColumnsPLArchive: SortColumns<ArchivedPLTableCols> = {
  property: ['city', 'state', 'address1'],
  event: ['eventDate', 'score'],
  projectId: ['projectId'],
  projectName: ['projectName'],
  biddate: ['bidDate'],
  actionStage: ['actionStage'],
  publishDate: ['eventDate'],
  hide: ['hide'],
  value: ['value'],
  primaryProjectType: ['primaryProjectType'],
  projectType: ['projectType'],
  categories: ['categories'],
  lastUpdated: ['lastUpdated'],
  projectScore: ['projectScore'],
  latestEventSource: ['dataSource', 'lastUpdated'],
  result: ['outcome'],
  // Won't be able to sort by below cols - the way that view filters work
  transitionReason: [''],
  transitionDate: [''],
  projectStatus: ['projectStatus'],
  isFavorite: ['projectScore', 'lastUpdated'],
  county: ['county', 'state'],
  state: ['state', 'county'],
  region: ['region'],
};
