import { selector } from 'recoil';

import { getUsers } from '../../api/users';

export const usersFetchState = selector({
  key: 'usersFetch',
  get: async () => {
    const usersResponse = await getUsers();
    return usersResponse.items;
  },
});
