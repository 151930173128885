import { StatusBarTabItem } from '../Leads/LeadsTable/StatusBar';
import { toTitleCase } from '../../../utils/string';

export enum AccountPageStatus {
  Home = 'home',
  Favorites = 'favorites',
  SavedFilters = 'savedFilters',
  Hidden = 'hidden',
}

export const AccountsPageMenu: StatusBarTabItem<AccountPageStatus>[] = [
  {
    status: AccountPageStatus.Home,
    text: toTitleCase(AccountPageStatus.Home),
    defaultFilter: {},
  },
  {
    status: AccountPageStatus.Favorites,
    text: toTitleCase(AccountPageStatus.Favorites),
    defaultFilter: {},
    disabled: true,
  },
  {
    status: AccountPageStatus.SavedFilters,
    text: 'Saved Filters',
    defaultFilter: {},
  },
  {
    status: AccountPageStatus.Hidden,
    text: 'Filter Details',
    defaultFilter: {},
    disabled: true,
  },
];
