// import { TableState } from 'react-table';

import { BaseModel } from '.';

import { IBaseModelResponse } from './baseModel';

export enum TableReference {
  ProjectLeadsHome = 'project-leads-home',
  ProjectLeadsFavorites = 'project-leads-favorites',
}

export type ITableSettingResponse = IBaseModelResponse & {
  user_id: string;
  table_reference: TableReference;
  saved_settings: Record<string, any>;
};

export default class TableSetting extends BaseModel {
  userId: string;
  tableReference: TableReference;
  savedSettings: Record<string, unknown>;

  constructor(data: ITableSettingResponse) {
    super(data);

    const { user_id, table_reference, saved_settings } = data;
    this.userId = user_id;
    this.tableReference = table_reference;
    this.savedSettings = saved_settings;
  }
}
