import {
  styled,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Select,
  MenuItem,
  Switch,
  Chip,
} from '@material-ui/core';
import React, { useState } from 'react';
import CancelIcon from '@material-ui/icons/Close';

import { SectionHeader, Title, SubTitle, Container } from '../../Common/StyleGuideStyles';
import { gray } from '../../../theme';
import CheckItem from '../../Common/CheckItem';
import MultiSelect from '../../Common/MultiSelect';

const Section = styled('div')({
  padding: '0px 10px 40px 0px',
});

const Column = styled(Box)({
  padding: '15px',
  display: 'flex',
});

const IconButtonCancel = styled(IconButton)({
  borderRadius: '20px',
});

const IconButtonCancelLarge = styled(IconButton)({
  borderRadius: '40px',
});

const SecondaryLink = styled(Link)({
  color: gray,
  component: 'button',
});

/**
 * This files servers for representing elements used by the application
 */
function ElementStyle(): JSX.Element {
  const [location, setLocation] = useState('LA');
  const handleSelectionChange = (event: React.ChangeEvent<{ value: any }>) => {
    setLocation(event.target.value as string);
  };
  return (
    <div>
      <SectionHeader>Elements</SectionHeader>
      <Section>
        <Title>Controls</Title>
        <Box boxShadow={0}>
          <Container>
            <SubTitle>Buttons - Lg</SubTitle>
            <Grid container item>
              <Column>
                <Button size="large" variant="contained" color="primary">
                  Continue
                </Button>
              </Column>
              <Column>
                <Button size="large" variant="outlined" color="primary">
                  Cancel
                </Button>
              </Column>
            </Grid>
            <SubTitle>Buttons - Sm</SubTitle>
            <Grid container item>
              <Column>
                <Button size="small" variant="contained" color="primary">
                  Apply
                </Button>
              </Column>
              <Column>
                <Button size="small" variant="outlined" color="primary">
                  Clear
                </Button>
              </Column>
            </Grid>
            <SubTitle>Buttons Link</SubTitle>
            <Grid container item>
              <Column>
                <Link color="primary" component="button">
                  Apply
                </Link>
              </Column>
              <Column>
                <SecondaryLink>Support</SecondaryLink>
              </Column>
            </Grid>
            <SubTitle>Buttons FAB</SubTitle>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Column>
                <IconButtonCancelLarge aria-label="close" size="medium">
                  <CancelIcon fontSize="large" />
                </IconButtonCancelLarge>
              </Column>
              <Column>
                <IconButtonCancel aria-label="close" size="small">
                  <CancelIcon fontSize="small" />
                </IconButtonCancel>
              </Column>
            </Box>
          </Container>
        </Box>
      </Section>
      <Section>
        <Title>Selection</Title>
        <Box boxShadow={0}>
          <Container>
            <SubTitle>Forms - Fields</SubTitle>
            <TextField label="Maximum" variant="outlined" />
            <SubTitle>Forms - Dropdowns</SubTitle>
            <Select
              label="Location"
              labelId="select-label"
              id="select"
              variant="outlined"
              onChange={handleSelectionChange}
              value={location}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'LA'}>Los Angeles, CA</MenuItem>
            </Select>
            <SubTitle>Forms - Dropdowns - Multi</SubTitle>
            <Grid container item>
              <MultiSelect
                items={[
                  'Torrance, CA',
                  'Hollywood, CA',
                  'Manhattan Beach, CA',
                  'New York City, NY',
                  'Seattle, WA',
                ]}
                selectedItems={['Torrance, CA', 'Hollywood, CA']}
                label={'Location'}
              />
            </Grid>
            <SubTitle>Forms - Inputs - Selection - Check</SubTitle>
            <CheckItem label={'Office'} checkStatus={true} />
            <Box />
            <CheckItem label={'HealthCare'} checkStatus={false} />
            <SubTitle>Forms - Inputs - Selection - Radio</SubTitle>
            <Switch inputProps={{ 'aria-label': 'primary checkbox' }} />
          </Container>
        </Box>
      </Section>
      <Section>
        <Title>Label</Title>
        <Box boxShadow={0}>
          <Container>
            <SubTitle>Chips</SubTitle>
            <Grid container item>
              <Column>
                <Chip color="primary" label="Ceiling" />
              </Column>
              <Column>
                <Chip color="secondary" label="Ceiling" />
              </Column>
            </Grid>
          </Container>
        </Box>
      </Section>
    </div>
  );
}

export default ElementStyle;
