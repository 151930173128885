import { makeStyles } from '@material-ui/core';

import { white } from '../../../../theme';

const useNotificationsListStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
    background: '#EAF5F3',
  },
  notification: {
    padding: theme.spacing(2, 6, 0, 6),
    width: '30rem',
  },
  noNotifications: {
    padding: theme.spacing(2, 6, 0, 6),
    width: '30rem',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    padding: theme.spacing(5, 6, 2, 6),
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '30rem',
  },
  notifications: {
    overflowY: 'auto',
    maxHeight: '38.5rem',
  },
  divider: {
    width: '20%',
    background: '#0F1A24',
  },
  confirmation: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '.9rem',
  },
  confirmationText: {
    display: 'flex',
    justifyContent: 'center',
  },
  confirmationButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  button: {
    color: white,
    backgroundColor: '#4E9AF3',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#4E9AF3',
    },
  },
}));

export default useNotificationsListStyles;
