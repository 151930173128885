import API from './api';
import type { IResponse, APICancelToken } from './api';

import { IScore, AddressesTable, sortColumns } from '../models/lead';
import encodeFilter from '../utils/encodeFilter';
import { sanitizeFilterConfig } from '../utils/filter';
import { ViewFilterRequest } from '../Components/Pages/Leads/Archive/Archive.config';

export async function getScores(
  scoreRequest: ViewFilterRequest<AddressesTable>,
  cancelToken?: APICancelToken,
): Promise<IResponse<IScore>> {
  const configOptions = sanitizeFilterConfig<AddressesTable>(
    scoreRequest,
    sortColumns,
    'addresses',
  );

  const { data } = await API.post(
    '/scores',
    {
      get_filter: encodeFilter(configOptions),
    },
    {
      cancelToken,
    },
  );
  return data as IResponse<IScore>;
}
