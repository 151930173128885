import React from 'react';
import { Grid, Typography, Paper, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import useNotificationCardStyles from './NotificationCard.styles';

import { white } from '../../../../theme';
import { getLeadDetailsPagePath } from '../../../../utils/page';
import { DetailedNotification } from '../../../../api/notifications';

interface IProps {
  notification: DetailedNotification;
  deleteNotification: (ids: string[]) => Promise<void>;
}

const NotificationCard: React.FC<IProps> = ({ notification, deleteNotification }: IProps) => {
  const event =
    notification.updated_contacts === '' || !notification.updated_contacts
      ? 'Spec Alerts'
      : 'Contacts';
  const formattedDate = new Date(notification.updated_at).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const classes = useNotificationCardStyles();

  return (
    <Paper
      component={props => <Link to={getLeadDetailsPagePath(notification.property_id)} {...props} />}
      className={classes.root}
      elevation={0}
      style={{ backgroundColor: !notification.viewed ? white : '#F4F9FC' }}
    >
      <Grid className={classes.main}>
        {!notification.viewed && (
          <Grid className={classes.newBanner}>
            <Typography variant="subtitle1" className={classes.newText}>
              <strong>NEW</strong>
            </Typography>
          </Grid>
        )}
        <Grid container spacing={0} alignItems="center" className={classes.buttonContainer}>
          <IconButton
            className={classes.removeButton}
            size="small"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              deleteNotification([notification.id]);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={classes.container}
          style={{ alignItems: 'flex-start' }}
        >
          <Grid item style={{ width: '75%' }}>
            <Typography
              className={classes.dense}
              color="primary"
              variant="subtitle2"
              component="span"
            >
              {event}
            </Typography>
            <Typography className={classes.dense} color="primary" variant="body2" component="span">
              {' have been added to '}
            </Typography>
            <Typography
              className={classes.dense}
              color="primary"
              variant="subtitle2"
              component="span"
            >
              {notification.title}
            </Typography>
            {notification.sfdc_opportunity_id && notification.sfdc_opportunity_id !== '' && (
              <div>
                <a
                  href={`https://armstrongceilings.my.salesforce.com/${notification.sfdc_opportunity_id}`}
                >
                  <span>{' View more.'}</span>
                </a>
              </div>
            )}
          </Grid>
          <Grid item style={{ flexWrap: 'wrap' }}>
            <Typography className={classes.dense} color="primary" variant="body2" component="span">
              {formattedDate}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NotificationCard;
