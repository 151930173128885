import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

type Props = RouteProps & {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export const ProtectedRoute: React.FC<Props> = ({ component, ...props }) => (
  <Route component={withAuthenticationRequired(component)} {...props} />
);
