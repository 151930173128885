import { atom } from 'recoil';

import { IScore } from '../../models/lead';
import { FilterData } from '../../types';

interface CachedRows {
  cachedRows: IScore[];
  cachedRowsCount: number;
}

export const leadRowsState = atom({
  key: 'cachedRows',
  default: {
    cachedRows: [] as IScore[],
    cachedRowsCount: 0,
  } as CachedRows,
});

export const selectedLeadRowsState = atom({
  key: 'selectedLeadRows',
  default: [] as string[],
});

export const bulkDemoteModalOpenState = atom({
  key: 'bulkDemoteModalOpen',
  default: false as boolean,
});

export const bookmarkIndexState = atom({
  key: 'bookmarkIndex',
  default: -1,
});

export const cachedFilterState = atom({
  key: 'cachedFilter',
  default: {} as FilterData,
});

export const resetCachedFilterState = atom({
  key: 'resetCachedFilter',
  default: false,
});

export const showNewFilterState = atom({
  key: 'showNewFilter',
  default: 'showNew' as 'showAll' | 'showNew' | 'showExisting',
});
