import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import AccountCardStyle from './AccountCard.style';

import {
  renderName,
  renderProjectLeadsContactCount,
  renderProperty,
} from '../AtAGlance/DrawerList/MapDrawerRowRenderers';
import { Organization } from '../../../models';
import { gold, primaryBlue } from '../../../theme';
import AccountIcons from '../../Common/OrgIcons/AccountIcons';
import { getAccountDetailsPagePath } from '../../../utils/page';
import { toTitleCase } from '../../../utils/string';

interface IDrawerCardAccount {
  organization: Organization;
  isOdd: boolean;
  showRelatedCount?: boolean;
}

const AccountCard: React.FC<IDrawerCardAccount> = ({
  organization,
  isOdd = true,
  showRelatedCount = true,
}) => {
  const classes = AccountCardStyle({ isOdd });
  const {
    id,
    name,
    address1,
    city,
    state,
    zipcode,
    sfType,
    sfLoyalty,
    sfRating,
    incrementalReason,
    cStakeholders,
    cProjectLeads,
    cEvents,
    role,
  } = organization;
  // National Account becomes target national account when sf_loyalty is set to target
  // Backend takes care of only displaying target accounts if sfType is CSA Account
  const isNationalAccount = sfType === 'CSA Account';
  const isTargetedNationalAccount = sfLoyalty === 'Target';
  const primaryOrgId = organization?.account?.primary_organization_id || id;

  const icons = (
    <AccountIcons
      incLength={incrementalReason?.length || 0}
      sfRating={sfRating || ''}
      isNationalAccount={isNationalAccount}
      isTargetedNationalAccount={isTargetedNationalAccount}
    />
  );

  return (
    <Link target="_blank" to={getAccountDetailsPagePath(primaryOrgId)} className={classes.link}>
      <Grid
        className={classes.root}
        style={{
          boxShadow: `inset 6px 0px 0px ${isNationalAccount ? gold : primaryBlue}`,
        }}
      >
        <Grid className={classes.body}>
          <Grid className={classes.info}>
            {role && <Grid item>{renderName(toTitleCase(role))}</Grid>}
            <Grid item>{renderName(name)}</Grid>
            <Grid item className={classes.address}>
              {renderProperty(address1 || '', toTitleCase(city) || '', state || '', zipcode || '')}
            </Grid>
            <Grid className={classes.footer}>
              {showRelatedCount &&
                renderProjectLeadsContactCount(
                  cProjectLeads || 0,
                  cEvents || 0,
                  cStakeholders || 0,
                )}
            </Grid>
          </Grid>
          {icons && <Grid className={classes.icons}>{icons}</Grid>}
        </Grid>
      </Grid>
    </Link>
  );
};

export default AccountCard;
