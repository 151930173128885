import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router';

import { usePLDetailsTabStyles } from './PLDetailsTab.style';
import {
  createProjectLeadInformationObject,
  createSpecAlertTooltipBody,
  createTooltipHTML,
  OwnersSelection,
  prepareIndicatorReasons,
  renderKeywords,
  renderString,
} from './PLDetailsTabHelperFunctions';
import { config, renderList, variant } from './PLDetailsTab.config';
import DataSourceItem from './DataSourceItem';

import TitleBorder from '../../../Common/TitleBorder';
import CleoButton from '../../../Common/CleoButton';
import AccordionDropDown from '../../../Common/AccordionDropDown';
import { IProjectLeadDetails } from '../../../../models/projectLead';
import { toSentence } from '../../../../utils/string';
import DetailNotePad from '../../../Common/DetailNotePad';
import { lightGreen, secondary, white } from '../../../../theme';
import { googleSearchQuery } from '../../../../utils/map';
import { getFullAddress } from '../../../../utils/address';
import { Organization, Stakeholder, User } from '../../../../models';
import {
  updateProjectById,
  getProjectLeadStakeholders,
  getProjectLeadOrganizations,
} from '../../../../api/projects';
import EmptyBox from '../../../Common/EmptyBox';
import { Variant } from '../../../../types/typography';
import { useMessages } from '../../../../state/contexts';
import StakeholderCard from '../../StakeholderDetails/StakeholderCard';
import AccountCard from '../../OrganizationDetails/AccountCard';
export enum ContactType {
  Contacts = 'Contacts',
  Accounts = 'Accounts',
}

interface PLDetailsTabProps {
  plDetails: IProjectLeadDetails;
}

interface IRouteParams {
  projectId: string;
}

const PLDetailsTab: React.FC<PLDetailsTabProps> = ({ plDetails }) => {
  const { projectId } = useParams<IRouteParams>();
  const classes = usePLDetailsTabStyles();
  const [contactType, setContactType] = useState<ContactType>(ContactType.Contacts);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadedContacts, setLoadedContacts] = useState(false);
  const [loadedAccounts, setLoadedAccounts] = useState(false);
  const projectInfo = plDetails.projectDetails;
  const pvf = plDetails.pvf;
  const isNational = plDetails.pvf?.hasNationalTargetAccount
    ? 'target,'
    : plDetails.pvf?.hasNationalAccount
    ? 'national,'
    : '';
  const incrementalReason = isNational.concat(...(plDetails.incrementalReason ?? '')).split(',');
  const activeEvents = plDetails.eventsOnActiveProject;
  const eventsOnProperty = plDetails.eventsOnProperty;
  const usersList = plDetails?.users?.map(userResponse => new User(userResponse)) || [];
  const keywords = {
    positive: plDetails?.pvf?.positiveKeywords?.split(', ') ?? [],
    negative: plDetails?.pvf?.negativeKeywords?.split(', ') ?? [],
  };
  let RenderedList;
  let features;
  let projectNotes;

  const { setErrorMessage } = useMessages();

  const loadContacts = useCallback(async () => {
    const contacts = await getProjectLeadStakeholders(projectId);
    setStakeholders(contacts);
    setLoadedContacts(true);
  }, [projectId]);

  const loadAccounts = useCallback(async () => {
    const accounts = await getProjectLeadOrganizations(projectId);
    setOrganizations(accounts);
    setLoadedAccounts(true);
  }, [projectId]);

  const handleProjectOwnerChange = async (ownerId: string) => {
    try {
      await updateProjectById(projectId, { owned_by: ownerId || null });
    } catch (e) {
      setErrorMessage('Error changing owners: ', e);
    }
  };

  useEffect(() => {
    if (!loadedContacts) {
      loadContacts();
    }
  }, [loadedContacts, loadContacts]);

  useEffect(() => {
    if (!loadedAccounts) {
      loadAccounts();
    }
  }, [loadedAccounts, loadAccounts]);

  const handleToggleDataSource = async (eventId: string | undefined) => {
    try {
      console.log('Toggle Event Id: ', eventId);
    } catch (e) {
      setErrorMessage('Error handling data source: ', e);
    }
  };

  if (projectInfo && pvf) {
    const listItems = createProjectLeadInformationObject(projectInfo, pvf);
    features = listItems.description;
    projectNotes = listItems.projectNote;

    const { address1, city, state, zipcode, projectUrl, sfdc_project_lead_id } = listItems;

    const sfdcLink = sfdc_project_lead_id
      ? `https://armstrongceilings.my.salesforce.com/${sfdc_project_lead_id}`
      : undefined;
    const links = {
      address1: googleSearchQuery(getFullAddress({ address1, city, state, zipcode })),
      dataSource: projectUrl,
      sfdc_project_lead_id: sfdcLink,
    };

    const specAlertsList = listItems?.specAlerts?.split(',');
    let tooltips;
    if (specAlertsList) {
      const tooltipBody = createSpecAlertTooltipBody(specAlertsList);
      const tooltipSpecAlerts = createTooltipHTML({
        header: 'Spec Alerts',
        body: tooltipBody,
      });
      tooltips = {
        specAlerts: tooltipSpecAlerts,
      };
    }

    // Add default value to ownersList
    const ownersList = [{ id: '', fullName: '' } as Partial<User>, ...usersList];
    const ownersSelection: OwnersSelection = {
      ownersList: ownersList,
      handleSelection: handleProjectOwnerChange,
    };

    const source =
      pvf.dataSource === 'DODGE'
        ? 'Dodge'
        : pvf.dataSource === 'BUILD_CENTRAL'
        ? 'Build Central'
        : pvf.dataSource;

    RenderedList = renderList({
      config,
      data: listItems,
      links,
      ownersSelection,
      tooltips,
      source,
    });
  }

  // Handle onClick logic
  const addContactButton = <CleoButton disabled={true}>{'Add Contact'}</CleoButton>;
  const addAccountButton = <CleoButton disabled={true}>{'Add Account'}</CleoButton>;
  const addToProjectButton = <CleoButton disabled={true}>{'Add To Project'}</CleoButton>;

  const options = [ContactType.Contacts, ContactType.Accounts];
  const variantDetailPad: Variant = variant;
  const variantTitleBorderLeft = 'h5';
  const variantTitleBorderRight = 'subtitle1';
  const vhTopTitleBorder = '65vh';
  const vhBottomTitleBorder = '35vh';

  return (
    <Grid className={classes.detailsTabRoot}>
      <Grid className={classes.body}>
        <TitleBorder
          leftItem={'Project Lead Information'}
          style={{ height: vhTopTitleBorder, width: '65%' }}
          variantLeft={variantTitleBorderLeft}
          variantRight={variantTitleBorderRight}
        >
          <Grid style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                padding: '.5rem',
                overflow: 'scroll',
              }}
            >
              {RenderedList}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                padding: '.5rem',
                overflow: 'scroll',
              }}
            >
              <DetailNotePad padTitle={'Features:'} style={{ flex: 1, backgroundColor: secondary }}>
                {renderString({
                  value: toSentence(features),
                  variant: variantDetailPad,
                  defaultString: 'No Description Available',
                })}
              </DetailNotePad>
              <DetailNotePad padTitle={'Keywords:'} style={{ flex: 1, backgroundColor: white }}>
                {renderKeywords(keywords)}
              </DetailNotePad>
              <DetailNotePad
                padTitle={'Indicator Reasons:'}
                style={{ flex: 1, backgroundColor: secondary }}
              >
                {prepareIndicatorReasons({ reasons: incrementalReason, variant: variantDetailPad })}
              </DetailNotePad>
              <DetailNotePad
                padTitle={'Project Notes:'}
                style={{ flex: 1, backgroundColor: white }}
              >
                {renderString({
                  value: toSentence(projectNotes),
                  variant: variantDetailPad,
                  defaultString: 'There are no Project Notes',
                })}
              </DetailNotePad>
            </Grid>
          </Grid>
        </TitleBorder>
        <TitleBorder
          leftItem={<AccordionDropDown handleSelectedValue={setContactType} options={options} />}
          rightItem={contactType === ContactType.Contacts ? addContactButton : addAccountButton}
          style={{ height: vhTopTitleBorder, width: '35%' }}
          variantLeft={variantTitleBorderLeft}
          variantRight={variantTitleBorderRight}
        >
          <Grid style={{ paddingTop: '.5rem', height: '100%', overflowY: 'scroll' }}>
            {(contactType === ContactType.Contacts && !stakeholders?.length) ||
              (contactType === ContactType.Accounts && !organizations?.length && (
                <EmptyBox
                  style={{ height: '100%' }}
                >{`There are currently no ${contactType} to show for this project`}</EmptyBox>
              ))}
            {contactType === ContactType.Contacts && (
              <div>
                {stakeholders?.map((stakeholder, idx) => {
                  return <StakeholderCard isOdd={!(idx % 2)} stakeholder={stakeholder} key={idx} />;
                })}
              </div>
            )}
            {contactType === ContactType.Accounts && (
              <div>
                {organizations?.map((org, idx) => {
                  return (
                    <AccountCard
                      isOdd={!(idx % 2)}
                      organization={org}
                      showRelatedCount={false}
                      key={idx}
                    />
                  );
                })}
              </div>
            )}
          </Grid>
        </TitleBorder>
      </Grid>
      <TitleBorder
        leftItem={'Data Sources'}
        rightItem={addToProjectButton}
        style={{ width: '99.5%' }}
        variantLeft={variantTitleBorderLeft}
        variantRight={variantTitleBorderRight}
      >
        <Grid
          style={{
            height: vhBottomTitleBorder,
            width: '100%',
            padding: '.5rem',
            overflow: 'scroll',
          }}
        >
          {activeEvents?.map((item, idx) => {
            return (
              <DataSourceItem
                key={`data-source-active-${idx}`}
                backgroundColor={!(idx % 2) ? lightGreen : white}
                handleEventToggle={handleToggleDataSource}
                data={item}
                addedEvent={true}
                isFirst={idx === 0}
              />
            );
          })}
          {eventsOnProperty?.map((item, idx) => {
            return (
              <DataSourceItem
                key={`data-source-property-${idx}`}
                backgroundColor={!(idx % 2) ? lightGreen : white}
                data={item}
                handleEventToggle={handleToggleDataSource}
              />
            );
          })}
        </Grid>
      </TitleBorder>
    </Grid>
  );
};

export default PLDetailsTab;
