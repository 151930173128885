import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';

import ContactDetails from './ContactDetails';
import type { SubmitHandler } from './ContactDetails';

import {
  getStakeholderById,
  updateStakeholderById,
  getStakeholderOrganizations,
  getStakeholderProjects,
  getStakeholderProperties,
} from '../../../api/stakeholders';
import { addProjectStakeholder } from '../../../api/projects';
import FormTextField from '../../Common/FormTextField';
import { useMessages } from '../../../state/contexts';
import type { IStakeholderRequest, Organization, Project, Property } from '../../../models';
import { Stakeholder } from '../../../models';
import {
  IAddProjectStakeholderRequest,
  StakeholderRequestSchema,
} from '../../../models/stakeholder';
import type { Optional } from '../../../types';

interface IRouteParams {
  stakeholderId: string;
}

const StakeholderDetails: React.FC = () => {
  const { stakeholderId } = useParams<IRouteParams>();
  const [stakeholder, setStakeholder] = useState<Optional<Stakeholder>>();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const { setPageLoading, setPageFetching, setPageNotFound, setErrorMessage } = useMessages();

  const fetchData = useCallback(async () => {
    setPageLoading(true);
    try {
      const [nextStakeholder, nextOrganizations, nextProjects, nextProperties] = await Promise.all([
        getStakeholderById(stakeholderId),
        getStakeholderOrganizations(stakeholderId),
        getStakeholderProjects(stakeholderId),
        getStakeholderProperties(stakeholderId),
      ]);
      setStakeholder(nextStakeholder);
      setOrganizations(uniqBy(nextOrganizations, 'id'));
      setProjects(uniqBy(nextProjects, 'id'));
      setProperties(uniqBy(nextProperties, 'id'));
      setPageLoading(false);
    } catch (err) {
      setPageNotFound();
    }
  }, [stakeholderId, setPageLoading, setPageNotFound]);

  const onCreateProject = useCallback(
    async (projectId: string, stakeholderId: string, role: IAddProjectStakeholderRequest) => {
      setPageLoading(true);
      try {
        await addProjectStakeholder(projectId, stakeholderId, role);
        const projects = await getStakeholderProjects(stakeholderId);
        setProjects(projects);
      } catch (err) {
        setErrorMessage(err);
      }
      setPageLoading(false);
    },
    [setErrorMessage, setPageLoading],
  );

  const handleSubmit: SubmitHandler = useCallback(
    async (values: IStakeholderRequest, { resetForm }) => {
      setPageFetching(true);
      try {
        const nextStakeholder = await updateStakeholderById(stakeholderId, values);
        setStakeholder(nextStakeholder);
        resetForm();
      } catch (err) {
        setErrorMessage(err);
      }
      setPageFetching(false);
    },
    [stakeholderId, setPageFetching, setErrorMessage],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues: IStakeholderRequest = useMemo(
    () => ({
      name: stakeholder?.name,
      description: stakeholder?.description,
      contact: stakeholder?.contactDetails,
      title: stakeholder?.title || '',
    }),
    [stakeholder],
  );

  if (!stakeholder) {
    return null;
  }

  return (
    <ContactDetails
      refreshData={() => fetchData()}
      contact={stakeholder}
      initialValues={initialValues}
      validationSchema={StakeholderRequestSchema}
      onSubmit={handleSubmit}
      organizations={organizations}
      projects={projects}
      properties={properties}
      onCreateProject={onCreateProject}
    >
      <Grid item>
        <FormTextField name="title" label="Title" fullWidth />
      </Grid>
    </ContactDetails>
  );
};

export default StakeholderDetails;
