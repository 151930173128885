import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { black, lightGray, primaryBlue } from '../../theme';

export type ContainedButtonProps = ButtonProps & {
  bgColor: string;
  textColor?: string;
};

const useStyles = makeStyles(theme => ({
  button: {
    color: primaryBlue,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0, 4),
    height: theme.spacing(6),
  },
  color: {
    color: (props: ContainedButtonProps) => props.textColor,
    backgroundColor: (props: ContainedButtonProps) => props.bgColor,
    '&:hover': {
      backgroundColor: (props: ContainedButtonProps) => props.bgColor,
      opacity: '60%',
    },
  },
  disabled: {
    backgroundColor: lightGray,
    color: black,
  },
}));

const ContainedButton: React.FC<ContainedButtonProps> = ({
  bgColor,
  textColor = 'white',
  disabled = false,
  onClick,
  children,
  ...props
}) => {
  const classes = useStyles({ bgColor, textColor });
  return (
    <Button
      className={
        disabled ? clsx(classes.button, classes.disabled) : clsx(classes.button, classes.color)
      }
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ContainedButton;
