import { makeStyles } from '@material-ui/core';

export const useCustomizeTablesPopoverStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 1rem .5rem 1rem',
  },
  checkbox: {
    '& svg': {
      fill: '#0F1A24',
    },
  },
  customizeTitle: {
    textAlign: 'center',
    margin: '0',
    color: '#0C4377',
    fontWeight: 700,
    flexGrow: 1,
    order: 0,
  },
  customizeMessage: {
    textAlign: 'center',
    margin: '0',
    padding: '.5rem 1rem 1rem 1rem',
    color: '#0C4377',
    fontSize: '.875rem',
  },
  closeButton: {
    padding: 0,
    width: '10%',
    minWidth: 0,
    flexGrow: 0,
    order: 1,
    color: '#172D50',
  },
  divider: {
    width: '13.5rem',
    margin: '0 auto',
    height: '0.1rem',
    background: '#8FB1D7',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  columnTagDisabled: {
    display: 'flex',
    alignItems: 'center',
    background: '#E3ECF6',
    opacity: 0.5,
    margin: '0.3rem 0',
    paddingRight: '0.5rem',
  },
  columnNameRequired: {
    color: '#0C4377',
    fontWeight: 700,
  },
  columnTag: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.1rem 0',
    paddingRight: '0.5rem',
  },
  columnTagSelected: {
    background: '#E3ECF6',
  },
  customizeButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  customizeButtons: {
    boxShadow: 'none',
    margin: '0 0.5rem',
    padding: '.25rem .5rem',
    textTransform: 'uppercase',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#0C4377',
    '&:hover': {
      background: '#0C4377',
    },
  },
  clearButton: {
    color: '#0C4377',
    backgroundColor: 'white',
    border: '1px solid #0C4377',
    '&:hover': {
      background: 'inherit',
    },
  },
  categoryLimitError: {
    color: '#F03434',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  resetText: {
    textTransform: 'uppercase',
    color: '#0066CC',
    textAlign: 'center',
    fontWeight: 700,
    marginBottom: '1rem',
    cursor: 'pointer',
  },
}));
