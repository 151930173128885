import React, { PropsWithChildren, ReactElement } from 'react';
import { Grid } from '@material-ui/core';

import { Optional } from '../../../types';
import { BaseModel, Organization, Property, Stakeholder } from '../../../models';
import LeadCard from '../../Pages/Leads/LeadsTable/LeadCard';
import StakeholderCard from '../../Pages/StakeholderDetails/StakeholderCard';
import OrganizationCard from '../../Pages/OrganizationDetails/OrganizationCard';

type TProps<T> = {
  selected: T;
  setSelected: (arg0: Optional<Partial<T>>) => void;
};

interface IProps<T> extends PropsWithChildren<TProps<T>> {
  redirectOnSearch?: boolean;
  modelType: string;
}

export default function SelectedPane<T extends BaseModel>({
  modelType,
  selected,
  setSelected,
  children,
  redirectOnSearch = false,
}: IProps<T>): ReactElement<IProps<T>> {
  return (
    <>
      <Grid item key={selected.id} onClick={() => setSelected(undefined)} xs={12}>
        {/* FIXME: Massage all properties into the same type */}
        {modelType === 'property' ? (
          <LeadCard property={(selected as unknown) as Property} clickable={redirectOnSearch} />
        ) : modelType === 'stakeholder' ? (
          <StakeholderCard
            stakeholder={(selected as unknown) as Stakeholder}
            clickable={redirectOnSearch}
            isOdd={true}
          />
        ) : (
          <OrganizationCard
            organization={(selected as unknown) as Organization}
            clickable={redirectOnSearch}
          />
        )}
      </Grid>
      {children}
    </>
  );
}
