import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';

import CreateOrganizationModal, { ICreateOrganizationProps } from './CreateOrganizationModal';
import OrganizationCard from './OrganizationCard';

import type { IProps as ICardListProps } from '../../Common/CardList';
import CardList from '../../Common/CardList';
import type { Organization } from '../../../models';

interface IProps extends ICardListProps {
  organizations: Organization[];
  ModalProps?: ICreateOrganizationProps;
}

const OrganizationCardList: React.FC<IProps> = ({ organizations, ModalProps, ...props }) => {
  const [open, setOpen] = useState(false);

  const { onChange } = props;
  const handleClose = useCallback(
    (success = false) => {
      if (success && onChange) {
        onChange();
      }

      setOpen(false);
    },
    [onChange],
  );

  return (
    <>
      <CardList {...props} onClickAdd={ModalProps && (() => setOpen(true))}>
        {organizations.map(organization => (
          <Grid item key={organization.id}>
            <OrganizationCard organization={organization} />
          </Grid>
        ))}
      </CardList>
      {ModalProps && <CreateOrganizationModal open={open} onClose={handleClose} {...ModalProps} />}
    </>
  );
};

export default OrganizationCardList;
