import React, { useRef, useState } from 'react';
import { Button, Grid, GridProps, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { UseProjectLeadsBulkDemoteConfirmationStyle } from './ProjectLeadsBulkDemoteConfirmation.styles';
import { bulkConfirmTableConfig } from './ProjectLeadsBulkDemoteConfirmationConfig';

import {
  renderCheckbox,
  renderDate,
  renderItem,
  renderNumber2,
  renderPropertyPL,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { bulkDemoteProjects } from '../../../../api/projects';
import { useMessages } from '../../../../state/contexts';
import {
  bulkDemoteTransitionState,
  selectedBulkDemoteState,
} from '../../../../state/atoms/projects';
import { toTitleCase } from '../../../../utils/string';

interface IProjectLeadsBulkDemoteConfirmationProps {
  clearBulkDemote: () => void;
}

const ProjectLeadsBulkDemoteConfirmation: React.FC<IProjectLeadsBulkDemoteConfirmationProps> = ({
  clearBulkDemote,
}) => {
  const classes = UseProjectLeadsBulkDemoteConfirmationStyle();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const selectedBulkItems = useRecoilValue(selectedBulkDemoteState);
  const transitionItems = useRecoilValue(bulkDemoteTransitionState);

  // Keep track of state of each projectId
  const bulkDemoteList = useRef<Record<string, boolean>>(
    //  Creates an object with all projectIds set as keys and values set to true
    selectedBulkItems.reduce((o, project) => ({ ...o, [project.id]: true }), {}),
  );

  const [demoteCount, setDemoteCount] = useState<number>(
    Object.values(bulkDemoteList.current).filter(Boolean).length,
  );
  //Disable buttons while bulk demoting
  const [demoting, setDemoting] = useState<boolean>(false);

  const countStatement = demoteCount
    .toString()
    .concat(` ${demoteCount === 1 ? 'project lead' : 'project leads'}`);
  const isHas = demoteCount === 1;

  const confirmDemote = async () => {
    setDemoting(true);
    const projectIdsToDemote = Object.keys(bulkDemoteList.current).filter(
      key => bulkDemoteList.current[key],
    );
    const bulkDemoteConfig = {
      reason: transitionItems?.reason || 'Other',
      note: transitionItems?.note || 'Default Note - needs fixing',
      value: 'low',
      idList: projectIdsToDemote,
    };
    try {
      await bulkDemoteProjects(bulkDemoteConfig);
      setDemoting(false);
      setSuccessMessage(`${countStatement} ${isHas ? 'has' : 'have'} been demoted`);
    } catch (err) {
      setErrorMessage('Bulk Demote Failed: ', err);
    }
    clearBulkDemote();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    bulkDemoteList.current[e.target.value] = !bulkDemoteList.current[e.target.value];
    setDemoteCount(Object.values(bulkDemoteList.current).filter(Boolean).length);
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.messageBlock}>
        <Grid>
          <Typography variant={'body1'}>
            The following <strong>{countStatement}</strong> {isHas ? 'has' : 'have'} been queued for
            demotion, <strong>do you wish to proceed?</strong>
          </Typography>
          <Typography variant={'body1'}>
            You may also remove any of the project leads below by unchecking them off this list.
          </Typography>
        </Grid>
        <Grid className={classes.buttonsContainer}>
          <Button
            className={classes.buttonNo}
            variant={'contained'}
            onClick={clearBulkDemote}
            disabled={demoting}
          >
            No
          </Button>
          <Button
            className={classes.buttonYes}
            variant={'contained'}
            onClick={confirmDemote}
            disabled={!demoteCount || demoting}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.tableContainer}>
        <Grid className={classes.header}>
          {bulkConfirmTableConfig.map((config, key) => {
            const { id, label } = config;
            const gridProps = config.gridProps as GridProps;
            const textAlign = config.textAlign as 'left' | 'center' | 'right';
            return (
              <Grid key={`${key}-${id}`} item style={{ textAlign }} {...gridProps}>
                <Typography component="span" variant="body1" style={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        {selectedBulkItems.map((project, idx) => {
          const {
            id,
            projectName,
            address1,
            city,
            state,
            actionStage,
            value1,
            categories,
            bidDate,
          } = project;

          return (
            <Grid className={classes.row} key={`${id}-${idx}`} container>
              <Grid item md={3} style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid style={{ marginRight: '.5rem' }}>
                  {renderCheckbox(id, handleChange, true)}
                </Grid>
                {renderItem(toTitleCase(projectName))}
              </Grid>
              <Grid item md={2} className={classes.item}>
                {renderPropertyPL(address1, city, state, undefined, false, true)}
              </Grid>
              <Grid item md={1} className={classes.item}>
                {renderNumber2(value1, true)}
              </Grid>
              <Grid item md={2} className={classes.item}>
                {renderDate(bidDate)}
              </Grid>
              <Grid item md={2} className={classes.item}>
                {renderItem(actionStage)}
              </Grid>
              <Grid item md={2} className={classes.item}>
                {renderItem(categories, true)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ProjectLeadsBulkDemoteConfirmation;
