import { makeStyles } from '@material-ui/core';

import { black, lightGray, primaryBlue, secondary, white } from '../../../../theme';

export const UseProjectLeadsBulkDemoteConfirmationStyle = makeStyles(() => ({
  root: {
    margin: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  messageBlock: {
    margin: '.5rem 0',
    backgroundColor: secondary,
    display: 'flex',
    flexDirection: 'row',
    height: '5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsContainer: {
    margin: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${black}`,
    borderRadius: '4px',
    padding: '.5rem',
  },
  buttonNo: {
    backgroundColor: secondary,
    color: primaryBlue,
    boxShadow: 'none',
    height: '1.5rem',
  },
  buttonYes: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: 'none',
    height: '1.5rem',
  },
  tableContainer: {
    margin: '.5rem 0',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignContent: 'center',
    height: '75vh',
    overflow: 'scroll',
  },
  header: {
    backgroundColor: lightGray,
    color: primaryBlue,
    padding: '.5rem',
    marginBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.25rem .5rem',
    alignItem: 'center',
    '&:nth-child(even)': {
      background: '#EAF5F3',
    },
    '&:nth-child(odd)': {
      background: '#fff',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
}));
