import React, { useState } from 'react';
import { Accordion, Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { AccordionDetailMod, AccordionSummaryMod } from './AccordionMod';

import { black, primaryBlue } from '../../theme';
import { Variant } from '../../types/typography';

const useStyles = makeStyles(() => ({
  accordion: {
    border: `1px solid ${primaryBlue}`,
    boxShadow: 'none',
    padding: '0 .25rem',
  },
  summary: {
    backgroundColor: 'rgba(255, 255, 255, .05)',
    color: black,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryItem: {
    flex: 2,
  },
  expandIcon: {
    paddingLeft: '.75rem',
  },
  details: {
    borderTop: `1px solid ${primaryBlue}`,
  },
  menuItem: {
    fontWeight: 'normal',
    width: '100%',
  },
}));

interface AccordionDropDownProps<T> {
  handleSelectedValue: React.Dispatch<React.SetStateAction<T>>;
  options: T[];
  variant?: Variant;
}

function AccordionDropDown<T>({
  handleSelectedValue,
  options,
  variant = 'h5',
}: AccordionDropDownProps<T>): JSX.Element {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(options[0]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const expandIcon = openDropDown ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  const handleSelection = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const targetValue = (e.currentTarget.getAttribute('value') as unknown) as T;
    setSelectedItem(targetValue);
    setOpenDropDown(!openDropDown);
    handleSelectedValue(targetValue);
  };

  const handleAccordionClick = () => {
    setOpenDropDown(!openDropDown);
  };
  return (
    <Accordion
      expanded={openDropDown}
      onClick={() => handleAccordionClick()}
      className={classes.accordion}
    >
      <AccordionSummaryMod className={classes.summary} style={{ minHeight: 0, padding: '.125rem' }}>
        <Grid className={classes.summaryItem}>
          <Typography style={{ fontWeight: 'bold' }} variant={variant}>
            {selectedItem}
          </Typography>
        </Grid>
        <Grid className={classes.expandIcon}>{expandIcon}</Grid>
      </AccordionSummaryMod>
      <AccordionDetailMod className={classes.details}>
        <Grid>
          {((options as unknown[]) as T[]).map((item, idx) => (
            <MenuItem
              value={(item as unknown) as number | string}
              key={`${item}-${idx}`}
              onClick={handleSelection}
              className={classes.menuItem}
            >
              {item}
            </MenuItem>
          ))}
        </Grid>
      </AccordionDetailMod>
    </Accordion>
  );
}

export default AccordionDropDown;
