import { makeStyles } from '@material-ui/core';

import { hyperlinkBlue, secondary } from '../../../../theme';

export const useAccountDetailsStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '.25rem .5rem',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'scroll',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
  },
  label: {
    marginRight: '.5rem',
  },
  specRates: {
    border: '0.25px solid #0C4377',
    padding: '.25rem .5rem',
    marginBottom: '.5rem',
  },
  specs: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '.5px solid black',
    padding: '.5rem',
  },
  spec: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dotted: {
    borderTop: '1px dotted black',
    margin: '0 .25rem',
    flex: 1,
  },
  lastMod: {
    backgroundColor: secondary,
    margin: '.25rem 0',
  },
  hyperlink: {
    textDecoration: 'none',
    color: hyperlinkBlue,
  },
}));
