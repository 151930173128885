import { selector } from 'recoil';

import { Project } from '../../models';
import { ProjectStatus } from '../../models/common';
import { currentPropertyState } from '../atoms/property';

export const projectsSelector = selector({
  key: 'projects',
  get: ({ get }) => {
    const property = get(currentPropertyState);
    if (property) {
      const { projects } = property;
      return projects as Project[];
    } else {
      return [] as Project[];
    }
  },
});

export const activeProjectsSelector = selector({
  key: 'activeProjects',
  get: ({ get }) => {
    const property = get(currentPropertyState);
    if (property) {
      const { projects } = property;
      // For multiple active projects, replace `.find` with `.filter`
      return projects?.find(project => project.status === ProjectStatus.Active) as Project;
    } else {
      return (undefined as unknown) as Project;
    }
  },
});
