import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';

import ProjectCard from './ProjectCard';
import CreateProjectForm from './CreateProjectForm';

import type { IProps as CardListProps } from '../../../Common/CardList';
import CardList from '../../../Common/CardList';
import type { Project } from '../../../../models';
import CreateDialog from '../../../Common/CreateDialog';

interface IProps extends CardListProps {
  origin: string;
  projects: Project[];
  onProjectCreate?: (project: Project) => void;
}

const ProjectCardList: React.FC<IProps> = ({ projects, onProjectCreate, origin, ...props }) => {
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const handleProjectCreated = useCallback(
    project => {
      setCreateProjectOpen(false);
      if (onProjectCreate) {
        onProjectCreate(project);
      }
    },
    [onProjectCreate],
  );
  const allowAdd = !!onProjectCreate;
  return (
    <>
      <CardList {...props} onClickAdd={allowAdd ? () => setCreateProjectOpen(true) : undefined}>
        {projects.map(project => (
          <Grid item key={project.id}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </CardList>
      {allowAdd && (
        <CreateDialog open={createProjectOpen} onClose={() => setCreateProjectOpen(false)}>
          <CreateProjectForm onCreate={project => handleProjectCreated(project)} origin={origin} />
        </CreateDialog>
      )}
    </>
  );
};

export default ProjectCardList;
