import { styled, Box } from '@material-ui/core';

/**
 * This file stores the shared styles used for style guide
 */
export const Layout = styled(Box)({
  minWidth: '100%',
});
export const Container = styled(Box)({
  padding: '10px 0',
});

export const Text = styled('div')({
  padding: '20px 0',
});

export const BoldText = styled(Text)({
  fontWeight: 'bold',
});

export const Title = styled(BoldText)({
  fontSize: 22,
});

export const SubTitle = styled(BoldText)({
  fontSize: 15,
});

export const SectionHeader = styled(BoldText)({
  fontSize: 50,
});

export const BoldContainer = styled(Container)({
  fontWeight: 'bold',
});
