import React, { MouseEvent, useState, useCallback, ReactElement } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import type { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IProps {
  maxLines: number;
  component?: React.ElementType<any>;
  TooltipProps: {
    arrow?: boolean;
    placement?:
      | 'bottom-end'
      | 'bottom-start'
      | 'bottom'
      | 'left-end'
      | 'left-start'
      | 'left'
      | 'right-end'
      | 'right-start'
      | 'right'
      | 'top-end'
      | 'top-start'
      | 'top';
    title: string | ReactElement | ReactElement[];
  };
  TypographyProps: TypographyProps;
}

const useStyles = (lineClamp: number) =>
  makeStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
      display: 'box',
      lineClamp,
      boxOrient: 'vertical',
    },
  });

const TruncatedTypography: React.FC<IProps> = ({
  maxLines,
  component = 'p',
  TooltipProps,
  TypographyProps,
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const classes = useStyles(maxLines)();
  const handleMouseOver = useCallback((e: MouseEvent<HTMLParagraphElement>) => {
    if (e.currentTarget.offsetHeight <= e.currentTarget.scrollHeight) {
      setTooltipOpen(true);
    }
  }, []);

  const handleMouseOut = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  return (
    <Tooltip open={tooltipOpen} {...TooltipProps}>
      <Typography
        component={component}
        {...{
          ...TypographyProps,
          className: clsx(classes.root, TypographyProps.className),
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTypography;
