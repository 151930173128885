import React from 'react';
import clsx from 'clsx';
import { getIn, FastField, FieldProps, FieldInputProps } from 'formik';
import { makeStyles, FormControl } from '@material-ui/core';
import type { BaseTextFieldProps } from '@material-ui/core/TextField';

import { darkLight, darkBlue } from '../../theme';

const useStyles = makeStyles(theme => ({
  root: {
    '&:focus-within': {
      '& $label': {
        color: darkBlue,
      },
    },
  },
  label: {
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
    color: darkLight,
    fontSize: theme.typography.caption.fontSize,
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export interface FormInputComponentProps extends FieldInputProps<any> {
  id: string;
  error?: boolean;
  helperText?: string;
}

interface IProps extends Pick<BaseTextFieldProps, 'name' | 'label' | 'fullWidth'> {
  children: (props: FormInputComponentProps) => React.ReactNode;
}

const FormInput: React.FC<IProps> = ({ children, name, label, fullWidth = false }) => {
  const id = `${name}-input`;
  const classes = useStyles();
  return (
    <FastField name={name}>
      {({ field, form: { touched, errors } }: FieldProps) => {
        const errorMessage = getIn(errors, field.name);
        const hasError = getIn(touched, field.name) && !!errorMessage;
        return (
          <FormControl
            className={classes.root}
            variant="outlined"
            error={hasError}
            fullWidth={fullWidth}
          >
            <label className={clsx(classes.label, { [classes.error]: hasError })} htmlFor={id}>
              {label}
            </label>
            {children({
              id,
              error: hasError,
              helperText: hasError && errorMessage,
              ...field,
              value: field.value || '',
            })}
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default FormInput;
