import type { IResponse } from './api';
import API from './api';

import { FilterQuery } from '../types';
import type { IUserResponse } from '../models';
import { User } from '../models';
import encodeFilter from '../utils/encodeFilter';

export const getUsers = async (name = ''): Promise<IResponse<User>> => {
  const filterData: FilterQuery = {
    sort: ['+full_name'],
    query: {
      full_name: {
        startsWith: name,
      },
    },
  };

  const { data } = await API.get('/users', {
    params: {
      filter: encodeFilter(filterData),
    },
  });

  const events = (data as IResponse<IUserResponse>).items.map(item => new User(item));
  return { items: events, total: data.total };
};
