import React from 'react';
import { Typography } from '@material-ui/core';

import { primaryBlue } from '../../theme';

interface IProps {
  title: string;
  subtitle?: string;
  color?: string;
}

const PaperTitle: React.FC<IProps> = ({ title, subtitle, color = primaryBlue }) => {
  return (
    <>
      <Typography component="span" variant="h6" style={{ color }}>
        <strong>{`${title}${subtitle ? ': ' : ''}`}</strong>
      </Typography>
      {!!subtitle && <Typography component="span">{subtitle}</Typography>}
    </>
  );
};

export default PaperTitle;
