import { makeStyles } from '@material-ui/core';

import theme, { black, primaryBlue } from '../../../../theme';

export const useAccountsMergeModalStyle = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: primaryBlue,
    boxShadow: '0px 16px 35px rgba(50, 50, 50, 0.2)',
    borderRadius: '4px',
    maxHeight: '60%',
  },
  close: {
    position: 'absolute',
    top: '.25rem',
    right: '.25rem',
  },
  header: {
    padding: '.25rem',
    width: '45rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    padding: '.25rem',
  },
  hr: {
    width: '100%',
    borderTop: '1px solid #0C4377',
    flex: 1,
    padding: '0 .5rem',
  },
  table: {
    width: '100%',
    padding: '.5rem 1rem',
    color: black,
    fontSize: theme.typography.body1.fontSize,
  },
  tr: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  c1: {
    display: 'flex',
    flex: 1,
    alignContent: 'start',
  },
  c2: {
    display: 'flex',
    flex: 2,
  },
  footer: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignContent: 'flex-start',
    padding: '0 1rem',
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '.75rem',
  },
}));
