import axios from 'axios';

import API, { IResponse } from './api';

import DataMapper, { IDataMapperResponse } from '../models/dataMapper';
import { DataMapperTabs } from '../Components/Pages/DataMapper/DataMapperConstants';

export const getDataMapperConfigurations = async (): Promise<{
  items: DataMapper[];
  total: number;
}> => {
  const { data } = await API.get('/dataMappers');
  const dataMapperConfigurations = (data as IResponse<IDataMapperResponse>).items.map(
    item => new DataMapper(item),
  );
  return { items: dataMapperConfigurations, total: data.total };
};

export const getDataResponse = async (
  url: string,
  setTabValue: (val: number) => void,
): Promise<any> => {
  try {
    const { data } = await axios.get(`${url}`);
    return data;
  } catch (error) {
    //  Prevents error: ESLint: Unnecessary try/catch wrapper.(no-useless-catch)
    setTabValue(DataMapperTabs.DataConfiguration);
    throw error;
  }
};

export const sendDataMapperETLRequest = async (dataMapperETLRequest: any) => {
  await API.post('/dataMappers', dataMapperETLRequest);
};

export const deleteETLConfiguration = async (id: string) => {
  await API.patch(`/dataMappers/${id}/delete`);
};
