import { SortColumns } from '../Components/Pages/Leads/Archive/Archive.config';

// TODO: TAKA - Rename these in relation to AccountsViewFilter
export type IAccountListAccountResponse = {
  id: string;
  name: string;
  sf_rating: string;
  is_national: boolean;
  is_targeted_national: boolean;
  is_new: boolean;
  is_favorite?: boolean;
  incremental_reason: string;
  accountId: string;
  state?: string;
  county?: string;
  isPrimary?: boolean;
  orgRating?: string;
  accRating?: string;
  updated_at: string;
};

export interface IAccountListRequest {
  offset: number;
  limit?: number;
  sortDirection: string;
  sortColumn: string;
  isNew?: boolean;
}

export interface IAccountDuplicateRequest {
  primary: string;
  duplicates: string[];
}

// Use this class for AccountViewFilter
export default class AccountListAccount {
  id: string;
  name: string;
  sf_rating: string;
  is_national: boolean;
  is_targeted_national: boolean;
  is_new: boolean;
  is_favorite: boolean;
  is_education: boolean;
  is_government: boolean;
  is_spec_alert: boolean;
  accountId?: string;
  state?: string;
  county?: string;
  isPrimary?: boolean;
  orgRating?: string;
  accRating?: string;
  updated_at?: string;

  constructor(data: IAccountListAccountResponse) {
    const {
      id,
      name,
      sf_rating,
      is_national,
      is_targeted_national,
      is_new,
      incremental_reason,
      accountId,
      state,
      county,
      isPrimary,
      orgRating,
      accRating,
      updated_at,
    } = data;
    this.id = id;
    this.name = name;
    this.is_national = is_national;
    this.sf_rating = sf_rating;
    this.is_targeted_national = is_targeted_national;
    this.is_new = is_new;
    this.is_education = incremental_reason?.includes('Education');
    this.is_government = incremental_reason?.includes('Government');
    this.is_spec_alert = incremental_reason?.includes('Spec Alert');
    this.is_favorite = false;
    this.accountId = accountId;
    this.state = state;
    this.county = county;
    this.isPrimary = isPrimary;
    this.orgRating = orgRating;
    this.accRating = accRating;
    this.updated_at = updated_at;
  }
}

export interface AccountsSortList {
  name: string;
  updated_at: string;
}

export const accountSortCols: SortColumns<AccountsSortList> = {
  name: ['name'],
  updated_at: ['updated_at'],
};
