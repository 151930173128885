import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';

import CreateStakeholderModal, { ICreateStakeholderProps } from './CreateStakeholderModal';
import StakeholderCard from './StakeholderCard';

import type { IProps as ICardListProps } from '../../Common/CardList';
import CardList from '../../Common/CardList';
import type { Stakeholder } from '../../../models';

interface IProps extends ICardListProps {
  stakeholders: Stakeholder[];
  primary?: boolean;
  ModalProps?: ICreateStakeholderProps;
  associated?: string[];
  propertyId?: string;
  projectId?: string;
  onToggleChanged?: (checked: boolean, data: Stakeholder) => void;
  onRemove?: (
    propertyOrProjectId: string,
    stakeholderId: string,
    addedToProperty: boolean,
  ) => Promise<void>;
}

const StakeholderCardList: React.FC<IProps> = ({
  stakeholders,
  primary = false,
  ModalProps,
  onToggleChanged,
  associated,
  onRemove,
  propertyId,
  projectId,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const { onChange } = props;
  const handleClose = useCallback(
    (success = false) => {
      if (success && onChange) {
        onChange();
      }

      setOpen(false);
    },
    [onChange],
  );

  return (
    <>
      <CardList {...props} onClickAdd={ModalProps && (() => setOpen(true))}>
        {stakeholders.map((stakeholder, idx) => {
          return (
            <Grid item key={stakeholder.id}>
              <StakeholderCard
                onToggleChanged={onToggleChanged}
                isAssociated={associated && associated.includes(stakeholder.id)}
                allowAssociation={Boolean(associated)}
                stakeholder={stakeholder}
                primary={primary}
                onRemove={onRemove}
                propertyId={propertyId}
                projectId={projectId}
                isOdd={!(idx % 2)}
              />
            </Grid>
          );
        })}
      </CardList>
      {ModalProps && <CreateStakeholderModal open={open} onClose={handleClose} {...ModalProps} />}
    </>
  );
};

export default StakeholderCardList;
