import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '2.5rem',
    width: '60vw',
    height: 'calc(100vh - 64px)',
    maxWidth: 'none',
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
}));

const InsightsPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <iframe
        title="Cleo Insights"
        src="https://app.powerbi.com/reportEmbed?reportId=44909718-3ace-4522-808e-79982fd87445&autoAuth=true&ctid=5b00abe1-105d-47d0-ab18-b84d5ea65f29"
        frameBorder="0"
        allowFullScreen={true}
        className={classes.iframe}
      />
    </Container>
  );
};

export default InsightsPage;
