export type IBaseModelResponse = {
  created_at: string;
  updated_at: string;
  id: string;
};

export default class BaseModel {
  createdAt: Date;
  updatedAt: Date;
  id: string;

  constructor(data: IBaseModelResponse) {
    const { created_at, updated_at, id } = data;
    this.createdAt = new Date(created_at);
    this.updatedAt = new Date(updated_at);
    this.id = id;
  }
}
