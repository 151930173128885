import React from 'react';
import { Cell, Row } from 'react-table';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import ReactTableCell from './ReactTableCell';
import { useReactTableRowStyles } from './ReactTableRow.styles';

import { getProjectLeadsDetailsPagePath } from '../../../../utils/page';

interface ReactTableRowProps<TableType extends Record<any, any>> {
  row: Row<TableType>;
}

export const ReactTableRow = <TableType extends Record<any, any>>({
  row,
}: ReactTableRowProps<TableType>): JSX.Element => {
  const classes = useReactTableRowStyles();

  const handleRowClick = () => {
    const newWindow = window.open(`${getProjectLeadsDetailsPagePath(row.values?.id)}`, '_blank');
    newWindow?.focus();
  };
  return (
    <tr {...row.getRowProps()} className={classes.root} onClick={handleRowClick}>
      {row.original?.isNew && (
        <td className={classes.newRibbon}>
          <Typography variant={'subtitle1'}>
            <strong>New</strong>
          </Typography>
        </td>
      )}
      {row.original?.viewed && (
        <td className={clsx(classes.newRibbon, classes.viewed)}>
          <Typography variant={'subtitle1'}>
            <strong>Viewed</strong>
          </Typography>
        </td>
      )}
      {row.cells.map((cell: Cell<TableType>, index: number) => {
        return (
          <ReactTableCell<TableType>
            cell={cell}
            key={`cell-${index}-${cell.row.id}`}
            viewed={row.original?.viewed}
          />
        );
      })}
    </tr>
  );
};
