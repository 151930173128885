import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { SourceType } from '../../../../models';
import { darkBlue } from '../../../../theme';

const useProjectLeadDocumentsStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    marginTop: '1rem',
    overflow: 'scroll',
    height: '75vh',
    width: '100%',
    border: `1px solid ${darkBlue}`,
  },
}));

interface IProjectLeadDocumentsProps {
  projectUrl?: string;
  dataSource?: string;
}

const ProjectLeadDocuments: React.FC<IProjectLeadDocumentsProps> = ({ projectUrl, dataSource }) => {
  const classes = useProjectLeadDocumentsStyles();

  if (!projectUrl) {
    return <div>{`This is Project Lead has no documents`}</div>;
  }

  let documentsUrl: string;
  switch (dataSource) {
    case SourceType.Dodge:
      documentsUrl = projectUrl.concat('#documents');
      break;
    case SourceType.Cmd:
      documentsUrl = projectUrl.concat('44071014');
      break;
    default:
      documentsUrl = '';
      break;
  }

  return (
    <Grid className={classes.root}>
      <iframe
        className={classes.iframe}
        src={documentsUrl}
        title={`Documents for ${dataSource} Project Lead`}
        scrolling={'yes'}
      />
    </Grid>
  );
};

export default ProjectLeadDocuments;
