import { makeStyles } from '@material-ui/core';

import { lightGreen, primaryBlue } from '../../../../theme';

export const useArchiveStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    marginTop: '3rem',
    overflow: 'scroll',
    border: `1px solid ${primaryBlue}`,
    borderRadius: '6px',
    height: '80vh',
    boxSizing: 'border-box',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${primaryBlue}`,
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
  tabList: {
    marginTop: '1rem',
  },
  tabPanelContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  emptyTabPanel: {
    height: '100%',
    padding: `2rem 0 2rem 0`,
  },
  tabPanel: {
    height: '100%',
    padding: 0,
  },
  emptyBox: {
    backgroundColor: lightGreen,
    verticalAlign: 'middle',
    height: '100%',
    width: '100%',
    borderRadius: '0',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0F1A24',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
});
