import { makeStyles } from '@material-ui/core/styles';

import { darkBlue, hyperlinkBlue, light, lightGreen, secondary } from '../../../theme';

const AccountCardStyle = makeStyles({
  link: {
    textDecoration: 'none',
    color: darkBlue,
    '&:hover': {
      backgroundColor: light,
    },
  },
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1rem',
    marginLeft: '1rem',
    marginTop: '.25rem',
    backgroundColor: (props: { isOdd: boolean }) => (!props.isOdd ? 'white' : lightGreen),
    '&:hover': {
      backgroundColor: light,
    },
  },
  name: {
    lineBreak: 'auto',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    fontWeight: 'bold',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.5rem',
  },
  info: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    padding: '0 .5rem .5rem .5rem',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'stretch',
  },
  address: {
    paddingTop: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  icons: {
    backgroundColor: secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footer: {
    paddingTop: '.5rem',
    color: hyperlinkBlue,
  },
});

export default AccountCardStyle;
