import React from 'react';
import type { GridProps } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { darkGray } from '../../theme';
import { Variant } from '../../types/typography';

type DetailNotePad = GridProps & {
  padTitle: string;
  padTitleVariant?: Variant;
  icon?: JSX.Element;
  details?: JSX.Element;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '.75rem',
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.5rem, 0',
    width: '100%',
    borderBottom: `1px solid ${darkGray}`,
  },
  title: {
    flex: 6,
    paddingBottom: '.5rem',
  },
  icon: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-.5rem',
  },
  details: {
    paddingTop: '.5rem',
  },
}));

const DetailNotePad: React.FC<DetailNotePad> = ({
  padTitle,
  padTitleVariant = 'subtitle1',
  icon,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} {...props}>
      <Grid className={classes.titleBlock}>
        <Grid className={classes.title}>
          <Typography variant={padTitleVariant}> {padTitle}</Typography>
        </Grid>
        <Grid className={classes.icon}>{icon}</Grid>
      </Grid>
      <Grid className={classes.details}>{children}</Grid>
    </Grid>
  );
};

export default DetailNotePad;
