import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Variant } from '../../../../types/typography';
import { IProject } from '../../Leads/Archive/Archive.config';
import { IProjectResponse, User } from '../../../../models';
import CleoSelect from '../../../Common/CleoSelect';
import { error, primary, secondary } from '../../../../theme';
import AccountCardIcons from '../../Accounts/AccountsHome/AccountCardIcons';

/**
 * Functions that help build JSX Elements to render on details page
 * */

interface IPrepareIndicatorReasons {
  reasons: string[];
  variant?: Variant;
}

export const prepareIndicatorReasons = ({ reasons }: IPrepareIndicatorReasons): JSX.Element => {
  let isNew = false;
  let isGovernment = false;
  let isEducation = false;
  let isSpecAlert = false;
  let isNational = false;
  let isTargeted = false;
  reasons?.forEach(reason => {
    isNew = isNew || reason.toLowerCase().includes('new');
    isGovernment = isGovernment || reason.toLowerCase().includes('government');
    isEducation = isEducation || reason.toLowerCase().includes('education');
    isSpecAlert = isSpecAlert || reason.toLowerCase().includes('spec');
    isNational = isNational || reason.toLowerCase().includes('national');
    isTargeted = isTargeted || reason.toLowerCase().includes('target');
  });

  return (
    <AccountCardIcons
      isSpecAlert={isSpecAlert}
      isEducation={isEducation}
      isGovernment={isGovernment}
      isNationalAccount={isNational}
      isTargetedNationalAccount={isTargeted}
      hideRatingIcon={true}
      isNew={isNew}
    />
  );
};

interface RenderStringProp {
  value?: string;
  variant?: Variant;
  defaultString: string;
}
export const renderString = ({
  value,
  variant = 'caption',
  defaultString,
}: RenderStringProp): JSX.Element => {
  return <Typography variant={variant}>{value || defaultString}</Typography>;
};

export const renderKeywords = (
  keywords: { positive: string[]; negative: string[] } | undefined,
): JSX.Element => {
  return (
    <Grid>
      {keywords && (keywords.positive.length > 0 || keywords.negative.length > 0) ? (
        <ul style={{ paddingLeft: '1.1rem', margin: 0, fontSize: '.875rem' }}>
          {keywords.positive.length > 0 && (
            <li style={{ color: primary, paddingBottom: '.25rem' }}>
              {keywords.positive
                .map(k => {
                  return k.charAt(0).toUpperCase() + k.substring(1);
                })
                .join(', ')}
            </li>
          )}
          {keywords.negative.length > 0 && (
            <li style={{ color: error, paddingBottom: '.25rem' }}>
              {keywords.negative
                .map(k => {
                  return k.charAt(0).toUpperCase() + k.substring(1);
                })
                .join(', ')}
            </li>
          )}
        </ul>
      ) : (
        <Typography variant={'body1'}>No Keywords Available</Typography>
      )}
    </Grid>
  );
};

const options: Partial<User>[] = [{ id: '', fullName: '' }];

export interface OwnersSelection {
  ownersList: Partial<User>[];
  handleSelection?: (selectedId: string) => void;
}

export const renderProjectOwnerSelection = (
  owner: string,
  ownersSelection: OwnersSelection = { ownersList: options },
) => {
  const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    // value is selected user's id
    if (ownersSelection.handleSelection) {
      ownersSelection.handleSelection(e.target.value);
    }
  };

  return (
    <Grid style={{ width: '100%' }}>
      <CleoSelect
        style={{ fontSize: '.65rem', width: '100%' }}
        defaultValue={owner}
        onChange={handleChange}
      >
        {ownersSelection?.ownersList?.map((option, idx) => (
          <option
            style={{ fontSize: '.65rem' }}
            key={`owner-${option.id}-${idx}`}
            value={option.id}
          >
            {option.fullName}
          </option>
        ))}
      </CleoSelect>
    </Grid>
  );
};

export const createSpecAlertTooltipBody = (specAlerts: string[]): JSX.Element => {
  const col1 = specAlerts.slice(0, Math.ceil(specAlerts.length / 2));
  const col2 = specAlerts.slice(Math.ceil(specAlerts.length / 2));
  const paddingCol = '0 1rem';
  const specItem = (spec: string) => (
    <Typography variant={'body2'} style={{ whiteSpace: 'nowrap', paddingTop: '.5rem' }}>
      {spec}
    </Typography>
  );
  return (
    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
      <Grid style={{ padding: paddingCol, flex: 1 }}>
        {col1.map((spec, idx) => {
          return <span key={`spec-alert-col1-${idx}`}>{specItem(spec)}</span>;
        })}
      </Grid>
      <Grid style={{ padding: paddingCol, flex: 1 }}>
        {col2.map((spec, idx) => {
          return <span key={`spec-alert-col2-${idx}`}>{specItem(spec)}</span>;
        })}
      </Grid>
    </Grid>
  );
};

interface CreateTooltipProps {
  header: string;
  body?: JSX.Element;
}
export const createTooltipHTML = ({ header = 'Test Header', body }: CreateTooltipProps) => {
  return (
    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography style={{ padding: '.5rem', backgroundColor: secondary }} variant={'subtitle2'}>
        {header}
      </Typography>
      {body}
    </Grid>
  );
};

/**
 * Functions that help build data objects used in details page
 * */

export type ProjectAndIProject = IProjectResponse & IProject;

export const createProjectLeadInformationObject = (
  project: IProjectResponse,
  pvf: IProject,
): Partial<ProjectAndIProject> => {
  const { created_at, type, stage, total_sqft, description, sfdc_project_lead_id } = project;
  const {
    projectStatus,
    address1,
    city,
    state,
    zipcode,
    value,
    value1,
    categories,
    actionStage,
    dataSource,
    size,
    specAlerts,
    projectNote,
    projectUrl,
    ownerId,
    primaryProjectType,
    dodgeVersionNumber,
    externalId,
  } = pvf;
  return {
    projectStatus,
    address1,
    city,
    state,
    zipcode,
    value,
    value1,
    categories,
    actionStage,
    dataSource,
    size,
    specAlerts,
    created_at,
    type,
    stage,
    total_sqft,
    description,
    projectNote,
    projectUrl,
    ownerId,
    sfdc_project_lead_id,
    primaryProjectType,
    dodgeVersionNumber,
    externalId,
  };
};
