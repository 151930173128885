import React, { useCallback } from 'react';
import { ColumnInstance } from 'react-table';
import { Grid, TableCell as MuiTableCell, TableSortLabel } from '@material-ui/core';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import clsx from 'clsx';
import { Draggable } from 'react-beautiful-dnd';

import { useReactTableHeaderCellStyles } from './ReactTableHeaderCell.styles';
import { ColumnDraggable } from './ReactTable';

import {
  bulkDemoteLeadsButtonPressedState,
  customizeColumnsGearIconAnchorElState,
  customizeColumnsGearIconClickedState,
} from '../../../../state/atoms/projectLeads';

export interface ReactTableHeaderCellProps<TableType extends Record<string, unknown>> {
  column: ColumnInstance<TableType> & ColumnDraggable;
  index: number;
}

export const ReactTableHeaderCell = <TableType extends Record<string, unknown>>(
  props: ReactTableHeaderCellProps<TableType>,
): JSX.Element => {
  const { column, index } = props;
  const classes = useReactTableHeaderCellStyles(props);
  const setGearIconClicked = useSetRecoilState<boolean>(customizeColumnsGearIconClickedState);
  const setAnchorEl = useSetRecoilState(customizeColumnsGearIconAnchorElState);
  const bulkDemoteButtonPressed = useRecoilValue(bulkDemoteLeadsButtonPressedState);

  const gearIconColumn = column.id === 'customizeColumnsGearIcon';
  const selectAllColumn = column.id === '_selector';
  const scoreColumn = column.id === 'score';

  const handleOpenCustomizeColumns = useCallback(
    e => {
      if (gearIconColumn) {
        setGearIconClicked(true);
        setAnchorEl(e.currentTarget);
      }
    },
    [setGearIconClicked, setAnchorEl, gearIconColumn],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onClickSort = column.getHeaderProps(column.getSortByToggleProps)?.onClick;

  return (
    <Draggable
      key={column.id}
      draggableId={column.id}
      index={index}
      isDragDisabled={column.disableDrag}
    >
      {provided => {
        return (
          <MuiTableCell
            className={clsx(
              classes.root,
              gearIconColumn && classes.gearIconColumn,
              scoreColumn && classes.scoreIcon,
            )}
            {...column.getHeaderProps()}
            onClick={gearIconColumn ? handleOpenCustomizeColumns : undefined}
            size="small"
          >
            <Grid container className={classes.cellContainer}>
              <TableSortLabel
                disabled={!column.canSort && !selectAllColumn}
                classes={{ icon: classes.label, active: classes.labelActive }}
                active={column.isSorted}
                direction={column.isSortedDesc ? 'desc' : 'asc'}
                onClick={onClickSort}
                hideSortIcon={!column.canSort}
              >
                <Grid
                  item
                  className={clsx(classes.cellText, gearIconColumn && classes.gearIconSpan)}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  {selectAllColumn && !bulkDemoteButtonPressed ? '' : column.render('Header')}
                </Grid>
              </TableSortLabel>
              {column.canResize && (
                <Grid item {...column.getResizerProps()} className={classes.resizer} draggable />
              )}
            </Grid>
          </MuiTableCell>
        );
      }}
    </Draggable>
  );
};
