import { HeadCell } from '../../Common/Tables/Table';
import {
  renderMoney,
  renderDate,
  renderValue,
  renderTruncatedValue,
  renderTitleCase,
  renderProperty,
  renderEvent,
  renderSearchProjectScore,
} from '../Leads/LeadsTable/LeadTableRowRenderers';
import { IDodgeProjectLeadResponse, IEventResponse } from '../../../models';

export type IDetailProjectResponse = IDodgeProjectLeadResponse & {
  score: string;
  address_1: string;
  events: IEventResponse;
  value: string;
  name: string;
};

export interface SearchProject {
  id: string;
  name: string;
  score: string;
  address_1: string;
  city: string;
  state: string;
  zipcode: string;
  value: string;
  bid_date: string;
  primary_stage: string;
  primary_project_type: string;
  events: IEventResponse;
}

export const projectHeadCells: HeadCell<IDetailProjectResponse, SearchProject>[] = [
  {
    id: 'score',
    label: 'Score',
    gridProps: { md: 1 },
    renderRow: { function: renderSearchProjectScore, param: ['score'], noData: '', style: {} },
  },
  {
    id: 'name',
    label: 'Project Name',
    gridProps: { md: 2 },
    renderRow: { function: renderTitleCase, param: ['name'], noData: '' },
  },
  {
    id: 'address_1',
    label: 'Location',
    gridProps: { md: 2 },
    renderRow: {
      function: renderProperty,
      param: ['address_1', 'city', 'state', 'zipcode'],
      addAdditionalParam: true,
      noData: '',
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 1 },
    renderRow: { function: renderMoney, param: ['value'], noData: '' },
  },
  {
    id: 'bid_date',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: { function: renderDate, param: ['bid_date'], noData: '' },
  },
  {
    id: 'primary_stage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: { function: renderValue, param: ['primary_stage'], noData: '' },
  },
  {
    id: 'primary_project_type',
    label: 'Project Type',
    gridProps: { md: 1 },
    renderRow: { function: renderTruncatedValue, param: ['primary_project_type'], noData: '' },
  },
  {
    id: 'events',
    label: 'Latest Event',
    gridProps: { md: 2 },
    renderRow: {
      function: renderEvent,
      param: ['events'],
      noData: '',
    },
  },
];
