import { makeStyles } from '@material-ui/core';

import { secondary } from '../../../../theme';

const useNotificationCardStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    height: '6rem',
    padding: theme.spacing(3, 1, 1, 3),
    flexDirection: 'column',
    alignItems: 'flex-end',
    overflow: 'hidden',
    position: 'relative',
  },
  dense: {
    lineHeight: '1.1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: '-0.25rem',
    marginBottom: '0.25rem',
    justifyContent: 'flex-end',
  },
  root: {
    display: 'flex',
    height: '6rem',
    textDecoration: 'none',
    padding: theme.spacing(1, 1.5, 1.5, 0),
    border: '1px solid #1FB4B1',
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    position: 'relative',
    overflow: 'hidden',
  },
  newText: {
    paddingRight: '3.6rem',
  },
  newBanner: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '1.3rem',
    width: '8rem',
    transform: 'rotate(-33.61deg)',
    backgroundColor: '#172D50',
    color: secondary,
    zIndex: 1,
    marginLeft: '-2rem',
    marginTop: '-1.3rem',
  },
  removeButton: {
    fontSize: '1rem',
    color: '#9E9797',
    padding: '0',
  },
}));

export default useNotificationCardStyles;
