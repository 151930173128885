import { Box, makeStyles, styled } from '@material-ui/core';

import { primaryBlue } from '../../../theme';

export const NoRecordBlock = styled(Box)({
  minHeight: '200px',
  justifyContent: 'center',
  fontSize: '1.21rem',
  fontWeight: 'bold',
  paddingTop: '40px',
  display: 'flex',
  color: primaryBlue,
});

export const useDataMapperStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 24),
    },
  },
  paper: {
    borderRadius: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    backgroundColor: 'white !important',
    borderRadius: '0 !important',
    color: `${primaryBlue} !important`,
    fontWeight: 'bold',
    fontSize: '1.21rem',
  },
  headerButton: {
    marginLeft: '1rem',
  },
}));
